import type { AutoAnnotateData } from '@/modules/Editor/AnnotationData'
import type { Click } from '@/modules/Editor/backend'
import type { IPoint } from '@/modules/Editor/point'
import { subPoints } from '@/modules/Editor/point'
import { Rectangle } from '@/modules/Editor/rectangle'

export const payloadRelativeToCentroid = (
  payload: { clicks: Click[]; bbox: Rectangle },
  centroid: IPoint,
): Omit<AutoAnnotateData, 'model'> => {
  const {
    left: x1,
    right: x2,
    top: y1,
    bottom: y2,
  } = new Rectangle(
    subPoints(payload.bbox.topLeft, centroid),
    subPoints(payload.bbox.bottomRight, centroid),
  )
  return {
    clicks: payload.clicks.map((click) => ({
      ...click,
      x: click.x - centroid.x,
      y: click.y - centroid.y,
    })),
    bbox: { x1, x2, y1, y2 },
  }
}
