import type { ImagePayload } from '@/modules/Editor/backend'
import type { IPoint } from '@/modules/Editor/point'
import { addPoints, divPoints, mulPoints, subPoints } from '@/modules/Editor/point'
import { Rectangle } from '@/modules/Editor/rectangle'
import type { PointMapping } from '@/modules/Editor/plugins/click/types'
import type { View } from '@/modules/Editor/views/view'

export type ClickImagePayload = {
  imagePayload: ImagePayload
  mapping: PointMapping
  scaled: boolean
}

const getBase64Payload = (view: View, bbox: Rectangle): ClickImagePayload => {
  // first add a 10% padding around the bounding box on all sides
  const pad10 = { x: bbox.width * 0.1, y: bbox.height * 0.1 }
  const pbbox = new Rectangle(subPoints(bbox.topLeft, pad10), addPoints(bbox.bottomRight, pad10))
  const sizeImage = { x: pbbox.width, y: pbbox.height }

  // keep the longest side under 800 px
  const targetLength =
    pbbox.width > pbbox.height
      ? { x: 800, y: (pbbox.height / pbbox.width) * 800 }
      : { x: (pbbox.width / pbbox.height) * 800, y: 800 }

  targetLength.x = Math.ceil(targetLength.x)
  targetLength.y = Math.ceil(targetLength.y)

  const mapping = {
    forward: (point: IPoint): IPoint =>
      mulPoints(subPoints(point, pbbox.topLeft), divPoints(targetLength, sizeImage)),
    backward: (point: IPoint): IPoint =>
      addPoints(divPoints(point, divPoints(targetLength, sizeImage)), pbbox.topLeft),
  }

  const croppedCanvas = document.createElement('canvas')
  croppedCanvas.width = targetLength.x
  croppedCanvas.height = targetLength.y
  const croppedCtx = croppedCanvas.getContext('2d')
  if (!croppedCtx) {
    throw new Error("Couldn't get context for cropped canvas")
  }

  // convert the padded bounding box to canvas view, for cropping
  const topLeft = view.camera.imageViewToCanvasView(pbbox.topLeft)
  const bottomRight = view.camera.imageViewToCanvasView({
    x: pbbox.topLeft.x + pbbox.width,
    y: pbbox.topLeft.y + pbbox.height,
  })

  const size = subPoints(bottomRight, topLeft)

  if (!view.mainLayer.canvas) {
    return { imagePayload: { base64: '' }, mapping: mapping, scaled: true }
  }

  croppedCtx.drawImage(
    view.mainLayer.canvas,
    topLeft.x,
    topLeft.y,
    size.x,
    size.y,
    0,
    0,
    targetLength.x,
    targetLength.y,
  )

  const base64 = croppedCanvas.toDataURL('image/png').replace(/^data:image\/(png|jpeg);base64,/, '')
  croppedCanvas.remove()
  return { imagePayload: { base64 }, mapping: mapping, scaled: true }
}

export const getImagePayload = async (
  visibleView: View,
  bbox: Rectangle,
): Promise<ClickImagePayload> => {
  // Long videos
  if (visibleView.fileManager.isFrameExtractorEnabled) {
    const frameSpec = await visibleView.fileManager.getFrameSpec(visibleView.currentFrameIndex)
    return {
      imagePayload: {
        segment_file_url: frameSpec.segmentFileUrl,
        index_in_segment: frameSpec.indexInSegment,
      },
      mapping: {
        forward: (p: IPoint) => p,
        backward: (p: IPoint) => p,
      },
      scaled: false,
    }
  }

  const section = await visibleView.fileManager.getSection(visibleView.currentFrameIndex)
  // TODO: in progress
  if (section?.type === 'tiled_image') {
    return getBase64Payload(visibleView, bbox)
  }

  if (section) {
    return {
      imagePayload: { url: section.hq_url },
      mapping: {
        forward: (p: IPoint) => p,
        backward: (p: IPoint) => p,
      },
      scaled: false,
    }
  }

  throw new Error('Expected image or video to be loaded')
}
