import type { TeamAction } from '@/store/modules/team/types'
import * as api from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

export type LeaveTeamPayload = {
  membershipId: number
}

/**
 * Soft-delete a team on the backend
 */
export const leaveTeam: TeamAction<LeaveTeamPayload, { success: boolean }> = async (_, params) => {
  try {
    return await api.remove(`memberships/${params.membershipId}`)
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.TEAM_LEAVE)
  }
}
