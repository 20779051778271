import { encodeString } from '@/core/utils/string'
import valuesIn from 'lodash/valuesIn'
import ColorConverter from 'color-convert'
import ColorHash from 'color-hash'

import { setContext } from '@/services/sentry'

export type RainbowColor = {
  name: string
  saturated: string
  normal: string
  desaturated: string
  text: string
}

// colors from:
// https://www.figma.com/file/UoTh3HOfBaImaXNfPtfPyP/%F0%9F%8E%A8-V7-Design-System?node-id=4%3A597
export const rainbowColors: RainbowColor[] = [
  {
    name: '1',
    saturated: 'rgba(238,240,241,1.0)',
    normal: 'rgba(255,73,73,1.0)',
    desaturated: 'rgba(255,229,229,1.0)',
    text: 'rgba(245,0,0,1.0)',
  },
  {
    name: '2',
    saturated: 'rgba(255,46,0,1.0)',
    normal: 'rgba(255,106,73,1.0)',
    desaturated: 'rgba(255,234,229,1.0)',
    text: 'rgba(245,61,0,1.0)',
  },
  {
    name: '3',
    saturated: 'rgba(255,92,0,1.0)',
    normal: 'rgba(255,128,73,1.0)',
    desaturated: 'rgba(255,237,229,1.0)',
    text: 'rgba(245,122,0,1.0)',
  },
  {
    name: '4',
    saturated: 'rgba(255,153,0,1.0)',
    normal: 'rgba(255,182,73,1.0)',
    desaturated: 'rgba(255,245,229,1.0)',
    text: 'rgba(204,153,0,1.0)',
  },
  {
    name: '5',
    saturated: 'rgba(255,199,0,1.0)',
    normal: 'rgba(255,215,73,1.0)',
    desaturated: 'rgba(255,249,229,1.0)',
    text: 'rgba(166,149,0,1.0)',
  },
  {
    name: '6',
    saturated: 'rgba(255,245,0,1.0)',
    normal: 'rgba(255,237,73,1.0)',
    desaturated: 'rgba(255,252,229,1.0)',
    text: 'rgba(121,160,3,1.0)',
  },
  {
    name: '7',
    saturated: 'rgba(219,255,0,1.0)',
    normal: 'rgba(219,255,73,1.0)',
    desaturated: 'rgba(250,255,229,1.0)',
    text: 'rgba(102,184,20,1.0)',
  },
  {
    name: '8',
    saturated: 'rgba(173,255,0,1.0)',
    normal: 'rgba(186,255,73,1.0)',
    desaturated: 'rgba(245,255,229,1.0)',
    text: 'rgba(83,201,44,1.0)',
  },
  {
    name: '9',
    saturated: 'rgba(143,255,0,1.0)',
    normal: 'rgba(175,255,73,1.0)',
    desaturated: 'rgba(244,255,229,1.0)',
    text: 'rgba(64,181,64,1.0)',
  },
  {
    name: '10',
    saturated: 'rgba(82,255,0,1.0)',
    normal: 'rgba(153,255,73,1.0)',
    desaturated: 'rgba(241,255,229,1.0)',
    text: 'rgba(64,181,122,1.0)',
  },
  {
    name: '11',
    saturated: 'rgba(0,236,123,1.0)',
    normal: 'rgba(82,255,130,1.0)',
    desaturated: 'rgba(229,255,237,1.0)',
    text: 'rgba(37,167,135,1.0)',
  },
  {
    name: '12',
    saturated: 'rgba(0,239,196,1.0)',
    normal: 'rgba(82,255,203,1.0)',
    desaturated: 'rgba(229,255,247,1.0)',
    text: 'rgba(20,184,184,1.0)',
  },
  {
    name: '13',
    saturated: 'rgba(0,225,239,1.0)',
    normal: 'rgba(82,245,255,1.0)',
    desaturated: 'rgba(229,254,255,1.0)',
    text: 'rgba(4,151,200,1.0)',
  },
  {
    name: '14',
    saturated: 'rgba(0,194,255,1.0)',
    normal: 'rgba(82,203,255,1.0)',
    desaturated: 'rgba(229,247,255,1.0)',
    text: 'rgba(43,148,252,1.0)',
  },
  {
    name: '15',
    saturated: 'rgba(0,133,255,1.0)',
    normal: 'rgba(82,151,255,1.0)',
    desaturated: 'rgba(229,240,255,1.0)',
    text: 'rgba(35,89,251,1.0)',
  },
  {
    name: '16',
    saturated: 'rgba(0,102,255,1.0)',
    normal: 'rgba(82,130,255,1.0)',
    desaturated: 'rgba(229,237,255,1.0)',
    text: 'rgba(54,61,233,1.0)',
  },
  {
    name: '17',
    saturated: 'rgba(5,0,255,1.0)',
    normal: 'rgba(82,89,255,1.0)',
    desaturated: 'rgba(229,231,255,1.0)',
    text: 'rgba(109,85,253,1.0)',
  },
  {
    name: '18',
    saturated: 'rgba(97,0,255,1.0)',
    normal: 'rgba(116,82,255,1.0)',
    desaturated: 'rgba(235,229,255,1.0)',
    text: 'rgba(122,24,220,1.0)',
  },
  {
    name: '19',
    saturated: 'rgba(143,0,255,1.0)',
    normal: 'rgba(179,82,255,1.0)',
    desaturated: 'rgba(244,229,255,1.0)',
    text: 'rgba(171,24,220,1.0)',
  },
  {
    name: '20',
    saturated: 'rgba(219,0,255,1.0)',
    normal: 'rgba(231,82,255,1.0)',
    desaturated: 'rgba(251,229,255,1.0)',
    text: 'rgba(244,62,199,1.0)',
  },
  {
    name: '21',
    saturated: 'rgba(255,0,214,1.0)',
    normal: 'rgba(255,82,207,1.0)',
    desaturated: 'rgba(255,229,248,1.0)',
    text: 'rgba(220,24,122,1.0)',
  },
  {
    name: '22',
    saturated: 'rgba(255,0,122,1.0)',
    normal: 'rgba(255,82,155,1.0)',
    desaturated: 'rgba(255,229,240,1.0)',
    text: 'rgba(220,24,73,1.0)',
  },
]

export const saturatedColors = rainbowColors.map((c) => c.saturated)
export const normalColors = rainbowColors.map((c) => c.normal)
export const desaturatedColors = rainbowColors.map((c) => c.desaturated)

/**
 * Generate a deterministic color from a predefined palette from a given string,
 * or get a random value if no string is given.
 */
export const getPaletteColor = (
  value?: string,
  type: 'saturated' | 'normal' | 'desaturated' = 'saturated',
): string => {
  let colors = saturatedColors
  if (type === 'normal') {
    colors = normalColors
  } else if (type === 'desaturated') {
    colors = desaturatedColors
  }
  // calculate consistently the color base on a string (color picked will always be the same)
  if (value) {
    const encodedIndex = encodeString(value) % colors.length
    return colors[encodedIndex]
  }
  // fallback to random color
  const randomIdx = Math.floor(Math.random() * colors.length)
  return colors[randomIdx] || 'auto'
}

export const getColorName = (color?: string, full = true): string | undefined => {
  if (!color) {
    return
  }

  const rainbowColor = rainbowColors.find((c) => valuesIn(c).includes(color))
  if (rainbowColor) {
    return `${full ? 'Rainbow ' : ''}${rainbowColor.name}`
  }
}

const RGBA_REGEX = /rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d?\.?\d+)\s*\)/i
const RGB_REGEX = /rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)/i

/**
 * Parses a `rgba(r,g,b,a)` or `rgb(r,g,b)` string and if possible returns a RGBA element.
 * If the alpha value is not present, it is set to 1.0
 */
export const parseRGBA = (rgba: string): RGBA => {
  const rgbaColor = rgba.match(RGBA_REGEX)
  if (rgbaColor) {
    return {
      r: Number(rgbaColor[1]),
      g: Number(rgbaColor[2]),
      b: Number(rgbaColor[3]),
      a: Number(rgbaColor[4]),
    }
  }

  const rgbColor = rgba.match(RGB_REGEX)
  if (rgbColor) {
    return { r: Number(rgbColor[1]), g: Number(rgbColor[2]), b: Number(rgbColor[3]), a: 1.0 }
  }

  setContext('wrong color input', { rgba })
  console.error('Invalid color string, falling back to first rainbow color')
  return parseRGBA(rainbowColors[0].normal)
}

/**
 * Renders a RGBA value in a css compatible way.
 * @param rgba `RGBA` value to render
 * @param alpha if `alpha` is provided then it overrides the alpha value in the `rgba` argument
 */
export const rgbaString = (rgba: RGBA, alpha: number | undefined = undefined): string => {
  if (alpha === undefined) {
    return `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`
  }
  return `rgba(${rgba.r},${rgba.g},${rgba.b},${alpha})`
}

/**
 * Change the alpha value of the RGBA color
 * @param rgbaClr original color
 * @param alpha target alpha value to change
 * @returns Alpha value updated color
 */
export const changeRGBAAlpha = (rgbaClr: string, alpha: number): string => {
  const rgba = parseRGBA(rgbaClr)
  return rgbaString(rgba, alpha)
}

const colorHash = new ColorHash()

const SATURATED_COLORS = rainbowColors.map((color) => parseRGBA(color.saturated))
const NORMAL_COLORS = rainbowColors.map((color) => parseRGBA(color.normal))

/**
 * An interface to capture red-green-blue-alpha values.
 */
export interface RGBA {
  /** red, between 0-255 */
  r: number
  /** green, between 0-255 */
  g: number
  /** blue, between 0-255 */
  b: number
  /** alpha, between 0.0-1.0 */
  a: number
}

/**
 * An interface to capture red-green-blue-alpha values.
 */
export interface HSLA {
  /** hue, between 0-359 */
  h: number
  /** saturation, between 0-99 */
  s: number
  /** lightness, between 0-99 */
  l: number
  /** alpha, between 0.0-1.0 */
  a: number
}

/**
 * The UI kit includes a large set of colours that are used to associate
 * classes to annotations. This function can be used to generate rainbow
 * colours in a way that means that similar colours aren't clustered together.
 */
export const getRainbowColor = (index: number): string => {
  const colorList = [1, 10, 19, 6, 15, 2, 11, 20, 7, 16, 3, 12, 21, 8, 17, 4, 13, 22, 9, 18, 5, 14]
  return rainbowColors[colorList[index % colorList.length] - 1].normal
}

export const getDesaturatedRainbowColor = (saturatedRGBA: RGBA): string | null => {
  const saturatedColorIndex = SATURATED_COLORS.findIndex(
    (c) => c.r === saturatedRGBA.r && c.g === saturatedRGBA.g && c.b === saturatedRGBA.b,
  )
  if (saturatedColorIndex < 0) {
    return null
  }

  return rainbowColors[saturatedColorIndex].desaturated
}

export const getTextRainbowColor = (saturatedRGBA: RGBA): string | null => {
  const saturatedColorIndex = SATURATED_COLORS.findIndex(
    (color) =>
      color.r === saturatedRGBA.r && color.g === saturatedRGBA.g && color.b === saturatedRGBA.b,
  )

  if (saturatedColorIndex === -1) {
    return null
  }

  return rainbowColors[saturatedColorIndex].text
}

export const getSaturatedRainbowColor = (normalRGBA: RGBA): string | null => {
  const normalColorIndex = NORMAL_COLORS.findIndex(
    (color) => color.r === normalRGBA.r && color.g === normalRGBA.g && color.b === normalRGBA.b,
  )

  if (normalColorIndex === -1) {
    return null
  }

  return rainbowColors[normalColorIndex].saturated
}

export const getRGBAColorHash = (str: string): RGBA => {
  const rgbHash = colorHash.rgb(str)
  return { r: rgbHash[0], g: rgbHash[1], b: rgbHash[2], a: 1.0 }
}

export const getHSLAColorHash = (str: string): HSLA => {
  const hslHash = colorHash.hsl(str)
  return { h: hslHash[0], s: hslHash[1] * 100, l: hslHash[2] * 100, a: 1.0 }
}

export const getColorHash = (str: string): string => colorHash.hex(str)

/**
 * Returns RGBA color hash of the string
 * @param str hash candidate
 * @returns rgba color string
 * 'Hello World' => rgba(135, 150, 197, 0)
 */
export const getColorHashRGBA = (str: string): string => rgbaString(getRGBAColorHash(str))

/**
 * Renders a HSLA value in a css compatible way.
 * @param hsla `HSLA` value to render
 * @param alpha if `alpha` is provided then it overrides the alpha value in the `hsla` argument
 */

export function hslaString(hsla: HSLA, alpha: number | undefined = undefined): string {
  if (!alpha) {
    return `hsla(${hsla.h},${hsla.s}%,${hsla.l}%,${hsla.a})`
  }
  return `hsla(${hsla.h},${hsla.s}%,${hsla.l}%,${alpha})`
}

/**
 * Convert RGBA into HSLA values
 * @param rgba rgba color value
 * @returns converted hsla object
 */
export const rgbaToHSLA = (rgba: RGBA): HSLA => {
  const hsl = ColorConverter.rgb.hsl([rgba.r, rgba.g, rgba.b])
  return {
    h: hsl[0],
    s: hsl[1],
    l: hsl[2],
    a: rgba.a,
  }
}

/**
 * Convert HSLA into RGBA values
 * @param hsla hsla color value
 * @returns updated color string
 */
export const hslaToRGBA = (hsla: HSLA): RGBA => {
  const rgb = ColorConverter.hsl.rgb([hsla.h, hsla.s, hsla.l])
  return {
    r: rgb[0],
    g: rgb[1],
    b: rgb[2],
    a: hsla.a,
  }
}

/**
 * Converts a HEX css string to a RGBA object
 *
 * Supports 3-digit or 6-digit hex strings
 *
 * @param {String} hex 3 or 6-digit hex string. Must start with '#'
 */
export const hexToRGBA = (hex: string, alpha = 1.0): RGBA => {
  const rgb = ColorConverter.hex.rgb(hex)
  return {
    r: rgb[0],
    g: rgb[1],
    b: rgb[2],
    a: alpha,
  }
}

/**
 * Modify HSLA attributes
 * @param hsla hsla color value
 * @param modifier partial hsla attributes to modify
 * @returns updated color string
 */
export const modifyHSLA = (hsla: HSLA, modifier?: Partial<HSLA>): HSLA => {
  if (!modifier) {
    return hsla
  }
  return {
    h: modifier.h || hsla.h,
    s: modifier.s || hsla.s,
    l: modifier.l || hsla.l,
    a: modifier.a || hsla.a,
  }
}

/**
 * Change the rgbaString to hsla string
 * @param rgbaString rgba string color
 * @param modifier HSLA modifier
 * @returns HSLA param modified string
 */
export const rgbaStringToHSLAString = (rgbaString: string, modifier?: Partial<HSLA>): string =>
  hslaString(modifyHSLA(rgbaToHSLA(parseRGBA(rgbaString)), modifier))

const HSLA_REGEX = /hsla\(\s*(\d{1,3})\s*,\s*(\d{1,3})%\s*,\s*(\d{1,3})%\s*,\s*(\d?\.?\d+)\s*\)/i
const HSL_REGEX = /hsl\(\s*(\d{1,3})\s*,\s*(\d{1,3})%\s*,\s*(\d{1,3})%\s*\)/i

/**
 * Parses a `hsla(h,s,l,a)` or `hsl(h,s,l)` string and if possible returns a HSLA element.
 * If the alpha value is not present, it is set to 1.0
 */
export const parseHSLA = (hsla: string): HSLA => {
  const hslaColor = hsla.match(HSLA_REGEX)
  if (hslaColor) {
    return {
      h: Number(hslaColor[1]),
      s: Number(hslaColor[2]),
      l: Number(hslaColor[3]),
      a: Number(hslaColor[4]),
    }
  }

  const hslColor = hsla.match(HSL_REGEX)
  if (hslColor) {
    return { h: Number(hslColor[1]), s: Number(hslColor[2]), l: Number(hslColor[3]), a: 1.0 }
  }

  setContext('wrong color input', { hsla })
  console.error('Invalid color string, falling back to the first rainbow color')
  return rgbaToHSLA(parseRGBA(rainbowColors[0].normal))
}

/**
 * Convert any color string into HSLA object
 * @param color color string - rgba, hsla, hex are accepted
 */
export const anyToHSLA = (color: string): HSLA => {
  if (color.startsWith('rgb')) {
    return rgbaToHSLA(parseRGBA(color))
  }
  if (color.startsWith('hsl')) {
    return parseHSLA(color)
  }
  if (color.startsWith('#')) {
    return rgbaToHSLA(hexToRGBA(color))
  }
  return { h: 0, s: 0, l: 0, a: 1 }
}

/**
 * Convert any color string into RGBA object
 * @param color color string - rgba, hsla, hex are accepted
 */
export const anyToRGBA = (color: string): RGBA => {
  if (color.startsWith('rgb')) {
    return parseRGBA(color)
  }
  if (color.startsWith('hsl')) {
    return hslaToRGBA(parseHSLA(color))
  }
  if (color.startsWith('#')) {
    return hexToRGBA(color)
  }
  return { r: 0, g: 0, b: 0, a: 1 }
}

export const white: (a?: number) => RGBA = (a = 1.0) => ({ r: 255, g: 255, b: 255, a })
export const primary: (a?: number) => RGBA = (a = 1.0) => ({ r: 35, g: 88, b: 250, a })
export const neutral: (a?: number) => RGBA = (a = 1.0) => ({ r: 94, g: 102, b: 110, a })

/**
 * Lighten the color by a given ratio
 * @param {RGBA} color to lighten
 * @param {number} ratio to lighten the color by, between 0 and 1
 */
export const getLightnedRGBA = (color: RGBA, ratio: number): RGBA => {
  if (ratio < 0 || ratio > 1) {
    throw new Error('Desaturation must be between 0 and 1')
  }

  const hsla = rgbaToHSLA(color)
  hsla.l = 100 * ratio
  return hslaToRGBA(hsla)
}
