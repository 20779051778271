import type { RasterBufferAccessor } from '@/modules/Editor/models/raster/RasterBufferAccessor'
import type { Bounds } from '@/modules/Editor/models/raster/Raster'

/**
 * Checks if a mask annotation is empty (has no labelled pixels within its bounding box).
 *
 * @param annotation The annotation to check
 * @param raster The raster associated with the annotation.
 *
 * @returns True if the annotation mask is empty.
 */
export function isMaskAnnotationEmpty(
  labelIndex: number,
  rasterBufferAccessor: RasterBufferAccessor,
  bounds?: Bounds,
): boolean {
  const { get, width, height } = rasterBufferAccessor

  let xMin: number
  let xMax: number
  let yMin: number
  let yMax: number

  if (bounds) {
    xMin = bounds.topLeft.x
    xMax = bounds.bottomRight.x
    yMin = bounds.topLeft.y
    yMax = bounds.bottomRight.y
  } else {
    xMin = 0
    xMax = width - 1
    yMin = 0
    yMax = height - 1
  }

  for (let y = yMin; y <= yMax; y++) {
    for (let x = xMin; x <= xMax; x++) {
      if (get(x, y) === labelIndex) {
        // Found -at least one- pixel labelled, jump out early
        return false
      }
    }
  }

  // Found no labelled pixels
  return true
}
