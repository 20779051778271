import type { Comment, CommentThread, ICommentsProvider } from '@/modules/Editor/iproviders/types'
import { useDatasetItemsStore } from '@/modules/Datasets/useDatasetItemsStore'
import {
  createV2Comment,
  createV2CommentThread,
  deleteV2Comment,
  deleteV2CommentThread,
  loadV2Comments,
  loadV2CommentThreads,
  updateV2Comment,
  updateV2CommentThread,
} from '@/backend/darwin'

export class CommentsProvider implements ICommentsProvider {
  private datasetItemsStore: ReturnType<typeof useDatasetItemsStore>
  private teamSlug: string

  constructor(config: { teamSlug: string }) {
    this.teamSlug = config.teamSlug
    this.datasetItemsStore = useDatasetItemsStore()
  }

  async getThreads(): Promise<CommentThread[]> {
    if (!this.teamSlug) {
      console.warn('Tried to load comment threads without setting a team first')
      return []
    }

    if (!this.datasetItemsStore.currentItemId) {
      // this will happen when a worker sends all items to next stage
      console.warn('Tried to load comment threads without a current item set')
      return []
    }

    const result = await loadV2CommentThreads({
      teamSlug: this.teamSlug,
      datasetItemId: this.datasetItemsStore.currentItemId,
    })

    if ('data' in result) {
      return result.data
    }

    if ('error' in result) {
      return Promise.reject(result)
    }

    return Promise.reject('getThreads failed unexpectedly')
  }

  async createThread(payload: CommentThread, initialComment: string): Promise<CommentThread> {
    const currentItemId = this.datasetItemsStore.currentItemId
    if (!currentItemId) {
      throw new Error('selectedDatasetItemV2Id is not set!')
    }

    if (!this.teamSlug) {
      throw new Error('Tried to load comment threads without setting a team first')
    }

    const result = await createV2CommentThread({
      comments: [{ body: initialComment }],
      boundingBox: payload.bounding_box,
      datasetItemId: currentItemId,
      sectionIndex: payload.section_index ?? undefined,
      slotName: payload.slot_name,
      teamSlug: this.teamSlug,
    })

    if (!('data' in result)) {
      return Promise.reject(result)
    }

    return result.data
  }

  async updateThread(payload: CommentThread): Promise<CommentThread> {
    if (!this.teamSlug) {
      throw new Error('Tried to load comment threads without setting a team first')
    }

    const result = await updateV2CommentThread({
      boundingBox: payload.bounding_box,
      datasetItemId: payload.dataset_item_id,
      resolved: payload.resolved,
      threadId: payload.id,
      teamSlug: this.teamSlug,
    })

    if (!('data' in result)) {
      return Promise.reject(result)
    }

    return result.data
  }

  async removeThread(payload: CommentThread): Promise<void> {
    if (!this.teamSlug) {
      throw new Error('Tried to load comment threads without setting a team first')
    }

    const result = await deleteV2CommentThread({
      teamSlug: this.teamSlug,
      datasetItemId: payload.dataset_item_id,
      threadId: payload.id,
    })

    if ('data' in result) {
      return
    }

    return Promise.reject('removeThread failed unexpectedly')
  }

  async resolveThread(payload: CommentThread): Promise<CommentThread> {
    if (!this.teamSlug) {
      throw new Error('Tried to load comment threads without setting a team first')
    }

    const result = await updateV2CommentThread({
      teamSlug: this.teamSlug,
      datasetItemId: payload.dataset_item_id,
      threadId: payload.id,
      resolved: true,
    })

    if ('data' in result) {
      return result.data
    }

    return Promise.reject(result)
  }

  unreadThread(): Promise<void> {
    return Promise.resolve()
  }

  async getComments(thread: CommentThread): Promise<Comment[]> {
    if (!this.teamSlug) {
      throw new Error('Tried to load comment threads without setting a team first')
    }

    try {
      const result = await loadV2Comments({
        datasetItemId: thread.dataset_item_id,
        teamSlug: this.teamSlug,
        threadId: thread.id,
      })

      if ('data' in result) {
        return result.data
      }
    } catch {
      return []
    }

    return []
  }

  async createComment(body: string, thread: CommentThread): Promise<Comment> {
    if (!this.teamSlug) {
      throw new Error('Tried to load comment threads without setting a team first')
    }

    try {
      const result = await createV2Comment({
        body: body,
        datasetItemId: thread.dataset_item_id,
        teamSlug: this.teamSlug,
        threadId: thread.id,
      })

      if ('data' in result) {
        return result.data
      }
    } catch (e: unknown) {
      return Promise.reject(e)
    }

    return Promise.reject()
  }

  async updateComment(comment: Comment, thread: CommentThread): Promise<Comment> {
    if (!this.teamSlug) {
      throw new Error('Tried to load comment threads without setting a team first')
    }

    try {
      const result = await updateV2Comment({
        body: comment.body,
        commentId: comment.id,
        datasetItemId: thread.dataset_item_id,
        teamSlug: this.teamSlug,
        threadId: thread.id,
      })

      if ('data' in result) {
        return result.data
      }
    } catch (e: unknown) {
      return Promise.reject(e)
    }

    return Promise.reject()
  }

  async removeComment(comment: Comment, thread: CommentThread): Promise<void> {
    if (!this.teamSlug) {
      throw new Error('Tried to load comment threads without setting a team first')
    }

    try {
      const result = await deleteV2Comment({
        commentId: comment.id,
        datasetItemId: thread.dataset_item_id,
        teamSlug: this.teamSlug,
        threadId: thread.id,
      })

      if ('data' in result) {
        return
      }
    } catch (e: unknown) {
      return Promise.reject(e)
    }

    return Promise.reject('removeComment failed unexpectedly')
  }
}
