import { calcCentroidPointFromPath } from '@/modules/Editor/annotationCentroid'
import type { Camera } from '@/modules/Editor/camera'
import type { IPoint } from '@/modules/Editor/point'
import { getAnnotationCompoundPathAtFrame } from '@/modules/Editor/getAnnotationCompoundPath'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'

export function calcCentroidPoint(
  scale: number,
  camera: Camera,
  frameIndex: number,
  annotation: Annotation,
): IPoint | undefined {
  if (annotation.type === 'tag' || annotation.type === 'mask') {
    return
  }

  const { path } = getAnnotationCompoundPathAtFrame(annotation, frameIndex)
  let centroid

  switch (annotation.type) {
    case 'ellipse':
    case 'line':
    case 'eye':
    case 'skeleton':
    case 'keypoint':
    default:
      if (!annotation.centroid) {
        centroid = annotation.centroid = calcCentroidPointFromPath(path, annotation.type, scale)
      } else {
        centroid = annotation.centroid
      }
      break
  }

  if (!centroid) {
    return
  }

  return camera.imageViewToCanvasView(centroid)
}
