import { VideoView } from '@/modules/Editor/views/videoView'

import { ViewTypes } from './viewTypes'

export class PdfView extends VideoView {
  readonly type = ViewTypes.PDF

  play(): void {
    if (this.isPlaying) {
      return
    }
    this.isPlaying = true
    const length = this.framesIndexes.length

    this.videoInterval = window.setInterval(async () => {
      if (this.isLoading) {
        return
      }

      const nextFrameIndex = (this.currentFrameIndex + 1) % length
      this.currentFrameIndex = nextFrameIndex

      if (!this.fileManager.isFrameLoaded(this.currentFrameIndex)) {
        this.loading = true
      }

      const lqFrame = await this.fileManager.getHQFrame(this.currentFrameIndex)

      this.currentFrame = lqFrame
      this.loading = false

      // jumping between frames changes subAnnotation content so the redraw option is enabled
      this.annotationManager.invalidateAnnotationCache()
      this.commentManager.deselectItem()

      this.allLayersChanged()
    }, 1000 / this.fileManager.fps)
  }
}
