<template>
  <ToastTemplate
    :variant="variant"
    extended
  >
    <div
      class="extended-toast__container"
      :style="{ 'align-items': meta.desc ? 'flex-start' : 'center' }"
    >
      <div
        class="extended-toast-icon__wrapper"
        :class="`extended-toast-icon__wrapper--${variant}`"
      >
        <Icon :name="icon" />
      </div>
      <div class="extended-toast-content__wrapper">
        <h3
          class="extended-toast__label primary"
          :class="`extended-toast__label--${variant}`"
        >
          {{ meta.title }}
        </h3>
        <h3
          v-if="meta.desc"
          class="extended-toast__label"
          :class="`extended-toast__label--${variant}`"
          style="margin-top: 4px"
        >
          {{ meta.desc }}
        </h3>
      </div>
    </div>
  </ToastTemplate>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Icon from '@/uiKit/Icon/Icon.vue'
import type { IconNames } from '@/uiKit/Icon/icons'

import ToastTemplate from './ToastTemplate.vue'
import type { ToastProps } from './types'
import { ToastEvent } from './types'

const props = defineProps<{
  variant: ToastProps['variant']
  meta: ToastProps['meta']
}>()

const icon = computed<IconNames>(() => {
  if (props.variant === ToastEvent.SUCCESS) {
    return 'duotone-status-complete'
  }

  if (props.variant === ToastEvent.WARNING || props.variant === ToastEvent.ERROR) {
    return 'duotone-warn'
  }

  return 'duotone-info'
})
</script>

<style lang="scss" scoped>
.extended-toast__container {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 20px max(791px) 20px;
}

.extended-toast-icon__wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 20px;
  height: 20px;
}

.extended-toast-content__wrapper {
  @include col--left;
  justify-content: flex-start;
}

.extended-toast-icon__wrapper {
  &--default {
    color: var(--color-icon-primary);
  }

  &--success {
    color: var(--color-icon-success);
  }

  &--error {
    color: var(--color-icon-critical);
  }

  &--warning {
    color: var(--color-icon-warning);
  }
}

.extended-toast__label {
  @include typography(md-1, inter, 500);
  color: var(--color-text-default);
  font-weight: 500;
  user-select: none;

  &--default {
    color: var(--color-text-selected);
  }

  &--warning {
    color: var(--color-text-warning);
  }

  &--error {
    color: var(--color-text-critical);
  }

  &--success {
    color: var(--color-text-success);
  }
}

.primary {
  font-weight: 600;
}
</style>
