<template>
  <ConfirmDialog
    open
    max-height="auto"
    max-width="auto"
    :click-outside-to-close="false"
    hide-close
  >
    <template #title>Critical error</template>
    <template #detail>Please refresh your browser.</template>

    <template #footer-buttons>
      <DarwinButton
        class="reload-button"
        flair="rounded"
        kind="primary"
        size="md"
        @click="reload"
        >Reload</DarwinButton
      >
    </template>
  </ConfirmDialog>
</template>

<script lang="ts">
export default {
  name: 'CropDialog',
}
</script>

<script setup lang="ts">
import ConfirmDialog from '@/uiKit/Dialog/ConfirmDialog.vue'
import DarwinButton from '@/uiKit/Button/DarwinButton.vue'

const reload = (): void => {
  window.location.reload()
}
</script>

<style scoped lang="scss">
.reload-button {
  align-self: flex-end;
  margin-left: auto;
}
</style>
