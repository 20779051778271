import type { AnnotationClassPayload } from '@/store/types'
import type { AClassFilter } from '@/store/types/AClassFilter'
import { get } from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'
import type { ApiResult } from './types'

type Params = AClassFilter & {
  teamSlug: string
}

type BackendQueryParams = AClassFilter & {
  dataset_ids?: number[]
  not_dataset_ids?: number[]
}

type Response = {
  annotation_classes: AnnotationClassPayload[]
  type_counts: { id: number; name: string; count: number }[]
}

export const loadAnnotationClasses = async (params: Params): Promise<ApiResult<Response>> => {
  const { teamSlug, ...rest } = params
  const path = `teams/${teamSlug}/annotation_classes`

  const backendParams: BackendQueryParams = { ...rest }

  try {
    const response = await get<Response>(path, backendParams)
    return { ok: true, data: response.data }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return { ...parseError(error, errorMessages.ANNOTATION_CLASS_LOAD), ok: false }
  }
}
