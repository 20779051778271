import { loadFramesManifests, getSignedUrl } from '@/backend/darwin/loadFramesManifests'

export type FrameManifest = {
  timestamp: string
  originalIndex: number
  segmentIndex: string
}

/**
 * @param manifest BE frame data in `${frameIndex}:${segmentIndex}:${visible}:${timestamp}` format,
 * where each line is a separate frame
 */
export const parseFramesManifest = (manifest: string): FrameManifest[] => {
  const result: FrameManifest[] = []
  const manifestLines = manifest.split('\n')
  manifestLines.forEach((line) => {
    if (!line) {
      return
    }

    const [frameIndex, segmentIndex, visible, timestamp] = line.split(':')

    if (visible === '1') {
      result.push({ originalIndex: Number(frameIndex), segmentIndex, timestamp })
    }
  })
  return result
}

export type FrameSpec = {
  segment_file_url: string
  index_in_segment: number
}

export type FramesManifest = {
  [frame_index: number]: FrameSpec
}

export const getFramesManifests = async (
  stream_url: string,
  frames_manifest_urls: string[] | undefined,
): Promise<FramesManifest> => {
  // Get the signed URLs for segments
  const signed_stream = await getSignedUrl(stream_url)

  const signed_segments = signed_stream
    .split('\n')
    .filter((line: string) => line.startsWith('https://'))

  const frames_manifest: FramesManifest = []

  if (frames_manifest_urls) {
    // Download and parse all frames manifests
    const manifests = await Promise.all(
      frames_manifest_urls.map(async (url) => {
        const manifest = await loadFramesManifests(url)
        if (!manifest) {
          return []
        }
        return parseFramesManifest(manifest)
      }),
    )
    const manifest = manifests.flat()
    // For each frame, get the segment URL and index in segment
    manifest.forEach((frame, i) => {
      frames_manifest[i] = {
        index_in_segment: frame.originalIndex,
        segment_file_url: signed_segments[parseInt(frame.segmentIndex)],
      }
    })
  }
  return frames_manifest
}

/**
 * Retrieve a frame manifest from a video manifest by manifest index
 */
const getFrameManifestByIndex = (manifest: FrameManifest[], manifestIndex: number): FrameManifest =>
  manifest[manifestIndex]

/**
 * Retrieve a frame timestamp from a video manifest by frame index
 */
export const getTimestampByIndex = (manifest: FrameManifest[], frameIndex: number): string =>
  getFrameManifestByIndex(manifest, frameIndex)?.timestamp

/**
 * Determines if the current timestamp is closer to the target than the best known so far
 */
const isCloser = (
  currentTimestamp: number,
  targetTimestamp: number,
  bestTimestamp: number,
): boolean =>
  Math.abs(currentTimestamp - targetTimestamp) < Math.abs(bestTimestamp - targetTimestamp)

/**
 * Finds the index of the frame in the manifest closest to the given timestamp using binary search.
 */
export const getNearestFrameIndexByTimestamp = (
  manifest: FrameManifest[],
  timestamp: number,
): number => {
  let left = 0
  let right = manifest.length - 1
  let nearestFrameIndex = -1

  while (left <= right) {
    const mid = Math.floor((left + right) / 2)
    const midTimestamp = Number(manifest[mid].timestamp)

    // Return immediately if the exact timestamp is found.
    if (midTimestamp === timestamp) {
      return mid
    }

    // Move the search boundaries based on comparison
    if (midTimestamp < timestamp) {
      left = mid + 1
    } else {
      right = mid - 1
    }

    // Update nearestFrameIndex if this frame is closer to the target timestamp
    if (
      nearestFrameIndex === -1 ||
      isCloser(midTimestamp, timestamp, Number(manifest[nearestFrameIndex].timestamp))
    ) {
      nearestFrameIndex = mid
    }
  }

  return nearestFrameIndex
}
