import type { EditablePoint } from '@/modules/Editor/point'

export type UpdateAnnotationContext = {
  action: 'update'
  content: [EditablePoint[][], number]
}

export type DeleteVertexContext = {
  action: 'deleteVertex'
  content: [EditablePoint[], number]
}

export type DeletableVertexContext = DeleteVertexContext | UpdateAnnotationContext | null

export class EditPluginError extends Error {
  constructor(message: string) {
    super(`Edit Plugin: ${message}`)
  }
}
