import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'

import { getAnnotationClassColor } from '@/modules/Editor/AnnotationClass'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { createAnnotationFromInstanceParams } from '@/modules/Editor/models/annotation/annotationFactories'
import type { CreateAnnotationParams } from '@/modules/Editor/models/annotation/annotationFactories'

import { getPath2D, setPath2D } from './annotationRenderingCache'

type CloneAnnotationParams = Partial<CreateAnnotationParams>

const clonePath2D = (targetAnnotationId: string, newAnnotationId: string): void => {
  // Copy the target path2D
  const targetPath2D = getPath2D(targetAnnotationId)

  if (targetPath2D) {
    setPath2D(newAnnotationId, targetPath2D)
  }
}

/**
 * Creates a set of CreateAnnotationParams for a new Annotation,
 * by picking properties from the target annotation and the given override
 * params.
 *
 * @param targetAnnotation The annotation being copied.
 * @param params properties to override the copied properies of
 * the target annotation.
 *
 * @returns A set of CreateAnnotationParams that can be used to create a
 * new annotation.
 */
const getShallowCloneCreateAnnotationParams = (
  targetAnnotation: Annotation,
  params?: Partial<CreateAnnotationParams>,
): CreateAnnotationParams => {
  const subAnnotations =
    (params && params.subAnnotations) || (targetAnnotation.subAnnotations as Annotation[])

  const annotationClass = (params && params.annotationClass) || targetAnnotation.annotationClass

  const createAnnotationParams = {
    id: targetAnnotation.id,
    type: (params && params.type) || targetAnnotation.type,
    parentId: targetAnnotation.parentId,
    annotationClass,
    classId: annotationClass.id,
    label: annotationClass.name,
    color: getAnnotationClassColor(annotationClass),
    data: (params && params.data) || targetAnnotation.data,
    properties: (params && params.properties) || targetAnnotation.properties,
    subAnnotations,
  }

  return createAnnotationParams
}

/**
 * Creates a set of CreateAnnotationParams for a new Annotation,
 * by picking properties from the target annotation and the given override
 * params.
 *
 * @param targetAnnotation The annotation being copied.
 * @param params properties to override the copied properies of
 * the target annotation.
 *
 * @returns A set of CreateAnnotationParams that can be used to create a
 * new annotation.
 */
const getDeepCloneCreateAnnotationParams = (
  targetAnnotation: Annotation,
  params?: Partial<CreateAnnotationParams>,
): CreateAnnotationParams => {
  const createAnnotationParams = getShallowCloneCreateAnnotationParams(targetAnnotation, params)

  createAnnotationParams.data = cloneDeep((params && params.data) || targetAnnotation.data)
  createAnnotationParams.id = uuidv4()

  return createAnnotationParams
}

/**
 * Method that will return a shallow-cloned annotation
 * Shallow cloned annotation will copy all the instance parameters in addition to params
 *
 * @param params Instance params that will be added to the new annotation
 */
const shallowCloneAnnotation = (
  targetAnnotation: Annotation,
  params?: CloneAnnotationParams,
): Annotation => {
  const createAnnotationParams = getShallowCloneCreateAnnotationParams(targetAnnotation, params)

  const annotation = createAnnotationFromInstanceParams(createAnnotationParams)

  if (annotation === null) {
    throw new Error('Annotation creation failed')
  }

  clonePath2D(targetAnnotation.id, annotation.id)

  return annotation
}

/**
 * Method that will return a deep-cloned annotation
 * Deep cloned annotation will copy all the parameters and assign a new id
 * Annotation Data will be deeply cloned and subAnnotations will be newly created
 *
 * @param params Instance params that will be added to the new annotation
 */
const cloneAnnotation = (
  targetAnnotation: Annotation,
  params?: CloneAnnotationParams,
): Annotation => {
  const createAnnotationParams = getDeepCloneCreateAnnotationParams(targetAnnotation, params)

  const annotation = createAnnotationFromInstanceParams(createAnnotationParams)

  if (annotation === null) {
    throw new Error('Annotation creation failed')
  }

  clonePath2D(targetAnnotation.id, annotation.id)

  return annotation
}

export { cloneAnnotation, shallowCloneAnnotation }
