import { useFeatureFlagsStore } from '@/pinia/useFeatureFlagsStore'
import type { FeatureName } from '@/store/types'

/**
 * Composable hook used for dealing with feature flags.
 *
 * @deprecated Use `useFeatureFlagsStore` instead.
 */
export const useFeatureFlags = (): {
  /**
   * @deprecated Use `useFeatureFlagsStore` instead.
   */
  featureEnabled: (feature: FeatureName) => boolean
} => {
  const store = useFeatureFlagsStore()

  return {
    featureEnabled: (feature: FeatureName): boolean => store.featureFlags[feature],
  }
}
