import { createEditablePoint } from '@/modules/Editor/point'
import { addVertexAction } from '@/modules/Editor/actions'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'
import type { PolylineTool } from '@/modules/Editor/plugins/polyline/tool'
import type { AddingPointContext } from '@/modules/Editor/plugins/polyline/types'

/**
 * Inserts new vertex into existing polyline and persists to backend
 * @param tool The instance of the polyline tool
 * @param context The provided tool context
 */
export const insertPoint = (
  tool: PolylineTool & AddingPointContext,
  context: ToolContext,
): void => {
  const { pointOnLine, pointOnLineAnnotation, pointOnLinePosition, pointOnLinePath } = tool
  const imagePoint = context.editor.activeView.camera.canvasViewToImageView(pointOnLine)
  const newVertex = createEditablePoint(imagePoint)

  tool.actionGroup = tool.actionGroup || context.editor.actionManager.createGroup()

  const action = addVertexAction(
    context.editor.activeView,
    pointOnLineAnnotation,
    pointOnLinePath,
    pointOnLinePosition,
    newVertex,
  )

  tool.actionGroup.do(action)
}
