import { WindAuthAction } from '@/backend/darwin/WindAuthAction'
import type { ApiResult } from '@/backend/darwin/types'
import { errorMessages } from '@/backend/error'

import { withAuth, withoutAuth } from './api'
import type { ModelTemplatePayload } from './types'

type Params = { teamId?: number }

export const loadPublishedModelTemplates = (
  payload: Params,
): Promise<ApiResult<ModelTemplatePayload[]>> => {
  const { teamId } = payload
  const params = {}
  const path = 'model_templates?published'

  if (!teamId) {
    return withoutAuth<ModelTemplatePayload[]>(
      (client) => client.get<ModelTemplatePayload[]>(path, { params }),
      errorMessages.NEURAL_MODEL_DATA,
    )
  }

  const authParams = { action: WindAuthAction.ViewModels, teamId }

  return withAuth<ModelTemplatePayload[]>(
    authParams,
    (client) => client.get<ModelTemplatePayload[]>(path, { params }),
    errorMessages.NEURAL_MODEL_DATA,
  )
}
