import type { Range } from '@/modules/Editor/types'
import type { RasterBufferAccessor } from '@/modules/Editor/models/raster/RasterBufferAccessor'
import type { VoxelRaster } from '@/modules/Editor/models/raster/VoxelRaster'
import { Plane } from '@/modules/Editor/utils/raster/Plane'

/**
 * Invalidates a 3D volume in the voxel raster based on the given range and frames range.
 * It accepts only 1 frame index for startFrameIndex, in that case it's considered a single
 * frame invalidation, so the endFrameIndex is set to the startFrameIndex.
 *
 * @param {VoxelRaster} voxelRaster - The voxel raster to invalidate.
 * @param {RasterBufferAccessor} rasterBufferAccessor - The raster buffer accessor.
 * @param {number[]} frameIndex - The range of frames to invalidate. If one is passed, it's
 * considered a single frame invalidation.
 * @param {Range} range - The range of the 3D volume to invalidate.
 * @param {number} [depth] - The depth of the invalidation.
 *
 * @throws {Error} If the plane is not supported.
 *
 * @returns {void}
 */
export const invalidate3D = (
  voxelRaster: VoxelRaster,
  rasterBufferAccessor: RasterBufferAccessor,
  frameIndex: number,
  range: Range,
  depth = 0,
): void => {
  const { minX, maxX, minY, maxY } = range
  const halfDepth = Math.floor(depth / 2)

  const { plane } = rasterBufferAccessor

  if (plane === Plane.X) {
    voxelRaster.invalidate3D({
      minX: frameIndex - halfDepth,
      maxX: frameIndex + halfDepth,
      minY: minX,
      maxY: maxX,
      minZ: minY,
      maxZ: maxY,
    })
  } else if (plane === Plane.Y) {
    voxelRaster.invalidate3D({
      minX,
      maxX,
      minY: frameIndex - halfDepth,
      maxY: frameIndex + halfDepth,
      minZ: minY,
      maxZ: maxY,
    })
  } else if (plane === Plane.Z) {
    voxelRaster.invalidate3D({
      minX,
      maxX,
      minY,
      maxY,
      minZ: frameIndex - halfDepth,
      maxZ: frameIndex + halfDepth,
    })
  } else {
    throw new Error('Currently only have support for MPR view for VoxelRaster')
  }
}
