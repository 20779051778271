import type { Keypoint } from '@/modules/Editor/AnnotationData'

import type { LinearInterpolationParams } from './types'

export const keypointInterpolation = (
  params: LinearInterpolationParams,
  prevData: Keypoint,
  nextData: Keypoint,
): Keypoint => {
  const { algorithm, interpolationFactor } = params

  if (algorithm && !algorithm.startsWith('linear')) {
    throw new Error(`Interpolate: keypoints don't support '${algorithm}' interpolation algorithm`)
  }

  return {
    x: prevData.x + (nextData.x - prevData.x) * interpolationFactor,
    y: prevData.y + (nextData.y - prevData.y) * interpolationFactor,
  }
}
