import { FramesLoaderEvents } from '@/modules/Editor/eventBus'
import { toWorker } from '@/modules/Editor/utils/workers/toWorker'

import type { Frame, FramesLoaderConfig } from './types'
import { Actions } from './types'
import Worker from './worker?worker'

type OnFrameLoadedEvent = {
  data: { index: number; frameObjectURL: string; isHQ: boolean; type: Actions.onFrameLoaded }
}
type OnGetSectionEvent = { data: { index: number; type: Actions.onGetSection } }

/**
 * Dispatcher to the promise worker in charge of loading frames.
 * Provides a public interface to work with the frames loader
 */
export class FramesLoaderWorker {
  private worker: Worker | null
  private id: string
  private toWorker: ReturnType<typeof toWorker> | null = null

  constructor(id: string, framesLoaderConfig?: FramesLoaderConfig) {
    this.id = id
    this.worker = new Worker()

    this.toWorker = toWorker(this.worker)

    if (framesLoaderConfig) {
      this.toWorker.postMessage<void>(Actions.setConfig, { config: framesLoaderConfig })
    }

    this.worker?.addEventListener('message', (event: OnFrameLoadedEvent | OnGetSectionEvent) => {
      if (event.data.type === Actions.onGetSection) {
        FramesLoaderEvents.getSection.emit({ index: event.data.index })
      } else if (event.data.type === Actions.onFrameLoaded) {
        FramesLoaderEvents.frameLoaded.emit({
          index: event.data.index,
          url: event.data.frameObjectURL,
          isHQ: event.data.isHQ,
          id: this.id,
        })
      }
    })

    FramesLoaderEvents.frameInvalid.on(this.onFrameInvalid)
  }

  onFrameInvalid = ({ index }: { index: number }): void => {
    this.toWorker?.postMessage<void>(Actions.setFrameInvalid, { index })
  }

  pushSections(sections: Frame[]): Promise<void> {
    if (!this.toWorker) {
      return Promise.resolve()
    }
    return this.toWorker.postMessage<void>(Actions.pushSections, { sections })
  }

  async loadLQFrame(index: number): Promise<string> {
    const res = await this.toWorker?.postMessage<string>(Actions.loadLQFrame, { index })
    return res || ''
  }

  async loadHQFrame(index: number): Promise<string> {
    const res = await this.toWorker?.postMessage<string>(Actions.loadHQFrame, { index })
    return res || ''
  }

  setFramesToLoad(framesIndexes: number[]): Promise<void> {
    if (!this.toWorker) {
      return Promise.reject('there is no toWorker setup for FramesLoader')
    }
    return this.toWorker.postMessage<void>(Actions.setFramesToLoad, { framesIndexes })
  }

  setNextFrameToLoad(index: number): Promise<string | undefined> {
    if (!this.toWorker) {
      return Promise.reject('there is no toWorker setup for FramesLoader')
    }
    return this.toWorker.postMessage<string | undefined>(Actions.setNextFrameToLoad, { index })
  }

  cleanup(): void {
    this.toWorker?.postMessage<void>(Actions.cleanup)
    this.toWorker?.cleanup()
    this.toWorker = null
    this.worker?.terminate()
    this.worker = null
    FramesLoaderEvents.frameInvalid.off(this.onFrameInvalid)
  }
}
