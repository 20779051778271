import type { IPoint } from '@/modules/Editor/point'
import type { Rectangle } from '@/modules/Editor/rectangle'

export const drawOverlay = (
  ctx: CanvasRenderingContext2D,
  { width, height }: { width: number; height: number },
  canvasRectangle?: Rectangle,
): void => {
  ctx.beginPath()

  // Filled outer shape
  ctx.moveTo(0, 0)
  ctx.lineTo(width, 0)
  ctx.lineTo(width, height)
  ctx.lineTo(0, height)
  ctx.closePath()

  if (canvasRectangle) {
    // Empty rectangle inner shape
    ctx.moveTo(canvasRectangle.left, canvasRectangle.top)
    ctx.lineTo(canvasRectangle.left, canvasRectangle.bottom)
    ctx.lineTo(canvasRectangle.right, canvasRectangle.bottom)
    ctx.lineTo(canvasRectangle.right, canvasRectangle.top)
    ctx.lineTo(canvasRectangle.left, canvasRectangle.top)
    ctx.closePath()
  }

  ctx.fillStyle = 'rgba(0, 0, 0, 0.25)'
  ctx.fill()
}

export const drawClick = (
  ctx: CanvasRenderingContext2D,
  point: IPoint,
  radius: number,
  color: string,
): void => {
  // Draw external circle
  ctx.beginPath()
  ctx.arc(point.x, point.y, radius + 1, 0, 2 * Math.PI)
  ctx.fillStyle = 'white'
  ctx.fill()
  ctx.closePath()
  // Draw count circle
  ctx.beginPath()
  ctx.arc(point.x, point.y, radius, 0, 2 * Math.PI)
  ctx.fillStyle = color
  ctx.fill()
  ctx.closePath()
}

export const drawPendingClick = (
  ctx: CanvasRenderingContext2D,
  point: IPoint,
  radius: number,
  color: string,
): void => {
  // Draw external circle
  ctx.beginPath()
  ctx.arc(point.x, point.y, radius + 1, 0, 2 * Math.PI)
  ctx.fillStyle = 'yellow'
  ctx.fill()
  ctx.closePath()
  // Draw count circle
  ctx.beginPath()
  ctx.arc(point.x, point.y, radius, 0, 2 * Math.PI)
  ctx.fillStyle = color
  ctx.fill()
  ctx.closePath()
}
