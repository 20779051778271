import type { EyeNodeName, FrameData, PropertiesData, SequenceData } from '@/core/annotations'
import type { PartialRecord } from '@/core/helperTypes'
import type { InterpolationAlgorithm } from '@/modules/Editor/annotationInterpolation/types'
import type { InferenceMetadata } from '@/modules/Editor/backend'

import type { AnnotationMeasure } from './MeasureOverlayData'
import type { EditablePoint } from './point'
/**
 * Structure for the auto_annotate "meta" sub-annotation data
 *
 * This is what gets stored under
 * - `annotation.data.auto_annotate` for image annotations
 * - `annotation.data.frames[index].auto_annotate` for video annotations
 *
 * When the main annotation was created using clicker.
 */
export type AutoAnnotateData = NonNullable<FrameData['auto_annotate']>

/**
 * Structure for the bounding_box main annotation data
 *
 * This is what gets stored under
 * - `annotation.data.bounding_box` for image annotations
 * - `annotation.data.frames[index].bounding_box` for video annotations
 *
 * When the main annotation is of type bounding_box
 */
export type BoundingBoxData = {
  x: number
  y: number
  w: number
  h: number
}

/**
 * Structure for the skeleton main annotation data
 *
 * This is what gets stored under
 * - `annotation.data.skeleton` for image annotations
 * - `annotation.data.frames[index].skeleton` for video annotations
 *
 * When the main annotation is of type skeleton
 */
export type SkeletonData = {
  nodes: {
    x: number
    y: number
    name: string
    occluded: boolean
  }[]
}

/**
 * Structure for the eye main annotation data
 *
 * This is what gets stored under
 * - `annotation.data.eye` for image annotations
 * - `annotation.data.frames[index].eye` for video annotations
 *
 * When the main annotation is of type eye
 */
export type EyeData = {
  nodes: {
    x: number
    y: number
    name: EyeNodeName
    occluded: boolean
  }[]
}

/**
 * Structure of the backend-stored data payload for an image annotation
 *
 * Should be expanded with additional type data as we have time for it.
 *
 * Note: This list will get longer and longer. We should have a base type which is
 * then extended for each annotation on the front end, we can then type to correct derived
 * types as needed in the code, which plugins can more concretely define.
 *
 * @deprecated - this is a broken data type, since it contains [x: string]: any
 */
export type AnnotationData = {
  auto_annotate?: AutoAnnotateData // eslint-disable camelcase
  inference?: InferenceMetadata
  rasterId?: string

  properties?: PropertiesData

  // the remainder here should be eliminated. they are wrong data in the type

  hidden_areas?: [number, number][]

  // backend payload type keys
  polygon?: FrameData['polygon']
  tag?: FrameData['tag']

  // wrongfully accessed keys from the video payload
  // these should be fixed by using the isVideoAnnotation type guard
  keyframe?: boolean
  frames?: VideoAnnotationData['frames'] | SequenceData['frames']
  sub_frames?: VideoAnnotationData['sub_frames']
  segments?: VideoAnnotationData['segments']

  // these should be eliminated by using the correct type guards and only dealing with pure,
  // strictly typed data like Polygon, Polyline, etc.
  additionalPaths?: Polygon['additionalPaths']
  path?: Polygon['path']

  confidence?: InferenceMetadata['confidence']
  model?: InferenceMetadata['model']
} & Partial<
  // these should stay as is, but not wrapped in a partial
  | AutoAnnotateData
  | Attributes
  | BoundingBox
  | DirectionalVector
  | Ellipse
  | Eye
  | InstanceID
  | Keypoint
  | Measures
  | Polygon
  | Polyline
  | SimpleTable
  | Skeleton
  | Text
>

/**
 * Data structure for the annotation's polygon data.
 * Right now the Annotation class uses serializer to convert API data to the format it expects.
 * We're working on a replacement to have no Annotation class at all and use API data directly.
 */
export type LegacyPolygonData = {
  path: EditablePoint[]
  additionalPaths?: EditablePoint[][]
}

export const isLegacyPolygonData = (obj: object): obj is LegacyPolygonData =>
  'additionalPaths' in obj

// This is still too messy to remove any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SubAnnotationData = AutoAnnotateData | PropertiesData | any

/**
 * If end index in segment is null it means last index
 */
export type VideoAnnotationDataSegment = [number, number | null]

/** Structure of the backend-stored data payload for a video annotation */
export type VideoAnnotationData = {
  frames: { [frame: string]: AnnotationData & { loaded?: boolean } }
  sub_frames: { [frame: string]: AnnotationData }
  global_sub_types?: SequenceData['global_sub_types']
  /**
   * "segments" will be undefined when it's a "global" tag annotation
   */
  segments: VideoAnnotationDataSegment[] | undefined
  hidden_areas?: [number, number][] | undefined
  interpolated: boolean
  interpolate_algorithm: InterpolationAlgorithm
}

export type AnnotationPropertiesData = PartialRecord<string, string[]>

/**
 * The shape of the data field of a sub-annotation in editor,
 * when the type is "attributes". External annotations normalize into this.
 *
 * REFACTOR: It actually looks like we completely corcumvent subannotation serialization,
 * so usage of this might be wrong.
 */
export type Attributes = {
  attributes: string[]
}

/**
 * The shape of the data field of an annotation in editor,
 * when the type is "bounding_box". External annotations normalize into this.
 *
 * REFACTOR: Technically should not extend AnnotationData, but due to bad typing, it does.
 */
export type BoundingBox = {
  topLeft: EditablePoint
  topRight: EditablePoint
  bottomRight: EditablePoint
  bottomLeft: EditablePoint

  inference?: FrameData['inference']
}

/**
 * The shape of the data field of a sub-annotation in editor,
 * when the type is "directional_vector". External annotations normalize into this.
 *
 * REFACTOR: It actually looks like we completely corcumvent subannotation serialization,
 * so usage of this might be wrong.
 */
export type DirectionalVector = {
  angle: number
  length: number
}

/**
 * The shape of the data field of an annotation in editor,
 * when the type is "ellipse". External annotations normalize into this.
 */
export type Ellipse = {
  center: EditablePoint
  right: EditablePoint
  top: EditablePoint
  bottom: EditablePoint
  left: EditablePoint
}

/**
 * The shape of the data field of a sub-annotation in editor,
 * when the type is "instance_id". External annotations normalize into this.
 *
 * REFACTOR: It actually looks like we completely corcumvent subannotation serialization,
 * so usage of this might be wrong.
 */
export type InstanceID = {
  value: number
}

/**
 * The shape of the data field of an annotation in editor,
 * when the type is "keypoint". External annotations normalize into this.
 */
export type Keypoint = {
  x: number
  y: number
}

/**
 * The partial shape of the data field of an annotation in editor, when it
 * contains measure data. External measure data normalizes into this.
 *
 * This structure is merged with the rest of the data normalized data field and
 * is not a type of it's own.
 * when the type is "bounding_box". External annotations normalize into this.
 */

export type Measures = {
  measures: AnnotationMeasure
}

/**
 * The shape of the data field of an annotation in editor,
 * when the type is "polygon". External annotations normalize into this.
 */
export type Polygon = {
  path: EditablePoint[]
  additionalPaths?: EditablePoint[][]
  /** TODO: We seem to be assigning to this in serializer.deserialize, but not actually using it */
  inference?: FrameData['inference']
  /** TODO: We seem to be assigning to this in serializer.deserialize, but not actually using it */
  auto_annotate?: AutoAnnotateData
}

/**
 * The shape of the data field of an annotation in editor,
 * when the type is "line". External annotations normalize into this.
 */
export type Polyline = {
  path: EditablePoint[]
}

/**
 * The shape of the data field of an annotation in editor,
 * when the type is "simple_table". External annotations normalize into this.
 */
export type SimpleTable = {
  boundingBox: BoundingBox
  rowOffsets: number[]
  colOffsets: number[]
}

/**
 * The shape of the data field of an annotation in editor,
 * when the type is "skeleton". External annotations normalize into this.
 */
export type Skeleton = {
  nodes: {
    name: string
    point: EditablePoint
    occluded: boolean
  }[]
}

/**
 * The shape of the data field of an annotation in editor,
 * when the type is "eye". External annotations normalize into this.
 *
 * The "eye" type is a special case of the "skeleton" type which we've built for
 * a specific important customer.
 */
export type Eye = {
  nodes: {
    name: 'inner' | 'outer' | 'upper' | 'lower'
    point: EditablePoint
    occluded: boolean
  }[]
}

/**
 * The shape of the data field of a sub-annotation in editor,
 * when the type is "text". External annotations normalize into this.
 *
 * REFACTOR: It actually looks like we completely corcumvent subannotation serialization,
 * so usage of this might be wrong.
 */
export type Text = {
  text: string
}
