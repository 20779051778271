import type { Segment } from '@/modules/Editor/polygonOperations'
import type { Range } from '@/modules/Editor/types'

/**
 * A fraction to pad the current edit's bounding box by, which determines when
 * regions of a complex polygon are used in polybooljs combine logic.
 */
const MERGE_PATH_PADDING_FRACTION = 0.01

/**
 * Finds the bounding box of the given path, and pads it by MERGE_PATH_PADDING_FRACTION * 100 %.
 *
 * @param compoundPath The path to find the padded bounding box for.
 *
 * @returns The range of the bounding box.
 */
export const getPaddedRangeOfPath = (compoundPath: Segment): Range => {
  let minX = Infinity
  let maxX = -Infinity
  let minY = Infinity
  let maxY = -Infinity

  const segments = compoundPath.segments

  segments.forEach(({ start, end }) => {
    ;[start, end].forEach(([x, y]) => {
      if (x < minX) {
        minX = x
      }
      if (x > maxX) {
        maxX = x
      }
      if (y < minY) {
        minY = y
      }
      if (y > maxY) {
        maxY = y
      }
    })
  })

  const widthBuffer = (maxX - minX) * MERGE_PATH_PADDING_FRACTION
  const heightBuffer = (maxY - minY) * MERGE_PATH_PADDING_FRACTION

  return {
    minX: minX - widthBuffer,
    maxX: maxX + widthBuffer,
    minY: minY - heightBuffer,
    maxY: maxY + heightBuffer,
  }
}
