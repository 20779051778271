import { markRaw } from 'vue'

import { LimitedArray } from '@/modules/Editor/limitedArray'
import { ActionManagerEvents } from '@/modules/Editor/eventBus'

export interface Action {
  id?: string
  do(): Promise<boolean> | boolean
  undo(): Promise<boolean> | boolean
}

export interface ActionGroup {
  do(action: Action): void
  canUndo: boolean
  clear(): void
  remove(): void
}

export enum Events {
  DONE_ACTIONS_CHANGED = 'doneActions:changed',
  UNDONE_ACTIONS_CHANGED = 'undoneActions:changed',
  ACTION = 'action',
}

const HISTORY_SIZE = 50

export class ActionManager {
  private doneActions = markRaw(new LimitedArray<Action>(HISTORY_SIZE))
  private undoneActions = markRaw(new LimitedArray<Action>(HISTORY_SIZE))

  /**
   * Performs an action which can later be undone (and then redone)
   * @param action The action to be performed and pushed into the history stack
   * @returns Success status of this action, if failed, no need to push into the history stack
   */
  async do(action: Action): Promise<void> {
    const success = await action.do()
    if (!success) {
      return
    }
    this.doneActions.push(action)
    ActionManagerEvents.doneActionsChanged.emit(null)
    this.undoneActions.clear()
    ActionManagerEvents.undoneActionsChanged.emit(null)
    ActionManagerEvents.action.emit(null)
  }

  /**
   * Adds an action to the history without executing it.
   * @param action The action to be stored
   */
  done(action: Action): void {
    this.doneActions.push(action)
    ActionManagerEvents.doneActionsChanged.emit(null)
    this.undoneActions.clear()
    ActionManagerEvents.undoneActionsChanged.emit(null)
  }

  /**
   * Undoes the latest performed action
   * No op if there are no actions to be undone.
   * @returns Success status of this undo action, if failed, no need to push into the history stack
   */
  async undo(): Promise<void> {
    const lastAction = this.doneActions.pop()
    ActionManagerEvents.doneActionsChanged.emit(null)
    if (lastAction) {
      const success = await lastAction.undo()
      if (!success) {
        return
      }
      this.undoneActions.push(lastAction)
      ActionManagerEvents.undoneActionsChanged.emit(null)
    }
    ActionManagerEvents.action.emit(null)
  }

  /**
   * Redoes a previously [[undo]]ed action
   */
  async redo(): Promise<void> {
    const lastAction = this.undoneActions.pop()
    ActionManagerEvents.undoneActionsChanged.emit(null)
    if (lastAction) {
      await lastAction.do()
      this.doneActions.push(lastAction)
      ActionManagerEvents.doneActionsChanged.emit(null)
    }
    ActionManagerEvents.action.emit(null)
  }

  /**
   * Empties lists of done actions and undone actions
   */
  clear(): void {
    this.doneActions.clear()
    ActionManagerEvents.doneActionsChanged.emit(null)
    this.undoneActions.clear()
    ActionManagerEvents.undoneActionsChanged.emit(null)
  }

  get undoSize(): number {
    return this.doneActions.length
  }

  get redoSize(): number {
    return this.undoneActions.length
  }

  get canUndo(): boolean {
    return this.undoSize > 0
  }

  get canRedo(): boolean {
    return this.redoSize > 0
  }

  /**
   * Creates an [[ActionGroup]], useful when one or more actions are only undoable for
   * a short duration, e.g. adding each point in a Polygon. When the group is no
   * longer needed: call [[remove]] to remove all its actions from the actionManager.
   */
  createGroup(id?: string): ActionGroup {
    const groupId = id || Math.random().toString(36).substr(2, 9)
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const manager = this

    return {
      async do(action: Action): Promise<void> {
        await manager.do({ ...action, id })
      },
      remove(): void {
        manager.remove(groupId)
      },
      /**
       * Clear the history of the action group.
       */
      clear(): void {
        manager.clear()
      },
      get canUndo(): boolean {
        return manager.doneActions.findIndex((action) => action.id === id) > -1
      },
    }
  }

  /**
   * Entirely remove an action from both the done/undone action history
   *
   * example: temporary SAM point are undoable/redoable while you're shaping
   * the temporary mask, but should be remove from the action history once
   * the annotation has been created.
   */
  public remove(id: string): void {
    this.removeFromDoneActions(id)
    this.removeFromUndoneActions(id)
  }

  private removeFromDoneActions(id: string): void {
    this.doneActions = this.doneActions.filter((action) => !action.id || action.id !== id)
    ActionManagerEvents.doneActionsChanged.emit(null)
  }

  private removeFromUndoneActions(id: string): void {
    this.undoneActions = this.undoneActions.filter((action) => !action.id || action.id !== id)
    ActionManagerEvents.undoneActionsChanged.emit(null)
  }
}
