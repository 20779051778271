import { PixelMatrixValues } from './PixelMatrixValues'

export enum Direction {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  UP = 'UP',
  DOWN = 'DOWN',
  DOWN_RIGHT = 'DOWN_RIGHT',
  DOWN_LEFT = 'DOWN_LEFT',
  UP_LEFT = 'UP_LEFT',
  UP_RIGHT = 'UP_RIGHT',
}

const { EDGE } = PixelMatrixValues

export type NextPixelItem = [number, number] | null

/**
 * Generates a pixel path builder, which returns a method for cycling through a binaryMask
 * with an expected circular pixel path on it.
 * @param binaryMask The mask to build the path builder for.
 * @param width The width of the mask.
 *
 * This works by the fact we know the target is vaguely circular. We return a function which:
 * - Given a pixel, search for the next pixel based on the previous direction (initially right).
 * - scan in an arc of the posible 4 directions looking for the next pixel.
 * - Return the next pixel and cache the previous direction.
 *
 * @returns An itterator method which returns the next pixel in the path, until all pixels
 * have been traversed, at which point it returns null.
 */
export const generateCircularPixelPathBuilder = (
  binaryMask: Uint8ClampedArray,
  width: number,
): ((pixel: [number, number]) => NextPixelItem) => {
  let previousDirection = Direction.RIGHT

  const isRight = (pixel: [number, number]): boolean => {
    // Check if right is off binary map, return false if so.
    if (pixel[0] + 1 >= width) {
      return false
    }

    return binaryMask[pixel[1] * width + pixel[0] + 1] === EDGE
  }

  const isLeft = (pixel: [number, number]): boolean => {
    // Check if right is off binary map, return false if so.
    if (pixel[0] - 1 < 0) {
      return false
    }

    const index = pixel[1] * width + pixel[0] - 1

    return binaryMask[index] === 2
  }

  const isDown = (pixel: [number, number]): boolean => {
    // Check if right is off binary map, return false if so.
    if (pixel[1] - 1 < 0) {
      return false
    }

    return binaryMask[(pixel[1] - 1) * width + pixel[0]] === EDGE
  }

  const isUp = (pixel: [number, number]): boolean => {
    // Check if right is off binary map, return false if so.
    if (pixel[1] + 1 >= width) {
      return false
    }

    return binaryMask[(pixel[1] + 1) * width + pixel[0]] === EDGE
  }

  const isDownRight = (pixel: [number, number]): boolean => {
    // Check if right is off binary map, return false if so.
    if (pixel[0] + 1 >= width || pixel[1] - 1 < 0) {
      return false
    }

    return binaryMask[(pixel[1] - 1) * width + pixel[0] + 1] === EDGE
  }

  const isDownLeft = (pixel: [number, number]): boolean => {
    // Check if right is off binary map, return false if so.
    if (pixel[0] - 1 < 0 || pixel[1] - 1 < 0) {
      return false
    }

    return binaryMask[(pixel[1] - 1) * width + pixel[0] - 1] === EDGE
  }

  const isUpRight = (pixel: [number, number]): boolean => {
    // Check if right is off binary map, return false if so.
    if (pixel[0] + 1 >= width || pixel[1] + 1 >= width) {
      return false
    }

    return binaryMask[(pixel[1] + 1) * width + pixel[0] + 1] === EDGE
  }

  const isUpLeft = (pixel: [number, number]): boolean => {
    // Check if right is off binary map, return false if so.
    if (pixel[0] - 1 >= width || pixel[1] + 1 >= width) {
      return false
    }

    return binaryMask[(pixel[1] + 1) * width + pixel[0] - 1] === EDGE
  }

  const getNextPixelInCircularPath = (pixel: [number, number]): NextPixelItem => {
    if (previousDirection === Direction.DOWN) {
      // Check: DOWN_LEFT, DOWN, DOWN_RIGHT, RIGHT
      if (isDownLeft(pixel)) {
        previousDirection = Direction.DOWN_LEFT
        return [pixel[0] - 1, pixel[1] - 1]
      }
      if (isDown(pixel)) {
        previousDirection = Direction.DOWN
        return [pixel[0], pixel[1] - 1]
      }
      if (isDownRight(pixel)) {
        previousDirection = Direction.DOWN_RIGHT
        return [pixel[0] + 1, pixel[1] - 1]
      }
      if (isRight(pixel)) {
        previousDirection = Direction.RIGHT
        return [pixel[0] + 1, pixel[1]]
      }
      return null
    }

    if (previousDirection === Direction.DOWN_RIGHT) {
      // Check: DOWN, DOWN_RIGHT, RIGHT, UP_RIGHT
      if (isDown(pixel)) {
        previousDirection = Direction.DOWN
        return [pixel[0], pixel[1] - 1]
      }
      if (isDownRight(pixel)) {
        previousDirection = Direction.DOWN_RIGHT
        return [pixel[0] + 1, pixel[1] - 1]
      }
      if (isRight(pixel)) {
        previousDirection = Direction.RIGHT
        return [pixel[0] + 1, pixel[1]]
      }
      if (isUpRight(pixel)) {
        previousDirection = Direction.UP_RIGHT
        return [pixel[0] + 1, pixel[1] + 1]
      }
      return null
    }

    if (previousDirection === Direction.RIGHT) {
      // Check: DOWN_RIGHT, RIGHT, UP_RIGHT, UP
      if (isDownRight(pixel)) {
        previousDirection = Direction.DOWN_RIGHT
        return [pixel[0] + 1, pixel[1] - 1]
      }
      if (isRight(pixel)) {
        previousDirection = Direction.RIGHT
        return [pixel[0] + 1, pixel[1]]
      }
      if (isUpRight(pixel)) {
        previousDirection = Direction.UP_RIGHT
        return [pixel[0] + 1, pixel[1] + 1]
      }
      if (isUp(pixel)) {
        previousDirection = Direction.UP
        return [pixel[0], pixel[1] + 1]
      }
      return null
    }

    if (previousDirection === Direction.UP_RIGHT) {
      // Check: RIGHT, UP_RIGHT, UP, UP_LEFT
      if (isRight(pixel)) {
        previousDirection = Direction.RIGHT
        return [pixel[0] + 1, pixel[1]]
      }
      if (isUpRight(pixel)) {
        previousDirection = Direction.UP_RIGHT
        return [pixel[0] + 1, pixel[1] + 1]
      }
      if (isUp(pixel)) {
        previousDirection = Direction.UP
        return [pixel[0], pixel[1] + 1]
      }
      if (isUpLeft(pixel)) {
        previousDirection = Direction.UP_LEFT
        return [pixel[0] - 1, pixel[1] + 1]
      }
      return null
    }

    if (previousDirection === Direction.UP) {
      // Check: UP_RIGHT, UP, UP_LEFT, LEFT
      if (isUpRight(pixel)) {
        previousDirection = Direction.UP_RIGHT
        return [pixel[0] + 1, pixel[1] + 1]
      }
      if (isUp(pixel)) {
        previousDirection = Direction.UP
        return [pixel[0], pixel[1] + 1]
      }
      if (isUpLeft(pixel)) {
        previousDirection = Direction.UP_LEFT
        return [pixel[0] - 1, pixel[1] + 1]
      }
      if (isLeft(pixel)) {
        previousDirection = Direction.LEFT
        return [pixel[0] - 1, pixel[1]]
      }
      return null
    }

    if (previousDirection === Direction.UP_LEFT) {
      // Check: UP, UP_LEFT, LEFT, DOWN_LEFT
      if (isUp(pixel)) {
        previousDirection = Direction.UP
        return [pixel[0], pixel[1] + 1]
      }
      if (isUpLeft(pixel)) {
        previousDirection = Direction.UP_LEFT
        return [pixel[0] - 1, pixel[1] + 1]
      }
      if (isLeft(pixel)) {
        previousDirection = Direction.LEFT
        return [pixel[0] - 1, pixel[1]]
      }
      if (isDownLeft(pixel)) {
        previousDirection = Direction.DOWN_LEFT
        return [pixel[0] - 1, pixel[1] - 1]
      }
      return null
    }

    if (previousDirection === Direction.LEFT) {
      // Check: UP_LEFT, LEFT, DOWN_LEFT, DOWN
      if (isUpLeft(pixel)) {
        previousDirection = Direction.UP_LEFT
        return [pixel[0] - 1, pixel[1] + 1]
      }
      if (isLeft(pixel)) {
        previousDirection = Direction.LEFT
        return [pixel[0] - 1, pixel[1]]
      }
      if (isDownLeft(pixel)) {
        previousDirection = Direction.DOWN_LEFT
        return [pixel[0] - 1, pixel[1] - 1]
      }
      if (isDown(pixel)) {
        previousDirection = Direction.DOWN
        return [pixel[0], pixel[1] - 1]
      }
      return null
    }

    if (previousDirection === Direction.DOWN_LEFT) {
      // Check: LEFT, DOWN_LEFT, DOWN, DOWN_RIGHT
      if (isLeft(pixel)) {
        previousDirection = Direction.LEFT
        return [pixel[0] - 1, pixel[1]]
      }
      if (isDownLeft(pixel)) {
        previousDirection = Direction.DOWN_LEFT
        return [pixel[0] - 1, pixel[1] - 1]
      }
      if (isDown(pixel)) {
        previousDirection = Direction.DOWN
        return [pixel[0], pixel[1] - 1]
      }
      if (isDownRight(pixel)) {
        previousDirection = Direction.DOWN_RIGHT
        return [pixel[0] + 1, pixel[1] - 1]
      }
      return null
    }

    // Reached end of path.
    throw new Error('Unrecognised direction')
  }

  return getNextPixelInCircularPath
}
