import type { IPoint } from '@/modules/Editor/point'
import type { RenderingContext2D } from '@/modules/Editor/types'

type DrawEllipseProps = {
  fillColor: string
  strokeColor: string
  lineWidth: number
  radius: IPoint
  center: IPoint
  angle: number
}

export const drawEllipse = (
  ctx: RenderingContext2D,
  { fillColor, strokeColor, lineWidth, radius, center, angle }: DrawEllipseProps,
): Path2D => {
  const path2D = new Path2D()

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = strokeColor
  ctx.fillStyle = fillColor

  path2D.ellipse(center.x, center.y, radius.x, radius.y, angle, 0, 2 * Math.PI)

  ctx.stroke(path2D)
  ctx.fill(path2D, 'evenodd')

  return path2D
}
