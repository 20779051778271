import type { components } from '@/backend/darwin/api'

/**
 * Enumerates possible types a dataset item can belong to
 */
export type DatasetItemType = components['schemas']['DatasetsV2.Common.SlotType']

/**
 * Enumerates possible types a dataset item can belong to
 */
export const DatasetItemType: { [T in DatasetItemType]: T } = {
  /**
   * Item is a plain or possibly tiled image
   */
  image: 'image',
  video: 'video',
  pdf: 'pdf',
  /**
   * Item is a .dcm (DICOM) or .nii (NIfTI) file
   */
  dicom: 'dicom',
} as const
