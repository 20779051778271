import type { BillingInfoPayload } from '@/modules/Billing/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useBillingStore = defineStore('billing', () => {
  const billingInfoLoading = ref(false)
  const billingInfo = ref<BillingInfoPayload | null>(null)

  const resetState = (): void => {
    billingInfoLoading.value = false
    billingInfo.value = null
  }

  return {
    billingInfoLoading,
    billingInfo,
    resetState,
  }
})
