import type { RenderingCanvas, RenderingContext2D } from '@/modules/Editor/types'
import type { RenderableItemWithState } from '@/modules/Editor/models/layers/object2D'
import type { CloseViewCanvas } from '@/modules/Editor/models/layers/optimisedLayer/closeViewCanvas/closeViewCanvas'
import type { Box, CanvasFillRule, RenderFilters } from '@/modules/Editor/models/layers/types'

import type { ICachedCanvas } from './ICachedCanvas'
import { CachedCanvas } from './cachedCanvas'
import { CachedCanvasWorkerInterface } from './worker/interface'
import { isSafariBrowser } from '@/core/utils/browser'

/**
 * The controller hides details about canvas usage.
 * It tries to establish the connection between
 * the worker's offscreen canvas, and on fail it fallback
 * to the regular canvas render using the CachedCanvas instance.
 */
export class CachedCanvasController implements ICachedCanvas {
  private _offscreenCanvas: HTMLCanvasElement | null = null
  private offscreen: OffscreenCanvas | null = null
  private cachedCanvasWorker: CachedCanvasWorkerInterface | null = null

  private cachedCanvas: CachedCanvas

  private cachedCanvasScale: number = 1
  private cameraScale: number = 1
  private filters: RenderFilters | null = null

  private renderAnimationFrameID: number = -1

  public get supportsOffscreenCanvas(): boolean {
    const { userAgent } = navigator

    /**
     * Firefox's engine apparently supports OffscreenCanvas,
     * but there are lots of issues that pop up with it on their bug tracker.
     * Its officially supported but notoriously flakey (a lot of issues are closed/resolved,
     * but people are still commenting on these that the Canvas API on offscreen canvas has issues).
     * For that reason we _choose_ to say it isn't supported in Firefox right now,
     * as it breaks our LAYER_V2 renderer if we try and use it.
     *
     * Note: 'Gecko/' should catch all firefox forks that our clients use.
     * Only the Mozila suite uses Gecko as its renderer.
     *
     * The inclusion of the slash in the search is VERY IMPORTANT!
     *
     * Chrome contains the string "Gecko" as it says its renderer is "Like Gecko".
     *  The "/" comes before the Gecko version, and this full string is only found in
     * Firefox based clients.
     */
    if (userAgent.includes('Firefox/') || userAgent.includes('Gecko/')) {
      return false
    }

    /**
     * Currently, we are experiencing some rendering delay when using offsetCanvas with Chrome.
     * For this reason we are disabling it (leaving it on only for Safari) until the issue
     * is resolved.
     *
     * TODO: Re-enable for Chrome, removing this `if`, when this ticket is resolved.
     * [Ticket](https://linear.app/v7labs/issue/DAR-1778/check-offscreencanvas-with-chrome)
     */
    if (!isSafariBrowser()) {
      return false
    }

    return HTMLCanvasElement.prototype.transferControlToOffscreen !== undefined
  }

  constructor() {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) {
      throw new Error("Can't get context of the canvas!")
    }
    this.cachedCanvas = new CachedCanvas(canvas, context)
  }

  /**
   * Initial point of the controller setup.
   * Tries to establish a connection with workers offscreen canvas.
   * On fail fallback to the regular rendering process.
   */
  async connect(
    width = this.cachedCanvas.canvas?.width,
    height = this.cachedCanvas.canvas?.height,
  ): Promise<void> {
    if (!width || !height) {
      return
    }

    this._offscreenCanvas?.remove()

    if (this.supportsOffscreenCanvas) {
      this._offscreenCanvas = document.createElement('canvas')
      this._offscreenCanvas.width = width
      this._offscreenCanvas.height = height

      this.cachedCanvasWorker?.terminate()
      this.cachedCanvasWorker = new CachedCanvasWorkerInterface()
      this.offscreen = this._offscreenCanvas.transferControlToOffscreen()

      window.cancelAnimationFrame(this.renderAnimationFrameID)
      this.render()

      try {
        await this.cachedCanvasWorker.connect(this.offscreen)
        this.setScale(this.cachedCanvasScale)
        this.setCameraScale(this.cameraScale)
        if (this.filters) {
          this.setFilters(this.filters)
        }
      } catch {
        this._offscreenCanvas.remove()
        this._offscreenCanvas = null
        this.cachedCanvasWorker.terminate()
        this.cachedCanvasWorker = null
        this.offscreen = null
      }
    }
  }

  setScale(scale: number): void {
    this.cachedCanvasScale = scale
    this.cachedCanvas?.setScale(this.cachedCanvasScale)
    this.cachedCanvasWorker?.setScale(this.cachedCanvasScale)
  }

  setCameraScale(scale: number): void {
    this.cameraScale = scale
    this.cachedCanvas?.setCameraScale(scale)
    this.cachedCanvasWorker?.setCameraScale(scale)
  }

  setFilters(filters: RenderFilters): void {
    this.filters = filters
    this.cachedCanvas?.setFilters(filters)
    this.cachedCanvasWorker?.setFilters(filters)
  }

  async setCanvasSize(w: number, h: number): Promise<void> {
    const width = w * this.cachedCanvasScale
    const height = h * this.cachedCanvasScale

    this.cachedCanvas.setCanvasSize(width, height)

    if (this.cachedCanvasWorker) {
      // OffscreenCanvas can't change its size after the creation it needs to be re-created.
      await this.connect()
    }
  }

  /**
   * Draw all items in the bounding box.
   * When WebWorker with OffscreenCanvas exists we use OffscreenCanvas as a main canvas.
   * When we don't have the support of the OffscreenCanvas we use regular (main thread) canvas.
   */
  drawItemsInBox(items: RenderableItemWithState[], box: Box): void {
    const performer = this.cachedCanvasWorker || this.cachedCanvas
    // Redraw to rebuild the Path2D instances
    performer.drawItemsInBox(items, box)
  }

  async drawAll(items: RenderableItemWithState[]): Promise<Map<string, Path2D>> {
    if (this.cachedCanvasWorker) {
      // Gonna draw the items using WebWorker
      // As the result you will get image on the offscreenCanvas
      const res = await this.cachedCanvasWorker.drawAll(items)

      return res
    }

    return this.cachedCanvas.drawAll(items)
  }

  insertCloseViewCanvas(closeViewCanvas: CloseViewCanvas): void {
    this.cachedCanvas.insertCloseViewCanvas(closeViewCanvas)
  }

  isPointInStroke(id: RenderableItemWithState['id'], x: number, y: number): Promise<boolean> {
    if (this.cachedCanvasWorker) {
      return this.cachedCanvasWorker.isPointInStroke(id, x, y)
    }

    return this.cachedCanvas.isPointInStroke(id, x, y)
  }

  isPointInPath(
    id: RenderableItemWithState['id'],
    x: number,
    y: number,
    fillRule?: CanvasFillRule,
  ): Promise<boolean> {
    if (this.cachedCanvasWorker) {
      return this.cachedCanvasWorker.isPointInPath(id, x, y, fillRule)
    }

    return this.cachedCanvas.isPointInPath(id, x, y, fillRule)
  }

  get canvas(): HTMLCanvasElement | null {
    if (!(this.cachedCanvas.canvas instanceof HTMLCanvasElement)) {
      return null
    }

    return this.cachedCanvas.canvas
  }

  get context(): RenderingContext2D | null {
    return this.cachedCanvas.context
  }

  clearCanvas(x: number, y: number, w: number, h: number): void {
    this.cachedCanvas.clearCanvas(x, y, w, h)
  }

  insertCanvas(
    image: RenderingCanvas | ImageBitmap,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    this.cachedCanvas.insertCanvas(image, x, y, w, h)
  }

  clearCanvasWithScale(x: number, y: number, w: number, h: number): void {
    this.cachedCanvas.clearCanvasWithScale(x, y, w, h)
  }

  insertCanvasWithScale(
    canvas: RenderingCanvas | ImageBitmap,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    this.cachedCanvas.insertCanvasWithScale(canvas, x, y, w, h)
  }

  cleanup(): void {
    this.cachedCanvas.cleanup()
    this.cachedCanvasWorker?.cleanup()
  }

  destroy(): void {
    window.cancelAnimationFrame(this.renderAnimationFrameID)
    this.cachedCanvasWorker?.terminate()
    this.cachedCanvasWorker = null
    this.filters = null
    this._offscreenCanvas?.remove()
    this._offscreenCanvas = null
    this.offscreen = null
    this.cachedCanvas.destroy()
  }

  /**
   * We run the render loop with Offscreen canvas support only.
   */
  render(): void {
    this.renderAnimationFrameID = requestAnimationFrame(() => {
      this.drawOffscreen()

      this.render()
    })
  }

  /**
   * We can't identify when image was drawn on the Offscreen canvas.
   * To keep CachedCanvas (that we insert in the MainCanvas with transform) updated
   * render offscreen on the cached in the render loop.
   */
  private drawOffscreen(): void {
    if (this._offscreenCanvas) {
      this.cachedCanvas.clearCanvas()
      this.cachedCanvas.insertCanvas(
        this._offscreenCanvas,
        0,
        0,
        this._offscreenCanvas.width,
        this._offscreenCanvas.height,
      )
    }
  }
}
