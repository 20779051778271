import type { ExtendedFileMetadata } from '@/modules/Editor/metadata'
import { MedicalModality } from '@/modules/Editor/MedicalModality'

const WEBGL_MAX_TEXTURE_SIZE = 2048

const isModalityVolumeBased = (metadata: ExtendedFileMetadata): boolean =>
  ![
    MedicalModality.MG, // Mammography
    MedicalModality.ES, // Endoscopy
    MedicalModality.GM, // General Microscopy
    MedicalModality.DM, // Digital Microscopy
    MedicalModality.ECG, // Electrocardiography
    MedicalModality.OP, // Ophthalmic Photography
  ].includes(metadata.modality as MedicalModality)

/** We currently have the following restrictions for vtk.js usage:
 * - files larger than 2048 pixels (in width or height) are not supported
 *   due to WebGL texture size limitations
 * - files with non-greyscale (RGB) colorspace are not supported, as we don't have
 *   the logic on vtk.js side to render them
 * - only files with medical metadata are supported, otherwise vtk.js is
 *   not able to reconstruct the volume
 * - only volume-based modalities are supported as there is no need
 *   for 3D masks/renders with other modalities
 */
export const isFileCompatibleWithVtk = (metadata: ExtendedFileMetadata | undefined): boolean =>
  !!metadata &&
  !!metadata.medical &&
  metadata.medical.is_volume &&
  isModalityVolumeBased(metadata) &&
  metadata.colorspace === 'RG16' &&
  metadata.width <= WEBGL_MAX_TEXTURE_SIZE &&
  metadata.height <= WEBGL_MAX_TEXTURE_SIZE
