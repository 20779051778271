import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
// eslint-disable-next-line boundaries/element-types
import { getRGBAColorHash, rgbaString } from '@/uiKit/colorPalette'

export const getMeasureOverlayColorFromAnnotation = (annotation: Annotation): string => {
  if (!annotation.annotationClass) {
    return rgbaString(getRGBAColorHash(annotation.label), 0.7)
  }
  return rgbaString(annotation.annotationClass.color, 0.7)
}
