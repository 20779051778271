import type { PartialRecord } from '@/core/helperTypes'
import { reportToSentry } from '@/services/sentry'

import {
  combine as combinePolybooljs,
  selectUnion as selectUnionPolybooljs,
  selectDifference as selectDifferencePolybooljs,
} from './polybooljs/index'
import type {
  Combined,
  Segment,
  Polygon,
  GeoJSON,
  InternalSegment,
  MultiPolygon,
} from './polybooljs/types'

export { polygon, segments, polygonFromGeoJSON, polygonToGeoJSON } from './polybooljs/index'

// Item length seems to be actually truncated at slightly less than 16k
// (seen at 16382 for example in some cases), so we reduce this slightly for safety.
export const MAX_ITEM_LENGTH = 16384 - 100

export const generateArgumentsJSON = (paramsAsString: string): PartialRecord<string, string> => {
  const numSegments = Math.ceil(paramsAsString.length / MAX_ITEM_LENGTH)
  const lengthOfLastSegment = paramsAsString.length % MAX_ITEM_LENGTH
  const segments: PartialRecord<string, string> = {}

  let offset = 0

  for (let i = 0; i < numSegments; i++) {
    const length = i === numSegments - 1 ? lengthOfLastSegment : MAX_ITEM_LENGTH

    segments[`jsonSegment${i}`] = paramsAsString.slice(offset, offset + length)

    offset += length
  }

  return segments
}

export const combine = (segment1: Segment, segment2: Segment): Combined => {
  try {
    return combinePolybooljs(segment1, segment2)
  } catch (error) {
    const argumentsAsJSONSegments = generateArgumentsJSON(JSON.stringify({ segment1, segment2 }))

    reportToSentry(error as Error, {
      extra: { type: 'combine', ...argumentsAsJSONSegments },
    })
    throw error
  }
}

export const selectUnion = (combined: Combined): Segment => {
  try {
    return selectUnionPolybooljs(combined)
  } catch (error) {
    const argumentsAsJSONSegments = generateArgumentsJSON(JSON.stringify({ combined }))

    reportToSentry(error as Error, {
      extra: { type: 'selectUnion', ...argumentsAsJSONSegments },
    })
    throw error
  }
}

export const selectDifference = (combined: Combined): Segment => {
  try {
    return selectDifferencePolybooljs(combined)
  } catch (error) {
    const argumentsAsJSONSegments = generateArgumentsJSON(JSON.stringify({ combined }))

    reportToSentry(error as Error, {
      extra: { type: 'selectDifference', ...argumentsAsJSONSegments },
    })
    throw error
  }
}

export type { Segment, Polygon, GeoJSON, InternalSegment, MultiPolygon }
