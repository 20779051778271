export enum ACTIONS {
  CONNECT = 'cached_canvas_worker:connect',
  SET_SCALE = 'cached_canvas_worker:setScale',
  SET_CAMERA_SCALE = 'cached_canvas_worker:setCameraScale',
  SET_FILTERS = 'cached_canvas_worker:setFilters',

  DRAW_ALL = 'cahced_canvas-worker:drawAll',
  DRAW_ITEMS_IN_BOX = 'cahced_canvas-worker:drawItemsInBox',

  IS_POINT_IN_STROKE = 'cahced_canvas-worker:isPointInStroke',
  IS_POINT_IN_PATH = 'cahced_canvas-worker:isPointInPath',

  CLEANUP = 'cahced_canvas-worker:cleanup',
}
