import type { IPoint } from '@/modules/Editor/point'

type Props = {
  x: number
  y: number
  w: number
  h: number
}

/** draws path in the following order - top-left > top-right > bottom-right > bottom-left */
const calcPathFromBBox = ({ x, y, w, h }: Props): IPoint[] => [
  { x, y },
  { x: x + w, y },
  { x: x + w, y: y + h },
  { x, y: y + h },
]

export default calcPathFromBBox
