import type { AnnotationsFramePackage, PrecalculatedStoreType } from './AnnotationsFramePackage'
import { isVideoAnnotationDataPayload } from '@/modules/Editor/models/annotation/annotationKindValidator'
import type { V2AnnotationPayload } from '@/store/types'
import { getAnnotationRange } from '@/core/utils/frames'

export const removeAnnotationFromPackage = (
  precalculatedStore: PrecalculatedStoreType,
  annotationsPackage: AnnotationsFramePackage,
  annotation: V2AnnotationPayload,
  totalFrames: number,
): void => {
  annotationsPackage.storeAnnotations = annotationsPackage.storeAnnotations.filter(
    (a) => a.id !== annotation.id,
  )
  annotationsPackage.editorAnnotations = annotationsPackage.editorAnnotations.filter(
    (a) => a.id !== annotation.id,
  )
  annotationsPackage.orderedAnnotationIds = annotationsPackage.orderedAnnotationIds.filter(
    (id) => id !== annotation.id,
  )
  delete annotationsPackage.annotationsMap[annotation.id]
  annotationsPackage.tagAnnotations = annotationsPackage.tagAnnotations.filter(
    (a) => a.id !== annotation.id,
  )
  annotationsPackage.annotationsRenderData.itemsBBoxMap.delete(annotation.id)
  annotationsPackage.annotationsRenderData.rTreeItems =
    annotationsPackage.annotationsRenderData.rTreeItems.filter((item) => item.id !== annotation.id)
  annotationsPackage.annotationsRenderData.itemsMap.delete(annotation.id)
  annotationsPackage.annotationsRenderData.zIndexesList =
    annotationsPackage.annotationsRenderData.zIndexesList.filter((id) => id !== annotation.id)

  // Clear the update annotation calculated segment since
  // data might be changd and we need to recalculate it
  const range = isVideoAnnotationDataPayload(annotation.data)
    ? getAnnotationRange(annotation.data.segments, totalFrames)
    : { startFrame: 0, endFrame: 1 }

  for (let i = range.startFrame; i <= range.endFrame; i++) {
    const renderableDataForFrame = precalculatedStore.renderableData.get(i)
    renderableDataForFrame?.itemsBBox.delete(annotation.id)
    renderableDataForFrame?.rTreeItems.delete(annotation.id)
    renderableDataForFrame?.renderableItems.delete(annotation.id)
  }
}
