// Based on the following solution
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
// Read the author detailed notes at: https://stackoverflow.com/a/65326844
// https://gist.github.com/eyedean/ce6ab6a5108a1bd19ace64382144b5b0
import type { RawLocation, Route } from 'vue-router'
import Router from 'vue-router'

export type UnknownFunction<RETURN_T = unknown> = (...args: unknown[]) => RETURN_T
export type ErrorHandlerFunction = (e: unknown) => Promise<Route>

let isAugumented = false

const navigationErrorHandler: ErrorHandlerFunction = (err) => {
  if (
    Router.isNavigationFailure(err, Router.NavigationFailureType.redirected) ||
    Router.isNavigationFailure(err, Router.NavigationFailureType.aborted) ||
    Router.isNavigationFailure(err, Router.NavigationFailureType.cancelled) ||
    Router.isNavigationFailure(err, Router.NavigationFailureType.duplicated)
  ) {
    // let's print so we can chase it if needed
    console.warn(err.message)
    // whatever, we are fine
    return Promise.resolve(err.from)
  }
  // rethrow error
  return Promise.reject(err)
}

export function augmentRouter(): void {
  if (isAugumented) {
    return
  }

  isAugumented = true

  const originalPush = Router.prototype.push

  function augmentedPush(location: RawLocation): Promise<Route>

  function augmentedPush(
    location: RawLocation,
    onResolve?: UnknownFunction,
    onReject?: ErrorHandlerFunction,
  ): void

  function augmentedPush(
    this: Router,
    location: RawLocation,
    onResolve?: UnknownFunction,
    onReject?: ErrorHandlerFunction,
  ): void | Promise<Route> {
    const boundOriginalPush = originalPush.bind(this)

    if (onResolve || onReject) {
      return boundOriginalPush(location, onResolve, onReject)
    }

    return boundOriginalPush(location).catch(navigationErrorHandler)
  }
  Router.prototype.push = augmentedPush

  const originalReplace = Router.prototype.replace

  function augmentedReplace(location: RawLocation): Promise<Route>

  function augmentedReplace(
    location: RawLocation,
    onResolve?: UnknownFunction,
    onReject?: ErrorHandlerFunction,
  ): void

  function augmentedReplace(
    this: Router,
    location: RawLocation,
    onResolve?: UnknownFunction,
    onReject?: ErrorHandlerFunction,
  ): void | Promise<Route> {
    const boundOriginalReplace = originalReplace.bind(this)

    if (onResolve || onReject) {
      return boundOriginalReplace(location, onResolve, onReject)
    }

    return boundOriginalReplace(location).catch(navigationErrorHandler)
  }

  Router.prototype.replace = augmentedReplace
}
