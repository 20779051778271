import type { CallbackHandle } from '@/modules/Editor/callbackHandler'
import type { Editor } from '@/modules/Editor/editor'

import { ACTIVATE_DELAY, hasPointerLockSupport } from './consts'
import { getSensitivy } from './helpers/getSensitivity'
import { initWindowLevelState } from './helpers/initWindowLevelState'
import { onMouseMove } from './helpers/onMouseMove'
import type { WindowLevelState } from './types'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useWindowLevelTabInteractions = (editor: Editor) => {
  let windowLevelState: WindowLevelState = initWindowLevelState()
  let handles: CallbackHandle[] = []

  const handleMouseMove = (event: MouseEvent): void => {
    if (!windowLevelState.active) {
      return
    }

    onMouseMove(event, editor.activeView, windowLevelState)
  }

  const activate = (): void => {
    const { activatedTime } = windowLevelState
    // NOTE:
    // Need to apply the delay before activating window_level plugin
    // because the same `Tab` handler exists to move to next/previous annotation.
    // After you press Tab for more than ACTIVATE_DELAY milliseconds,
    // it should activate plugin.
    // Before that, it should activate moving next/previous annotations.
    if (!activatedTime) {
      windowLevelState.activatedTime = new Date()
    } else if (activatedTime.getTime() + ACTIVATE_DELAY <= new Date().getTime()) {
      windowLevelState.active = true

      const { metadata } = editor.activeView.fileManager
      const colorspace = metadata?.colorspace
      const { width, height } = editor.activeView.camera

      windowLevelState.sensitivity = getSensitivy(width, height, colorspace)
      editor.activeView.mainLayer.changed()

      if (!editor.activeView.mainLayer.canvas) {
        return
      }

      if (editor.activeView && hasPointerLockSupport) {
        editor.activeView.mainLayer.canvas.requestPointerLock()
      }

      handles = editor.onMouseMove(handleMouseMove)
    }
  }

  const deactivate = (): void => {
    if (hasPointerLockSupport) {
      document.exitPointerLock()
    }
    windowLevelState = initWindowLevelState()
    editor.activeView.mainLayer.changed()
    handles.forEach((handle) => handle.release())
  }

  return {
    activate,
    deactivate,
  }
}
