import sortBy from 'lodash/sortBy'
import { defineStore } from 'pinia'
import { computed } from 'vue'

import { useStore } from '@/store/useStore'
import type { ModelType } from '@/core/annotations'
import { OWL_VIT_MODEL_NAME } from '@/modules/Editor/autoLabel/constants'
import { useCurrentItemStore } from '@/modules/Workview/useCurrentItemStore'
import type { RGBA } from '@/uiKit/colorPalette'
import type { TrainingClass, Visible } from '@/backend/wind/types'

import { useModelsStore } from '@/modules/Models/useModelsStore'

export enum AutoAnnotatePromptMode {
  HoverAndClick = 'hover_and_click',
  BoundingBox = 'bounding_box',
}

/**
 * Auto Annotate models can come from two sources:
 * - internally hosted models, which are stored in the Vuex store as running sessions
 * - externally hosted models, which are stored in this store as ExternalModelPayloads
 * This type is used to provide a common interface for both types of models.
 */
export type AutoAnnotateModel = {
  id: string
  label: string
  classes: Array<TrainingClass & { color: RGBA }>
  type: ModelType
  /**
   * If visible.dropdown is true, then the user will be able to select this model
   * for inference using the auto-annotate tool.
   */
  visible: Visible
  auto_annotate_prompt_mode: AutoAnnotatePromptMode
  tags?: string[]
  display_name?: string | null
  order?: number | null
}

/**
 * This Pinia store is used to load and select auto-annotate models. Its responsibilities
 * include:
 * - loading auto-annotate models
 * - defining the set of auto-annotate models that can be used for the current dataset item
 * - selecting the auto-annotate model to use for inference
 * - showing the auto-annotate model that is currently selected
 */
export const useAutoAnnotateStore = defineStore('autoAnnotate', () => {
  const vuexStore = useStore()
  const modelsStore = useModelsStore()

  const currentItemStore = useCurrentItemStore()

  /**
   * Load all models that can be used in the workview auto-annotate tool.
   */
  const loadAutoAnnotateModels = (): Promise<void> => modelsStore.loadAvailableModels()

  /**
   * All available auto-annotate models, combining internally hosted models with
   * external models.
   */
  const autoAnnotateModels = computed<AutoAnnotateModel[]>(() => {
    const canShowSAM = !currentItemStore.activeSlot?.isTiled
    return sortBy(
      modelsStore.availableModels.filter(
        ({ label, auto_annotate_prompt_mode }) =>
          canShowSAM ||
          (auto_annotate_prompt_mode === AutoAnnotatePromptMode.BoundingBox &&
            !label.includes(OWL_VIT_MODEL_NAME)),
      ),
      (m) => m.order,
    )
  })

  const preselectedModelId = computed(() => vuexStore.state.workview.preselectedModelId)

  /**
   * Current selected auto-annotate model or null if the current model it's not
   * of type auto-annotate
   */
  const selectedAutoAnnotateModel = computed(
    () => autoAnnotateModels.value.find((m) => m.id === preselectedModelId.value) || null,
  )

  const getAutoAnnotateModelById = (value: string | undefined): AutoAnnotateModel | undefined =>
    autoAnnotateModels.value.find((model) => model.id === value)

  const getAutoAnnotateModelByLabel = (value: string | undefined): AutoAnnotateModel | undefined =>
    autoAnnotateModels.value.find((model) => model.label === value)

  return {
    loadAutoAnnotateModels,
    getAutoAnnotateModelById,
    getAutoAnnotateModelByLabel,
    preselectedModelId,
    selectedAutoAnnotateModel,
    autoAnnotateModels,
  }
})
