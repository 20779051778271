import { buildPath2DImageView } from '@/modules/Editor/graphics/buildPath2DImageView'
import type { IPoint } from '@/modules/Editor/point'
import type { RenderingContext2D } from '@/modules/Editor/types'

type SimpleTableProps = {
  boundingBox: { x: number; y: number; w: number; h: number }
  colOffsets: number[]
  fillColor: string
  lineWidth: number
  path: IPoint[]
  rowOffsets: number[]
  strokeColor: string
}

export function drawSimpleTable(
  ctx: RenderingContext2D,
  {
    boundingBox,
    colOffsets,
    fillColor,
    lineWidth,
    path,
    rowOffsets,
    strokeColor,
  }: SimpleTableProps,
): Path2D {
  const path2D = buildPath2DImageView(path)

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = strokeColor
  ctx.fillStyle = fillColor

  ctx.stroke(path2D)
  ctx.fill(path2D)

  for (let i = 0; i < colOffsets.length; i++) {
    const x = boundingBox.x + colOffsets[i] * boundingBox.w

    ctx.beginPath()
    ctx.moveTo(x, boundingBox.y)
    ctx.lineTo(x, boundingBox.y + boundingBox.h)
    ctx.stroke()
  }

  for (let i = 0; i < rowOffsets.length; i++) {
    const y = boundingBox.y + rowOffsets[i] * boundingBox.h

    ctx.beginPath()
    ctx.moveTo(boundingBox.x, y)
    ctx.lineTo(boundingBox.x + boundingBox.w, y)
    ctx.stroke()
  }

  return path2D
}
