/* globals CanvasImageSource */
import { imageFilterToString } from './imageFilterToString'
import type { Camera } from './camera'
import type { ImageManipulationFilter } from './imageManipulation'

export const renderImageOnCanvas = (
  canvas: HTMLCanvasElement,
  imageData: HTMLImageElement,
  imageTransformedData: CanvasImageSource,
  camera: Camera,
  imageFilter: ImageManipulationFilter,
): boolean => {
  // clicker provide tmp canvas so we need to set its size here.
  canvas.width = camera.width
  canvas.height = camera.height
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return false
  }

  ctx.clearRect(0, 0, camera.width, camera.height)

  ctx.imageSmoothingEnabled = !!imageFilter.isImageSmoothing
  const [dx, dy, dw, dh] = camera.drawImageParams(imageData)
  ctx.filter = imageFilterToString(imageFilter)
  ctx.drawImage(imageTransformedData, dx, dy, dw, dh)
  ctx.filter = 'none'
  ctx.imageSmoothingEnabled = true

  return true
}
