import { toRaw, markRaw } from 'vue'

import type { MainAnnotationType } from '@/core/annotationTypes'
import { isAnnotationMainType } from '@/core/annotationTypes'
import type { FrameData } from '@/core/annotations'
import { hasSomeAnnotationFrameData } from '@/core/annotations'
import {
  interpolateAnnotationFrames,
  supportsInterpolation,
} from '@/modules/Editor/annotationInterpolation'
import { deserializeFrame } from '@/modules/Editor/serialization/deserializeFrame'
import { serializeFrame } from '@/modules/Editor/serialization/serializeFrame'
import { skeletonSerializer } from '@/modules/Editor/serialization/skeletonSerializer'
import type { RenderableItem } from '@/modules/Editor/models/layers/object2D'
import type { SerializerProps } from '@/modules/Editor/serialization/annotationSerializer'
import { serializeAnnotation } from '@/modules/Editor/serialization/annotationSerializer'
import { getFramesToSerialize } from '@/modules/Editor/utils/getFramesToSerialize'

import type { Annotation } from './Annotation'
import { isVideoAnnotation, isVideoAnnotationData } from './annotationKindValidator'
import { isSkeleton } from '@/modules/Editor/annotationTypes/skeleton'

const notSupportedTypes: MainAnnotationType[] = ['tag', 'mask']

const addSubframe = (data: FrameData | null, subframeData: FrameData): FrameData =>
  data ? { ...data, ...subframeData } : subframeData

/**
 * Converts annotation to something OptimisedLayer can render.
 *
 * Note that we pass in the view and optionally the frame index even though it
 * could be read from view. This is because passing in view is hopefully
 * temporary and we can soon simplify
 */
export const annotationToRenderableItem = (
  annotation: Annotation,
  {
    slotName,
    isProcessedAsVideo,
    videoAnnotationDuration,
    frameIndex,
    totalFrames,
  }: SerializerProps,
): RenderableItem | undefined => {
  const type = annotation.type
  if (!isAnnotationMainType(type) || notSupportedTypes.includes(type)) {
    return
  }

  // NOTE: color has Vue reactivity we convert it to raw object
  let color = toRaw(annotation.annotationClass?.color)
  if (!color) {
    return
  }

  // NOTE: to prevent later attached reactivity mark it as raw
  color = markRaw(color)

  const framesToSerialize = isVideoAnnotation(annotation)
    ? getFramesToSerialize(annotation.data.frames, frameIndex)
    : undefined

  const serializedAnnotation = serializeAnnotation(
    annotation,
    {
      slotName,
      isProcessedAsVideo,
      videoAnnotationDuration,
      frameIndex,
      totalFrames,
    },
    {
      framesToSerialize,
    },
  )
  if (!serializedAnnotation) {
    return
  }

  // Because of the clicker tool we should rely on 'segments' only to verify the video annotation
  // For some reason ClickerTool creates a video annotation avoiding 'frames' object and
  // set annotations data directly with additional 'segments' property
  if (isVideoAnnotationData(annotation.data) && annotation.data.segments) {
    if (frameIndex === undefined) {
      return
    }

    /**
     * Will be true if and only if there is a segment that contains the current frame index.
     */
    const annotationIntersectsCurrentFrame: boolean = annotation.data.segments.some(
      ([start, end]: [number, number | null]) =>
        frameIndex >= start && (end === null || frameIndex <= end),
    )
    /*
     * If no segments contain the current frame index, then the annotation
     * isn't visible on the current frame so we shouldn't render it.
     */
    if (!annotationIntersectsCurrentFrame) {
      return
    }

    if (!('frames' in serializedAnnotation.data)) {
      return
    }

    const { frames, sub_frames } = serializedAnnotation.data
    const hasKeyframe = frameIndex in frames
    const hasSubframe = sub_frames && frameIndex in sub_frames

    let data: FrameData | null = null
    if (hasKeyframe) {
      data = frames[frameIndex]

      if (hasSubframe) {
        data = addSubframe(data, sub_frames[frameIndex])
      }

      if (!data) {
        return
      }

      return {
        id: annotation.id,
        type,
        data,
        color,
      }
    }

    // Find the closest keyframe to the left of the current index
    // and the closest keyframe to the right of the current index
    let prevIdx: number | null = null
    let nextIdx: number | null = null

    const sortedFrames = Object.keys(frames)
      .map((idx) => parseInt(idx))
      .sort((a, b) => a - b)

    for (const index of sortedFrames) {
      if (index < frameIndex) {
        prevIdx = index
      }
      if (index > frameIndex) {
        nextIdx = index
        break
      }
    }

    if (prevIdx === null && nextIdx !== null) {
      data = frames[nextIdx]
      if (hasSubframe) {
        data = addSubframe(data, sub_frames[frameIndex])
      }

      return {
        id: annotation.id,
        type,
        data,
        color,
      }
    }

    if (prevIdx !== null && nextIdx === null) {
      data = frames[prevIdx]
      if (hasSubframe) {
        data = addSubframe(data, sub_frames[frameIndex])
      }

      return {
        id: annotation.id,
        type,
        data,
        color,
      }
    }

    if (prevIdx !== null && nextIdx !== null) {
      const prevData = frames[prevIdx]
      const nextData = frames[nextIdx]

      if (!supportsInterpolation(type) || !serializedAnnotation.data.interpolated) {
        data = prevData
        if (hasSubframe) {
          data = addSubframe(data, sub_frames[frameIndex])
        }

        return { id: annotation.id, type, data, color }
      }

      const prevEditorData = deserializeFrame(type, prevData)
      const nextEditorData = deserializeFrame(type, nextData)

      if (!(prevEditorData && nextEditorData)) {
        return
      }

      const interpolatedEditorData = interpolateAnnotationFrames(
        type,
        prevEditorData,
        nextEditorData,
        {
          algorithm: serializedAnnotation.data.interpolate_algorithm || 'linear-1.0',
          interpolationFactor: (frameIndex - prevIdx) / (nextIdx - prevIdx),
        },
      )

      if (!interpolatedEditorData) {
        return
      }

      let interpolatedData =
        type === 'skeleton' && isSkeleton(interpolatedEditorData) && nextData?.skeleton?.edges
          ? // This is a hacky way to pass edges to the serializer, which are at times droped from
            // editor annotation data nad are not available in the interpolation result
            // we pass them in here, to effectively restore them on the annotation
            // result to be rendered. This might get fixed, or it might be eliminated, depending
            // on further cleanup process.
            skeletonSerializer.serialize(interpolatedEditorData, {
              skeleton: { edges: nextData.skeleton.edges },
            })
          : serializeFrame(type, interpolatedEditorData)

      if (hasSubframe) {
        interpolatedData = addSubframe(interpolatedData, sub_frames[frameIndex])
      }

      if (!interpolatedData) {
        return
      }

      return { id: annotation.id, type, data: interpolatedData, color }
    }

    return
  }

  const data = serializedAnnotation.data
  if (!hasSomeAnnotationFrameData(data)) {
    return
  }

  return {
    id: annotation.id,
    type,
    data,
    color,
  }
}
