import type { Action } from '@/modules/Editor/managers/actionManager'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import { assertImageRaster } from '@/modules/Editor/models/raster/assertImageRaster'
import type { Raster, RasterDataSnapshot } from '@/modules/Editor/models/raster/Raster'
import difference from 'lodash/difference'
import { createMaskAnnotation } from '@/modules/Editor/plugins/mask/utils/shared/createMaskAnnotation'
import without from 'lodash/without'
import { updateMaskAnnotation } from '@/modules/Editor/plugins/mask/utils/shared/updateMaskAnnotation'
import {
  assertImageRasterSnapshot,
  assertVideoOrVoxelRasterSnapshot,
} from '@/modules/Editor/models/raster/assertRasterSnapshot'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'
import type { View } from '@/modules/Editor/views/view'
import { deserializeImageRaster } from '@/modules/Editor/models/raster/deserializeImageRaster'
import { deserializeVideoOrVoxelRaster } from '@/modules/Editor/models/raster/deserializeVideoOrVoxelRaster'
import { getOrCreateRasterForView } from '@/modules/Editor/plugins/mask/utils/shared/getOrCreateRasterForView'
import type { SequenceData } from '@/core/annotations'
import { getLabelBounds } from '@/modules/Editor/plugins/mask/utils/shared/getLabelBounds'

type MaskDiff = {
  add: number[]
  remove: number[]
  change: number[]
}

const SKIP_LABEL_INDEX_CORRECTION = false

/**
 * Finds the difference between the old and new annotation ID mappings.
 * When changedLabels is provided, it will only consider those labels for the diff. This is
 * useful for videos, because the mappings include all lavels in the item, but the single frame
 * might have a subset of those labels.
 */
const findLabelDiff = (
  oldMapping: Record<string, number>,
  newMapping: Record<string, number>,
  changedLabels: number[] | undefined,
): MaskDiff => {
  const add = difference(Object.values(newMapping), Object.values(oldMapping))
  const remove = difference(Object.values(oldMapping), Object.values(newMapping))
  const change = without(changedLabels || Object.values(newMapping), ...add, ...remove)
  return { add, remove, change }
}

const applyDiff = async (
  diff: MaskDiff,
  raster: Raster,
  rasterData: RasterDataSnapshot,
  rasterDataBefore: RasterDataSnapshot,
  frameIndexes: number[],
): Promise<void> => {
  for (const labelIndex of diff.add) {
    const annotationClassId = rasterData.labelIndexToClassIdMapping.get(labelIndex)
    if (!annotationClassId) {
      throw new Error('Annotation class id not found for label index')
    }
    const annotationClass = raster.view.editor.getClassById(annotationClassId)

    // We need to pass the bounds only when the restore data refers to a single frame, else
    // we will just compute them for each frame
    const bounds =
      frameIndexes.length === 1 ? getLabelBounds(raster, labelIndex, frameIndexes[0]) : undefined

    // Assume the annotation ID mapping is available
    await createMaskAnnotation({
      view: raster.view,
      raster,
      bounds,
      labelIndex,
      classId: annotationClassId,
      annotationClass,
      frameIndex: frameIndexes[0],
      annotationId: rasterData.labelIndexToAnnotationIdMapping.get(labelIndex),
    })
  }

  diff.remove.forEach((labelIndex) => {
    // remove is firing an operation on an annotation that has been removed already, so we need
    // to use the `rasterDataBefore` to get the mapping
    const annotationId = rasterDataBefore.labelIndexToAnnotationIdMapping.get(labelIndex)
    if (annotationId) {
      const annotation = raster.view.annotationManager.getAnnotation(annotationId)
      if (annotation) {
        raster.view.annotationManager.deleteAnnotation(annotation.id)
      }
    }
  })

  diff.change.forEach((labelIndex) => {
    const annotationId = raster.getAnnotationMapping(labelIndex)
    if (!annotationId) {
      throw new Error('Annotation to update not found for label index')
    }
    const annotation = raster.view.annotationManager.getAnnotation(annotationId)
    if (!annotation) {
      throw new Error('Annotation to update not found for label index')
    }
    const bounds = getLabelBounds(raster, labelIndex, frameIndexes[0])
    updateMaskAnnotation(raster.view, raster, annotation, bounds, frameIndexes)
  })
}

/**
 * Restores the raster data from an action snapshot.
 * The `actionData` is the data we want to restore, while `currentRasterData` is the current state
 * of the raster that we need to change.
 */
export const restoreRasterActionData = async (
  raster: Raster,
  actionData: RasterRestoreActionData,
  currentRasterData: RasterRestoreActionData,
): Promise<void> => {
  const annotationIdMapping = Object.fromEntries(currentRasterData.data.maskAnnotationIdsMapping)
  const newAnnotationIdMapping = Object.fromEntries(actionData.data.maskAnnotationIdsMapping)
  let frameIndex = 0
  const restoredFrameIndexes = new Set<number>()

  if (raster.type === RasterTypes.IMAGE) {
    const imageRaster = assertImageRaster(raster)
    const imageRasterSnapshot = assertImageRasterSnapshot(actionData.data)

    deserializeImageRaster(
      imageRaster,
      {
        mask_annotation_ids_mapping: newAnnotationIdMapping,
        dense_rle: imageRasterSnapshot.denseRLE,
        total_pixels: raster.height * raster.width,
      },
      actionData.annotationIdToClassId,
    )
  }

  if (raster.type === RasterTypes.VIDEO || raster.type === RasterTypes.VOXEL) {
    const videoRaster = assertVideoRaster(raster)
    const videoRasterSnapshot = assertVideoOrVoxelRasterSnapshot(actionData.data)
    const currentVideoRasterSnapshot = assertVideoOrVoxelRasterSnapshot(currentRasterData.data)

    const restoreFrameData: SequenceData['frames'] = {}
    const allRestoreFrameIndexes = new Set<string>([
      ...Object.keys(videoRasterSnapshot.denseRLE),
      ...Object.keys(currentVideoRasterSnapshot.denseRLE),
    ])

    allRestoreFrameIndexes.forEach((frameIndexString) => {
      frameIndex = Number(frameIndexString)
      restoredFrameIndexes.add(frameIndex)
      const frameDenseRLE = videoRasterSnapshot.denseRLE[frameIndex]
      if (!frameDenseRLE || frameDenseRLE[0] === undefined) {
        // No frame data to restore found, is it present now?
        if (currentVideoRasterSnapshot.denseRLE[frameIndex]) {
          // Frame data is present now, but not in the data to restore. Delete it.
          restoreFrameData[frameIndex] = {
            raster_layer: {
              mask_annotation_ids_mapping: {},
              dense_rle: [0, raster.size],
              total_pixels: raster.size,
            },
          }
        }
        return
      }

      restoreFrameData[frameIndex] = {
        raster_layer: {
          mask_annotation_ids_mapping: newAnnotationIdMapping,
          dense_rle: frameDenseRLE,
          total_pixels: raster.height * raster.width,
        },
      }
    })

    deserializeVideoOrVoxelRaster(
      videoRaster,
      restoreFrameData,
      actionData.annotationIdToClassId,
      SKIP_LABEL_INDEX_CORRECTION,
    )
  }

  // If labelsInSnapshots are found (video/voxel) check both snapshots to be sure to capture the difference
  let labelsInSnapshots: number[] | undefined
  if (actionData.data.labelsInSnapshot) {
    const labelsSet = new Set([
      ...actionData.data.labelsInSnapshot,
      ...(currentRasterData.data.labelsInSnapshot || []),
    ])
    labelsInSnapshots = Array.from(labelsSet)
  }

  // Find the difference between the old and new annotation ID mappings
  const labelDiff = findLabelDiff(annotationIdMapping, newAnnotationIdMapping, labelsInSnapshots)

  if (restoredFrameIndexes.size === 0) {
    // In case of images, we need to restore the first frame
    restoredFrameIndexes.add(0)
  }

  // Create/Delete/Update annotations according to the diff
  await applyDiff(
    labelDiff,
    raster,
    actionData.data,
    currentRasterData.data,
    Array.from(restoredFrameIndexes),
  )

  // In a next iteration, we should get the bounds of the changes and only invalidate those
  // TODO: should be improved part of this ticket:
  // https://linear.app/v7labs/issue/DAR-1885/performance-improvement-for-undoredo-action
  raster.invalidateAll()
}

type RasterRestoreActionData = {
  data: RasterDataSnapshot
  annotationIdToClassId: Record<string, number>
}

/**
 * Creates an action that handles undo/redo for raster updates, and can be added to the action
 * manager.
 * It requires a `view` rather than a `raster` because the raster could be undefined before or
 * after the action, so the view allows us the get the current raster and perform actions to it.
 */
export const createUpdateRasterAction = (
  view: View,
  rasterDataCurrent: RasterDataSnapshot,
  rasterDataBefore: RasterDataSnapshot,
): Action => {
  const rasterLayerDataForUndo: RasterRestoreActionData = {
    data: { ...rasterDataBefore },
    annotationIdToClassId: Object.fromEntries(rasterDataBefore.maskAnnotationClassIdsMapping),
  }
  const rasterLayerDataForRedo: RasterRestoreActionData = {
    data: { ...rasterDataCurrent },
    annotationIdToClassId: Object.fromEntries(rasterDataCurrent.maskAnnotationClassIdsMapping),
  }

  const action: Action = {
    do: async (): Promise<boolean> => {
      const raster = getOrCreateRasterForView(view)
      await restoreRasterActionData(raster, rasterLayerDataForRedo, rasterLayerDataForUndo)
      return true
    },
    undo: async (): Promise<boolean> => {
      const raster = getOrCreateRasterForView(view)
      await restoreRasterActionData(raster, rasterLayerDataForUndo, rasterLayerDataForRedo)
      return true
    },
  }
  return action
}
