import type { PartialRecord } from '@/core/helperTypes'
import type { View } from '@/modules/Editor/views/view'
import type { V2AnnotationPayload } from '@/store/types/StageAnnotationPayload'

import { createRasterFromDeserializable } from './createRasterFromDeserializable'
import { isViewsAnnotation } from '@/modules/Workview/isViewsAnnotation'
import { isMaskAnnotationData } from '@/modules/Editor/models/annotation/annotationKindValidator'

const isImageRasterLayerAnnotation = (annotation: V2AnnotationPayload): boolean =>
  'raster_layer' in annotation.data && annotation.data.raster_layer !== undefined

export const isVideoRasterLayerAnnotation = (annotation: V2AnnotationPayload): boolean => {
  if (!('frames' in annotation.data)) {
    return false
  }

  const frameIndicies = Object.keys(annotation.data.frames)
  const firstFrame = annotation.data.frames[frameIndicies[0]] || {}

  if (!('raster_layer' in firstFrame) || firstFrame.raster_layer === undefined) {
    return false
  }

  return true
}

export const isRasterLayerAnnotation = (annotation: V2AnnotationPayload): boolean =>
  isImageRasterLayerAnnotation(annotation) || isVideoRasterLayerAnnotation(annotation)
/**
 * If a raster layer doesn't yet exist on the image, sets up the raster
 * and adds it to the RasterManager.
 *
 * @param rasterLayerStageAnnotations The `raster_layer` annotations for the stage
 * @param maskAnnotations An array of `mask` annotations for the stage.
 * @param boundingBoxPerMaskAnnotationId A container for the derived bounding
 * box information. If the raster layer is created for the first time, these are
 * derived during decoding.
 */
const initialiseRasterIfNotYetInitialised = (
  view: View,
  rasterLayerAnnotations: V2AnnotationPayload[],
  maskAnnotations: PartialRecord<string, V2AnnotationPayload>,
): void => {
  rasterLayerAnnotations.forEach((rasterLayerAnnotation) => {
    createRasterFromDeserializable(view, rasterLayerAnnotation, maskAnnotations)
  })
}

/**
 * Scans through given annotations and creates a mapping of raster masks,
 * while using the already cached mapping on the view to reduce the amount of work needed.
 */
export const resolveRasterMapping = (
  view: View,
  newAnnotations: V2AnnotationPayload[],
  rasterLayers: V2AnnotationPayload[],
): V2AnnotationPayload[] => {
  const regularAndMaskAnnotations: V2AnnotationPayload[] = []
  // We use a map for this as its quicker to consume as each element is looked for once by id.
  const maskAnnotationMap: PartialRecord<string, V2AnnotationPayload> = {}

  newAnnotations.forEach((storeAnnotation) => {
    if (!isViewsAnnotation(view.fileManager.slotName, storeAnnotation)) {
      return
    }

    // Seperate out rasters, and make multiple annotations from these rasters
    if (!isRasterLayerAnnotation(storeAnnotation)) {
      regularAndMaskAnnotations.push(storeAnnotation)

      if (isMaskAnnotationData(storeAnnotation.data)) {
        maskAnnotationMap[storeAnnotation.id] = storeAnnotation
      }
    }
  })

  view.rasterAnnotationLayer.clear()

  initialiseRasterIfNotYetInitialised(
    view,
    rasterLayers.filter((raster) => isViewsAnnotation(view.name, raster)),
    maskAnnotationMap,
  )

  return regularAndMaskAnnotations
}
