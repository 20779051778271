import { computed } from 'vue'

import { useWorkflowInWorkviewStore } from '@/modules/Workview/useWorkflowInWorkviewStore'
import { StageType } from '@/store/types/StageType'
import { useOntology } from '@/modules/Classes/useOntology'
import { defineComposable } from '@/core/utils/defineComposable'
import { useClasses } from '@/modules/Classes/useClasses'

/**
 * This composable is only meant to be used in those components where
 * the classes shown might be restricted by their stage

 * It is not intended to become the classes pinia store.
 * Instead, once we have a classes pinia store, this component will talk to it
 * instead of to vuex
 */

export const useStageSpecificClasses = defineComposable(() => {
  const workflowInworkview = useWorkflowInWorkviewStore()

  const selectedStage = computed(() => workflowInworkview.stage)
  const ontologyStore = useOntology()
  const classStore = useClasses()

  const allowedClassIds = computed(() => {
    if (selectedStage.value?.type !== StageType.Annotate) {
      return []
    }
    const selectedIds = new Set(selectedStage.value?.config.allowed_class_ids ?? [])
    const allowedIds = [] as number[]
    classStore.datasetClasses.forEach((o) => {
      if (selectedIds.has(o.id)) {
        allowedIds.push(o.id)
      }
    })

    return allowedIds
  })

  const allowedPropertyIds = computed(() => {
    if (selectedStage.value?.type !== StageType.Annotate) {
      return []
    }

    const selectedIds = new Set(selectedStage.value?.config.allowed_property_ids ?? [])
    const allowedIds = [] as string[]
    ontologyStore.datasetItemProperties.forEach(
      (o) => selectedIds.has(o.id) && allowedIds.push(o.id),
    )

    return allowedIds
  })

  const allowedClassIdsMap = computed(() =>
    Object.fromEntries(allowedClassIds.value.map((id) => [id, true])),
  )

  const allowedPropertyIdsMap = computed(() =>
    Object.fromEntries(allowedPropertyIds.value.map((id) => [id, true])),
  )

  /**
   * We treat SSP and SSC as a unified feature, so this returns true if
   * either SSP or SSC is applied to the stage.
   */
  const isFilterApplied = computed<boolean>(
    () => !!allowedClassIds.value.length || !!allowedPropertyIds.value.length,
  )

  const stageClasses = computed(() => {
    const classes = classStore.datasetClasses

    return isFilterApplied.value ? classes.filter((o) => allowedClassIdsMap.value[o.id]) : classes
  })

  const stageProperties = computed(() => {
    const properties = ontologyStore.datasetItemProperties

    return isFilterApplied.value
      ? properties.filter((o) => allowedPropertyIdsMap.value[o.id])
      : properties
  })

  return {
    selectedStage,

    isFilterApplied,

    // classes
    allowedClassIds,
    allowedClassIdsMap,
    stageClasses,

    // properties
    allowedPropertyIds,
    allowedPropertyIdsMap,
    stageProperties,
  }
})
