import type { CommentThread } from '@/modules/Editor/iproviders/types'
import type { ApiResponse } from '@/store/types'
import { remove } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

type Params = {
  datasetItemId: string
  teamSlug: string
  threadId: string
}

/**
 * Deletes a comment thread associated to a 2.0 dataset item
 *
 * On the backend, this will also automatically delete v2 comments, so they
 * should be purged from any client store.
 */
export const deleteV2CommentThread = async (
  params: Params,
): Promise<ApiResponse<CommentThread> | ParsedError> => {
  const { datasetItemId, teamSlug, threadId } = params
  const path = `v2/teams/${teamSlug}/items/${datasetItemId}/comment_threads/${threadId}`
  try {
    const response = await remove<CommentThread>(path)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.COMMENT_THREAD_DELETE)
  }
}
