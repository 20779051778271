/**
 * A definition of all possible tool names supported by the editor
 *
 * New tools should always be added to this object, which will automatically
 * include them in the associated type and provide type validation when the
 * new tool is used in editor code.
 *
 * This can be optionally used to avoid needing to specify tool names as "magic"
 * strings, while still allowing it and retaining all the type safety of an enum.
 */
export const ToolName = {
  BoundingBox: 'bounding_box_tool',
  Brush: 'brush_tool',
  Clicker: 'clicker_tool',
  Commentator: 'commentator',
  Crosshairs: 'crosshairs_tool',
  Edit: 'edit_tool',
  Ellipse: 'ellipse_tool',
  Eye: 'eye_tool',
  Keypoint: 'keypoint_tool',
  Polygon: 'polygon_tool',
  Polyline: 'polyline_tool',
  Select: 'select_tool',
  SimpleTable: 'simple_table_tool',
  Skeleton: 'skeleton_tool',
  WindowLevel: 'window_level_tool',
  Zoom: 'zoom_tool',
  SAM: 'sam_tool',
} as const

export type ToolName = (typeof ToolName)[keyof typeof ToolName]

export const isToolName = (name: string): name is ToolName =>
  !!Object.values(ToolName).find((i) => i === name)

/**
 * A definition of all possible subtool names supported by the editor
 *
 * New subtools should always be added to this object, which will automatically
 * include them in the associated type and provide type validation when the
 * new tool is used in editor code.
 *
 * This can be optionally used to avoid needing to specify tool names as "magic"
 * strings, while still allowing it and retaining all the type safety of an enum.
 */
export const SubToolName = {
  AutoAnnotate: 'auto_annotate_tool',
  Attributes: 'attributes_tool',
  DirectionalVector: 'directional_vector_tool',
  Empty: 'empty_tool',
  Text: 'text_tool',
  InstanceID: 'instance_id_tool',
} as const

export type SubToolName = (typeof SubToolName)[keyof typeof SubToolName]

export const isSubToolName = (name: string): name is SubToolName =>
  !!Object.values(SubToolName).find((i) => i === name)

/**
 * ToolInfo has all info the UI need to display the tool
 *
 * Note: We can not use ToolEntry, since it contains context,
 * which contains the engine. This causes a circular dependency that breaks reactivity.
 */
export interface ToolInfo {
  readonly name: ToolName | SubToolName
  readonly active: boolean
}

/**
 * Defines all possible tool options (a tool's subtools).
 *
 * They are currently named (keys) according to the tool they are associated with,
 * but their values do not yet follow this rule and probably should in the long term.
 *
 * The separator options, we should aim to eliminate.
 */
export const ToolOptionId = {
  Brush: 'brush',
  BrushEraser: 'eraser',
  BrushSize: 'brush-size',
  BrushSizeAndThreshold: 'size_and_threshold',
  BrushDimension: 'dimension_switcher',
  BrushTipRound: 'round',
  BrushTipSquared: 'squared',
  PolygonMerge: 'polygon_merge',
  PolygonSubtract: 'polygon_subtract',
  RestoreJoints: 'restore_joints',
  TableCols: 'cols',
  TableRows: 'rows',
} as const

export type FileTypes = 'image' | 'video' | 'pdf' | 'dicom'

export type ToolOptionId = (typeof ToolOptionId)[keyof typeof ToolOptionId]

export type ToolOption = {
  id: ToolOptionId
  active: boolean
  /**
   * Only used by a few tools and really no reason for it to be part of the
   * option declaration, as it's used to provide defaults for the app state.
   */
  props: object
  category?: string
  action: string
}

export type ToolHotkeyEvent = {
  key: string
  altKey: boolean
  ctrlKey: boolean
  shiftKey: boolean
  metaKey: boolean
}

export type Environment = {
  toolName: ToolName | SubToolName
  lastHotkeyPressed: ToolHotkeyEvent | null
  editing: boolean
}

export type ToolEvent = Environment

/** Mask brush dimension - as in spacial dimension: 2D or 3D **/
export const MaskBrushDimension = {
  Paint2D: '2D',
  Paint3D: '3D',
} as const
export type MaskBrushDimension = (typeof MaskBrushDimension)[keyof typeof MaskBrushDimension]

export type MaskBrushThreshold = {
  min: number
  max: number
}

import type { FrameData } from '@/core/annotations'

/**
 * Common interface for all tools.
 */
export type ToolType = {
  name: ToolName
  submit: () => void
  cancel: () => void
  /**
   * Refreshes the tool with the given data.
   */
  reset: () => void
  exitEditMode: () => void
  refresh: (data: unknown) => void
  activate: () => void
  deactivate: () => void
  edit: (frameData: FrameData) => void
  disable?: () => void
  enable?: () => void
  setLoading?: (value: boolean) => void
}

/**
 * Error thrown when a tool refresh is canceled.
 * NOTE: Like second call of refresh before the first one is finished.
 */
export class ToolRefreshCancelError extends Error {
  constructor() {
    super('Tool refresh canceled')
  }
}
