import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { Bounds } from '@/modules/Editor/models/raster/Raster'
import type { VoxelRaster } from '@/modules/Editor/models/raster/VoxelRaster'
import type { Plane } from '@/modules/Editor/utils/raster/Plane'
import type { View } from '@/modules/Editor/views/view'

import { getBoundsOfSegmentOnReformat } from './getBoundsOfSegmentOnReformat'
import { isVideoAnnotationData } from '@/modules/Editor/models/annotation/annotationKindValidator'

/**
 * Updates a mask annotation on a reformatted view. This means:
 * - Adding new "video frames" if the update was drawn onto a new frame
 * of the source data.
 * - Extend the bounds of each frame edited.
 * - Update the annotation in the annotation manager, for all consumers, persistence etc
 * to react to.
 */
export const updateMaskAnnotationOnReformat = (
  primaryView: View,
  voxelRaster: VoxelRaster,
  annotation: Annotation,
  bounds: Bounds,
  reformatFrameIndex: number,
  plane: Plane.X | Plane.Y,
  segmentOffset: number = 0,
): void => {
  const labelIndex = voxelRaster.getLabelIndexForAnnotationId(annotation.id)

  if (labelIndex === undefined) {
    throw new Error('label not found on raster')
  }

  const firstFrame = bounds.topLeft.y
  // Bounds for a pixel filled at index [0, 0] will be:
  // { topLeft: { x: 0, y: 0 }, bottomRight: { x: 1, y: 1 } }
  // So we need to subtract 1 from the bottomRight.y to avoid creating empty frames.
  const lastFrame = Math.min(bounds.bottomRight.y - 1, voxelRaster.depth - 1)
  const xRangeOnReformat: [number, number] = [bounds.topLeft.x, bounds.bottomRight.x]

  const updatedFramesIndices: number[] = []

  const videoAnnotationData = annotation.data

  if (!isVideoAnnotationData(videoAnnotationData)) {
    throw new Error('Annotation data for VOXEL or VIDEO raster is invalid')
  }

  if (!videoAnnotationData.segments) {
    throw new Error('Annotation segments not found on VOXEL or VIDEO raster')
  }

  const segment = videoAnnotationData.segments[0]

  videoAnnotationData.segments[0] = [
    Math.min(segment[0], firstFrame),
    Math.max(segment[1] || -Infinity, lastFrame + 1),
  ]

  // If max is the last keyframe of the mask, extend to full mask length.
  const rasterKeyframes = voxelRaster.rasterKeyframes

  if (rasterKeyframes[rasterKeyframes.length - 1] === lastFrame) {
    const rasterRange = voxelRaster.rasterRange
    // Segments go one past the frame they are defined on, so need to add 1 compared
    // to the raster range.
    videoAnnotationData.segments[0][1] = rasterRange[1] + 1
  }

  for (let frameIndex = firstFrame; frameIndex <= lastFrame; frameIndex++) {
    const annotationData = {
      rasterId: voxelRaster.id,
    }

    videoAnnotationData.frames[frameIndex] = annotationData

    // Calculate bounds

    let boundsForFrame = getBoundsOfSegmentOnReformat(
      reformatFrameIndex,
      plane,
      xRangeOnReformat,
      voxelRaster,
      segmentOffset,
    )

    const existingBoundsForFrame = voxelRaster.getVideoBoundsForLabelIndexForFrame(
      labelIndex,
      frameIndex,
    )

    if (existingBoundsForFrame) {
      // Combine the existing bounds with the new bounds.
      boundsForFrame = {
        topLeft: {
          x: Math.min(boundsForFrame.topLeft.x, existingBoundsForFrame.topLeft.x),
          y: Math.min(boundsForFrame.topLeft.y, existingBoundsForFrame.topLeft.y),
        },
        bottomRight: {
          x: Math.max(boundsForFrame.bottomRight.x, existingBoundsForFrame.bottomRight.x),
          y: Math.max(boundsForFrame.bottomRight.y, existingBoundsForFrame.bottomRight.y),
        },
      }
    }

    if (!voxelRaster.frameBuffers[frameIndex]) {
      voxelRaster.createNewKeyframe(frameIndex)
    }

    voxelRaster.setVideoBoundsForLabelIndexForFrame(labelIndex, frameIndex, boundsForFrame)
    voxelRaster.setLabelOnKeyframe(labelIndex, frameIndex)
    updatedFramesIndices.push(frameIndex)
  }

  primaryView.annotationManager.updateAnnotationData(annotation, videoAnnotationData)

  primaryView.annotationManager.updateAnnotation(annotation, {
    updatedFramesIndices: updatedFramesIndices,
  })
}
