import type { AnnotationClassPayload } from '@/store/types/AnnotationClassPayload'
import { put } from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'
import type { ApiResult } from './types'

/**
 * Defines param structure for the updateAnnotationClass backend action
 */
type Params = {
  annotation_type_ids?: number[]
  datasets?: AnnotationClassPayload['datasets']
  description?: string | null
  id: number
  images?: AnnotationClassPayload['images']
  annotation_class_image_url?: AnnotationClassPayload['annotation_class_image_url']
  metadata?: AnnotationClassPayload['metadata']
  name?: string
}

/**
 * Creates annotation class on backend
 */
export const updateAnnotationClass = async (
  params: Params,
): Promise<ApiResult<AnnotationClassPayload>> => {
  const path = `annotation_classes/${params.id}`

  try {
    const response = await put<AnnotationClassPayload>(path, params)
    return { data: response.data, ok: true }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return { ...parseError(error, errorMessages.ANNOTATION_CLASS_CREATE), ok: false }
  }
}
