import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import type { UserPayload } from '@/store/types'

/**
 * The primary store that holds logged in user info
 */
export const useUserStore = defineStore('user', () => {
  const loadingUser = ref(false)
  const currentUser = ref<UserPayload | null>(null)
  const currentUserId = computed<number | undefined>(() => currentUser.value?.id)

  return {
    currentUser,
    currentUserId,
    loadingUser,
  }
})
