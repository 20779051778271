import { get } from '@/backend/api'
import { parseError, errorMessages, isErrorResponse } from '@/backend/error'
import type { ApiResult } from './types'
import type { SlotSectionResponse, SlotSectionSimple } from '@/store/types/SlotSection'

export type TilesRequestPayload = { x: number; y: number; z: number }[]
export type PagePayload = {
  from?: string
  to?: string
  offset?: number
  size?: number
}

type Params = {
  teamSlug: string
  page?: PagePayload
  itemId: string
  slotName: string
}

export const loadSlotSections = async (
  params: Params,
): Promise<ApiResult<SlotSectionResponse<SlotSectionSimple>>> => {
  const { teamSlug, page, itemId, slotName } = params

  const path = `v2/teams/${teamSlug}/items/${itemId}/slots/${slotName}/sections`

  try {
    const response = await get<SlotSectionResponse<SlotSectionSimple>>(path, { page })
    return { ok: true, data: response.data }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return { ...parseError(error, errorMessages.STAGE_LOAD), ok: false }
  }
}
