import type { FrameData } from '@/core/annotations'
import type { AnnotationData, SimpleTable } from '@/modules/Editor/AnnotationData'
import { createEditablePoint } from '@/modules/Editor/point'

const isTable = (data: AnnotationData): data is SimpleTable =>
  'boundingBox' in data && 'rowOffsets' in data && 'colOffsets' in data

export const simpleTableSerializer = {
  serialize(data: SimpleTable): FrameData {
    if (!isTable(data)) {
      throw new Error('Invalid annotation given to table serializer')
    }

    const { boundingBox, rowOffsets, colOffsets } = data
    const { x: left, y: top } = boundingBox.topLeft
    const { x: right, y: bottom } = boundingBox.bottomRight

    return {
      simple_table: {
        bounding_box: {
          x: Math.min(left, right),
          y: Math.min(top, bottom),
          w: Math.abs(right - left),
          h: Math.abs(bottom - top),
        },
        row_offsets: rowOffsets,
        col_offsets: colOffsets,
      },
    }
  },

  deserialize(rawData: FrameData): AnnotationData | null {
    if (!rawData.simple_table) {
      return null
    }
    const { bounding_box, col_offsets, row_offsets } = rawData.simple_table
    const { x, y, w, h } = bounding_box
    return {
      boundingBox: {
        topLeft: createEditablePoint({ x, y }),
        bottomRight: createEditablePoint({ x: x + w, y: y + h }),
        topRight: createEditablePoint({ x: x + w, y: y }),
        bottomLeft: createEditablePoint({ x: x, y: y + h }),
      },
      colOffsets: col_offsets,
      rowOffsets: row_offsets,
    }
  },
}
