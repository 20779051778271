import type { FrameData } from '@/core/annotations'
import { DEFAULT_VERTEX_ACTIVE_SCALE, DEFAULT_VERTEX_SIZE } from '@/modules/Editor/config'
import type { BBox } from '@/modules/Editor/types'

export const calcKeypointBBox = (data: FrameData): BBox | undefined => {
  const { keypoint } = data

  if (!keypoint) {
    return
  }

  const width = DEFAULT_VERTEX_SIZE * DEFAULT_VERTEX_ACTIVE_SCALE
  const height = DEFAULT_VERTEX_SIZE * DEFAULT_VERTEX_ACTIVE_SCALE

  return {
    x: keypoint.x + width / 2,
    y: keypoint.y + height / 2,
    width,
    height,
  }
}
