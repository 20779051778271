<template>
  <component
    :is="tag"
    class="darwin-button"
    :class="{
      [`darwin-button--flair-${flair}`]: true,
      [`darwin-button--kind-${kind}`]: true,
      [`darwin-button--size-${size}`]: true,
      [`darwin-button--size-${size}--with-prepend`]: $slots['icon-prepend'],
      [`darwin-button--size-${size}--with-append`]: $slots['icon-append'],
      [`darwin-button--size-${size}--with-shortcut`]: $slots['icon-shortcut'],
      'darwin-button--disabled': disabled,
      'darwin-button--full-width': fullWidth,
    }"
    :disabled="disabled"
    :type="typeAttribute"
    v-bind="{ ...linkProps, ...$attrs }"
    v-on="$listeners"
  >
    <div
      v-if="$slots['icon-prepend']"
      class="darwin-button__icon-prepend"
    >
      <slot name="icon-prepend" />
    </div>
    <slot />

    <div
      v-if="$slots['icon-shortcut']"
      class="darwin-button__icon-shortcut"
    >
      <slot name="icon-shortcut" />
    </div>

    <div
      v-if="$slots['icon-append']"
      class="darwin-button__icon-append"
    >
      <slot name="icon-append" />
    </div>
  </component>
</template>

<script lang="ts">
export default {
  name: 'DarwinButton',
}
</script>

<script setup lang="ts">
import { buttonProps } from '@/uiKit/Button/props'
import { computed } from 'vue'

const props = // eslint-disable-next-line vue/define-props-declaration
  defineProps({
    ...buttonProps,
    fullWidth: { default: false, type: Boolean },
  })

const tag = computed<'button' | 'a' | 'router-link'>(() => {
  if (typeof props.to === 'string' && props.to.startsWith('http')) {
    return 'a'
  }
  if (props.to) {
    return 'router-link'
  }
  return 'button'
})

/*
 * We need to bind:
 * - the `href` attribute if the tag is an anchor
 * - the `to` prop if the tag is a router-link
 */
const linkProps = computed(() => {
  if (tag.value === 'a') {
    return { href: props.to }
  }
  if (tag.value === 'router-link') {
    return { to: props.to }
  }
  return {}
})

/**
 * The `type` property is used to set the type attribute on the button element. Make sure
 * that we don't pass it to the router-link component by setting it as undefined.
 */
const typeAttribute = computed(() => (tag.value === 'button' ? props.type : undefined))
</script>

<style lang="scss" scoped>
@import '@/uiKit/assets/borders.scss';
@import '@/uiKit/assets/buttons.scss';
@import '@/uiKit/assets/colors.global.scss';
@import '@/uiKit/assets/spacings.scss';
@import '@/uiKit/assets/transitions.scss';
@import '@/uiKit/assets/typography.scss';

.darwin-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
  width: fit-content;
  transition: background-color $transitionDefault;

  @include buttonProperties;

  &--full-width {
    width: 100%;
  }

  &--size {
    &-xs {
      $padding-x: 12px;

      gap: 0;
      height: 24px;
      padding: 0 $padding-x;
      @include typographyRegularBody200;
      font-size: 12px;

      &--with-prepend {
        padding-left: calc(#{$padding-x} - 4px);
      }

      &--with-append {
        padding-right: calc(#{$padding-x} - 4px);
      }

      .darwin-button__icon-shortcut {
        transform: scale(0.4444);
      }
    }

    &-sm {
      $padding-x: 16px;

      height: 32px;
      padding: 0 $padding-x;
      @include typographyRegularBody200;

      &--with-prepend {
        padding-left: calc(#{$padding-x} - 4px);
      }

      &--with-append {
        padding-right: calc(#{$padding-x} - 4px);
      }

      .darwin-button__icon-shortcut {
        transform: scale(0.6666);
        margin-left: $spacing-2;
      }
    }

    &-md {
      $padding-x: 20px;

      height: 36px;
      padding: 0 $padding-x;
      @include typographyRegularBody300;

      &--with-prepend {
        padding-left: calc(#{$padding-x} - 6px);
      }

      &--with-append {
        padding-right: calc(#{$padding-x} - 6px);
      }

      .darwin-button__icon-shortcut {
        height: 24px;
        margin-left: $spacing-4;
      }
    }

    &-lg {
      $padding-x: 22px;

      height: 40px;
      padding: 0 $padding-x;
      @include typographyRegularBody500;

      &--with-prepend {
        padding-left: calc(#{$padding-x} - 8px);
      }

      &--with-append {
        padding-right: calc(#{$padding-x} - 8px);
      }

      .darwin-button__icon-shortcut {
        height: 24px;
        margin-left: $spacing-4;
      }
    }
  }

  &__icon {
    &-prepend,
    &-append {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      aspect-ratio: 1 / 1;
    }

    &-shortcut {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      aspect-ratio: 1 / 1;
    }
  }
}
</style>
