import type { ApiResponse } from '@/store/types'
import type { V2AnnotationPayload } from '@/store/types/StageAnnotationPayload'
import { post } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { parseError, errorMessages, isErrorResponse } from '@/backend/error'

export type Params = {
  teamSlug: string
  itemId: string
  targetItemId: string
  workPhase: string
}

/**
 * Copies stage annotations from one stage to another
 */
export const copyV2Annotations = async ({
  teamSlug,
  itemId,
  targetItemId,
  workPhase,
}: Params): Promise<ApiResponse<V2AnnotationPayload[]> | ParsedError> => {
  const path = `/v2/teams/${teamSlug}/items/${itemId}/annotations/copy`
  try {
    const response = await post<V2AnnotationPayload[]>(path, {
      target_item_id: targetItemId,
      work_phase: workPhase,
    })

    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.DATASET_IMAGE_ANNOTATE)
  }
}
