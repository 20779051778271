import type { Skeleton } from '@/modules/Editor/AnnotationData'
import type { CompoundPath } from '@/modules/Editor/compoundPath'
import { addPointMutate, type EditablePoint, type IPoint } from '@/modules/Editor/point'

export const isSkeleton = (data: Skeleton | object): data is Skeleton => 'nodes' in data

const getPathFromSkeleton = (data: Skeleton | object): EditablePoint[] =>
  isSkeleton(data) ? data.nodes.map((node) => node.point) : []

export const getAllVerticesFromSkeleton = (data: Skeleton | object): EditablePoint[] =>
  getPathFromSkeleton(data)

export const getCompoundPathFromSkeleton = (data: Skeleton | object): CompoundPath => ({
  path: getPathFromSkeleton(data),
  additionalPaths: [],
})

export const translateSkeleton = (data: Skeleton | object, offset: IPoint): void =>
  getPathFromSkeleton(data).forEach((point) => addPointMutate(point, offset))

export const moveVertexOnSkeleton = (movingVertex: EditablePoint, offset: IPoint): void =>
  addPointMutate(movingVertex, offset)
