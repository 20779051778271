import { getNumPixelChannels } from '@/modules/Editor/utils/windowLevels'
import type { WindowLevelSensitivity } from '@/modules/Editor/plugins/windowLevel/types'
import type { ColorSpace } from '@/modules/Editor/plugins/windowLevel/types'

/**
 * Calculates the sensitivity of the window level.
 * -- Dragging the full canvas width left-to-right moves the window its full range.
 * -- Dragging the full canvas width top-to-bottom moves the level its full range.
 * -- This is the standard UX expected from a PACS Viewer like product.
 */
export const getSensitivy = (
  width: number,
  height: number,
  colorspace: ColorSpace,
): WindowLevelSensitivity => {
  /**
   * The sensitivity is set such that the one drag across the whole canvas spans the range.
   * This is the sensitivity that all PACS viewers use to get the right "feel" for windowing
   * any image.
   */
  const range = getNumPixelChannels(colorspace)

  // Window is left/right
  // Level is up/down
  return {
    windowSensitivity: range / width,
    levelSensitivity: range / height,
  }
}
