import type { AnnotationType } from '@/core/annotationTypes'

import type { AnnotationData } from './AnnotationData'
import { isBoundingBox, moveVertexOnBoundingBox } from './annotationTypes/boundingBox'
import type { SpecialKey } from './annotationTypes/ellipse'
import { isEllipse, moveVertexOnEllipse } from './annotationTypes/ellipse'
import { isEye, moveVertexOnEye } from './annotationTypes/eye'
import { isKeyPoint, moveVertexOnKeyPoint } from './annotationTypes/keypoint'
import { isPolygon, moveVertexOnPolygon } from './annotationTypes/polygon'
import { isPolyline, moveVertexOnPolyline } from './annotationTypes/polyline'
import { isSimpleTable, moveVertexOnSimpleTable } from './annotationTypes/simpleTable'
import { isSkeleton, moveVertexOnSkeleton } from './annotationTypes/skeleton'
import type { EditablePoint, IPoint } from './point'

import { inferCurrentAnnotationData } from './inferCurrentAnnotationData'
import type { Annotation } from './models/annotation/Annotation'
import type { View } from './views/view'

const moveAnnotationDataVertex = (
  data: AnnotationData,
  type: AnnotationType,
  movingVertex: EditablePoint,
  offset: IPoint,
  specialKey?: SpecialKey,
): void => {
  if (type === 'polygon' && isPolygon(data)) {
    return moveVertexOnPolygon(movingVertex, offset)
  }

  if (type === 'bounding_box' && isBoundingBox(data)) {
    return moveVertexOnBoundingBox(data, movingVertex, offset)
  }

  if (type === 'ellipse' && isEllipse(data)) {
    return moveVertexOnEllipse(data, movingVertex, offset, specialKey)
  }

  if (type === 'skeleton' && isSkeleton(data)) {
    return moveVertexOnSkeleton(movingVertex, offset)
  }

  if (type === 'eye' && isEye(data)) {
    return moveVertexOnEye(movingVertex, offset)
  }

  if (type === 'line' && isPolyline(data)) {
    return moveVertexOnPolyline(movingVertex, offset)
  }

  if (type === 'keypoint' && isKeyPoint(data)) {
    return moveVertexOnKeyPoint(data, movingVertex, offset)
  }

  if (type === 'simple_table' && isSimpleTable(data)) {
    return moveVertexOnSimpleTable(data, movingVertex, offset)
  }

  // graph, string and table do not support moving
}

export const moveAnnotationVertex = (
  annotation: Annotation,
  view: View,
  movingVertex: EditablePoint,
  offset: IPoint,
  specialKey?: SpecialKey,
): void => {
  const data = inferCurrentAnnotationData(annotation, view.currentFrameIndex)
  moveAnnotationDataVertex(data, annotation.type, movingVertex, offset, specialKey)
}
