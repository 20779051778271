/**
 * This utility contains api-related functions that deals with tokens
 * and requests to the backend.
 * It also manages the localStorage and sessionStorage to manage the tokens
 */
import axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from 'qs'

import { WIND_API } from '@/services/config'
import { addErrorContextToSentry } from '@/backend/apiClient'
import type { CreateWindAuthParams } from '@/backend/darwin'
import type { ApiResult } from '@/backend/darwin/types'
import { createWindAuth } from '@/backend/darwin'
import type { ErrorMessageGroup, ErrorWithMessage, ParsedError } from '@/backend/error'
import { isErrorResponse, parseError } from '@/backend/error'

const baseURL = WIND_API

const paramsSerializer: AxiosRequestConfig['paramsSerializer'] = {
  serialize: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
}

const withCache = async (
  key: string,
  request: () => ReturnType<typeof createWindAuth>,
): Promise<AxiosResponse<{ token: string }> | ParsedError> => {
  const authResponse = await request()
  if ('data' in authResponse) {
    sessionStorage.setItem(key, authResponse.data.token)
  }
  return authResponse
}

export const resolveWindAuth = async (
  authParams: CreateWindAuthParams,
  useCache: boolean = true,
): Promise<{ data: { token: string } } | ParsedError> => {
  const key = `${authParams.action}:${authParams.teamId}`

  if (useCache) {
    const cached = sessionStorage.getItem(key)
    if (cached) {
      return await Promise.resolve({ data: { token: cached } })
    }
  }

  return await withCache(key, () => createWindAuth(authParams))
}

const authHeaders = (token: string): { Authorization: `ApiKey ${string}` } => ({
  Authorization: `ApiKey ${token}`,
})

const client = (token: string): AxiosInstance => {
  const axiosClient = axios.create({ baseURL, paramsSerializer, headers: authHeaders(token) })
  axiosClient.interceptors.response.use(undefined, addErrorContextToSentry)
  return axiosClient
}

const unauthenticatedClient = (): AxiosInstance => {
  const axiosClient = axios.create({ baseURL, paramsSerializer })
  axiosClient.interceptors.response.use(undefined, addErrorContextToSentry)
  return axiosClient
}

type Requestor = () => Promise<AxiosResponse>

const wrap = async <T = unknown>(
  request: Requestor,
  errorGroup?: ErrorMessageGroup,
): Promise<ApiResult<T>> => {
  try {
    const response = await request()
    return { data: response.data, ok: true }
  } catch (e) {
    if (!isErrorResponse(e)) {
      throw e
    }
    if (errorGroup) {
      return { ...parseError(e, errorGroup), ok: false }
    }

    const status = e.response ? e.response.status : null
    const code = e.response?.data.errors.code
    const error: ErrorWithMessage = {
      code: typeof code === 'string' ? code : null,
      detail: null,
      message: 'Wind error',
      backendMessage: null,
      status,
    }
    return { error, ok: false }
  }
}

const wait = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Wraps a request callback into an authenticated wind request
 *
 * Uses authParams to resolve authentication, either from cache or by making a
 * request to darwin backend to get a new wind auth key.
 *
 * Using token from authentication, instantiates an axios instance and passes it
 * into the request callback, so a request can be made.
 *
 * Wraps the response into a { data?, error? } structure.
 */
export const withAuth = async <T = unknown>(
  authParams: CreateWindAuthParams,
  request: (client: AxiosInstance) => Promise<AxiosResponse<T>>,
  errorGroup?: ErrorMessageGroup,
  depth: number = 0,
): Promise<ApiResult<T>> => {
  // First, try to get the token from the cache
  const tokenResponse = await resolveWindAuth(authParams)
  if ('error' in tokenResponse) {
    return { ...tokenResponse, ok: false }
  }

  // If we got a token, use it to make a request
  const response = await wrap<T>(() => request(client(tokenResponse.data.token)), errorGroup)
  if ('error' in response && response.error.status !== 401) {
    return { ...response, ok: false }
  }

  if ('data' in response) {
    return { data: response.data, ok: true }
  }

  // If we got an error, try to get a new token and retry the request
  // This time, we don't want to use the cache to resolve the token
  const authResponse = await resolveWindAuth(authParams, false)
  if ('error' in authResponse) {
    return { ...response, ok: false }
  }

  // We use an exponentional backoff to avoid overloading the backend
  while (depth < 5) {
    await wait(2 ** depth * 10)
    const response = await wrap<T>(() => request(client(authResponse.data.token)), errorGroup)
    if ('error' in response && response.error.status === 401) {
      depth += 1
      continue
    }

    if ('error' in response) {
      return { ...response, ok: false }
    }

    return { data: response.data, ok: true }
  }

  return { ...response, ok: false }
}

/**
 * Wraps a request callback into an unauthenticated wind request.
 *
 * Instantiates an unauthenticated axios instance and passes it into the
 * request callback, so a request can be made.
 *
 * Wraps the response into a { data?, error? } structure.
 */
export const withoutAuth = async <T = unknown>(
  request: (client: AxiosInstance) => Promise<AxiosResponse<T>>,
  errorGroup?: ErrorMessageGroup,
): Promise<ApiResult<T>> => {
  const response = await wrap<T>(() => request(unauthenticatedClient()), errorGroup)
  return 'error' in response ? { ...response, ok: false } : { data: response.data, ok: true }
}
