import type { Keypoint } from '@/modules/Editor/AnnotationData'
import type { CompoundPath } from '@/modules/Editor/compoundPath'
import type { EditablePoint, IPoint } from '@/modules/Editor/point'
import { addPointMutate, createEditablePoint } from '@/modules/Editor/point'

export const isKeyPoint = (data: Keypoint | object): data is Keypoint => 'x' in data && 'y' in data

export const getAllVerticesFromKeyPoint = (keypoint: Keypoint | object): EditablePoint[] =>
  isKeyPoint(keypoint) ? [createEditablePoint(keypoint)] : []

export const getCompoundPathFromKeyPoint = (keypoint: Keypoint | object): CompoundPath => ({
  path: isKeyPoint(keypoint) ? [createEditablePoint(keypoint)] : [],
  additionalPaths: [],
})

export const moveVertexOnKeyPoint = (
  keypoint: Keypoint,
  movingVertex: EditablePoint,
  offset: IPoint,
): void => {
  // weird one. because annotation.data is the point (as in, just x and y) the vertex passed in
  // is a separate object, but with the same x and y, so we have to both re-assign annotation.data
  // as well as mutate the vertex itself
  addPointMutate(movingVertex, offset)
  keypoint.x = movingVertex.x
  keypoint.y = movingVertex.y
}

export const translateKeyPoint = (keypoint: Keypoint, offset: IPoint): void =>
  addPointMutate(keypoint, offset)
