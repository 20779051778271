import type { Camera } from '@/modules/Editor/camera'
import type { IPoint } from '@/modules/Editor/point'

import { CanvasAndContext } from './canvasAndContext'
import type { CloseViewCanvas } from './closeViewCanvas/closeViewCanvas'
import { CACHED_CANVAS_PADDING, DOUBLE_CACHED_CANVAS_PADDING } from './configs'

export class MainCanvas extends CanvasAndContext {
  /**
   * Stores current offset value.
   * Prevents unnecessary rendering of the main canvas for the same offset value.
   */
  public offset: IPoint = { x: 0, y: 0 }

  /**
   * Stores current scale value.
   * Prevents unnecessary rendering of the main canvas for the same scale value.
   */
  public scale = 0

  constructor() {
    super({ willReadFrequently: true })
  }

  saveCameraPosition(scale: number, offset: IPoint): void {
    this.scale = scale
    this.offset = {
      x: offset.x,
      y: offset.y,
    }
  }

  /**
   * Main canvas renders CloseView cached canvas.
   * That provides higher quality for items.
   */
  insertCloseViewCanvas(closeViewCanvas: CloseViewCanvas): void {
    if (!closeViewCanvas.canvas) {
      return
    }

    this.clearCanvas()

    this.insertCanvas(
      closeViewCanvas.canvas,
      0,
      0,
      closeViewCanvas.canvas.width,
      closeViewCanvas.canvas.height,
    )
  }

  /**
   * Draw cached canvas on the main canvas using current offset/scale.
   */
  insertCached(image: HTMLCanvasElement | ImageBitmap, camera: Camera): void {
    // Verify canvas height and width to be set
    // TiledView by default has 0 width/height and update it with the API response
    if (image instanceof HTMLCanvasElement && (image.width === 0 || image.width === 0)) {
      return
    }

    this.clearCanvas()

    this.insertCanvas(
      image,
      -camera.offset.x - CACHED_CANVAS_PADDING * camera.scale,
      -camera.offset.y - CACHED_CANVAS_PADDING * camera.scale,
      (camera.image.width + DOUBLE_CACHED_CANVAS_PADDING) * camera.scale,
      (camera.image.height + DOUBLE_CACHED_CANVAS_PADDING) * camera.scale,
    )
  }
}
