import type { Action } from '@/modules/Editor/managers/actionManager'
import type { Editor } from '@/modules/Editor/editor'
import type { CommentThread } from '@/modules/Editor/iproviders/types'

export const moveCommentThreadVertexAction = (editor: Editor, thread: CommentThread): Action => ({
  async do(): Promise<boolean> {
    await editor.activeView.commentManager.updateThread(thread)
    return true
  },
  async undo(): Promise<boolean> {
    await editor.activeView.commentManager.updateThread(thread)
    return true
  },
})
