import type { Ellipse } from '@/modules/Editor/AnnotationData'
import { createEditablePoint, addPoints, mulScalar, subPoints } from '@/modules/Editor/point'

import type { LinearInterpolationParams } from './types'

export const ellipseInterpolation = (
  params: LinearInterpolationParams,
  prevData: Ellipse,
  nextData: Ellipse,
): Ellipse => {
  const { algorithm, interpolationFactor } = params

  if (algorithm && !algorithm.startsWith('linear')) {
    throw new Error(`Interpolate: ellipses don't support '${algorithm}' interpolation algorithm`)
  }

  const {
    center: prevCenter,
    right: prevRight,
    top: prevTop,
    left: prevLeft,
    bottom: prevBottom,
  } = prevData
  const {
    center: nextCenter,
    right: nextRight,
    top: nextTop,
    left: nextLeft,
    bottom: nextBottom,
  } = nextData

  // In case of ellipse rotation
  // the interpolation wouldn't rotate ellipse,
  // it would instead shrink it while the old 1 goes
  // to the new 1s position.

  // more details: https://www.notion.so/v7labs/Ellipse-interpolation-algorithm-e359f1991e514592abd2fa54d25433e6
  const prev = [prevRight, prevTop, prevLeft, prevBottom]
  const next = [nextRight, nextTop, nextLeft, nextBottom]
  let minCost = 1e100
  let minCostIdx = 0
  for (let i = 0; i < 4; i++) {
    let cost = 0
    for (let j = 0; j < 4; j++) {
      const dist = subPoints(prev[j], next[(j + i) % 4])
      cost += dist.x ** 2 + dist.y ** 2
    }
    if (cost < minCost) {
      minCost = cost
      minCostIdx = i
    }
  }

  const interpolatedPoints = {
    center: createEditablePoint(
      addPoints(prevCenter, mulScalar(subPoints(nextCenter, prevCenter), interpolationFactor)),
    ),
    right: createEditablePoint(
      addPoints(
        prev[0],
        mulScalar(subPoints(next[(minCostIdx + 0) % 4], prev[0]), interpolationFactor),
      ),
    ),
    top: createEditablePoint(
      addPoints(
        prev[1],
        mulScalar(subPoints(next[(minCostIdx + 1) % 4], prev[1]), interpolationFactor),
      ),
    ),
    left: createEditablePoint(
      addPoints(
        prev[2],
        mulScalar(subPoints(next[(minCostIdx + 2) % 4], prev[2]), interpolationFactor),
      ),
    ),
    bottom: createEditablePoint(
      addPoints(
        prev[3],
        mulScalar(subPoints(next[(minCostIdx + 3) % 4], prev[3]), interpolationFactor),
      ),
    ),
  }

  return interpolatedPoints
}
