import type { Raster } from '@/modules/Editor/models/raster/Raster'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'

/**
 * Helper function used after the raster drawing which did not impact any pixels.
 *
 * This scenario can happen for example when the user starts drawing outside the canvas,
 * usually that means we have a new empty label and keyframe that should be removed.
 */
export const cleanupPotentiallyEmptyMask = (
  raster: Raster,
  isNewMaskAnnotation: boolean,
  labelIndex: number,
): void => {
  if (isNewMaskAnnotation) {
    raster.deleteAnnotationMapping(labelIndex)
  }

  const isVideoOrVoxelRaster =
    raster.type === RasterTypes.VIDEO || raster.type === RasterTypes.VOXEL
  if (!isVideoOrVoxelRaster) {
    return
  }

  // Additional clean-up for video and voxel rasters which both works with the timeline
  const videoRaster = assertVideoRaster(raster)

  videoRaster.rasterKeyframes.forEach((frameIndex) => {
    // Bounds are not set yet (no painting was done), delete the label on the keyframe
    if (videoRaster.getVideoBoundsForLabelIndex(labelIndex) === undefined) {
      videoRaster.deleteLabelOnKeyframe(labelIndex, frameIndex)
    }

    // All labels are removed on the keyframe, delete also the keyframe itself
    if (videoRaster.getLabelsOnKeyframe(frameIndex).length === 0) {
      videoRaster.deleteKeyframe(frameIndex)
    }
  })
}
