import type { Segment, InternalSegment } from '@/modules/Editor/polygonOperations'

/**
 * Creates new Segment object, optionally initiliased with a set
 * of given segments.
 *
 * @param segments Optional set of segments to initialise the path with.
 *
 * @returns A Segment path.
 */
export const getNewPath = (segments?: InternalSegment[]): Segment => {
  segments = segments ? segments : []

  return { segments, inverted: false }
}
