import type { ExtendedFileMetadata } from '@/modules/Editor/metadata'
import type { IPoint } from '@/modules/Editor/point'
import { mulPoints } from '@/modules/Editor/point'
import type { VoxelRasterDimensions } from '@/modules/Editor/models/raster/VoxelRaster'

import { getXYVoxelScaling } from './getXYVoxelScaling'

export const getTopLeftOfScaledPixel = (
  slot_name: string,
  imagePoint: IPoint,
  metadata?: ExtendedFileMetadata,
  voxelRasterDimensions?: VoxelRasterDimensions,
): { voxelCoordinate: IPoint; topLeftOfScaledPixel: IPoint; voxelScaling?: IPoint } => {
  if (!metadata || !voxelRasterDimensions) {
    const flooredImagePoint = { x: Math.floor(imagePoint.x), y: Math.floor(imagePoint.y) }
    return {
      voxelCoordinate: flooredImagePoint,
      topLeftOfScaledPixel: flooredImagePoint,
      voxelScaling: { x: 1, y: 1 },
    }
  }

  const voxelScaling = getXYVoxelScaling(slot_name, metadata, voxelRasterDimensions) || {
    x: 1,
    y: 1,
  }

  // Get the voxel coordinate (need to floor _here_)
  const voxelCoordinate = {
    x: Math.floor(imagePoint.x / voxelScaling.x),
    y: Math.floor(imagePoint.y / voxelScaling.y),
  }

  // Now we have the position of the top left of the voxel, scale back to image pixel space.
  const topLeftOfScaledPixel = mulPoints(voxelCoordinate, voxelScaling)

  return { voxelCoordinate, topLeftOfScaledPixel, voxelScaling }
}
