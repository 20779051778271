import type { RasterDataSnapshot } from './Raster'
import type { PartialRecord } from '@/core/helperTypes'

export type ImageRasterDataSnapshot = RasterDataSnapshot & {
  denseRLE: number[]
}

/**
 * Returns a raster as an image raster for type interpretation, without casting.
 * Throws if this is not possible.
 */
export const assertImageRasterSnapshot = (
  snapshot: RasterDataSnapshot,
): ImageRasterDataSnapshot => {
  if (!Array.isArray(snapshot.denseRLE)) {
    throw new Error('Snapshot is not for Image raster')
  }

  return snapshot as ImageRasterDataSnapshot
}

export type VideoRasterDataSnapshot = RasterDataSnapshot & {
  denseRLE: PartialRecord<number, number[]>
}

/**
 * Returns a raster as a video raster for type interpretation, without casting.
 * Throws if this is not possible.
 */
export const assertVideoOrVoxelRasterSnapshot = (
  snapshot: RasterDataSnapshot,
): VideoRasterDataSnapshot => {
  if (Array.isArray(snapshot.denseRLE)) {
    throw new Error('Snapshot is not for Video or Voxel raster')
  }

  return snapshot as VideoRasterDataSnapshot
}
