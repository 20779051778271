import type { ImageRaster } from '@/modules/Editor/models/raster/ImageRaster'
import type { FrameData } from '@/core/annotations'
import { decodeDenseRLE } from '@/modules/Editor/plugins/mask/rle/denseRle'

/**
 * Deserializes the raster annotations and apply the buffer to the raster
 */
export const deserializeImageRaster = (
  imageRaster: ImageRaster,
  rasterLayerDataPayload: FrameData['raster_layer'],
  annotationIdToClassIdMapping: Record<string, number>,
): void => {
  if (rasterLayerDataPayload === undefined) {
    throw new Error('No raster_layer on raster annotation')
  }

  const maskAnnotationIdsMapping = rasterLayerDataPayload.mask_annotation_ids_mapping
  const denseRLE = rasterLayerDataPayload.dense_rle
  const totalPixels = rasterLayerDataPayload.total_pixels

  if (!denseRLE || denseRLE[0] === undefined) {
    return
  }

  const annotationIds = Object.keys(maskAnnotationIdsMapping)

  const { mask, boundsPerLabelIndex, totalBounds } = decodeDenseRLE(
    denseRLE,
    totalPixels,
    imageRaster.width,
  )

  annotationIds.forEach((annotationId) => {
    const labelIndex = maskAnnotationIdsMapping[annotationId]
    if (!labelIndex) {
      return
    }

    const classId = annotationIdToClassIdMapping[annotationId]
    if (!classId) {
      // We would normally throw an error here, but we can't do that as have sometimes leftovers
      // from the old raster layer that are not in the annotationIdToClassIdMapping.
      return
    }
    imageRaster.setAnnotationMapping(labelIndex, annotationId, classId)

    /**
     * It is completely valid for a mask class to exist (e.g. through API),
     * without it having a single voxel on the raster layer. If it does not,
     * we set its range to zero.
     */
    const bounds = boundsPerLabelIndex[labelIndex] || {
      topLeft: { x: 0, y: 0 },
      bottomRight: { x: 0, y: 0 },
    }

    imageRaster.setBoundsForLabelIndex(labelIndex, bounds)
  })

  imageRaster.setActiveBuffer(mask)

  // Invalidate only the region containing data.
  imageRaster.invalidate(
    totalBounds.topLeft.x,
    totalBounds.bottomRight.x,
    totalBounds.topLeft.y,
    totalBounds.bottomRight.y,
  )
}
