import md5 from 'md5'

import type { AnnotationData, Polygon } from '@/modules/Editor/AnnotationData'
import {
  interpolateAnnotationFrames,
  supportsInterpolation,
} from '@/modules/Editor/annotationInterpolation'
import type {
  InterpolationAlgorithm,
  LinearInterpolationParams,
} from '@/modules/Editor/annotationInterpolation/types'
import { hasSegmentContainingIndex } from '@/modules/Editor/helpers/segments'
import {
  isVideoSubAnnotations,
  isVideoAnnotation,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import type { VideoAnnotationDataPayload } from '@/modules/Editor/types'

import type { Annotation } from './Annotation'
import { getCachedInterpolatedData, setCachedInterpolatedData } from './annotationRenderingCache'
import type { VideoAnnotation } from './types'

const emptyPayload: VideoAnnotationDataPayload = {
  data: {},
  subs: [],
  keyframe: false,
  subkeyframe: false,
  interpolateAlgorithm: 'linear-1.1',
}

/**
 * Creates a hash for a particular interpolation of the data.
 * This is so we can check if we need to recompute any expensive interpolation.
 * @param prevData
 * @param nextData
 * @param params
 * @returns a hash unqiue to the state of the 3 inputs.
 */
const getHashOfInterpolatedData = (
  prevData: Polygon | { path: undefined },
  nextData: Polygon | { path: undefined },
  params: LinearInterpolationParams,
): string =>
  md5(
    JSON.stringify(prevData.path) +
      JSON.stringify(nextData.path) +
      params.interpolationFactor.toString(),
  )

/**
 * Finds the closest non-auto-track keyframe indexes to the left and
 * right of the current frame index.
 * Auto-track keyframes are skipped to prevent the UI from creating
 * new auto-track keyframes when the user manually creates new keyframes.
 */
const findClosestKeyframes = (
  /** An object where each key is a frame index and each value contains frame details */
  frames: Record<number, AnnotationData>,
  /** The current workview frame index */
  currentFrameIndex: number,
): { prevManualIdx: number | null; nextManualIdx: number | null } => {
  let prevManualIdx: number | null = null
  let nextManualIdx: number | null = null

  Object.keys(frames).forEach((key) => {
    const idx = parseInt(key, 10)

    const isBeforeCurrentFrame = idx < currentFrameIndex
    const isAfterCurrentFrame = idx > currentFrameIndex
    const shouldUpdatePrevManualIdx = prevManualIdx === null || idx > prevManualIdx
    const shouldUpdateNextManualIdx = nextManualIdx === null || idx < nextManualIdx

    if (isBeforeCurrentFrame && shouldUpdatePrevManualIdx) {
      prevManualIdx = idx
    }

    if (isAfterCurrentFrame && shouldUpdateNextManualIdx) {
      nextManualIdx = idx
    }
  })

  return { prevManualIdx, nextManualIdx }
}

/**
 * Returns payload for frames with available data or defaults if not.
 */
const frameDataPayload = (
  frames: { [key: string]: AnnotationData },
  index: number,
  annotation: Annotation,
  interpolateAlgorithm: InterpolationAlgorithm,
): VideoAnnotationDataPayload => {
  if (!isVideoAnnotation(annotation)) {
    return emptyPayload
  }

  return {
    data: frames[index] || {},
    subs: annotation.subAnnotations.frames[index] || [],
    keyframe: index in frames,
    subkeyframe: index in annotation.subAnnotations.frames,
    interpolateAlgorithm,
  }
}

/**
 * Handles the interpolation of frame data between two manual keyframes.
 */
const interpolateFrameData = (
  annotation: VideoAnnotation,
  prevManualIdx: number,
  nextManualIdx: number,
  currentFrameIndex: number,
  algorithm: InterpolationAlgorithm,
): VideoAnnotationDataPayload => {
  const { frames } = annotation.data

  const interpolationFactor = (currentFrameIndex - prevManualIdx) / (nextManualIdx - prevManualIdx)
  const params: LinearInterpolationParams = { algorithm, interpolationFactor }
  const newHash = getHashOfInterpolatedData(frames[prevManualIdx], frames[nextManualIdx], params)
  let cachedData = getCachedInterpolatedData(annotation.id)

  // If there is no cached data interpolate
  if (!cachedData || cachedData.hash !== newHash) {
    const interpolatedData =
      interpolateAnnotationFrames(
        annotation.type,
        frames[prevManualIdx],
        frames[nextManualIdx],
        params,
      ) || {}
    cachedData = { hash: newHash, data: interpolatedData }
    setCachedInterpolatedData(annotation.id, cachedData)
  }

  return {
    ...emptyPayload,
    data: { ...frames[prevManualIdx], ...cachedData.data },
    subs: isVideoSubAnnotations(annotation.subAnnotations)
      ? annotation.subAnnotations.frames[prevManualIdx] || []
      : [],
  }
}

/**
 * Infers the data payload for a video annotation
 *
 * Note: We only accept the view, rather than reading it off the annotation.
 * This is so we can more easily split the view from the annotation in the future.
 */
export const inferVideoData = (
  annotation: VideoAnnotation,
  currentFrameIndex: number,
): VideoAnnotationDataPayload => {
  if (!isVideoAnnotation(annotation) || !isVideoSubAnnotations(annotation.subAnnotations)) {
    return emptyPayload
  }

  const { frames, segments, interpolate_algorithm: interpolateAlgorithm } = annotation.data

  // If the video annotation is not visible
  if (!hasSegmentContainingIndex(segments, currentFrameIndex)) {
    return emptyPayload
  }

  // If there's already a keyframe at the current frame index
  // return its video annotation data payload
  const hasKeyframe = currentFrameIndex in frames
  if (hasKeyframe) {
    return frameDataPayload(frames, currentFrameIndex, annotation, interpolateAlgorithm)
  }

  const { prevManualIdx, nextManualIdx } = findClosestKeyframes(frames, currentFrameIndex)
  const isInterpolationSupported = supportsInterpolation(annotation.type)
  const isInterpolatedFlagSet = annotation.data.interpolated
  const hasValidKeyframes = prevManualIdx !== null && nextManualIdx !== null
  const canInterpolate = isInterpolationSupported && isInterpolatedFlagSet && hasValidKeyframes

  if (!canInterpolate) {
    // Choose the closest manual keyframe index to the current frame,
    // preferring the previous one if available
    const fallbackIndex = prevManualIdx !== null ? prevManualIdx : nextManualIdx
    // If a fallback keyframe index is found, use its data; otherwise, return an empty payload
    return fallbackIndex !== null
      ? frameDataPayload(frames, fallbackIndex, annotation, interpolateAlgorithm)
      : emptyPayload
  }

  return interpolateFrameData(
    annotation,
    prevManualIdx,
    nextManualIdx,
    currentFrameIndex,
    interpolateAlgorithm,
  )
}
