import type { AnnotationType } from '@/core/annotationTypes'
import { calcMiddleSegmentMiddlePoint } from '@/modules/Editor/graphics/calcMiddleSegmentMiddlePoint'
import type { IPoint } from '@/modules/Editor/point'
import { addPoints, divScalar } from '@/modules/Editor/point'

export const calcOuterBoxCentroid = (path: IPoint[]): IPoint => {
  const minX = Math.min(...path.map((point) => point.x))
  const minY = Math.min(...path.map((point) => point.y))
  const maxX = Math.max(...path.map((point) => point.x))
  const maxY = Math.max(...path.map((point) => point.y))
  return { x: (minX + maxX) / 2, y: (minY + maxY) / 2 }
}

export const calcEllipseCentroid = (path: IPoint[]): IPoint => path[0]

export const calcCentroid = (path: IPoint[], scale?: number): IPoint => {
  // In the case of keypoints, the centroid is shifted horizontally,
  // so the keypoint itself remains visible
  if (path.length === 1 && scale) {
    return addPoints(path[0], { x: 25 / scale, y: 0 })
  }
  // Calculating the centroid of a polygon https://en.wikipedia.org/wiki/Centroid
  let x = 0
  let y = 0
  let area = 0
  for (let i = 0; i < path.length; i++) {
    const c = path[i]
    const n = path[i < path.length - 1 ? i + 1 : 0]
    x += (c.x + n.x) * (c.x * n.y - n.x * c.y)
    y += (c.y + n.y) * (c.x * n.y - n.x * c.y)
    area += c.x * n.y - n.x * c.y
  }
  const centroid = { x, y }
  return divScalar(centroid, 3 * area)
}

export const calcCentroidPointFromPath = (
  path: IPoint[],
  type: AnnotationType,
  cameraScale: number,
): IPoint | undefined => {
  switch (type) {
    case 'ellipse':
      return calcEllipseCentroid(path)
    case 'line':
      return calcMiddleSegmentMiddlePoint(path)
    case 'skeleton':
    case 'eye':
      return calcOuterBoxCentroid(path)
    default:
      return calcCentroid(path, cameraScale)
  }
}
