import { ToolName } from '@/modules/Editor/tools/types'
import type { Editor } from '@/modules/Editor/editor'
import { isVideoSubAnnotations } from '@/modules/Editor/models/annotation/annotationKindValidator'

import { retrieveAutoAnnotateData } from './utils/retrieveAutoAnnotateData'

export const resume = (editor: Editor): void => {
  const { selectedAnnotation } = editor.activeView.annotationManager
  if (!selectedAnnotation) {
    return
  }
  if (isVideoSubAnnotations(selectedAnnotation.subAnnotations)) {
    return
  }

  const autoAnnotateData = retrieveAutoAnnotateData(selectedAnnotation.id, editor.activeView)
  if (!autoAnnotateData) {
    return
  }

  editor.toolManager.activateTool(ToolName.Clicker)
  editor.callCommand('clicker_tool.init', selectedAnnotation, autoAnnotateData)
}
