import { fromPolyBool, toPolyBool, polygonToCompoundPath } from '@/modules/Editor/compoundPath'
import type { Action } from '@/modules/Editor/managers/actionManager'
import { combine, selectUnion, polygon, segments } from '@/modules/Editor/polygonOperations'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'

import { mergeSubAnnotations } from './utils/mergeSubAnnotations'
import { isPolygon } from '@/modules/Editor/annotationTypes/polygon'

export const mergePolygonsAction = (
  view: View,
  firstAnnotation: Annotation,
  secondAnnotation: Annotation,
): Action => {
  const previousFirstAnnotationData = firstAnnotation.data
  const { id: secondAnnotationId } = secondAnnotation

  return {
    do(): boolean {
      if (!isPolygon(firstAnnotation.data) || !isPolygon(secondAnnotation.data)) {
        return false
      }

      const polygonCompoundPath1 = polygonToCompoundPath(firstAnnotation.data)
      const polygonCompoundPath2 = polygonToCompoundPath(secondAnnotation.data)
      const segment1 = segments(toPolyBool(polygonCompoundPath1))
      const segment2 = segments(toPolyBool(polygonCompoundPath2))

      const combined = combine(segment1, segment2)

      const mergedSegment = selectUnion(combined)
      const mergedPolygon = fromPolyBool(polygon(mergedSegment))

      const subAnnotations = mergeSubAnnotations(firstAnnotation, secondAnnotation)

      view.annotationManager.deleteAnnotation(secondAnnotationId)

      const updatedAnnotation = shallowCloneAnnotation(firstAnnotation, {
        data: {
          ...firstAnnotation.data,
          path: mergedPolygon.path,
          additionalPaths: mergedPolygon.additionalPaths,
        },
        subAnnotations,
      })

      view.annotationManager.updateAnnotation(updatedAnnotation)

      return true
    },
    undo(): boolean {
      firstAnnotation.data = previousFirstAnnotationData
      try {
        view.annotationManager.createAnnotation(secondAnnotation)
        view.annotationManager.selectAnnotation(secondAnnotation.id)
      } catch (e) {
        setContext('error', { error: e })
        console.error('in v2 mergePolygons action, annotatonManager failed to create')
        return false
      }
      view.annotationManager.updateAnnotation(firstAnnotation)

      return true
    },
  }
}
