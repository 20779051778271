import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'
// eslint-disable-next-line boundaries/element-types
import { rgbaString } from '@/uiKit/colorPalette'

import { highlightedAlpha, defaultAlpha } from './renderingConstants'

export const fillStyle = (
  color: RGBA,
  filter: ImageManipulationFilter | null,
  inferred: boolean,
  isHighlighted: boolean,
  isSelected: boolean,
): string => {
  let alpha = 1
  if (!filter) {
    alpha = inferred || isHighlighted || isSelected ? highlightedAlpha : defaultAlpha
  } else {
    if (isSelected) {
      alpha = filter.opacity / 100.0
    } else {
      alpha = inferred || isHighlighted ? highlightedAlpha : filter.opacity / 100.0
    }
  }
  return rgbaString(color, alpha)
}
