// Sensitivity parameters
const SENSITIVITY_LOW = 5
const SENSITIVITY_HIGH = 80

// Delay for activating on activating the key press bound tool.
const ACTIVATE_DELAY = 100

const hasPointerLockSupport = !!document.exitPointerLock

export { SENSITIVITY_LOW, SENSITIVITY_HIGH, ACTIVATE_DELAY, hasPointerLockSupport }
