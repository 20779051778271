import type { MedicalMetadata } from '@/modules/Editor/MedicalMetadata'
import { getSlotNamesPerPlane } from '@/modules/Editor/utils/radiology/getSlotNamesPerPlane'

/**
 * For each slot name, returns the indices for image width, height and depth
 * Indices [0, 1, 2] correspond respectively to [Sagittal, Coronal, Axial]
 *
 * For example: `[slotNamesPerPlane.AXIAL]: { width: 0, height: 1, depth: 2 }` means
 * that for images acquired using in the axial plane, the width is the Sagittal axis,
 * the height is the Coronal axis and the depth is the Axial axis
 *
 * For an image acquired in the Coronal plane, if dimensions is the dimensions of
 * the volume in [Sagittal, Coronal, Axial] order, then the height will be:
 * `dimensions[indicesPerSlotName[slotNamesPerPlane.CORONAL].height]`
 */
export const getIndicesPerSlotName = (
  medicalMetadata: MedicalMetadata | undefined,
): Record<string, { width: number; height: number; depth: number }> => {
  const slotNamesPerPlane = getSlotNamesPerPlane(medicalMetadata)

  return {
    [slotNamesPerPlane.AXIAL]: { width: 0, height: 1, depth: 2 },
    [slotNamesPerPlane.CORONAL]: { width: 0, height: 2, depth: 1 },
    [slotNamesPerPlane.SAGITTAL]: { width: 1, height: 2, depth: 0 },
  }
}
