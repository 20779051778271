export enum Actions {
  onGetSection = 'framesLoaderWorker:onGetSection',
  onFrameLoaded = 'framesLoaderWorker:onFrameLoaded',

  pushSections = 'framesLoaderWorker:pushSections',
  loadLQFrame = 'framesLoaderWorker:loadLQFrame',
  loadHQFrame = 'framesLoaderWorker:loadHQFrame',
  setFramesToLoad = 'framesLoaderWorker:setFramesToLoad',
  setNextFrameToLoad = 'framesLoaderWorker:setNextFrameToLoad',
  setFrameInvalid = 'framesLoaderWorker:setFrameInvalid',

  setConfig = 'framesLoaderWorker:setConfig',
  cleanup = 'framesLoaderWorker:cleanup',
}

export type FramesLoaderConfig = {
  hqOnly?: boolean
  dicomVolumePrefetching?: boolean
  hardwareConcurrency?: number
}

export type Frame = { hq_url: string; lq_url?: string; section_index: number }
