import type { RenderingContext2D } from '@/modules/Editor/types'
// eslint-disable-next-line boundaries/element-types
import { rgbaString } from '@/uiKit/colorPalette'

import { OCCLUDED_COLOR } from './drawVerticesByType'

type DrawSkeletonProps = {
  strokeColor: string
  lineWidth: number
  pointSize: number
  nodes: {
    x: number
    y: number
    name: string
    occluded: boolean
  }[]
  edges: {
    from: string
    to: string
  }[]
}

export const drawSkeleton = (
  ctx: RenderingContext2D,
  { strokeColor, lineWidth, nodes, edges }: DrawSkeletonProps,
): Path2D => {
  ctx.lineWidth = lineWidth

  const path = new Path2D()
  for (const edge of edges) {
    const fromNode = nodes.find((node) => node.name === edge.from)
    const toNode = nodes.find((node) => node.name === edge.to)
    if (!fromNode || !toNode) {
      continue
    }

    const pathEdge = new Path2D()
    if (fromNode.occluded || toNode.occluded) {
      ctx.strokeStyle = rgbaString(OCCLUDED_COLOR)
    } else {
      ctx.strokeStyle = strokeColor
    }
    pathEdge.moveTo(fromNode.x, fromNode.y)
    pathEdge.lineTo(toNode.x, toNode.y)
    ctx.stroke(pathEdge)
    path.addPath(pathEdge)
  }

  return path
}
