import type { CommentThread } from '@/modules/Editor/iproviders/types'
import type { ApiResponse } from '@/store/types'
import { post } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { isErrorResponse, errorMessages, parseError } from '@/backend/error'

type Params = {
  boundingBox: { x: number; y: number; w: number; h: number }
  comments: { body: string }[]
  datasetItemId: string
  sectionIndex?: number
  slotName: string
  teamSlug: string
}

/**
 * Creates a comment thread on a 2.0 dataset item
 */
export const createV2CommentThread = async (
  params: Params,
): Promise<ApiResponse<CommentThread> | ParsedError> => {
  const {
    boundingBox: { x, y, w, h },
    comments,
    datasetItemId,
    sectionIndex,
    slotName,
    teamSlug,
  } = params
  const path = `v2/teams/${teamSlug}/items/${datasetItemId}/comment_threads`

  const payload = {
    bounding_box: { x, y, w, h },
    comments,
    section_index: Number.isFinite(sectionIndex) ? sectionIndex : null,
    slot_name: slotName,
  }

  try {
    const response = await post<CommentThread>(path, payload)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.COMMENT_THREAD_CREATE)
  }
}
