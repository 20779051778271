// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'
// eslint-disable-next-line boundaries/element-types
import { rgbaString } from '@/uiKit/colorPalette'

/**
 * Produce the strokeStyle string based on arguments.
 * @param color
 * @param borderOpacity
 * @param inferred
 * @param isSelected
 * @returns {string}
 */
export const strokeStyle = (
  color: RGBA,
  borderOpacity: number | null,
  inferred: boolean,
  isSelected: boolean,
): string => {
  if (borderOpacity === null) {
    const alpha = inferred || isSelected ? 1 : 0.5
    return rgbaString(color, alpha)
  }

  const alpha = borderOpacity / 100.0
  return rgbaString(color, alpha)
}
