import type { ComputedRef } from 'vue'
import { computed, shallowRef, triggerRef } from 'vue'

import type { ContainsId } from '@/core/dataStructures/MapLinkedList'
import { MapLinkedList } from '@/core/dataStructures/MapLinkedList'
import { defineComposable } from '@/core/utils/defineComposable'

export const useReactiveMapLinkedList = defineComposable(<T extends ContainsId>() => {
  const mapLinkedList = shallowRef(new MapLinkedList<T>())

  const size = computed(() => mapLinkedList.value.size)

  const contains = (id: string): boolean => mapLinkedList.value.contains(id)

  const get = (id: string): T | null => mapLinkedList.value.get(id)

  const getRef = (id: string): ComputedRef<T | null> => computed(() => mapLinkedList.value.get(id))

  const set = (id: string, val: T): void => {
    mapLinkedList.value.set(id, val)
    triggerRef(mapLinkedList)
  }

  const getFirst = (): T | null => mapLinkedList.value.getFirst()

  const getLast = (): T | null => mapLinkedList.value.getLast()

  const getBefore = (id: string): T | null => mapLinkedList.value.getBefore(id)

  const getAfter = (id: string): T | null => mapLinkedList.value.getAfter(id)

  const addLast = (value: T, skipTrigger?: boolean): void => {
    mapLinkedList.value.addLast(value)

    if (skipTrigger) {
      return
    }
    triggerRef(mapLinkedList)
  }

  const addFirst = (value: T, skipTrigger?: boolean): void => {
    mapLinkedList.value.addFirst(value)

    if (skipTrigger) {
      return
    }
    triggerRef(mapLinkedList)
  }

  const addBefore = (value: T, beforeId: string): void => {
    mapLinkedList.value.addBefore(value, beforeId)
    triggerRef(mapLinkedList)
  }

  const addAfter = (value: T, afterId: string): void => {
    mapLinkedList.value.addAfter(value, afterId)
    triggerRef(mapLinkedList)
  }

  const remove = (id: string): void => {
    mapLinkedList.value.remove(id)
    triggerRef(mapLinkedList)
  }

  const indexOf = (id: string): number => mapLinkedList.value.indexOf(id)

  const clear = (): void => {
    mapLinkedList.value.clear()
    triggerRef(mapLinkedList)
  }

  const addFromArray = (
    renderableItemList: IterableIterator<T> | T[],
    passFn?: (item: T) => boolean,
  ): void => {
    mapLinkedList.value.addFromArray(renderableItemList, passFn)
    triggerRef(mapLinkedList)
  }

  const values = computed(() => mapLinkedList.value.values())

  const _triggerRef = (): void => triggerRef(mapLinkedList)

  return {
    size,
    contains,
    get,
    getRef,
    set,
    getFirst,
    getLast,
    getBefore,
    getAfter,
    addLast,
    addFirst,
    addBefore,
    addAfter,
    remove,
    indexOf,
    clear,
    addFromArray,
    values,

    triggerRef: _triggerRef,
  }
})
