import colors from '@/assets/styles/modules/colors.module.scss'
import shadows from '@/assets/styles/modules/shadows.module.scss'
import fonts from '@/assets/styles/modules/typography.module.scss'
import styles from '@/assets/styles/ui.module.scss'

/**
 * Single module holding logic around css which needs to be accessed from .ts
 * for some reason.
 *
 * Long term, individual functions should be exported, rather than a single large
 * theme object.
 */

/**
 * Returns the value for the specified named color used in css
 * When not found, returns 'transparent'
 */
export const getColor = (name: string): string => colors[name] || 'transparent'

/**
 * Returns the css for the specified named shadow
 */
export const getShadow = (name: 'shadowSM'): string => shadows[name]

/**
 * Returns the width of the main app sidebar when expanded, in pixels
 */
export const getSidebarExpandedWidth = (): number => parseFloat(styles.sidebar_expanded_width)

/**
 * Returns the width of the main app sidebar when collapsed, in pixels
 */
export const getSidebarCollapsedWidth = (): number => parseFloat(styles.sidebar_collapsed_width)

export const getFont = (family: 'headlines'): string => fonts[family]
