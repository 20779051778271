import { defineAsyncComponent } from 'vue'
import type { RouteConfig } from 'vue-router'

export const workflowsRoute: RouteConfig = {
  path: '/workflows',
  name: 'Workflows',
  component: defineAsyncComponent(() => import('@/modules/Workflows/RouteWorkflows.vue')),
  meta: { requiresAuth: true },
}

export const workflowRoute: RouteConfig = {
  path: '/workflows/:workflowId',
  name: 'WorkflowCreation',
  component: defineAsyncComponent(() => import('@/modules/Workflows/RouteWorkflow.vue')),
  meta: { requiresAuth: true },
}
