import type { FrameData } from '@/core/annotations'
import type { BBox } from '@/modules/Editor/types'
import { calcCentroidByType } from '@/modules/Editor/utils/calcCentroidByType'

import { calcBBoxForVectorByType } from './calcBBoxForVectorByType'

export const calcBoundingBoxBBox = (data: FrameData): BBox | undefined => {
  const { bounding_box } = data

  if (!bounding_box) {
    return
  }

  const { x, y, w, h } = bounding_box

  const bbox = {
    x: x + w / 2,
    y: y + h / 2,
    width: w,
    height: h,
  }

  if (data.directional_vector) {
    const c = calcCentroidByType('bounding_box', data)

    if (!c) {
      return bbox
    }

    return calcBBoxForVectorByType(data.directional_vector, c, bbox)
  }

  return bbox
}
