export type PointerEvent = MouseEvent | TouchEvent

export const isTouchEvent = (event: PointerEvent): event is TouchEvent => {
  if ('targetTouches' in event) {
    return true
  }
  return false
}

const getFirstTouch = (event: TouchEvent): { x: number; y: number } | null => {
  const target = event.target as Element
  if (!target) {
    return null
  }

  const bcr = target.getBoundingClientRect()
  const touch = event.targetTouches[0]
  const x = touch.clientX - bcr.x
  const y = touch.clientY - bcr.y

  return { x, y }
}

export const resolveEventPoint = (
  event: PointerEvent,
  ignoreTouch: boolean = false,
): { x: number; y: number } | null => {
  if (event instanceof MouseEvent) {
    return { x: event.offsetX, y: event.offsetY }
  }
  if (isTouchEvent(event) && !ignoreTouch) {
    return getFirstTouch(event)
  }
  return null
}

export type IOSTouch = Touch & { touchType: TouchType }

export const isIOSTouch = (touch: Touch): touch is IOSTouch => 'touchType' in touch

export const TouchType = { DIRECT: 'direct', STYLUS: 'stylus' } as const
export type TouchType = (typeof TouchType)[keyof typeof TouchType]
