import type { IPoint } from '@/modules/Editor/point'
import type { BBox } from '@/modules/Editor/types'

export const MIN_BBOX_SIZE = 1

/**
 * Return a bounding box where x and y represent
 * the center/top-left of the bounding box
 * @param path array of coordinates
 * @param offCenter convert the x, y coordinates to represent
 * the center of the bounding box instead than top left corner
 * @returns a { x, y, width, height} bounding box
 */
export const calcPathBBox = (path: IPoint[], offCenter = true): BBox => {
  let xMin = Infinity
  let xMax = -Infinity
  let yMin = Infinity
  let yMax = -Infinity

  path.forEach((point: IPoint) => {
    xMin = Math.min(point.x, xMin)
    yMin = Math.min(point.y, yMin)
    xMax = Math.max(point.x, xMax)
    yMax = Math.max(point.y, yMax)
  })

  const width = Math.abs(xMax - xMin) || MIN_BBOX_SIZE
  const height = Math.abs(yMax - yMin) || MIN_BBOX_SIZE
  const x = offCenter ? (xMax + xMin) / 2 || Infinity : xMin
  const y = offCenter ? (yMax + yMin) / 2 || Infinity : yMin

  return {
    width,
    height,
    x,
    y,
  }
}
