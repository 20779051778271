import type { AnnotationType } from '@/core/annotationTypes'
import type { MaskBrushThreshold } from '@/modules/Editor/tools/types'

const maskAnnotationType: AnnotationType = 'mask'
const polygonAnnotationType: AnnotationType = 'polygon'

enum TipShape {
  Squared = 'squared',
  Round = 'round',
}

const DEFAULT_BRUSH_THRESHOLD: MaskBrushThreshold = {
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
} as const

export { maskAnnotationType, polygonAnnotationType, TipShape, DEFAULT_BRUSH_THRESHOLD }
