import type { Raster } from './Raster'
import { VideoRaster } from './VideoRaster'

/**
 * Returns a raster as a video raster for type interpretation, without casting.
 * Throws if this is not possible.
 */
export const assertVideoRaster = (raster: Raster): VideoRaster => {
  if (!(raster instanceof VideoRaster)) {
    throw new Error('Raster is not a VideoRaster')
  }

  return raster
}
