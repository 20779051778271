import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { PresetPayload } from './imageManipulationTypes'

export const useImageManipulationPresetsStore = defineStore('imageManipulationPresets', () => {
  const presets = ref<PresetPayload[]>([])

  const activePresetId = ref<PresetPayload['id']>()

  return { presets, activePresetId }
})
