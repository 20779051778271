import type { Bounds, Raster } from '@/modules/Editor/models/raster/Raster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import { assertImageRaster } from '@/modules/Editor/models/raster/assertImageRaster'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'

export const getLabelBounds = (raster: Raster, labelIndex: number, frameIndex: number): Bounds => {
  if (raster.type === RasterTypes.IMAGE) {
    const imageRaster = assertImageRaster(raster)
    return imageRaster.getBoundsForLabelIndex(labelIndex)
  }

  if (raster.type === RasterTypes.VIDEO) {
    const videoRaster = assertVideoRaster(raster)
    const frameBounds = videoRaster.getVideoBoundsForLabelIndexForFrame(labelIndex, frameIndex)
    if (frameBounds) {
      return frameBounds
    }
  }

  return {
    topLeft: { x: 0, y: 0 },
    bottomRight: { x: raster.width, y: raster.height },
  }
}
