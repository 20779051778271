import type { AutoAnnotateModel } from '@/pinia/useAutoAnnotateStore'
import { WindAuthAction } from '@/backend/darwin/WindAuthAction'
import type { ApiResult } from '@/backend/darwin/types'
import { errorMessages } from '@/backend/error/errors'

import { withAuth } from './api'

export const loadAvailableModels = (teamId: number): Promise<ApiResult<AutoAnnotateModel[]>> =>
  withAuth<AutoAnnotateModel[]>(
    { action: WindAuthAction.ViewModels, teamId },
    (client) => client.get<AutoAnnotateModel[]>('models', { params: { include_public: true } }),
    errorMessages.LOAD_EXTERNAL_MODELS,
  )
