import type { TeamAction } from '@/store/modules/team/types'
import type { TeamPayload } from '@/store/types/TeamPayload'
import * as api from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

/**
 * Soft-delete a team on the backend
 */
export const deleteTeam: TeamAction<TeamPayload, { success: boolean }> = async (_, team) => {
  try {
    return await api.put<{ success: boolean }>(`teams/${team.id}/archive`)
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.TEAM_DELETE)
  }
}
