// Standard yellow color used by a large number of DICOM viewers
const referenceLinesColor = {
  r: 255,
  g: 255,
  b: 0,
  a: 1,
}

// Half of the width/height of the crosshairs gap in canvas pixels.
const HALF_CROSSHAIRS_SIZE_CANVAS_PIXELS = 24

const CROSSHAIRS_EDGE_SPACING_LR = 24
const CROSSHAIRS_EDGE_SPACING_TB = 32

enum SLOT_NAMES {
  AXIAL = '0.1',
  CORONAL = '0.2',
  SAGITTAL = '0.3',
}

export {
  referenceLinesColor,
  CROSSHAIRS_EDGE_SPACING_LR,
  CROSSHAIRS_EDGE_SPACING_TB,
  HALF_CROSSHAIRS_SIZE_CANVAS_PIXELS,
  SLOT_NAMES,
}
