import type { AdvancedFilters } from '@/modules/AdvancedFilters/filters'
import { normalizeFilter } from '@/modules/AdvancedFilters/normalizeFilter'
import type { PaginationParams } from '@/store/types'
import { post } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

type ResponseType = { item_ids: string[] }

type Params = {
  filter: AdvancedFilters
  teamSlug: string
  page?: PaginationParams
  dataset_ids: number[]
  sort: { [key: string]: string }
}

export const loadV2DatasetItemIds = async ({
  teamSlug,
  filter,
  page,
  dataset_ids,
  sort,
}: Params): Promise<{ data: ResponseType } | ParsedError> => {
  // if page is provided use the paginated endpoint
  const path = page
    ? `unstable/teams/${teamSlug}/items/list_ids`
    : `unstable/teams/${teamSlug}/items/list_all_ids`

  try {
    const response = await post<ResponseType>(path, {
      filter: normalizeFilter(filter),
      page,
      dataset_ids,
      sort,
    })
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.WORKVIEW_IMAGES_LOAD)
  }
}
