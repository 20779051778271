import type { Editor } from '@/modules/Editor/editor'
import type { Layout } from '@/modules/Editor/layout'
import type { CrosshairsState } from '@/modules/Editor/plugins/crosshairsTool/types'
import { DicomView } from '@/modules/Editor/views/dicomView'
import { getSlotNamesPerPlane } from '@/modules/Editor/utils/radiology/getSlotNamesPerPlane'

const notDicomViewErrorMessage =
  'Crosshairs is being used on a non DICOM View. This should not be possible'

function getView(layout: Layout, slotName: string): DicomView {
  const desiredView = layout.getViewByName(slotName)

  if (!desiredView || !(desiredView instanceof DicomView)) {
    throw new Error(notDicomViewErrorMessage)
  }

  return desiredView
}

export function initCrosshairsState(editor: Editor): CrosshairsState {
  const { activeView, layout } = editor
  const slotNames = getSlotNamesPerPlane(activeView.fileManager.metadata?.medical)
  const axialView = getView(layout, slotNames.AXIAL)
  const coronalView = getView(layout, slotNames.CORONAL)
  const sagittalView = getView(layout, slotNames.SAGITTAL)

  if (!(activeView instanceof DicomView)) {
    throw new Error(notDicomViewErrorMessage)
  }

  return {
    previousImagePoint: undefined,
    axialView,
    coronalView,
    sagittalView,
    activeView,
  }
}
