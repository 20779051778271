import type { AnnotationType } from '@/core/annotationTypes'

import type { AnnotationData } from './AnnotationData'
import { getCompoundPathFromBoundingBox, isBoundingBox } from './annotationTypes/boundingBox'
import { getCompoundPathFromEllipse, isEllipse } from './annotationTypes/ellipse'
import { getCompoundPathFromEye, isEye } from './annotationTypes/eye'
import { getCompoundPathFromKeyPoint, isKeyPoint } from './annotationTypes/keypoint'
import { getCompoundPathFromPolygon, isPolygon } from './annotationTypes/polygon'
import { getCompoundPathFromPolyline, isPolyline } from './annotationTypes/polyline'
import { getCompoundPathFromSimpleTable, isSimpleTable } from './annotationTypes/simpleTable'
import { getCompoundPathFromSkeleton, isSkeleton } from './annotationTypes/skeleton'
import type { CompoundPath } from './compoundPath'
import { inferCurrentAnnotationData } from './inferCurrentAnnotationData'
import type { Annotation } from './models/annotation/Annotation'

const getAnnotationDataCompoundPath = (
  data: AnnotationData,
  type: AnnotationType,
): CompoundPath => {
  if (type === 'polygon' && isPolygon(data)) {
    return getCompoundPathFromPolygon(data)
  }

  if (type === 'bounding_box' && isBoundingBox(data)) {
    return getCompoundPathFromBoundingBox(data)
  }

  if (type === 'ellipse' && isEllipse(data)) {
    return getCompoundPathFromEllipse(data)
  }

  if (type === 'skeleton' && isSkeleton(data)) {
    return getCompoundPathFromSkeleton(data)
  }

  if (type === 'eye' && isEye(data)) {
    return getCompoundPathFromEye(data)
  }

  if (type === 'line' && isPolyline(data)) {
    return getCompoundPathFromPolyline(data)
  }

  if (type === 'keypoint' && isKeyPoint(data)) {
    return getCompoundPathFromKeyPoint(data)
  }

  if (type === 'simple_table' && isSimpleTable(data)) {
    return getCompoundPathFromSimpleTable(data)
  }

  return {
    path: [],
    additionalPaths: [],
  }
}

export const getAnnotationCompoundPathAtFrame = (
  annotation: Annotation,
  frameIndex: number,
): CompoundPath =>
  getAnnotationDataCompoundPath(inferCurrentAnnotationData(annotation, frameIndex), annotation.type)
