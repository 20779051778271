import { remove } from '@/backend/api'
import { errorMessages, parseError, isErrorResponse, type ParsedError } from '@/backend/error'
import type { AxiosResponse } from 'axios'

type Params = {
  teamSlug: string
  datasetSlug: string
  reportId: string
}

/**
 * Delete report item by it's id
 */
export const deleteDatasetItemReport = async (
  params: Params,
): Promise<AxiosResponse | ParsedError> => {
  const path = `teams/${params.teamSlug}/${params.datasetSlug}/item_reports/${params.reportId}`

  try {
    return await remove<void>(path)
  } catch (err) {
    if (!isErrorResponse(err)) {
      throw err
    }
    return parseError(err, errorMessages.DATASET_ITEM_REPORT_DELETE)
  }
}
