import * as api from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'
import type { ApiResult } from './types'

export const confirmFileUpload = async (payload: {
  teamSlug: string
  uploadId: string
}): Promise<ApiResult<null>> => {
  const { teamSlug, uploadId } = payload
  try {
    await api.post<null>(`v2/teams/${teamSlug}/items/uploads/${uploadId}/confirm`)
    return { ok: true, data: null }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return { ...parseError(error, errorMessages.DATASET_VIDEO_UPLOAD_CONFIRMATION), ok: false }
  }
}
