import { euclideanDistance } from './algebra'

export interface IPoint {
  x: number
  y: number
}

/** Mutates the given point by adding the coordinates of another point to it */
export const addPointMutate = (point: IPoint, offset: IPoint): void => {
  point.x += offset.x
  point.y += offset.y
}

/** Mutates the given point by subtracting the coordinates of another point from it */
export const subPointMutate = (point: IPoint, offset: IPoint): void => {
  point.x -= offset.x
  point.y -= offset.y
}

/** Mutates the given point by dividing with a scalar */
export const divScalarMutate = (point: IPoint, divider: number): void => {
  point.x /= divider
  point.y /= divider
}

/** Mutates the given point by multiplying the coordinates with the coordinates for another point */
export const mulPointMutate = (point: IPoint, factor: IPoint): void => {
  point.x *= factor.x
  point.y *= factor.y
}

/**
 * Returns a new point where the coordinates are the sum of the two given points
 *
 * @param point Point to add to
 * @param offset Point to add
 * @returns New point with the coordinates of the offset added
 * @example
 * ```ts
 * const p1 = {x: 1, y: 2}
 * const p2 = addPoints(p1, {x: 2, y: 3})
 * expect(p2.x).toBe(3)
 * expect(p2.y).toBe(5)
 * ```
 */
export const addPoints = (point: IPoint, offset: IPoint): IPoint => ({
  x: point.x + offset.x,
  y: point.y + offset.y,
})

/**
 * Returns a new point where the coordinates are the the difference between the two given points
 * @param point Point to subtract from
 * @param offset Point to subtract
 * @returns New point with the coordinates of the offset subtracted
 * @example
 * ```ts
 * const p1 = {x: 1, y: 2}
 * const p2 = subPoints(p1, {x: 2, y: 3})
 * expect(p2.x).toBe(-1)
 * expect(p2.y).toBe(-1)
 * ```
 */
export const subPoints = (point: IPoint, offset: IPoint): IPoint => ({
  x: point.x - offset.x,
  y: point.y - offset.y,
})

/**
 * Returns a new Point where the coordinates are the product coordinates of the two given points
 *
 * @param point Point to multiply
 * @param factor Point to multiply with
 * @returns New point with the coordinates of the factor multiplied
 *
 * @example
 * ```ts
 * const p1 = {x: 1, y: 2}
 * const p2 = mulPoints(p1, {x: 2, y: 3})
 * expect(p2.x).toBe(2)
 * expect(p2.y).toBe(6)
 * ```
 */
export const mulPoints = (point: IPoint, factor: IPoint): IPoint => ({
  x: point.x * factor.x,
  y: point.y * factor.y,
})

/**
 * Returns a new Point where the coordinates are the coordinates of the first
 * point multiplied by the given factor
 *
 * @param point Point to divide
 * @param divider Scalar to divide with
 * @returns New point with the coordinates of the point divided by the scalar
 *
 * @example
 * ```ts
 * const p1 = {x: 1, y: 2}
 * const p2 = mulScalar(p1, 2)
 * expect(p2.x).toBe(2)
 * expect(p2.y).toBe(4)
 * ```
 */
export const mulScalar = (point: IPoint, factor: number): IPoint => ({
  x: point.x * factor,
  y: point.y * factor,
})

/**
 * Returns a new point of the same type as the point passed in, while dividing
 * the coordinates with the given point
 *
 * @param point Point to divide
 * @param divider Point to divide with
 * @returns New point with the coordinates of the point divided by the divider
 *
 * @example
 * ```ts
 * const p1 = {x: 1, y: 2}
 * const p2 = divPoints(p1, {x: 2, y: 4})
 * expect(p2.x).toBe(0.5)
 * expect(p2.y).toBe(0.5)
 * ```
 */
export const divPoints = (point: IPoint, divider: IPoint): IPoint => ({
  x: point.x / divider.x,
  y: point.y / divider.y,
})

/**
 * Returns a new point of the same type as the point passed in, while dividing
 * the coordinates with the given scalar
 *
 * @param point Point to divide
 * @param divider Scalar to divide with
 * @returns New point with the coordinates of the point divided by the scalar
 *
 * @example
 * ```ts
 * const p1 = {x: 1, y: 2}
 * const p2 = divScalar(p1, 2)
 * expect(p2.x).toBe(0.5)
 * expect(p2.y).toBe(1)
 */
export const divScalar = (point: IPoint, divider: number): IPoint => ({
  x: point.x / divider,
  y: point.y / divider,
})

/**
 * Checks if `point` resides inside or outside a polygon described by `path`
 */
export const pointInPath = (point: IPoint, path: IPoint[]): boolean => {
  let inside = false
  for (let i = 0, j = path.length - 1; i < path.length; j = i++) {
    const xi = path[i].x
    const yi = path[i].y
    const xj = path[j].x
    const yj = path[j].y
    const intersect =
      yi > point.y !== yj > point.y && point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi
    if (intersect) {
      inside = !inside
    }
  }
  return inside
}

export const pointIsVertexOfPath = (
  point: IPoint,
  path: (IPoint | undefined)[],
  threshold: number,
): boolean => {
  for (const vertex of path) {
    if (!vertex) {
      return false
    }
    const distance = euclideanDistance(point, vertex)
    if (distance < threshold) {
      return true
    }
  }
  return false
}

export const anchorCursor = (currentPoint: IPoint, point: IPoint, nextPoint: IPoint): IPoint => {
  // Point is A, nextPoint is B, the cursor is C
  // Trying to find D on AB, so AB and CD are perpendicular
  const BA = subPoints(nextPoint, point)
  const t =
    ((currentPoint.x - point.x) * BA.x + (currentPoint.y - point.y) * BA.y) /
    (BA.x * BA.x + BA.y * BA.y + 0.00001)

  return { x: point.x + t * BA.x, y: point.y + t * BA.y }
}

/**
 * Returns true if the given point is on the given path, within a treshold
 * determined by the given camera scale.
 *
 * This is determined by taking points of the path 2 at a time and checking if
 * the given point is within the bounding box defined by those two points.
 *
 * The defined bounding box is grown on each side by 5 / camerascale, so on a
 * 100% zoom, it will be grown by 5px, on a 200% zoom, by 2.5 px,
 * and on a 50% zoom, by 10px.
 */
export const isPointOnPath = (point: IPoint, path: IPoint[], cameraScale: number): boolean => {
  const threshold = 5 / cameraScale

  for (let i = 0; i < path.length - 1; i++) {
    const head = path[i]
    const tail = path[i + 1]

    const pointOnLine = anchorCursor(point, head, tail)
    const distanceFromCursor = euclideanDistance(point, pointOnLine)

    // Is the point within the bounding box of the segment,
    // grown by the threshold
    const pointInSegment =
      point.x > Math.min(head.x, tail.x) - threshold &&
      point.y > Math.min(head.y, tail.y) - threshold &&
      point.x < Math.max(head.x, tail.x) + threshold &&
      point.y < Math.max(head.y, tail.y) + threshold

    if (pointInSegment && distanceFromCursor <= threshold) {
      return true
    }
  }

  return false
}

/**
 * EditablePoint is a point that can be selected and highlighted,
 * use for moving vertices in the workview.
 */
export type EditablePoint = IPoint & {
  isHighlighted: boolean
  isSelected: boolean
}

export const createEditablePoint = (p: IPoint): EditablePoint => ({
  x: p.x,
  y: p.y,
  isHighlighted: false,
  isSelected: false,
})
