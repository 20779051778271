/**
 * Calculates the line width to keep it visually the same for different scales.
 * @param width
 * @param scale
 * @returns {number}
 */
export const calcLineWidth = (width: number, scale: number): number => {
  // prevent division by zero
  if (scale === 0) {
    return width
  }

  return width / scale
}
