import type { Range } from '@/modules/Editor/types'

import { clipRangeToRasterBounds } from './clipRangeToRasterBounds'

/**
 * Updates the edit range to bound itself and the rangeToInclude
 * @param rangeToUpdate The range to update with the new range object.
 *
 * @param rangeToInclude The range to include.
 */
export const combineRange = (
  range1: Range,
  range2: Range,
  imageWidth: number,
  imageHeight: number,
): Range => {
  const newRange = {
    minX: Math.min(range1.minX, range2.minX),
    maxX: Math.max(range1.maxX, range2.maxX),
    minY: Math.min(range1.minY, range2.minY),
    maxY: Math.max(range1.maxY, range2.maxY),
  }

  return clipRangeToRasterBounds(newRange, imageWidth, imageHeight)
}
