import { hasSegmentContainingIndex } from '@/modules/Editor/helpers/segments'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'
import { isVideoAnnotation } from '@/modules/Editor/models/annotation/annotationKindValidator'
import type { View } from '@/modules/Editor/views/view'

import type { PolygonOrMaskBrushTool } from './tool'

export const brushSaveAndExitEditMode = async (
  view: View,
  tool: PolygonOrMaskBrushTool,
  context: ToolContext,
  newIndex: number,
  oldIndex?: number,
): Promise<void> => {
  if (!tool.selectedAnnotationId) {
    return
  }

  const annId = tool.selectedAnnotationId

  const annotation = view.annotationManager.getAnnotation(annId)

  if (!annotation || !isVideoAnnotation(annotation)) {
    return
  }

  if (hasSegmentContainingIndex(annotation.data?.segments, newIndex)) {
    return
  }

  if (oldIndex === undefined) {
    return
  }

  await tool.confirmCurrentAnnotation(context, oldIndex)

  view.annotationsLayer.show(annId)
  view.annotationManager.deselectAllAnnotations()
}
