/**
 * Allow to persist values into the browser
 * @property storage localStorage' | 'sessionStorage'
 * Choose between the localStorage and sessionStorage (defaults to localStorage)
 *
 * CONSIDER using useLocalStorage/useStorage form @vueuse/core
 */
export class PersistedSettings {
  private _storage

  constructor(storage: 'localStorage' | 'sessionStorage' = 'localStorage') {
    this._storage = storage === 'localStorage' ? window.localStorage : window.sessionStorage
  }

  // Boolean
  private _saveBoolean = (key: string, value: boolean): void => {
    this._storage.setItem(key, `${value}`)
  }

  private _loadBoolean = (key: string, fallback: boolean): boolean => {
    const setting = this._storage.getItem(key)
    if (setting === 'true') {
      return true
    }
    if (setting === 'false') {
      return false
    }
    return fallback
  }

  // String
  private _saveString = (key: string, value: string): void => this._storage.setItem(key, value)

  private _loadString = (key: string, fallback: string): string => {
    const setting = this._storage.getItem(key)
    return setting ? setting : fallback
  }

  // Number
  private _loadNumber = (key: string, fallback: number): number => {
    const setting = this._storage.getItem(key)
    if (!setting) {
      return fallback
    }

    const value = Number(setting)
    if (isNaN(value)) {
      return fallback
    }

    return value
  }

  private _saveNumber = (key: string, value: number): void => this._storage.setItem(key, `${value}`)

  public saveValue = (key: string, value: string | boolean | number): void => {
    if (typeof value === 'boolean') {
      this._saveBoolean(key, value)
    } else if (typeof value === 'number') {
      this._saveNumber(key, value)
    } else if (typeof value === 'string') {
      this._saveString(key, value)
    }
  }

  public loadValue = <T>(key: string, value: T | string = 'not set'): T => {
    if (typeof value === 'boolean') {
      return <T>this._loadBoolean(key, value)
    }
    if (typeof value === 'number') {
      return <T>this._loadNumber(key, value)
    }
    return <T>this._loadString(key, value as string)
  }

  public removeValue = (key: string): void => this._storage.removeItem(key)
}
