import type { PartialRecord } from '@/core/helperTypes'
import type { AnnotationData } from '@/modules/Editor/AnnotationData'

export type CachedInterpolatedData = {
  hash?: string
  data?: AnnotationData
}

// Interpolated Data Cache
const interpolatedDataCache: PartialRecord<string, CachedInterpolatedData> = {}

const clearInterpolatedData = (annotationId: string): void => {
  delete interpolatedDataCache[annotationId]
}

const getCachedInterpolatedData = (annotationId: string): CachedInterpolatedData | undefined =>
  interpolatedDataCache[annotationId]

const setCachedInterpolatedData = (
  annotationId: string,
  interpolatedData: CachedInterpolatedData,
): void => {
  interpolatedDataCache[annotationId] = interpolatedData
}

// Path2D Cache
const path2DCache: PartialRecord<string, Path2D> = {}

const clearPath2DCache = (annotationId: string): void => {
  delete path2DCache[annotationId]
}

const getPath2D = (annotationId: string): Path2D | undefined => path2DCache[annotationId]

const setPath2D = (annotationId: string, path2D: Path2D): void => {
  path2DCache[annotationId] = path2D
}

/**
 * Clears the rendering cache for a given annotation.
 *
 * This removes the cached path2D renderable, as well as any cached video interpolation data.
 *
 * In the future we will move path2D out also, and replace the interface with annotationId instead.
 */
const clearAnnotationRenderingCache = (annotationId: string): void => {
  clearPath2DCache(annotationId)
  clearInterpolatedData(annotationId)
}

export {
  // Interpolated Data Cache API
  getCachedInterpolatedData,
  setCachedInterpolatedData,
  clearAnnotationRenderingCache,
  // Path2D Cache API
  getPath2D,
  setPath2D,
  clearPath2DCache,
}
