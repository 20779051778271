import type { AnnotationData } from '@/modules/Editor/AnnotationData'
import { inferVideoDataForType } from '@/modules/Editor/inferVideoDataForType'
import type { IPoint } from '@/modules/Editor/point'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'
import { isVideoAnnotationData } from '@/modules/Editor/models/annotation/annotationKindValidator'

export const resolvePolygonPath = (
  context: ToolContext,
  annotationData: AnnotationData,
): IPoint[] => {
  if (isVideoAnnotationData(annotationData)) {
    const data = inferVideoDataForType(
      annotationData,
      'polygon',
      context.editor.activeView.currentFrameIndex,
    )
    return data.path || []
  }

  return annotationData.path || []
}
