import type { FrameData } from '@/core/annotations'
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import type { Eye, EyeData } from '@/modules/Editor/AnnotationData'
import { isEye } from '@/modules/Editor/annotationTypes/eye'
import { createEditablePoint } from '@/modules/Editor/point'

export const eyeSerializer = {
  serialize(data: Eye, metadata?: { eye?: AnnotationClass['eyeMetaData'] }): FrameData {
    if (!isEye(data)) {
      throw new Error('eye: expected annotation of eye type')
    }
    const nodes = data.nodes.map((node) => ({
      x: node.point.x,
      y: node.point.y,
      name: node.name,
      occluded: node.occluded,
    }))

    if (!metadata?.eye?.edges) {
      return { eye: { nodes } }
    }

    const {
      eye: { edges },
    } = metadata
    return { eye: { nodes, edges } }
  },

  deserialize(rawData: FrameData): Eye | null {
    if (!rawData.eye) {
      return null
    }

    const nodes = rawData.eye.nodes.map((node: EyeData['nodes'][0]) => ({
      point: createEditablePoint({ x: node.x, y: node.y }),
      name: node.name,
      occluded: node.occluded,
    }))

    return { nodes }
  },
}
