import type { EditablePoint } from './point'
import { createEditablePoint } from './point'

/**
 * Defines the shape of the bounding box for a comment thread, as stored on the backend
 */
export interface CommentBoundingBox {
  x: number
  y: number
  w: number
  h: number
}

/**
 * Defines the 4 editable vertices of a comment thread on canvas
 */
export type CommentVertices = {
  topLeft: EditablePoint
  topRight: EditablePoint
  bottomRight: EditablePoint
  bottomLeft: EditablePoint
}

export const COMMENT_THREAD_BOUNDING_BOX_COLOR = { r: 255, g: 255, b: 255, a: 0.5 }

export const verticesToBoundingBox = (commentBox: CommentVertices): CommentBoundingBox => {
  const { x: left, y: top } = commentBox.topLeft
  const { x: right, y: bottom } = commentBox.bottomRight
  return {
    x: left,
    y: top,
    w: right - left,
    h: bottom - top,
  }
}

export const boundingBoxToVertices = (rawData: CommentBoundingBox): CommentVertices => {
  const topLeft = createEditablePoint({ x: rawData.x, y: rawData.y })
  const bottomRight = createEditablePoint({ x: rawData.x + rawData.w, y: rawData.y + rawData.h })
  const topRight = createEditablePoint({ x: bottomRight.x, y: topLeft.y })
  const bottomLeft = createEditablePoint({ x: topLeft.x, y: bottomRight.y })
  return {
    topLeft,
    bottomRight,
    topRight,
    bottomLeft,
  }
}

export const COMMENT_THREAD_SHADOW_COLOR = 'rgba(0, 0, 0, 0.3)'
export const COMMENT_THREAD_SHADOW_BLUR = 2
export const COMMENT_THREAD_SHADOW_OFFSET_Y = 1
export const COMMENT_THREAD_STROKE_OPACITY = 1.0
export const COMMENT_THREAD_VERTEX_SIZE = 4
