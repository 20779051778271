import type { InferenceResult } from '@/modules/Editor/backend'
import type { IPoint } from '@/modules/Editor/point'
import type { PointMapping } from '@/modules/Editor/plugins/click/types'

const remapPoint = (point: { x: number; y: number }, mapping: PointMapping): IPoint =>
  mapping.backward({ ...point })

const remapPath = (path: { x: number; y: number }[], mapping: PointMapping): IPoint[] =>
  path.map((point) => remapPoint(point, mapping))

export const remapInferenceResult = (
  inferenceData: InferenceResult,
  mapping: PointMapping,
): InferenceResult => {
  const {
    bounding_box: boundingBox,
    complex_polygon: complexPolygon,
    path,
    polygon,
    keypoint,
    line,
  } = inferenceData
  if (path) {
    return { ...inferenceData, path: remapPath(path, mapping) }
  }

  if (polygon) {
    return {
      ...inferenceData,
      polygon: {
        path: remapPath(polygon.path, mapping),
        additional_paths:
          polygon.additional_paths?.map((path) => remapPath(path, mapping)) || undefined,
      },
    }
  }

  if (keypoint) {
    return { ...inferenceData, keypoint: remapPoint(keypoint, mapping) }
  }

  if (line) {
    return { ...inferenceData, line: { path: remapPath(line.path, mapping) } }
  }

  if (complexPolygon) {
    return {
      ...inferenceData,
      complex_polygon: complexPolygon.map((path) => remapPath(path, mapping)),
    }
  }

  if (boundingBox) {
    const { x: xmin, y: ymin, w, h } = boundingBox
    const xmax = xmin + w
    const ymax = ymin + h

    const { x: mappedXmin, y: mappedYmin } = remapPoint({ x: xmin, y: ymin }, mapping)
    const { x: mappedXmax, y: mappedYmax } = remapPoint({ x: xmax, y: ymax }, mapping)
    return {
      ...inferenceData,
      bounding_box: {
        x: mappedXmin,
        y: mappedYmin,
        w: mappedXmax - mappedXmin,
        h: mappedYmax - mappedYmin,
      },
    }
  }

  return inferenceData
}
