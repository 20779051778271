import { euclideanDistance } from '@/modules/Editor/algebra'
import type { IPoint } from '@/modules/Editor/point'
import { addPoints, divScalar, mulScalar, subPoints } from '@/modules/Editor/point'

export const interpolateBetweenPoints = (
  initialPoint: IPoint,
  finalPoint: IPoint,
  interpolationLength: number = 0.2,
): IPoint[] => {
  const interpolatedPoints = []

  const diffVector = subPoints(finalPoint, initialPoint)
  const norm = euclideanDistance(finalPoint, initialPoint) / interpolationLength
  const normedDiffVector = divScalar(diffVector, norm)

  for (let i = interpolationLength; i < norm; i += interpolationLength) {
    interpolatedPoints.push(addPoints(initialPoint, mulScalar(normedDiffVector, i)))
  }

  return interpolatedPoints
}
