import { computedWithControl } from '@vueuse/core'
import RBush from 'rbush'
import type { ComputedRef } from 'vue'
import { ref } from 'vue'

/**
 * Provides an interface to the RBush data structure with the reactive search
 * for unsorted list of IDs by x and y ranges.
 *
 * Y - Slot name index
 * ^
 * |
 * |
 * . ― ― > X - Frame index
 *
 * Set min & max for x & y ranges with the item
 * so you can search for the items in the given range later.
 */
export const useReactiveRTree = <T extends { id: string }>(): {
  setItem: (item: T) => void
  setItems: (items: Map<string, T>) => void
  removeItem: (id: string) => void
  clear: () => void
  getReactiveIds: (
    xRange: ComputedRef<[number, number]>,
    yRange: ComputedRef<[number, number]>,
  ) => ComputedRef<{ id: string }[]>
  getIds: (xRange: [number, number], yRange: [number, number]) => { id: string }[]
} => {
  const rBushInstance = new RBush<T>(5)
  let rTreePayload = new Map<string, T>()
  const reactiveTrigger = ref(0)

  /** Adds the item to the tree and trigger the reactivity */
  const setItem = (item: T): void => {
    rTreePayload.set(item.id, item)

    rBushInstance.clear()
    rBushInstance.load(Array.from(rTreePayload.values()))

    reactiveTrigger.value++
  }

  /** Adds the items to the tree and trigger the reactivity */
  const setItems = (items: Map<string, T>): void => {
    rTreePayload.clear()
    rTreePayload = items

    rBushInstance.clear()
    rBushInstance.load(Array.from(rTreePayload.values()))
    reactiveTrigger.value++
  }

  /** Removes the item from the tree and trigger the reactivity */
  const removeItem = (id: string): void => {
    rTreePayload.delete(id)
    rBushInstance.clear()
    rBushInstance.load(Array.from(rTreePayload.values()))
    reactiveTrigger.value++
  }

  /** Clears the tree */
  const clear = (): void => {
    rTreePayload.clear()
    rBushInstance.clear()
    reactiveTrigger.value = 0
  }

  /**
   * Returns the reactive list of IDs that are in the given ranges
   * Every time the x or y range is changed the returned reactive list is updated
   */
  const getReactiveIds = (
    xRange: ComputedRef<[number, number]>,
    yRange: ComputedRef<[number, number]>,
  ): ComputedRef<{ id: string }[]> =>
    computedWithControl<{ id: string }[], [number, number] | number>(
      [reactiveTrigger, xRange, yRange],
      () =>
        rBushInstance.search({
          minX: xRange.value[0],
          maxX: xRange.value[1],
          minY: yRange.value[0],
          maxY: yRange.value[1],
        }),
    )

  const getIds = (xRange: [number, number], yRange: [number, number]): { id: string }[] =>
    rBushInstance.search({
      minX: xRange[0],
      maxX: xRange[1],
      minY: yRange[0],
      maxY: yRange[1],
    })

  return {
    setItem,
    setItems,
    removeItem,
    clear,
    getReactiveIds,
    getIds,
  }
}
