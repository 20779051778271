import type { ExtendedFileMetadata } from '@/modules/Editor/metadata'
import { getRasterPlaneMapForAcquisitionPlane } from './getRasterPlaneMapForAcquisitionPlane'
import type { Plane } from './Plane'

export const getRasterPlaneFromRadiologySlotName = (
  slotName: string,
  metadata: ExtendedFileMetadata | undefined,
): Plane | undefined => {
  const rasterPlaneMap = getRasterPlaneMapForAcquisitionPlane(metadata)
  return rasterPlaneMap[slotName]
}
