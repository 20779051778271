import { WindAuthAction } from '@/backend/darwin/WindAuthAction'
import type { ApiResult } from '@/backend/darwin/types'
import { errorMessages } from '@/backend/error'

import { withAuth } from './api'
import type { ExternalModelResponsePayload } from './types'

/**
 * Deletes a V2 external model
 */
export const deleteExternalModel = (
  modelId: string,
  teamId: number,
): Promise<ApiResult<ExternalModelResponsePayload>> =>
  withAuth(
    { action: WindAuthAction.DeployModel, teamId },
    (client) => client.delete(`external_models/${modelId}`),
    errorMessages.DELETE_EXTERNAL_MODEL,
  )
