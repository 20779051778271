import { MainAnnotationType, type AnnotationType, SubAnnotationType } from '@/core/annotationTypes'
import type { FrameData } from '@/core/annotations'

import type { AnnotationData, DirectionalVector, InstanceID } from '@/modules/Editor/AnnotationData'
import { annotationDataIsEmpty } from '@/modules/Editor/utils/pagination'
import { isPolygon } from '@/modules/Editor/annotationTypes/polygon'
import { isBoundingBox } from '@/modules/Editor/annotationTypes/boundingBox'
import { setContext } from '@/services/sentry'

import { attributesSerializer } from './attributesSerializer'
import { boundingBoxSerializer } from './boundingBoxSerializer'
import { directionalVectorSerializer } from './directionalVectorSerializer'
import { ellipseSerializer } from './ellipseSerializer'
import { eyeSerializer } from './eyeSerializer'
import { inferenceSerializer } from './inferenceSerializer'
import { instanceIdSerializer } from './instanceIdSerializer'
import { keypointSerializer } from './keypointSerializer'
import { polygonSerializer } from './polygonSerializer'
import { polylineSerializer } from './polylineSerializer'
import { simpleTableSerializer } from './simpleTableSerializer'
import { skeletonSerializer } from './skeletonSerializer'
import { tagSerializer } from './tagSerializer'
import { textSerializer } from './textSerializer'
import { isEllipse } from '@/modules/Editor/annotationTypes/ellipse'
import { isEye } from '@/modules/Editor/annotationTypes/eye'
import { isKeyPoint } from '@/modules/Editor/annotationTypes/keypoint'
import { isPolyline } from '@/modules/Editor/annotationTypes/polyline'
import { isSimpleTable } from '@/modules/Editor/annotationTypes/simpleTable'
import { isSkeleton } from '@/modules/Editor/annotationTypes/skeleton'

const isDirectionalVector = (data: AnnotationData): data is DirectionalVector =>
  'angle' in data && 'length' in data

export const serializeFrame = (
  type: AnnotationType,
  data: AnnotationData,
  extra?: Parameters<typeof skeletonSerializer.serialize>[1] &
    Parameters<typeof eyeSerializer.serialize>[1],
): FrameData => {
  if (typeof data === 'object' && annotationDataIsEmpty(data)) {
    setContext('data', { data, extra, type })
    throw new Error('frame serializer: annotation with empty frame detected')
  }

  if (type === SubAnnotationType.Attributes) {
    return attributesSerializer.serialize(data)
  }

  if (type === MainAnnotationType.BoundingBox && isBoundingBox(data)) {
    return boundingBoxSerializer.serialize(data)
  }

  if (type === SubAnnotationType.DirectionalVector && isDirectionalVector(data)) {
    return directionalVectorSerializer.serialize(data)
  }

  if (type === MainAnnotationType.Ellipse && isEllipse(data)) {
    return ellipseSerializer.serialize(data)
  }

  if (type === MainAnnotationType.Eye && isEye(data)) {
    return eyeSerializer.serialize(data, { eye: extra?.eye })
  }

  if (type === SubAnnotationType.Inference) {
    return inferenceSerializer.serialize(data)
  }

  if (type === SubAnnotationType.InstanceId) {
    return instanceIdSerializer.serialize(data as InstanceID)
  }

  if (type === MainAnnotationType.Keypoint && isKeyPoint(data)) {
    return keypointSerializer.serialize(data)
  }

  if (type === MainAnnotationType.Polygon && isPolygon(data)) {
    return polygonSerializer.serialize(data)
  }

  if (type === MainAnnotationType.Polyline && isPolyline(data)) {
    return polylineSerializer.serialize(data)
  }

  if (type === MainAnnotationType.SimpleTable && isSimpleTable(data)) {
    return simpleTableSerializer.serialize(data)
  }

  if (type === MainAnnotationType.Skeleton && isSkeleton(data)) {
    return skeletonSerializer.serialize(data, { skeleton: extra?.skeleton })
  }

  if (type === MainAnnotationType.Tag) {
    return tagSerializer.serialize(data)
  }

  if (type === SubAnnotationType.Text) {
    return textSerializer.serialize(data)
  }

  return {}
}
