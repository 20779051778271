/*
 * Original:
 * @copyright 2016 Sean Connelly (@voidqk), http://syntheti.cc
 * @license MIT
 * @preserve Project Home: https://github.com/voidqk/polybooljs
 *
 * This is now a fork of polybooljs, forked from the last material, non README commit:
 * hash: 7d12dd3368e5fb3a1a9d2b84a964962cc8ba71b1
 * date: July 22nd 2017
 *
 * We have typescriptified the interface here and aim to slowly unwrap this library and
 * make it more functional.
 */
import BuildLog from './lib/build-log'
import Epsilon from './lib/epsilon'
import GeoJSON from './lib/geojson'
import Intersecter from './lib/intersecter'
import SegmentChainer from './lib/segment-chainer'
import SegmentSelector from './lib/segment-selector'
import './types'
import type { Combined, Polygon, Segment, GeoJSON as GeoJSONType } from './types'

type BuildLogType = ReturnType<typeof BuildLog> | false

let buildLogInstance: BuildLogType = false

/**
 * Epsilon gives the distance between 1 and the next largest floating point number on your computer.
 * @example if (Math.abs(A - B) < epsilon)
 * // A and B are equal
 * else
 * // A and B are not equal
 *
 * Floating point calculations are not exactly perfect.
 * This is a problem when trying to detect whether lines are on top of each other,
 * or if vertices are exactly the same.
 *
 * If PolyBool detects that your epsilon is too small or too large,
 * it will throw an error that a zero-length segment was detected.
 * This might happen if two points are very close to each other
 * and the epsilon is not enough to distinguish them.
 *
 * The default epsilon value is 0.0000000001.
 * Increasing it will reduce the precision.
 *
 * Given that we are working with pixels,
 * we probably do not need precision up to 10 digits after the decimal point. */
const epsilonValue = Epsilon(0.00000001)

// getter/setter for buildLog
// NOTE: We may just be able to remove this, we aren't using it right now.
const buildLog = (bl: boolean): unknown => {
  if (bl === true) {
    buildLogInstance = BuildLog()
  } else if (bl === false) {
    buildLogInstance = false
  }
  return buildLogInstance === false ? false : buildLogInstance.list
}
// getter/setter for epsilon
const epsilon = (value?: number): number => epsilonValue.epsilon(value)

// core API
const segments = (poly: Polygon): Segment => {
  const i = Intersecter(true, epsilonValue, buildLogInstance)
  poly.regions.forEach((r) => i.addRegion && i.addRegion(r))
  return {
    segments: i.calculate(poly.inverted),
    inverted: poly.inverted,
  }
}

const combine = (segments1: Segment, segments2: Segment): Combined => {
  const i3 = Intersecter(false, epsilonValue, buildLogInstance)

  return {
    combined: i3.calculate(
      segments1.segments,
      segments1.inverted,
      segments2.segments,
      segments2.inverted,
    ),
    inverted1: segments1.inverted,
    inverted2: segments2.inverted,
  }
}
const selectUnion = (combined: Combined): Segment => ({
  segments: SegmentSelector.union(combined.combined, buildLogInstance),
  inverted: combined.inverted1 || combined.inverted2,
})
const selectIntersect = (combined: Combined): Segment => ({
  segments: SegmentSelector.intersect(combined.combined, buildLogInstance),
  inverted: combined.inverted1 && combined.inverted2,
})
const selectDifference = (combined: Combined): Segment => ({
  segments: SegmentSelector.difference(combined.combined, buildLogInstance),
  inverted: combined.inverted1 && !combined.inverted2,
})
const selectDifferenceRev = (combined: Combined): Segment => ({
  segments: SegmentSelector.differenceRev(combined.combined, buildLogInstance),
  inverted: !combined.inverted1 && combined.inverted2,
})
const selectXor = (combined: Combined): Segment => ({
  segments: SegmentSelector.xor(combined.combined, buildLogInstance),
  inverted: combined.inverted1 !== combined.inverted2,
})
const polygon = (segments: Segment): Polygon => ({
  regions: SegmentChainer(segments.segments, epsilonValue, buildLogInstance),
  inverted: segments.inverted,
})

function operate(
  poly1: Polygon,
  poly2: Polygon,
  selector: (combined: Combined) => Segment,
): Polygon {
  const seg1 = segments(poly1)
  const seg2 = segments(poly2)
  const comb = combine(seg1, seg2)
  const seg3 = selector(comb)
  return polygon(seg3)
}

// helper functions for common operations
const union = (poly1: Polygon, poly2: Polygon): Polygon => operate(poly1, poly2, selectUnion)
const intersect = (poly1: Polygon, poly2: Polygon): Polygon =>
  operate(poly1, poly2, selectIntersect)
const difference = (poly1: Polygon, poly2: Polygon): Polygon =>
  operate(poly1, poly2, selectDifference)
const differenceRev = (poly1: Polygon, poly2: Polygon): Polygon =>
  operate(poly1, poly2, selectDifferenceRev)
const xor = (poly1: Polygon, poly2: Polygon): Polygon => operate(poly1, poly2, selectXor)

// GeoJSON converters
const polygonFromGeoJSON = (geojson: GeoJSONType): Polygon =>
  GeoJSON.toPolygon({ segments, selectDifference, combine, selectUnion }, geojson)
const polygonToGeoJSON = (poly: Polygon): GeoJSONType =>
  // the cast is necessary because the internal type says the "type" field on it is a string
  GeoJSON.fromPolygon(
    { segments, selectDifference, combine, selectUnion, polygon },
    epsilonValue,
    poly,
  ) as GeoJSONType

const PolyBool = {
  buildLog,
  epsilon,
  segments,
  combine,
  selectUnion,
  selectIntersect,
  selectDifference,
  selectDifferenceRev,
  selectXor,
  polygon,
  polygonFromGeoJSON,
  polygonToGeoJSON,
  union,
  intersect,
  difference,
  differenceRev,
  xor,
}

export {
  buildLog,
  epsilon,
  segments,
  combine,
  selectUnion,
  selectIntersect,
  selectDifference,
  selectDifferenceRev,
  selectXor,
  polygon,
  polygonFromGeoJSON,
  polygonToGeoJSON,
  union,
  intersect,
  difference,
  differenceRev,
  xor,
}

export { PolyBool }
