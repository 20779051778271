import { MainAnnotationType, type AnnotationType, SubAnnotationType } from '@/core/annotationTypes'
import type { FrameData } from '@/core/annotations'
import type { AnnotationData } from '@/modules/Editor/AnnotationData'

import { attributesSerializer } from './attributesSerializer'
import { boundingBoxSerializer } from './boundingBoxSerializer'
import { directionalVectorSerializer } from './directionalVectorSerializer'
import { ellipseSerializer } from './ellipseSerializer'
import { eyeSerializer } from './eyeSerializer'
import { inferenceSerializer } from './inferenceSerializer'
import { instanceIdSerializer } from './instanceIdSerializer'
import { keypointSerializer } from './keypointSerializer'
import { polygonSerializer } from './polygonSerializer'
import { polylineSerializer } from './polylineSerializer'
import { simpleTableSerializer } from './simpleTableSerializer'
import { skeletonSerializer } from './skeletonSerializer'
import { tagSerializer } from './tagSerializer'
import { textSerializer } from './textSerializer'

export const deserializeFrame = (type: AnnotationType, data: FrameData): AnnotationData | null => {
  if (type === SubAnnotationType.Attributes) {
    return attributesSerializer.deserialize(data)
  }

  if (type === MainAnnotationType.BoundingBox) {
    return boundingBoxSerializer.deserialize(data)
  }

  if (type === SubAnnotationType.DirectionalVector) {
    return directionalVectorSerializer.deserialize(data)
  }

  if (type === MainAnnotationType.Ellipse) {
    return ellipseSerializer.deserialize(data)
  }

  if (type === MainAnnotationType.Eye) {
    return eyeSerializer.deserialize(data)
  }

  if (type === SubAnnotationType.Inference) {
    return inferenceSerializer.deserialize(data)
  }

  if (type === SubAnnotationType.InstanceId) {
    return instanceIdSerializer.deserialize(data)
  }

  if (type === MainAnnotationType.Keypoint) {
    return keypointSerializer.deserialize(data)
  }

  if (type === MainAnnotationType.Polygon) {
    return polygonSerializer.deserialize(data)
  }

  if (type === MainAnnotationType.Polyline) {
    return polylineSerializer.deserialize(data)
  }

  if (type === MainAnnotationType.SimpleTable) {
    return simpleTableSerializer.deserialize(data)
  }

  if (type === MainAnnotationType.Skeleton) {
    return skeletonSerializer.deserialize(data)
  }

  if (type === MainAnnotationType.Tag) {
    return tagSerializer.deserialize(data)
  }

  if (type === SubAnnotationType.Text) {
    return textSerializer.deserialize(data)
  }

  return {}
}
