import type { Bounds } from '@/modules/Editor/models/raster/Raster'
import type { VoxelRaster } from '@/modules/Editor/models/raster/VoxelRaster'
import { Plane } from '@/modules/Editor/utils/raster/Plane'

export const getBoundsOfSegmentOnReformat = (
  reformatFrameIndex: number,
  plane: Plane,
  xRangeInReformat: [number, number],
  voxelRaster: VoxelRaster,
  segmentOffset: number = 0,
): Bounds => {
  // work out bounds of line segment added.
  if (plane === Plane.X) {
    return {
      /**
       * NOTE: the bounds are from the left of the pixel
       * to the right of the last pixel, hence the +1.
       */
      topLeft: {
        x: Math.max(reformatFrameIndex - segmentOffset, 0),
        y: Math.max(xRangeInReformat[0], 0),
      },
      bottomRight: {
        x: Math.min(reformatFrameIndex + segmentOffset + 1, voxelRaster.width),
        y: Math.min(xRangeInReformat[1] + 1, voxelRaster.height),
      },
    }
  }

  if (plane === Plane.Y) {
    return {
      topLeft: {
        x: Math.max(xRangeInReformat[0], 0),
        y: Math.max(reformatFrameIndex - segmentOffset, 0),
      },
      bottomRight: {
        x: Math.min(xRangeInReformat[1] + 1, voxelRaster.width),
        y: Math.min(reformatFrameIndex + segmentOffset + 1, voxelRaster.height),
      },
    }
  }

  throw new Error('not a reformat.')
}
