import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { useBinarySearchArray } from '@/composables/useBinarySearchArray'
import type { AdvancedFilters } from '@/modules/AdvancedFilters/filters'
import { prepareSort } from '@/modules/AdvancedFilters/prepareSort'
import type { Sort } from '@/modules/AdvancedFilters/sort'
import type { PaginationParams, V2DatasetItemPayload } from '@/store/types'
import { loadV2DatasetItemIds } from '@/backend/darwin/loadV2DatasetItemIds'
import { loadV2DatasetItems } from '@/backend/darwin/loadV2DatasetItems'
import { loadItem } from '@/backend/darwin/loadItem'

export const useDatasetItemsStore = defineStore('datasetItems', () => {
  const { items, add, addBatch, remove, removeBatch, reset } =
    useBinarySearchArray<V2DatasetItemPayload>()
  const itemIds = ref<string[]>([])
  const itemsMap = computed(() => Object.fromEntries(items.value.map((i) => [i.id, i])))
  // There is a huge perfomance boost using a Set.has instead of and array.includes
  // most of all when it's only used to test the existend/non existence of an element
  // for large datasets
  // Even thought we have the same ids stored twice and we are using 2x memory storage
  // given the performance boost we get out of it it's worth it
  let idsSet: Set<string> = new Set()

  const loading = ref<boolean>(false)
  const idsLoaded = ref<boolean>(false)

  const loadItemIds = async (
    datasetId: number,
    teamSlug: string,
    filter: AdvancedFilters,
    page: PaginationParams | null,
    sort: Sort,
  ): Promise<void> => {
    idsLoaded.value = false

    const response = await loadV2DatasetItemIds({
      filter,
      dataset_ids: [datasetId],
      teamSlug: teamSlug,
      sort: prepareSort(sort),
      ...(page && { page }),
    })

    // we can dispatch a toast here if we wanted to
    if ('error' in response) {
      return
    }

    // if response is not a pagination response, throw an error
    if (!('item_ids' in response.data)) {
      throw new Error('[datasetItems.loadItemIds]: Expected items ids!')
    }

    itemIds.value = response.data.item_ids
    idsSet = new Set([...idsSet, ...response.data.item_ids])
    idsLoaded.value = true
  }

  const setItemIds = (ids: string[]): void => {
    itemIds.value = ids
    idsSet = new Set(ids)
    reset()
  }

  const currentItemId = ref<string | null>(null)
  const lastItemIndex = ref<number | null>(null)

  const setCurrentItemId = (id: string | null): void => {
    lastItemIndex.value = itemIds.value.findIndex((itemId) => itemId === id)
    currentItemId.value = id
  }

  const currentItem = computed(() => {
    if (!currentItemId.value) {
      return null
    }
    return itemsMap.value[currentItemId.value]
  })

  const loadItems = async (
    teamSlug: string,
    datasetId: number,
    filter: AdvancedFilters,
    {
      include_workflow_data,
      include_thumbnails,
      include_tags,
    }: {
      include_workflow_data: boolean
      include_thumbnails: boolean
      include_tags: boolean
    },
  ): Promise<void> => {
    loading.value = true

    const response = await loadV2DatasetItems({
      filter,
      dataset_ids: [datasetId],
      teamSlug,
      include_workflow_data,
      include_thumbnails,
      include_tags,
    })

    // if response errored, do nothing for now. later on, we can toast, or put error into state
    if ('error' in response) {
      return
    }

    // if response is not a pagination response, throw an error
    if (!('items' in response.data) || !('page' in response.data)) {
      throw new Error('[datasetItems.loadItems]: Expected pagination response')
    }

    addBatch(response.data.items, { triggerReactivity: true })

    loading.value = false
  }

  const getItemDetails = async (
    teamSlug: string,
    itemId: string,
  ): Promise<V2DatasetItemPayload | undefined> => {
    try {
      const response = await loadItem({
        teamSlug,
        itemId: itemId,
      })

      if ('error' in response) {
        return undefined
      }

      return response.data
    } catch {
      return undefined
    }
  }

  const rangeStart = ref(-1)
  const rangeEnd = ref(-1)

  const setItemRange = (start: number, end: number): void => {
    rangeStart.value = start
    rangeEnd.value = end
  }

  const addItem = (item: V2DatasetItemPayload): void => {
    if (!idsSet.has(item.id)) {
      itemIds.value.push(item.id)
      idsSet.add(item.id)
    }
    add(item, { triggerReactivity: true })
  }

  const removeItem = (itemId: string): void => {
    itemIds.value = itemIds.value.filter((id) => id !== itemId)
    idsSet.delete(itemId)
    remove(itemId, { triggerReactivity: true })
  }

  const removeItems = (removedItemIds: string[], triggerUpdates = true): void => {
    itemIds.value = itemIds.value.filter((id) => !removedItemIds.includes(id))
    removedItemIds.forEach((id) => idsSet.delete(id))
    removeBatch(removedItemIds, { triggerReactivity: triggerUpdates })
  }

  const updateItem = (item: V2DatasetItemPayload, triggerUpdates = true): void => {
    add(item, { overwrite: true, triggerReactivity: triggerUpdates })
  }

  const batchActions = (
    {
      updates,
      removals,
    }: {
      updates: V2DatasetItemPayload[]
      removals: V2DatasetItemPayload['id'][]
    },
    onlyUpdateItemsWithinCurrentIds = false,
  ): void => {
    const hasRemovals = !!removals.length
    const updatesLastIndex = updates.length - 1

    updates.forEach((item, index) => {
      if (onlyUpdateItemsWithinCurrentIds && !idsSet.has(item.id)) {
        return
      }

      // only trigger reactivity for last item if there are no removals
      const triggerReactivity = !hasRemovals && index === updatesLastIndex

      const isNew = add(item, { triggerReactivity, overwrite: true })
      if (!isNew) {
        return
      }
      if (!idsSet.has(item.id)) {
        itemIds.value.push(item.id)
        idsSet.add(item.id)
      }
    })

    if (hasRemovals) {
      removeItems(removals, true)
    }
  }

  const resetItems = (): void => {
    itemIds.value = []
    idsSet.clear()
    reset({ triggerReactivity: true })
    idsLoaded.value = false
    setItemRange(-1, -1)
  }

  return {
    // current item
    currentItem,
    currentItemId,
    lastItemIndex,
    setCurrentItemId,

    // listing
    itemIds,
    items,
    itemsMap,
    loading,
    idsLoaded,
    loadItems,
    getItemDetails,
    loadItemIds,
    setItemIds,

    // mutating
    addItem,
    removeItem,
    removeItems,
    updateItem,
    resetItems,
    batchActions,

    // loading by range
    rangeStart,
    rangeEnd,
    setItemRange,

    // consts
    LOAD_ITEMS_IN_RANGE_DELAY_MS: 300 as const,
  }
})
