import type { AnnotationData } from './AnnotationData'
import type {
  MeasureOverlayData,
  MeasureOverlayItem,
  MeasureRegionsPayload,
} from './MeasureOverlayData'
import { getEllipseBoundingBox } from './annotationBoundingBox'
import { calcCentroidPointFromPath } from './annotationCentroid'
import { isBoundingBox } from './annotationTypes/boundingBox'
import { isEllipse } from './annotationTypes/ellipse'
import { isPolygon } from './annotationTypes/polygon'
import { isPolyline } from './annotationTypes/polyline'
import type { Camera } from './camera'
import type { EditablePoint, IPoint } from './point'

export const DRAWING_ANNOTATION_ID = 'drawing-annotation'

export const calculateEllipseMeasures = (
  data: AnnotationData,
  camera: Camera,
  measureRegion: MeasureRegionsPayload | null,
): Pick<MeasureOverlayData, 'label' | 'measures'> => {
  const emptyMeasures = { label: '', measures: [] }

  if (!isEllipse(data)) {
    return emptyMeasures
  }

  if (!measureRegion) {
    return emptyMeasures
  }
  if (measureRegion.unit.x !== measureRegion.unit.y) {
    return emptyMeasures
  }

  const unit = measureRegion.unit.x
  const center: EditablePoint = data.center
  const right: EditablePoint = data.right
  const top: EditablePoint = data.top

  const { x, y, w, h } = getEllipseBoundingBox(center, top, right)
  const width = Math.abs(w * measureRegion.delta.x).toFixed(2)
  const height = Math.abs(h * measureRegion.delta.y).toFixed(2)
  const label = `H: ${height}${unit} W: ${width}${unit}`
  const measures: MeasureOverlayItem[] = [
    // Bottom Center
    {
      center: 'HOR',
      position: camera.imageViewToCanvasView({ x: center.x, y: y + h }),
      value: width,
      unit,
    },
    // Right Center
    {
      center: 'VER',
      position: camera.imageViewToCanvasView({ x: x + w, y: center.y }),
      value: height,
      unit,
    },
  ]

  return { label, measures }
}

export const calculatePolygonMeasures = (
  data: AnnotationData,
  camera: Camera,
  measureRegion: MeasureRegionsPayload | null,
): Pick<MeasureOverlayData, 'label' | 'measures'> => {
  const emptyMeasures = { label: '', measures: [] }

  if (!isPolygon(data)) {
    return emptyMeasures
  }

  if (!measureRegion) {
    return emptyMeasures
  }
  if (measureRegion.unit.x !== measureRegion.unit.y) {
    return emptyMeasures
  }

  const unit = measureRegion.unit.x

  const path: EditablePoint[] = data.path
  if (path.length === 0) {
    return emptyMeasures
  }

  const centroid = calcCentroidPointFromPath(path, 'polygon', camera.scale)
  if (!centroid) {
    return emptyMeasures
  }

  const canvasCentroid = camera.imageViewToCanvasView(centroid)

  // Add padding not to make it overlap with the annotation overlay
  canvasCentroid.y -= 30

  let maxX = path[0].x
  let minX = path[0].x
  let maxY = path[0].y
  let minY = path[0].y

  path.forEach((p) => {
    if (p.x > maxX) {
      maxX = p.x
    }
    if (p.x < minX) {
      minX = p.x
    }
    if (p.y > maxY) {
      maxY = p.y
    }
    if (p.y < minY) {
      minY = p.y
    }
  })

  const mw = ((maxX - minX) * measureRegion.delta.x).toFixed(2)
  const mh = ((maxY - minY) * measureRegion.delta.y).toFixed(2)
  const label = `H: ${mh}${unit} W: ${mw}${unit}`

  const measures: MeasureOverlayItem[] = [
    // Bottom Center
    {
      center: 'HOR',
      position: canvasCentroid,
      value: `(${mw} ${unit}, ${mh} ${unit})`,
      // Hacky solution not to add `unit` at the end of display string
      unit: '',
    },
  ]

  return { label, measures }
}

const calcValue = (path: IPoint[], delta: { x: number; y: number }): number =>
  path.reduce((value, point, index) => {
    if (index === 0) {
      return value
    }
    const { x: x1, y: y1 } = path[index]
    const { x: x2, y: y2 } = path[index - 1]
    const edgeLength = Math.sqrt(
      Math.pow(delta.x * (x2 - x1), 2) + Math.pow(delta.y * (y2 - y1), 2),
    )
    return value + edgeLength
  }, 0)

export const calculatePolylineMeasures = (
  data: AnnotationData,
  camera: Camera,
  measureRegion: MeasureRegionsPayload | null,
): Pick<MeasureOverlayData, 'label' | 'measures'> => {
  const emptyMeasures = { label: '', measures: [] }

  if (!isPolyline(data)) {
    return emptyMeasures
  }

  if (!measureRegion) {
    return emptyMeasures
  }
  if (measureRegion.unit.x !== measureRegion.unit.y) {
    return emptyMeasures
  }

  const unit = measureRegion.unit.x
  const path: EditablePoint[] = data.path
  if (path.length === 0) {
    return emptyMeasures
  }

  const centroid = calcCentroidPointFromPath(path, 'line', camera.scale)
  if (!centroid) {
    return emptyMeasures
  }

  const canvasCentroid = camera.imageViewToCanvasView(centroid)

  // Add padding not to make it overlap with the annotation overlay
  canvasCentroid.y -= 30

  const length = calcValue(path, measureRegion.delta).toFixed(2)
  const label = `Length: ${length}${unit}`
  const measures: MeasureOverlayItem[] = [
    // Bottom Center
    {
      center: 'HOR',
      position: canvasCentroid,
      value: length,
      unit,
    },
  ]

  return { label, measures }
}

export const calculateBoundingBoxMeasures = (
  data: AnnotationData,
  camera: Camera,
  measureRegion: MeasureRegionsPayload | null,
): Pick<MeasureOverlayData, 'label' | 'measures'> => {
  const emptyMeasures = { label: '', measures: [] }

  if (!isBoundingBox(data)) {
    return emptyMeasures
  }

  if (!measureRegion) {
    return emptyMeasures
  }
  if (measureRegion.unit.x !== measureRegion.unit.y) {
    return emptyMeasures
  }

  const unit = measureRegion.unit.x

  const bottomRight: EditablePoint = data.bottomRight
  const topLeft: EditablePoint = data.topLeft

  const width = Math.abs((bottomRight.x - topLeft.x) * measureRegion.delta.x).toFixed(2)
  const height = Math.abs((bottomRight.y - topLeft.y) * measureRegion.delta.y).toFixed(2)
  const label = `H: ${height}${unit} W: ${width}${unit}`
  const measures: MeasureOverlayItem[] = [
    // Bottom Center
    {
      center: 'HOR',
      position: camera.imageViewToCanvasView({
        x: (topLeft.x + bottomRight.x) / 2,
        y: bottomRight.y,
      }),
      value: width,
      unit,
    },
    // Right Center
    {
      center: 'VER',
      position: camera.imageViewToCanvasView({
        x: bottomRight.x,
        y: (bottomRight.y + topLeft.y) / 2,
      }),
      value: height,
      unit,
    },
  ]

  return { label, measures }
}

export const toFullOverlayData = (
  data: Pick<MeasureOverlayData, 'label' | 'measures'>,
  color: string,
  id = DRAWING_ANNOTATION_ID,
): MeasureOverlayData | null => (data.measures.length === 0 ? null : { ...data, id, color })
