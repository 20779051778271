import type { AnnotationType } from '@/core/annotationTypes'
import type { ModelType } from '@/core/annotations'
import type { FrameData } from '@/core/annotations'

import type { InferenceResult } from '@/modules/Editor/backend'

import type { ModelVariant } from '@/modules/Models/useExternalModelSettingsStore'

export type RunningSessionAccessLevel = 'private' | 'protected' | 'public'

export type TrainingClass = {
  darwin_id?: number
  display_name?: string
  id: string
  name: string
  type: AnnotationType
  subs: AnnotationType[]
}

export type Visible = { model_page: boolean; dropdown: boolean; stage: boolean }

export const ModelDevice = {
  CPU: 'cpu',
  GPU: 'gpu',
} as const

export type ModelDevice = (typeof ModelDevice)[keyof typeof ModelDevice]

export type RunningSessionPayload = {
  access_level: RunningSessionAccessLevel
  auto_start: boolean
  auto_stop: boolean
  device: ModelDevice
  id: string
  inserted_at: string
  max: number
  meta: {
    classes: TrainingClass[]
    num_instances_available: number
    num_instances_starting: number
  }
  min: number
  name: string
  public: boolean
  team_id: number
  trained_model_id: string
  updated_at: string
  visible: Visible
}

export type RunningSessionExpand =
  | 'meta.classes'
  | 'meta.num_instances_available'
  | 'meta.num_instances_starting'

/**
 * When creating new models, there are lots of components that work with
 * 'every model but external models'. This type is used to represent that.
 */
export type InternalModelType = Exclude<ModelType, ModelVariant>

export type ModelTemplatePayload = {
  devices: ModelDevice[]
  docker_image: string
  id: string
  infer_params: object
  inserted_at: string
  load_params: object
  name: string
  train_params: object
  type: ModelType
  updated_at: string
}

export type GustStatus =
  | 'available'
  | 'initiating'
  | 'loading'
  | 'scheduled'
  | 'starting'
  | 'stopped'
  | 'stopping'

export type RunningSessionInstanceCountPayload = {
  [k in GustStatus]: number
} & {
  reported_for: string
  running_session_id: RunningSessionPayload['id']
}

export type Gust = {
  current_running_session_id: null
  id: string
  mode: 'train'
  status: GustStatus
}

export type TrainingSessionPayload = {
  dataset_id: number
  dataset_identifier: string
  dataset_version: number
  device: ModelDevice
  gust: Gust
  id: string
  model_template: ModelTemplatePayload
  name: string
  inserted_at: string
  status: string
  team_id: number
  trained_model_id: string | null
  training_stats: TrainingStatsPayload | null
  training_time_secs: number
}

type TrainingStatsPayload = {
  train: TrainingSplitStatsPayload
  val: TrainingSplitStatsPayload
  test: TrainingSplitStatsPayload
  max_density: number
  split_seed: number
}

type TrainingSplitStatsPayload = {
  class_distribution: { [class_name: string]: number }
  frame_count: number
  instance_distribution: { [class_name: string]: number }
  item_count: number
  split_percentage: number
}

export type TrainedModelPayload = {
  classes: TrainingClass[]
  id: string
  inserted_at: string
  model_template: ModelTemplatePayload
  name: string
  team_id: number | null
  training_result: Partial<{
    // this is only a partial definition. it used to be "any", but with the ban of any
    // we couldn't change it to "unknown" because it would break too much, so instead
    // we opt to gradually add to the definition as we find more information about it
    segm: Partial<{ AP: number; AP50: number; AP75: number }>
    classes: string[]
    test_metrics: Partial<{ norm: number[][]; accuracy: number }>
    'test/accuracy': number
    'test/confusion_matrix_norm': number[][]
    'test/confusion_matrix': number[][]
    'test/f1': number
    'test/map_50': number[]
    'test/map_75': number[]
    'test/map_large': number[]
    'test/map_medium': number[]
    'test/map_per_class'?: number[]
    'test/map_small': number[]
    'test/map': number[]
    'test/mar_1': number[]
    'test/mar_10': number[]
    'test/mar_100_per_class': number[]
    'test/mar_100': number[]
    'test/mar_large': number[]
    'test/mar_medium': number[]
    'test/mar_small': number[]
    'test/micro-accuracy': number
    'test/micro-f1': number
    'test/micro-recall': number
    'test/recall': number
  }> | null
  weights_key: string
  visible: Visible
}

export type ModelPayload = TrainedModelPayload | TrainingSessionPayload | RunningSessionPayload

export type RunInferenceResponse<T = InferenceResult> = { result: T }

export type InferenceRequestCountPayload = {
  request_count: number
  success_count: number
  failure_count: number
  date: string
  running_session_id: string
}

type MetricData = { x: number; y: number }[]

export type MetricPayload = {
  data: MetricData
  name: string
  training_session_id: string
}

export type MetricsPayload = MetricPayload[]

export type ExternalModelClass = Pick<TrainingClass, 'id' | 'name' | 'type'>

export type ExternalModelRequestPayload = {
  id?: string
  team_id: number
  url: string
  name: string
  auth: {
    auth_secret_header: string | null
    basic_auth_username: string | null
    method: 'none' | 'basic_auth' | 'secret_header'
    auth_secret?: string | null | boolean
    basic_auth_password?: string | null | boolean
  }
  transform: 'none' | 'huggingface'
  type: 'generic'
  classes: ExternalModelClass[]
}

export type ExternalModelResponsePayload = ExternalModelRequestPayload & {
  id: string
  inserted_at: string
  updated_at: string
  visible: Visible
}

export type EmbeddingImageReturnType = {
  result: {
    dtype: 'float32'
    embedding: string
    embedding_shape: number[]
    // Used by Custom SAM models
    interm_embedding?: string
    interm_embedding_shape?: number[]
  }
}

export type SamOnnxType = {
  url: string
}

export type ModelInferenceResult = {
  inference: {
    confidence: number
    model: NonNullable<FrameData['inference']>['model']
  }
  label: string
  name: string
  polygon?: { path: Array<{ x: number; y: number }> }
  bounding_box?: { h: number; w: number; x: number; y: number }
  // Tag is always the empty object or undefined. Its presence as a property
  // is used to signal that the inference is for a tag that applies to the entire image.
  tag?: Record<string, never>
  keypoint?: { x: number; y: number }
  line?: { path: Array<{ x: number; y: number }> }
  skeleton?: {
    nodes: Array<{
      name: string
      occluded: boolean
      x: number
      y: number
    }>
  }
  ellipse?: {
    center: { x: number; y: number }
    angle: number
    radius: { x: number; y: number }
  }
}
