import type { Camera } from '@/modules/Editor/camera'
import { DEFAULT_LINE_WIDTH } from '@/modules/Editor/config'
import type { IPoint } from '@/modules/Editor/point'
import { fillStyle } from '@/modules/Editor/graphics/fillStyle'
import { lineWidth } from '@/modules/Editor/graphics/lineWidth'
import { strokeStyle } from '@/modules/Editor/graphics/strokeStyle'
import type { ReferenceLinesLayer } from '@/modules/Editor/models/layers/referenceLinesLayer'
import type { DicomView } from '@/modules/Editor/views/dicomView'

import { referenceLinesColor } from './consts'
import { getImageCenter } from './helpers/getImageCenter'
import { getImageReferenceLines } from './helpers/getImageReferenceLines'
import { getViewInfoArray } from './helpers/getViewInfoArray'

/**
 * Clears the canvas so that we can draw the updated labelmap.
 * @param canvas The canvas to clear.
 * @param ctx The 2d canvas context.
 */
const clearCanvas = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void => {
  ctx.save()
  // Set transform to the identiy matrix as:
  // [a c e]
  // [b d f]
  // [0 0 1]
  // Where arguments are, in order, `a, b, c, d, e, f`
  ctx.setTransform(1, 0, 0, 1, 0, 0)
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  ctx.restore()
}

/**
 * Renders the lines to the given reference lines layer.
 *
 * @param layer The reference lines layer they will be rendered on.
 * @param camera The camera of the view.
 * @param lines An array of lines each defined by two image points.
 */
const renderToCanvas = (layer: ReferenceLinesLayer, camera: Camera, lines: IPoint[][]): void => {
  const { canvas, context: ctx } = layer

  if (!canvas) {
    return
  }
  if (!ctx) {
    return
  }

  clearCanvas(canvas, ctx)

  ctx.save()

  ctx.strokeStyle = strokeStyle(referenceLinesColor, null, false, false)
  ctx.fillStyle = fillStyle(referenceLinesColor, null, false, false, false)
  ctx.lineWidth = (lineWidth(DEFAULT_LINE_WIDTH, false) * 1.0) / camera.scale

  camera.imageViewCtxToCanvasViewCtx(ctx)

  ctx.beginPath()

  lines.forEach((line) => {
    ctx.moveTo(line[0].x, line[0].y)
    ctx.lineTo(line[1].x, line[1].y)
  })

  ctx.stroke()
  ctx.restore()
}

/**
 * Renders references lines on the reference lines layer of the given DicomView.
 * Currently only works for the volume view layout, but could be generalised
 * later for more complicated layouts.
 *
 * @param view The DicomView to render the reference lines on.
 * @param layer The ReferenceLinesLayer on the view to render the volume on.
 */
export const renderReferenceLines = (view: DicomView, layer: ReferenceLinesLayer): void => {
  const viewInfoArray = getViewInfoArray(view)
  const centerImage = getImageCenter(view, viewInfoArray)

  if (!centerImage) {
    return
  }

  const { camera } = view
  const lines = getImageReferenceLines(centerImage, camera, layer)

  renderToCanvas(layer, camera, lines)
}

/**
 * Clears the reference lines on the given layer.
 *
 * @param layer The ReferenceLinesLayer on the view to clear.
 */
export const clearReferenceLines = (layer: ReferenceLinesLayer): void => {
  const { canvas, context: ctx } = layer

  if (!canvas) {
    return
  }
  if (!ctx) {
    return
  }

  clearCanvas(canvas, ctx)
}
