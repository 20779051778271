/**
 * Generates a promise, and returns a reference to it, as well as its resolve/reject callbacks.
 *
 * This allows the caller to store the promise for later awaiting, as well as pass the callbacks
 * into the code that actually needs to execute asynchronously.
 *
 * That way, it's possible to reuse the same promise for async requests that merge into each other,
 * for example, so that all callers of all merged requests can await the same promise.
 */
export const createDeferredPromise = <R, E = unknown>(): {
  promise: Promise<R>
  resolve: (r: R) => void
  reject: (e?: E) => void
} => {
  // the callback passed into a promise constructor always executes in such a
  // way that we are guaranteed resolver and rejecter will be set when returning
  // from this function
  let resolver!: (r: R) => void
  let rejecter!: (e?: E) => void

  const promise = new Promise<R>((resolve, reject) => {
    resolver = resolve
    rejecter = reject
  })

  return { promise, resolve: resolver, reject: rejecter }
}
