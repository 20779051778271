import type { VoxelRaster } from 'src/modules/Editor/models/raster/VoxelRaster'

import type { RasterBufferAccessor } from '@/modules/Editor/models/raster/RasterBufferAccessor'

import { Plane } from './Plane'

const outOfBoundsErrorMessage = 'Requesting plane outside of volume range'

/**
 * Returns a RasterBufferAccessor for a given frame of a VoxelRaster,
 * allowing that frame to be renderered/edited.
 */
export const getRasterBufferAccessorForPlane = (
  raster: VoxelRaster,
  plane: Plane,
  frameIndex: number,
): RasterBufferAccessor => {
  const { voxelRasterBuffer, width, height, depth, size } = raster

  if (plane === Plane.Y) {
    // Fixed Y - XZ plane
    if (frameIndex < 0 || frameIndex >= height) {
      throw new Error(outOfBoundsErrorMessage)
    }

    const y = frameIndex

    return {
      get(x: number, z: number): number {
        return voxelRasterBuffer[z * size + y * width + x]
      },
      set(x: number, z: number, val: number): void {
        voxelRasterBuffer[z * size + y * width + x] = val
      },
      width, // X
      height: depth, // Z
      plane,
    }
  }

  if (plane === Plane.X) {
    // Fixed X - YZ plane
    if (frameIndex < 0 || frameIndex >= width) {
      throw new Error(outOfBoundsErrorMessage)
    }

    const x = frameIndex

    return {
      get(y: number, z: number): number {
        return voxelRasterBuffer[z * size + y * width + x]
      },
      set(y: number, z: number, val: number): void {
        voxelRasterBuffer[z * size + y * width + x] = val
      },
      width: height, // Y
      height: depth, // Z
      plane,
    }
  }

  if (plane === Plane.Z) {
    // Fixed Z - XY plane
    if (frameIndex < 0 || frameIndex >= depth) {
      throw new Error(outOfBoundsErrorMessage)
    }

    const z = frameIndex

    return {
      get(x: number, y: number): number {
        return voxelRasterBuffer[z * size + y * width + x]
      },
      set(x: number, y: number, val: number): void {
        voxelRasterBuffer[z * size + y * width + x] = val
      },
      width, // X
      height, // Y
      plane,
    }
  }

  throw new Error(`Unknown plane should not be hit in getRasterBufferAccessorForPlane: ${plane}`)
}
