import { euclideanDistance } from '@/modules/Editor/algebra'
import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import { isLeftMouseButton } from '@/modules/Editor/mouse'
import type { IPoint } from '@/modules/Editor/point'
import { addPoints, divScalar } from '@/modules/Editor/point'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import type { View } from '@/modules/Editor/views/view'

interface ZoomTool extends Tool {
  initialPoint?: IPoint
  cursorPoint?: IPoint
  zoomOut: boolean

  onStart(context: ToolContext, event: PointerEvent): void
  onMove(context: ToolContext, event: PointerEvent): void
  onEnd(context: ToolContext, event: PointerEvent): void
  draw(view: View): void
}

export const zoomTool: ZoomTool = {
  initialPoint: undefined,
  cursorPoint: undefined,
  zoomOut: false,

  onStart(context: ToolContext, event: PointerEvent) {
    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    this.initialPoint = point
    this.draw(context.editor.activeView)
  },

  onMove(context: ToolContext, event: PointerEvent) {
    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    if (this.initialPoint) {
      this.cursorPoint = point
      this.draw(context.editor.activeView)
      return CallbackStatus.Stop
    }
  },

  onEnd(context: ToolContext, event: PointerEvent) {
    const point = resolveEventPoint(event, true)

    if (point) {
      this.cursorPoint = point
    }

    if (!this.cursorPoint) {
      return
    }

    if (this.initialPoint && euclideanDistance(this.initialPoint, this.cursorPoint) > 0) {
      if (this.zoomOut) {
        const center = divScalar(addPoints(this.initialPoint, this.cursorPoint), 2)
        context.editor.activeView.camera.zoomOut(center)
      } else {
        context.editor.activeView.camera.zoomToBox(this.initialPoint, this.cursorPoint)
      }
    } else {
      if (this.zoomOut) {
        context.editor.activeView.camera.zoomOut(this.cursorPoint)
      } else {
        context.editor.activeView.camera.zoomIn(this.cursorPoint)
      }
    }
    this.reset(context)

    context.editor.activeView.annotationsLayer.draw()
    context.editor.activeView.allLayersChanged()
  },

  activate(context: ToolContext) {
    setupMouseButtonLoadout(context, { middle: true })

    selectCursor(this.zoomOut ? EditorCursor.ZoomOut : EditorCursor.ZoomIn)

    context.editor.registerCommand('zoom_tool.cancel', () => {
      this.reset(context)
      this.draw(context.editor.activeView)
    })

    context.handles.push(
      ...context.editor.onKeyDown((event) => {
        this.zoomOut = event.shiftKey || event.altKey
        selectCursor(this.zoomOut ? EditorCursor.ZoomOut : EditorCursor.ZoomIn)
      }),
    )

    context.handles.push(
      ...context.editor.onKeyUp((event) => {
        this.zoomOut = event.shiftKey || event.altKey
        selectCursor(this.zoomOut ? EditorCursor.ZoomOut : EditorCursor.ZoomIn)
        context.editor.activeView.allLayersChanged()
      }),
    )

    context.handles.push(
      ...context.editor.onMouseDown((e) => {
        if (!isLeftMouseButton(e)) {
          return CallbackStatus.Continue
        }
        return this.onStart(context, e)
      }),
    )

    context.handles.push(...context.editor.onMouseMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onMouseUp((event) => this.onEnd(context, event)))

    context.handles.push(...context.editor.onTouchStart((event) => this.onStart(context, event)))
    context.handles.push(...context.editor.onTouchMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onTouchEnd((event) => this.onEnd(context, event)))
  },

  draw(view: View): void {
    view.annotationsLayer.draw((ctx) => {
      if (!this.cursorPoint || !this.initialPoint) {
        return
      }
      if (!ctx) {
        return
      }
      ctx.strokeStyle = '#fff'
      ctx.lineWidth = 2
      ctx.strokeRect(
        this.initialPoint.x,
        this.initialPoint.y,
        this.cursorPoint.x - this.initialPoint.x,
        this.cursorPoint.y - this.initialPoint.y,
      )
    })
  },

  deactivate(context: ToolContext): void {
    context.editor.activeView.annotationsLayer.draw()
  },

  reset(): void {
    this.initialPoint = undefined
    this.cursorPoint = undefined
  },
}
