import type { Eye } from '@/modules/Editor/AnnotationData'
import calcEyeLowerVertex from '@/modules/Editor/eye/calcEyeLowerVertex'
import { createEditablePoint, addPoints, mulScalar, subPoints } from '@/modules/Editor/point'

import type { LinearInterpolationParams } from './types'

const eyeInterpolation = (params: LinearInterpolationParams, prevData: Eye, nextData: Eye): Eye => {
  const { algorithm, interpolationFactor } = params

  if (algorithm && !algorithm.startsWith('linear')) {
    throw new Error(
      `Interpolate: Eye annotations don't support '${algorithm}' interpolation algorithm`,
    )
  }
  const newNodes = prevData.nodes.map((prevNode) => {
    const nextNode = nextData.nodes.find((node) => node.name === prevNode.name)
    if (!nextNode) {
      throw new Error("eyeInterpolation was given invalid eye. Couldn't find next node")
    }
    return {
      name: prevNode.name,
      occluded: prevNode.occluded,
      point: createEditablePoint(
        addPoints(
          prevNode.point,
          mulScalar(subPoints(nextNode.point, prevNode.point), interpolationFactor),
        ),
      ),
    }
  })

  // adjust the lower vertex position to follow perpendicularity rules
  const lowerVertex = newNodes.find((node) => node.name === 'lower')
  if (!lowerVertex) {
    throw new Error('Trying to interpolate Eye without "lower" node')
  }
  const newLowerVertexPos = calcEyeLowerVertex(newNodes)
  lowerVertex.point.x = newLowerVertexPos.x
  lowerVertex.point.y = newLowerVertexPos.y

  return {
    nodes: newNodes,
  }
}

export default eyeInterpolation
