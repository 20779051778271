import EventEmitter from 'events'

import type { MedicalVolumePlane } from '@/modules/Editor/MedicalMetadata'
import type { CallbackHandle } from '@/modules/Editor/callbackHandler'
import type { CameraEvent } from '@/modules/Editor/camera'
import type { ViewEvent } from '@/modules/Editor/eventBus'
import { CameraEvents, ViewEvents } from '@/modules/Editor/eventBus'
import { Events } from '@/modules/Editor/models/layers/layer'
import type Vtk2DContext from '@/modules/Editor/models/layers/vtk2DContext'
import type { DicomView } from '@/modules/Editor/views/dicomView'

import type { ILayer } from './types'

/**
 * Accumulates items for render.
 *
 * Manages rendering process of each item.
 */
export class VtkLayer
  extends EventEmitter
  implements ILayer<CanvasRenderingContext2D, HTMLCanvasElement>
{
  private readonly _view: DicomView
  private _destroyed: boolean = false
  private _canvas: HTMLCanvasElement | null
  protected _hasChanges: boolean = false

  protected _vtk2DContext: Vtk2DContext | null

  private cameraHandler = (cameraEvent: CameraEvent): void => {
    if (cameraEvent.viewId === this._view.id) {
      this.updateCamera()
    }
  }

  private filterHandler = (viewEvent: ViewEvent): void => {
    if (viewEvent.viewId === this._view.id) {
      this.updateFilter()
    }
  }

  private frameIndexHandler = (viewEvent: ViewEvent): void => {
    if (viewEvent.viewId === this._view.id) {
      this.changed()
      this.render()
    }
  }

  constructor(view: DicomView, medicalVolumePlane: MedicalVolumePlane) {
    super()
    this._view = view

    // Dynamic loading of modules using vtk.js
    // The canvas need to be non-null after constructor, to be able to initialize the views
    this._vtk2DContext = null
    this._canvas = document.createElement('canvas')
    // This is the main layer, where we render the file
    this._canvas.setAttribute('data-test', 'main-layer')
    // adding vtk-layer class to differentiate from the default main layer
    this._canvas.classList.add('vtk-layer', 'main-layer')
    import('@/modules/Editor/models/layers/vtk2DContext').then(
      async ({ default: Vtk2DContext }) => {
        if (this._canvas) {
          const contextManager = await this._view.editor.vtkContextManager
          this._vtk2DContext = new Vtk2DContext(medicalVolumePlane, this._canvas, contextManager)
          this.render()
        }
      },
    )

    // Layer itself defines when to re-render for panning or scaling
    CameraEvents.scaleChanged.on(this.cameraHandler)
    CameraEvents.offsetChanged.on(this.cameraHandler)
    CameraEvents.setDimensions.on(this.cameraHandler)
    CameraEvents.setWidth.on(this.cameraHandler)
    CameraEvents.setHeight.on(this.cameraHandler)

    // Listen to filter changes
    ViewEvents.imageFilterChanged.on(this.filterHandler)
    ViewEvents.currentFrameIndexChanged.on(this.frameIndexHandler)
  }

  get vtk2DContext(): Vtk2DContext | null {
    return this._vtk2DContext
  }

  private updateCamera(): void {
    if (!this._vtk2DContext) {
      return
    }

    const camera = this._view.camera

    // Update camera "zoom" using parallelScale
    // v7 camera scale is the ratio cameraViewport / imageDimension
    // vtk.js parallel scale, in parallel projection mode, is half the height of the camera
    const cameraScale = (0.5 * camera.height) / camera.scale
    this._vtk2DContext.setCameraParallelScale(cameraScale)

    // Update camera position
    // Offset is the position of the origin of the camera relative to the origin of the image
    const cameraPosition: [number, number] = [
      (0.5 * camera.width + camera.offset.x) / camera.scale,
      (0.5 * camera.height + camera.offset.y) / camera.scale,
    ]
    this._vtk2DContext.setCameraPosition(cameraPosition)

    // Update canvas size
    const width = camera.width
    const height = camera.height
    this._vtk2DContext.resizeCanvas(width, height)

    this.changed()
  }

  private updateFilter(): void {
    this._vtk2DContext?.updateFilter(this._view.imageFilter, this._view.fileManager.file.metadata)
  }

  public enableThresholdBrushPreview(min: number, max: number): void {
    const annotationClass = this._view.annotationManager.preselectedAnnotationClass
    if (!annotationClass) {
      return
    }

    const color = annotationClass.color
    this.vtk2DContext?.setRgbPointForRange([color.r, color.g, color.b], min, max)
    this.changed()
  }

  public disableThresholdBrushPreview(): void {
    this.updateFilter()
    this.changed()
  }

  public changedDebounce(): void {}
  public activate(): void {}
  public deactivate(): void {}
  public reorderAnnotation(): void {}
  public hitItemRegion(): Promise<undefined> {
    return Promise.resolve(undefined)
  }
  public hitVertexRegion(): number | undefined {
    return
  }
  public hitItemStroke(): Promise<string | undefined> {
    return Promise.resolve(undefined)
  }
  public setFilters(): void {}
  public updateItemState(): void {}
  public activateVertexWithState(): void {}
  public unhighlightAllVertices(): void {}
  public deactivateVertex(): void {}
  public clearDrawingCanvas(): void {}
  public isHidden(): boolean {
    return false
  }
  public show(): void {}
  public showAll(): void {}
  public hide(): void {}
  public hideAll(): void {}
  public hideLayer(): void {}
  public showLayer(): void {}

  /**
   * Can't draw using a DrawFn
   */
  public draw(): void {}

  public get element(): DocumentFragment {
    const frag = document.createDocumentFragment()
    this.canvas && frag.appendChild(this.canvas)
    return frag
  }

  /**
   * Can't return a CanvasRenderingContext2D as the canvas already gave WebGL2RenderingContext
   */
  public get context(): null {
    return null
  }

  public get canvas(): HTMLCanvasElement | null {
    return this._canvas
  }

  /**
   * Add new object to the render pool.
   */
  add(): void {}

  replaceAll(): void {
    throw new Error('Method not implemented.')
  }
  replaceAllWithParsedData(): void {}

  update(): void {}

  /**
   * Remove object from the render pool by its id.
   */
  delete(): void {}

  /**
   * Returns object by its id.
   */
  getItem(): undefined {
    return undefined
  }

  /**
   * Checks if the object with id exists on the layer.
   */
  has(): boolean {
    return false
  }

  /**
   * Renders each item in the pool.
   *
   * If the layer has no changes it will skip re-render iteration.
   */
  render(): void {
    if (this._destroyed || !this._vtk2DContext) {
      return
    }

    const dataManager = this._view.vtkDataManager
    if (
      dataManager &&
      this._vtk2DContext.setVtkDataManager(dataManager, this._view.editor.vtkLayers)
    ) {
      this.updateCamera()
      this.updateFilter()
    }

    if (!this._hasChanges) {
      return
    }

    this._hasChanges = false

    this.emit(Events.BEFORE_RENDER, this.context, this.canvas)
    this._vtk2DContext.render(this._view.currentFrameIndex)
    this.emit(Events.RENDER, this.context, this.canvas)
  }

  /**
   * Marks layer as changed.
   *
   * So it will be redrawn during the next render iteration.
   */
  changed(): void {
    this._hasChanges = true
  }

  clear(): void {}

  destroy(): void {
    this._vtk2DContext?.delete()
    this._vtk2DContext = null
    CameraEvents.scaleChanged.off(this.cameraHandler)
    CameraEvents.offsetChanged.off(this.cameraHandler)
    CameraEvents.setDimensions.off(this.cameraHandler)
    CameraEvents.setWidth.off(this.cameraHandler)
    CameraEvents.setHeight.off(this.cameraHandler)
    ViewEvents.imageFilterChanged.off(this.filterHandler)
    ViewEvents.currentFrameIndexChanged.off(this.frameIndexHandler)
    this._destroyed = true
    this.clear()
    this._canvas?.remove()
    this._canvas = null
    this.removeAllListeners(Events.RENDER)
    this.removeAllListeners(Events.BEFORE_RENDER)
  }

  onRender(cb: (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => void): CallbackHandle {
    this.on(Events.RENDER, cb)

    return {
      id: this.listenerCount(Events.RENDER),
      release: this.off.bind(this, Events.RENDER, cb),
    }
  }

  onBeforeRender(
    cb: (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => void,
  ): CallbackHandle {
    this.on(Events.BEFORE_RENDER, cb)

    return {
      id: this.listenerCount(Events.BEFORE_RENDER),
      release: this.off.bind(this, Events.BEFORE_RENDER, cb),
    }
  }

  onRendered(cb: (renderKey: string | null) => void): CallbackHandle {
    this.on(Events.RENDERED, cb)

    return {
      id: this.listenerCount(Events.RENDERED),
      release: this.off.bind(this, Events.RENDERED, cb),
    }
  }

  setKeyForNextRender(): void {
    throw new Error('Method not implemented.')
  }
}
