import now from 'lodash/now'

import { DEFAULT_VERTEX_ACTIVE_SCALE } from '@/modules/Editor/config'
import type { IPoint } from '@/modules/Editor/point'
import type { RenderingContext2D } from '@/modules/Editor/types'

import { changeRGBAAlpha } from './helpers'

type DrawOptions = {
  pointSize?: number
  lineWidth?: number
  fillColor?: string
  strokeColor?: string
  isHighlighted?: boolean
  isSelected?: boolean
}

/**
 * Annotation vertex drawing function.
 * As a result of the execution, you will get Path2D instance
 * that will be used to detect the vertex using the cursor.
 * @param ctx
 * @param point
 * @param drawOptions
 * @returns {Path2D}
 */
export const drawVertex = (
  ctx: RenderingContext2D,
  point: IPoint,
  {
    pointSize = 5.5,
    lineWidth = 1,
    fillColor = 'rgb(255, 255, 255)',
    strokeColor = 'rgb(255, 255, 255)',
    isHighlighted = false,
    isSelected = false,
  }: DrawOptions,
): Path2D => {
  const path2D = new Path2D()
  const { x, y } = point

  ctx.lineWidth = lineWidth
  ctx.fillStyle = fillColor
  ctx.strokeStyle = strokeColor
  ctx.beginPath()
  const size = pointSize * (isSelected || isHighlighted ? DEFAULT_VERTEX_ACTIVE_SCALE : 1)
  path2D.arc(x, y, size, 0, 2 * Math.PI)
  ctx.fill(path2D)
  ctx.stroke(path2D)

  return path2D
}

/**
 * Add to the canvas an animated pulsating point
 * @param ctx
 * @param point
 * @param param2
 * @returns
 */
export const drawPulsatingVertex = (
  ctx: RenderingContext2D,
  point: IPoint,
  {
    pointSize = 5.5,
    lineWidth = 1,
    fillColor = 'rgb(255, 255, 255)',
    strokeColor = 'rgb(255, 255, 255)',
    isHighlighted = false,
    isSelected = false,
  }: DrawOptions,
): Path2D => {
  const path2D = new Path2D()
  const { x, y } = point

  ctx.lineWidth = lineWidth

  const unixTime = now()

  // Make the scale oscillate between 1 and 4
  const scale = 1 + 3 * Math.abs(Math.cos(unixTime / 1000))
  const scaledX = x - x * scale
  const scaledY = y - y * scale

  // With the same approach, make opacity oscillate between 100% and 33%
  const opacity = Math.min(1, 0.33 + Math.abs(Math.cos(unixTime / 1000)))
  ctx.fillStyle = changeRGBAAlpha(fillColor, opacity)
  ctx.strokeStyle = strokeColor

  ctx.beginPath()
  const size = pointSize * (isSelected || isHighlighted ? DEFAULT_VERTEX_ACTIVE_SCALE : 1)
  path2D.arc(x, y, size, 0, 2 * Math.PI)

  ctx.setTransform(scale, 0, 0, scale, scaledX, scaledY)
  ctx.fill(path2D)

  return path2D
}
