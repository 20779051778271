import type { SpecialKey } from '@/modules/Editor/annotationTypes/ellipse'

export const resolveModifierByPriority = (
  event: KeyboardEvent | MouseEvent | TouchEvent,
): SpecialKey | undefined => {
  if (event.shiftKey) {
    return 'shift'
  }
  if (event.altKey) {
    return 'alt'
  }
  if (event.ctrlKey) {
    return 'ctrl'
  }
}
