import { normalizeFilter } from '@/modules/AdvancedFilters/normalizeFilter'
import type { V2DatasetGeneralCountsPayload } from '@/store/types'
import { post } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

import type { LoadV2DatasetItemsParams } from './loadV2DatasetItems'

type Params = Omit<LoadV2DatasetItemsParams, 'page' | 'sort'>

export const loadV2DatasetGeneralCounts = async ({
  teamSlug,
  ...params
}: Params): Promise<{ data: V2DatasetGeneralCountsPayload } | ParsedError> => {
  try {
    const { data } = await post<V2DatasetGeneralCountsPayload>(
      `unstable/teams/${teamSlug}/items/general_counts`,
      {
        ...params,
        filter: normalizeFilter(params.filter),
      },
    )
    return { data }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.WORKVIEW_IMAGES_LOAD)
  }
}
