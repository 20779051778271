import type { Action } from '@/modules/Editor/managers/actionManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'
import type { DuplicateMeta } from '@/modules/Editor/serialization/types'

import { ANNOTATION_ACTION } from './actionIds'

export const duplicateAnnotationAction = (
  view: View,
  {
    sourceAnnotationId,
    newAnnotation,
  }: {
    sourceAnnotationId: string
    newAnnotation: Annotation
  },
  duplicateMeta?: DuplicateMeta,
): Action => ({
  id: ANNOTATION_ACTION,
  do(): boolean {
    try {
      view.annotationManager.duplicateAnnotation(
        { newAnnotation, sourceAnnotationId },
        duplicateMeta,
      )
      view.annotationManager.selectAnnotation(newAnnotation.id)
    } catch (e) {
      setContext('error', { error: e })
      console.error('In v2 duplicateAnnotationAction, annotationManager failed to duplicate')
      return false
    }
    return true
  },

  undo(): boolean {
    const deletedAnn = view.annotationManager.deleteAnnotation(newAnnotation.id)

    if (!deletedAnn) {
      return false
    }

    view.annotationsLayer.changedDebounce()
    return true
  },
})
