import { LoadingStatus } from '@/store/types'
import { loadAnnotationTypes } from '@/backend/darwin/loadAnnotationTypes'
import { useAnnotationTypesStore } from './useAnnotationTypesStore'

export const useAnnotationTypeLoader = (): { load: () => Promise<void> } => {
  const typesStore = useAnnotationTypesStore()

  const load = async (): Promise<void> => {
    if (typesStore.typesLoadingStatus !== LoadingStatus.Unloaded) {
      return
    }

    typesStore.typesLoadingStatus = LoadingStatus.Loading

    const response = await loadAnnotationTypes()

    if (response.ok) {
      typesStore.types = response.data
      typesStore.typesLoadingStatus = LoadingStatus.Loaded
    }
  }

  return { load }
}
