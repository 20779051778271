import type { AnnotationType } from '@/core/annotationTypes'
import type { IPoint, EditablePoint } from '@/modules/Editor/point'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'

export const POLYLINE_ANNOTATION_TYPE: AnnotationType = 'line'

export type AddingPointContext = {
  pointOnLine: IPoint
  pointOnLineAnnotation: Annotation
  pointOnLinePosition: number
  pointOnLinePath: EditablePoint[]
}
