import type { ViewInfo } from '@/modules/Editor/utils/referenceLines/types'
import type { DicomView } from '@/modules/Editor/views/dicomView'

export const getViewInfoArray = (view: DicomView): ViewInfo[] => {
  const viewsList = view.editor.viewsList

  const viewInfoEntries: ViewInfo[] = []

  viewsList.forEach((view) => {
    const { currentFrameIndex, totalFrames } = view

    const { slot_name } = view.fileManager.file

    const info = {
      currentFrameIndex,
      slotName: slot_name,
      totalFrames,
    }
    viewInfoEntries.push(info)
  })

  return viewInfoEntries
}
