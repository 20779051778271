import { WindAuthAction } from '@/backend/darwin/WindAuthAction'
import type { ApiResult } from '@/backend/darwin/types'
import { errorMessages } from '@/backend/error'

import { withAuth, withoutAuth } from './api'
import type { TrainingSessionPayload } from './types'

type Params = { teamId?: number }

export const loadTrainingSessions = (
  payload: Params,
): Promise<ApiResult<TrainingSessionPayload[]>> => {
  const { teamId } = payload
  const params = {}
  const path = 'training_sessions'

  if (!teamId) {
    return withoutAuth<TrainingSessionPayload[]>(
      (client) => client.get<TrainingSessionPayload[]>(path, { params }),
      errorMessages.NEURAL_MODEL_DATA,
    )
  }

  const authParams = { action: WindAuthAction.ViewModels, teamId }

  return withAuth<TrainingSessionPayload[]>(
    authParams,
    (client) => client.get<TrainingSessionPayload[]>(path, { params }),
    errorMessages.NEURAL_MODEL_DATA,
  )
}
