import type { ComputedRef } from 'vue'

type ComposableMethod = (...args: unknown[]) => unknown

type ExtractDefineComposableDefinitionKeys<TT> = keyof {
  [K in keyof TT as TT[K] extends ComposableMethod | ComputedRef ? never : K]: unknown
}

type ExtractDefineComposableDefinition<TT> = TT extends undefined | void
  ? object
  : ExtractDefineComposableDefinitionKeys<TT> extends keyof TT
    ? TT
    : never

type ComposableDefinition<T, P extends unknown[]> = (...args: P) => T

/**
 * Returns a typed version of the provided composable implementation
 * The composable implementation return type doesn't have to be specified as it is extracted
 */
export const defineComposable = <T, P extends unknown[]>(
  implementation: (...args: P) => T,
): ComposableDefinition<ExtractDefineComposableDefinition<T>, P> =>
  implementation as ComposableDefinition<ExtractDefineComposableDefinition<T>, P>
