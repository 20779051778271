import type { MeasureOverlayData, MeasureRegionsPayload } from '@/modules/Editor/MeasureOverlayData'
import {
  DRAWING_ANNOTATION_ID,
  calculateBoundingBoxMeasures,
  calculateEllipseMeasures,
  calculatePolygonMeasures,
  calculatePolylineMeasures,
  toFullOverlayData,
} from '@/modules/Editor/annotationMeasures'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import {
  isImageAnnotation,
  isVideoAnnotation,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import { getMeasureOverlayColorFromAnnotation } from '@/modules/Editor/utils/getMeasureOverlayColorFromAnnotation'
import { getRadiologyMeasureRegions } from '@/modules/Editor/utils/radiology/getRadiologyMeasureRegions'
import type { View } from '@/modules/Editor/views/view'
import { MeasureManagerEvents } from '@/modules/Editor/eventBus'

export enum Events {
  MEASURE_DATA_CHANGED = 'measureData:changed',
}

const resolveMeasureAnnotation = (
  annotation: Annotation,
  frameIndex: number,
): Annotation | null => {
  if (isImageAnnotation(annotation)) {
    return annotation
  }

  if (isVideoAnnotation(annotation)) {
    const { data } = inferVideoData(annotation, frameIndex)
    if (!data || Object.keys(data).length === 0) {
      return null
    }
    return shallowCloneAnnotation(annotation, { data })
  }

  return null
}

export class MeasureManager {
  // This should be kept as reactive as this is used by
  // Workview component to render annotation overlays.
  public measureOverlayDataEntries: Record<string, MeasureOverlayData> = {}

  constructor(private view: View) {}

  public get measureData(): MeasureOverlayData[] {
    return Object.values(this.measureOverlayDataEntries)
  }

  public reset(): void {
    // Cache the overlay for currently drawing annotation as it's not created yet
    const drawingAnnotationOverlay = this.measureOverlayDataEntries[DRAWING_ANNOTATION_ID]

    this.measureOverlayDataEntries = this.view.annotationManager.visibleAnnotations
      .map((a) => this.getMeasureOverlay(a))
      .filter((a): a is MeasureOverlayData => !!a)
      .reduce((map: { [key: string]: MeasureOverlayData }, entry) => {
        map[entry.id] = entry
        return map
      }, {})

    if (drawingAnnotationOverlay) {
      this.measureOverlayDataEntries[DRAWING_ANNOTATION_ID] = drawingAnnotationOverlay
    }

    MeasureManagerEvents.measureDataChanged.emit({ viewId: this.view.id }, this.measureData)
  }

  public updateOverlayForExistingAnnotation(annotation: Annotation): void {
    const newOverlay = this.getMeasureOverlay(annotation)
    if (!newOverlay) {
      return
    }

    this.measureOverlayDataEntries = {
      ...this.measureOverlayDataEntries,
      [newOverlay.id]: newOverlay,
    }

    MeasureManagerEvents.measureDataChanged.emit({ viewId: this.view.id }, this.measureData)
  }

  public updateOverlayForDrawingAnnotation(overlay: MeasureOverlayData): void {
    const newOverlay = {
      ...overlay,
      id: DRAWING_ANNOTATION_ID,
    }

    this.measureOverlayDataEntries = {
      ...this.measureOverlayDataEntries,
      [newOverlay.id]: newOverlay,
    }

    MeasureManagerEvents.measureDataChanged.emit({ viewId: this.view.id }, this.measureData)
  }

  public updateOverlayForAnnotation(annotation: Annotation): void {
    const newOverlay = this.getMeasureOverlay(annotation)
    if (!newOverlay) {
      return
    }

    const entry = this.measureOverlayDataEntries[annotation.id]
    if (entry === undefined) {
      this.measureOverlayDataEntries[newOverlay.id] = newOverlay

      MeasureManagerEvents.measureDataChanged.emit({ viewId: this.view.id }, this.measureData)
      return
    }

    this.measureOverlayDataEntries[newOverlay.id] = newOverlay

    MeasureManagerEvents.measureDataChanged.emit({ viewId: this.view.id }, this.measureData)
  }

  public removeOverlayForAnnotation(annotationId: Annotation['id']): void {
    const entry = this.measureOverlayDataEntries[annotationId]
    if (entry === undefined) {
      return
    }
    delete this.measureOverlayDataEntries[annotationId]

    MeasureManagerEvents.measureDataChanged.emit({ viewId: this.view.id }, this.measureData)
  }

  public removeOverlayForDrawingAnnotation(): void {
    const entry = this.measureOverlayDataEntries[DRAWING_ANNOTATION_ID]
    if (entry === undefined) {
      return
    }
    delete this.measureOverlayDataEntries[DRAWING_ANNOTATION_ID]

    MeasureManagerEvents.measureDataChanged.emit({ viewId: this.view.id }, this.measureData)
  }

  public getMeasureOverlay(annotation: Annotation): MeasureOverlayData | null {
    const { view } = this

    if (!['ellipse', 'polygon', 'line', 'bounding_box'].includes(annotation.type)) {
      return null
    }

    const measureAnnotation = resolveMeasureAnnotation(annotation, view.currentFrameIndex)

    if (!measureAnnotation) {
      return null
    }

    const { data, id } = measureAnnotation

    const color = getMeasureOverlayColorFromAnnotation(measureAnnotation)
    const {
      camera,
      measureManager: { measureRegion },
    } = view

    let measures: Pick<MeasureOverlayData, 'measures' | 'label'> | null = null

    if (annotation.type === 'bounding_box') {
      measures = calculateBoundingBoxMeasures(data, camera, measureRegion)
    }

    if (annotation.type === 'ellipse') {
      measures = calculateEllipseMeasures(data, camera, measureRegion)
    }

    if (annotation.type === 'polygon') {
      measures = calculatePolygonMeasures(data, camera, measureRegion)
    }

    if (annotation.type === 'line') {
      measures = calculatePolylineMeasures(data, camera, measureRegion)
    }

    return measures ? toFullOverlayData(measures, color, id) : null
  }

  /**
   * Returns measure region with high priority
   * - When there is no measure region, use the image/video size
   * - When there are multiple regions, choose the one with high priority
   *   NOTE:
   *     It is possible that there can be several which has `high_priority` flag as true,
   *     In that case, we choose the first visible one.
   * - When there is only one region, choose the first one.
   */
  get measureRegion(): MeasureRegionsPayload | null {
    const { isProcessedAsVideo, isImage, metadata } = this.view.fileManager
    if (this.view.isLoading) {
      return null
    }

    const medicalMetadata = metadata?.medical

    if (medicalMetadata) {
      const measureRegion = getRadiologyMeasureRegions(this.view.fileManager.slotName, metadata)

      if (measureRegion) {
        return measureRegion
      }
    }

    if (isProcessedAsVideo) {
      if (metadata?.measure_regions?.length) {
        const measureRegions = metadata.measure_regions
        const highPriorityOne = measureRegions.find((mr) => mr.high_priority)
        return highPriorityOne || measureRegions[0]
      }

      return {
        delta: { x: 1, y: 1 },
        high_priority: true,
        unit: { x: 'px', y: 'px' },
        x: 0,
        y: 0,
        w: this.view.width,
        h: this.view.height,
      }
    }

    if (isImage) {
      return {
        delta: { x: 1, y: 1 },
        high_priority: true,
        unit: { x: 'px', y: 'px' },
        x: 0,
        y: 0,
        w: this.view.width,
        h: this.view.height,
      }
    }

    return null
  }

  cleanup(): void {
    this.measureOverlayDataEntries = {}
  }
}
