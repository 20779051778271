import type { WindowLevelRange } from '@/modules/Editor/MedicalMetadata'
import type { WindowLevels } from '@/modules/Editor/imageManipulation'
import {
  MIN_PIXEL_CHANNEL,
  NUM_PIXEL_CHANNELS_64k,
  NUM_PIXEL_CHANNELS_256,
  MAX_PIXEL_CHANNEL_64k,
  MAX_PIXEL_CHANNEL_256,
} from '@/modules/Editor/imageManipulation'

type ColorSpace = 'RGB' | 'RG16'

export const getNumPixelChannels = (colorspace?: ColorSpace | null): number =>
  colorspace === 'RG16' ? NUM_PIXEL_CHANNELS_64k : NUM_PIXEL_CHANNELS_256

/**
 * For N channels, the max value is N-1, as the first channel is intepretted as 0.
 */
export const getMaxWindowLevel = (colorspace?: ColorSpace | null): number =>
  colorspace === 'RG16' ? MAX_PIXEL_CHANNEL_64k : MAX_PIXEL_CHANNEL_256

/**
 * Returns default for colorspace window/level range or
 * formats provided window level
 *
 * @param colorspace
 * @param defaultWindow
 * @returns {[number, number]}
 */
export const getWindowLevelsRange = (
  colorspace?: ColorSpace | null,
  defaultWindowLevelRange?: WindowLevelRange | null,
): WindowLevels => {
  let minLevel = MIN_PIXEL_CHANNEL
  let maxLevel = getMaxWindowLevel(colorspace)

  if (defaultWindowLevelRange) {
    const { min, max } = defaultWindowLevelRange
    if (minLevel < min) {
      minLevel = min
    }
    if (maxLevel > max) {
      maxLevel = max
    }
  }

  return [minLevel, maxLevel]
}

export const refineWindowLevels = (value: number, colorspace?: ColorSpace | null): number => {
  const range = getWindowLevelsRange(colorspace)
  return Math.max(range[0], Math.min(range[1], value))
}
