import type { FrameData } from '@/core/annotations'
import type { AnnotationData, VideoAnnotationData } from '@/modules/Editor/AnnotationData'

/**
 * Returns true if an annotation data object is empty (i.e. has no properties that are defined).
 *
 * This function is used when paginating annotations. Annotation metadata is fetched and stored
 * separately to frame data. The metadata contains the full set of keyframe indices, but we might
 * only have frame data (i.e. shape data) for a subset of those indices. When the editor
 * normalizes these empty frames, it instantiates a few properties which will be set to undefined.
 *
 * So by using this function, we can detect when we are trying to serialize a frame for which we
 * don't yet have data.
 */
export const annotationDataIsEmpty = (annotationData?: AnnotationData | null): boolean =>
  !annotationData || Object.values(annotationData).every((value) => value === undefined)

export const frameDataIsLoaded = (
  frameData?: AnnotationData | (FrameData & { loaded?: boolean }) | null,
): boolean =>
  !frameData ||
  !Object.entries(frameData).find(([key, value]) => key === 'loaded' && value === false)

/**
 * When paginating annotations, we will sometimes have annotation frames that have no
 * data. This function returns the indices of all frames that DO have frame data.
 */
export const getFramesWithData = (annotationData: VideoAnnotationData['frames']): number[] => {
  const frameEntries = Object.entries(annotationData)
  const frameEntriesWithData = frameEntries.filter(([, data]) =>
    // When normalizing annotation data, the editor will always set  some properties,
    // even if the annotation data is empty. However these properties will have
    // undefined values. All populated frames will have at least some properties
    // with defined values.
    Object.values(data).some((value) => value !== undefined),
  )
  return frameEntriesWithData.map(([index]) => Number(index))
}
