<template>
  <!-- TODO: Uses button class from main style -->
  <div
    class="header"
    :style="style"
  >
    <SecondaryButton
      class="clear"
      size="small"
      @click="tryClear"
    >
      Clear
    </SecondaryButton>
    <SecondaryButton
      class="rerun"
      size="small"
      :disable="busy"
      @click="tryRerun"
    >
      Rerun
    </SecondaryButton>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'

// eslint-disable-next-line boundaries/element-types
import SecondaryButton from '@/components/Common/Button/V1/SecondaryButton.vue'

export default defineComponent({
  name: 'ClickHeaderTools',
  components: {
    SecondaryButton,
  },
  props: {
    x: { required: true, type: Number },
    y: { required: true, type: Number },
    onClear: { required: true, type: Function as PropType<(...args: unknown[]) => void> },
    onRerun: { required: true, type: Function as PropType<(...args: unknown[]) => void> },
    busy: { required: true, type: Boolean },
  },
  setup(props) {
    const style = computed(() => ({
      left: `${props.x}px`,
      top: `${props.y}px`,
    }))

    const tryClear = (): void => {
      props.onClear()
    }

    const tryRerun = (): void => {
      if (!props.busy) {
        props.onRerun()
      }
    }

    return {
      style,
      tryClear,
      tryRerun,
    }
  },
})
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}

.clear,
.rerun {
  background: transparent;
  border-color: $colorWhite !important;
  color: $colorWhite !important;
  text-transform: uppercase;

  &:hover,
  &:active {
    background: transparent;
  }
}
</style>
