import type { FeatureName } from '@/store/types/FeaturePayload'
import type { V2DatasetItemPayload } from '@/store/types/V2DatasetItemPayload'
import type { V2DatasetItemSlot } from '@/store/types/V2DatasetItemSlot'
import { MedicalVolumePlane } from '@/modules/Editor/MedicalMetadata'
import type { MedicalMetadata } from '@/modules/Editor/MedicalMetadata'
import { isFileCompatibleWithVtk } from '@/modules/Editor/utils/radiology/isFileCompatibleWithVtk'

import cloneDeep from 'lodash/cloneDeep'

export const VIRTUAL_MPR_SLOT_ID_PREFIX = 'virtual-mpr-slot' as const

const virtualSlotAName = '0.2'
const virtualSlotBName = '0.3'

const injectVirtualSlotsForAxialPrimaryPlane = (
  item: V2DatasetItemPayload,
  primarySlot: V2DatasetItemSlot,
  medicalMetadata: MedicalMetadata,
  shape: [number, number, number, number],
): void => {
  item.layout = {
    type: 'horizontal',
    slots: [primarySlot.slot_name, virtualSlotAName, virtualSlotBName],
    version: 1,
  }
  item.slot_types = ['dicom', 'dicom', 'dicom']

  medicalMetadata.plane_map = {
    [primarySlot.slot_name]: MedicalVolumePlane.AXIAL,
    [virtualSlotAName]: MedicalVolumePlane.CORONAL,
    [virtualSlotBName]: MedicalVolumePlane.SAGITTAL,
  }

  const width = shape[1]
  const height = shape[2]
  const depth = shape[3]

  const virtualSlotA = cloneDeep(primarySlot) // coronal plane
  const virtualSlotB = cloneDeep(primarySlot) // sagittal plane

  virtualSlotA.id = `${VIRTUAL_MPR_SLOT_ID_PREFIX}-${virtualSlotAName}-${primarySlot.id}`
  virtualSlotA.slot_name = virtualSlotAName
  if (virtualSlotA.metadata) {
    virtualSlotA.metadata.width = width
    virtualSlotA.metadata.height = depth
    virtualSlotA.total_sections = height
  }

  virtualSlotB.id = `${VIRTUAL_MPR_SLOT_ID_PREFIX}-${virtualSlotBName}-${primarySlot.id}`
  virtualSlotB.slot_name = virtualSlotBName
  if (virtualSlotB.metadata) {
    virtualSlotB.metadata.width = height
    virtualSlotB.metadata.height = depth
    virtualSlotB.total_sections = width
  }

  item.slots.push(virtualSlotA)
  item.slots.push(virtualSlotB)
}

const injectVirtualSlotsForCoronalPrimaryPlane = (
  item: V2DatasetItemPayload,
  primarySlot: V2DatasetItemSlot,
  medicalMetadata: MedicalMetadata,
  shape: [number, number, number, number],
): void => {
  item.layout = {
    type: 'horizontal',
    slots: [virtualSlotAName, primarySlot.slot_name, virtualSlotBName],
    version: 1,
  }
  item.slot_types = ['dicom', 'dicom', 'dicom']

  medicalMetadata.plane_map = {
    [virtualSlotAName]: MedicalVolumePlane.AXIAL,
    [primarySlot.slot_name]: MedicalVolumePlane.CORONAL,
    [virtualSlotBName]: MedicalVolumePlane.SAGITTAL,
  }

  const width = shape[1]
  const height = shape[2]
  const depth = shape[3]

  const virtualSlotA = cloneDeep(primarySlot) // axial plane
  const virtualSlotB = cloneDeep(primarySlot) // sagittal plane

  virtualSlotA.id = `${VIRTUAL_MPR_SLOT_ID_PREFIX}-${virtualSlotAName}-${primarySlot.id}`
  virtualSlotA.slot_name = virtualSlotAName
  if (virtualSlotA.metadata) {
    virtualSlotA.metadata.width = width
    virtualSlotA.metadata.height = height
    virtualSlotA.total_sections = depth
  }

  virtualSlotB.id = `${VIRTUAL_MPR_SLOT_ID_PREFIX}-${virtualSlotBName}-${primarySlot.id}`
  virtualSlotB.slot_name = virtualSlotBName
  if (virtualSlotB.metadata) {
    virtualSlotB.metadata.width = height
    virtualSlotB.metadata.height = depth
    virtualSlotB.total_sections = width
  }

  item.slots.push(virtualSlotA)
  item.slots.push(virtualSlotB)
}

const injectVirtualSlotsForSagittalPrimaryPlane = (
  item: V2DatasetItemPayload,
  primarySlot: V2DatasetItemSlot,
  medicalMetadata: MedicalMetadata,
  shape: [number, number, number, number],
): void => {
  item.layout = {
    type: 'horizontal',
    slots: [virtualSlotAName, virtualSlotBName, primarySlot.slot_name],
    version: 1,
  }
  item.slot_types = ['dicom', 'dicom', 'dicom']

  medicalMetadata.plane_map = {
    [virtualSlotAName]: MedicalVolumePlane.AXIAL,
    [virtualSlotBName]: MedicalVolumePlane.CORONAL,
    [primarySlot.slot_name]: MedicalVolumePlane.SAGITTAL,
  }

  const width = shape[1]
  const height = shape[2]
  const depth = shape[3]

  const virtualSlotA = cloneDeep(primarySlot) // axial plane
  const virtualSlotB = cloneDeep(primarySlot) // coronal plane

  virtualSlotA.id = `${VIRTUAL_MPR_SLOT_ID_PREFIX}-${virtualSlotAName}-${primarySlot.id}`
  virtualSlotA.slot_name = virtualSlotAName
  if (virtualSlotA.metadata) {
    virtualSlotA.metadata.width = width
    virtualSlotA.metadata.height = height
    virtualSlotA.total_sections = depth
  }

  virtualSlotB.id = `${VIRTUAL_MPR_SLOT_ID_PREFIX}-${virtualSlotBName}-${primarySlot.id}`
  virtualSlotB.slot_name = virtualSlotBName
  if (virtualSlotB.metadata) {
    virtualSlotB.metadata.width = width
    virtualSlotB.metadata.height = depth
    virtualSlotB.total_sections = height
  }

  item.slots.push(virtualSlotA)
  item.slots.push(virtualSlotB)
}

/**
 * Virtual slots are non-existing slots that are created for the purpose of
 * the dynamic MPR support. They're being injected to the layout config
 * alongside the primary view and rendered with vtk.js dynamically.
 */
export const injectVirtualSlotsForDicomMPR = (
  item: V2DatasetItemPayload,
  featureFlags: Record<FeatureName, boolean>,
): void => {
  if (!featureFlags.MED_2D_VIEWER || !featureFlags.DYNAMIC_MPR) {
    return
  }

  // Injection is only possible for items with a single slot
  if (item.slots.length !== 1) {
    return
  }

  const primarySlot = item.slots[0]
  const { metadata } = primarySlot
  const shape = metadata?.shape
  const medicalMetadata = metadata?.medical

  if (
    !shape ||
    !medicalMetadata ||
    primarySlot.type !== 'dicom' ||
    medicalMetadata.handler !== 'MONAI' ||
    !isFileCompatibleWithVtk(metadata)
  ) {
    return
  }

  if (metadata.primary_plane === MedicalVolumePlane.AXIAL) {
    injectVirtualSlotsForAxialPrimaryPlane(item, primarySlot, medicalMetadata, shape)
  }

  if (metadata.primary_plane === MedicalVolumePlane.CORONAL) {
    injectVirtualSlotsForCoronalPrimaryPlane(item, primarySlot, medicalMetadata, shape)
  }

  if (metadata.primary_plane === MedicalVolumePlane.SAGITTAL) {
    injectVirtualSlotsForSagittalPrimaryPlane(item, primarySlot, medicalMetadata, shape)
  }
}
