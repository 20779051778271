import type { Primitive } from '@sentry/types'
import type { Extras } from '@sentry/types'

/* eslint-disable no-restricted-imports */
import {
  addBreadcrumb,
  captureException,
  captureMessage,
  init,
  setContext as _setContext,
  setTag,
  setTags,
  setUser,
  withScope,
  browserTracingIntegration,
  replayIntegration,
  addIntegration,
  getReplay,
  replayCanvasIntegration,
} from '@sentry/vue'
import { browserProfilingIntegration } from '@sentry/browser'

/* eslint-enable no-restricted-imports */
import type { VueConstructor } from 'vue'
import type Router from 'vue-router'

import { BUILD, BUILD_ENVIRONMENT, ENVIRONMENT, SENTRY_DSN } from './config'

const isProductionBuild = ENVIRONMENT === 'production'
const isEnvStaging = ENVIRONMENT === 'staging'
const REPLAY_CONFIG = {
  maskAllText: false,
  blockAllMedia: false,
  block: ['img:not([src$=".svg"])', 'video', 'canvas.main-layer', '.medical-3d-view canvas'],
  networkDetailAllowUrls: [
    /.*v7labs\.com\/api.*\/commands/, // workview command
    /.*v7labs\.com\/api.*\/list_ids/, // fetch workview paginated items
    /.*v7labs\.com\/api.*\/list_all_ids/, // fetch workview items
  ],
  networkCaptureBodies: true,
  unblock: ['.darwin-icon'],
  networkResponseHeaders: ['traceparent'],
  _experiments: {
    traceInternals: true,
    captureExceptions: true,
  },
}
const REPLAY_CANVAS_CONFIG = {
  quality: 'low' as const,
}

/*
 * Add Sentry replay integration or stop a session if enable is false.
 * Also deal with tracking the canvas depending on the passed input.
 */
export const toggleReplay = (enable = false, withCanvas = false): void => {
  if (enable === false) {
    getReplay()?.stop()
    return
  }

  if (!getReplay()) {
    addIntegration(replayIntegration(REPLAY_CONFIG))
  } else {
    try {
      getReplay()?.start()
    } catch {
      // there is no way to check if replay is stopped (although an internal var is there) but
      // it will drop an error if we try to start it again.
    }
  }

  if (withCanvas) {
    addIntegration(replayCanvasIntegration(REPLAY_CANVAS_CONFIG))
  }

  if (!withCanvas) {
    replayCanvasIntegration({
      // Enabling the following will ensure your canvas elements are not forced
      // into `preserveDrawingBuffer` - and also we would not track the canvas.
      enableManualSnapshot: true,
    })
  }
}

export const setupSentry = (Vue: VueConstructor, router: Router): void => {
  if (BUILD_ENVIRONMENT === 'development') {
    return
  }
  const browserIntegration = browserTracingIntegration({
    router,
  })

  init({
    Vue,
    dsn: SENTRY_DSN || '',
    integrations: [browserIntegration, ...(isEnvStaging ? [browserProfilingIntegration()] : [])],
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: isProductionBuild ? 1.0 : 0.1,
    tracesSampleRate: isProductionBuild ? 0.01 : 1.0,
    tracingOptions: {
      // This is default. We had it to true, but this is actually causing UI lockups.
      // We need to instead identify specific components to track and list them here
      trackComponents: false,
    },
    ...(ENVIRONMENT && { environment: ENVIRONMENT }),
    ...(BUILD && { release: BUILD }),
    ...(isEnvStaging ? { profilesSampleRate: 1 } : {}),
    // We try to filter out errors using the "shouldFilter" logic in beforeSend,
    // but this is apparently not working.
    // They are now also added tot he ignoreErrors setting as an experiment.
    // If it works, we will remove the beforeSend logic fully.
    ignoreErrors: [
      /Loading chunk [0-9]+ failed/,
      /Loading CSS chunk [0-9]+ failed/,
      /ResizeObserver loop limit exceeded/,
      /ResizeObserver loop completed with undelivered notifications/,
      /Unable to get FullStory session URL: Unable to get url: No FullStory session URL found/,
    ],
  })

  // Enable replay to start tracking even when no user is logged in.
  // Allows us to track the log in screen
  toggleReplay(true, false)
}

// Allows us to send extra data when it is actually needed.
export const reportToSentry = (
  error: Error,
  context: { tags?: { [key: string]: Primitive }; extra: Extras },
): void =>
  withScope((scope) => {
    context.tags && scope.setTags(context.tags)
    scope.setExtras(context.extra)
    captureException(error)
  })

/**
 * Sets the sentry context.
 * This is a wrapper around the sentry setContext function that adds a timestamp to the
 * context object.
 *
 * @param {string} name - The name of the context.
 * @param {Object} [context={}] - Additional context properties.
 * @returns {void}
 */
export const setContext = (name: string, context: { [key: string]: unknown } = {}): void => {
  _setContext(name, {
    ...context,
    when: new Date().toISOString(),
  })
}

/**
 * Creates a string representing a combination of pressed keys.
 * @param {KeyboardEvent} e - The keyboard event.
 * @returns {string} Key combination string like 'Shift+Control+X'.
 */
const composeKeyString = (e: KeyboardEvent): string =>
  `${e.shiftKey ? 'SHIFT+' : ''}${e.ctrlKey ? 'CTRL+' : ''}${e.altKey ? 'ALT+' : ''}${
    e.metaKey ? 'CMD+' : ''
  }${(e.key || e.code)?.toUpperCase()}`

export const trackHotkey = (e: KeyboardEvent, source?: 'hotkeyManager'): void => {
  // Ignore modifier keys to reduce noise
  if (
    e.key === 'Shift' ||
    e.code === 'ShiftLeft' ||
    e.code === 'ShiftRight' ||
    e.key === 'Control' ||
    e.code === 'ControlLeft' ||
    e.code === 'ControlRight' ||
    e.key === 'Alt' ||
    e.code === 'AltLeft' ||
    e.code === 'AltRight' ||
    e.key === 'Meta' ||
    e.code === 'MetaLeft' ||
    e.code === 'MetaRight'
  ) {
    return
  }

  const shortcut = composeKeyString(e)
  const target = e.target
    ? (e.target as Element).className || (e.target as Element).localName
    : undefined
  const type = e.type

  addBreadcrumb({
    category: 'hotkey',
    level: 'info',
    type: 'user',
    data: {
      shortcut,
      target,
      type,
      source,
    },
  })
}

export { addBreadcrumb, captureMessage, captureException, setTag, setTags, setUser, getReplay }
