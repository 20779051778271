import { defineStore } from 'pinia'
import { ref } from 'vue'

import { setContext } from '@/services/sentry'

export const useFatalError = defineStore('fatalError', () => {
  const hasFatalError = ref(false)

  return {
    hasFatalError,
    setFatalError: (error?: unknown): void => {
      setContext('error', { error: error })
      console.error('Fatal error!')
      hasFatalError.value = true
    },
  }
})
