import cloneDeep from 'lodash/cloneDeep'

import type { AnnotationData, Ellipse } from '@/modules/Editor/AnnotationData'
import { euclideanDistance } from '@/modules/Editor/algebra'
import { calculateEllipseMeasures, toFullOverlayData } from '@/modules/Editor/annotationMeasures'
import { getAllVerticesFromEllipse } from '@/modules/Editor/annotationTypes/ellipse'
import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import { isLeftMouseButton } from '@/modules/Editor/mouse'
import type { IPoint, EditablePoint } from '@/modules/Editor/point'
import {
  addPoints,
  createEditablePoint,
  divScalar,
  pointIsVertexOfPath,
  subPoints,
} from '@/modules/Editor/point'
import { ToolName } from '@/modules/Editor/tools/types'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import { updateAnnotationData } from '@/modules/Editor/actions'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import { isVideoAnnotation } from '@/modules/Editor/models/annotation/annotationKindValidator'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import { moveAnnotationVertex } from '@/modules/Editor/moveAnnotationVertex'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import { resolveModifierByPriority } from '@/modules/Editor/utils'
import { preselectOrPromptForAnnotationClass } from '@/modules/Editor/utils/preselectOrPromptForAnnotationClass'
import type { Action } from '@/modules/Editor/managers/actionManager'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'

import { ELLIPSE_ANNOTATION_TYPE } from './types'

interface EllipseTool extends Tool {
  initialPoint?: IPoint
  cursorPoint?: IPoint
  hoveringVertex?: EditablePoint
  initialAnnotationData?: AnnotationData
  onStart: (context: ToolContext, event: PointerEvent) => void
  onMove: (context: ToolContext, event: PointerEvent) => void
  onEnd: (context: ToolContext, event: PointerEvent) => void
  draw: (view: View) => void
  renderFn: (ctx: CanvasRenderingContext2D, view: View) => void
  targetedAnnotationId: string | null
  targetedItemsIndex: number | undefined
}

const annotationCreationAction = async (
  context: ToolContext,
  ellipse: AnnotationData,
): Promise<Action> => {
  const params = { type: ELLIPSE_ANNOTATION_TYPE, data: ellipse }
  const newAnnotation =
    await context.editor.activeView.annotationManager.prepareAnnotationForCreation(params)

  if (!newAnnotation) {
    throw new Error('Failed to create annotation')
  }

  // support view id for undoing purposes
  const sourceViewId = context.editor.activeView.id

  return {
    do(): boolean {
      context.editor.activeView.annotationManager.createAnnotation(newAnnotation)
      context.editor.activeView.annotationManager.selectAnnotation(newAnnotation.id)
      context.editor.activeView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)

      return true
    },
    undo(): boolean {
      // we don't want to use the active view here, as by the time the user want to undo
      // the view could have changed due to multi-slot;
      // this would result in the annotation not being found and the undo action failing
      const sourceView = context.editor.viewsList.find(({ id }) => id === sourceViewId)
      if (!sourceView) {
        return false
      }

      sourceView.annotationManager.deleteAnnotation(newAnnotation.id)

      sourceView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)
      return true
    },
  }
}

export const createEllipseData = (
  context: ToolContext,
  initialPoint: IPoint,
  cursorPoint: IPoint,
): Ellipse | null => {
  const p1 = context.editor.activeView.camera.canvasViewToImageView(cursorPoint)
  const p2 = context.editor.activeView.camera.canvasViewToImageView(initialPoint)

  const center = createEditablePoint(divScalar(addPoints(p2, p1), 2))
  const right = createEditablePoint(p1)
  const { x: symY, y: symX } = subPoints(right, center)
  const top = createEditablePoint({ x: symX + center.x, y: symY + center.y })
  const bottom = createEditablePoint({ x: center.x - symX, y: center.y - symY })
  const left = createEditablePoint({ x: center.x - symY, y: center.y - symX })

  const ellipse: Ellipse = { center, top, right, bottom, left }

  return ellipse
}

export const updateDrawingMeasuresOverlay = (
  context: ToolContext,
  initialPoint: IPoint,
  cursorPoint: IPoint,
): void => {
  const ellipse = createEllipseData(context, cursorPoint, initialPoint)
  if (ellipse) {
    const view = context.editor.activeView
    const measureData = toFullOverlayData(
      calculateEllipseMeasures(ellipse, view.camera, view.measureManager.measureRegion),
      view.annotationManager.preselectedAnnotationClassColor(),
    )

    if (measureData) {
      context.editor.activeView.measureManager.updateOverlayForDrawingAnnotation(measureData)
    } else {
      context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
    }
  } else {
    context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
  }
}

export const ellipseTool: EllipseTool = {
  targetedAnnotationId: null,
  targetedItemsIndex: undefined,
  onStart(context: ToolContext, event: PointerEvent) {
    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    const { camera, isLoading } = context.editor.activeView
    const { selectedAnnotation } = context.editor.activeView.annotationManager

    this.initialPoint = point
    if (!this.hoveringVertex) {
      this.draw(context.editor.activeView)
      context.editor.activeView.annotationManager.deselectVertex()
      return
    }

    this.hoveringVertex.isSelected = true
    if (selectedAnnotation) {
      if (this.targetedItemsIndex !== undefined) {
        context.editor.activeView.annotationsLayer.activateVertexWithState(
          selectedAnnotation.id,
          this.targetedItemsIndex,
          { isSelected: true },
        )
      }
    } else {
      this.draw(context.editor.activeView)
      return CallbackStatus.Stop
    }

    // Save initial annotation data, which is a necessary argument for undoable/redoable action
    this.initialAnnotationData = cloneDeep(selectedAnnotation.data)

    if (!isVideoAnnotation(selectedAnnotation)) {
      this.draw(context.editor.activeView)
      return CallbackStatus.Stop
    }

    const { data, subs } = inferVideoData(
      selectedAnnotation,
      context.editor.activeView.currentFrameIndex,
    )
    if (isLoading) {
      throw new Error('Ellipse: Expected video to be loaded')
    }
    context.editor.activeView.annotationManager.updateAnnotationFrame(
      selectedAnnotation,
      cloneDeep(data),
      subs,
      context.editor.activeView.currentFrameIndex,
    )

    // Force update hovering vertex with clicked one,
    // so it's possible to select it right away and trigger ellipse editing
    // This operation is only necessary for videos, since highlighting/selecting
    // vertices only works on keyframes in our current API
    const initialImagePoint = camera.canvasViewToImageView(this.initialPoint)
    this.hoveringVertex =
      context.editor.activeView.annotationManager.findAnnotationVertexAt(initialImagePoint)
    if (this.hoveringVertex) {
      this.hoveringVertex.isSelected = true
      if (selectedAnnotation && this.targetedItemsIndex !== undefined) {
        context.editor.activeView.annotationsLayer.activateVertexWithState(
          selectedAnnotation.id,
          this.targetedItemsIndex,
          { isSelected: true },
        )
      }
    }

    this.draw(context.editor.activeView)
    return CallbackStatus.Stop
  },

  onMove(context: ToolContext, event: PointerEvent) {
    if (!context.editor.activeView.hitTarget(event)) {
      return CallbackStatus.Stop
    }

    context.editor.activeView.unhighlightAllVertices()

    let previousCursorImagePoint: IPoint | undefined
    if (this.cursorPoint) {
      previousCursorImagePoint = context.editor.activeView.camera.canvasViewToImageView(
        this.cursorPoint,
      )
    }

    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    this.cursorPoint = point

    // If we're hovering over a selected vertex, move the vertex
    const cursorImagePoint = context.editor.activeView.camera.canvasViewToImageView(
      this.cursorPoint,
    )

    const { selectedAnnotation } = context.editor.activeView.annotationManager
    if (selectedAnnotation) {
      this.targetedItemsIndex = context.editor.activeView.annotationsLayer.hitVertexRegion(
        selectedAnnotation.id,
        cursorImagePoint,
      )

      if (selectedAnnotation && this.targetedItemsIndex !== undefined) {
        context.editor.activeView.annotationsLayer.activateVertexWithState(
          selectedAnnotation.id,
          this.targetedItemsIndex,
          { isHighlighted: true },
        )
      } else {
        context.editor.activeView.annotationsLayer.unhighlightAllVertices()
      }
    }

    if (this.hoveringVertex && this.hoveringVertex.isSelected) {
      if (!selectedAnnotation) {
        this.reset(context)
        this.draw(context.editor.activeView)
        return
      }

      if (!previousCursorImagePoint) {
        this.reset(context)
        this.draw(context.editor.activeView)
        return
      }

      if (!isVideoAnnotation(selectedAnnotation)) {
        moveAnnotationVertex(
          selectedAnnotation,
          context.editor.activeView,
          this.hoveringVertex,
          subPoints(cursorImagePoint, previousCursorImagePoint),
          resolveModifierByPriority(event),
        )
        this.draw(context.editor.activeView)
        if (selectedAnnotation) {
          context.editor.activeView.updateRenderedAnnotation(selectedAnnotation.id)
        }
        return
      }

      const { isLoading } = context.editor.activeView
      if (isLoading) {
        return
      }

      const {
        data: annotationData,
        keyframe,
        subs,
      } = inferVideoData(selectedAnnotation, context.editor.activeView.currentFrameIndex)
      if (!annotationData) {
        return
      }
      // If it's not yet a key frame, make it a key frame
      // Then we need to relocate the vertex since cloneDeep breaks the old link.
      if (!keyframe) {
        const { isLoading } = context.editor.activeView
        if (isLoading) {
          return
        }
        context.editor.activeView.annotationManager.updateAnnotationFrame(
          selectedAnnotation,
          cloneDeep(annotationData),
          subs,
          context.editor.activeView.currentFrameIndex,
        )
      }

      // Build an Annotation object with current frame data, then translate it
      const currentAnnotation = shallowCloneAnnotation(selectedAnnotation, { data: annotationData })
      const path = getAllVerticesFromEllipse(currentAnnotation.data)
      if (
        pointIsVertexOfPath(this.hoveringVertex, path, 5 / context.editor.activeView.cameraScale)
      ) {
        moveAnnotationVertex(
          currentAnnotation,
          context.editor.activeView,
          this.hoveringVertex,
          subPoints(cursorImagePoint, previousCursorImagePoint),
          resolveModifierByPriority(event),
        )
        context.editor.activeView.annotationManager.updateAnnotationFrame(
          selectedAnnotation,
          currentAnnotation.data,
          subs,
          context.editor.activeView.currentFrameIndex,
        )
        if (selectedAnnotation) {
          context.editor.activeView.updateRenderedAnnotation(selectedAnnotation.id)
        }
      }
    }

    // If we're drawing a new ellipse, just record the new cursor point and repaint
    if (this.initialPoint) {
      if (context.editor.renderMeasures) {
        updateDrawingMeasuresOverlay(context, this.initialPoint, this.cursorPoint)
      }
      this.draw(context.editor.activeView)
      return CallbackStatus.Stop
    }

    let topAnnotation
    context.editor.activeView.annotationsLayer.hitItemRegion(cursorImagePoint).then((res) => {
      this.targetedAnnotationId = res || null
    })

    if (this.targetedAnnotationId) {
      topAnnotation = context.editor.activeView.annotationManager.getAnnotation(
        this.targetedAnnotationId,
      )
    }

    // If we're not hovering over any annotation,
    // or we're on top of an annotation which is not an ellipse, repaint and return
    if (!topAnnotation || topAnnotation.type !== ELLIPSE_ANNOTATION_TYPE) {
      context.editor.activeView.annotationManager.unhighlightAllAnnotations()
      this.reset(context)
      this.draw(context.editor.activeView)
      return
    }

    if (context.editor.activeView.annotationManager.selectedAnnotation?.id !== topAnnotation.id) {
      // If we're hovering over an existing ellipse,
      // select it, and see if we're hovering over a control point
      context.editor.activeView.annotationManager.highlightAnnotation(topAnnotation.id)
      context.editor.activeView.annotationManager.selectAnnotation(topAnnotation.id)
    }

    this.hoveringVertex =
      context.editor.activeView.annotationManager.findAnnotationVertexAt(cursorImagePoint)

    if (this.hoveringVertex) {
      this.hoveringVertex.isHighlighted = true
    }

    this.draw(context.editor.activeView)
    return CallbackStatus.Stop
  },

  async onEnd(context: ToolContext, event: PointerEvent) {
    if (!this.initialPoint || !this.cursorPoint) {
      return this.reset(context)
    }

    if (this.hoveringVertex && this.hoveringVertex.isSelected) {
      const selectedAnnotation = context.editor.activeView.annotationManager.selectedAnnotation
      if (!selectedAnnotation) {
        return this.reset(context)
      }

      if (!this.initialAnnotationData) {
        return this.reset(context)
      }

      const action = updateAnnotationData(
        context.editor.activeView,
        selectedAnnotation,
        this.initialAnnotationData,
        selectedAnnotation.data,
      )

      context.editor.actionManager.do(action)
      context.editor.activeView.annotationManager.deselectVertex()
      this.reset(context)
      this.draw(context.editor.activeView)
      return CallbackStatus.Stop
    }

    const point = resolveEventPoint(event, true)
    if (point) {
      this.cursorPoint = point
    }

    const ellipse = createEllipseData(context, this.initialPoint, this.cursorPoint)
    if (!ellipse) {
      this.reset(context)
      if (context.editor.renderMeasures) {
        context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
      }
      this.draw(context.editor.activeView)
      return
    }

    try {
      await context.editor.actionManager.do(await annotationCreationAction(context, ellipse))
    } catch (e) {
      setContext('error', { error: e })
      console.error('V2 ellipse tool createAnnotation failed')
    } finally {
      if (context.editor.renderMeasures) {
        context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
      }
    }

    this.reset(context)
    this.draw(context.editor.activeView)
  },

  async activate(context: ToolContext) {
    setupMouseButtonLoadout(context, { middle: true })

    const classSelected = await preselectOrPromptForAnnotationClass(
      context.editor.activeView,
      ToolName.Ellipse,
      [ELLIPSE_ANNOTATION_TYPE],
      'You must create or select an existing Ellipse class before using the ellipse tool',
    )

    if (!classSelected) {
      return
    }

    selectCursor(EditorCursor.Ellipse)

    context.editor.registerCommand('ellipse_tool.cancel', () => {
      this.reset(context)
      this.draw(context.editor.activeView)
    })

    context.handles.push(
      ...context.editor.onMouseDown((e) => {
        if (!isLeftMouseButton(e)) {
          return CallbackStatus.Continue
        }
        return this.onStart(context, e)
      }),
    )
    context.handles.push(...context.editor.onMouseMove((e) => this.onMove(context, e)))
    context.handles.push(...context.editor.onMouseUp((e) => this.onEnd(context, e)))

    context.handles.push(...context.editor.onTouchStart((e) => this.onStart(context, e)))
    context.handles.push(...context.editor.onTouchMove((e) => this.onMove(context, e)))
    context.handles.push(...context.editor.onTouchEnd((e) => this.onEnd(context, e)))
  },

  deactivate(context: ToolContext) {
    this.reset(context)
    this.draw(context.editor.activeView)
    context.editor.activeView.annotationsLayer.unhighlightAllVertices()
  },

  renderFn(ctx: CanvasRenderingContext2D, view: View) {
    if (!this.initialPoint || !this.cursorPoint || this.hoveringVertex?.isSelected) {
      return
    }

    ctx.lineWidth = 1
    ctx.strokeStyle = view.annotationManager.preselectedAnnotationClassColor()
    ctx.fillStyle = view.annotationManager.preselectedAnnotationClassColor()

    ctx.beginPath()
    ctx.setLineDash([10, 5])
    ctx.moveTo(this.initialPoint.x, this.initialPoint.y)
    ctx.lineTo(this.cursorPoint.x, this.cursorPoint.y)
    ctx.stroke()

    ctx.beginPath()
    ctx.arc(this.initialPoint.x, this.initialPoint.y, 3.5, 0, 2 * Math.PI)
    ctx.stroke()
    ctx.fill()

    ctx.beginPath()
    ctx.arc(this.cursorPoint.x, this.cursorPoint.y, 3.5, 0, 2 * Math.PI)
    ctx.stroke()
    ctx.fill()

    ctx.fillStyle = view.annotationManager.preselectedAnnotationClassColor(0.15)
    const center = divScalar(addPoints(this.initialPoint, this.cursorPoint), 2)
    ctx.beginPath()
    ctx.setLineDash([])
    ctx.arc(center.x, center.y, euclideanDistance(this.cursorPoint, center), 0, 2 * Math.PI)
    ctx.stroke()
    ctx.fill()
  },

  draw(view) {
    view.annotationsLayer.draw((ctx) => {
      this.renderFn(ctx, view)
    })
  },

  reset(context: ToolContext) {
    this.initialPoint = undefined
    this.cursorPoint = undefined
    this.hoveringVertex = undefined
    this.targetedItemsIndex = undefined
    this.targetedAnnotationId = null

    if (context.editor.renderMeasures) {
      context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
    }
  },
}
