import generalUICopyJSON from './generalUICopy.json'
export const generalUICopy: {
  deletionBlockedTooltip: string
  exclusionBlockedTooltip: string
  classProperties: {
    name: string
    title: string
    description: string
    requiredInfo: string
    requiredInfo_annotationBlocked: string
  }
  classItemProperties: {
    name: string
    title: string
    description: string
    requiredInfo: string
    requiredInfo_annotationBlocked: string
  }
} = generalUICopyJSON
