import { defineStore } from 'pinia'
import { ref } from 'vue'

export const TimerStatus = {
  STOPPED: 'stopped', // stopped by default, once set it cannot be set back to stopped
  STARTED: 'started',
  PAUSED: 'paused',
}
export type TimerStatus = (typeof TimerStatus)[keyof typeof TimerStatus]

export const IDLE_TIMEOUT_IN_SECONDS = 60

export const useWorkviewTimerStore = defineStore('workTimer', () => {
  // How many seconds have elaapsed
  const timeInSeconds = ref(0)
  const timerStatus = ref<TimerStatus>(TimerStatus.STOPPED)
  // Keeps track of the last time a user took action, to calculate
  // how much time is left before the time tracking going idle
  const lastActionTimeInSeconds = ref(0)
  // True if 60 seconds elapsed since the last time a user took action
  const isIdlePause = ref(false)
  let timerIntervalID: number | null = null

  /**
   * Clears the timer interval
   */
  const clearTimer = (): void => {
    if (!timerIntervalID) {
      return
    }

    window.clearInterval(timerIntervalID)
    timerIntervalID = null
  }

  /**
   * Pauses the timer if the timer already STARTED as
   * otherwise it's not needed
   */
  const pauseTimer = (): void => {
    if (timerStatus.value !== TimerStatus.STARTED) {
      return
    }

    clearTimer()
    timerStatus.value = TimerStatus.PAUSED

    // The timer was manually paused, so it's not anymore
    // paused by the timer going idle after 60 seconds
    isIdlePause.value = false
  }

  /**
   * Sets the timer interval to increment the timer and check for idle state
   */
  const setTimerInterval = (): number =>
    (timerIntervalID = window.setInterval((): void => {
      timeInSeconds.value++
      const idleTimeInSeconds = timeInSeconds.value - lastActionTimeInSeconds.value
      if (idleTimeInSeconds >= IDLE_TIMEOUT_IN_SECONDS) {
        console.info(`User was idle for over ${IDLE_TIMEOUT_IN_SECONDS} seconds. Pausing timer.`)
        pauseTimer()

        // The timer has gone idle as no manual actions happened for 60 seconds
        isIdlePause.value = true
      }
    }, 1000))

  /**
   * Sets the last action time to the current time
   */
  const setLastActionTime = (): void => {
    lastActionTimeInSeconds.value = timeInSeconds.value
  }

  /**
   * Starts the timer if the timer was PAUSED, otherwise just
   * updates the last action time, to make sure the timer won't go
   * idle
   */
  const tickTimer = (): void => {
    if (timerStatus.value === TimerStatus.STARTED) {
      setLastActionTime()
      return
    }

    clearTimer()
    setTimerInterval()
    setLastActionTime()
    timerStatus.value = TimerStatus.STARTED

    // Keeps track of the time the last action was performed
    lastActionTimeInSeconds.value = timeInSeconds.value

    // The user just took action so the timer it's not more idle
    isIdlePause.value = false
  }

  /**
   * Sets the time in seconds
   */
  const setTimeInSeconds = (value: number): void => {
    timeInSeconds.value = value
  }

  /**
   * Reset the timer to the initial state
   */
  const resetTimer = (): void => {
    clearTimer()
    timeInSeconds.value = 0
    timerStatus.value = TimerStatus.STOPPED
  }

  return {
    timeInSeconds,
    timerStatus,
    isIdlePause,
    lastActionTimeInSeconds,
    tickTimer,
    pauseTimer,
    clearTimer,
    setTimeInSeconds,
    resetTimer,
  }
})
