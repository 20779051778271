import type { FrameData } from '@/core/annotations'
import type { AnnotationData, InstanceID } from '@/modules/Editor/AnnotationData'

export const instanceIdSerializer = {
  serialize(data: InstanceID): FrameData {
    // This function never seems to be called. Saving an instance id bypasses the serializer.
    return { instance_id: { value: data.value } }
  },

  deserialize(rawData: FrameData): InstanceID | null {
    if (!rawData.instance_id) {
      return null
    }
    return { value: rawData.instance_id.value }
  },

  // In V1 nothing is passed as instance id is set by server,
  // while in V2 we need to fetch and set it beforehand due to optimistic UI
  defaultData(data: { instance_id?: number } | undefined): AnnotationData {
    const value = data?.instance_id ? data.instance_id : 0
    return { value: value }
  },
}
