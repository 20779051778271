import type { Skeleton } from '@/modules/Editor/AnnotationData'
import type { EditablePoint } from '@/modules/Editor/point'

export const getEdgesAsPaths = (
  nodes: Skeleton['nodes'],
  edges: { from: string; to: string }[],
): EditablePoint[][] => {
  const paths = []
  for (const edge of edges) {
    const fromNode = nodes.find((node) => node.name === edge.from)
    const toNode = nodes.find((node) => node.name === edge.to)
    if (!fromNode || !toNode) {
      continue
    }
    paths.push([fromNode.point, toNode.point])
  }
  return paths
}
