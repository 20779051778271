import { euclideanDistance } from '@/modules/Editor/algebra'
import type { EditablePoint, IPoint } from '@/modules/Editor/point'
import { anchorCursor } from '@/modules/Editor/point'
import type { Editor } from '@/modules/Editor/editor'
import { inferCurrentAnnotationData } from '@/modules/Editor/inferCurrentAnnotationData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { POLYGON_ANNOTATION_TYPE } from '@/modules/Editor/plugins/polygon/types'

const getClosestPointOnPath = (
  paths: EditablePoint[][],
  editor: Editor,
  annotation: Annotation,
  annotationCursor: IPoint,
  maxDistance: number,
): [number, IPoint, Annotation, number, EditablePoint[]] | null => {
  let distanceToEdge: Array<[number, IPoint, Annotation, number, EditablePoint[]]> = []
  for (const path of paths) {
    for (let i = 0; i < path.length; i++) {
      const point = path[i]
      const nextPoint = i === path.length - 1 ? path[0] : path[i + 1]
      const pointOnLine = anchorCursor(annotationCursor, point, nextPoint)
      const distanceFromCusor = euclideanDistance(
        editor.activeView.camera.imageViewToCanvasView(annotationCursor),
        editor.activeView.camera.imageViewToCanvasView(pointOnLine),
      )

      if (distanceFromCusor > maxDistance) {
        continue
      }

      // calculate if the point is between point and nextPoint.
      const AB = euclideanDistance(point, nextPoint)
      const AC = euclideanDistance(point, pointOnLine)
      const BC = euclideanDistance(nextPoint, pointOnLine)
      if (AB < AC + BC - 0.001) {
        continue
      }

      distanceToEdge.push([distanceFromCusor, pointOnLine, annotation, i + 1, path])
    }
  }

  distanceToEdge = distanceToEdge.sort(([d1], [d2]) => d1 - d2)
  if (distanceToEdge[0]) {
    return distanceToEdge[0]
  }

  return null
}

/**
 * Returns the closest point on the edge of the polygon (annotation with polygon type).
 */
export const getPolygonEdge = async (
  editor: Editor,
  annotation: Annotation,
  currentPoint: IPoint,
  maxDistance: number,
): Promise<[IPoint, Annotation, number, EditablePoint[]] | null> => {
  const annotationCursor = editor.activeView.camera.canvasViewToImageView(currentPoint)
  const polygon = inferCurrentAnnotationData(annotation, editor.activeView.currentFrameIndex)

  const targetedItemStroke = await editor.activeView.annotationsLayer.hitItemStroke(
    annotationCursor,
    [{ id: annotation.id }],
  )
  if (targetedItemStroke !== annotation.id) {
    return null
  }

  // we need to consider all edges, both in path and in additional paths
  const paths = [polygon.path || [], ...(polygon.additionalPaths || [])]

  const res = getClosestPointOnPath(paths, editor, annotation, annotationCursor, maxDistance)

  if (!res) {
    return null
  }

  return [res[1], res[2], res[3], res[4]]
}

/**
 * Loops through all visible polygons, finding the closest edge for the currentPoint
 * @returns [closet point on the edge, the polygon, the distance] or null
 */
export const findClosestPolygonEdge = (
  editor: Editor,
  currentPoint: IPoint,
  maxDistance: number,
): [IPoint, Annotation, number, EditablePoint[]] | null => {
  const annotationCursor = editor.activeView.camera.canvasViewToImageView(currentPoint)
  let distanceToEdge: Array<[number, IPoint, Annotation, number, EditablePoint[]]> = []

  const polygonAnnotations = editor.activeView.annotationManager.visibleAnnotations.filter(
    ({ type }) => type === POLYGON_ANNOTATION_TYPE,
  )

  for (const annotation of polygonAnnotations) {
    const polygon = inferCurrentAnnotationData(annotation, editor.activeView.currentFrameIndex)

    // we need to consider all edges, both in path and in additional paths
    const paths = [polygon.path || [], ...(polygon.additionalPaths || [])]
    const res = getClosestPointOnPath(paths, editor, annotation, annotationCursor, maxDistance)

    if (!res) {
      continue
    }

    distanceToEdge.push(res)
  }

  distanceToEdge = distanceToEdge.sort(([d1], [d2]) => d1 - d2)
  if (distanceToEdge.length > 0) {
    return [distanceToEdge[0][1], distanceToEdge[0][2], distanceToEdge[0][3], distanceToEdge[0][4]]
  }
  return null
}
