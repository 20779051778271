/* globals BlobPart */
import { filesize } from 'filesize'

export const readFileAsData = (file: File): Promise<string> => {
  const reader = new FileReader()
  return new Promise<string>((resolve) => {
    reader.onload = (): void => {
      resolve(reader.result as string)
    }
    reader.readAsDataURL(file)
  })
}

export const readFileAsBinary = (file: File): Promise<string> => {
  const reader = new FileReader()
  return new Promise<string>((resolve) => {
    reader.onload = (): void => {
      resolve(reader.result as string)
    }
    reader.readAsBinaryString(file)
  })
}

/**
 * Returns File object from remote file
 */
export const getFileFromUrl = async (filename: string, url: string): Promise<File> => {
  const response = await fetch(url)
  const blob = await response.blob()
  return new File([blob], filename, { type: blob.type })
}

/** Triggers download of a file the contents of which have been received through an API request */
export const downloadFile = (data: BlobPart, filename: string, contentType: string): void => {
  const blob = new Blob([data], { type: contentType })
  const link = document.createElement('a')

  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  document.body.appendChild(link)

  link.click()
  document.body.removeChild(link)
}

export const formatFileSize = (bytes: number): string => filesize(bytes, { round: 1 })

export const getFileExtension = (fileName: string): string => fileName.split('.').pop() || ''

/**
 * When working with video segments, the file UUID is not enough to be unique,
 * because you can have multiple segments for the same file.
 * The method combines the file UUID with the segment number to create a unique ID for the segment.
 */
export const extractSegmentIndexFromURL = (segmentURL: string): string => {
  /**
   * Example segment URL: .../teams/.../name.mp4/segments/high/000000007.ts?...
   * Example segment URL: .../teams/.../name.mp4/segments/low/000000007.ts?...
   * Example segment URL: .../teams/.../name.mp4/segments/000000007.ts?... (legacy format)
   */
  const segmentRegex = /segments(?:\/\w*)?\/(\d+)\.ts/
  const segmentMatch = segmentURL.match(segmentRegex)
  if (!segmentMatch) {
    throw new Error(`Could not extract segment number from segment URL: ${segmentURL}`)
  }

  return segmentMatch[1]
}
