import type { IPoint } from '@/modules/Editor/point'
import { addPoints, subPoints } from '@/modules/Editor/point'
import { isVideoAnnotation } from '@/modules/Editor/models/annotation/annotationKindValidator'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import { calcCentroidPoint } from '@/modules/Editor/utils'
import type { View } from '@/modules/Editor/views/view'

export const focusOnSelectedAnnotation = (view: View): void => {
  const { selectedAnnotation } = view.annotationManager

  if (!selectedAnnotation) {
    return
  }

  let centroid = selectedAnnotation.centroid
  if (!centroid) {
    let canvasCentroid: IPoint | undefined

    if (selectedAnnotation.type === 'tag' || selectedAnnotation.type === 'mask') {
      return
    }

    if (isVideoAnnotation(selectedAnnotation)) {
      const { data: annotationData } = inferVideoData(selectedAnnotation, view.currentFrameIndex)

      if (!annotationData || Object.keys(annotationData).length === 0) {
        return
      }
      canvasCentroid = calcCentroidPoint(
        view.camera.scale,
        view.camera,
        view.currentFrameIndex,
        shallowCloneAnnotation(selectedAnnotation, { data: annotationData }),
      )
    } else {
      canvasCentroid = calcCentroidPoint(
        view.camera.scale,
        view.camera,
        view.currentFrameIndex,
        selectedAnnotation,
      )
    }

    if (!canvasCentroid) {
      return
    }

    centroid = view.camera.canvasViewToImageView(canvasCentroid)
  }

  if (!centroid) {
    return
  }

  const topLeft = subPoints(centroid, {
    x: view.camera.width / 2 / view.cameraScale,
    y: view.camera.height / 2 / view.cameraScale,
  })

  const bottomRight = addPoints(centroid, {
    x: view.camera.width / 2 / view.cameraScale,
    y: view.camera.height / 2 / view.cameraScale,
  })

  view.zoomTo(topLeft, bottomRight)
}
