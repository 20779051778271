import type { SimpleTable } from '@/modules/Editor/AnnotationData'
import type { CompoundPath } from '@/modules/Editor/compoundPath'
import type { IPoint } from '@/modules/Editor/point'
import { createEditablePoint, type EditablePoint } from '@/modules/Editor/point'

import { moveVertexOnBoundingBox, translateBoundingBox } from './boundingBox'

export const isSimpleTable = (data: SimpleTable | object): data is SimpleTable =>
  'boundingBox' in data && 'rowOffsets' in data && 'colOffsets' in data

export type Row = { left: EditablePoint; right: EditablePoint }
export type Column = { top: EditablePoint; bottom: EditablePoint }

export const getPathForSimpleTable = (data: SimpleTable | object): EditablePoint[] =>
  isSimpleTable(data)
    ? [
        data.boundingBox.topLeft,
        data.boundingBox.topRight,
        data.boundingBox.bottomRight,
        data.boundingBox.bottomLeft,
      ]
    : []

export const getRowLines = (data: SimpleTable | object): Row[] => {
  if (!isSimpleTable(data)) {
    return []
  }

  const left = data.boundingBox.topLeft.x
  const right = data.boundingBox.topRight.x
  const top = data.boundingBox.topLeft.y
  const height = data.boundingBox.bottomLeft.y - top

  return data.rowOffsets.map((offset: number) => ({
    left: createEditablePoint({ x: left, y: top + height * offset }),
    right: createEditablePoint({ x: right, y: top + height * offset }),
  }))
}

export const getColumnLines = (data: SimpleTable | object): Column[] => {
  if (!isSimpleTable(data)) {
    return []
  }

  const left = data.boundingBox.topLeft.x
  const top = data.boundingBox.topLeft.y
  const bottom = data.boundingBox.bottomLeft.y
  const width = data.boundingBox.topRight.x - left

  return data.colOffsets.map((offset: number) => ({
    top: createEditablePoint({
      x: left + width * offset,
      y: top,
    }),
    bottom: createEditablePoint({
      x: left + width * offset,
      y: bottom,
    }),
  }))
}

export const getAllVerticesFromSimpleTable = (data: SimpleTable | object): EditablePoint[] =>
  getPathForSimpleTable(data)

export const getCompoundPathFromSimpleTable = (data: SimpleTable | object): CompoundPath => ({
  path: getPathForSimpleTable(data),
  additionalPaths: [],
})

export const translateSimpleTable = (data: SimpleTable | object, offset: IPoint): void => {
  if (!isSimpleTable(data)) {
    return
  }
  translateBoundingBox(data.boundingBox, offset)
}

export const moveVertexOnSimpleTable = (
  data: SimpleTable | object,
  movingVertex: EditablePoint,
  offset: IPoint,
): void => {
  if (!isSimpleTable(data)) {
    return
  }
  moveVertexOnBoundingBox(data.boundingBox, movingVertex, offset)
}
