/**
 * Browser and System related functions
 */
export const onMacOS = (): boolean => {
  const platform = window.navigator.platform
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  if (macosPlatforms.indexOf(platform) !== -1) {
    return true
  }
  return false
}

export const isSafariBrowser = (): boolean =>
  navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1

/** WASM supports multithreading by making use of SharedArrayBuffer */
export const checkBrowserSupportsMultithreading = (): boolean =>
  typeof SharedArrayBuffer === 'function'

export const isMobileOrTablet = (): boolean => {
  const userAgents = ['Android', 'iPad', 'iPhone']
  return userAgents.some((userAgent) => navigator.userAgent.includes(userAgent))
}

export const tabletCompensation = (): number => (isMobileOrTablet() ? 1.6 : 1)
