import { euclideanDistance } from '@/modules/Editor/algebra'
import type { IPoint } from '@/modules/Editor/point'
import type { Rectangle } from '@/modules/Editor/rectangle'
import type { Editor } from '@/modules/Editor/editor'
import type { CornerInfo } from '@/modules/Editor/plugins/click/types'

export const findEditableCorner = (
  editor: Editor,
  currentCrop: Rectangle,
  point: IPoint,
): CornerInfo | undefined => {
  const corners: CornerInfo[] = [
    { corner: currentCrop.topLeftEditable, position: 'top-left' },
    { corner: currentCrop.topRightEditable, position: 'top-right' },
    { corner: currentCrop.bottomRightEditable, position: 'bottom-right' },
    { corner: currentCrop.bottomLeftEditable, position: 'bottom-left' },
  ]

  for (const { corner, position } of corners) {
    const canvasPoint = editor.activeView.camera.imageViewToCanvasView(corner)
    if (euclideanDistance(point, canvasPoint) < 5.5) {
      return { corner, position }
    }
  }
}
