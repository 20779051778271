import { PersistedSettings } from '@/core/utils/PersistedSettings'
import {
  ANNOTATION_ITEM_DEFAULT_DURATION,
  HARDWARE_CONCURRENCY,
  NEIGHBOUR_TILES,
} from '@/utils/localStorageKeys'

import { SET_AUTO_ANNOTATE_CLASS_MAPPING } from './mutations/SET_AUTO_ANNOTATE_CLASS_MAPPING'
import { SET_ERROR } from './mutations/SET_ERROR'
import type { WorkflowMutation, WorkviewState } from './types'

export type { WorkviewState }

import { VIDEO_ANNOTATION_DURATION_DEFAULT } from './constants'
// we will move it to workview

const persistedSettings = new PersistedSettings()

export const getInitialState = (): WorkviewState => ({
  clickerEpsilon: 0.5,
  currentTool: null,
  autoAnnotateModels: [],
  classMapping: JSON.parse(localStorage.getItem('class-mapping') || '{}'),
  preselectedAnnotationClassId: null,
  preselectedModelId: null,

  videoAnnotationDuration: persistedSettings.loadValue(
    ANNOTATION_ITEM_DEFAULT_DURATION,
    VIDEO_ANNOTATION_DURATION_DEFAULT,
  ),
  neighbourTiles: persistedSettings.loadValue(NEIGHBOUR_TILES, false),
  hardwareConcurrency: persistedSettings.loadValue(
    HARDWARE_CONCURRENCY,
    navigator.hardwareConcurrency - 1 || 2,
  ),
  error: null,
})

const state: WorkviewState = getInitialState()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mutations: { [k: string]: WorkflowMutation<any> } = {
  // tools

  /**
   * @param payload: { preselectedClassId: number, skipAutoSelectTool: boolean }
   *
   * NOTE: skipAutoSelectTool - each PRESELECT_CLASS_ID call triggers
   * the toolManager.autoActivateTool from the useLinkTooling, we don't need it sometimes
   * since it reselect the tool.
   */
  // NOTE: In use by V1 only
  PRESELECT_CLASS_ID(state, payload) {
    if (!payload) {
      return
    }
    const { preselectedClassId } = payload

    state.preselectedAnnotationClassId = preselectedClassId
  },

  SET_CURRENT_TOOL_PRESELECTED_MODEL_ID(state, preselectedModelId = null) {
    state.preselectedModelId = preselectedModelId
  },

  // annotation selection/visibility/highlight

  RESET_ALL(state) {
    state.autoAnnotateModels = []
    state.classMapping = {}
    state.preselectedAnnotationClassId = null
    state.preselectedModelId = null
    state.currentTool = null
    state.error = null
    state.videoAnnotationDuration = VIDEO_ANNOTATION_DURATION_DEFAULT
    state.neighbourTiles = false
    state.hardwareConcurrency = navigator.hardwareConcurrency - 1 || 2
  },

  SET_ERROR,

  SET_CLICKER_EPSILON(state, epsilon: number) {
    state.clickerEpsilon = epsilon
  },

  SET_VIDEO_ANNOTATION_DURATION(state, duration: number) {
    persistedSettings.saveValue(ANNOTATION_ITEM_DEFAULT_DURATION, duration)
    state.videoAnnotationDuration = duration
  },

  SET_HARDWARE_CONCURRENCY(state, hardwareConcurrency: number) {
    persistedSettings.saveValue(HARDWARE_CONCURRENCY, hardwareConcurrency)
    state.hardwareConcurrency = hardwareConcurrency
  },

  SET_NEIGHBOUR_TILES(state, neighourTiles: boolean) {
    persistedSettings.saveValue(NEIGHBOUR_TILES, neighourTiles)
    state.neighbourTiles = neighourTiles
  },
  SET_AUTO_ANNOTATE_CLASS_MAPPING,
}

export default { namespaced: true, state, mutations }
