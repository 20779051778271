<template>
  <component
    :is="!!to ? 'router-link' : 'button'"
    class="icon-button"
    :class="{
      [`icon-button--flair-${flair}`]: true,
      [`icon-button--kind-${kind}`]: true,
      [`icon-button--size-${size}`]: true,
      'icon-button--full-with': fullWidth,
    }"
    role="button"
    :name="name"
    :disabled="disabled"
    :to="to"
    :type="typeAttribute"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span class="icon-button__slot">
      <slot />
    </span>
  </component>
</template>

<script lang="ts">
export default {
  name: 'IconButton',
}
</script>

<script setup lang="ts">
import { buttonProps } from '@/uiKit/Button/props'
import { computed } from 'vue'

const props = // eslint-disable-next-line vue/define-props-declaration
  defineProps({
    ...buttonProps,
    fullWidth: { default: false, type: Boolean },
  })

const tag = computed(() => (props.to ? 'router-link' : 'button'))
/**
 * The `type` property is used to set the type attribute on the button element. Make sure
 * that we don't pass it to the router-link component by setting it as undefined.
 */
const typeAttribute = computed(() => (tag.value === 'button' ? props.type : undefined))
</script>

<style lang="scss" scoped>
@import '@/uiKit/assets/borders.scss';
@import '@/uiKit/assets/buttons.scss';
@import '@/uiKit/assets/colors.global.scss';
@import '@/uiKit/assets/transitions.scss';
@import '@/uiKit/assets/typography.scss';

.icon-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: auto;
  aspect-ratio: 1 / 1;
  padding: 0;
  gap: 8px;
  transition: background-color $transitionDefault;

  @include buttonProperties;

  &--size {
    &-icon {
      @include typographyRegularBody200;
      height: 20px;
    }

    &-xs {
      @include typographyRegularBody200;
      height: 24px;
    }

    &-sm {
      @include typographyRegularBody200;
      height: 32px;
    }

    &-md {
      @include typographyRegularBody300;
      height: 36px;
    }

    &-lg {
      @include typographyRegularBody500;
      height: 40px;
    }
  }

  &__slot {
    @include row--center;
    align-items: center;
  }
}
</style>
