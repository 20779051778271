import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { PartialRecord } from '@/core/helperTypes'

export const useClassSelectionStore = defineStore('classSelection', () => {
  const preselectedAnnotationClassId = ref<number | null>(null)
  const preselectedClassIdPerTool = ref<PartialRecord<string, number>>({})

  const setPreselectedAnnotationClassId = (id: number | null): void => {
    preselectedAnnotationClassId.value = id
  }

  /**
   * Stores the given class id as the last selected class id
   * for the given tool, so that, when we select that tool again
   * in the future, this class id will be automatically selected with it.
   *
   * This will get called when we, for example, select a class from
   * the class selection UI, while a tool is already selected.
   */
  const setClassIdForTool = (tool: string, classId: number): void => {
    preselectedClassIdPerTool.value = {
      ...preselectedClassIdPerTool.value,
      [tool]: classId,
    }
  }

  const cleanup = (): void => {
    preselectedAnnotationClassId.value = null
    preselectedClassIdPerTool.value = {}
  }

  return {
    preselectedAnnotationClassId,
    preselectedClassIdPerTool,
    setPreselectedAnnotationClassId,
    setClassIdForTool,
    cleanup,
  }
})
