import throttle from 'lodash/throttle'
import type { DebouncedFunc } from 'lodash'

import { WorkviewTrackerEvents } from '@/modules/Editor/eventBus'

const THROTTLE_REPORT_ACTIVITY_MS = 1000

export const throttleReportActivity = (): DebouncedFunc<() => void> =>
  throttle(
    () => {
      WorkviewTrackerEvents.reportActivity.emit()
    },
    THROTTLE_REPORT_ACTIVITY_MS,
    { leading: true, trailing: false },
  )
