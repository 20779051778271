import type { FrameData } from '@/core/annotations'
import type { Ellipse } from '@/modules/Editor/AnnotationData'
import { euclideanDistance } from '@/modules/Editor/algebra'
import {
  createEditablePoint,
  addPoints,
  subPoints,
  type EditablePoint,
} from '@/modules/Editor/point'

export const ellipseSerializer = {
  serialize(data: Ellipse): FrameData {
    const center: EditablePoint = data.center
    const right: EditablePoint = data.right
    const top: EditablePoint = data.top
    return {
      ellipse: {
        center: { x: center.x, y: center.y },
        radius: { x: euclideanDistance(center, right), y: euclideanDistance(center, top) },
        angle: Math.atan2(right.y - center.y, right.x - center.x),
      },
    }
  },

  deserialize(rawData: FrameData): Ellipse | null {
    if (!rawData.ellipse) {
      return null
    }

    const { center, radius, angle } = rawData.ellipse
    const right = createEditablePoint({
      x: center.x + radius.x * Math.cos(angle),
      y: center.y + radius.x * Math.sin(angle),
    })
    const top = createEditablePoint({
      x: center.x + radius.y * Math.cos(angle - Math.PI / 2),
      y: center.y + radius.y * Math.sin(angle - Math.PI / 2),
    })
    const centerPoint = createEditablePoint(center)
    const left = createEditablePoint(addPoints(centerPoint, subPoints(centerPoint, right)))
    const bottom = createEditablePoint(addPoints(centerPoint, subPoints(centerPoint, top)))
    return {
      center: centerPoint,
      right,
      top,
      left,
      bottom,
    }
  },
}
