<template>
  <ButtonTemplate
    v-bind="$attrs"
    class="secondary-button"
    v-on="$listeners"
  >
    <slot>LABEL MISSING!</slot>
  </ButtonTemplate>
</template>

<script lang="ts">
export default { name: 'SecondaryButton' }
</script>

<script lang="ts" setup>
import ButtonTemplate from './ButtonTemplate.vue'
</script>

<style lang="scss" scoped>
.secondary-button {
  @include row--center;
  @include typographyBoldBody200;
  border-radius: 999px;

  color: var(--color-text-subtle);
  background-color: var(--color-background-gray-subtlest);

  &:hover {
    background-color: var(--color-background-gray-subtlest-hovered);
  }

  &:active {
    background-color: var(--color-background-gray-subtlest-pressed);
  }
}
</style>
