import type { MainAnnotationType, SubAnnotationType } from '@/core/annotationTypes'

import type { ToolName, SubToolName } from './types'

/**
 * Provides a definition of how main annotation types map to relevant tools,
 * for main annotation types that actually have tools.
 *
 * If something maps to null, it means it doesn't have a tool associated
 */
export const TOOL_FOR_MAIN_TYPE: Record<MainAnnotationType, ToolName | null> = {
  bounding_box: 'bounding_box_tool',
  ellipse: 'ellipse_tool',
  eye: 'eye_tool',
  line: 'polyline_tool',
  polygon: 'polygon_tool',
  keypoint: 'keypoint_tool',
  skeleton: 'skeleton_tool',
  simple_table: 'simple_table_tool',
  raster_layer: null,
  mask: null,
  tag: null,
}

/**
 * Provides a definition of how sub annotation types map to relevant tools,
 * for sub annotation types that have tools.
 *
 * If something maps to null, it means it doesn't have tool associated
 */
export const TOOL_FOR_SUBTYPE: Record<SubAnnotationType, SubToolName | null> = {
  attributes: 'attributes_tool',
  directional_vector: 'directional_vector_tool',
  auto_annotate: 'auto_annotate_tool',
  text: 'text_tool',
  instance_id: 'instance_id_tool',
  inference: null,
  measures: null,
} as const
