import type { IPoint } from '@/modules/Editor/point'
import type { ViewInfo } from '@/modules/Editor/utils/referenceLines/types'
import type { DicomView } from '@/modules/Editor/views/dicomView'
import { getSlotNamesPerPlane } from '@/modules/Editor/utils/radiology/getSlotNamesPerPlane'

function getImageCenterFromViewInfo(viewInfo: ViewInfo, scalingFactor: number): number {
  // Move to center of frame, normalize and multiply by the scalingFactor.
  return ((viewInfo.currentFrameIndex + 0.5) / viewInfo.totalFrames) * scalingFactor
}

/**
 * Finds the center in image space of the crosshairs made by the reference lines
 * of the two orthogonal views.
 *
 * Note: This is used in order to render the crosshairs with
 * a gap in center.
 *
 * @param view The target DicomView
 * @param viewInfo Required data about the views in the layout
 * @returns The image point of the center of the crosshairs.
 */
export function getImageCenter(view: DicomView, viewInfo: ViewInfo[]): IPoint | undefined {
  const { metadata: viewMetadata, slot_name: viewSlotName } = view.fileManager.file
  const slotNames = getSlotNamesPerPlane(view.fileManager.metadata?.medical)

  if (viewMetadata === undefined) {
    // Return silently
    return
  }

  const { width, height } = viewMetadata
  const axialView = viewInfo.find((vi) => vi.slotName === slotNames.AXIAL)
  const coronalView = viewInfo.find((vi) => vi.slotName === slotNames.CORONAL)
  const sagittalView = viewInfo.find((vi) => vi.slotName === slotNames.SAGITTAL)

  if (!axialView || !sagittalView || !coronalView) {
    // This means the view is not yet initailized
    return
  }

  let centerX = 0
  let centerY = 0

  if (viewSlotName === slotNames.AXIAL) {
    // -> vertical line at X = Sagittal view index.
    // -> horizontal line at Y = Coronal view index.

    centerX = getImageCenterFromViewInfo(sagittalView, width)
    centerY = getImageCenterFromViewInfo(coronalView, height)
  } else if (viewSlotName === slotNames.CORONAL) {
    // -> vertical line at X = Sagittal view index.
    // -> horizontal line at Y = Axial view index.

    centerX = getImageCenterFromViewInfo(sagittalView, width)
    centerY = getImageCenterFromViewInfo(axialView, height)
  } else if (viewSlotName === slotNames.SAGITTAL) {
    // -> vertical line at X = Coronal view index.
    // -> horizontal line at Y = Axial view index.

    centerX = getImageCenterFromViewInfo(coronalView, width)
    centerY = getImageCenterFromViewInfo(axialView, height)
  }

  return { x: centerX, y: centerY }
}
