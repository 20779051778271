// eslint-disable-next-line no-restricted-syntax
import MediaInfoFactory, { type MediaInfo, type ReadChunkFunc } from 'mediainfo.js'
import type { VideoTrack } from 'mediainfo.js/dist/MediaInfoResult'

export const extractVideoFPS = async (
  mi: MediaInfo<'object'>,
  file: File,
): Promise<number | undefined> => {
  const getSize = (): number => file.size

  const readChunk: ReadChunkFunc = (chunkSize, offset) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (event: ProgressEvent<FileReader>): void => {
        if (event.target?.error) {
          reject(event.target.error)
        }

        resolve(new Uint8Array(event.target?.result as ArrayBuffer))
      }

      // load data in chunks to prevent memory exhaustion
      reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
    })

  const metadata = await mi.analyzeData(getSize, readChunk)
  const fps = metadata.media?.track.find((t): t is VideoTrack => t['@type'] === 'Video')?.FrameRate
  return fps
}

export const prefetchMediaInfo = (): void => {
  MediaInfoFactory({
    format: 'object',
    locateFile: () => '/MediaInfoModule.wasm',
  }).then((mi) => mi.close())
}
