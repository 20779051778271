import { buildRegularMaskPath } from '@/modules/Editor/plugins/brush/utils/buildRegularMaskPath'

/**
 * Builds a sphere (3D) mask path.
 * The sphere will be composed by as many slices as the diameter of the sphere.
 * To reduce complexity, the sphere is composed by generating only one side and (since it's
 * symmetrical) mirroring it.
 *
 * @param {number} widthInPixels - The width of the mask in pixels.
 * @returns {Uint8ClampedArray[]} - An array of Uint8ClampedArrays representing the
 * pixel mask in 3D.
 */
export const buildRegularMaskPathIn3D = (widthInPixels: number): Uint8ClampedArray[] => {
  const pixelMaskIn3D: Uint8ClampedArray[] = []
  const width = Math.round(widthInPixels)
  const halfWidth = widthInPixels / 2

  // Cicle through half ot the width, from the center to the right edge
  for (let offset = 0; offset < halfWidth; offset++) {
    let sliceDiameter = width

    if (offset === 0 && width % 2 > 0) {
      // When size is odd, we need to add an initial slice for the centre
      pixelMaskIn3D.push(buildRegularMaskPath(sliceDiameter, widthInPixels).pixelMask)
      continue
    }

    if (offset !== 0) {
      // Circle equation: x^2 + y^2 = r^2
      // y = sqrt(r^2 - x^2)
      const sliceRadius = halfWidth
      sliceDiameter = Math.sqrt(sliceRadius ** 2 - offset ** 2) * 2
      // Make sure the diameter is 1 or more
      sliceDiameter = Math.max(1, sliceDiameter)
    }

    const pixelMask = buildRegularMaskPath(sliceDiameter, widthInPixels).pixelMask

    // Add same slice to each side
    pixelMaskIn3D.push(pixelMask)
    pixelMaskIn3D.unshift(pixelMask)
  }

  return pixelMaskIn3D
}
