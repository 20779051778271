import axios from 'axios'

import type { ValidationErrors, ValidationError } from '@/store/types'

import type { errorsByCode } from './errors'

export type ParametricMessage = (params: { [s: string]: string | number }) => string

export type ErrorMessageGroup = {
  [k: number]: string
  default: string
  parametric?: ParametricMessage
}

export type BackendError = {
  code?: keyof typeof errorsByCode
  message?: string
  detail?: object
}

/**
 * Typeguard to be used when catching an API error, in order to parse it as
 * into a user-friendly error. We need this because a caught error is otherwise
 * implicitly typed as `unknown`.
 *
 * Since ErrorResponse is quite openly defined, i.e. it might, but also might
 * not have one or more of 3 possible fields, we don't really check for anything
 * other than the value being an object.
 *
 * As we define out our api request layer a bit better, we might be able to do more.
 */
export const isErrorResponse = (e: unknown): e is ErrorResponse =>
  // cancel error will be ignored
  // NB: while on development, on browser network you will still see status 200 but
  // the methods `axios.isCancel(err)` reveals it's cancelled
  e instanceof Object && !axios.isCancel(e)

export type ErrorResponse<T = BackendError | ValidationErrors> = {
  message?: string
  name?: string
  code?: string
  response?: {
    data: {
      errors: T
    }
    status: number
  }
}

export type ErrorWithMessage = {
  /**
   * Friendly error string often (but not always) provided by backend.
   * In a lot of cases, can be shown to the user, but sometimes, we will require
   * custom messages generated by frontend.
   */
  backendMessage: string | null
  /**
   * String based status code for the message.
   *
   * Used by backend to uniquely identify the message.
   * Used by frontend to provide a frontend-based error message.
   */
  code: string | null
  /**
   * Any extra information given by backend, which could be used to further
   * inform the user.
   */
  detail: object | null
  /**
   * Frontend-generated error message, based primarily on `code`, but could be
   * based on other things.
   */
  message: string
  /**
   * HTTP Status code sent by backend, if applicable
   */
  status: number | null
}

export type ParsedValidationError = {
  [s: string]: string | string[] | ValidationError | boolean
  isValidationError: boolean
}

export type ParsedError<T = ErrorWithMessage | ParsedValidationError> = { error: T }
export type ParsedErrorWithMessage = { error: ErrorWithMessage }
