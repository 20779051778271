import { PixelMatrixValues } from './PixelMatrixValues'
import { generateCircularPixelPathBuilder } from './generateCircularPixelPathBuilder'
import { getPixelOutlines } from './getPixelOutlines'
import { translateMaskPath } from './translateMaskPath'

type TipPathAndPixelMask = {
  tipPath: number[][]
  pixelMask: Uint8ClampedArray
}

const { EXTERIOR, INTERIOR, EDGE, CONSUMED_EDGE } = PixelMatrixValues

/**
 * For a pixel matrix containing edge values, finds the position
 * of the first EDGE_PIXEL
 */
const getFirstPointOfCircle = (pixelMask: Uint8ClampedArray, width: number): [number, number] => {
  for (let y = 0; y < width; y++) {
    for (let x = 0; x < width; x++) {
      if (pixelMask[y * width + x] === EDGE) {
        return [x, y]
      }
    }
  }

  throw new Error('Edge not found')
}

/**
 * Returns a pixelMaks and tipPath with the following shape:
 *
 * 1
 */
const specialCaseWidth1 = (): TipPathAndPixelMask => {
  const width = 1
  const pixelMask = new Uint8ClampedArray(width)

  pixelMask[0] = INTERIOR

  const tipPath = [
    [-0.5, -0.5],
    [0.5, -0.5],
    [0.5, 0.5],
    [-0.5, 0.5],
  ]

  return {
    pixelMask,
    tipPath,
  }
}

/**
 * Returns a pixelMaks and tipPath with the following shape:
 *
 * 0 1 0
 * 1 1 1
 * 0 1 0
 */
const specialCaseWidth3 = (): TipPathAndPixelMask => {
  const width = 3
  const pixelMask = new Uint8ClampedArray(width * width)

  pixelMask[1] = INTERIOR
  pixelMask[3] = INTERIOR
  pixelMask[4] = INTERIOR
  pixelMask[5] = INTERIOR
  pixelMask[7] = INTERIOR

  const circularPath = [
    [-0.5, -1.5],
    [0.5, -1.5],
    [0.5, -0.5],
    [1.5, -0.5],
    [1.5, 0.5],
    [0.5, 0.5],
    [0.5, 1.5],
    [-0.5, 1.5],
    [-0.5, 0.5],
    [-1.5, 0.5],
    [-1.5, -0.5],
    [-0.5, -0.5],
  ]

  return {
    pixelMask,
    tipPath: circularPath,
  }
}

const paddedTipPathAndPixelMask = (
  tipPathAndPixelMask: TipPathAndPixelMask,
  padding: number,
): TipPathAndPixelMask => {
  const originalPixelMask = tipPathAndPixelMask.pixelMask
  const size = Math.sqrt(originalPixelMask.length)
  const paddedSize = size + padding * 2
  const pixelMask = new Uint8ClampedArray(paddedSize * paddedSize)

  for (let i = 0; i < size; i++) {
    for (let j = 0; j < size; j++) {
      const originalIndex = i * size + j
      // For float padding, we need to round the index, using floor and ceil we push the rounding
      // to the next top right pixel. We can change that if we rather have it pushed to the
      // left or the bottom (see unit tests for better understanding).
      const paddedIndex = (i + Math.floor(padding)) * paddedSize + (j + Math.ceil(padding))

      pixelMask[paddedIndex] = originalPixelMask[originalIndex]
    }
  }

  return {
    pixelMask,
    tipPath: tipPathAndPixelMask.tipPath,
  }
}

/**
 * Generates both a pixelMask to use as a brush overlay for mask brush painting,
 * and an outline path to render as the brush tip.
 * `paddedWidthInPixel` is the width of the mask path with padding in pixels (optional).
 * This is expected to be larger (or equal) than `widthInPixels` and is used to generate 3D masks
 * path for spheres.
 *
 * Note: This is a rather expensive method, but allows us to the do pixel brush
 * with the current CPU rendering. This can be massively accelerated on the GPU layer later.
 * However this is only called when we change the brush size, so the overhead is not that big.
 *
 * @param {number} widthInPixels - The width of the mask path in pixels.
 * @param {number} [paddedWidthInPixel] - Padded width of the mask path in pixels (optional).
 * @returns {TipPathAndPixelMask} - The generated tip path and pixel mask.
 */

export const buildRegularMaskPath = (
  widthInPixels: number,
  paddedWidthInPixel?: number,
): TipPathAndPixelMask => {
  // Note: pixel [x,x] is a rect of [x, y, w = 1, h = 1]

  const width = Math.floor(widthInPixels)
  const totalWidth = paddedWidthInPixel ? Math.floor(paddedWidthInPixel) : width
  const padding = (totalWidth - width) / 2

  // Deal with special cases 1 and 3 where we want a certain shape
  // (would need to add loads more rules that are wasted on every other size)
  if (width === 1) {
    return paddedTipPathAndPixelMask(specialCaseWidth1(), padding)
  }

  if (width === 3) {
    return paddedTipPathAndPixelMask(specialCaseWidth3(), padding)
  }

  const radius = width / 2
  const squaredRadius = radius * radius

  const pixelMask = new Uint8ClampedArray(totalWidth * totalWidth)

  // Note this works for both even and odd width.
  // Odd will pick the central pixel of the matrix,
  // Even will take the corner at the center of the matrix.
  const center = (totalWidth - 1) / 2

  // Generate a pixel mask
  for (let y = 0; y < totalWidth; y++) {
    for (let x = 0; x < totalWidth; x++) {
      const inside = (x - center) * (x - center) + (y - center) * (y - center) < squaredRadius

      if (inside) {
        pixelMask[y * totalWidth + x] = INTERIOR
      }
    }
  }

  // label boundary as EDGE
  for (let y = 0; y < totalWidth; y++) {
    for (let x = 0; x < totalWidth; x++) {
      const index = y * totalWidth + x

      const pixelValue = pixelMask[index]

      // Only check labels found on first pass.
      if (pixelValue !== INTERIOR) {
        continue
      }

      // Check if we are actually on the egde of the matrix:
      if (y === 0 || y === totalWidth - 1 || x === 0 || x === totalWidth - 1) {
        pixelMask[index] = EDGE

        continue
      }

      // Check if any adjacent is equal to 0, this means we are on the edge.
      if (
        pixelMask[(y - 1) * totalWidth + x] === EXTERIOR ||
        pixelMask[(y + 1) * totalWidth + x] === EXTERIOR ||
        pixelMask[y * totalWidth + x + 1] === EXTERIOR ||
        pixelMask[y * totalWidth + x - 1] === EXTERIOR
      ) {
        pixelMask[index] = EDGE
      }
    }
  }

  // Start from that first voxel, and you always know the path is going right, or down.
  // Find the next adjacent in that direction (i.e. not the 3 directions behind you)
  // Walk along and pop these to a list and color them as CONSUMED_EDGEs.
  const firstVoxel = getFirstPointOfCircle(pixelMask, totalWidth)

  const circularPath: number[][] = []

  let currentPosition: [number, number] = [firstVoxel[0], firstVoxel[1]]

  circularPath.push(...getPixelOutlines(currentPosition, pixelMask, totalWidth))

  const getNextPixelInCircularPath = generateCircularPixelPathBuilder(pixelMask, totalWidth)

  const traversePath = (): void => {
    const nextPixel = getNextPixelInCircularPath(currentPosition)

    // Set the previous pixel as traversed
    pixelMask[currentPosition[0] + currentPosition[1] * totalWidth] = CONSUMED_EDGE

    if (nextPixel) {
      currentPosition = nextPixel

      // Draw lines for this pixel
      circularPath.push(...getPixelOutlines(currentPosition, pixelMask, totalWidth))

      traversePath()
    }
  }

  traversePath()

  // Switch voxels used to work edge back to simple mask
  for (let pixelIndex = 0; pixelIndex < pixelMask.length; pixelIndex++) {
    if (pixelMask[pixelIndex] == 3) {
      pixelMask[pixelIndex] = 1
    }
  }

  const topLeft = { x: -totalWidth / 2, y: -totalWidth / 2 }
  const tipPath = translateMaskPath(topLeft, circularPath)

  return { tipPath, pixelMask }
}
