import type { AnnotationClassPayload, ApiResponse } from '@/store/types'
import { post } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

type Params = {
  annotation_type_ids?: number[]
  annotation_types?: string[]
  datasets: AnnotationClassPayload['datasets']
  description: string | null
  images: AnnotationClassPayload['images']
  metadata: AnnotationClassPayload['metadata']
  name: string
  team_slug: string
}

/**
 * Creates annotation class on backend
 */
export const createAnnotationClass = async (
  params: Params,
): Promise<ApiResponse<AnnotationClassPayload> | ParsedError> => {
  const { team_slug: teamSlug, ...rest } = params
  const path = `teams/${params.team_slug}/annotation_classes`

  try {
    const response = await post<AnnotationClassPayload>(path, rest)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.ANNOTATION_CLASS_CREATE)
  }
}
