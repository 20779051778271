import { v4 as uuidv4 } from 'uuid'

import { getAnnotationClassColor } from '@/modules/Editor/AnnotationClass'
import { euclideanDistance } from '@/modules/Editor/algebra'
import type { IPoint } from '@/modules/Editor/point'
import { MaskBrushDimension } from '@/modules/Editor/tools/types'
import type { Range } from '@/modules/Editor/types'
import { createAnnotationFromInstanceParams } from '@/modules/Editor/models/annotation/annotationFactories'
import { isRasterAnnotation } from '@/modules/Editor/models/annotation/annotationKindValidator'
import type { MaskAnnotation } from '@/modules/Editor/models/annotation/types'
import type { Raster } from '@/modules/Editor/models/raster/Raster'
import type { RasterBufferAccessor } from '@/modules/Editor/models/raster/RasterBufferAccessor'
import type { MaskBrushThreshold } from '@/modules/Editor/tools/types'
import { VoxelRaster } from '@/modules/Editor/models/raster/VoxelRaster'
import { isThresholdBrushAvailable } from '@/modules/Editor/utils/radiology/isThresholdBrushAvailable'
import { isDicomView } from '@/modules/Editor/utils/radiology/isDicomView'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'
import { assertVoxelRaster } from '@/modules/Editor/models/raster/assertVoxelRaster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import { TipShape } from '@/modules/Editor/plugins/brush/consts'
import { createMaskAnnotationIn3D } from '@/modules/Editor/plugins/mask/utils/shared/createMaskAnnotationIn3D'
import { invalidate3D } from '@/modules/Editor/plugins/mask/utils/shared/invalidate3D'
import { updateMaskAnnotation } from '@/modules/Editor/plugins/mask/utils/shared/updateMaskAnnotation'
import { updateMaskAnnotationIn3D } from '@/modules/Editor/plugins/mask/utils/shared/updateMaskAnnotationIn3D'
import { cleanupPotentiallyEmptyMask } from '@/modules/Editor/plugins/mask/utils/shared/cleanupPotentiallyEmptyMask'
import { Plane } from '@/modules/Editor/utils/raster/Plane'
import type { View } from '@/modules/Editor/views/view'

import { polygonToRaster } from './polygonToRaster'
import { checkAndRemoveEmptyMasks } from './shared/checkAndRemoveEmptyMasks'
import { createMaskAnnotation } from './shared/createMaskAnnotation'
import { getAnnotationForClassIdOnRaster } from './shared/getAnnotationForClassIdOnRaster'
import { getOrCreateRasterForView } from './shared/getOrCreateRasterForView'
import { clipRangeToRasterBounds } from './shared/clipRangeToRasterBounds'
import { combineRange } from './shared/combineRange'
import { createMaskAnnotationOnReformat } from './shared/createMaskAnnotationOnReformat'
import { getPrimaryViewFromView } from './shared/getPrimaryViewFromView'
import { getRasterBufferAccessorForView } from './shared/getRasterBufferAccessorForView'
import { getUpdatedMaskAnnotationBounds } from './shared/getUpdatedMaskAnnotationBounds'
import { updateMaskAnnotationOnReformat } from './shared/updateMaskAnnotationOnReformat'

type StrokeData = {
  imagePoint: IPoint
  width: number
}

type PaintMethod = (imagePoint: IPoint) => void

type InterpolateGetter = (currentStrokeData: StrokeData, previousStrokeData: StrokeData) => IPoint[]

export type PixelBrushProperties = {
  width: number
  tipShape: TipShape
  pixelMask: Uint8ClampedArray | null
  pixelMaskIn3D: Uint8ClampedArray[] | null
  isEraser: boolean
  dimension: MaskBrushDimension
  threshold: MaskBrushThreshold
  isThresholdEnabled: boolean
}

const isOutsideImage = (x: number, y: number, imageWidth: number, imageHeight: number): boolean =>
  y > imageHeight - 1 || x > imageWidth - 1 || x < 0 || y < 0

/**
 * Clips a polygon to the given raster region. Prevents fast brush strokes off the canvas
 * painting incorrectly to surrounding pixels.
 */
const clipInterpolationRegionToRasterRange = (
  rasterBufferAccessor: RasterBufferAccessor,
  points: IPoint[],
): IPoint[] => {
  const { width, height } = rasterBufferAccessor

  return points.map((point) => ({
    x: Math.min(Math.max(point.x, 0), width),
    y: Math.min(Math.max(point.y, 0), height),
  }))
}

/**
 * Class which facilitates the drawing of a single brush stroke
 * of a particular label to the raster layer.
 */
export class BrushPainter {
  /**
   * The view being drawn onto.
   */
  private readonly view: View
  /**
   * The primary view. In the case of voxel rasters this can be different to the drawn view.
   */
  private readonly primaryView: View
  public readonly raster: Raster
  // Cache the accessor, as we may be drawing to a 3D raster from another view.
  private readonly rasterBufferAccessor: RasterBufferAccessor
  private readonly classId: number
  private readonly labelIndex: number
  public isEndingStroke: boolean = false
  private pixelBrushProperties: PixelBrushProperties
  private readonly maskAnnotation: MaskAnnotation
  private readonly labelsBeingOverwritten: Set<number> = new Set()
  private isRasterBufferModified: boolean = false
  private isVideoRaster: boolean
  private isVoxelRaster: boolean
  private editRange: Range
  /**
   * The method used to paint pixels to the raster layer.
   * Varies based on brush type and whether the brush is being
   * used a brush or an eraser.
   */
  private readonly paint: PaintMethod
  /**
   * The method which returns a polygon that needs to be interpolated
   * between individual .stroke() calls.
   *
   * Varies based on the `tipShape`.
   */
  private readonly interpolateGetter: InterpolateGetter
  // We use 2 as the cuttoff rather than 1,
  // as a distance of 1 from the last voxel means any
  // interpolation would be sub grid and not change the raster.
  private readonly minPixelDistanceToInterpolate: number = 2.0
  /**
   * Will be set true in the constructor if a Mask annotation associated
   * with the given classId does not exist. This is then queried to decide
   * whether to create or update the mask annotation at the end of the brush
   * stroke (i.e. .endStroke()).
   */
  private isNewMaskAnnotation: boolean = false
  private previousStrokeData?: StrokeData

  /**
   * Initialises the brush so that the brush stroke logic is efficient.
   *
   * @param view The view on which to paint the brush stroke.
   * @param classId The id of the class to be painted to the raster layer.
   * @param tipShape The shape of the brush tip being used for the stroke.
   * @param isEraser Whether the brush is an eraser rather than an additive paint brush.
   */
  constructor(view: View, classId: number, pixelBrushProperties: PixelBrushProperties) {
    this.view = view

    const primaryView = getPrimaryViewFromView(view)

    if (!primaryView) {
      throw new Error('No primary view for reformatted MPR view')
    }

    this.primaryView = primaryView

    this.raster = getOrCreateRasterForView(this.primaryView)
    this.rasterBufferAccessor = getRasterBufferAccessorForView(view, this.raster)

    this.isVideoRaster = this.raster.type === RasterTypes.VIDEO
    this.isVoxelRaster = this.raster.type === RasterTypes.VOXEL

    this.classId = classId
    this.maskAnnotation = this.getAnnotationForMaskClass()

    this.pixelBrushProperties = pixelBrushProperties

    const labelIndex = this.raster.getLabelIndexForAnnotationId(this.maskAnnotation.id)

    if (labelIndex === undefined) {
      throw new Error(`Annotation with id ${this.maskAnnotation.id} not on raster`)
    }

    this.labelIndex = labelIndex
    this.editRange = this.initEditRange()

    const { isEraser, tipShape } = this.pixelBrushProperties

    if (isEraser) {
      // Set that this label is beind modified, so that we may check it at the end
      // as a deletion candidate.
      this.labelsBeingOverwritten.add(labelIndex)
    }

    this.paint = this.generatePaintMethod(labelIndex, tipShape, isEraser)
    this.interpolateGetter =
      tipShape === TipShape.Round
        ? this.getInterpolatedRegionBetweenCircles
        : this.getInterpolatedRegionBetweenSquares
  }

  public setProperties(properties: Partial<PixelBrushProperties>): void {
    this.pixelBrushProperties = {
      ...this.pixelBrushProperties,
      ...properties,
    }

    if (properties.isEraser !== undefined) {
      this.generatePaintMethod(
        this.labelIndex,
        this.pixelBrushProperties.tipShape,
        properties.isEraser,
      )
    }
  }

  /**
   * The first time this is called, it paints the brush onto the raster layer
   * using the brushTip.
   *
   * On subsequent calls, this method produces a linearly interpolated brush
   * stroke from the previous stroke location to the current one.
   *
   * @param imagePoint The center of the stroke point in image space.
   * @param radius The radius of the brush stroke.
   */
  public stroke(imagePoint: IPoint): void {
    this.isEndingStroke = false
    const { pixelBrushProperties, rasterBufferAccessor } = this
    const { width: imageWidth, height: imageHeight } = rasterBufferAccessor
    const { width } = pixelBrushProperties
    let range = this.getBrushRange({ imagePoint, width })

    this.paint(imagePoint)

    // Potentially draw an interpolated region between brush strokes
    range = this.interpolateStrokes(range, { imagePoint, width })

    // Update edit range
    this.editRange = combineRange(this.editRange, range, imageWidth, imageHeight)

    // Invalidate the raster
    this.invalidate(range)

    // Cache the input as previous stroke data, so we can interpolate
    // on the next stroke event.
    this.previousStrokeData = {
      imagePoint,
      width,
    }
  }

  private invalidate(range: Range): void {
    const { raster } = this

    if (raster instanceof VoxelRaster) {
      let depth = undefined

      // If the brush is doing a 3D stroke, we need to invalidate all frames (depth)
      if (this.isUsing3dBrush) {
        depth = this.pixelBrushProperties.width
      }

      invalidate3D(raster, this.rasterBufferAccessor, this.view.currentFrameIndex, range, depth)
      return
    }

    raster.invalidate(range.minX, range.maxX, range.minY, range.maxY)
  }

  private isOutsideThreshold(x: number, y: number, z: number, plane: Plane | undefined): boolean {
    if (!isThresholdBrushAvailable(this.view)) {
      return false
    }

    if (!isDicomView(this.view)) {
      return false
    }

    const { threshold, isThresholdEnabled } = this.pixelBrushProperties

    if (!isThresholdEnabled) {
      return false
    }

    const voxelValue = this.view.getVoxelValueAt(x, y, z, plane)
    return voxelValue < threshold.min || voxelValue > threshold.max
  }

  /**
   * Releases the brush from painting on the raster layer and finishes the stroke.
   * If this was a new mask annotation, this annotation is created, otherwise it is
   * updated.
   */
  public async endStroke(): Promise<void> {
    if (this.isEndingStroke) {
      return
    }

    this.isEndingStroke = true

    const {
      primaryView,
      raster,
      pixelBrushProperties,
      labelIndex,
      isNewMaskAnnotation,
      isRasterBufferModified,
    } = this

    // If painting was performed outside the raster there is nothing to fill,
    // also if the brush threshold is outside the range the same can happen.
    // We should not create/update any annotations in these cases
    // and cleanup the side effects of the brush stroke.
    if (!isRasterBufferModified) {
      cleanupPotentiallyEmptyMask(raster, isNewMaskAnnotation, labelIndex)
      this.isEndingStroke = false
      return
    }

    const { isEraser } = pixelBrushProperties

    if (isNewMaskAnnotation && isEraser) {
      // Mask annotation doesn't exist, and used the eraser this paint, just clean up.
      raster.clearInProgressAnnotations()
      raster.deleteAnnotationMapping(labelIndex)
    } else if (isNewMaskAnnotation) {
      // Mask annotation doesn't yet exist, and used a brush, create annotation and clean up.
      await this.createMaskAnnotation()
    } else {
      this.updateMaskAnnotation()
    }

    if (this.labelsBeingOverwritten.size === 0) {
      this.isEndingStroke = false
      return
    }

    // Potentially remove old annotations when any is overwritten
    const labelsBeingOverwrittenArray = Array.from(this.labelsBeingOverwritten)

    const strokeFrameRange = this.getBrushPaintFrameRange()
    checkAndRemoveEmptyMasks(primaryView, raster, labelsBeingOverwrittenArray, strokeFrameRange)
    this.isEndingStroke = false
  }

  private async createMaskAnnotation(): Promise<void> {
    const { view, primaryView, raster, editRange, labelIndex, rasterBufferAccessor, classId } = this
    const { plane } = rasterBufferAccessor

    const bounds = {
      topLeft: {
        x: editRange.minX,
        y: editRange.minY,
      },
      bottomRight: {
        x: editRange.maxX,
        y: editRange.maxY,
      },
    }

    raster.clearInProgressAnnotations()

    if (plane && plane !== Plane.Z) {
      const depth = this.pixelBrushProperties.width
      const halfDepth = Math.floor(depth / 2)

      return await createMaskAnnotationOnReformat(
        primaryView,
        assertVoxelRaster(raster),
        bounds,
        labelIndex,
        classId,
        view.currentFrameIndex,
        plane,
        this.isUsing3dBrush ? halfDepth : 0,
      )
    }

    if (this.isUsing3dBrush) {
      return await createMaskAnnotationIn3D(
        primaryView,
        assertVoxelRaster(raster),
        bounds,
        labelIndex,
        classId,
        this.pixelBrushProperties.width,
      )
    }

    return await createMaskAnnotation({ view: primaryView, raster, bounds, labelIndex, classId })
  }

  private updateMaskAnnotation(): void {
    const {
      view,
      primaryView,
      raster,
      editRange,
      labelIndex,
      rasterBufferAccessor,
      maskAnnotation,
    } = this
    const { plane, width: imageWidth, height: imageHeight } = rasterBufferAccessor

    if (plane && plane !== Plane.Z) {
      const bounds = {
        topLeft: {
          x: editRange.minX,
          y: editRange.minY,
        },
        bottomRight: {
          x: editRange.maxX,
          y: editRange.maxY,
        },
      }

      const depth = this.pixelBrushProperties.width
      const halfDepth = Math.floor(depth / 2)

      updateMaskAnnotationOnReformat(
        primaryView,
        assertVoxelRaster(raster),
        maskAnnotation,
        bounds,
        view.currentFrameIndex,
        plane,
        this.isUsing3dBrush ? halfDepth : 0,
      )

      return
    }

    const bounds = getUpdatedMaskAnnotationBounds(
      raster,
      labelIndex,
      this.editRange,
      imageWidth,
      imageHeight,
      view.currentFrameIndex,
    )

    if (this.isUsing3dBrush) {
      updateMaskAnnotationIn3D(
        primaryView,
        assertVoxelRaster(raster),
        maskAnnotation,
        bounds,
        this.pixelBrushProperties.width,
      )

      return
    }

    updateMaskAnnotation(primaryView, raster, maskAnnotation, bounds)
  }

  /**
   * Creates a linearly interpolated stroke between two points
   * on the raster.
   * @param currentInvalidationRange The current invalidated region, should this need be updated.
   * @param currentStrokeData Data on the current stroke point.
   *
   * @returns The update range after potential interpolation.
   */
  private interpolateStrokes(
    currentInvalidationRange: Range,
    currentStrokeData: StrokeData,
  ): Range {
    // interpolation between 3D brush strokes is not
    // supported for performance reasons
    if (this.isUsing3dBrush) {
      return currentInvalidationRange
    }

    const { previousStrokeData } = this

    if (!previousStrokeData) {
      return currentInvalidationRange
    }

    // Filter out sub-pixel moves
    const prevImagePoint = previousStrokeData.imagePoint
    const currentImagePoint = currentStrokeData.imagePoint

    if (
      Math.abs(prevImagePoint.x - currentImagePoint.x) < this.minPixelDistanceToInterpolate &&
      Math.abs(prevImagePoint.y - currentImagePoint.y) < this.minPixelDistanceToInterpolate
    ) {
      return currentInvalidationRange
    }

    const { labelIndex, labelsBeingOverwritten, pixelBrushProperties } = this
    const { isEraser } = pixelBrushProperties
    const polygon = this.interpolateGetter(currentStrokeData, previousStrokeData)

    const { get, set, width, height, plane } = this.rasterBufferAccessor

    const rasterizedPolygon = polygonToRaster(polygon)

    if (isEraser) {
      // Erase labels in the polygon defined by this quad
      rasterizedPolygon.data.forEach(([x, y]): void => {
        if (x < 0 || x >= width || y < 0 || y >= height) {
          return
        }

        if (get(x, y) === labelIndex) {
          set(x, y, 0)
          this.isRasterBufferModified = true
        }
      })
    } else {
      // Fill in the polygon defined by this quad
      rasterizedPolygon.data.forEach(([x, y]): void => {
        if (isOutsideImage(x, y, width, height)) {
          return
        }

        if (this.isOutsideThreshold(x, y, this.view.currentFrameIndex, plane)) {
          return
        }

        const pixelValue = get(x, y)

        if (pixelValue !== labelIndex && pixelValue !== 0) {
          labelsBeingOverwritten.add(pixelValue)
        }
        set(x, y, labelIndex)
        this.isRasterBufferModified = true
      })
    }

    const { width: imageWidth, height: imageHeight } = this.rasterBufferAccessor

    const newInvaldationRange = combineRange(
      currentInvalidationRange,
      rasterizedPolygon.coords,
      imageWidth,
      imageHeight,
    )

    return newInvaldationRange
  }

  /**
   * Given two circle brush points, works out the interpolated quad polygon to rasterize.
   *
   * @param currentStrokeData The current circle brush point.
   * @param previousStrokeData The previous circle brush point.
   */
  private getInterpolatedRegionBetweenCircles(
    currentStrokeData: StrokeData,
    previousStrokeData: StrokeData,
  ): IPoint[] {
    // Note: The quad linking the radii connects the two circles perfectly,
    // and no further logic is required, unlike the square brush.

    const interpolateRegion = this.getQuadLinkingRadii(currentStrokeData, previousStrokeData)

    return clipInterpolationRegionToRasterRange(this.rasterBufferAccessor, interpolateRegion)
  }

  /**
   * Given two square brush points, works out the interpolated quad polygon to rasterize.
   *
   * @param currentStrokeData The current square brush point.
   * @param previousStrokeData The previous square brush point.
   */
  private getInterpolatedRegionBetweenSquares(
    currentStrokeData: StrokeData,
    previousStrokeData: StrokeData,
  ): IPoint[] {
    const quadLinkingRadii = this.getQuadLinkingRadii(currentStrokeData, previousStrokeData)

    // Snap each vertex to the corner of the square brush.
    // This ensures we get a smooth interpolation.

    const prevSquareBrushCorners = this.getSquareBrushCorners(previousStrokeData)
    const currentSquareBrushCorners = this.getSquareBrushCorners(currentStrokeData)

    // First two points to be snapped to the previous square
    quadLinkingRadii[0] = this.snapPointToPolygon(quadLinkingRadii[0], prevSquareBrushCorners)
    quadLinkingRadii[1] = this.snapPointToPolygon(quadLinkingRadii[1], prevSquareBrushCorners)
    // Second two points to be snapped to the current square
    quadLinkingRadii[2] = this.snapPointToPolygon(quadLinkingRadii[2], currentSquareBrushCorners)
    quadLinkingRadii[3] = this.snapPointToPolygon(quadLinkingRadii[3], currentSquareBrushCorners)

    return clipInterpolationRegionToRasterRange(this.rasterBufferAccessor, quadLinkingRadii)
  }

  /**
   * Snapes the given `point` to the closest point on the `polygon`.
   * @param point The point to snap
   * @param polygon The target polygon.
   *
   * @returns The snapped point.
   */
  private snapPointToPolygon(point: IPoint, polygon: IPoint[]): IPoint {
    const closest = {
      dist: Infinity,
      index: 0,
    }

    for (let i = 0; i < polygon.length; i++) {
      const polygonPoint = polygon[i]
      const dist = euclideanDistance(point, polygonPoint)

      if (dist < closest.dist) {
        closest.dist = dist
        closest.index = i
      }
    }

    return polygon[closest.index]
  }

  /**
   * Gets the four corners of a square brush tip from its `StrokeData`
   * (i.e. its center and radius).
   *
   * @param strokeData The brushTip's center and radius.
   * @returns The 4 corners as a polygon.
   */
  private getSquareBrushCorners(strokeData: StrokeData): IPoint[] {
    const { imagePoint, width } = strokeData
    const squareRadius = width / 2
    const square = [
      // Bottom Left
      {
        x: imagePoint.x - squareRadius,
        y: imagePoint.y - squareRadius,
      },
      // Bottom Right
      {
        x: imagePoint.x + squareRadius,
        y: imagePoint.y - squareRadius,
      },
      // Top Right
      {
        x: imagePoint.x + squareRadius,
        y: imagePoint.y + squareRadius,
      },
      // Top Left
      {
        x: imagePoint.x - squareRadius,
        y: imagePoint.y + squareRadius,
      },
    ]

    return square
  }

  /**
   * Produces a quadrilateral by taking the vector between the center of two
   * brush points, and at each end, taking two points of length equal to the
   * brush radius perpendicular to this vector.
   *
   * This quadrilateral is used for interpolation.
   *
   * @param currentStrokeData The current square brush point.
   * @param previousStrokeData The previous square brush point.
   * @returns The quad as a polygon.
   */
  private getQuadLinkingRadii(
    currentStrokeData: StrokeData,
    previousStrokeData: StrokeData,
  ): IPoint[] {
    const { imagePoint: prevImagePoint, width: prevWidth } = previousStrokeData
    const { imagePoint: currentImagePoint, width: currentWidth } = currentStrokeData

    const prevRadius = prevWidth / 2
    const currentRadius = currentWidth / 2

    const dist = euclideanDistance(prevImagePoint, currentImagePoint)

    const directionVector = {
      x: (currentImagePoint.x - prevImagePoint.x) / dist,
      y: (currentImagePoint.y - prevImagePoint.y) / dist,
    }

    const perpendicularVector1 = {
      x: -directionVector.y,
      y: directionVector.x,
    }

    const perpendicularVector2 = {
      x: directionVector.y,
      y: -directionVector.x,
    }

    const polygon = [
      // Points on previous Circle
      // prev 1
      {
        x: prevImagePoint.x + perpendicularVector1.x * prevRadius,
        y: prevImagePoint.y + perpendicularVector1.y * prevRadius,
      },
      // prev 2
      {
        x: prevImagePoint.x + perpendicularVector2.x * prevRadius,
        y: prevImagePoint.y + perpendicularVector2.y * prevRadius,
      },
      // Points on current Circle
      // current 2
      {
        x: currentImagePoint.x + perpendicularVector2.x * currentRadius,
        y: currentImagePoint.y + perpendicularVector2.y * currentRadius,
      },
      // current 1
      {
        x: currentImagePoint.x + perpendicularVector1.x * currentRadius,
        y: currentImagePoint.y + perpendicularVector1.y * currentRadius,
      },
    ]

    return polygon
  }

  /**
   * Initialises the range of the edited mask region. This is recalculated as we draw
   * so that we end up with a bounding box for the mask at the end of the stroke.
   *
   * @returns The initial range of the annotation, if present, or a default range
   * if the annotation is new.
   */
  private initEditRange(): Range {
    return {
      minX: Infinity,
      maxX: -Infinity,
      minY: Infinity,
      maxY: -Infinity,
    }
  }

  /**
   * Gets the range of an individual circular brush tip.
   *
   * @param strokeData The brush point.
   * @returns The range to paint over.
   */
  private getBrushRange(strokeData: StrokeData): Range {
    const { imagePoint, width } = strokeData
    const radius = width / 2

    const minX = Math.floor(imagePoint.x - radius)
    const minY = Math.floor(imagePoint.y - radius)
    const maxX = Math.ceil(imagePoint.x + radius)
    const maxY = Math.ceil(imagePoint.y + radius)

    return clipRangeToRasterBounds(
      { minX, minY, maxX, maxY },
      this.rasterBufferAccessor.width,
      this.rasterBufferAccessor.height,
    )
  }

  /**
   * If a Mask annotation already exists for this stroke's classId, fetches it.
   * If a Mask annotation does not yet exist, creates a temporary one to use whilst
   * we perform the brush stroke prior to annotation creation.
   *
   * @returns The (temporary) Mask annotation.
   */
  private getAnnotationForMaskClass(): MaskAnnotation {
    const { primaryView, raster, classId } = this

    const annotation = getAnnotationForClassIdOnRaster(primaryView, raster, classId)

    if (annotation && isRasterAnnotation(annotation)) {
      return annotation
    }

    // Create a temporary annotation and add it to the raster

    this.isNewMaskAnnotation = true

    const annotationClass = this.view.editor.getClassById(classId)
    if (!annotationClass) {
      throw Error('Failed to create match class for class id')
    }

    const tempAnnotation = createAnnotationFromInstanceParams({
      id: uuidv4(),
      type: 'mask',
      annotationClass,
      classId: annotationClass.id,
      label: annotationClass.name,
      color: getAnnotationClassColor(annotationClass),
      data: {
        rasterId: raster.id,
      },
    })

    if (!tempAnnotation || !isRasterAnnotation(tempAnnotation)) {
      throw new Error('Failed to create temporary annotation')
    }

    raster.setInProgressAnnotation(tempAnnotation)

    // Assign label index
    const labelIndex = this.raster.getNextAvailableLabelIndex()
    this.raster.setAnnotationMapping(labelIndex, tempAnnotation.id, annotationClass.id)

    if (this.isVideoRaster || this.isVoxelRaster) {
      const videoRaster = assertVideoRaster(this.raster)

      videoRaster.setLabelOnKeyframe(labelIndex, primaryView.currentFrameIndex)
    }

    return tempAnnotation
  }

  private get isUsing3dBrush(): boolean {
    return this.pixelBrushProperties.dimension === MaskBrushDimension.Paint3D
  }

  /**
   * Generates a paint method for the brush tip based on the given
   * label index, tip shape and whether the brush stroke is an eraser.
   * @param labelIndex The index being labelled on the raster.
   * @param tipShape The shape of the brush tip.
   * @param isEraser Whether this stroke is an eraser stroke.
   *
   * @returns The draw method to use.
   */
  private generatePaintMethod(
    labelIndex: number,
    tipShape: TipShape,
    isEraser: boolean,
  ): PaintMethod {
    if (this.isUsing3dBrush) {
      if (tipShape === TipShape.Round) {
        if (isEraser) {
          return this.generateCircleEraserPaintMethodIn3D(labelIndex)
        }
        return this.generateCircleBrushPaintMethodIn3d(labelIndex)
      }
      if (isEraser) {
        return this.generateSquareEraserPaintMethodIn3D(labelIndex)
      }
      return this.generateSquareBrushPaintMethodIn3D(labelIndex)
    }

    if (tipShape === TipShape.Round) {
      if (isEraser) {
        return this.generateCircleEraserPaintMethod(labelIndex)
      }
      return this.generateCircleBrushPaintMethod(labelIndex)
    }
    if (isEraser) {
      return this.generateSquareEraserPaintMethod(labelIndex)
    }
    return this.generateSquareBrushPaintMethod(labelIndex)
  }

  private generateCircleBrushPaintMethod(labelIndex: number): PaintMethod {
    const { rasterBufferAccessor, labelsBeingOverwritten } = this
    const { get, set, width, height, plane } = rasterBufferAccessor

    return (imagePoint: IPoint): void => {
      const { pixelMask } = this.pixelBrushProperties

      if (pixelMask === null) {
        throw new Error('We should have a pixelMask if using a circular brush')
      }

      const { minX, maxX, minY, maxY } = this.getBrushPaintRange(imagePoint)

      let pixelMaskIndex = 0

      for (let y = minY; y <= maxY; y++) {
        for (let x = minX; x <= maxX; x++) {
          if (isOutsideImage(x, y, width, height)) {
            // Outside actual image, just continue.
            pixelMaskIndex++
            continue
          }

          // Using out precomputed pixel mask, here we check if this pixel
          // is within the mask. This way we only paint pixels in the "template"
          // efficiently, without and re-computation of the circle.
          if (
            pixelMask[pixelMaskIndex] === 1 &&
            !this.isOutsideThreshold(x, y, this.view.currentFrameIndex, plane)
          ) {
            const currentLabelIndex = get(x, y)
            if (currentLabelIndex !== labelIndex && currentLabelIndex !== 0) {
              labelsBeingOverwritten.add(currentLabelIndex)
            }

            set(x, y, labelIndex)
            this.isRasterBufferModified = true
          }

          pixelMaskIndex++
        }
      }
    }
  }

  private generateCircleBrushPaintMethodIn3d(labelIndex: number): PaintMethod {
    const { labelsBeingOverwritten } = this

    return (imagePoint: IPoint): void => {
      const { pixelMaskIn3D } = this.pixelBrushProperties

      if (pixelMaskIn3D === null) {
        throw new Error('We should have a pixelMaskIn3D if using a circular 3D brush')
      }

      const { minX, maxX, minY, maxY } = this.getBrushPaintRange(imagePoint)

      const depth = this.pixelBrushProperties.width
      const halfDepth = Math.floor(depth / 2)
      const edgeCorrection = depth % 2 === 0 ? 1 : 0

      for (let offset = -halfDepth; offset <= halfDepth - edgeCorrection; offset++) {
        const paintingFrameIndex = this.view.currentFrameIndex + offset
        if (paintingFrameIndex < 0 || paintingFrameIndex > this.view.totalFrames - 1) {
          continue
        }

        const rasterBufferAccessor = getRasterBufferAccessorForView(
          this.view,
          this.raster,
          paintingFrameIndex,
        )
        const { get, set, width, height, plane } = rasterBufferAccessor

        const pixelMask = pixelMaskIn3D[offset + halfDepth]
        let pixelMaskIndex = 0

        for (let y = minY; y <= maxY; y++) {
          for (let x = minX; x <= maxX; x++) {
            if (isOutsideImage(x, y, width, height)) {
              // Outside actual image, just continue.
              pixelMaskIndex++
              continue
            }

            if (!pixelMask) {
              continue
            }

            // Using out precomputed pixel mask, here we check if this pixel
            // is within the mask. This way we only paint pixels in the "template"
            // efficiently, without and re-computation of the circle.
            if (
              pixelMask[pixelMaskIndex] === 1 &&
              !this.isOutsideThreshold(x, y, paintingFrameIndex, plane)
            ) {
              const currentLabelIndex = get(x, y)
              if (currentLabelIndex !== labelIndex && currentLabelIndex !== 0) {
                labelsBeingOverwritten.add(currentLabelIndex)
              }

              set(x, y, labelIndex)
              this.isRasterBufferModified = true
            }

            pixelMaskIndex++
          }
        }
      }
    }
  }

  private generateCircleEraserPaintMethod(labelIndex: number): PaintMethod {
    const { rasterBufferAccessor } = this
    const { get, set, width, height } = rasterBufferAccessor

    return (imagePoint: IPoint): void => {
      const { pixelMask } = this.pixelBrushProperties

      if (pixelMask === null) {
        throw new Error('We should have a pixelMask if using a circular brush')
      }

      const { minX, maxX, minY, maxY } = this.getBrushPaintRange(imagePoint)

      let pixelMaskIndex = 0

      for (let y = minY; y <= maxY; y++) {
        for (let x = minX; x <= maxX; x++) {
          if (isOutsideImage(x, y, width, height)) {
            // Outside actual image, just continue.
            pixelMaskIndex++
            continue
          }

          if (pixelMask[pixelMaskIndex] === 1) {
            if (get(x, y) === labelIndex) {
              set(x, y, 0)
              this.isRasterBufferModified = true
            }
          }

          pixelMaskIndex++
        }
      }
    }
  }

  private generateCircleEraserPaintMethodIn3D(labelIndex: number): PaintMethod {
    return (imagePoint: IPoint): void => {
      const { pixelMaskIn3D } = this.pixelBrushProperties

      if (pixelMaskIn3D === null) {
        throw new Error('We should have a pixelMaskIn3D if using a circular 3D brush')
      }

      const { minX, maxX, minY, maxY } = this.getBrushPaintRange(imagePoint)

      const depth = this.pixelBrushProperties.width
      const halfDepth = Math.floor(depth / 2)
      const edgeCorrection = depth % 2 === 0 ? 1 : 0

      for (let offset = -halfDepth; offset <= halfDepth - edgeCorrection; offset++) {
        const paintingFrameIndex = this.view.currentFrameIndex + offset
        if (paintingFrameIndex < 0 || paintingFrameIndex > this.view.totalFrames - 1) {
          continue
        }

        const rasterBufferAccessor = getRasterBufferAccessorForView(
          this.view,
          this.raster,
          paintingFrameIndex,
        )
        const { get, set, width, height } = rasterBufferAccessor

        const pixelMask = pixelMaskIn3D[offset + halfDepth]
        let pixelMaskIndex = 0

        for (let y = minY; y <= maxY; y++) {
          for (let x = minX; x <= maxX; x++) {
            if (isOutsideImage(x, y, width, height)) {
              // Outside actual image, just continue.
              pixelMaskIndex++
              continue
            }

            if (pixelMask[pixelMaskIndex] === 1) {
              if (get(x, y) === labelIndex) {
                set(x, y, 0)
                this.isRasterBufferModified = true
              }
            }

            pixelMaskIndex++
          }
        }
      }
    }
  }

  private generateSquareBrushPaintMethod(labelIndex: number): PaintMethod {
    const { rasterBufferAccessor, labelsBeingOverwritten } = this
    const { get, set, width, height, plane } = rasterBufferAccessor

    return (imagePoint: IPoint): void => {
      const { minX, maxX, minY, maxY } = this.getBrushPaintRange(imagePoint)

      for (let y = minY; y <= maxY; y++) {
        for (let x = minX; x <= maxX; x++) {
          if (isOutsideImage(x, y, width, height)) {
            continue
          }

          if (this.isOutsideThreshold(x, y, this.view.currentFrameIndex, plane)) {
            continue
          }

          const currentLabelIndex = get(x, y)

          if (currentLabelIndex !== labelIndex && currentLabelIndex !== 0) {
            labelsBeingOverwritten.add(currentLabelIndex)
          }

          set(x, y, labelIndex)
          this.isRasterBufferModified = true
        }
      }
    }
  }

  private generateSquareBrushPaintMethodIn3D(labelIndex: number): PaintMethod {
    const { labelsBeingOverwritten } = this

    return (imagePoint: IPoint): void => {
      const { minX, maxX, minY, maxY } = this.getBrushPaintRange(imagePoint)

      const depth = this.pixelBrushProperties.width
      const halfDepth = Math.floor(depth / 2)
      const edgeCorrection = depth % 2 === 0 ? 1 : 0

      for (let offset = -halfDepth; offset <= halfDepth - edgeCorrection; offset++) {
        const paintingFrameIndex = this.view.currentFrameIndex + offset
        if (paintingFrameIndex < 0 || paintingFrameIndex > this.view.totalFrames - 1) {
          continue
        }

        const rasterBufferAccessor = getRasterBufferAccessorForView(
          this.view,
          this.raster,
          paintingFrameIndex,
        )
        const { get, set, width, height, plane } = rasterBufferAccessor

        for (let y = minY; y <= maxY; y++) {
          for (let x = minX; x <= maxX; x++) {
            if (isOutsideImage(x, y, width, height)) {
              continue
            }

            if (this.isOutsideThreshold(x, y, paintingFrameIndex, plane)) {
              continue
            }

            const currentLabelIndex = get(x, y)

            if (currentLabelIndex !== labelIndex && currentLabelIndex !== 0) {
              labelsBeingOverwritten.add(currentLabelIndex)
            }

            set(x, y, labelIndex)
            this.isRasterBufferModified = true
          }
        }
      }
    }
  }

  private generateSquareEraserPaintMethod(labelIndex: number): PaintMethod {
    const { rasterBufferAccessor } = this
    const { get, set, width, height } = rasterBufferAccessor

    return (imagePoint: IPoint): void => {
      const { minX, maxX, minY, maxY } = this.getBrushPaintRange(imagePoint)

      for (let y = minY; y <= maxY; y++) {
        for (let x = minX; x <= maxX; x++) {
          if (isOutsideImage(x, y, width, height)) {
            continue
          }

          if (get(x, y) === labelIndex) {
            set(x, y, 0)
            this.isRasterBufferModified = true
          }
        }
      }
    }
  }

  private generateSquareEraserPaintMethodIn3D(labelIndex: number): PaintMethod {
    return (imagePoint: IPoint): void => {
      const { minX, maxX, minY, maxY } = this.getBrushPaintRange(imagePoint)

      const depth = this.pixelBrushProperties.width
      const halfDepth = Math.floor(depth / 2)
      const edgeCorrection = depth % 2 === 0 ? 1 : 0

      for (let offset = -halfDepth; offset <= halfDepth - edgeCorrection; offset++) {
        const paintingFrameIndex = this.view.currentFrameIndex + offset
        if (paintingFrameIndex < 0 || paintingFrameIndex > this.view.totalFrames - 1) {
          continue
        }

        const rasterBufferAccessor = getRasterBufferAccessorForView(
          this.view,
          this.raster,
          paintingFrameIndex,
        )
        const { get, set, width, height } = rasterBufferAccessor

        for (let y = minY; y <= maxY; y++) {
          for (let x = minX; x <= maxX; x++) {
            if (isOutsideImage(x, y, width, height)) {
              continue
            }

            if (get(x, y) === labelIndex) {
              set(x, y, 0)
              this.isRasterBufferModified = true
            }
          }
        }
      }
    }
  }

  private getBrushPaintFrameRange(): number[] {
    const { dimension, width } = this.pixelBrushProperties

    if (dimension === MaskBrushDimension.Paint2D) {
      return [this.view.currentFrameIndex, this.view.currentFrameIndex]
    }

    return [
      this.view.currentFrameIndex - Math.floor(width / 2),
      this.view.currentFrameIndex + Math.ceil(width / 2),
    ]
  }

  private getBrushPaintRange(imagePoint: IPoint): Range {
    const { width } = this.pixelBrushProperties
    const flooredWidth = Math.floor(width)

    if (flooredWidth === 1) {
      // Edge cases where the generic logic breaks down due to pixel
      // level sensitivity and rounding:
      return {
        minX: imagePoint.x,
        maxX: imagePoint.x,
        minY: imagePoint.y,
        maxY: imagePoint.y,
      }
    }

    if (width % 2 === 0) {
      // Even
      const topLeftPixel = [
        Math.floor(imagePoint.x - width / 2),
        Math.floor(imagePoint.y - width / 2),
      ]

      const minX = topLeftPixel[0]
      const minY = topLeftPixel[1]

      const maxX = minX + flooredWidth - 1
      const maxY = minY + flooredWidth - 1

      return {
        minX,
        maxX,
        minY,
        maxY,
      }
    }

    // Odd
    const topLeftPixel = [
      Math.floor(imagePoint.x - width / 2) + 1,
      Math.floor(imagePoint.y - width / 2) + 1,
    ]

    const minX = topLeftPixel[0]
    const minY = topLeftPixel[1]

    const maxX = minX + flooredWidth - 1
    const maxY = minY + flooredWidth - 1

    return {
      minX,
      maxX,
      minY,
      maxY,
    }
  }
}
