import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { VideoAnnotation } from '@/modules/Editor/models/annotation/types'
import type { View } from '@/modules/Editor/views/view'

export const createAndSelectAnnotation = <T extends VideoAnnotation | Annotation>(
  view: View,
  annotation: T,
): T => {
  // We need to deselect all the annotations
  // because the new annotation will always be selected by default.
  view.annotationManager.deselectAllAnnotations()

  const newAnnotation = view.annotationManager.createAnnotation(annotation)
  view.annotationManager.selectAnnotation(annotation.id)

  return newAnnotation
}
