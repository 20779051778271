/**
 * Make sure that both the start and end frames are always defined.
 * This is necessary because the end might be null, which might happen:
 * - when a tag is assigned to a video when it gets uploaded
 * - when a tag is globally assigned to a video from the `/dataset-management` page
 * - when an annotation is imported from the python SDK/APIs
 * When the end range is `null` we span its annotation to the end of the video as fallback.
 * @param value the annotation segments
 * @return { startFrame: number, endFrame: number }
 */
export const getAnnotationRange = (
  segments: [number, number | null][] | undefined,
  lastVideoFrame: number,
): { startFrame: number; endFrame: number } => {
  if (!segments || segments.length === 0) {
    throw new Error('frames, No segments provided')
  }

  const startFrame = segments[0][0]
  const endFrame = segments[segments.length - 1]?.[1] || lastVideoFrame

  return { startFrame, endFrame }
}
