import Vue from 'vue'

import { EditorEvents } from '@/modules/Editor/eventBus'
import type { Editor } from '@/modules/Editor/editor'
import ClickerHeaderTools from '@/modules/Editor/plugins/click/HeaderTools.vue'
import ClickerSpinner from '@/modules/Editor/plugins/click/Spinner.vue'
import { HEADER_COMPONENT, SPINNER_COMPONENT } from '@/modules/Editor/plugins/click/consts'
import { resume as resumeClicker } from '@/modules/Editor/plugins/click/resume'
import { useClipboardInteractions } from '@/modules/Editor/plugins/clipboard/useClipboardInteractions'
import { deleteVertex } from '@/modules/Editor/plugins/edit/deleteVertex'
import { toggleReferenceLines } from '@/modules/Editor/plugins/referenceLines/toggleReferenceLines'
import { useWindowLevelTabInteractions } from '@/modules/Editor/plugins/windowLevel/useWindowLevelTabInteractions'
import { useZoomInteractions } from '@/modules/Editor/plugins/zoom/useZoomInteractions'

export const PluginName = {
  Clicker: 'clicker',
  Clipboard: 'clipboard',
  Edit: 'edit',
  ReferenceLines: 'reference_lines',
  SaveAnnotations: 'save_annotations',
} as const

export type PluginName = (typeof PluginName)[keyof typeof PluginName]

export class PluginManager {
  private pluginNames: PluginName[] = []

  constructor(editor: Editor) {
    // registers the two components used by the clicker, so they can be used
    // calling this more than once is a no-op, so it doesn't need to be cleaned up
    // long term, we should see if it's even needed and if so,
    // if it should be moved at the point of instantiating the editor.
    Vue.component(HEADER_COMPONENT, ClickerHeaderTools)
    Vue.component(SPINNER_COMPONENT, ClickerSpinner)

    // interactions conditioned to the presence of a plugin

    const registerInteraction = (command: string, name: PluginName, callback: () => void): void =>
      editor.registerCommand(command, () => this.pluginNames.includes(name) && callback())

    registerInteraction('clicker_tool.resume', PluginName.Clicker, () => resumeClicker(editor))

    registerInteraction('save_annotations.toast', PluginName.SaveAnnotations, () => {
      EditorEvents.message.emit({ content: 'No need, your work is auto-saved', level: 'info' })
    })

    registerInteraction('reference_lines.toggle', PluginName.ReferenceLines, () =>
      toggleReferenceLines(editor),
    )

    registerInteraction('edit.delete', PluginName.Edit, () => deleteVertex(editor))

    const clipboard = useClipboardInteractions(editor)
    registerInteraction('clipboard.cut', PluginName.Clipboard, clipboard.cut)
    registerInteraction('clipboard.paste', PluginName.Clipboard, clipboard.paste)
    registerInteraction('clipboard.shift_paste', PluginName.Clipboard, clipboard.shiftPaste)
    registerInteraction('clipboard.alt_paste', PluginName.Clipboard, clipboard.altPaste)
    registerInteraction('clipboard.alt_shift_paste', PluginName.Clipboard, clipboard.altShiftPaste)

    // always available interactions

    const zoom = useZoomInteractions(editor)
    editor.registerCommand('zoom.reset', zoom.reset)
    editor.registerCommand('zoom.focus_on_selected_annotation', zoom.focusOnSelectedAnnotation)
    editor.registerCommand('zoom.in', zoom.zoomIn)
    editor.registerCommand('zoom.out', zoom.zoomOut)

    editor.registerCommand('clipboard.copy', clipboard.copy)

    const windowLevel = useWindowLevelTabInteractions(editor)
    editor.registerCommand('window_level.activate', windowLevel.activate)
    editor.registerCommand('window_level.deactivate', windowLevel.deactivate)
  }

  public setPlugins(pluginNames: PluginName[]): void {
    this.pluginNames = pluginNames
  }

  public clearPlugins(): void {
    this.pluginNames = []
  }
}
