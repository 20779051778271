import { euclideanDistance } from '@/modules/Editor/algebra'
import type { Camera } from '@/modules/Editor/camera'
import { DEFAULT_LINE_WIDTH } from '@/modules/Editor/config'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import type { EditablePoint } from '@/modules/Editor/point'
import type { DrawCallback } from '@/modules/Editor/models/layers/types'
// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'

import { drawVertex, drawVertexV2 } from './drawVertex'
import { fillStyle } from './fillStyle'
import { strokeStyle } from './strokeStyle'
import type { Ellipse } from '@/modules/Editor/AnnotationData'

export const drawEllipseV2 = (
  drawFn: DrawCallback,
  camera: Camera,
  ellipse: Ellipse,
  color: RGBA,
  filter: ImageManipulationFilter | null,
  isHighlighted = false,
  isSelected = false,
): Path2D => {
  const { center, top, right, left, bottom } = ellipse

  const path2D = new Path2D()

  drawFn((ctx) => {
    ctx.lineWidth = DEFAULT_LINE_WIDTH
    ctx.strokeStyle = strokeStyle(color, filter, false, isSelected)
    ctx.fillStyle = fillStyle(color, filter, false, isHighlighted, isSelected)

    const radius = { x: euclideanDistance(center, right), y: euclideanDistance(center, top) }
    const angle = Math.atan2(right.y - center.y, right.x - center.x)

    path2D.ellipse(ellipse.center.x, ellipse.center.y, radius.x, radius.y, angle, 0, 2 * Math.PI)

    ctx.lineWidth = (DEFAULT_LINE_WIDTH * 1.0) / camera.scale
    ctx.stroke(path2D)
    ctx.fill(path2D, 'evenodd')
  })

  if (isSelected) {
    drawVertexV2(drawFn, camera, right, filter, color, isSelected, false)
    drawVertexV2(drawFn, camera, top, filter, color, isSelected, false)
    drawVertexV2(drawFn, camera, left, filter, color, isSelected, false)
    drawVertexV2(drawFn, camera, bottom, filter, color, isSelected, false)
  }

  return path2D
}

/**
 * @deprecated
 */
export const drawEllipse = (
  camera: Camera,
  ctx: CanvasRenderingContext2D,
  ellipse: {
    center: EditablePoint
    right: EditablePoint
    top: EditablePoint
    bottom: EditablePoint
    left: EditablePoint
  },
  color: RGBA,
  filter: ImageManipulationFilter | null,
  isHighlighted = false,
  isSelected = false,
): Path2D | undefined => {
  ctx.lineWidth = DEFAULT_LINE_WIDTH
  ctx.strokeStyle = strokeStyle(color, filter, false, isSelected)
  ctx.fillStyle = fillStyle(color, filter, false, isHighlighted, isSelected)

  const { center, top, right, left, bottom } = ellipse
  const radius = { x: euclideanDistance(center, right), y: euclideanDistance(center, top) }
  const angle = Math.atan2(right.y - center.y, right.x - center.x)

  const path = new Path2D()
  path.ellipse(ellipse.center.x, ellipse.center.y, radius.x, radius.y, angle, 0, 2 * Math.PI)

  ctx.save()
  camera.imageViewCtxToCanvasViewCtx(ctx)
  // the line width will change at different zoom levels.
  ctx.lineWidth = (DEFAULT_LINE_WIDTH * 1.0) / camera.scale
  ctx.stroke(path)
  ctx.fill(path, 'evenodd')
  ctx.restore()

  if (isSelected) {
    drawVertex(ctx, camera, right, filter, color, isSelected)
    drawVertex(ctx, camera, top, filter, color, isSelected)
    drawVertex(ctx, camera, left, filter, color, isSelected)
    drawVertex(ctx, camera, bottom, filter, color, isSelected)
  }

  return path
}
