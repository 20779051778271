import type { Action } from '@/modules/Editor/managers/actionManager'
import type { EditablePoint } from '@/modules/Editor/point'
import { inferCurrentAnnotationData } from '@/modules/Editor/inferCurrentAnnotationData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { isVideoAnnotation } from '@/modules/Editor/models/annotation/annotationKindValidator'
import type { View } from '@/modules/Editor/views/view'

import { updateImageAnnotation } from './utils/updateImageAnnotation'
import { updateVideoAnnotation } from './utils/updateVideoAnnotation'

/**
 * Add a vertex to the annotation path at the specified index.
 *
 * The path must be a reference in order for update to work correctly.
 *
 * @param {view} view instance
 * @param {Annotation} annotation Annotation who's path we're removing a vertex from
 * @param {EditablePoint[]} path Reference to the annotation path
 * @param {number} index The position to insert the new vertex at
 * @param {EditablePoint} vertext The vertex to insert
 */
export const addVertexAction = (
  view: View,
  annotation: Annotation,
  path: EditablePoint[],
  index: number,
  vertex: EditablePoint,
): Action => {
  const frameIndex = view.currentFrameIndex
  const isVideo = isVideoAnnotation(annotation)
  const annotationData = inferCurrentAnnotationData(annotation, frameIndex)
  // need to find out if we're modifying the main path or an additional path
  const isModifyingMainPath = path === annotationData.path
  const modifiedAdditionalPathIndex = (annotationData.additionalPaths || []).findIndex(
    (p) => p === path,
  )

  return {
    do(): boolean {
      // add the new 'vertex' at 'index' position
      const newPath = [...path.slice(0, index), vertex, ...path.slice(index)]
      const modifiedData = isModifyingMainPath
        ? { path: newPath, additionalPaths: annotationData.additionalPaths || [] }
        : {
            path: annotationData.path,
            additionalPaths: [
              ...(annotationData.additionalPaths || []).slice(0, modifiedAdditionalPathIndex),
              newPath,
              ...(annotationData.additionalPaths || []).slice(modifiedAdditionalPathIndex + 1),
            ],
          }

      // un-highlight the centroid as the new vertex will be highlighted next
      annotation.centroid = undefined
      // save the updated annotation to the BE
      let updatedAnnotation: Annotation
      if (isVideo) {
        updatedAnnotation = updateVideoAnnotation(view, annotation, modifiedData)
      } else {
        updatedAnnotation = updateImageAnnotation(annotation, modifiedData)
      }

      view.annotationManager.updateAnnotation(updatedAnnotation, {
        updatedFramesIndices: [frameIndex],
      })
      return true
    },
    undo(): boolean {
      const newPath = [...path.slice(0, index), ...path.slice(index)]
      const modifiedData = isModifyingMainPath
        ? { path: newPath, additionalPaths: annotationData.additionalPaths }
        : {
            path: annotationData.path,
            additionalPaths: [
              ...(annotationData.additionalPaths || []).slice(0, modifiedAdditionalPathIndex),
              newPath,
              ...(annotationData.additionalPaths || []).slice(modifiedAdditionalPathIndex + 1),
            ],
          }
      // un-highlight the centroid as the new vertex will be highlighted next
      annotation.centroid = undefined
      let updatedAnnotation: Annotation
      if (isVideoAnnotation(annotation)) {
        updatedAnnotation = updateVideoAnnotation(view, annotation, modifiedData)
      } else {
        updatedAnnotation = updateImageAnnotation(annotation, modifiedData)
      }
      // save the updated annotation to the BE
      view.annotationManager.updateAnnotation(updatedAnnotation, {
        updatedFramesIndices: [frameIndex],
      })
      return true
    },
  }
}
