import type { PartialRecord } from '@/core/helperTypes'
import type { MeasureRegionsPayload } from '@/modules/Editor/MeasureOverlayData'

/**
 * Defines the range of the window.
 * Note that this is distinct from the window level, which defines the
 * window in a more semantic way natural to medical imaging.
 */
export type WindowLevelRange = {
  min: number
  max: number
}

/**
 * Enumerates each of the three orthogonal planes
 */
export enum MedicalVolumePlane {
  AXIAL = 'AXIAL',
  SAGITTAL = 'SAGITTAL',
  CORONAL = 'CORONAL',
}

/**
 * Provides a map between each of the slot names on the item to
 * the displayed view of that stack.
 */
export type MedicalVolumePlaneMap = PartialRecord<string, MedicalVolumePlane>

/**
 * Enumerates the 8 possible ways to define the cordinate system of the data,
 * where:
 *
 * X direction:
 *    L === increasing X points moves to the Left
 *    R === increasing X points moves to the Right
 * Y direction:
 *    P === increasing Y points moves to the Posterior
 *    A === increasing Y points moves to the Anterior
 * Z direction:
 *    S === increasing Z points moves to the Superior
 *    I === increasing Z points moves to the Inferior
 */
export enum RAICodes {
  LPS = 'LPS', // "Standard" DICOM
  LPI = 'LPI',
  LAS = 'LAS',
  LAI = 'LAI',
  RAS = 'RAS', // "Standard" NIFTI
  RAI = 'RAI',
  RPI = 'RPI',
  RPS = 'RPS',
}

/**
 * Data specific to medical images.
 */
export type MedicalMetadata = {
  /** Affine of the volume, which is a 4x4 matrix defining the position, orientation and
   * spacing of voxels in a 3D volume, with strings as numbers
   */
  affine: string[][] | null
  /**
   * Original affine in source data before being transformed in pre-processing.
   */
  original_affine?: string[][]
  raiCode: RAICodes
  pixdims: [string, string, string]
  plane_map: MedicalVolumePlaneMap
  // These were numbers at somepoint, but are usually given as strings now.
  rescaleIntercept: string | number
  rescaleSlope: string | number
  smallestImagePixelValue: string | null
  largestImagePixelValue: string | null
  measure_regions: PartialRecord<string, MeasureRegionsPayload> | null
  is_volume: boolean
  /**
   * The frame of reference that the medical series belongs to. Series with the same
   * FrameOfReferenceUID are in the same physical space.
   */
  FrameOfReferenceUID?: string
  /**
   * The unique identifier for the series. Reconstructions of the same volume will have
   * the same SeriesInstanceUID, otherwise this should be unique per series.
   */
  SeriesInstanceUID?: string
  /**
   * A short description which tells the purpose of / the acquisition details of the series.
   */
  SeriesDescription?: string
  /**
   * A unique number within the a DICOM study which guides Radiologists to know which series were
   * taken as part of the same study and what protocol was used.
   */
  SeriesNumber?: string
  /**
   * Data extraction handler. For legacy uploads it's empty, for new files uploaded
   * without the isotropic transformation it is set to 'MONAI'. Can be used as an indicator
   * when vtk.js should apply isotropic transformation on the client or also
   * if the item thumbnail should be scaled to match the isotropic size.
   */
  handler?: string
}
