import type { MainAnnotationType } from '@/core/annotationTypes'
import type { FrameData } from '@/core/annotations'
import type { Range } from '@/modules/Editor/types'
import type { DrawCallback, IObject } from '@/modules/Editor/models/layers/types'
// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'

/**
 * Replacement for Object2D.
 *
 * The pure data type for ILayer instance to render the annotation items.
 */
export type RenderableItem = {
  id: string
  type: MainAnnotationType
  data: FrameData
  color: RGBA
}

export type RenderableItemState = {
  isSelected?: boolean
  isHighlighted?: boolean
  isLocked?: boolean
}

/**
 * Type represents the state of the annotation's vertex.
 */
export type VertexState = {
  isSelected?: boolean
  isHighlighted?: boolean
}

export type RenderableItemWithState = RenderableItem & {
  state: RenderableItemState
}

export const isRenderableItem = (obj: object): obj is RenderableItem =>
  'type' in obj && 'data' in obj

export type RTreeRenderableItem = Range & {
  id: RenderableItem['id']
}

export type RTreeObject2D = Range & {
  id: Object2D['id']
}

/**
 * @deprecated
 * We replace the Object2D with pure data type RenderableItem.
 */
export class Object2D implements IObject<CanvasRenderingContext2D, HTMLCanvasElement> {
  id: string

  constructor(
    id: string,
    /**
     * Object 2d instance render function.
     *
     * Defines the way to render objects on each render iteration.
     */
    private _render: (
      ctx: CanvasRenderingContext2D,
      canvas: HTMLCanvasElement,
      drawFn?: DrawCallback,
    ) => Path2D | void,
  ) {
    this.id = id
  }

  /**
   * Renders object 2d instance using provided render function.
   *
   * After render marks object 2d instance as unchanged.
   * @param ctx
   * @param canvas
   * @param drawFn - provides a function for an optimized draw (using an active canvas)
   */
  public render(
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    drawFn?: DrawCallback,
  ): Path2D | void {
    return this._render(ctx, canvas, drawFn)
  }
}

export const isObject2D = (obj: object): obj is Object2D => 'render' in obj
