export enum ACTIONS {
  // Messages to the worker
  SET_CONFIG = 'dataParser:SET_CONFIG',
  SET_EDITOR_CLASSES_BY_ID = 'dataParser:SET_EDITOR_CLASSES_BY_ID',
  INIT_DATA_PARSING = 'dataParser:INIT_DATA_PARSING',
  ADD_ANNOTATION = 'dataParser:ADD_ANNOTATION',
  UPDATE_ANNOTATION = 'dataParser:UPDATE_ANNOTATION',
  REMOVE_ANNOTATION = 'dataParser:REMOVE_ANNOTATION',
  REORDER_ANNOTATION = 'dataParser:REORDER_ANNOTATION',

  ITEM_DELIVERED = 'dataParser:ITEM_DELIVERED',

  // Listeners from the worker
  YIELD_PARSED_FRAME_PER_SLOT = 'dataParser:YIELD_PARSED_FRAME',
  CANCEL_ALL_PARSED_FRAME_EVENTS = 'dataParser:CANCEL_ALL_PARSED_FRAME_EVENTS',
}
