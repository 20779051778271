import cloneDeep from 'lodash/cloneDeep'

import type { EditablePoint, IPoint } from '@/modules/Editor/point'
import { pointIsVertexOfPath } from '@/modules/Editor/point'
import type { PointerEvent } from '@/core/utils/touch'
import type { Editor } from '@/modules/Editor/editor'
import { getAllAnnotationVertices } from '@/modules/Editor/getAllAnnotationVertices'
import {
  isImageAnnotation,
  isVideoAnnotation,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import { moveAnnotationVertex } from '@/modules/Editor/moveAnnotationVertex'
import { resolveModifierByPriority } from '@/modules/Editor/utils'

export const translateVertex = (
  editor: Editor,
  vertex: EditablePoint,
  offset: IPoint,
  tool: { vertexMoving?: EditablePoint },
  event: PointerEvent,
): void => {
  const { activeView } = editor

  const selectedAnnotation = editor.activeView.annotationManager.selectedAnnotation
  if (!selectedAnnotation) {
    return
  }

  // if image annotation just translate the annotation

  if (isImageAnnotation(selectedAnnotation)) {
    const path = getAllAnnotationVertices(selectedAnnotation, editor.activeView)
    if (pointIsVertexOfPath(vertex, path, 5 / editor.activeView.cameraScale)) {
      const specialKey = resolveModifierByPriority(event)
      moveAnnotationVertex(selectedAnnotation, activeView, vertex, offset, specialKey)
    }

    return
  }

  // if video annotation with loaded view, create keyframe, then translate

  if (isVideoAnnotation(selectedAnnotation) && !activeView.isLoading) {
    const inferredData = inferVideoData(selectedAnnotation, activeView.currentFrameIndex)
    const { keyframe, subs } = inferredData
    let { data: annotationData } = inferredData

    if (!annotationData) {
      return
    }

    // create a keyframe
    if (!keyframe) {
      annotationData = cloneDeep(annotationData)
      activeView.annotationManager.updateAnnotationFrame(
        selectedAnnotation,
        annotationData,
        subs,
        activeView.currentFrameIndex,
      )
      tool.vertexMoving = editor.activeView.annotationManager.findAnnotationVertexAt(vertex)
      if (tool.vertexMoving) {
        vertex = tool.vertexMoving
      }
    }

    // Build an Annotation object with current frame data, then translate it
    const currentAnnotation = shallowCloneAnnotation(selectedAnnotation, { data: annotationData })
    const path = getAllAnnotationVertices(currentAnnotation, editor.activeView)

    if (pointIsVertexOfPath(vertex, path, 5 / editor.activeView.cameraScale)) {
      const specialKey = resolveModifierByPriority(event)
      moveAnnotationVertex(currentAnnotation, activeView, vertex, offset, specialKey)
      activeView.annotationManager.updateAnnotationFrame(
        selectedAnnotation,
        currentAnnotation.data,
        subs,
        activeView.currentFrameIndex,
      )
    }
  }
}
