/**
 * Calculates the scale factor to apply to the image to fit the viewport
 */
export const getScale = (
  cameraWidth: number,
  cameraHeight: number,
  imageWidth: number,
  imageHeight: number,
): number => {
  // For image smaller than viewport, we return the scale to fill the viewport
  if (cameraWidth > imageWidth || cameraHeight > imageHeight) {
    return Math.min(cameraWidth / imageWidth, cameraHeight / imageHeight)
  }

  // For image larger than viewport, we return the scale to fit the viewport
  return Math.max(cameraWidth / imageWidth, cameraHeight / imageHeight)
}
