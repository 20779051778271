import type { DatasetPayload, ModelConfigurationPayload } from '@/store/types'
import { put } from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'
import type { ApiResult } from './types'

export type UpdateDatasetParams = {
  annotationHotkeys?: { [key: string]: string }
  annotators?: { userId: number }[]
  annotatorsCanInstantiateWorkflows?: boolean
  anyoneCanDoubleAssign?: boolean
  datasetId: number
  instructions?: string
  name?: string
  pdfFitPage?: boolean
  reviewersCanAnnotate?: boolean
  workforceManagers?: { managerId?: number; invitationId?: number; userId?: number }[]
  workPrioritization?: string
  workSize?: number
  modelConfiguration?: ModelConfigurationParams
}

export type ModelConfigurationParams = {
  /**
   * Sets the backend field
   * Sets dataset.model_configuration.oriented_bounding_box_output
   */
  autoTrackOBB: boolean
  /**
   * Sets the backend field 
   * dataset.model_configuration.frame_skip_factor
   */
  autoTrackFrameSkipFactor: number
}

type BackendParams = {
  annotation_hotkeys?: { [key: string]: string }
  annotators?: { user_id: number }[]
  annotators_can_instantiate_workflows?: boolean
  anyone_can_double_assign?: boolean
  instructions?: string
  name?: string
  pdf_fit_page?: boolean
  public?: boolean
  reviewers_can_annnotate?: boolean
  workforce_managers?: { id?: number; invitation_id?: number; user_id?: number }[]
  work_prioritization?: string
  work_size?: number
  model_configuration?: ModelConfigurationPayload
}

/**
 * Retrieves list of dataset items from the backend
 *
 * Supports pagination, filtering and ordering.
 */
export const updateDataset = async (
  params: UpdateDatasetParams,
): Promise<ApiResult<DatasetPayload>> => {
  const {
    annotationHotkeys,
    annotators,
    annotatorsCanInstantiateWorkflows,
    anyoneCanDoubleAssign,
    datasetId,
    instructions,
    name,
    pdfFitPage,
    reviewersCanAnnotate,
    workforceManagers,
    workPrioritization,
    workSize,
    modelConfiguration,
  } = params

  const path = `datasets/${datasetId}`
  const requestParams: BackendParams = {}

  if (annotators) {
    requestParams.annotators = annotators.map((a) => ({ user_id: a.userId }))
  }

  if (annotationHotkeys !== undefined) {
    requestParams.annotation_hotkeys = annotationHotkeys
  }

  if (annotatorsCanInstantiateWorkflows !== undefined) {
    requestParams.annotators_can_instantiate_workflows = annotatorsCanInstantiateWorkflows
  }

  if (anyoneCanDoubleAssign !== undefined) {
    requestParams.anyone_can_double_assign = anyoneCanDoubleAssign
  }

  if (instructions !== undefined) {
    requestParams.instructions = instructions
  }

  if (name) {
    requestParams.name = name
  }

  if (pdfFitPage !== undefined) {
    requestParams.pdf_fit_page = pdfFitPage
  }

  if (reviewersCanAnnotate !== undefined) {
    requestParams.reviewers_can_annnotate = reviewersCanAnnotate
  }

  if (workforceManagers) {
    requestParams.workforce_managers = workforceManagers.map((m) => ({
      ...(m.managerId && { manager_id: m.managerId }),
      ...(m.userId && { user_id: m.userId }),
      ...(m.invitationId && { invitation_id: m.invitationId }),
    }))
  }

  if (workPrioritization) {
    requestParams.work_prioritization = workPrioritization
  }
  if (workSize) {
    requestParams.work_size = workSize
  }

  if (modelConfiguration) {
    requestParams.model_configuration = {
      oriented_bounding_box_output: modelConfiguration.autoTrackOBB,
      frame_skip_factor: modelConfiguration.autoTrackFrameSkipFactor,
    }
  }

  try {
    const response = await put<DatasetPayload>(path, requestParams)
    return { ok: true, data: response.data }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return { ...parseError(error, errorMessages.DATASET_UPDATE), ok: false }
  }
}
