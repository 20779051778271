import omit from 'lodash/omit'
import { computed } from 'vue'

import { useTeamStore } from '@/pinia/useTeamStore'
import type { Property, TeamProperty } from '@/store/types/PropertyTypes'
import { TeamPropertyGranularity } from '@/store/types/PropertyTypes'
import type { TeamPropertyRequestBody } from '@/backend/darwin/teamProperties'
import { defineComposable } from '@/core/utils/defineComposable'

/** Used to link, update and unlink an annotation class property */
export const useAnnotationClassProperty = defineComposable(() => {
  const teamStore = useTeamStore()

  const teamSlug = computed(() => teamStore.currentTeam?.slug)

  const teamToClassProperty = (property: TeamProperty): Property => ({
    id: property.id,
    name: property.name,
    type: property.type,
    description: property.description || '',
    required: property.required,
    annotation_class_id: property.annotation_class_id,
    dataset_ids: property.dataset_ids,
    inserted_at: property.inserted_at,
    granularity: property.granularity || TeamPropertyGranularity.SECTION,
    values: property.property_values.map((propertyValue) => ({
      id: propertyValue.id,
      name: propertyValue.value,
      position: propertyValue.position,
      color: propertyValue.color || 'transparent',
    })),
  })

  const classToTeamProperties = (
    properties: Property[],
  ): Omit<TeamProperty, 'team_id' | 'slug'>[] =>
    properties.map((property) => ({
      id: property.id,
      name: property.name,
      type: property.type,
      description: property.description,
      required: property.required,
      annotation_class_id: property.annotation_class_id,
      dataset_ids: property.dataset_ids,
      inserted_at: property.inserted_at,
      granularity: property.granularity || TeamPropertyGranularity.SECTION,
      property_values: property.values.map((propertyValue) => ({
        id: propertyValue.id,
        color: propertyValue.color,
        position: propertyValue.position,
        value: propertyValue.name,
      })),
    }))

  const toTeamPropertyRequestBody = (
    property: Omit<TeamProperty, 'team_id' | 'slug'>,
  ): TeamPropertyRequestBody => ({
    name: property.name,
    type: property.type,
    required: property.required,
    annotation_class_id: property.annotation_class_id,
    dataset_ids: property.dataset_ids,
    description: property.description,
    granularity: property.granularity || TeamPropertyGranularity.SECTION,
    property_values: property.property_values?.map((v) => omit(v, 'id', 'position')) || [],
  })

  return {
    teamSlug,
    teamToClassProperty,
    classToTeamProperties,
    toTeamPropertyRequestBody,
  }
})
