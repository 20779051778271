import type { FrameData } from '@/core/annotations'
import type { AutoAnnotateData } from '@/modules/Editor/AnnotationData'

type Serialized = NonNullable<FrameData['auto_annotate']>

export const autoAnnotateSerializer = {
  serialize(data: AutoAnnotateData): Serialized {
    return {
      clicks: data.clicks || [],
      bbox: data.bbox,
      model: data.model,
    }
  },

  deserialize(rawData: Serialized): AutoAnnotateData {
    return {
      clicks: rawData.clicks,
      bbox: rawData.bbox,
      model: rawData.model,
    }
  },

  defaultData(data?: { clicker?: AutoAnnotateData }): AutoAnnotateData | null {
    if (!data || !data.clicker) {
      return null
    }
    return {
      clicks: data.clicker.clicks,
      bbox: data.clicker.bbox,
      model: data.clicker.model,
    }
  },
}
