import type { DirectionalVector } from '@/modules/Editor/AnnotationData'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import { drawVector } from '@/modules/Editor/graphics'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { DrawCallback } from '@/modules/Editor/models/layers/types'
import { calcCentroidPoint } from '@/modules/Editor/utils'
import type { View } from '@/modules/Editor/views/view'

export const renderDirectionalVector = (
  drawFn: DrawCallback,
  view: View,
  annotation: Annotation,
  filter: ImageManipulationFilter | null,
  parent: Annotation,
): void => {
  const vector = annotation.data as DirectionalVector

  const canvasCentroid = calcCentroidPoint(
    view.camera.scale,
    view.camera,
    view.currentFrameIndex,
    parent,
  )
  if (!canvasCentroid) {
    return
  }

  const centroid = view.camera.canvasViewToImageView(canvasCentroid)
  if (!centroid) {
    return
  }

  const color = { r: 255, g: 255, b: 255, a: 1.0 }

  return drawFn((ctx) => {
    drawVector(view.camera, ctx, centroid, vector, color, filter)
  })
}
