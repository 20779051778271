import { WindAuthAction } from '@/backend/darwin/WindAuthAction'
import type { ApiResult } from '@/backend/darwin/types'
import { errorMessages } from '@/backend/error'

import { withAuth } from './api'
import type { TrainingSessionPayload } from './types'

type Params = {
  teamId: number
  trainingSessionId: TrainingSessionPayload['id']
}

export const stopTrainingSession = (params: Params): Promise<ApiResult<TrainingSessionPayload>> => {
  const { teamId, trainingSessionId } = params
  const authParams = { action: WindAuthAction.TrainModels, teamId }

  const path = `training_sessions/${trainingSessionId}`

  return withAuth<TrainingSessionPayload>(
    authParams,
    (client) => client.delete<TrainingSessionPayload>(path),
    errorMessages.NEURAL_MODEL_STOP_TRAINING_SESSION,
  )
}
