import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { ApiKeyPayload } from './types'

export const useApiKeyStore = defineStore('apiKey', () => {
  const apiKeys = ref<ApiKeyPayload[]>([])

  /** Push a new or replace an existing key by id in the store */
  const pushKey = (key: ApiKeyPayload): void => {
    const index = apiKeys.value.findIndex((k) => k.id === key.id)
    if (index > -1) {
      apiKeys.value.splice(index, 1, key)
    } else {
      apiKeys.value.push(key)
    }
  }

  /** Remove key from the store, matching by id */
  const removeKey = (keyId: number): void => {
    const index = apiKeys.value.findIndex((k) => k.id === keyId)
    if (index > -1) {
      apiKeys.value.splice(index, 1)
    }
  }

  const resetState = (): void => {
    apiKeys.value = []
  }

  return {
    apiKeys,
    pushKey,
    removeKey,
    resetState,
  }
})
