import cloneDeep from 'lodash/cloneDeep'

import type { Eye } from '@/modules/Editor/AnnotationData'
import calcEyeLowerVertex from '@/modules/Editor/eye/calcEyeLowerVertex'
import type { IPoint } from '@/modules/Editor/point'
import { pointIsVertexOfPath } from '@/modules/Editor/point'
import type { EditablePoint } from '@/modules/Editor/point'
import type { PointerEvent } from '@/core/utils/touch'
import type { Editor } from '@/modules/Editor/editor'
import { getAllAnnotationVertices } from '@/modules/Editor/getAllAnnotationVertices'
import {
  isImageAnnotation,
  isVideoAnnotation,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import { moveAnnotationVertex } from '@/modules/Editor/moveAnnotationVertex'
import { resolveModifierByPriority } from '@/modules/Editor/utils'
import { isEye } from '@/modules/Editor/annotationTypes/eye'

const translateLowerVertex = (eyeData: Eye, vertices: EditablePoint[]): void => {
  const newPos = calcEyeLowerVertex(eyeData.nodes)
  const lowerVertex = vertices.find((vertex) =>
    eyeData.nodes.find(
      (eyeVertex) =>
        eyeVertex.point.x === vertex.x &&
        eyeVertex.point.y === vertex.y &&
        eyeVertex.name === 'lower',
    ),
  )
  if (lowerVertex) {
    lowerVertex.x = newPos.x
    lowerVertex.y = newPos.y
  }
}

const translateVertex = (
  editor: Editor,
  vertexIndex: number,
  offset: IPoint,
  event: PointerEvent,
): void => {
  const { activeView } = editor

  const selectedAnnotation = editor.activeView.annotationManager.selectedAnnotation
  if (!selectedAnnotation) {
    return
  }

  const vertices = getAllAnnotationVertices(selectedAnnotation, activeView)

  const vertex = vertices[vertexIndex]
  const vertexThreshold = 5 / editor.activeView.cameraScale

  if (!pointIsVertexOfPath(vertex, vertices, vertexThreshold)) {
    return
  }

  const specialKey = resolveModifierByPriority(event)

  // if video annotation, create an annotation keyframe
  // Otherwise just translate the annotation

  if (isImageAnnotation(selectedAnnotation)) {
    if (!isEye(selectedAnnotation.data)) {
      return
    }

    moveAnnotationVertex(selectedAnnotation, activeView, vertex, offset, specialKey)
    translateLowerVertex(selectedAnnotation.data, vertices)
    return
  }

  if (isVideoAnnotation(selectedAnnotation) && !activeView.isLoading) {
    const inferredData = inferVideoData(selectedAnnotation, activeView.currentFrameIndex)
    const { keyframe, subs } = inferredData
    let { data: annotationData } = inferredData

    if (!annotationData) {
      return
    }

    // If it's not yet a key frame, make it a key frame
    // Then we need to relocate the vertex since cloneDeep breaks the old link.
    if (!keyframe) {
      annotationData = cloneDeep(annotationData)
      activeView.annotationManager.updateAnnotationFrame(
        selectedAnnotation,
        annotationData,
        subs,
        activeView.currentFrameIndex,
      )
    }
    if (!isEye(annotationData)) {
      return
    }

    // Build an Annotation object with current frame data, then translate it
    const currentAnnotation = shallowCloneAnnotation(selectedAnnotation, { data: annotationData })

    moveAnnotationVertex(currentAnnotation, activeView, vertex, offset, specialKey)
    translateLowerVertex(annotationData, vertices)
    activeView.annotationManager.updateAnnotationFrame(
      selectedAnnotation,
      currentAnnotation.data,
      subs,
      activeView.currentFrameIndex,
    )
  }
}

export default translateVertex
