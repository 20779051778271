import type { ViewEvent } from '@/modules/Editor/eventBus'
import { CameraEvents, ViewEvents } from '@/modules/Editor/eventBus'
import {
  clearReferenceLines,
  renderReferenceLines,
} from '@/modules/Editor/utils/referenceLines/renderReferenceLines'
import { DicomView } from '@/modules/Editor/views/dicomView'

import { Layer, Events as LayerEvents } from './layer'

/**
 * Manages rendering process for rasters independnetly of other
 * other annotations.
 *
 * Still has support for `Object2D` annotations that may come up
 * as subannotations for Rasters in the future.
 */
export class ReferenceLinesLayer extends Layer {
  private view: DicomView
  private _renderOtherViewports = false
  private displayReferenceLines = true

  constructor(view: DicomView) {
    super()

    this.view = view
  }

  /**
   * Binds to relevant camera and view events in order to
   * trigger re-rendering of the layer.
   *
   * Must be a separate init function as doing it in constructor
   * would thrigger the handler before relevant parts of the editor
   * are fully setup.
   */
  public init(): void {
    // On any camera change + frame change, update reference lines
    ViewEvents.currentFrameIndexChanged.on(this.triggerRerender)
    CameraEvents.scaleChanged.on(this.triggerRerender)
    CameraEvents.offsetChanged.on(this.triggerRerender)
  }

  private triggerRerender = (e: ViewEvent): void => {
    if (e.viewId !== this.view.id) {
      return
    }

    this._hasChanges = true
    this._renderOtherViewports = true
    this.render()
  }

  /**
   * Renders groups of mask annotations to single rasters in one draw call,
   * using the `Annotation` data to style the rendering of different
   * portions of the raster.
   *
   * If the layer has no changes it will skip the re-render.
   */
  public render(): void {
    if (
      !this.context ||
      !this._hasChanges ||
      !this.view.isRadiologicalVolumeView ||
      !this.displayReferenceLines
    ) {
      return
    }

    this._hasChanges = false

    this.emit(LayerEvents.BEFORE_RENDER, this.context, this.canvas)

    const { view } = this
    const { editor } = view

    // Render this viewport
    renderReferenceLines(view, this)

    // Optionally render other viewports
    if (this._renderOtherViewports) {
      const thisViewId = view.id

      const { viewsList } = editor

      viewsList.forEach((view) => {
        if (view.id !== thisViewId && view instanceof DicomView) {
          view.referenceLinesLayer?.changed()
          view.referenceLinesLayer?.render()
        }
      })

      this._renderOtherViewports = false
    }

    this.emit(LayerEvents.RENDER, this.context, this.canvas)
  }

  public toggleReferenceLines(): void {
    const { view } = this

    this.displayReferenceLines = !this.displayReferenceLines

    if (this.displayReferenceLines) {
      renderReferenceLines(view, this)
    } else {
      clearReferenceLines(this)
    }
  }

  destroy(): void {
    super.destroy()

    CameraEvents.scaleChanged.off(this.triggerRerender)
    CameraEvents.offsetChanged.off(this.triggerRerender)
    ViewEvents.currentFrameIndexChanged.off(this.triggerRerender)
  }
}
