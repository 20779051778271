import { MainAnnotationType } from '@/core/annotationTypes'
import type { EyeData, FrameData } from '@/core/annotations'
import calcPathFromBBox from '@/modules/Editor/bbox/calcPathFromBBox'
import projectPointToBorder from '@/modules/Editor/geometry/projectPointToBorder'
import type { IPoint } from '@/modules/Editor/point'
import { ellipseSerializer } from '@/modules/Editor/serialization/ellipseSerializer'
import type { RenderingContext2D } from '@/modules/Editor/types'
import { drawVertex } from '@/modules/Editor/graphicsV2/drawVertex'
import type { VertexState } from '@/modules/Editor/models/layers/object2D'
// eslint-disable-next-line boundaries/element-types
import { rgbaString } from '@/uiKit/colorPalette'

import { drawPath } from './drawPath'
import { compoundToFlatPath } from '@/modules/Editor/utils/compoundToFlatPath'

type ReturnType = {
  // We use compoundPath to track annotation in general
  compoundPath: Path2D
  // verticesMap helps to track each individual vertex
  verticesMap: Map<number, Path2D>
}

type PropsType = {
  fillColor?: string
  strokeColor?: string
  lineWidth: number
  pointSize: number
  isSelected?: boolean
  isHighlighted?: boolean
}
export const OCCLUDED_COLOR = { r: 100, g: 100, b: 100, a: 1 }

const drawEllipseVertices = (
  ctx: RenderingContext2D,
  data: FrameData,
  props: PropsType,
  activeVertices?: Map<number, VertexState>,
): ReturnType | undefined => {
  if (!data.ellipse) {
    return
  }

  const compoundPath = new Path2D()
  const verticesMap = new Map()

  if (props.isSelected) {
    const serializedEllipse = ellipseSerializer.deserialize(data)

    const vertices = serializedEllipse
      ? [
          serializedEllipse.top,
          serializedEllipse.right,
          serializedEllipse.bottom,
          serializedEllipse.left,
        ]
      : []

    vertices.forEach((point, index) => {
      const activeVertex = activeVertices?.get(index)

      const path2D = drawVertex(ctx, point, {
        strokeColor: props.strokeColor,
        pointSize: props.pointSize,
        fillColor: activeVertex?.isSelected ? props.fillColor : undefined,
        isSelected: activeVertex?.isSelected,
        isHighlighted: activeVertex?.isHighlighted,
        lineWidth: props.lineWidth,
      })

      compoundPath.addPath(path2D)
      verticesMap.set(index, path2D)
    })
  }

  return {
    compoundPath,
    verticesMap,
  }
}

const drawBoundingBoxVertices = (
  ctx: RenderingContext2D,
  data: FrameData,
  props: PropsType,
  activeVertices?: Map<number, VertexState>,
): ReturnType | undefined => {
  if (!data.bounding_box) {
    return
  }

  const compoundPath = new Path2D()
  const verticesMap = new Map()

  const path = calcPathFromBBox(data.bounding_box)

  if (props.isSelected) {
    path.forEach((point, index) => {
      const activeVertex = activeVertices?.get(index)

      const path2D = drawVertex(ctx, point, {
        strokeColor: props.strokeColor,
        pointSize: props.pointSize,
        fillColor: activeVertex?.isSelected ? props.fillColor : undefined,
        isSelected: activeVertex?.isSelected,
        isHighlighted: activeVertex?.isHighlighted,
        lineWidth: props.lineWidth,
      })

      compoundPath.addPath(path2D)
      verticesMap.set(index, path2D)
    })
  }

  return {
    compoundPath,
    verticesMap,
  }
}

const drawPolygonVertices = (
  ctx: RenderingContext2D,
  data: FrameData,
  props: PropsType,
  activeVertices?: Map<number, VertexState>,
): ReturnType | undefined => {
  if (!data.polygon) {
    return
  }

  const compoundPath = new Path2D()
  const verticesMap = new Map()

  if (props.isSelected) {
    compoundToFlatPath(data.polygon).forEach((point: IPoint, index: number): void => {
      const activeVertex = activeVertices?.get(index)

      const path2D = drawVertex(
        ctx,
        { ...point },
        {
          strokeColor: props.strokeColor,
          pointSize: props.pointSize,
          fillColor: activeVertex?.isSelected ? props.fillColor : undefined,
          isSelected: activeVertex?.isSelected,
          isHighlighted: activeVertex?.isHighlighted,
          lineWidth: props.lineWidth,
        },
      )

      compoundPath.addPath(path2D)
      verticesMap.set(index, path2D)
    })
  }

  return {
    compoundPath,
    verticesMap,
  }
}

const drawLineVertices = (
  ctx: RenderingContext2D,
  data: FrameData,
  props: PropsType,
  activeVertices?: Map<number, VertexState>,
): ReturnType | undefined => {
  if (!data.line) {
    return
  }

  const compoundPath = new Path2D()
  const verticesMap = new Map()

  if (props.isSelected) {
    data.line.path.forEach((point, index) => {
      const activeVertex = activeVertices?.get(index)

      const path2D = drawVertex(ctx, point, {
        strokeColor: props.strokeColor,
        pointSize: props.pointSize,
        fillColor: activeVertex?.isSelected ? props.fillColor : undefined,
        isSelected: activeVertex?.isSelected,
        isHighlighted: activeVertex?.isHighlighted,
        lineWidth: props.lineWidth,
      })

      compoundPath.addPath(path2D)
      verticesMap.set(index, path2D)
    })
  }

  return {
    compoundPath,
    verticesMap,
  }
}

export const drawSkeletonVertices = (
  ctx: RenderingContext2D,
  data: FrameData,
  props: PropsType,
  activeVertices?: Map<number, VertexState>,
): ReturnType | undefined => {
  if (!data.skeleton) {
    return
  }

  const compoundPath = new Path2D()
  const verticesMap = new Map()

  data.skeleton.nodes.forEach((point, index) => {
    const activeVertex = activeVertices?.get(index)

    const strokeColor = point.occluded ? rgbaString(OCCLUDED_COLOR) : props.strokeColor
    let fillColor
    if (activeVertex?.isSelected) {
      fillColor = props.fillColor
    } else if (point.occluded) {
      fillColor = rgbaString(OCCLUDED_COLOR)
    }

    const path2D = drawVertex(ctx, point, {
      pointSize: props.pointSize,
      strokeColor,
      fillColor,
      isSelected: activeVertex?.isSelected,
      isHighlighted: activeVertex?.isHighlighted,
      lineWidth: props.lineWidth,
    })

    compoundPath.addPath(path2D)
    verticesMap.set(index, path2D)
  })

  return {
    compoundPath,
    verticesMap,
  }
}

export const drawEyeVertices = (
  ctx: RenderingContext2D,
  data: FrameData,
  props: PropsType,
  activeVertices?: Map<number, VertexState>,
): ReturnType | undefined => {
  if (!data.eye) {
    return
  }

  const compoundPath = new Path2D()
  const verticesMap = new Map()

  data.eye.nodes.forEach((point, index) => {
    const activeVertex = activeVertices?.get(index)

    const strokeColor = point.occluded ? rgbaString(OCCLUDED_COLOR) : props.strokeColor
    let fillColor
    if (activeVertex?.isSelected) {
      fillColor = props.fillColor
    } else if (point.occluded) {
      fillColor = rgbaString(OCCLUDED_COLOR)
    }

    const path2D = drawVertex(ctx, point, {
      pointSize: props.pointSize,
      strokeColor,
      fillColor,
      isSelected: activeVertex?.isSelected,
      isHighlighted: activeVertex?.isHighlighted,
      lineWidth: props.lineWidth,
    })

    compoundPath.addPath(path2D)
    verticesMap.set(index, path2D)
  })

  return {
    compoundPath,
    verticesMap,
  }
}

const drawEyeGuideline = (
  ctx: RenderingContext2D,
  data: FrameData,
  props: PropsType,
): ReturnType | undefined => {
  if (!data.eye) {
    return
  }

  const eyeNodes: EyeData['nodes'] = data.eye.nodes
  const upperNode = eyeNodes.find((node) => node.name === 'upper')
  const lowerNode = eyeNodes.find((node) => node.name === 'lower')
  if (upperNode && lowerNode) {
    const startPoint = projectPointToBorder(
      upperNode,
      lowerNode,
      0,
      ctx.canvas.width,
      ctx.canvas.height,
      0,
    )
    const endPoint = projectPointToBorder(
      lowerNode,
      upperNode,
      0,
      ctx.canvas.width,
      ctx.canvas.height,
      0,
    )
    const lineDash = Math.round(props.pointSize) * 2
    ctx.setLineDash([lineDash, lineDash * 2])
    drawPath(ctx, {
      lineWidth: props.lineWidth * 2,
      fillColor: 'rgb(255, 255, 255)',
      strokeColor: 'rgb(255, 255, 255)',
      path: [startPoint, endPoint],
    })
    ctx.setLineDash([])
  }
}

const drawTableVertices = (
  ctx: RenderingContext2D,
  data: FrameData,
  props: PropsType,
  activeVertices?: Map<number, VertexState>,
): ReturnType | undefined => {
  if (!data.simple_table) {
    return
  }

  const compoundPath = new Path2D()
  const verticesMap = new Map()

  const path = calcPathFromBBox(data.simple_table.bounding_box)

  if (props.isSelected) {
    path.forEach((point, index) => {
      const activeVertex = activeVertices?.get(index)

      const path2D = drawVertex(ctx, point, {
        strokeColor: props.strokeColor,
        pointSize: props.pointSize,
        fillColor: activeVertex?.isSelected ? props.fillColor : undefined,
        isSelected: activeVertex?.isSelected,
        isHighlighted: activeVertex?.isHighlighted,
        lineWidth: props.lineWidth,
      })

      compoundPath.addPath(path2D)
      verticesMap.set(index, path2D)
    })
  }

  return {
    compoundPath,
    verticesMap,
  }
}

/**
 * Calculates the position and draw vertices by Annotation type
 * @param ctx
 * @param type
 * @param  {FrameData} data - we use data to calculate the positions of the vertices
 * @param props
 * @param activeVertices - the key/value pair that defines active vertices and its state
 * @returns {ReturnType}
 */
export const drawVerticesByType = (
  ctx: RenderingContext2D,
  type: MainAnnotationType,
  data: FrameData,
  props: PropsType,
  activeVertices?: Map<number, VertexState>,
): ReturnType | undefined => {
  if (type === MainAnnotationType.Ellipse) {
    return drawEllipseVertices(ctx, data, props, activeVertices)
  }

  if (type === MainAnnotationType.BoundingBox) {
    return drawBoundingBoxVertices(ctx, data, props, activeVertices)
  }

  if (type === MainAnnotationType.Polygon) {
    return drawPolygonVertices(ctx, data, props, activeVertices)
  }

  if (type === MainAnnotationType.Polyline) {
    return drawLineVertices(ctx, data, props, activeVertices)
  }

  if (type === MainAnnotationType.Skeleton) {
    return drawSkeletonVertices(ctx, data, props, activeVertices)
  }

  if (type === MainAnnotationType.Eye) {
    let shouldDrawGuideline = false
    activeVertices?.forEach((vertex) => {
      if (vertex.isSelected) {
        shouldDrawGuideline = true
      }
    })
    if (shouldDrawGuideline) {
      drawEyeGuideline(ctx, data, props)
    }
    return drawEyeVertices(ctx, data, props, activeVertices)
  }

  if (type === MainAnnotationType.SimpleTable) {
    return drawTableVertices(ctx, data, props, activeVertices)
  }
}
