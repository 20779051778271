import type { View } from '@/modules/Editor/views/view'

import type { Bounds, BoundsPerLabelIndex, RasterDataSnapshot } from './Raster'
import { Raster } from './Raster'
import type { RasterBufferAccessor } from './RasterBufferAccessor'
import { RasterTypes } from './rasterTypes'
import { encodeDenseRLE } from '@/modules/Editor/plugins/mask/rle/denseRle'

/**
 * An object which contains the raw data used to display a raster mask.
 * Each raster has its own id and references a particular file to which
 * it is associated. The size of the raster is automatically derived
 * from the file it is associated with.
 */
export class ImageRaster extends Raster {
  public readonly type: RasterTypes = RasterTypes.IMAGE
  private buffer: Uint8Array
  private boundsPerLabelIndex: BoundsPerLabelIndex = {}

  constructor(view: View) {
    super(view)

    this.buffer = new Uint8Array(this.size)
  }

  public getLabelRange(): [number, number] {
    return [0, 0]
  }

  /**
   * For serialization, we need the actual buffer. For all other uses in app,
   * we should use the rasterBufferAccessors provided by getActiveBufferForRender
   * and getActiveBufferForEdit.
   */
  public getBufferForSerialization(): Uint8Array {
    return this.buffer
  }

  public setBoundsForLabelIndex(labelIndex: number, bounds: Bounds): void {
    this.boundsPerLabelIndex[labelIndex] = bounds
  }

  public getBoundsForLabelIndex(labelIndex: number): Bounds {
    return this.boundsPerLabelIndex[labelIndex]
  }

  protected deleteBoundsForLabelIndex(labelIndex: number): void {
    delete this.boundsPerLabelIndex[labelIndex]
  }

  public getActiveBufferForEdit(): RasterBufferAccessor {
    const { buffer, width, height } = this

    return {
      get(x: number, y: number): number {
        return buffer[y * width + x]
      },
      set(x: number, y: number, val: number): void {
        buffer[y * width + x] = val
      },
      width,
      height,
    }
  }

  /**
   * For ImageRasters there is only ever one buffer, so this
   * is just pass through to satisfy the generic interface.
   */
  public getActiveBufferForRender(): RasterBufferAccessor {
    return this.getActiveBufferForEdit()
  }

  public setActiveBuffer(buffer: Uint8Array): void {
    this.buffer = buffer
  }

  /**
   * Takes a snapshot of the current state of the Raster.
   * This is only returning the snapshot, not setting it in any way.
   * Include the buffer, and data mappings to link annotations, labels and classes
   */
  public takeSnapshot(): RasterDataSnapshot {
    const snapshotData: RasterDataSnapshot = {
      denseRLE: encodeDenseRLE(this.getBufferForSerialization()),
      maskAnnotationIdsMapping: structuredClone(this.getAnnotationToLabelMapping()),
      maskAnnotationClassIdsMapping: structuredClone(this.getAnnotationIdToClassIdMapping()),
      labelIndexToClassIdMapping: structuredClone(this.getLabelIndexToClassIdMapping()),
      labelIndexToAnnotationIdMapping: structuredClone(this.getLabelIndexToAnnotationMapping()),
    }
    return snapshotData
  }

  /**
   * When clearing the mappings, we also need to clear the bounds as they would be linked
   * to labels that are potentially no longer mapped
   * TODO: Can `cleanup` be used instead? To be checked with
   * https://linear.app/v7labs/issue/DAR-1879/add-create-mask-action-function-for-image-rasters
   */
  clearAnnotationMappings(): void {
    super.clearAnnotationMappings()
    this.boundsPerLabelIndex = {}
  }

  public deleteLabelFromRaster(labelIndex: number): void {
    const bounds = this.getBoundsForLabelIndex(labelIndex)
    const mask = this.getActiveBufferForEdit()

    const { width, height } = this

    // TEMP
    const otherMasks: Set<number> = new Set()

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const pixelValue = mask.get(x, y)
        if (pixelValue !== 0) {
          otherMasks.add(pixelValue)
        }
      }
    }

    let xMin = 0
    let xMax = width - 1
    let yMin = 0
    let yMax = height - 1

    if (bounds) {
      xMin = bounds.topLeft.x
      xMax = bounds.bottomRight.x
      yMin = bounds.topLeft.y
      yMax = bounds.bottomRight.y
    }

    for (let y = yMin; y <= yMax; y++) {
      for (let x = xMin; x <= xMax; x++) {
        if (mask.get(x, y) === labelIndex) {
          mask.set(x, y, 0)
        }
      }
    }

    this.deleteBoundsForLabelIndex(labelIndex)
    this.deleteAnnotationMapping(labelIndex)
    this.invalidate(xMin, xMax, yMin, yMax)
  }

  cleanup(): void {
    // This will drop the reference and be garbage collected soon when the manager drops
    // the reference but making a new empty array means we don't need to make this
    // property optional.
    this.buffer = new Uint8Array(0)
    this.boundsPerLabelIndex = {}

    this.cachedCanvas?.remove()
    delete this.cachedCanvas

    this.snapshot = undefined
  }
}
