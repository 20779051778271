import { post } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

type Params =
  | {
      email: string
      password: string
      token: string
    }
  | {
      access_token: string
      token: string
    }

export const confirm2fa = async (params: Params): Promise<ParsedError | { data: unknown }> => {
  const path = 'users/confirm_2fa'

  try {
    const response = await post<unknown>(path, params)
    return { data: response.data }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.AUTH_CONFIRM_2FA)
  }
}
