import EventEmitter from 'events'

import type { CallbackHandle } from '@/modules/Editor/callbackHandler'
import { CameraEvents } from '@/modules/Editor/eventBus'
import type { Object2D, RenderableItem } from '@/modules/Editor/models/layers/object2D'
import { isObject2D } from '@/modules/Editor/models/layers/object2D'
import type { DrawFn } from '@/modules/Editor/models/layers/types'
import { setContext } from '@/services/sentry'

import type { ILayer } from './types'

export enum Events {
  RENDER = 'render',
  BEFORE_RENDER = 'before:render',
  RENDERED = 'before:rendered',
}

/**
 * Accumulates items for render.
 *
 * Manages rendering process of each item.
 */
export class Layer
  extends EventEmitter
  implements ILayer<CanvasRenderingContext2D, HTMLCanvasElement>
{
  private _destroyed: boolean = false
  protected _canvas: HTMLCanvasElement | null
  private _context: CanvasRenderingContext2D | null
  protected _hasChanges: boolean = false

  protected _renderPool: Map<string, Object2D> = new Map()

  constructor(name?: string) {
    super()
    this._canvas = document.createElement('canvas')
    if (name) {
      this._canvas.classList.add(name)
      this._canvas.setAttribute('data-test', name)
    }
    this._context = this._canvas.getContext('2d')

    // Layer itself defines when to re-render
    // for panning or scaling.
    CameraEvents.scaleChanged.on(this.cameraHandler)
    CameraEvents.offsetChanged.on(this.cameraHandler)
  }

  public setKeyForNextRender(): void {
    throw new Error('Method not implemented.')
  }

  private cameraHandler = (): void => {
    this.changed()
  }

  public changedDebounce(): void {}
  public activate(): void {}
  public updateItemState(): void {}
  public deactivate(): void {}
  public reorderAnnotation(): void {}
  public hitItemRegion(): Promise<Object2D['id'] | undefined> {
    return Promise.resolve(undefined)
  }
  public hitVertexRegion(): number | undefined {
    return
  }
  public hitItemStroke(): Promise<string | undefined> {
    return Promise.resolve(undefined)
  }
  public setFilters(): void {}
  public activateVertexWithState(): void {}
  public unhighlightAllVertices(): void {}
  public deactivateVertex(): void {}
  public clearDrawingCanvas(): void {
    if (!this.context) {
      return
    }
    if (!this.canvas) {
      return
    }
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
  }
  public isHidden(): boolean {
    return false
  }
  public show(): void {}
  public showAll(): void {}
  public hide(): void {}
  public hideAll(): void {}

  public hideLayer(): void {
    if (!this.canvas) {
      return
    }
    this.canvas.style.display = 'none'
  }

  public showLayer(): void {
    if (!this.canvas) {
      return
    }
    this.canvas.style.display = 'initial'
  }

  /**
   * Public interface that manages drawing on canvas.
   */
  public draw(fn?: DrawFn): void {
    if (!this.context) {
      return
    }
    if (!this.canvas) {
      return
    }

    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    // ctx.save & ctx.restore encapsulates canvases context
    // changes inside the callback function (fn)
    this.context.save()

    // Callback function gets context, canvas and draw function
    fn?.(
      this.context,
      this.canvas,
      // Draw function provides context and canvas
      // U can use drawFn to provide for the draw* functions
      (drawFn?: DrawFn): void => {
        if (!this.context) {
          return
        }
        if (!this.canvas) {
          return
        }

        drawFn?.(this.context, this.canvas, undefined, true)
      },
    )

    this.context.restore()
  }

  public get element(): DocumentFragment {
    const frag = document.createDocumentFragment()
    this.canvas && frag.appendChild(this.canvas)
    return frag
  }

  public get context(): CanvasRenderingContext2D | null {
    return this._context
  }

  public get canvas(): HTMLCanvasElement | null {
    return this._canvas
  }

  /**
   * Add new object to the render pool.
   */
  add(payload: Object2D | RenderableItem): void
  add(payload: Object2D[] | RenderableItem[]): void
  add(payload: Object2D | Object2D[] | RenderableItem | RenderableItem[]): void {
    const items = Array.isArray(payload) ? payload : [payload]

    this._hasChanges = true

    items.forEach((item) => {
      if (!isObject2D(item)) {
        return
      }
      this._renderPool.set(item.id, item)
    })
  }

  replaceAll(): void {
    throw new Error('Method not implemented.')
  }
  replaceAllWithParsedData(): void {
    throw new Error('Method not implemented.')
  }

  update(): void {}

  /**
   * Remove object from the render pool by its id.
   */
  delete(id: string): void {
    this._hasChanges = true

    this._renderPool.delete(id)
  }

  /**
   * Returns object by its id.
   */
  getItem(id: string): Object2D {
    const item = this._renderPool.get(id)
    if (!item) {
      throw new Error("Can't get object by id!")
    }

    return item
  }

  getAll(): { key: string; item: Object2D }[] {
    const res = []
    for (const key of this._renderPool.keys()) {
      const item = this._renderPool.get(key)
      if (!item) {
        continue
      }

      res.push({
        key,
        item,
      })
    }
    return res
  }

  /**
   * Checks if the object with id exists on the layer.
   */
  has(id: string): boolean {
    return !!this._renderPool.has(id)
  }

  /**
   * Renders each item in the pool.
   *
   * If the layer has no changes it will skip re-render iteration.
   */
  render(): void {
    if (this._destroyed) {
      return
    }

    if (!this.context) {
      return
    }
    if (!this.canvas) {
      return
    }
    if (!this._hasChanges) {
      return
    }

    this._hasChanges = false

    this.emit(Events.BEFORE_RENDER, this.context, this.canvas)

    for (const item of this._renderPool.values()) {
      try {
        item.render(this.context, this.canvas, (drawFn?: DrawFn): void => {
          if (!this.context) {
            return
          }
          if (!this.canvas) {
            return
          }
          drawFn?.(this.context, this.canvas, undefined, true)
        })
      } catch (e) {
        setContext('error', { error: e })
        console.error('V2 Base layer failed to render object')
      }
    }

    this.emit(Events.RENDER, this.context, this.canvas)
  }

  /**
   * Marks layer as changed.
   *
   * So it will be redrawn during the next render iteration.
   */
  changed(): void {
    this._hasChanges = true
  }

  clear(): void {
    this._renderPool.clear()
    this._hasChanges = true
  }

  destroy(): void {
    CameraEvents.scaleChanged.off(this.cameraHandler)
    CameraEvents.offsetChanged.off(this.cameraHandler)
    this._destroyed = true
    this.clear()
    this._canvas?.remove()
    this._canvas = null
    this._context = null
    this.removeAllListeners(Events.RENDER)
    this.removeAllListeners(Events.BEFORE_RENDER)
  }

  onRender(cb: (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => void): CallbackHandle {
    this.on(Events.RENDER, cb)

    return {
      id: this.listenerCount(Events.RENDER),
      release: this.off.bind(this, Events.RENDER, cb),
    }
  }

  onBeforeRender(
    cb: (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => void,
  ): CallbackHandle {
    this.on(Events.BEFORE_RENDER, cb)

    return {
      id: this.listenerCount(Events.BEFORE_RENDER),
      release: this.off.bind(this, Events.BEFORE_RENDER, cb),
    }
  }

  onRendered(cb: (renderKey: string | null) => void): CallbackHandle {
    this.on(Events.RENDERED, cb)

    return {
      id: this.listenerCount(Events.RENDERED),
      release: this.off.bind(this, Events.RENDERED, cb),
    }
  }
}
