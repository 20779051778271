import type { Editor } from '@/modules/Editor/editor'
import { clipboardCommandAltPaste } from './clipboardCommandAltPaste'
import { clipboardCommandCopy, clipboardCommandCut } from './clipboardCommandCopy'
import { clipboardCommandPaste } from './clipboardCommandPaste'
import { clipboardCommandAltShiftPaste } from './clipboardCommandAltShiftPaste'
import { clipboardCommandShiftPaste } from './clipboardCommandShiftPaste'
import { defineComposable } from '@/core/utils/defineComposable'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { VideoAnnotationDataPayload } from '@/modules/Editor/types'
import { EditorEvents } from '@/modules/Editor/eventBus'

export type ClipboardData = {
  clipboardAnnotation: Annotation | undefined
  sourceAnnotationId: string | undefined
  videoAnnotationData: VideoAnnotationDataPayload
  inferredVideoAnnotationData: VideoAnnotationDataPayload
}

const createClipboardData = (): ClipboardData => ({
  clipboardAnnotation: undefined,
  sourceAnnotationId: undefined,
  videoAnnotationData: {
    data: {},
    subs: [],
    keyframe: false,
    subkeyframe: false,
    interpolateAlgorithm: 'linear-1.1',
  },
  inferredVideoAnnotationData: {
    data: {},
    subs: [],
    keyframe: false,
    subkeyframe: false,
    interpolateAlgorithm: 'linear-1.1',
  },
})

const data = createClipboardData()

export const useClipboardInteractions = defineComposable((editor: Editor) => {
  const copy = (): void => {
    const payload = clipboardCommandCopy(editor.activeView)

    if (!payload) {
      return
    }

    const {
      clipboardAnnotation,
      sourceAnnotationId,
      videoAnnotationData,
      inferredVideoAnnotationData,
    } = payload

    if (videoAnnotationData && inferredVideoAnnotationData) {
      data.videoAnnotationData = videoAnnotationData
      data.inferredVideoAnnotationData = inferredVideoAnnotationData
    }

    data.sourceAnnotationId = sourceAnnotationId
    data.clipboardAnnotation = clipboardAnnotation

    EditorEvents.copyOrCut.emit({ annotationId: sourceAnnotationId })
  }

  const cut = (): void => {
    const { clipboardAnnotation, videoAnnotationData } =
      clipboardCommandCut(editor.activeView) || {}

    if (videoAnnotationData) {
      data.videoAnnotationData = videoAnnotationData
    }

    data.clipboardAnnotation = clipboardAnnotation
  }

  const paste = (): void => clipboardCommandPaste(editor, data)
  const shiftPaste = (): void => clipboardCommandShiftPaste(editor, data)
  const altPaste = (): void => clipboardCommandAltPaste(editor, data)
  const altShiftPaste = (): void => clipboardCommandAltShiftPaste(editor, data)

  return { copy, cut, paste, shiftPaste, altPaste, altShiftPaste }
})
