import type { FrameData } from '@/core/annotations'
import type { DirectionalVector } from '@/modules/Editor/AnnotationData'

export const directionalVectorSerializer = {
  serialize(data: DirectionalVector): FrameData {
    return {
      directional_vector: {
        angle: data.angle,
        length: data.length,
      },
    }
  },

  deserialize(rawData: FrameData): Exclude<FrameData['directional_vector'], undefined> | null {
    if (!rawData.directional_vector) {
      return null
    }

    return {
      angle: rawData.directional_vector.angle,
      length: rawData.directional_vector.length,
    }
  },
}
