import type { AxiosResponse } from 'axios'

import type { TeamProperty, TeamPropertyValue } from '@/store/types/PropertyTypes'
import { get, post, put, remove } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'
import { sendV2Commands } from '@/backend/darwin/sendV2Commands'
import type { ApiResponse } from '@/store/types'
import type { V2WorkflowCommandResponse } from '@/store/types/V2WorkflowCommandResponse'
import type { PartialRecord } from '@/core/helperTypes'
import type { ApiResult } from './types'

export const getBaseUrl = (teamSlug: string): string =>
  `/v2/teams/${teamSlug}/properties?include_values=true`
export const getIdUrl = (teamSlug: string, id: string): string =>
  `/v2/teams/${teamSlug}/properties/${id}`

export type DatasetItemProperties = PartialRecord<TeamProperty['id'], DatasetItemProperty>

export type DatasetItemPropertyActor = {
  model_id: string | undefined
  role: string
  user_id: number
}

export type DatasetItemPropertyId = TeamProperty['id']

export type DatasetItemPropertyValues =
  | TeamProperty['property_values'][0]['id'][]
  | [{ text: string }]

export type DatasetItemProperty = {
  actors: DatasetItemPropertyActor[]
  id: DatasetItemPropertyId
  values: DatasetItemPropertyValues
}

export type DatasetItemPropertiesResponse = {
  properties: DatasetItemProperty[]
}

type GetPropertiesResponse = {
  properties: TeamProperty[]
}

const isTextPropertyValue = (value: string | { text: string }): value is { text: string } =>
  typeof value === 'object' && 'text' in value

export const isDatasetItemTextPropertyValue = (
  value: DatasetItemPropertyValues,
): value is [{ text: string }] =>
  Array.isArray(value) && value.length === 1 && isTextPropertyValue(value[0])

export const isDatasetItemPredefinedPropertyValues = (
  value: DatasetItemPropertyValues,
): value is string[] => Array.isArray(value) && !value.some(isTextPropertyValue)

export const getTeamProperties = async (
  teamSlug: string,
): Promise<ApiResult<GetPropertiesResponse>> => {
  try {
    const response = await get<GetPropertiesResponse>(getBaseUrl(teamSlug))
    return { ok: true, data: response.data }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return { ...parseError(error, errorMessages.PROPERTIES_LOAD), ok: false }
  }
}

export type TeamPropertyRequestBody = Pick<
  TeamProperty,
  'name' | 'description' | 'type' | 'required' | 'annotation_class_id' | 'dataset_ids'
> & {
  property_values: Omit<TeamPropertyValue, 'id' | 'position'>[]
  granularity?: TeamProperty['granularity']
}

export type TeamPropertyUpdateRequestBody = Omit<
  TeamPropertyRequestBody,
  'annotation_class_id' | 'type' | 'granularity'
>

// Frame index for a property can be `number` as normal, or the string `global` when granularity
// it set to `annotation`
export const STATIC_PROPERTY_FRAME_NAME = 'global'
export type TeamPropertyFrameIndex = number | typeof STATIC_PROPERTY_FRAME_NAME

export const createTeamProperty = async (
  teamSlug: string,
  params: TeamPropertyRequestBody,
): Promise<AxiosResponse<TeamProperty> | ParsedError> => {
  try {
    const response = await post<TeamProperty>(getBaseUrl(teamSlug), params)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.PROPERTIES_CREATE)
  }
}

export const updateTeamProperty = async (
  teamSlug: TeamProperty['slug'],
  id: TeamProperty['id'],
  params: TeamPropertyUpdateRequestBody,
): Promise<AxiosResponse<TeamProperty> | ParsedError> => {
  try {
    const response = await put<TeamProperty>(getIdUrl(teamSlug, id), params)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.PROPERTIES_UPDATE)
  }
}

export const deleteTeamProperty = async (
  teamSlug: TeamProperty['slug'],
  id: TeamProperty['id'],
): Promise<AxiosResponse<TeamProperty> | ParsedError> => {
  try {
    const response = await remove<TeamProperty>(getIdUrl(teamSlug, id))
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.PROPERTIES_DELETE)
  }
}

export const updateTeamPropertyValue = async (
  teamSlug: TeamProperty['slug'],
  propertyId: TeamProperty['id'],
  propertyValueId: string,
  params: Pick<TeamPropertyValue, 'color' | 'value'>,
): Promise<AxiosResponse<TeamPropertyValue> | ParsedError> => {
  try {
    const response = await put<TeamPropertyValue>(
      `/v2/teams/${teamSlug}/properties/${propertyId}/property_values/${propertyValueId}`,
      params,
    )
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.PROPERTIES_VALUE_UPDATE)
  }
}

export const deleteTeamPropertyValue = async (
  teamSlug: TeamProperty['slug'],
  propertyId: TeamProperty['id'],
  propertyValueId: string,
): Promise<AxiosResponse<TeamProperty> | ParsedError> => {
  try {
    const response = await remove<TeamProperty>(
      `/v2/teams/${teamSlug}/properties/${propertyId}/property_values/${propertyValueId}`,
    )
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.PROPERTIES_VALUE_DELETE)
  }
}

/**
 * Returns all the property values that are selected for the given item.
 * The name is a bit confusing but it's the best representation of the data
 */
export const getItemTeamItemProperties = (
  teamSlug: TeamProperty['slug'],
  itemId: string,
): Promise<AxiosResponse<DatasetItemPropertiesResponse>> =>
  get<DatasetItemPropertiesResponse>(`/v2/teams/${teamSlug}/items/${itemId}/properties`)

/**
 * Set's the property values for an item
 */
export const setItemTeamPropertyValue = (
  teamSlug: TeamProperty['slug'],
  itemId: string,
  propertyId: DatasetItemPropertyId,
  propertyValueIds: DatasetItemPropertyValues,
  stageInstanceId: string,
  stageId: string,
): Promise<ApiResponse<V2WorkflowCommandResponse> | ParsedError> =>
  sendV2Commands({
    datasetItemId: itemId,
    teamSlug,
    commands: [
      {
        type: 'set_item_properties',
        data: {
          properties: {
            [propertyId]: propertyValueIds,
          },
          stage_id: stageId,
          stage_instance_id: stageInstanceId,
        },
      },
    ],
  })
