export const V2ChannelEvent = {
  ACTIVITY: 'workview:activity',
  AUTOMATION_ACTION: 'workview:automation_action',
  PAUSE: 'workview:pause',
  UNPAUSE: 'workview:unpause',
  TIME: 'workview:time',
}

export const V3ChannelEvent = {
  ACTIVITY: 'workview_v3:activity',
  AUTOMATION_ACTION: 'workview_v3:automation_action',
  PAUSE: 'workview_v3:pause',
  UNPAUSE: 'workview_v3:unpause',
  TIME: 'workview_v3:time',
}
