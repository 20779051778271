import type { AnnotationType } from '@/core/annotationTypes'

import type { AnnotationData } from './AnnotationData'
import { getAllVerticesFromBoundingBox, isBoundingBox } from './annotationTypes/boundingBox'
import { getAllVerticesFromEllipse, isEllipse } from './annotationTypes/ellipse'
import { getAllVerticesFromEye, isEye } from './annotationTypes/eye'
import { getAllVerticesFromKeyPoint, isKeyPoint } from './annotationTypes/keypoint'
import { getAllVerticesFromPolygon, isPolygon } from './annotationTypes/polygon'
import { getAllVerticesFromPolyline, isPolyline } from './annotationTypes/polyline'
import { getAllVerticesFromSimpleTable, isSimpleTable } from './annotationTypes/simpleTable'
import { getAllVerticesFromSkeleton, isSkeleton } from './annotationTypes/skeleton'
import type { EditablePoint } from './point'

import { inferCurrentAnnotationData } from './inferCurrentAnnotationData'
import type { Annotation } from './models/annotation/Annotation'
import type { View } from './views/view'

const getAllAnnotationDataVertices = (
  data: AnnotationData,
  type: AnnotationType,
): EditablePoint[] => {
  if (type === 'polygon' && isPolygon(data)) {
    return getAllVerticesFromPolygon(data)
  }

  if (type === 'bounding_box' && isBoundingBox(data)) {
    return getAllVerticesFromBoundingBox(data)
  }

  if (type === 'ellipse' && isEllipse(data)) {
    return getAllVerticesFromEllipse(data)
  }

  if (type === 'skeleton' && isSkeleton(data)) {
    return getAllVerticesFromSkeleton(data)
  }

  if (type === 'eye' && isEye(data)) {
    return getAllVerticesFromEye(data)
  }

  if (type === 'line' && isPolyline(data)) {
    return getAllVerticesFromPolyline(data)
  }

  if (type === 'keypoint' && isKeyPoint(data)) {
    return getAllVerticesFromKeyPoint(data)
  }

  if (type === 'simple_table' && isSimpleTable(data)) {
    return getAllVerticesFromSimpleTable(data)
  }

  return []
}

export const getAllAnnotationVertices = (annotation: Annotation, view: View): EditablePoint[] => {
  const data = inferCurrentAnnotationData(annotation, view.currentFrameIndex)
  return getAllAnnotationDataVertices(data, annotation.type)
}
