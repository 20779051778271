import type { CustomerPlan } from '@/modules/Billing/planTypes'
import type { ApiResponse } from '@/store/types'
import { get } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

type Params = {
  teamSlug: string
}

type Response = Promise<ApiResponse<CustomerPlan> | ParsedError>

export const loadCustomerPlan = async ({ teamSlug }: Params): Response => {
  const path = `/teams/${teamSlug}/billing/plans/active`

  try {
    const response = await get<CustomerPlan>(path)
    return { data: response.data }
  } catch (error: unknown) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.LOAD_CUSTOMER_PLAN)
  }
}
