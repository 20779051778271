import type { Camera } from '@/modules/Editor/camera'
import { DEFAULT_LINE_WIDTH } from '@/modules/Editor/config'
import type { DrawCallback } from '@/modules/Editor/models/layers/types'

import { lineWidth } from './lineWidth'

export const drawBoxPathV2 = (
  drawFn: DrawCallback,
  camera: Camera,
  boxPath: Path2D,
  inferred: boolean,
): Path2D => {
  drawFn((ctx) => {
    ctx.lineCap = 'round'
    ctx.lineJoin = 'round'
    ctx.lineWidth = lineWidth(DEFAULT_LINE_WIDTH, inferred) / camera.scale
    ctx.strokeStyle = 'rgb(241, 245, 249)' // Alice Blue
    ctx.stroke(boxPath)
  })

  return boxPath
}

/**
 * @deprecated
 */
export const drawBoxPath = (
  ctx: CanvasRenderingContext2D,
  camera: Camera,
  boxPath: Path2D,
  inferred: boolean,
): void => {
  ctx.save()
  camera.imageViewCtxToCanvasViewCtx(ctx)
  ctx.lineCap = 'round'
  ctx.lineJoin = 'round'
  ctx.lineWidth = (lineWidth(DEFAULT_LINE_WIDTH, inferred) * 1.0) / camera.scale
  ctx.strokeStyle = 'rgb(241, 245, 249)' // Alice Blue
  ctx.stroke(boxPath)
  ctx.restore()
}
