import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import type { IPoint } from '@/modules/Editor/point'
import { ToolName } from '@/modules/Editor/tools/types'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import { preselectOrPromptForAnnotationClass } from '@/modules/Editor/utils/preselectOrPromptForAnnotationClass'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'

import { annotationType } from './consts'
import type { Action } from '@/modules/Editor/managers/actionManager'

interface KeypointTool extends Tool {
  point?: IPoint
  draw(view: View): void
}

const annotationCreationAction = async (context: ToolContext, point: IPoint): Promise<Action> => {
  const newAnnotation =
    await context.editor.activeView.annotationManager.prepareAnnotationForCreation({
      type: annotationType,
      data: point,
    })

  if (!newAnnotation) {
    throw new Error('Failed to create annotation')
  }

  // support view id for undoing purposes
  const sourceViewId = context.editor.activeView.id

  return {
    do(): boolean {
      context.editor.activeView.annotationManager.createAnnotation(newAnnotation)
      context.editor.activeView.annotationManager.selectAnnotation(newAnnotation.id)

      context.editor.activeView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)

      return true
    },
    undo(): boolean {
      // we don't want to use the active view here, as by the time the user want to undo
      // the view could have changed due to multi-slot;
      // this would result in the annotation not being found and the undo action failing
      const sourceView = context.editor.viewsList.find(({ id }) => id === sourceViewId)
      if (!sourceView) {
        return false
      }

      context.editor.activeView.annotationManager.deleteAnnotation(newAnnotation.id)

      context.editor.activeView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)
      return true
    },
  }
}

export const keypointTool: KeypointTool = {
  point: undefined,

  async activate(context: ToolContext) {
    setupMouseButtonLoadout(context, { middle: true })

    const classSelected = await preselectOrPromptForAnnotationClass(
      context.editor.activeView,
      ToolName.Keypoint,
      [annotationType],
      'You must create or select an existing Keypoint class before using the keypoint tool',
    )

    if (!classSelected) {
      return
    }

    selectCursor(EditorCursor.Draw)

    context.editor.registerCommand('keypoint_tool.cancel', () => {
      this.point = undefined
      this.draw(context.editor.activeView)
    })

    context.handles.push(
      ...context.editor.onMouseMove((event) => {
        if (!context.editor.activeView.hitTarget(event)) {
          return CallbackStatus.Stop
        }

        const point = { x: event.offsetX, y: event.offsetY }
        this.point = context.editor.activeView.camera.canvasViewToImageView(point)
        this.draw(context.editor.activeView)
      }),
    )

    context.handles.push(
      ...context.editor.onMouseUp(async (event) => {
        if (!this.point) {
          return
        }
        try {
          this.point = context.editor.activeView.camera.canvasViewToImageView({
            x: event.offsetX,
            y: event.offsetY,
          })
          await context.editor.actionManager.do(await annotationCreationAction(context, this.point))
        } catch (e) {
          setContext('error', { error: e })
          console.error('V2 keypoint tool createAnnotation failed')
        }
        this.draw(context.editor.activeView)
        return CallbackStatus.Stop
      }),
    )
  },
  draw(view: View): void {
    view.annotationsLayer.draw((ctx) => {
      if (!ctx || !this.point) {
        return
      }
      const point = view.camera.imageViewToCanvasView(this.point)
      ctx.strokeStyle = view.annotationManager.preselectedAnnotationClassColor()
      ctx.fillStyle = 'rgb(255, 255, 255)'
      ctx.lineJoin = 'round'
      ctx.lineWidth = 1.5
      ctx.beginPath()
      ctx.arc(point.x, point.y, 3.5, 0, 2 * Math.PI)
      ctx.fill()
      ctx.stroke()
      ctx.closePath()
    })
  },
  deactivate(context: ToolContext): void {
    context.editor.activeView.annotationsLayer.draw()
  },
  reset() {},
}
