import type { VideoRaster } from '@/modules/Editor/models/raster/VideoRaster'
import type { View } from '@/modules/Editor/views/view'

/**
 * Delete mask annotations on frame, but only if they are not used on any other keyframe.
 * This is called when an existing raster keyframe is deleted.
 */
export const deleteMaskAnnotationsOnFrame = (
  view: View,
  videoRaster: VideoRaster,
  frameIndex: number,
): void => {
  const labelsOnKeyframe = videoRaster.getLabelsOnKeyframe(frameIndex)

  labelsOnKeyframe.forEach((labelIndex) => {
    const annotationId = videoRaster.getAnnotationMapping(labelIndex)

    if (!annotationId) {
      throw new Error('Label on keyframe with no annotation mapping.')
    }

    const annotation = view.annotationManager.getAnnotation(annotationId)

    if (!annotation) {
      return
    }

    if (!videoRaster.isLabelUsedOnOtherKeyframe(labelIndex, frameIndex)) {
      // Delete annotation entirely if it's only used on a single raster keyframe
      view.annotationManager.deleteAnnotation(annotation.id)
    }
  })
}
