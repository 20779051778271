import type { Raster } from '@/modules/Editor/models/raster/Raster'
import { getToolFrameRange } from '@/modules/Editor/plugins/brush/utils/getToolFrameRange'
import { createUpdateRasterAction } from '@/modules/Editor/plugins/mask/utils/shared/createUpdateRasterAction'

type MaskChangeFunction = {
  (): Promise<void>
}

/**
 * This function applys changes to the raster with the support of undo/redo (when active)
 * IMPORTANT: anything that needs to be reverted must be part to the `changeFunction`. Anything
 * modified outside of it will not be undone.
 */
export const applyRasterChange = async (
  changeFunction: MaskChangeFunction,
  raster: Raster,
  isReformattedDICOMView: boolean,
  changeFramesRange?: number[],
): Promise<void> => {
  const framesRange = changeFramesRange || getToolFrameRange(raster, isReformattedDICOMView)
  const snapshotBeforeChange = raster.takeSnapshot(framesRange)

  await changeFunction()

  const snapshotAfterChange = raster.takeSnapshot(framesRange)
  const action = createUpdateRasterAction(raster.view, snapshotAfterChange, snapshotBeforeChange)
  raster.view.editor.actionManager.done(action)
}
