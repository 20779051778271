import type { SequenceData, FrameData } from '@/core/annotations'
import type { AnnotationData, VideoAnnotationData } from '@/modules/Editor/AnnotationData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type {
  ImageSubAnnotation,
  VideoSubAnnotations,
  VideoAnnotation,
  ImageAnnotation,
  MaskAnnotation,
} from '@/modules/Editor/models/annotation/types'

export const isVideoAnnotationData = (
  data: AnnotationData | VideoAnnotationData,
): data is VideoAnnotationData => 'frames' in data && 'segments' in data

export const isImageMaskAnnotationData = (data: AnnotationData): boolean =>
  'mask' in data && data.mask !== undefined

export const isVideoMaskAnnotationData = (data: AnnotationData | VideoAnnotationData): boolean => {
  // Used for the Optimistic UI
  if ('rasterId' in data) {
    return true
  }

  if (!isVideoAnnotationData(data)) {
    return false
  }

  const frameIndicies = Object.keys(data.frames)
  const firstFrame = data.frames[Number(frameIndicies[0])]

  if (firstFrame === undefined) {
    return false
  }

  return 'mask' in firstFrame || 'rasterId' in firstFrame
}

export const isMaskAnnotationData = (data: AnnotationData | VideoAnnotationData): boolean =>
  isImageMaskAnnotationData(data) || isVideoMaskAnnotationData(data)

export const isVideoAnnotationDataPayload = (
  data: FrameData | SequenceData,
): data is SequenceData => 'frames' in data && 'segments' in data

export const isImageAnnotationDataPayload = (data: FrameData | SequenceData): data is FrameData =>
  !isVideoAnnotationDataPayload(data)

export const isVideoSubAnnotations = (
  subAnnotations: ImageSubAnnotation[] | VideoSubAnnotations,
): subAnnotations is VideoSubAnnotations => 'frames' in subAnnotations

export const isImageSubAnnotations = (
  subAnnotations: ImageSubAnnotation[] | VideoSubAnnotations,
): subAnnotations is ImageSubAnnotation[] => !isVideoSubAnnotations(subAnnotations)

export const isVideoAnnotation = (annotation: Annotation): annotation is VideoAnnotation =>
  isVideoAnnotationData(annotation.data) && isVideoSubAnnotations(annotation.subAnnotations)

export const isImageAnnotation = (annotation: Annotation): annotation is ImageAnnotation =>
  !isVideoAnnotationData(annotation.data) && !isVideoSubAnnotations(annotation.subAnnotations)

export const isRasterAnnotation = (
  annotation: Annotation | VideoAnnotation,
): annotation is MaskAnnotation => isMaskAnnotationData(annotation.data)
