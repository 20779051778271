import { isAnnotationFrameData, type SequenceData } from '@/core/annotations'
import type {
  CreateAnnotationCommand,
  ShiftSequenceAnnotationCommand,
  UpdateSequenceAnnotationCommand,
  V2WorkflowCommandPayload,
} from '@/store/types/V2WorkflowCommandPayload'

type CommandLike = { id: string; user_id?: number; type: string }
type Unsaved<T extends CommandLike> = Omit<T, 'id' | 'user_id'>

const isSequenceCommand = (
  c: Unsaved<V2WorkflowCommandPayload>,
): c is Unsaved<UpdateSequenceAnnotationCommand> => c.type === 'update_sequence_annotation'

export const isCreateCommand = (
  c: Unsaved<V2WorkflowCommandPayload>,
): c is Unsaved<CreateAnnotationCommand> => c.type === 'create_annotation'

export const isShiftCommand = (
  c: Unsaved<V2WorkflowCommandPayload>,
): c is Unsaved<ShiftSequenceAnnotationCommand> => c.type === 'shift_sequence_annotation_segments'

const mergeFrames = (
  prevCommand: Unsaved<CreateAnnotationCommand | UpdateSequenceAnnotationCommand>,
  nextCommand: Unsaved<UpdateSequenceAnnotationCommand>,
): UpdateSequenceAnnotationCommand['data']['data']['frames'] => {
  if (
    !('frames' in prevCommand.data.data && prevCommand.data.data.frames) &&
    !nextCommand.data.data.frames
  ) {
    return undefined
  }

  if (!('frames' in prevCommand.data.data && prevCommand.data.data.frames)) {
    return nextCommand.data.data.frames
  }

  if (!nextCommand.data.data.frames) {
    return prevCommand.data.data.frames
  }

  return {
    ...prevCommand.data.data.frames,
    ...nextCommand.data.data.frames,
  }
}

const mergeSubFrames = (
  prevCommand: Unsaved<CreateAnnotationCommand | UpdateSequenceAnnotationCommand>,
  nextCommand: Unsaved<UpdateSequenceAnnotationCommand>,
): UpdateSequenceAnnotationCommand['data']['data']['sub_frames'] => {
  if (
    !('sub_frames' in prevCommand.data.data && prevCommand.data.data.sub_frames) &&
    !nextCommand.data.data.sub_frames
  ) {
    return undefined
  }

  if (!('sub_frames' in prevCommand.data.data && prevCommand.data.data.sub_frames)) {
    return nextCommand.data.data.sub_frames
  }

  if (!nextCommand.data.data.sub_frames) {
    return prevCommand.data.data.sub_frames
  }

  return {
    ...prevCommand.data.data.sub_frames,
    ...nextCommand.data.data.sub_frames,
  }
}

/**
 * Merges two shift commands into one by summing their shift
 * amounts. Returns null if the two shifts cancel each other.
 */
const mergeShiftCommands = (
  originalShift: Unsaved<ShiftSequenceAnnotationCommand>,
  addedShift: Unsaved<ShiftSequenceAnnotationCommand>,
): Unsaved<ShiftSequenceAnnotationCommand> => {
  const originalShiftAmount = originalShift.data.shift_amount
  const addedShiftAmount = addedShift.data.shift_amount

  const combinedShiftAmount = originalShiftAmount + addedShiftAmount

  return {
    ...originalShift,
    data: {
      ...originalShift.data,
      shift_amount: combinedShiftAmount,
    },
  }
}

const mergeShiftIntoCreateOrUpdate = (
  update: Unsaved<CreateAnnotationCommand | UpdateSequenceAnnotationCommand>,
  addedShift: Unsaved<ShiftSequenceAnnotationCommand>,
): Unsaved<CreateAnnotationCommand | UpdateSequenceAnnotationCommand> => {
  if (isAnnotationFrameData(update.data.data)) {
    /**
     * This is to satisfy Typescript, and realistically we should never get here. Shift
     * commands should only be applied to video annotations, and so all update commands
     * in the queue should also apply to the same video annotation.
     */
    throw new Error('Cannot merge shift command into frame data')
  }

  const updateFrames = update.data.data.frames
  const updateSubFrames = update.data.data.sub_frames

  const shiftAmount = addedShift.data.shift_amount

  const originalFrameEntries = Object.entries(updateFrames ?? {})
  const newFrameEntries = originalFrameEntries.map(([frameIndex, frameData]) => [
    Number(frameIndex) + shiftAmount,
    frameData,
  ])

  const originalSubframeEntries = Object.entries(updateSubFrames ?? {})
  const newSubframeEntries = originalSubframeEntries.map(([frameIndex, frameData]) => [
    Number(frameIndex) + shiftAmount,
    frameData,
  ])

  const newSegments: SequenceData['segments'] = update.data.data.segments?.map(([start, end]) => {
    // Condition is theoretically possible according to TypeScript types;
    // retained to future-proof the code.
    if (end === null) {
      console.warn('Unexpected annotation created with null end segment')

      throw new Error('Null end segments are not supported when merging a shift into a create.')
    }
    return [start + shiftAmount, end + shiftAmount]
  })

  return {
    type: update.type,
    data: {
      ...update.data,
      data: {
        ...update.data.data,
        frames: updateFrames ? Object.fromEntries(newFrameEntries) : undefined,
        sub_frames: updateSubFrames ? Object.fromEntries(newSubframeEntries) : undefined,
        segments: newSegments,
      },
    },
  }
}

const mergeSequenceCommands = (
  prevCommand: Unsaved<CreateAnnotationCommand | UpdateSequenceAnnotationCommand>,
  nextCommand: Unsaved<UpdateSequenceAnnotationCommand>,
): Unsaved<CreateAnnotationCommand | UpdateSequenceAnnotationCommand> => {
  const merged = {
    ...prevCommand,
    ...nextCommand,
    data: {
      ...prevCommand.data,
      ...nextCommand.data,
      data: {
        ...prevCommand.data.data,
        ...nextCommand.data.data,
        frames: mergeFrames(prevCommand, nextCommand),
        sub_frames: mergeSubFrames(prevCommand, nextCommand),
      },
    },
    type: prevCommand.type,
  }

  if (isSequenceCommand(prevCommand)) {
    // if two sequence commands merged, then what we have here is good for backend processing
    return merged
  }

  // if we got here, a sequence command got merged into a create command.
  // a create command doesn't understand the value `null` for a frame or subframe
  // so we must drop those

  // we mutate the merged object

  const mergedFrames = merged.data.data.frames

  if (mergedFrames) {
    for (const k in mergedFrames) {
      mergedFrames[k] === null && delete mergedFrames[k]
    }
  }

  const mergedSubs = merged.data.data.sub_frames
  if (mergedSubs) {
    for (const k in mergedSubs) {
      mergedSubs[k] === null && delete mergedSubs[k]
    }
  }

  return merged
}

const mergeCommands = (
  prevCommand: Unsaved<V2WorkflowCommandPayload>,
  nextCommand: Unsaved<V2WorkflowCommandPayload>,
): Unsaved<V2WorkflowCommandPayload> => {
  if (
    (isSequenceCommand(prevCommand) || isCreateCommand(prevCommand)) &&
    isSequenceCommand(nextCommand)
  ) {
    return mergeSequenceCommands(prevCommand, nextCommand)
  }

  if (isShiftCommand(prevCommand) && isShiftCommand(nextCommand)) {
    return mergeShiftCommands(prevCommand, nextCommand)
  }

  if (
    (isCreateCommand(prevCommand) || isSequenceCommand(prevCommand)) &&
    isShiftCommand(nextCommand)
  ) {
    return mergeShiftIntoCreateOrUpdate(prevCommand, nextCommand)
  }

  // this could be merging an update command into a create command,
  // or one create command into another
  // since create commands can contain extra keys in the base data field
  // we need to make sure everything is mored in correct order, not just
  // for example, annotation_group_id might be dropped otherwise

  return {
    ...prevCommand,
    ...nextCommand,
    type: prevCommand.type,
    data: {
      ...prevCommand.data,
      ...nextCommand.data,
    },
  }
}

type HasUnsavedCommand = { commands: Unsaved<V2WorkflowCommandPayload>[] }

export const mergeRequests = <T extends HasUnsavedCommand>(prev: T, next: T): T => {
  const prevCommand = prev.commands[0]
  const nextCommand = next.commands[0]

  const mergedCommand = mergeCommands(prevCommand, nextCommand)

  return {
    ...next,
    commands: [mergedCommand],
  }
}

export const convertToUpdate = <T extends HasUnsavedCommand>(request: T): T => ({
  ...request,
  commands: [
    {
      ...request.commands[0],
      type: 'update_annotation',
    },
  ],
})
