import EventEmitter from 'events'

import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'

export enum Events {
  SHOW_DIALOG = 'show:dialog',
  HIDE_DIALOG = 'hide:dialog',
}

export default class ClassDialog extends EventEmitter {
  public previousClassID: number | null = null
  private resolveCallback?: ((value: AnnotationClass | null) => void) | null
  private rejectCallback?: (() => void) | null

  public requestUserSelectClass(
    previousClassID: number | null = null,
  ): Promise<AnnotationClass | null> {
    this.previousClassID = previousClassID
    this.emit(Events.SHOW_DIALOG)

    this.rejectCallback && this.rejectCallback()

    return new Promise((resolve, reject) => {
      this.resolveCallback = resolve
      this.rejectCallback = reject
    })
  }

  public selectAnnotationClass(annotationClass: AnnotationClass): void {
    if (this.previousClassID === annotationClass.id) {
      this.cancel()
      return
    }
    this.resolveCallback && this.resolveCallback(annotationClass)
    this.reset()
  }

  public cancel(): void {
    this.resolveCallback && this.resolveCallback(null)
    this.reset()
  }

  reset(): void {
    this.previousClassID = null
    this.emit(Events.HIDE_DIALOG)
    this.rejectCallback = null
    this.resolveCallback = null
  }
}
