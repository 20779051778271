import type { AnnotationTypePayload } from '@/store/types'
import { get } from '@/backend/api'
import { parseError, errorMessages, isErrorResponse } from '@/backend/error'
import type { ApiResult } from './types'

export const loadAnnotationTypes = async (): Promise<ApiResult<AnnotationTypePayload[]>> => {
  try {
    const { data } = await get<AnnotationTypePayload[]>('annotation_types')
    return { data, ok: true }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return { ...parseError(error, errorMessages.ANNOTATION_TYPE_LOAD), ok: false }
  }
}
