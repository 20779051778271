import type { CompoundPath } from '@/modules/Editor/compoundPath'

export const isValidPath = (data: CompoundPath): boolean => {
  // Check that data is valid
  // This is especially useful when erasing a polygon entirely
  const paths = [data.path, ...data.additionalPaths]
  for (const path of paths) {
    if (path.length < 3) {
      return false
    }
    const index = path.findIndex((p) => p.isSelected)
    if (index >= 0) {
      return false
    }
  }

  return true
}
