import type { PartialRecord } from '@/core/helperTypes'

export interface ContainsId {
  id: string
}

/**
 * To introduce the map support for the Vue
 */
class ReactiveMap<KEY extends string, TYPE> {
  private _size: number = 0
  private map: PartialRecord<KEY, TYPE> = {}

  public get size(): number {
    return this._size
  }

  public set(id: KEY, value: TYPE): void {
    if (!this.has(id)) {
      this._size++
    }
    this.map[id] = value
  }

  public get(id: KEY): TYPE | undefined {
    return this.map[id]
  }

  public delete(id: KEY): void {
    if (this.has(id)) {
      this._size--
    }
    delete this.map[id]
  }

  public has(id: KEY): boolean {
    return !!this.map[id]
  }

  // eslint-disable-next-line require-yield
  *[Symbol.iterator](): Generator<TYPE> {
    return Object.values(this.map)
  }

  public clear(): void {
    this.map = {}
    this._size = 0
  }
}

class MapLinkedListNode<T extends ContainsId> {
  value: T
  next: MapLinkedListNode<T> | null = null
  prev: MapLinkedListNode<T> | null = null

  constructor(value: T) {
    this.value = value
  }
}

/**
 * Hybrid data structure (Doubly Linked List for the order and a Map for the lookup)
 * that keeps track of items by id and allows inserting before/after specific item
 */
class MapLinkedList<T extends ContainsId> {
  private head: MapLinkedListNode<T> | null = null
  private tail: MapLinkedListNode<T> | null = null
  private idNodeMap: ReactiveMap<string, MapLinkedListNode<T>> = new ReactiveMap()
  private memoIndexMap = new Map<string, number>()

  public get size(): number {
    return this.idNodeMap.size
  }

  public contains(id: string): boolean {
    return this.idNodeMap.has(id)
  }

  public get(id: string): T | null {
    return this.idNodeMap.get(id)?.value || null
  }

  public set(id: string, val: T): void {
    const item = this.idNodeMap.get(id)

    if (!item) {
      return
    }

    item.value = val
    this.memoIndexMap.clear()
  }

  public getFirst(): T | null {
    if (this.head != null) {
      return this.head.value
    }
    return null
  }

  public getLast(): T | null {
    if (this.tail != null) {
      return this.tail.value
    }
    return null
  }

  public getBefore(id: string): T | null {
    return this.idNodeMap.get(id)?.prev?.value || null
  }

  public getAfter(id: string): T | null {
    return this.idNodeMap.get(id)?.next?.value || null
  }

  public addLast(value: T): void {
    if (this.idNodeMap.has(value.id)) {
      return
    }

    this.memoIndexMap.clear()

    const newNode = new MapLinkedListNode<T>(value)

    if (this.tail === null) {
      this.head = newNode
      this.tail = newNode
    } else {
      newNode.next = null
      newNode.prev = this.tail
      newNode.value = value

      this.tail.next = newNode

      this.tail = newNode
    }

    this.idNodeMap.set(value.id, newNode)
  }

  public addFirst(value: T): void {
    if (this.idNodeMap.has(value.id)) {
      return
    }

    this.memoIndexMap.clear()

    const newNode = new MapLinkedListNode<T>(value)

    if (this.head === null) {
      this.head = newNode
      this.tail = newNode
    } else {
      newNode.next = this.head
      newNode.prev = null

      this.head.prev = newNode

      this.head = newNode
    }

    this.idNodeMap.set(value.id, newNode)
  }

  public addBefore(value: T, beforeId: string): void {
    if (this.idNodeMap.has(value.id)) {
      return
    }

    const referenceNode = this.idNodeMap.get(beforeId)

    if (!referenceNode) {
      return
    }

    this.memoIndexMap.clear()

    const newNode = new MapLinkedListNode<T>(value)

    newNode.prev = referenceNode.prev
    newNode.next = referenceNode
    referenceNode.prev = newNode
    if (this.head === referenceNode) {
      this.head = newNode
    } else if (newNode.prev) {
      newNode.prev.next = newNode
    }

    this.idNodeMap.set(value.id, newNode)
  }

  public addAfter(value: T, afterId: string): void {
    if (this.idNodeMap.has(value.id)) {
      return
    }

    const referenceNode = this.idNodeMap.get(afterId)

    if (!referenceNode) {
      return
    }

    this.memoIndexMap.clear()

    const newNode = new MapLinkedListNode<T>(value)

    newNode.prev = referenceNode
    newNode.next = referenceNode.next
    referenceNode.next = newNode
    if (this.tail === referenceNode) {
      this.tail = newNode
    } else if (newNode.next) {
      newNode.next.prev = newNode
    }

    this.idNodeMap.set(value.id, newNode)
  }

  public remove(id: string): void {
    const matchingNode = this.idNodeMap.get(id)

    if (!matchingNode) {
      return
    }

    this.memoIndexMap.clear()

    this.removeNode(matchingNode)
    this.idNodeMap.delete(id)
  }

  private removeNode(node: MapLinkedListNode<T>): void {
    this.memoIndexMap.clear()

    if (node.prev && node.next) {
      // removing a node with surrounding nodes on both sides
      node.prev.next = node.next
      node.next.prev = node.prev
    } else if (node.prev) {
      // removing current tail
      this.tail = node.prev
      node.prev.next = null
    } else if (node.next) {
      // removing current head
      this.head = node.next
      node.next.prev = null
    } else {
      // removing the only node in the list
      this.head = null
      this.tail = null
    }

    node.next = null
    node.prev = null
  }

  public indexOf(id: string): number {
    if (this.size < 1) {
      return -1
    }

    if (this.memoIndexMap.has(id)) {
      return this.memoIndexMap.get(id) ?? -1
    }

    let index = 0
    let startingNode = this.head
    while (startingNode != null) {
      this.memoIndexMap.set(startingNode.value.id, index)
      if (startingNode.value?.id === id) {
        return index
      }
      startingNode = startingNode.next
      index++
    }
    return -1
  }

  public addFromArray(
    renderableItemList: IterableIterator<T> | T[],
    passFn?: (item: T) => boolean,
  ): void {
    this.memoIndexMap.clear()
    for (const item of renderableItemList) {
      if (passFn && !passFn?.(item)) {
        continue
      }
      this.addFirst(item)
    }
  }

  /**
   * @returns array IDs items starting from the head to the tail
   */
  public toArray(): T['id'][] {
    const values = []

    let currentNode = this.head
    while (currentNode?.value) {
      values.push(currentNode.value.id)
      currentNode = currentNode.next
    }

    return values
  }

  public values(): T[] {
    const values = []

    let currentNode = this.head
    while (currentNode?.value) {
      values.push(currentNode.value)
      currentNode = currentNode.next
    }

    return values
  }

  *[Symbol.iterator](): Generator<T> {
    let startingNode = this.head
    while (startingNode != null) {
      yield startingNode.value
      startingNode = startingNode.next
    }
  }

  public clear(): void {
    this.head = null
    this.tail = null
    this.idNodeMap.clear()
    this.memoIndexMap.clear()
  }
}

export { MapLinkedList, MapLinkedListNode }
