import {
  clearRefreshToken,
  clearToken,
  getRefreshToken,
  getToken,
  setRefreshToken,
  setToken,
  setTokenExpiration,
} from './token'

export const TokenSyncKeys = {
  REFRESH_TOKEN_REQUEST: 'refresh_token_request',
  REFRESH_TOKEN_RESPONSE: 'refresh_token_response',
}

/**
 * Receive and set refresh token from another tab
 *
 *
 */
const receiveRefreshTokenFromOtherTabs = (refreshToken: string): void => {
  // false means the token will be stored into sessions storage. if it were in local storage,
  // we'd not be getting to this point, as it would persist across multiple tabs anyway
  const isPermanent = false
  setRefreshToken(refreshToken, isPermanent)
}

/**
 * Request a refresh token from other tabs.
 *
 * Hapens on page load if a refresh token isn't already in session.
 *
 * This would happen if a user is directly opening a darwin URL,
 * while a tab with darwin is already open. It will allow the user to reuse the session.
 */
const requestRefreshTokenFromOtherTabs = (): void => {
  window.localStorage.setItem(TokenSyncKeys.REFRESH_TOKEN_REQUEST, 'sessions')
  window.localStorage.removeItem(TokenSyncKeys.REFRESH_TOKEN_REQUEST)
}

/**
 * Send refresh token to other tabs, provided user did not check remember me.
 *
 * This will cause other tabs to store this new refresh token into session storage,
 * same as this tab.
 *
 * Happens on login
 */
const sendRefreshTokenToOtherTabs = (): void => {
  const token = getRefreshToken()

  if (token) {
    console.info('Sending credentials to other open tabs.')
    window.localStorage.setItem(TokenSyncKeys.REFRESH_TOKEN_RESPONSE, token)
    window.localStorage.removeItem(TokenSyncKeys.REFRESH_TOKEN_RESPONSE)
  }
}

type AuthenticationPayload = {
  isPermanent?: boolean
  refreshToken: string
  token: string
  tokenExpiration: number
}

/**
 * Handles sync of user's session within a single tab as well as across tabs.
 *
 * When the user logs in, token data is set into local/session storage.
 *
 * When the user logs out, this is synced to other tabs using storage events, so
 * other tabs can logout to.
 *
 * When a new tab is open, a refresh token is requested from previous tabs.
 *
 * When a refresh token is requested from another tab, it is sent if available.
 */
export class Session {
  /**
   * Indicate if user clicked "remember me" when logging in.
   *
   * If this flag is active, the tokens are stored into local,
   * rather than session storage.
   */
  private isPermanent: boolean = false

  constructor() {
    window.addEventListener('storage', (e) => this.onStorageEvent(e), false)

    // when we open a new tab,
    // if we were signed in with "remember me" unchecked in the other one
    // we will have an access token, but not a refresh token
    // we ask for an access token from other open tabs
    if (getToken() && !getRefreshToken()) {
      requestRefreshTokenFromOtherTabs()
    }
  }

  /**
   * Binds to storage events, which trigger when another browser tab
   * on the same domain stores something into local or sessions storage
   */
  private onStorageEvent(e: StorageEvent): void {
    if (e.key === TokenSyncKeys.REFRESH_TOKEN_RESPONSE && !!e.newValue) {
      // another tab sent a refresh token to all open tabs
      // if this tab doesn't have it, so we store it
      if (getRefreshToken()) {
        console.info('Received credentials from another tab, but already have them.')
      } else {
        console.info('Received credentials from another tab. Storing...')
        receiveRefreshTokenFromOtherTabs(e.newValue)
      }
    }

    if (e.key === TokenSyncKeys.REFRESH_TOKEN_REQUEST && !!e.newValue) {
      // a newly opened tab requested the refresh token from other open tabs
      // if this tab has it, we send it
      if (getRefreshToken()) {
        console.info('Another tab requested credentials. Sending...')
        sendRefreshTokenToOtherTabs()
      } else {
        console.info('Another tab requested credentials, but we do not have them.')
      }
    }
  }

  /**
   * Authenticate session for user
   *
   * Set all tokens and send refresh token to other open tabs
   */
  public authenticate(payload: AuthenticationPayload): void {
    const isPermanent = payload.isPermanent === undefined ? this.isPermanent : payload.isPermanent

    setToken(payload.token)
    setTokenExpiration(payload.tokenExpiration)
    setRefreshToken(payload.refreshToken, isPermanent)
    sendRefreshTokenToOtherTabs()
  }

  private onLogoutCallbacks: (() => void)[] = []

  /**
   * Register a function to call when session is logged out
   */
  public onLogout(callback: () => unknown): void {
    this.onLogoutCallbacks.push(callback)
  }

  /**
   * Logs out current tab only.
   *
   * Automated logout happens when the user logged out directly fron another tab,
   * or when the token expired.
   */
  public async logout(): Promise<void> {
    clearToken()
    clearRefreshToken()

    // prevents same callbacks from running over and over again
    const logoutCallbacks = [...this.onLogoutCallbacks]
    this.onLogoutCallbacks = []

    for (let i = 0; i < logoutCallbacks.length; i++) {
      await logoutCallbacks[i]()
    }
  }
}

const session = new Session()
export default session
