export const WorkflowStatus = {
  Complete: 'complete',
  New: 'new',
  Annotate: 'annotate',
  Review: 'review',
  Processing: 'processing',
  Error: 'error',
  Archived: 'archived',
} as const

export type WorkflowStatus = (typeof WorkflowStatus)[keyof typeof WorkflowStatus]
