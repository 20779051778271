import type { Keybinding } from './keybinding'
import { keybindingMatch } from './keybinding'

/**
 * Global editor keybindings.
 *
 * This list is being grown by moving keybindings defined on individual plugins to here
 */
const KEYBINDINGS: Keybinding[] = [
  { action: 'clipboard.alt_paste', keys: ['alt', 'v'] }, // option+v on MacOS
  { action: 'clipboard.copy', keys: ['meta', 'c'] },
  { action: 'clipboard.cut', keys: ['meta', 'x'] },
  { action: 'clipboard.paste', keys: ['meta', 'v'] },
  { action: 'clipboard.alt_shift_paste', keys: ['alt', 'shift', 'V'] }, // option+shift+v on MacOS
  { action: 'clipboard.shift_paste', keys: ['meta', 'shift', 'v'] },
  { action: 'edit.delete', keys: ['Backspace'] },
  { action: 'edit.delete', keys: ['Delete'] },
  { action: 'edit_tool.activate_polygon_merge', keys: ['shift', 'm'] },
  { action: 'edit_tool.activate_polygon_subtract', keys: ['shift', 's'] },
  { action: 'reference_lines.toggle', keys: ['shift', 'r'] },
  { action: 'save_annotations.toast', keys: ['ctrl', 's'] }, // Windows/Linux
  { action: 'save_annotations.toast', keys: ['meta', 's'] }, // MacOS
  { action: 'video_player.play', keys: [' '] },
  { action: 'window_level.activate', eventType: 'keydown', keys: ['Tab'] },
  { action: 'window_level.deactivate', eventType: 'keyup', keys: ['Tab'] },
  { action: 'zoom.focus_on_selected_annotation', keys: ['shift', '0'] },
  { action: 'zoom.in', keys: ['+'] },
  { action: 'zoom.in', keys: ['='] },
  { action: 'zoom.in', keys: ['meta', '='] },
  { action: 'zoom.out', keys: ['-'] },
  { action: 'zoom.out', keys: ['meta', '-'] },
  { action: 'zoom.reset', keys: ['0'] },
]

const handleEditorKeybinding = (
  editor: { callCommand: (name: string, ...args: unknown[]) => void },
  keybinding: Keybinding,
  event: KeyboardEvent,
  eventType: 'keyup' | 'keydown',
): void => {
  // if the keybidning does not specify an event type, keydown is assumed
  if ((keybinding.eventType || 'keydown') !== eventType) {
    return
  }

  if (!keybindingMatch(event, keybinding.keys)) {
    return
  }

  if (typeof keybinding.action === 'string') {
    editor.callCommand(keybinding.action)
  } else {
    for (const action of keybinding.action) {
      editor.callCommand(action)
    }
  }
  event.preventDefault()
  event.stopPropagation()
}

/**
 * Handles keyboard event by matching it with a command and dispatching it on the editor.
 *
 * If there is no match, nothing happens.
 */
export const handleEditorKeybindings = (
  editor: { callCommand: (name: string, ...args: unknown[]) => void },
  event: KeyboardEvent,
  eventType: 'keydown' | 'keyup',
): void =>
  KEYBINDINGS.forEach((keybinding) => handleEditorKeybinding(editor, keybinding, event, eventType))
