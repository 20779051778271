import type { TeamAction } from '@/store/modules/team/types'
import type { InvitationPayload } from '@/store/types/InvitationPayload'
import type { MembershipRole } from '@/store/types/MembershipRole'
import * as api from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

export type AddInvitationsPayload = {
  teamId: number
  invitations: Array<{
    email: string
    role: MembershipRole
  }>
}

/**
 * Add a new team invitation
 */
export const addInvitations: TeamAction<AddInvitationsPayload, InvitationPayload[]> = async (
  { commit },
  { teamId, invitations },
) => {
  let response
  try {
    response = await api.post<InvitationPayload[]>(`teams/${teamId}/invitations`, { invitations })
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.TEAM_MEMBERS_ADD_INVITATIONS)
  }

  commit('PUSH_INVITATIONS', response.data)
  return response
}
