import { defineAsyncComponent } from 'vue'
import type { Route, RouteConfig } from 'vue-router'

const resolveDatasetId = (route: Route): number | void => {
  if (typeof route.query.dataset !== 'string') {
    return
  }
  const datasetId = parseInt(route.query.dataset)
  if (isNaN(datasetId)) {
    return
  }
  return datasetId
}

const resolveItemId = (route: Route): string | void =>
  typeof route.query.item === 'string' ? route.query.item : undefined

const resolveSeq = (route: Route): number | void => {
  if (typeof route.query.image !== 'string') {
    return undefined
  }
  const seq = parseInt(route.query.image)
  if (isNaN(seq)) {
    return undefined
  }
  return seq
}

const resolveSlotName = (route: Route): string | undefined => {
  if (typeof route.query.slot !== 'string') {
    return undefined
  }
  const { slot } = route.query
  return slot || undefined
}

const resolveFrameIndex = (route: Route): number | undefined => {
  if (typeof route.query.frameIndex !== 'string') {
    return undefined
  }
  const frameIndex = parseInt(route.query.frameIndex)
  if (isNaN(frameIndex)) {
    return undefined
  }
  return frameIndex
}

export const workviewRoute: RouteConfig = {
  component: defineAsyncComponent(() => import('@/modules/Workview/RouteWorkview.vue')),
  props: (route) => ({
    datasetId: resolveDatasetId(route),
    itemId: resolveItemId(route),
    seq: resolveSeq(route),
    slotName: resolveSlotName(route),
    frameIndex: resolveFrameIndex(route),
  }),
  meta: { requiresAuth: true, layout: 'empty' },
  name: 'Workflow',
  path: '/workview',
}
