import { isEllipse } from '@/modules/Editor/annotationTypes/ellipse'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import { drawEllipseV2 } from '@/modules/Editor/graphics'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { DrawCallback } from '@/modules/Editor/models/layers/types'
import type { View } from '@/modules/Editor/views/view'

export const renderEllipse = (
  drawFn: DrawCallback,
  view: View,
  annotation: Annotation,
  filter: ImageManipulationFilter | null,
): Path2D | void => {
  const isSelected = view.annotationManager.isSelected(annotation.id)
  const isHighlighted = view.annotationManager.isHighlighted(annotation.id)
  const ellipse = annotation.data
  const color = annotation.color

  if (!isEllipse(ellipse)) {
    return
  }

  return drawEllipseV2(drawFn, view.camera, ellipse, color, filter, isHighlighted, isSelected)
}
