import type { VideoRaster } from '@/modules/Editor/models/raster/VideoRaster'
import type { VoxelRaster } from '@/modules/Editor/models/raster/VoxelRaster'

/**
 * Enumerates all the supported types of raster containers that contain
 * and render Mask annotation data.
 */
export enum RasterTypes {
  IMAGE = 'ImageRaster',
  VIDEO = 'VideoRaster',
  VOXEL = 'VoxelRaster',
}

export type MultiFrameRaster = VideoRaster | VoxelRaster
