import type { ToolContext } from '@/modules/Editor/managers/toolManager'
import { TipShape } from '@/modules/Editor/plugins/brush/consts'

export const getTipShape = (context: ToolContext): TipShape => {
  const { currentTool: tool } = context.editor.toolManager
  if (!tool || tool.name !== 'brush_tool') {
    return TipShape.Round
  }

  const activeToolOption = (tool.toolConfig.toolOptions || [])
    .filter((option) => option.category === 'brush-tip-shape')
    .find((option) => option.active)

  if (!activeToolOption || !activeToolOption.id) {
    return TipShape.Round
  }

  return <TipShape>activeToolOption.id
}
