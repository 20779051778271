import type { TeamAction } from '@/store/modules/team/types'
import type { MembershipPayload } from '@/store/types/MembershipPayload'
import * as api from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

export type DeleteMembershipPayload = {
  id: number
}

/**
 * Delete a membership
 */
export const deleteMembership: TeamAction<DeleteMembershipPayload, MembershipPayload> = async (
  { commit },
  params,
) => {
  let response
  try {
    response = await api.remove<MembershipPayload>(`memberships/${params.id}`)
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.TEAM_MEMBERS_DELETE_MEMBERSHIP, params)
  }
  commit('DELETE_MEMBERSHIP', params.id)
  return response
}
