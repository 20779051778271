import type { AnnotationType } from '@/core/annotationTypes'

import type { AnnotationData } from './AnnotationData'
import { isBoundingBox, translateBoundingBox } from './annotationTypes/boundingBox'
import { isEllipse, translateEllipse } from './annotationTypes/ellipse'
import { isEye, translateEye } from './annotationTypes/eye'
import { isKeyPoint, translateKeyPoint } from './annotationTypes/keypoint'
import { isPolygon, translatePolygon } from './annotationTypes/polygon'
import { isPolyline, translatePolyline } from './annotationTypes/polyline'
import { isSimpleTable, translateSimpleTable } from './annotationTypes/simpleTable'
import { isSkeleton, translateSkeleton } from './annotationTypes/skeleton'
import type { IPoint } from './point'

import { inferCurrentAnnotationData } from './inferCurrentAnnotationData'
import type { Annotation } from './models/annotation/Annotation'
import type { View } from './views/view'

export const translateAnnotationData = (
  data: AnnotationData,
  type: AnnotationType,
  offset: IPoint,
): void => {
  if (type === 'polygon' && isPolygon(data)) {
    return translatePolygon(data, offset)
  }

  if (type === 'bounding_box' && isBoundingBox(data)) {
    return translateBoundingBox(data, offset)
  }

  if (type === 'ellipse' && isEllipse(data)) {
    return translateEllipse(data, offset)
  }

  if (type === 'skeleton' && isSkeleton(data)) {
    return translateSkeleton(data, offset)
  }

  if (type === 'eye' && isEye(data)) {
    return translateEye(data, offset)
  }

  if (type === 'line' && isPolyline(data)) {
    return translatePolyline(data, offset)
  }

  if (type === 'keypoint' && isKeyPoint(data)) {
    return translateKeyPoint(data, offset)
  }

  if (type === 'simple_table' && isSimpleTable(data)) {
    return translateSimpleTable(data, offset)
  }

  // graph, string and table do not support moving
}

export const translateAnnotation = (annotation: Annotation, view: View, offset: IPoint): void => {
  const data = inferCurrentAnnotationData(annotation, view.currentFrameIndex)
  translateAnnotationData(data, annotation.type, offset)
}
