import type { Range } from '@/modules/Editor/types'

/**
 * Returns true if the two given ranges overlap.
 *
 * It works by checking that both 1D projections of
 * the rectangles onto the axes principal axes overlap.
 * If both overlap, than the rectangles overlap.
 */
export const rangeOverlaps = (range1: Range, range2: Range): boolean =>
  // Check if projected X lines overlap
  range1.minX < range2.maxX &&
  range2.minX < range1.maxX &&
  // Check if projected Y lines overlap
  range1.minY < range2.maxY &&
  range2.minY < range1.maxY
