import type { Module } from 'vuex'

import type { RootState } from '@/store/types'

import { state } from './state'
import type { DatasetUploadState } from './state'

export { getInitialState } from './state'

import { addFiles } from './actions/addFiles'
import { continuouslyUploadChunks } from './actions/continuouslyUploadChunks'
import { enqueueUploads } from './actions/enqueueUploads'
import { getVideoInfo } from './actions/getVideoInfo'
import { processUploadQueue } from './actions/processUploadQueue'
import { removeFiles } from './actions/removeFiles'
import { startUpload } from './actions/startUpload'
import { updateFiles } from './actions/updateFiles'

import { ADD_FILES } from './mutations/ADD_FILES'
import { ADD_UPLOAD_FILES } from './mutations/ADD_UPLOAD_FILES'
import { REMOVE_FILES } from './mutations/REMOVE_FILES'
import { RESET_ALL } from './mutations/RESET_ALL'
import { RESET_FILES } from './mutations/RESET_FILES'
import { SET_COMMON_PATH } from './mutations/SET_COMMON_PATH'
import { SET_COMMON_TAGS } from './mutations/SET_COMMON_TAGS'
import { SET_COMMON_TAG_CLASSES } from './mutations/SET_COMMON_TAG_CLASSES'
import { SET_CURRENT_UPLOAD_FILE } from './mutations/SET_CURRENT_UPLOAD_FILE'
import { SET_DATASET_ID } from './mutations/SET_DATASET_ID'
import { SET_FILES_DATA } from './mutations/SET_FILES_DATA'
import { SET_FILE_DATA } from './mutations/SET_FILE_DATA'
import { SET_UPLOAD_STATUS } from './mutations/SET_UPLOAD_STATUS'
import { STOP_UPLOAD } from './mutations/STOP_UPLOAD'

const datasetUpload: Module<DatasetUploadState, RootState> = {
  namespaced: true,
  state,
  actions: {
    addFiles,
    continuouslyUploadChunks,
    enqueueUploads,
    getVideoInfo,
    processUploadQueue,
    removeFiles,
    startUpload,
    updateFiles,
  },
  mutations: {
    ADD_FILES,
    ADD_UPLOAD_FILES,
    REMOVE_FILES,
    RESET_FILES,
    RESET_ALL,
    SET_DATASET_ID,
    SET_FILE_DATA,
    SET_FILES_DATA,
    SET_UPLOAD_STATUS,
    STOP_UPLOAD,
    SET_COMMON_PATH,
    SET_COMMON_TAGS,
    SET_COMMON_TAG_CLASSES,
    SET_CURRENT_UPLOAD_FILE,
  },
}

export default datasetUpload
