import type { ClassUsagePayload, DatasetItemFilter } from '@/store/types'
import { get } from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'
import type { ApiResult } from './types'

export type LoadClassUsageParams = Omit<DatasetItemFilter, 'page' | 'sort'> & {
  teamSlug: string
}

/**
 * Simply load class usage for the specified team
 */
export const loadClassUsage = async (
  params: LoadClassUsageParams,
): Promise<ApiResult<ClassUsagePayload>> => {
  const { teamSlug, ...filters } = params
  const path = `/teams/${teamSlug}/annotation_classes/usage`

  try {
    const response = await get<ClassUsagePayload>(path, filters)
    return {
      ok: true,
      data: response.data,
    }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }

    return {
      ...parseError(error, errorMessages.CLASS_USAGE_LOAD),
      ok: false,
    }
  }
}
