import type { Camera } from '@/modules/Editor/camera'
import { DEFAULT_LINE_WIDTH } from '@/modules/Editor/config'
import { buildPath2DImageView } from '@/modules/Editor/graphics/buildPath2DImageView'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import type { EditablePoint } from '@/modules/Editor/point'
import type { DrawCallback, DrawFn } from '@/modules/Editor/models/layers/types'
// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'

import { drawVertex, drawVertexV2 } from './drawVertex'
import { lineWidth } from './lineWidth'
import { strokeStyle } from './strokeStyle'

export function drawOpenPathV2(
  drawFn: DrawCallback,
  camera: Camera,
  path: EditablePoint[],
  color: RGBA,
  filter: ImageManipulationFilter | null,
  isSelected = false,
  path2D: Path2D | undefined = undefined,
): Path2D {
  let openPath2D = new Path2D()

  drawFn((ctx) => {
    ctx.strokeStyle = strokeStyle(color, filter, false, isSelected)

    openPath2D = path2D || buildPath2DImageView(path, false)

    ctx.lineWidth = lineWidth(DEFAULT_LINE_WIDTH) / camera.scale
    ctx.stroke(openPath2D)
  })

  for (const point of path) {
    drawVertexV2(
      (draw?: DrawFn) => {
        drawFn((ctx, canvas) => {
          ctx.lineJoin = 'round'
          draw?.(ctx, canvas)
        })
      },
      camera,
      point,
      filter,
      color,
      isSelected,
      false,
    )
  }

  return openPath2D
}

/**
 * @deprecated
 */
export function drawOpenPath(
  camera: Camera,
  ctx: CanvasRenderingContext2D,
  path: EditablePoint[],
  color: RGBA,
  filter: ImageManipulationFilter | null,
  isSelected = false,
  path2D: Path2D | undefined = undefined,
): Path2D {
  ctx.strokeStyle = strokeStyle(color, filter, false, isSelected)

  const openPath = path2D || buildPath2DImageView(path, false)

  ctx.save()
  camera.imageViewCtxToCanvasViewCtx(ctx)
  ctx.lineWidth = (lineWidth(DEFAULT_LINE_WIDTH) * 1.0) / camera.scale
  ctx.stroke(openPath)
  ctx.restore()

  ctx.lineJoin = 'round'
  for (const point of path) {
    drawVertex(ctx, camera, point, filter, color, isSelected)
  }

  return openPath
}
