import type { AxiosError, AxiosResponse } from 'axios'
import qs from 'qs'
import axios from 'axios'

import { BASE_API } from '@/services/config'
import { captureMessage, setContext } from '@/services/sentry'

import {
  getRefreshToken,
  getToken,
  getTokenExpiration,
  getTokenLifetimeLeft,
  updateToken,
} from './token'
import { isErrorResponse } from './error'
import { ErrorCodes } from './error/errors'
import session from './session'

/**
 * Client used to perform refresh-token related requests to the backend
 *
 */
export const refreshClient = axios.create({
  baseURL: BASE_API,
  paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'comma' }) },
})

/**
 * Automatically add the refresh token to all refresh client requests, if one exists
 */
refreshClient.interceptors.request.use((request) => {
  if (!request.headers) {
    console.info('Request did not have headers')
    return request
  }

  const token = getRefreshToken()

  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  } else {
    console.info('Refresh token not available.')
  }

  return request
})

// primary client for backend requests

type TokenResponse = { token: string; token_expiration: number }

/**
 * Memoized access token request,
 * which helps avoiding firing multiple such requests at the same time
 */
let updateAccessTokenRequest: Promise<AxiosResponse<TokenResponse>> | null = null

/**
 * Requests a new access-token using the stored refresh token.
 *
 * The request is memoized while in progress, and is attached to by subsequent requests,
 * to avoid firing multiple requests in paralel.
 */
const updateAccessToken = async (): Promise<boolean> => {
  try {
    updateAccessTokenRequest =
      updateAccessTokenRequest || refreshClient.get<TokenResponse>('refresh')

    const response = await updateAccessTokenRequest
    updateAccessTokenRequest = null

    updateToken({
      token: response.data.token,
      tokenExpiration: response.data.token_expiration,
    })

    return true
  } catch (error) {
    console.info('Failed to refresh credentials')
    updateAccessTokenRequest = null
    if (!isErrorResponse(error)) {
      throw error
    }

    return false
  }
}

/**
 * How close we have to be to access token expiring, before we try to get a new one.
 */
const TOKEN_EXPIRATION_THRESHOLD_SECONDS = 180

const hasValidAccessToken = (): boolean =>
  !!getToken() && getTokenLifetimeLeft() > TOKEN_EXPIRATION_THRESHOLD_SECONDS

/**
 * Primary client used to perform backend requests.
 */
export const client = axios.create({
  baseURL: BASE_API,
  paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'comma' }) },
})

/**
 * Always add an authorisation header if an unexpired access token is available or if it can be
 * retrieved using the refresh token.
 */
client.interceptors.request.use(async (request) => {
  if (!request.headers) {
    return request
  }

  // We try to get a new access token if we don't have a valid one
  !hasValidAccessToken() && (await updateAccessToken())

  const token = getToken()

  // We don't want to place an expired token as a header,
  // because the backend will then respond with 401,
  // even on public endpoints that wouldn't otherwise need authorisation
  // We try to not add the token on public endpoints anyway,
  // but this is a safety net in case we miss a few
  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }

  return request
}, undefined)

type BackendError = {
  errors?: {
    /**
     * Enumeration code for the error, such as
     */
    code?: ErrorCodes
    /**
     * This one is sometimes filled in and contains a map with extra data about the error
     */
    detail?: object
    /**
     * This one is almost always filled in and is usually formatted to be shown to the user
     */
    message?: string
    /**
     * Same http status code the response itself has
     */
    status?: number
  }
}

/** *
 * Adds last axios error that ocurred to sentry context, so we can get more information
 * when improperly handled errors get reported to sentry.
 *
 * For example, request cancellations throw errors, which should be handled and
 * silenced by us. If we don't do that, they end up on sentry, sometimes wthout
 * a stacktrace, due to being reported via console.error, so this allows us to
 * track down their cause.
 */
export const addErrorContextToSentry = (
  error: AxiosError<BackendError>,
): Promise<AxiosResponse<unknown>> => {
  setContext('lastErroredAxiosRequest', {
    url: error.config?.url,
    baseURL: error.config?.baseURL,
    errorCode: error.code,
    errorName: error.name,
    errorMessage: error.message,
    errorCause: error.cause,
    errorStatus: error.status,
    errorBody: error.response?.data,
    isAxiosError: error.isAxiosError,
  })
  return Promise.reject(error)
}

client.interceptors.response.use(undefined, addErrorContextToSentry)

/**
 * Performs session logout if a request fails with a 401 NOT_AUTHENTICATED
 *
 * Because the 'addAccessTokenHeader' interceptor will add the access token to the request as long
 * as there is a valid one, and even try to get a valid one first, this can only happen if no
 * token was added, but the endpoint we tried to reach needs one.
 * That can only be interpreted as "our session is fully expired".
 */
client.interceptors.response.use(undefined, async (error: AxiosError<BackendError>) => {
  const didSessionExpire =
    error.response?.status === 401 &&
    // "NOT_AUTHENTICATED" and "NOT_AUTHORIZED" both return a 401, but the code is different
    // We do not want to log the user out in the case of "NOT_AUTHORIZED" as there are parts of the
    // app where such requests will randomly fire
    error.response?.data.errors?.code === ErrorCodes.NOT_AUTHENTICATED

  if (didSessionExpire && getRefreshToken() && error.config) {
    // While we try to procatively keep the session alive,
    // this relies 100% on the user's system clock being correct.
    // If the clock is late by more than TOKEN_EXPIRATION_THRESHOLD_SECONDS,
    // proactive renewal will not work, in which case,
    // we try to recover the session here once.
    console.info('Session expired. Trying to recover session')

    if (await updateAccessToken()) {
      console.info('Session recovered. Retrying!')
      return client.request(error.config)
    }
  }

  if (didSessionExpire) {
    console.info('Session expired. Redirecting to login...')
    if (hasValidAccessToken()) {
      // sanity check that we don't somehow have a race condition
      // where we sent a request without an access token, but we got one in the meantime
      // we suspect this is a possibility when users are working across multiple tabs
      captureMessage('Session expired, but valid credentials still exist', {
        extra: {
          // we record both these values as extra to rule out timezone issues
          lifetimeLeft: getTokenLifetimeLeft(),
          expiration: getTokenExpiration(),
        },
      })
    }
    session.logout()
  }

  return Promise.reject(error)
})

/**
 * Parses an error response as JSON, even if the success response is an `arraybuffer`
 *
 * Allows proper parsing of errors when downloading files from backend.
 */
client.interceptors.response.use(undefined, (error: AxiosError): Promise<never> => {
  if (
    error.config?.responseType === 'arraybuffer' &&
    error.response &&
    error.response.headers['content-type'] === 'application/json'
  ) {
    error.response.data = JSON.parse(error.response.data as string)
  }

  return Promise.reject(error)
})
