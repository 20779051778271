import type { IPoint, EditablePoint } from '@/modules/Editor/point'
import { createEditablePoint } from '@/modules/Editor/point'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'

/**
 * Converts the given vanilla path to a valid annotation path.
 * @param context the tool context
 * @param path a list of { x, y } points
 */
export const resolveAnnotationPath = (context: ToolContext, path: IPoint[]): EditablePoint[] => {
  const editor = context.editor
  const annotationPath = path.map((point) => {
    const imagePoint = { ...point }
    const canvasPoint = editor.activeView.camera.imageViewToCanvasView(imagePoint)
    return createEditablePoint(editor.activeView.camera.canvasViewToImageView(canvasPoint))
  })
  return annotationPath
}
