import { MedicalVolumePlane } from '@/modules/Editor/MedicalMetadata'
import type { ExtendedFileMetadata } from '@/modules/Editor/metadata'
import { getSlotNamesPerPlane } from '@/modules/Editor/utils/radiology/getSlotNamesPerPlane'

import { Plane } from './Plane'

/**
 * Single file uploads:
 * - Slot names '0.1', '0.2' and '0.3' (legacy uploads) are used for the items
 *   uploaded with the MPR toggle on. With DYNAMIC_MPR feature flag enabled
 *   the toggle is no longer available.
 *
 * - Slot name '0' is used for the items uploaded with the MPR toggle off,
 *   the plane is always Z in this case.
 *
 * Multi-slot file uploads:
 * - Slots can be named in any way, each slot needs to always render the Z plane.
 */

export const getRasterPlaneMapForAcquisitionPlane = (
  metadata: ExtendedFileMetadata | undefined,
): Partial<Record<string, Plane>> => {
  const acquisitionPlane = metadata?.primary_plane
  const slotNames = getSlotNamesPerPlane(metadata?.medical)

  if (acquisitionPlane === MedicalVolumePlane.CORONAL) {
    return {
      [slotNames.AXIAL]: Plane.Y,
      [slotNames.CORONAL]: Plane.Z, // acquired in the coronal plane
      [slotNames.SAGITTAL]: Plane.X,
    }
  }

  if (acquisitionPlane === MedicalVolumePlane.SAGITTAL) {
    return {
      [slotNames.AXIAL]: Plane.Y,
      [slotNames.CORONAL]: Plane.X,
      [slotNames.SAGITTAL]: Plane.Z, // acquired in the sagittal plane
    }
  }

  return {
    [slotNames.AXIAL]: Plane.Z, // acquired in the axial plane
    [slotNames.CORONAL]: Plane.Y,
    [slotNames.SAGITTAL]: Plane.X,
  }
}
