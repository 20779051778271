import type { ToolOption } from '@/modules/Editor/tools/types'
import { MaskBrushDimension } from '@/modules/Editor/tools/types'
import { ToolName, SubToolName } from '@/modules/Editor/tools/types'
import type { Keybinding } from '@/modules/Editor/keybinding'

export interface ToolConfig {
  name: ToolName
  keybindings: Keybinding[]
  sub: false
  toolOptions?: ToolOption[]
}

export interface SubToolConfig {
  name: SubToolName
  keybindings: Keybinding[]
  sub: true
  toolOptions?: ToolOption[]
}

export const editToolConfig: ToolConfig = {
  name: ToolName.Edit,
  keybindings: [
    { keys: ['v'], action: 'edit_tool.activate' },
    { keys: ['shift', 'j'], action: 'edit_tool.restore_joints' },
  ],
  sub: false,
  toolOptions: [
    { id: 'restore_joints', active: false, props: {}, action: 'edit_tool.restore_joints' },
    {
      id: 'polygon_merge',
      active: false,
      props: {},
      category: 'polygon-bool',
      action: 'edit_tool.activate_polygon_merge',
    },
    {
      id: 'polygon_subtract',
      active: false,
      props: {},
      category: 'polygon-bool',
      action: 'edit_tool.activate_polygon_subtract',
    },
  ],
}

export const boundingBoxToolConfig: ToolConfig = {
  name: ToolName.BoundingBox,
  keybindings: [
    { keys: ['b'], action: 'bounding_box_tool.activate' },
    { keys: ['Escape'], action: 'bounding_box_tool.cancel', when: 'active' },
  ],
  sub: false,
}

export const keypointToolConfig: ToolConfig = {
  name: ToolName.Keypoint,
  keybindings: [
    { keys: ['k'], action: 'keypoint_tool.activate' },
    { keys: ['Escape'], action: 'keypoint_tool.cancel', when: 'active' },
  ],
  sub: false,
}

export const polygonToolConfig: ToolConfig = {
  name: ToolName.Polygon,
  keybindings: [
    { keys: ['p'], action: 'polygon_tool.activate' },
    { keys: ['Escape'], action: 'polygon_tool.cancel', when: 'active' },
    { keys: ['Enter'], action: 'polygon_tool.close', when: 'active' },
  ],
  sub: false,
}

export const brushToolConfig: ToolConfig = {
  name: ToolName.Brush,
  keybindings: [
    { keys: ['f'], action: ['brush_tool.activate', 'brush_tool.activate_brush'] },
    { keys: ['e'], action: ['brush_tool.activate', 'brush_tool.activate_eraser'] },
    { keys: ['ctrl', 'r'], action: ['brush_tool.activate', 'brush_tool.change_brush_dimension'] },
    { keys: ['ctrl', 't'], action: 'brush_tool.toggle_brush_threshold_enabled' },
    { keys: ['['], action: 'brush_tool.shrink' },
    { keys: [']'], action: 'brush_tool.grow' },
    { keys: ['Escape'], action: 'brush_tool.cancel', when: 'active' },
    { keys: ['Enter'], action: 'brush_tool.save', when: 'active' },
  ],
  sub: false,
  toolOptions: [
    {
      id: 'brush',
      active: true,
      props: {},
      category: 'brush-mode',
      action: 'brush_tool.activate_brush',
    },
    {
      id: 'eraser',
      active: false,
      props: {},
      category: 'brush-mode',
      action: 'brush_tool.activate_eraser',
    },
    {
      id: 'dimension_switcher',
      active: false,
      props: {
        dimension: MaskBrushDimension.Paint2D,
      },
      category: 'brush-dimension',
      action: '',
    },
    {
      id: 'round',
      active: true,
      props: {},
      category: 'brush-tip-shape',
      action: 'brush_tool.activate_round_tip',
    },
    {
      id: 'squared',
      active: false,
      props: {},
      category: 'brush-tip-shape',
      action: 'brush_tool.activate_squared_tip',
    },
    {
      id: 'size_and_threshold',
      active: false,
      props: {
        size: 10,
        threshold: {
          min: Number.MIN_SAFE_INTEGER,
          max: Number.MAX_SAFE_INTEGER,
        },
        isThresholdEnabled: false,
      },
      category: 'brush-size-and-threshold',
      action: '',
    },
    {
      id: 'brush-size',
      active: false,
      props: { value: 10, commandName: 'brush_tool.set_brush_size' },
      action: '',
    },
  ],
}

export const ellipseToolConfig: ToolConfig = {
  name: ToolName.Ellipse,
  keybindings: [
    { keys: ['o'], action: 'ellipse_tool.activate' },
    { keys: ['Escape'], action: 'ellipse_tool.cancel', when: 'active' },
  ],
  sub: false,
}

export const polylineToolConfig: ToolConfig = {
  name: ToolName.Polyline,
  keybindings: [
    { keys: ['l'], action: 'polyline_tool.activate' },
    { keys: ['Escape'], action: 'polyline_tool.cancel', when: 'active' },
    { keys: ['Enter'], action: 'polyline_tool.confirm', when: 'active' },
  ],
  sub: false,
}

export const clickerToolConfig: ToolConfig = {
  name: ToolName.Clicker,
  keybindings: [
    { keys: ['n'], action: 'clicker_tool.activate' },
    { keys: ['Escape'], action: 'clicker_tool.cancel', when: 'active' },
    { keys: ['Enter'], action: 'clicker_tool.complete', when: 'active' },
    { keys: ['meta', 'Enter'], action: 'clicker_tool.infer', when: 'active' },
  ],
  sub: false,
}

export const autoAnnotateToolConfig: SubToolConfig = {
  name: SubToolName.AutoAnnotate,
  keybindings: [{ keys: ['shift', 'n'], action: 'clicker_tool.resume' }],
  sub: true,
}

export const emptyToolConfig: SubToolConfig = {
  name: SubToolName.Empty,
  keybindings: [],
  sub: true,
}

export const windowLevelToolConfig: ToolConfig = {
  name: ToolName.WindowLevel,
  keybindings: [{ keys: ['/'], action: 'window_level_tool.activate' }],
  sub: false,
}

export const zoomToolConfig: ToolConfig = {
  name: ToolName.Zoom,
  keybindings: [
    { keys: ['z'], action: 'zoom_tool.activate' },
    { keys: ['Escape'], action: 'zoom_tool.cancel', when: 'active' },
  ],
  sub: false,
}

export const commentatorToolConfig: ToolConfig = {
  name: ToolName.Commentator,
  keybindings: [
    { keys: ['c'], action: 'commentator.activate' },
    { keys: ['Escape'], action: 'commentator.cancel', when: 'active' },
  ],
  sub: false,
}

export const directionalVectorToolConfig: SubToolConfig = {
  name: SubToolName.DirectionalVector,
  keybindings: [],
  sub: true,
}

export const crosshairsToolConfig: ToolConfig = {
  name: ToolName.Crosshairs,
  keybindings: [{ keys: ['r'], action: 'crosshairs_tool.activate' }],
  sub: false,
}

export const skeletonToolConfig: ToolConfig = {
  name: ToolName.Skeleton,
  keybindings: [
    { keys: ['j'], action: 'skeleton_tool.activate' },
    { keys: ['Escape'], action: 'skeleton_tool.cancel', when: 'active' },
  ],
  sub: false,
}

export const eyeToolConfig: ToolConfig = {
  name: ToolName.Eye,
  keybindings: [
    { keys: ['ctrl', 'shift', 'j'], action: 'eye_tool.activate' },
    { keys: ['Escape'], action: 'eye_tool.cancel', when: 'active' },
  ],
  sub: false,
}

export const selectToolConfig: ToolConfig = {
  name: ToolName.Select,
  keybindings: [{ keys: ['v'], action: 'select_tool.activate' }],
  sub: false,
}

export const simpleTableToolConfig: ToolConfig = {
  name: ToolName.SimpleTable,
  keybindings: [
    { keys: ['t'], action: 'simple_table_tool.activate' },
    { keys: ['Escape'], action: 'simple_table_tool.cancel', when: 'active' },
  ],
  sub: false,
  toolOptions: [
    { id: 'rows', active: false, props: { value: 2 }, action: '' },
    { id: 'cols', active: false, props: { value: 3 }, action: '' },
  ],
}
