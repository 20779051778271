import { LoadingStatus } from '@/store/types'
import type { AnnotationTypePayload } from '@/store/types/AnnotationTypePayload'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAnnotationTypesStore = defineStore('annotationTypes', () => {
  const types = ref<AnnotationTypePayload[]>([])
  const typesLoadingStatus = ref<LoadingStatus>(LoadingStatus.Unloaded)

  return {
    types,
    typesLoadingStatus,
  }
})
