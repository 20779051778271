export const APP_INIT = 'app:init'

export const ANNOTATION_CREATE_FUNCTION_CALL = 'annotation:create:function-call'

export const JUMP_TO_FRAME_TIMELINE_FRAME_CLICK = 'jump-to-frame:timeline-frame:click'
export const SYNC_VIDEO_PLAYBACK_CALL = 'sync-video-playback:call'

export const FIRST_ANNOTATION_RENDERED = 'first:annotation:rendered'
export const ACTIVE_ANNOTATION_RENDERED = 'active:annotation:rendered'
export const RENDER_IMAGE_AND_ANNOTATIONS = 'render:image-and-annotations'
export const RENDERED_CALL = 'render:call'

export const OVERLAYS_HTML_SUB_ANNOTATION_RENDERED = 'overlays:html:sub-annotation:rendered'

export const ALL_OVERLAYS_RENDERED = 'overlays:all:rendered'

export const LAYER_BAR_ITEM_MOUNTED = 'layer-bar:item:mounted'

export const GET_RESPONSE_WITH_ANNOTATIONS = 'get-response:with-annotations'

export const PAGINATION_REQUEST_ANNOTATION_FRAME_DATA = 'pagination:request:annotation-frame-data'
export const PAGINATION_GET_RESPONSE_WITH_ANNOTATION_FRAME_DATA =
  'pagination:get-response:with-annotation-frame-data'
export const PAGINATION_GET_RESPONSE_WITH_ANNOTATION_METADATA =
  'pagination:get-response:with-annotation-metadata'
