import { CameraEvents } from '@/modules/Editor/eventBus'

import { euclideanDistance } from './algebra/euclideanDistance'
import type { IPoint } from './point'
import { addPoints, addPointMutate, divScalar, mulScalar, subPoints } from './point'

const DEFAULT_CAMERA_SIZE = 1
export const MAX_SCALE = 50
export const CANVAS_CONTENT_VISIBILITY_MARGIN = 20
export const CURSOR_FIRST_VERTEX_MAX_DISTANCE = 8

/*
  The ZOOM_ADJUSTMENT_PIXEL_CUTOFF is used to lower the zoom limit past the usual minimum
  on small images (24x24px for example). Images with dimensions less than 500 pixels can be
  zoomed out further than normal, the smaller the image the greater the zoom adjustment factor.
*/

export const ZOOM_ADJUSTMENT_PIXEL_CUTOFF = 500

export type CameraOffset = IPoint

export type CameraConfig = {
  scale: number
  offset: CameraOffset
  centerY?: boolean
  imageDimension?: { width: number; height: number }
}

export type CameraEvent = { viewId: string; slotName: string }

const CameraDefaultConfig: CameraConfig = {
  scale: 1.0,
  offset: { x: 0, y: 0 },
}

export class Camera {
  // TODO DAR-2188: the goal is to later remove the `vireId` and just rely on `slotName`
  private cameraEvent = { viewId: 'none', slotName: 'none' }

  public width: number = DEFAULT_CAMERA_SIZE
  public height: number = DEFAULT_CAMERA_SIZE
  public image: { width: number; height: number } = {
    width: DEFAULT_CAMERA_SIZE,
    height: DEFAULT_CAMERA_SIZE,
  }
  private isImageLocked: boolean = false
  private hasBeenModified: boolean = false
  private onCleanup: (() => void)[] = []

  private _centerY: boolean = false

  private _offset: CameraOffset = { x: 0, y: 0 }

  /**
   * Mute the camera events, so that nothing is emitted by the camera
   */
  private _muted: boolean = false
  set muted(value: boolean) {
    this._muted = value
  }
  get muted(): boolean {
    return this._muted
  }

  public get offset(): CameraOffset {
    return this._offset
  }

  public set offset(value: CameraOffset) {
    this._offset = value
    this.hasBeenModified = true
    if (this.muted) {
      return
    }
    CameraEvents.offsetChanged.emit(this.cameraEvent, this._offset)
  }

  private _scale: number = 1.0

  public get scale(): number {
    return this._scale
  }

  public set scale(value: number) {
    this._scale = value
    this.hasBeenModified = true
    if (this.muted) {
      return
    }
    CameraEvents.scaleChanged.emit(this.cameraEvent, this._scale)
  }

  constructor(viewId: string, slotName: string, initialConfig: CameraConfig = CameraDefaultConfig) {
    this.setInitialConfig(initialConfig)

    this.cameraEvent = { viewId, slotName }

    CameraEvents.setConfig.on(this.setConfigHandler)
  }

  public cleanup(): void {
    CameraEvents.setConfig.off(this.setConfigHandler)
    this.onCleanup.forEach((cleanupCallback) => cleanupCallback())
  }

  private setInitialConfig(initalConfig: CameraConfig): void {
    if (initalConfig.imageDimension) {
      this.setImage(initalConfig.imageDimension)
    }

    if (initalConfig.scale && Number.isFinite(initalConfig.scale)) {
      this._scale = initalConfig.scale
      if (this.muted) {
        return
      }
      CameraEvents.scaleChanged.emit(this.cameraEvent, this._scale)
    }

    if (initalConfig.offset) {
      this._offset = initalConfig.offset
      if (this.muted) {
        return
      }
      CameraEvents.offsetChanged.emit(this.cameraEvent, this._offset)
    }
  }

  public init(): void {
    this.initialScaleToFit()
  }

  private setConfigHandler = (event: CameraEvent, config: Partial<CameraConfig>): void => {
    const { viewId, slotName } = event
    if (viewId !== this.cameraEvent.viewId || slotName !== this.cameraEvent.slotName) {
      return
    }
    this.setConfig(config)
  }

  public setConfig(config: Partial<CameraConfig>): void {
    if (config.scale && Number.isFinite(config.scale)) {
      this.scale = config.scale
    }

    if (config.offset) {
      this.offset = config.offset
    }

    if (config.centerY) {
      this._centerY = config.centerY
    }
  }

  public get cameraConfig(): CameraConfig {
    return {
      offset: this.offset,
      scale: this.scale,
    }
  }

  /**
   * Checks if camera has default config.
   *
   * NOTE: in use to run .scaleToFit() for non changed config only
   */
  public get isDefaultConfig(): boolean {
    return (
      this.scale === CameraDefaultConfig.scale &&
      this.offset.x === CameraDefaultConfig.offset.x &&
      this.offset.y === CameraDefaultConfig.offset.y
    )
  }

  /**
   * Set viewport width and height at the same time.
   */
  public setDimensions(width: number, height: number): void {
    if (this.width === width && this.height === height) {
      return
    }

    this.width = width
    this.height = height

    if (!this.hasBeenModified) {
      this.scaleToFit()
    }
    if (this.muted) {
      return
    }
    CameraEvents.setDimensions.emit(this.cameraEvent, { width: this.width, height: this.height })
  }

  public setWidth(width: number): void {
    this.width = width
    if (!this.hasBeenModified) {
      this.scaleToFit()
    }
    if (this.muted) {
      return
    }
    CameraEvents.setWidth.emit(this.cameraEvent, this.width)
    CameraEvents.setDimensions.emit(this.cameraEvent, { width: this.width, height: this.height })
  }

  public setHeight(height: number): void {
    this.height = height
    if (!this.hasBeenModified) {
      this.scaleToFit()
    }
    if (this.muted) {
      return
    }
    CameraEvents.setHeight.emit(this.cameraEvent, this.height)
    CameraEvents.setDimensions.emit(this.cameraEvent, { width: this.width, height: this.height })
  }

  public getOffset(): CameraOffset {
    return this._offset
  }

  public getMinZoom(): number {
    // allow small images to be zoomed out greatly while not affecting normal sized images
    const smallestImageDimension = Math.min(this.image.height, this.image.width)
    const zoomAdjustmentFactor = Math.min(smallestImageDimension / ZOOM_ADJUSTMENT_PIXEL_CUTOFF, 1)
    const hRatio = this.height / this.image.height
    const wRatio = this.width / this.image.width
    return (zoomAdjustmentFactor * Math.min(hRatio, wRatio)) / 2
  }

  /**
   * Locks image dimensions so they are not updated when a new image is set.
   * This is especially useful on DICOM files where the image size is
   * identical for all frames and we want to avoid unnecessary computations.
   */
  public lockImage(): void {
    this.isImageLocked = true
  }

  public setImage(image: { width: number; height: number }): void {
    if (this.isImageLocked) {
      return
    }

    // Prevents trigger of the event for the same image size
    if (
      (image.width <= 0 && image.height <= 0) ||
      (this.image.width === image.width && this.image.height === image.height)
    ) {
      return
    }

    this.image = {
      width: image.width,
      height: image.height,
    }
    if (this.muted) {
      return
    }
    CameraEvents.setImageSize.emit(this.cameraEvent, this.image)
  }

  public get scaleToFitValue(): number {
    const hRatio = this.height / this.image.height
    const wRatio = this.width / this.image.width
    return Math.min(hRatio, wRatio)
  }

  /** Scales the image to fit tightly within the viewport. */
  public scaleToFit(): void {
    if (
      this.width === DEFAULT_CAMERA_SIZE ||
      this.height === DEFAULT_CAMERA_SIZE ||
      this.image.width === DEFAULT_CAMERA_SIZE ||
      this.image.height === DEFAULT_CAMERA_SIZE
    ) {
      return
    }

    this._scale = this.scaleToFitValue
    if (!this.muted) {
      CameraEvents.scaleChanged.emit(this.cameraEvent, this._scale)
    }

    this.updateOffset()
  }

  /**
   * When the camera scale or offset is unchanged (initial state) then run a full scale to fit.
   * When scale or offsets were changed, then just update the offset to keep the given scale.
   */
  public initialScaleToFit(): void {
    if (!this.isDefaultConfig) {
      return
    }

    this.scaleToFit()
  }

  public updateOffset(): void {
    const xBorder = this.width - this.image.width * this._scale

    const x = -xBorder / 2
    let y = 0

    if (this._centerY) {
      const yBorder = this.height - this.image.height * this._scale

      y = -yBorder / 2
    }

    this._offset = { x, y }
    if (this.muted) {
      return
    }
    CameraEvents.offsetChanged.emit(this.cameraEvent, this._offset)
  }

  public setOffset(point: IPoint): void {
    this.hasBeenModified = true
    this.offset = point
  }

  public canvasViewToImageView(point: IPoint): IPoint {
    return {
      x: (point.x + this.offset.x) / this.scale,
      y: (point.y + this.offset.y) / this.scale,
    }
  }

  public imageViewToCanvasView(point: IPoint): IPoint {
    return {
      x: point.x * this.scale - this.offset.x,
      y: point.y * this.scale - this.offset.y,
    }
  }

  // Translate and scales the CanvasRenderingContext so that a Path2D in Image coordinates
  // gets drawn correctly in Canvas coordinates
  public imageViewCtxToCanvasViewCtx(ctx: CanvasRenderingContext2D): void {
    ctx.scale(this.scale, this.scale)
    ctx.translate(-this.offset.x / this.scale, -this.offset.y / this.scale)
  }

  public cursorIsClosingPath(cursorPoint: IPoint, initialPoint: IPoint): boolean {
    return (
      euclideanDistance(this.canvasViewToImageView(cursorPoint), initialPoint) <
      CURSOR_FIRST_VERTEX_MAX_DISTANCE / this.scale
    )
  }

  public drawImageParams(image: { width: number; height: number }): number[] {
    return [-this.offset.x, -this.offset.y, image.width * this.scale, image.height * this.scale]
  }

  /**
   * Zoom to a box where p1 is the topLeft corner and p2 is the bottomRight corner.
   *
   * You can optionally specify a desired scale, which will be otherwise
   * automatically estimated to fit the coorinated within the zoomed screen
   */
  public zoomToBox(p1: IPoint, p2: IPoint, scale?: number): void {
    const srcInitialPoint = mulScalar(addPoints(p1, this.offset), 1.0 / this.scale)
    const srcEndPoint = mulScalar(addPoints(p2, this.offset), 1.0 / this.scale)

    if (scale) {
      this.scale = scale
    } else {
      const nw = Math.abs(srcEndPoint.x - srcInitialPoint.x)
      const nh = Math.abs(srcEndPoint.y - srcInitialPoint.y)
      this.scale =
        this.width / this.height < nw / nh
          ? Math.min(this.width / nw, MAX_SCALE)
          : Math.min(this.height / nh, MAX_SCALE)
    }

    const nRectStart = mulScalar(
      {
        x: Math.min(srcInitialPoint.x, srcEndPoint.x),
        y: Math.min(srcInitialPoint.y, srcEndPoint.y),
      },
      this.scale,
    )
    const nRectEnd = mulScalar(
      {
        x: Math.max(srcInitialPoint.x, srcEndPoint.x),
        y: Math.max(srcInitialPoint.y, srcEndPoint.y),
      },
      this.scale,
    )
    const viewportEnd = addPoints(nRectStart, { x: this.width, y: this.height })

    this.setOffset(subPoints(nRectStart, mulScalar(subPoints(viewportEnd, nRectEnd), 0.5)))
  }

  zoom(magnificationFactor: number, offset: IPoint): void {
    magnificationFactor > 1
      ? this.zoomIn(offset, magnificationFactor)
      : this.zoomOut(offset, 1 / magnificationFactor)
  }

  zoomIn(offset: IPoint, magnificationFactor = 1.25): void {
    if (this.scale * magnificationFactor > MAX_SCALE) {
      return
    }

    // this is the mouse cursors position in the unscaled image
    const srcPosition = mulScalar(addPoints(offset, this.offset), 1.0 / this.scale)
    this.scale = this.scale * magnificationFactor
    // then we zoom and move the camera to keep the viewport unchanged
    this.setOffset(
      addPoints(mulScalar(srcPosition, this.scale - 1), subPoints(srcPosition, offset)),
    )
  }

  zoomOut(offset: IPoint, magnificationFactor = 1.25): void {
    const srcPosition = mulScalar(addPoints(offset, this.offset), 1.0 / this.scale)
    this.scale =
      this.scale / magnificationFactor < this.getMinZoom()
        ? this.getMinZoom()
        : this.scale / magnificationFactor
    this.setOffset(
      addPoints(mulScalar(srcPosition, this.scale - 1), subPoints(srcPosition, offset)),
    )
  }

  scroll(delta: IPoint, scalingFactor = 2): void {
    delta = divScalar(delta, scalingFactor)
    addPointMutate(this._offset, delta)

    const { height, width } = this.image

    const maxHorizontalOffset = width * this.scale - CANVAS_CONTENT_VISIBILITY_MARGIN
    const minHorizontalOffset = -this.width + CANVAS_CONTENT_VISIBILITY_MARGIN
    const maxVerticalOffset = height * this.scale - CANVAS_CONTENT_VISIBILITY_MARGIN
    const minVerticalOffset = -this.height + CANVAS_CONTENT_VISIBILITY_MARGIN
    if (this._offset.x > maxHorizontalOffset) {
      this._offset.x = maxHorizontalOffset
    } else if (this._offset.x < minHorizontalOffset) {
      this._offset.x = minHorizontalOffset
    }
    if (this._offset.y > maxVerticalOffset) {
      this._offset.y = maxVerticalOffset
    } else if (this._offset.y < minVerticalOffset) {
      this._offset.y = minVerticalOffset
    }

    if (this.muted) {
      return
    }
    CameraEvents.offsetChanged.emit(this.cameraEvent, this._offset)
  }
}
