import type { LoginResponsePayload } from '@/store/types'
import { post } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

type Params = {
  email: string
  password: string
  token: string
}

export const login2fa = async (
  params: Params,
): Promise<ParsedError | { data: LoginResponsePayload }> => {
  const { email, password, token } = params
  const path = 'users/authenticate/2fa'

  try {
    const response = await post<LoginResponsePayload>(path, { email, password, token })
    return { data: response.data }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.AUTH_CONFIRM_2FA)
  }
}
