import type { Camera } from '@/modules/Editor/camera'
import type { BBox } from '@/modules/Editor/types'

/**
 * Returns true for bbox in the camera's viewport
 */
export const isVisible = (camera: Camera, bbox: BBox | undefined): boolean => {
  if (!bbox) {
    return true
  }

  const { x, y, width, height } = bbox
  const halfWidth = width / 2
  const halfHeight = height / 2
  if (x === Infinity || y === Infinity) {
    return true
  }

  const rect1 = {
    x: x - halfWidth,
    y: y - halfHeight,
    w: width,
    h: height,
  }
  const rect2 = {
    x: camera.offset.x / camera.scale,
    y: camera.offset.y / camera.scale,
    w: camera.width / camera.scale,
    h: camera.height / camera.scale,
  }

  if (
    rect1.x < rect2.x + rect2.w &&
    rect1.x + rect1.w > rect2.x &&
    rect1.y < rect2.y + rect2.h &&
    rect1.h + rect1.y > rect2.y
  ) {
    return true
  }

  return false
}

export const isEmptyPixel = (pixel: ImageData): boolean => {
  if (pixel.width > 1 || pixel.height > 1) {
    throw new Error('ImageData with width = 1 & height = 1 expected!')
  }
  const { data } = pixel

  // r, g, b, a - equal 0 then it is considered an empty pixel
  return !data[0] && !data[1] && !data[2] && !data[3]
}
