import { signFile, sendFile } from '@/modules/Datasets/urlsigner'
import { useToast } from '@/uiKit/Toast/useToast'
import type { DatasetUploadAction, UploadFileData } from '@/store/modules/datasetUpload/types'
import { confirmFileUpload } from '@/backend/darwin/confirmFileUpload'

export const processUploadQueue: DatasetUploadAction<void, void> = async ({ commit, state }) => {
  const chunk = state.files
    .filter((u) => u.data.status === 'queued')
    .map(async (uploadFile) => {
      const { file, data } = uploadFile
      const { blocked } = data

      const config = { signingURL: data.signingURL }

      const fileData: Partial<UploadFileData> = blocked
        ? { blocked, status: 'uploaded-reported' }
        : { status: 'signing' }
      commit('SET_FILE_DATA', { uploadFile, data: fileData })

      if (data.blocked) {
        return
      }

      let signResponse

      try {
        signResponse = await signFile(file, config)
      } catch {
        return commit('SET_FILE_DATA', { uploadFile, data: { status: 'error-signing' } })
      }

      commit('SET_FILE_DATA', { uploadFile, data: { status: 'uploading', ...signResponse } })

      const sendConfig = {
        ...signResponse,
        onProgress(sentBytes: number, totalBytes: number): void {
          commit('SET_FILE_DATA', { uploadFile, data: { sentBytes, totalBytes } })
        },
      }

      const sendResponse = await sendFile(file, sendConfig)

      if (!sendResponse.success) {
        useToast().warning({
          meta: { title: `${file.name} could not be uploaded.` },
          duration: 3000,
        })
        return commit('SET_FILE_DATA', { uploadFile, data: { status: 'error-uploading' } })
      }

      commit('SET_FILE_DATA', { uploadFile, data: { status: 'reporting' } })
      const { teamSlug, uploadId } = data
      if (!teamSlug || !uploadId) {
        return
      }
      const response = await confirmFileUpload({ teamSlug, uploadId })

      const status = response.ok ? 'uploaded-reported' : 'error-reporting'
      commit('SET_FILE_DATA', { uploadFile, data: { status } })
    })

  await Promise.all(chunk)
}
