import type { SequenceData } from '@/core/annotations'
import { getVideoOrVoxelDataMapping } from '@/modules/Editor/plugins/mask/utils/shared/getVideoOrVoxelDataMapping'
import { decodeDenseRLE } from '@/modules/Editor/plugins/mask/rle/denseRle'
import type { MultiFrameRaster } from '@/modules/Editor/models/raster/rasterTypes'

/**
 * Deserializes the raster annotation and creates a VideoRaster.
 * The `applyCorrectionForLabelIndexes` flag referrs to the correction of the label index mapping.
 * More on the topic can be found below where the flag is checked
 */
export const deserializeVideoOrVoxelRaster = (
  raster: MultiFrameRaster,
  videoRasterLayerDataFrames: SequenceData['frames'],
  annotationIdToClassIdMapping: Record<string, number>,
  applyCorrectionForLabelIndexes: boolean = true,
): void => {
  // This variable is used to get all of the masks used on the whole video raster.
  const frames = videoRasterLayerDataFrames
  const dataMapping = getVideoOrVoxelDataMapping(frames)

  Object.keys(frames).forEach((frameIndexString) => {
    const frameIndex = Number(frameIndexString)
    const frame = frames[frameIndex]
    const rasterLayer = frame.raster_layer
    const frameLabelCorrection = dataMapping.frameLabelIndexCorrection[frameIndex]

    if (rasterLayer === undefined) {
      throw new Error('No raster_layer property on raster layer annotation')
    }

    const denseRLE = rasterLayer.dense_rle
    const totalPixels = rasterLayer.total_pixels

    if (!denseRLE || denseRLE[0] === undefined) {
      return
    }

    const { mask, boundsPerLabelIndex } = decodeDenseRLE(denseRLE, totalPixels, raster.width)

    /**
     *  Some annotation id <=> label index association can be wrong from the BE.
     *  Every frame has label index starting from 1
     *  Example:
     *  {
     *    frame1: { annotation_id_1: 1, annotation_id_2: 2 },
     *    frame2: { annotation_id_2: 1 }
     *  }
     *  In this case, annotation_id_2 was associated with label index 2 in frame1 and 1 in frame2.
     *  This loop is to apply the correction to keep it consistent, and doesn't need to be
     *  applied when the deserialization is triggered by an undo/redo action, as the data is
     *  already normalised
     */
    if (applyCorrectionForLabelIndexes) {
      for (let pixelIndex = 0; pixelIndex < mask.length; pixelIndex++) {
        const maskLabelIndex = mask[pixelIndex]
        if (maskLabelIndex === 0) {
          continue
        }
        mask[pixelIndex] = frameLabelCorrection[maskLabelIndex]
      }
    }
    raster.setActiveBufferForFrame(mask, frameIndex)

    // Set bounds for rasters on frame.
    Object.keys(rasterLayer.mask_annotation_ids_mapping).forEach((annotationId) => {
      const frameLabelIndex = rasterLayer.mask_annotation_ids_mapping[annotationId]
      const labelIndex = dataMapping.getLabelIndex(annotationId)
      if (
        labelIndex === undefined ||
        frameLabelIndex === undefined ||
        !boundsPerLabelIndex[frameLabelIndex]
      ) {
        return
      }
      const bounds = boundsPerLabelIndex[frameLabelIndex]

      raster.setLabelOnKeyframe(labelIndex, frameIndex)
      raster.setVideoBoundsForLabelIndexForFrame(labelIndex, frameIndex, bounds)
    })
  })

  const annotationIds = Object.keys(dataMapping.annotationIdToLabelIndex)

  annotationIds.forEach((annotationId) => {
    const labelIndex = dataMapping.getLabelIndex(annotationId)
    if (labelIndex === undefined) {
      return
    }
    const classId = annotationIdToClassIdMapping[annotationId]

    raster.setAnnotationMapping(labelIndex, annotationId, classId)
  })
}
