import { MainAnnotationType } from '@/core/annotationTypes'
import type { AutoAnnotateData } from '@/modules/Editor/AnnotationData'
import { calcPathBBox } from '@/modules/Editor/bbox/calcPathBBox'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import {
  isImageAnnotation,
  isVideoAnnotation,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import type {
  ImageAnnotation,
  ImageSubAnnotation,
  VideoAnnotation,
} from '@/modules/Editor/models/annotation/types'
import { findClosestKeyFrame } from '@/modules/Editor/utils'
import { isAutotrackFrame } from '@/modules/Editor/utils/autotrackUtils'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'

/**
 * Resolves auto_annotate data for a video annotation.
 *
 * Since an annotation could span multiple frames, the correct video data
 * depends on which frame the editor is currently on.
 *
 * This is either the first entry to the left of the current video frame, or
 * if there is no such entry, which could happen if the annotation was resized
 * to the left after creation, it's the first entry overall.
 */
const retrieveVideoAutoAnnotateData = (
  view: View,
  annotation: VideoAnnotation,
): AutoAnnotateData | undefined => {
  const currentFrameIndex = view.currentFrameIndex
  if (currentFrameIndex === undefined || currentFrameIndex < 0) {
    return
  }

  const frame = findClosestKeyFrame(annotation, currentFrameIndex, 'auto_annotate')

  /*
   * If the current frame is an auto-track frame, we don't want to
   * use the auto_annotate data from the closest auto-annotate frame.
   */
  const currentFrame = annotation.data.frames[currentFrameIndex]
  if (isAutotrackFrame(currentFrame)) {
    if (!frame?.auto_annotate) {
      return
    }

    if (!(annotation.type === MainAnnotationType.Polygon)) {
      setContext('retrieveVideoAutoAnnotateData', {
        annotationId: annotation.id,
        type: annotation.type,
        autoAnnotateData: frame.auto_annotate,
      })
      throw new Error('Only polygon annotations are supported for auto-annotate')
    }

    const PADDING = 20

    const pathBBox = calcPathBBox(currentFrame.path || [], true)
    /**
     * The origin of the auto-annotate bounding box should be
     * the annotation centroid, not the corner of the canvas.
     */
    const xDistanceFromCentroid = pathBBox.width / 2 + PADDING
    const yDistanceFromCentroid = pathBBox.height / 2 + PADDING
    const bbox = {
      x1: -xDistanceFromCentroid,
      x2: xDistanceFromCentroid,
      y1: -yDistanceFromCentroid,
      y2: yDistanceFromCentroid,
    }

    return {
      model: frame.auto_annotate.model,
      bbox,
      clicks: [],
    }
  }

  return frame?.auto_annotate
}

/**
 * Within an image annotation, finds the subannotation instance
 * containing auto_annotate/clicker data.
 */
export const retrieveImageAutoAnnotateSubAnnotation = (
  annotation: ImageAnnotation,
): ImageSubAnnotation | undefined => {
  const sub = annotation.subAnnotations.find((s) => s.type === 'auto_annotate')
  if (!sub) {
    return
  }
  if (!isImageAnnotation(sub)) {
    throw new Error(
      'ImageAnnotation is incorrectly structured. Subannotation looks like a frame subannotation',
    )
  }
  return sub
}

/**
 * Retrieves the contents of the auto_annotate key, if any,
 * within an image or video annotation.
 */
export const retrieveAutoAnnotateData = (
  annotationId: Annotation['id'],
  view: View,
): AutoAnnotateData | undefined => {
  const annotation = view.annotationManager.getAnnotation(annotationId)
  if (!annotation) {
    return
  }
  if (isVideoAnnotation(annotation)) {
    return retrieveVideoAutoAnnotateData(view, annotation)
  }

  if (isImageAnnotation(annotation)) {
    return retrieveImageAutoAnnotateSubAnnotation(annotation)?.data
  }

  setContext('retrieveAutoAnnotateData', { annotation })
  throw new Error(
    [
      'Received or loaded annotation with invalid data.',
      '"data" field has the structure of a video,',
      'while the "subAnnotations" field has the structure of an image or vice-versa.',
    ].join(' '),
  )
}
