import type { ApiResponse } from '@/store/types'
import type { GenerateInstanceIdResponse } from '@/store/types/GenerateInstanceIdResponse'
import { post } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

type Params = {
  stage_id: string
  teamSlug: string
  datasetItemId: string
}

type Response = Promise<ApiResponse<GenerateInstanceIdResponse> | ParsedError>

/**
 * Fetches unique instance id to use it for create annotation command.
 */
export const generateInstanceId = async (params: Params): Response => {
  const { teamSlug, datasetItemId } = params
  const path = `v2/teams/${teamSlug}/items/${datasetItemId}/annotations/instance_id`

  try {
    const response = await post<GenerateInstanceIdResponse>(path, { stage_id: params.stage_id })
    return { data: response.data }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.ANNOTATION_CREATE)
  }
}
