import countLeadingDecimalZeroes from './countLeadingDecimalZeroes'

const calcAdjustedVideoTimestamp = (timestamp: number, fps: number): number => {
  /**
   * Corrected inaccuracies in the FPS sent by BE.
   * @description a 29.97 FPS video could be noted as 29.97002997002997 or 29.965001425867836 FPS
   */
  const nativeFPS = Number(fps.toFixed(2))
  const frameDuration = 1 / nativeFPS
  /**
   * Used to calculate the frame time adjustment.
   * @description For 30FPS frame duration is 0.0333333 - one leading zero after the decimal.
   * We can use that to calculate the frame adjustment to make sure it's not too small or too big.
   * In this example, the frame adjustment would also have one leading zero: 0.01.
   */
  const countLeadingZeroesInFrameDuration = countLeadingDecimalZeroes(frameDuration)
  /**
   * Need to add a time adjustment to overcome browser inaccuracies.
   * Basically, we seek a bit past the frame to make sure we don't "undershoot" it.
   * Let's say a video's FPS is 30. That means a single frame is 0.03333333333333333s long.
   * If we want to go to frame 2 at timestamp 0.0333333s, seeking there isn't guaranteed to work.
   * The browser might display the video at an earlier frame, in this case frame 1 at 0.00s.
   * By adjusting the seek time by 0.01 to 0.04333s, there is no frame at that exact timestamp.
   * The browser will display the video at the closest earlier frame, i.e. 0.03333s.
   * This hack was taken from other libraries and this is my interpretation of why it's needed.
   * @external https://raw.githubusercontent.com/allensarkisyan/VideoFrame/master/VideoFrame.js
   */
  const timeAdjustment = Math.min(0.01, 1 / Math.pow(10, countLeadingZeroesInFrameDuration + 1))

  return timestamp + timeAdjustment
}

export default calcAdjustedVideoTimestamp
