import type { EditablePoint } from '@/modules/Editor/point'
import { occludeVertexAction } from '@/modules/Editor/actions/occludeVertexAction'
import { removeVertexAction } from '@/modules/Editor/actions/removeVertexAction'
import { updateAnnotationData } from '@/modules/Editor/actions/updateAnnotationData'
import type { Editor } from '@/modules/Editor/editor'
import { getAnnotationCompoundPathAtFrame } from '@/modules/Editor/getAnnotationCompoundPath'

import { isDeleteVertexContext } from './utils/isDeleteVertexContext'
import { resolveDeletableVertexContext } from './utils/resolveDeletableVertexContext'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { DeleteVertexContext, UpdateAnnotationContext } from './types'
import { isVideoAnnotationData } from '@/modules/Editor/models/annotation/annotationKindValidator'

const deleteVertexFromAnnotation = (
  editor: Editor,
  selectedAnnotation: Annotation,
  deleteContext: DeleteVertexContext,
): void => {
  const [path, index] = deleteContext.content
  const action = removeVertexAction(editor.activeView, selectedAnnotation, path, index)
  editor.actionManager.do(action)

  editor.activeView.annotationManager.deselectVertex()
}

const deletePathFromAnnotation = (
  editor: Editor,
  selectedAnnotation: Annotation,
  deleteContext: UpdateAnnotationContext,
): void => {
  // Instead of deleting the vertex, we should update the annotation data,
  // since the path where the vertex needs to be deleted from is part of a
  // compound path
  const [paths, pathIndex] = deleteContext.content
  const newPaths: EditablePoint[][] = []
  for (let i = 0; i < paths.length; i++) {
    if (i === pathIndex) {
      continue
    }
    newPaths.push(paths[i])
  }
  const newData = isVideoAnnotationData(selectedAnnotation.data)
    ? {
        ...selectedAnnotation.data,
        frames: {
          ...selectedAnnotation.data.frames,
          [editor.activeView.currentFrameIndex]: {
            ...selectedAnnotation.data.frames[editor.activeView.currentFrameIndex],
            path: newPaths[0],
            additionalPaths: newPaths.slice(1, newPaths.length),
          },
        },
      }
    : {
        path: newPaths[0],
        additionalPaths: newPaths.slice(1, newPaths.length),
      }
  const action = updateAnnotationData(
    editor.activeView,
    selectedAnnotation,
    selectedAnnotation.data,
    newData,
  )
  editor.actionManager.do(action)
  // LAYER_V2 method to control the vertex state
  editor.activeView.annotationsLayer.deactivateVertex(selectedAnnotation.id)
}

const deleteSkeletonOrEyeVertex = (
  editor: Editor,
  selectedAnnotation: Annotation,
  deleteContext: DeleteVertexContext,
): void => {
  // Vertex is selected, so we occlude it rather than delete the annotation
  const [, index] = deleteContext.content
  const action = occludeVertexAction(editor.activeView, selectedAnnotation, index)
  editor.actionManager.do(action)
  // LAYER_V2 method to control the vertex state
  editor.activeView.annotationsLayer.deactivateVertex(selectedAnnotation.id)
}

export const deleteVertex = (editor: Editor): void => {
  const { selectedAnnotation, selectedVertexIndex } = editor.activeView.annotationManager
  if (!selectedAnnotation) {
    return
  }

  // Try to find a path that has a selected vertex
  const deleteContext = resolveDeletableVertexContext(
    getAnnotationCompoundPathAtFrame(selectedAnnotation, editor.activeView.currentFrameIndex),
    selectedAnnotation,
    selectedVertexIndex,
  )

  if (
    deleteContext &&
    isDeleteVertexContext(deleteContext) &&
    ['skeleton', 'eye'].includes(selectedAnnotation.type)
  ) {
    deleteSkeletonOrEyeVertex(editor, selectedAnnotation, deleteContext)

    return
  }

  if (deleteContext) {
    if (isDeleteVertexContext(deleteContext)) {
      deleteVertexFromAnnotation(editor, selectedAnnotation, deleteContext)
    } else {
      deletePathFromAnnotation(editor, selectedAnnotation, deleteContext)
    }

    return
  }
}
