import { v4 as uuidv4 } from 'uuid'

import type { VideoAnnotationData } from '@/modules/Editor/AnnotationData'
import { createAndSelectAnnotation } from '@/modules/Editor/actions/utils/createAndSelectAnnotation'
import type { Bounds, Raster } from '@/modules/Editor/models/raster/Raster'
import { assertVoxelRaster } from '@/modules/Editor/models/raster/assertVoxelRaster'
import type { View } from '@/modules/Editor/views/view'

/**
 * Creates a new mask annotation in 3D and associates it with the raster.
 *
 * @param primaryView The view on which the raster lives.
 * @param raster The raster object
 * @param boundingBox The bounding box to set on the annotation data.
 * @param labelIndex The label index of the annotation on the raster
 *
 * @returns A promise which resolves to the new annotation
 */
export async function createMaskAnnotationIn3D(
  primaryView: View,
  raster: Raster,
  bounds: Bounds,
  labelIndex: number,
  classId: number,
  depth: number,
  annotationId?: string,
): Promise<void> {
  const newAnnotationId = annotationId || raster.getAnnotationMapping(labelIndex) || uuidv4()

  raster.setAnnotationMapping(labelIndex, newAnnotationId, classId)

  const voxelRaster = assertVoxelRaster(raster)
  const { currentFrameIndex } = primaryView
  const halfDepth = Math.floor(depth / 2)
  const edgeCorrection = depth % 2 === 0 ? 1 : 0
  const firstFrame = Math.max(0, currentFrameIndex - halfDepth)
  const lastFrame = Math.min(currentFrameIndex + halfDepth - edgeCorrection, voxelRaster.depth - 1)

  const updatedFramesIndices: number[] = []
  const frames: VideoAnnotationData['frames'] = {}

  for (let frameIndex = firstFrame; frameIndex <= lastFrame; frameIndex++) {
    voxelRaster.setVideoBoundsForLabelIndexForFrame(labelIndex, frameIndex, bounds)
    voxelRaster.setLabelOnKeyframe(labelIndex, frameIndex)
    updatedFramesIndices.push(frameIndex)

    frames[frameIndex] = {
      rasterId: voxelRaster.id,
    }
  }

  let annotation = await primaryView.annotationManager.prepareAnnotationForCreation({
    type: 'mask',
    id: newAnnotationId,
    data: {
      frames,
      sub_frames: {},
      segments: [[firstFrame, lastFrame + 1]],
      interpolated: false,
      interpolate_algorithm: 'linear-1.1',
    },
  })

  if (!annotation) {
    throw new Error('Failed to create 3D mask annotation')
  }

  if (!annotation.data.segments) {
    throw new Error('Annotation segments are not set')
  }

  // Need to set this after prepareAnnotationForCreation, in order not change its internals.
  annotation.data.segments[0] = [firstFrame, lastFrame + 1]
  annotation = createAndSelectAnnotation(primaryView, annotation)

  // Call update so all the frames are registered.
  primaryView.annotationManager.updateAnnotation(annotation, {
    updatedFramesIndices,
  })
}
