type Transferable = OffscreenCanvas | ImageBitmap | MessagePort

/**
 * Helper functions targeted TO the WebWorker.
 * Reduces the boilerplate code and simplifies the usage of the WebWorker.
 *
 * This file works in cooperation with fromWorker.ts
 */

/**
 * Checks the type of the event with the expected type
 */
const sameEventType = (ev: MessageEvent, type: string): boolean => {
  if (!ev.data) {
    return false
  }

  return ev.data.type === type
}

type ReturnType = {
  postMessage: <RESPONSE = void, PAYLOAD = unknown>(
    type: string,
    payload?: PAYLOAD,
    transfer?: Transferable[],
    config?: { abortSignal?: AbortSignal },
  ) => Promise<RESPONSE | undefined>
  addListener: <RESPONSE = void>(type: string, handler: (res: RESPONSE) => void) => void
  cleanup: () => void
}

export const toWorker = (worker: Worker): ReturnType => {
  const handlersMap: Map<string, () => void> = new Map()

  /**
   * Posts a message of a specific type and payload to a webworker,
   * returning a promise which resolves when the worker replies, and contains
   * the reply.
   */
  const postMessage = <RESPONSE = void, PAYLOAD = unknown>(
    type: string,
    payload?: PAYLOAD,
    transfer: Transferable[] = [],
    config: { abortSignal?: AbortSignal } = {},
  ): Promise<RESPONSE | undefined> =>
    new Promise((resolve, reject) => {
      const { port1, port2 } = new MessageChannel()
      transfer.push(port2)

      worker?.postMessage(
        {
          type,
          payload,
        },
        transfer,
      )

      const id = handlersMap.size.toString()

      const abortHandler = (): void => {
        reject(new DOMException('Aborted', 'AbortError'))
        handlersMap.get(id)?.()
      }

      const handler = (ev?: MessageEvent): void => {
        if (ev && !sameEventType(ev, type)) {
          return
        }
        port1?.removeEventListener('message', handler)
        port1?.close()
        handlersMap.delete(id)
        resolve(ev?.data?.payload)
        config.abortSignal?.removeEventListener('abort', abortHandler)
      }

      // Registers and removes the event listener to get the result of the action execution.
      port1?.addEventListener('message', handler)
      handlersMap.set(id, handler)

      port1.start()

      config.abortSignal?.addEventListener('abort', abortHandler)
    })

  const addListener = <RESPONSE = void>(type: string, handler: (res: RESPONSE) => void): void => {
    const id = handlersMap.size.toString()
    handlersMap.delete(id)

    const messageHandler = (ev?: MessageEvent): void => {
      if (ev && !sameEventType(ev, type)) {
        return
      }

      handler(ev?.data?.payload)
    }

    worker.addEventListener('message', messageHandler)

    handlersMap.set(id, () => {
      worker.removeEventListener('message', messageHandler)
    })
  }

  /**
   * Removes all listeners created by the worker.
   */
  const cleanup = (): void => {
    for (const h of handlersMap.values()) {
      h()
    }
    handlersMap.clear()
  }

  return {
    postMessage,
    addListener,
    cleanup,
  }
}
