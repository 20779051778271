import type { CallbackHandle } from '@/modules/Editor/callbackHandler'
import { CallbackHandleCollection } from '@/modules/Editor/callbackHandler'
import type { View } from '@/modules/Editor/views/view'

/**
 * Just a bucket holding onRender callbacks for various tools.
 *
 * NOTE:
 *
 * This should probably be a couple extra fields on the view itself.
 * Really shouldn't need to be a class of it's own.
 * Please don't needlessly grow it.
 */
export class RenderManager {
  private onRenderCallbacks: CallbackHandleCollection<[View]> = new CallbackHandleCollection<
    [View]
  >()

  public onRender(cb: (view: View) => void): CallbackHandle {
    return this.onRenderCallbacks.add(cb)
  }

  public emitRenderCallbacks(view: View): void {
    // editor.layout is typed as never null, but that's a lie so
    // onRenderCallbacks.call will result in sometimes raising errors
    // this resolves the immediate issue but there will be a separate ticket
    // to fix bad typing of editor.layout
    if (view.editor.layout && view.editor.activeView === view) {
      this.onRenderCallbacks.call(view)
    }
  }

  /**
   * Clean the canvas causing a re-rendering on the next frame draw
   */
  public cleanup(): void {
    this.onRenderCallbacks.clear()
  }
}
