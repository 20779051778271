import type { DatasetMutation } from '@/store/modules/dataset/types'

export const UPDATE_V2_ITEM_SELECTION: DatasetMutation<{
  itemIds: string[]
  selected: boolean
}> = (state, { itemIds, selected }) => {
  state.selectedV2ItemIds = selected
    ? state.selectedV2ItemIds.filter((id) => !itemIds.includes(id)).concat([...itemIds])
    : state.selectedV2ItemIds.filter((id) => !itemIds.includes(id))
}
