import type VueIntercom from '@/services/vue-intercom/types'
import { getCurrentInstance } from 'vue'

export const useIntercom = (): VueIntercom | undefined => {
  const vm = getCurrentInstance()
  if (!vm) {
    throw new Error(
      'getCurrentInstance() returned null. Method must be called at the top of a setup function',
    )
  }
  return vm.proxy.$intercom
}

export const INTERCOM_EVENT = {
  UPGRADE: 'upgrade_request',
  SUPPORT: 'support_request',
} as const
