import type { VideoAnnotation } from '@/modules/Editor/models/annotation/types'

/**
 * This function will return the subset of an annotation's frames that need to be
 * serialized in order for an annotation to be rendered in the editor at a given
 * video frame.
 *
 * Some background as to why this function is needed: When paginating annotation data,
 * we store a greater range of metadata than frame data. This means that there may be
 * keyframes in the metadata that don't have corresponding frame data, and therefore
 * cannot be serialized.
 *
 * In fact, we only need to serialize the keyframes that are closest either side of the
 * current frame since the shape of an annotation at a given frame depends only on the
 * keyframes before and after it. Edge cases to this rule are:
 * - If the current frame is a keyframe, we only need to serialize that one keyframe.
 * - If there are no keyframes after the current frame, we only need to serialize the
 *   last keyframe before the current frame.
 * - If there are no keyframes before the current frame, we only need to serialize the
 *   first keyframe after the current frame.
 */
export const getFramesToSerialize = (
  frames: VideoAnnotation['data']['frames'],
  currentFrameIndex: number,
): number[] => {
  let keyframeBeforeCurrentFrame: number | undefined
  let keyframeAfterCurrentFrame: number | undefined
  const framesToSerialize: number[] = []

  const matchingKeyframe = frames[currentFrameIndex]
  if (matchingKeyframe) {
    framesToSerialize.push(currentFrameIndex)
  } else {
    for (const frame in frames) {
      const frameIndex = Number(frame)

      if (
        frameIndex < currentFrameIndex &&
        (keyframeBeforeCurrentFrame === undefined || frameIndex > keyframeBeforeCurrentFrame)
      ) {
        keyframeBeforeCurrentFrame = frameIndex
      }

      if (
        frameIndex > currentFrameIndex &&
        (keyframeAfterCurrentFrame === undefined || frameIndex < keyframeAfterCurrentFrame)
      ) {
        keyframeAfterCurrentFrame = frameIndex
      }
    }

    if (keyframeBeforeCurrentFrame !== undefined) {
      framesToSerialize.push(keyframeBeforeCurrentFrame)
    }
    if (keyframeAfterCurrentFrame !== undefined) {
      framesToSerialize.push(keyframeAfterCurrentFrame)
    }
  }

  return framesToSerialize
}
