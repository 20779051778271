import type { Polygon } from '@/modules/Editor/AnnotationData'
import type { CompoundPath } from '@/modules/Editor/compoundPath'
import { addPointMutate } from '@/modules/Editor/point'
import type { EditablePoint, IPoint } from '@/modules/Editor/point'

export const isPolygon = (data: Polygon | object): data is Polygon => 'path' in data

export const getPathFromPolygon = (polygon: Polygon | object): EditablePoint[] =>
  isPolygon(polygon) ? polygon.path : []

export const getAdditionalPathsFromPolygon = (polygon: Polygon | object): EditablePoint[][] =>
  isPolygon(polygon) ? polygon.additionalPaths || [] : []

export const getCompoundPathFromPolygon = (data: Polygon | object): CompoundPath => ({
  path: getPathFromPolygon(data),
  additionalPaths: getAdditionalPathsFromPolygon(data),
})

export const translatePolygon = (data: Polygon | object, offset: IPoint): void => {
  getPathFromPolygon(data).forEach((point) => addPointMutate(point, offset))
  getAdditionalPathsFromPolygon(data).forEach((path) =>
    path.map((point) => addPointMutate(point, offset)),
  )
}

export const getAllVerticesFromPolygon = (data: Polygon | object): EditablePoint[] => {
  const compoundPath = getCompoundPathFromPolygon(data)
  let { path } = compoundPath

  for (const additionalPath of compoundPath.additionalPaths) {
    path = path.concat(additionalPath)
  }
  return path
}

export const moveVertexOnPolygon = (movingVertex: EditablePoint, offset: IPoint): void =>
  addPointMutate(movingVertex, offset)
