import type { Polyline } from '@/modules/Editor/AnnotationData'
import type { CompoundPath } from '@/modules/Editor/compoundPath'
import { addPointMutate, type EditablePoint, type IPoint } from '@/modules/Editor/point'

export const isPolyline = (data: Polyline | object): data is Polyline => 'path' in data

export const getPathFromPolyline = (polyline: Polyline | object): EditablePoint[] =>
  isPolyline(polyline) ? polyline.path : []

export const getCompoundPathFromPolyline = (polyline: Polyline | object): CompoundPath => ({
  path: getPathFromPolyline(polyline),
  additionalPaths: [],
})

export const getAllVerticesFromPolyline = (polyline: Polyline | object): EditablePoint[] =>
  getPathFromPolyline(polyline)

export const translatePolyline = (polyline: Polyline | object, offset: IPoint): void =>
  getPathFromPolyline(polyline).forEach((point) => addPointMutate(point, offset))

/**
 * Moves a single specified vertex by a specified amount.
 *
 * Due to the nature of polyline, this does not affect other vertices in it in any way.
 */
export const moveVertexOnPolyline = (vertex: EditablePoint, offset: IPoint): void =>
  addPointMutate(vertex, offset)
