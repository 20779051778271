export const ViewTypes = {
  IMAGE: 'image',
  VIDEO: 'video',
  PDF: 'pdf',
  DICOM: 'dicom',
  // For 16 bit image support for non-dicom images, see viewport for info.
  LEGACY_DICOM: 'legacy_dicom',
  STREAM: 'stream',
  TILED: 'tiled',
} as const
export type ViewTypesType = (typeof ViewTypes)[keyof typeof ViewTypes]
