import type { Ellipse } from '@/modules/Editor/AnnotationData'
import { euclideanDistance } from '@/modules/Editor/algebra'
import type { CompoundPath } from '@/modules/Editor/compoundPath'
import type { EditablePoint, IPoint } from '@/modules/Editor/point'
import { addPointMutate, addPoints, divScalar, subPointMutate } from '@/modules/Editor/point'

export const isEllipse = (data: Ellipse | object): data is Ellipse => 'center' in data

/**
 * NOTE: This is some very outaded documentation copy-pasted from original code location
 * It's unclear whether it's still relevant. Once we eliminate renderer fully,
 * we can check which parts of this code are actually used.
 *
 *
 * OUTDATED DOCS:
 *
 * getPath doesn't make much sense for Ellipses, since we don't convert the
 * ellipse to a compound path here in the frontend.
 * However, since we need to support interpolation and
 * other MainAnnotationTypeRenderer features, we still implement
 * a dummy getPath.
 */
const getPathFromEllipse = (ellipse: Ellipse | object): EditablePoint[] =>
  isEllipse(ellipse)
    ? [ellipse.center, ellipse.top, ellipse.right, ellipse.bottom, ellipse.left]
    : []

export const getCompoundPathFromEllipse = (ellipse: Ellipse | object): CompoundPath => ({
  path: getPathFromEllipse(ellipse),
  additionalPaths: [],
})

export const getAllVerticesFromEllipse = (ellipse: Ellipse | object): EditablePoint[] => {
  const path = getPathFromEllipse(ellipse)
  if (path.length === 5) {
    return path.slice(1)
  }
  return []
}

export const translateEllipse = (ellipse: Ellipse | object, offset: IPoint): void => {
  getPathFromEllipse(ellipse).forEach((point) => addPointMutate(point, offset))
}

export const moveVertexOnEllipse = (
  ellipse: Ellipse,
  movingVertex: EditablePoint,
  offset: IPoint,
  specialKey?: SpecialKey,
): void => {
  const { center, top, right, left, bottom } = ellipse

  // First, determine the role of all actionable points.
  // movingVertex is the vertex which is currently held and moved by the user
  // oppositeVertex is the vertex at the other side with respect to the center point
  // symVertex is the vertex which is sitting on the other ellipse diameter
  // oppositeSymVertex is the vertex at the other side of symVertex
  // with respect to the center point
  let oppositeVertex: EditablePoint | undefined
  let symVertex: EditablePoint | undefined
  let oppositeSymVertex: EditablePoint | undefined
  let isTopOrBottomMoving = false

  if (right.x === movingVertex.x && right.y === movingVertex.y) {
    oppositeVertex = left
    symVertex = top
    oppositeSymVertex = bottom
  } else if (top.x === movingVertex.x && top.y === movingVertex.y) {
    oppositeVertex = bottom
    symVertex = right
    oppositeSymVertex = left
    isTopOrBottomMoving = true
  } else if (left.x === movingVertex.x && left.y === movingVertex.y) {
    oppositeVertex = right
    symVertex = bottom
    oppositeSymVertex = top
  } else if (bottom.x === movingVertex.x && bottom.y === movingVertex.y) {
    oppositeVertex = top
    symVertex = left
    oppositeSymVertex = right
    isTopOrBottomMoving = true
  }

  // If the moving vertex does not coincide with any of the ellipse action points, simply return
  if (!oppositeVertex || !symVertex || !oppositeSymVertex) {
    return
  }

  const oldD = euclideanDistance(movingVertex, center)
  const newD = euclideanDistance(addPoints(movingVertex, offset), center)

  // If no special key is held, then project the offset vector on the moving diameter axis.
  // Move the other diameter control points and center point of the same offset,
  // halving its contribution.
  // The opposite vertex does not move.
  if (!specialKey) {
    addPointMutate(movingVertex, offset)
    addPointMutate(center, divScalar(offset, 2))
    addPointMutate(symVertex, divScalar(offset, 2))
    addPointMutate(oppositeSymVertex, divScalar(offset, 2))
  } else {
    // Move movingVertex and oppositeVertex according to offset,
    // and measure the difference in distance with respect to the center point
    addPointMutate(movingVertex, offset)
    subPointMutate(oppositeVertex, offset)
  }

  // Compute the equivalent radius of the distance with respect to the center point
  // on the other ellipse diameter, considering potential special keys changing the behaviour:
  // ALT makes the moving vertex offset count for the opposite vertex, too.
  // The center point acts as anchor.
  // SHIFT makes the distance on the moving diameter count for the other
  // diameter in the same absolute value
  // CTRL makes the distance on the moving diameter count for the other
  // diameter in the same relative value (i.e. proportionally)
  let d: number = 0
  switch (specialKey) {
    case 'ctrl':
      d = newD
      break
    case 'shift':
      d = euclideanDistance(center, symVertex) + newD - oldD
      break
    default:
      d = euclideanDistance(center, symVertex)
      break
  }
  const angle = isTopOrBottomMoving
    ? Math.atan2(center.y - movingVertex.y, center.x - movingVertex.x)
    : Math.atan2(movingVertex.y - center.y, movingVertex.x - center.x)
  // Move the vertices on the other diameter accordingly
  const oldSymX = symVertex.x
  const oldSymY = symVertex.y
  symVertex.x = center.x + d * Math.cos(angle - Math.PI / 2)
  symVertex.y = center.y + d * Math.sin(angle - Math.PI / 2)
  subPointMutate(oppositeSymVertex, { x: symVertex.x - oldSymX, y: symVertex.y - oldSymY })
}

export type SpecialKey = 'alt' | 'ctrl' | 'shift'
