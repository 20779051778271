import type { Raster } from './Raster'
import { VoxelRaster } from './VoxelRaster'

/**
 * Returns a raster as a voxel raster for type interpretation, without casting.
 * Throws if this is not possible.
 */
export const assertVoxelRaster = (raster: Raster): VoxelRaster => {
  if (!(raster instanceof VoxelRaster)) {
    throw new Error('Raster is not an VoxelRaster')
  }

  return raster
}
