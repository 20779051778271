import { mark } from '@/performance/mark'
import { RENDER_IMAGE_AND_ANNOTATIONS } from '@/performance/keys'

/**
 * This class is responsible for managing the annotations and frame sync.
 * if the frame is not rendered, it will not render the annotations when they are set for the desired couple `${slot_name}_${frame_index}`.
 */
export class AnnotationsAndFrameSyncManager {
  private annotationsRenderer: (() => void) | null = null
  private annotationsCleaner: (() => void) | null = null

  private annotationsSetKey: string | null = null
  private _renderedKey: string | null = null
  public get renderedKey(): string | null {
    return this._renderedKey
  }
  private annotationsRenderedKey: string | null = null

  constructor(private isSupported: () => boolean) {}

  public get shouldPausePlayback(): boolean {
    if (!this.isSupported()) {
      return false
    }

    return this.renderedKey !== null && this.renderedKey !== this.annotationsRenderedKey
  }

  public defineAnnotationsRenderer(renderFn: () => void, clearFn: () => void): void {
    this.annotationsRenderer = renderFn
    this.annotationsCleaner = clearFn
  }

  public annotationsSetForKey(key: string): void {
    this.annotationsSetKey = key

    if (
      !this.isSupported() ||
      (this.renderedKey !== null && this.renderedKey === this.annotationsSetKey)
    ) {
      this.annotationsRenderer?.()
    }
  }

  public frameRenderedForKey(key: string): void {
    this._renderedKey = key

    if (
      this.isSupported() &&
      this.annotationsSetKey !== null &&
      this.annotationsSetKey === this.renderedKey
    ) {
      this.annotationsRenderer?.()
    }
  }

  public annotationsRenderedForKey(key: string): void {
    if (key !== this.annotationsRenderedKey) {
      mark(RENDER_IMAGE_AND_ANNOTATIONS)
    }

    this.annotationsRenderedKey = key

    if (
      this.isSupported() &&
      this.annotationsSetKey !== null &&
      this.annotationsSetKey !== this.annotationsRenderedKey
    ) {
      this.annotationsCleaner?.()
    }
  }

  public cleanup(): void {
    this.annotationsRenderer = null
    this.annotationsCleaner = null
    this.annotationsSetKey = null
    this._renderedKey = null
    this.annotationsRenderedKey = null
  }
}
