import { Rectangle } from '@/modules/Editor/rectangle'

import { drawDashedBox } from './graphics'
import type { View } from './views/view'

export const renderMeasureRegion = (view: View): void => {
  const ctx = view.mainLayer.context
  if (!ctx) {
    return
  }

  const { measureRegion } = view.measureManager
  if (!measureRegion) {
    return
  }

  const { x, y, w, h } = measureRegion
  const topLeft = view.camera.imageViewToCanvasView({ x, y })
  const bottomRight = view.camera.imageViewToCanvasView({ x: x + w, y: y + h })
  const measureRect = new Rectangle(topLeft, bottomRight)
  drawDashedBox(ctx, measureRect)
}
