import { post } from '@/backend/api'
import { parseError, errorMessages, isErrorResponse } from '@/backend/error'
import type { ApiResult } from './types'
import type { SlotSectionResponse, SlotSectionTiled } from '@/store/types/SlotSection'

export type TilesRequestPayload = { x: number; y: number; z: number }[]
export type PagePayload = {
  from?: string
  to?: string
  offset?: number
  size?: number
}

type Params = {
  teamSlug: string
  page?: PagePayload
  tiles: TilesRequestPayload
  itemId: string
  slotName: string
}

export const loadSlotSectionsTiled = async (
  params: Params,
): Promise<ApiResult<SlotSectionResponse<SlotSectionTiled>>> => {
  const { teamSlug, page, tiles, itemId, slotName } = params
  const path = `v2/teams/${teamSlug}/items/${itemId}/slots/${slotName}/sections/get`

  try {
    const response = await post<SlotSectionResponse<SlotSectionTiled>>(path, { page, tiles })
    return { ok: true, data: response.data }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return { ...parseError(error, errorMessages.STAGE_LOAD), ok: false }
  }
}
