import EventEmitter from 'events'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import RBush from 'rbush'

import { mark } from '@/performance/mark'
import { FIRST_ANNOTATION_RENDERED, ACTIVE_ANNOTATION_RENDERED } from '@/performance/keys'
import { MainAnnotationType } from '@/core/annotationTypes'
import { MapLinkedList } from '@/core/dataStructures/MapLinkedList'
import { isSameBBox } from '@/modules/Editor/bbox/isSameBBox'
import type { CallbackHandle } from '@/modules/Editor/callbackHandler'
import type { Camera, CameraEvent } from '@/modules/Editor/camera'
import { DEFAULT_LINE_WIDTH, DEFAULT_VERTEX_SIZE } from '@/modules/Editor/config'
import { CameraEvents } from '@/modules/Editor/eventBus'
import type { IPoint } from '@/modules/Editor/point'
import type { Range } from '@/modules/Editor/types'
import type { BBox } from '@/modules/Editor/types'
import { isVisible as isVisibleInViewport } from '@/modules/Editor/models/layers/helpers'
import { Events as LayerEvents } from '@/modules/Editor/models/layers/layer'
import type {
  VertexState,
  RenderableItem,
  RenderableItemState,
  RenderableItemWithState,
  RTreeRenderableItem,
} from '@/modules/Editor/models/layers/object2D'
import {
  CACHED_CANVAS_QUALITY_FACTOR,
  DOUBLE_CACHED_CANVAS_PADDING,
  MAX_CANVAS_SIZE,
} from '@/modules/Editor/models/layers/optimisedLayer/configs'
import type { Box, DrawFn, ILayer, RenderFilters } from '@/modules/Editor/models/layers/types'

import { ActiveCanvas } from './activeCanvas'
import { CachedCanvasController } from './cachedCanvas/controller'
import { CloseViewCanvas } from './closeViewCanvas/closeViewCanvas'
import { parseAnnotationData } from './dataParser/parseAnnotationData'
import {
  parseAnnotationsData,
  type ReturnType as ParsedData,
} from './dataParser/parseAnnotationsData'
import { MainCanvas } from './mainCanvas'
import { getScale } from './utils/getScale'

type Dimension = {
  width: number
  height: number
}

type SortOrder = 'asc' | 'desc'

/**
 * Accumulates items for render.
 *
 * Manages the rendering process of each item.
 * Optimizes rendering process by introducing active canvas,
 * cached canvas, and independent item rendering.
 *
 * Check ./README.md to get more details.
 */
export class OptimisedLayer
  extends EventEmitter
  implements ILayer<CanvasRenderingContext2D, HTMLCanvasElement>
{
  /**
   * Cached canvas keeps static (rendered) items
   * and re-use 'em to idle/pan/scale render.
   * Canvas not exists in the DOM.
   *
   * We use the controller`s interface that repeats the CachedCanvas interface
   * to access cached canvas functionality. For browsers with the transferControlToOffscreen
   * support controller delegates rendering to the WebWorker with the mounted
   * CachedCanvas instance.
   */
  private cachedCanvasController = new CachedCanvasController()

  /**
   * Cached canvas for the close view annotations render that supports higher quality.
   * It uses camera width & height and sets scale
   * and offset provided by camera to render high quality annotations.
   * Canvas not exists in the DOM.
   */
  private closeViewCanvas = new CloseViewCanvas()

  /**
   * Main canvas renders cached canvas or close view canvas
   * respecting camera scale and offset.
   * Canvas exists in the DOM.
   */
  private mainCanvas = new MainCanvas()

  /**
   * Active canvas renders active items
   * or draws on canvas using public draw method.
   * Canvas exists in the DOM.
   */
  private activeCanvas = new ActiveCanvas()

  /**
   * List of activated items.
   *
   * Items will be rendered on active canvas and
   * ignored on cached canvas.
   */
  private _activeItems: Set<RenderableItem['id']> = new Set()

  private _hasChanges: boolean = false
  private _moving: boolean = false

  /**
   * Keeps all renderable items.
   */
  private _itemsMap: Map<string, RenderableItemWithState> = new Map()
  /**
   * R-Tree of items to optimise items search by coordinates or bbox.
   */
  private _rTreeItems: RTreeRenderableItem[] = []
  private _rBush = new RBush<RTreeRenderableItem>(5)
  /**
   * The map of the bounding boxes of the items.
   */
  private _itemsBBoxMap: Map<string, BBox> = new Map()

  /**
   * List of sorted by zIndex objects ids
   * The MapLinkedList data structure keeps zIndexes auto sorted.
   */
  private zIndexList: MapLinkedList<{
    id: RenderableItem['id']
  }>

  private _isIdle: boolean = true

  private renderKey: string | null = null

  private set isIdle(val: boolean) {
    this._isIdle = val
    if (!val) {
      this.debounceResetIdleAndRenderCloseView()
    }
  }

  private get isIdle(): boolean {
    return this._isIdle
  }

  private debounceResetIdleAndRenderCloseView = debounce(() => {
    this.isIdle = true
    // on idle we can render high-quality annotations to draw 'em on the screen
    if (!this.isBeforeScaleToFit) {
      this.renderCloseView()
    }
  }, 100)

  constructor(
    private viewId: string,
    private slotName: string,
    private camera: Camera,
  ) {
    super()

    this.zIndexList = new MapLinkedList()

    this.canvas?.classList.add('optimised-layer')
    this.canvas?.setAttribute('data-test', 'optimised-layer')

    this.cachedCanvasController.connect()

    CameraEvents.scaleChanged.on(this.onScaleChanged)
    CameraEvents.offsetChanged.on(this.onCanvasMove)

    this.setSizeForCanvases({ viewId: this.viewId, slotName: this.slotName })
    CameraEvents.setDimensions.on(this.setSizeForCanvases)

    this.setCachedCanvasSize({ viewId: this.viewId, slotName: this.slotName }, camera.image)
    CameraEvents.setImageSize.on(this.setCachedCanvasSize)
  }

  private onScaleChanged = (cameraEvent: CameraEvent, scale: number): void => {
    if (cameraEvent.viewId !== this.viewId) {
      return
    }
    this.cachedCanvasController.setCameraScale(scale)
    this.activeCanvas.setCameraScale(scale)
    this.onCanvasMove(cameraEvent)
    this.isIdle = false
  }

  private onCanvasMove = (cameraEvent: CameraEvent): void => {
    if (cameraEvent.viewId !== this.viewId) {
      return
    }
    this._moving = true
    this.resetMovingDebounce()
    this.isIdle = false

    if (!this.isBeforeScaleToFit) {
      this.changedDebounce()
    }
  }

  setFilters(filters: RenderFilters): void {
    this.cachedCanvasController.setFilters(filters)
    this.closeViewCanvas.setFilters(filters)
    this.changed()
  }

  /**
   * Sets main, active and close view canvases dimension to camera size
   */
  private setSizeForCanvases = (cameraEvent: CameraEvent): void => {
    if (cameraEvent.viewId !== this.viewId) {
      return
    }

    this.setCachedCanvasSize({ viewId: this.viewId, slotName: this.slotName }, this.camera.image)

    this.mainCanvas.setCanvasSize(this.camera.width, this.camera.height)
    this.activeCanvas.setCanvasSize(this.camera.width, this.camera.height)
    this.closeViewCanvas.setCanvasSize(this.camera.width, this.camera.height)
    this.changedDebounce()
    if (!this.isBeforeScaleToFit) {
      this.renderCloseView()
    }
  }

  /**
   * Returns the scale factor for Image to fill the Viewport
   */
  private scaleToImageFillViewport(imageWidth: number, imageHeight: number): number {
    // Without this, we would return infinity if width/height is 0
    if (!imageWidth || !imageHeight) {
      return 1
    }

    const adjustedImageWidth = Math.min(imageWidth, MAX_CANVAS_SIZE)
    const adjustedImageHeight = Math.min(imageHeight, MAX_CANVAS_SIZE)

    return getScale(this.camera.width, this.camera.height, adjustedImageWidth, adjustedImageHeight)
  }

  /**
   * Set cached canvas dimension to image size
   */
  private setCachedCanvasSize = async (
    cameraEvent: CameraEvent,
    { width, height }: Dimension,
  ): Promise<void> => {
    if (cameraEvent.viewId !== this.viewId) {
      return
    }
    this.cachedCanvasController.setScale(
      this.scaleToImageFillViewport(
        width / CACHED_CANVAS_QUALITY_FACTOR,
        height / CACHED_CANVAS_QUALITY_FACTOR,
      ),
    )

    await this.cachedCanvasController.setCanvasSize(
      width + DOUBLE_CACHED_CANVAS_PADDING,
      height + DOUBLE_CACHED_CANVAS_PADDING,
    )
    this.changedDebounce()
    if (!this.isBeforeScaleToFit) {
      this.renderCloseView()
    }
  }

  public get context(): CanvasRenderingContext2D | null {
    return this.mainCanvas.context
  }

  public get canvas(): HTMLCanvasElement | null {
    return this.mainCanvas.canvas
  }

  /**
   * Marks the render so we can identify that the data has been displayed.
   */
  public setKeyForNextRender(key: string | null): void {
    this.renderKey = key
  }

  /**
   * DOM attachable element getter which holds the canvas and the active canvas.
   * Use element as a single element to attach layers canvases to the DOM
   */
  public get element(): DocumentFragment {
    const frag = document.createDocumentFragment()

    this.canvas && frag.appendChild(this.canvas)
    this.activeCanvas.canvas && frag.appendChild(this.activeCanvas.canvas)

    return frag
  }

  /**
   * Add new object(s) to the render pool.
   */
  add(payload: RenderableItem): void
  add(payload: RenderableItem[]): void
  add(payload: RenderableItem | RenderableItem[]): void {
    const newItems = Array.isArray(payload) ? payload : [payload]

    const items = [...this._itemsMap.values(), ...newItems]

    const res = parseAnnotationsData(items)
    if (!res) {
      throw 'No result of parsingAnnotationsData while adding obj to render pool'
    }

    const { itemsBBoxMap, rTreeItems, itemsMap } = res

    if (!rTreeItems.length) {
      return
    }
    this._itemsBBoxMap.clear()
    this._itemsBBoxMap = itemsBBoxMap
    this._rTreeItems.length = 0
    this._rTreeItems = rTreeItems
    this._rBush.clear()
    this._rBush.load(this._rTreeItems)
    this._itemsMap.clear()
    this._itemsMap = itemsMap
    if (Array.isArray(payload)) {
      this.zIndexList.addFromArray(payload.map((item) => ({ id: item.id })))
      this.renderCachedThrottle()
    } else {
      this.zIndexList.addLast({ id: payload.id })
      this.repaintCachedItem(payload.id)
    }
  }

  replaceAll(items: RenderableItem[]): void {
    this._moving = false
    this.isIdle = false
    this.zIndexList.clear()
    this.cachedCanvasController.cleanup()
    this.closeViewCanvas.cleanup()
    this.activeCanvas.clear()

    const { itemsBBoxMap, rTreeItems, itemsMap, zIndexesList } = parseAnnotationsData(items)

    if (!rTreeItems.length) {
      return
    }
    this._itemsBBoxMap.clear()
    this._itemsBBoxMap = itemsBBoxMap
    this._rTreeItems.length = 0
    this._rTreeItems = rTreeItems
    this._rBush.clear()
    this._rBush.load(this._rTreeItems)
    this._itemsMap.clear()
    this._itemsMap = itemsMap
    this.zIndexList.addFromArray(zIndexesList.map((id) => ({ id })))
    this.changed()
  }

  replaceAllWithParsedData(payload: ParsedData): void {
    this._moving = false
    this.isIdle = false
    this.zIndexList.clear()
    this.cachedCanvasController.cleanup()
    this.closeViewCanvas.cleanup()
    this.activeCanvas.clear()

    const { itemsBBoxMap, rTreeItems, itemsMap, zIndexesList } = payload

    if (!rTreeItems.length) {
      return
    }
    this._itemsBBoxMap.clear()
    this._itemsBBoxMap = itemsBBoxMap
    this._rTreeItems.length = 0
    this._rTreeItems = rTreeItems
    this._rBush.clear()
    this._rBush.load(this._rTreeItems)
    this._itemsMap.clear()
    this._itemsMap = itemsMap
    this.zIndexList.addFromArray(zIndexesList.map((id) => ({ id })))
    this.changed()
  }

  /**
   * Updates existing renderable item's data
   */
  update(ann: RenderableItem): Promise<void> {
    // The only case in which this might happen is when the user tries to update a hidden
    // item with an undo action, which succeed anyway so no point in raising an error
    if (!this._itemsMap.has(ann.id)) {
      return Promise.resolve()
    }
    const oldItem = this._itemsMap.get(ann.id)
    if (!oldItem) {
      return Promise.reject('Annotation is null - skipping re-rendering')
    }

    const parsedAnn = parseAnnotationData(ann, oldItem.state)
    if (!parsedAnn) {
      return Promise.resolve()
    }

    const { item, bbox, rTreeItem } = parsedAnn

    this._itemsMap.set(item.id, {
      ...item,
      state: oldItem.state,
    })
    const oldBBox = this._itemsBBoxMap.get(item.id)
    this._itemsBBoxMap.set(item.id, bbox)

    const indexToRemove = this._rTreeItems.findIndex((i) => i.id === item.id)
    if (indexToRemove < 0) {
      return Promise.resolve()
    }

    this._rTreeItems.splice(indexToRemove, 1, rTreeItem)
    this._rBush.clear()
    this._rBush.load(this._rTreeItems)

    if (!this.isItemActive(ann.id)) {
      this.repaintCachedItem(ann.id)
      if (oldBBox && !isSameBBox(oldBBox, bbox)) {
        this.repaintCachedCanvasBox(oldBBox)
      }
    }

    return Promise.resolve()
  }

  updateItemState(itemId: string, state: Partial<RenderableItemState>): void {
    const item = this.getItem(itemId)
    if (!item) {
      return
    }
    item.state = {
      ...item.state,
      ...state,
    }
    this.repaintCachedItem(itemId)
    this.changed(itemId)
  }

  /**
   * Reorders `annotationToReorder` either above or below
   * `referenceAnnotation` based on `direction`
   */
  reorderAnnotation(
    annotationToReorder: RenderableItem,
    referenceAnnotation: RenderableItem,
    /** needed to determine if `annotationToReorder`
     * will be placed before or after `referenceAnnotation`*/
    direction: 'up' | 'down',
  ): void {
    this.zIndexList.remove(annotationToReorder.id)
    if (direction === 'up') {
      this.zIndexList.addAfter({ id: annotationToReorder.id }, referenceAnnotation.id)
    } else {
      this.zIndexList.addBefore({ id: annotationToReorder.id }, referenceAnnotation.id)
    }
    this.repaintCachedItem(annotationToReorder.id)
    this.repaintCachedItem(referenceAnnotation.id)
  }

  /**
   * Delete object from the render pool by its id.
   */
  delete(itemId: RenderableItem['id']): void {
    const item = this.getItem(itemId)
    if (!item) {
      return
    }

    const indexToRemove = this._rTreeItems.findIndex((i) => i.id === item.id)
    if (indexToRemove < 0) {
      return
    }

    this._activeItems.delete(itemId)
    this._hiddenItems.delete(itemId)
    this._rTreeItems.splice(indexToRemove, 1)
    this._rBush.clear()
    this._rBush.load(this._rTreeItems)

    this._itemsMap.delete(itemId)
    this.zIndexList.remove(itemId)
    const bbox = this._itemsBBoxMap.get(itemId)
    this._itemsBBoxMap.delete(itemId)
    this.deactivate(itemId)

    if (!bbox) {
      return
    }
    this.repaintCachedCanvasBox(bbox)
  }

  /**
   * Returns object by its id.
   */
  getItem(id: string): RenderableItemWithState | undefined {
    return this._itemsMap.get(id)
  }

  /**
   * Returns all objects in the pool.
   */
  getAll(): {
    key: string
    item: RenderableItem
  }[] {
    const res = []
    for (const { id } of this.zIndexList) {
      const item = this.getItem(id)
      if (!item) {
        continue
      }

      res.push({ key: id, item })
    }
    return res
  }

  /**
   * Checks if the object with id exists on the layer.
   */
  has(id: string): boolean {
    return !!this._itemsMap.has(id)
  }

  private get isBeforeScaleToFit(): boolean {
    return this.camera.scale <= this.camera.scaleToFitValue
  }

  private drawItemsOnCachedCloseViewCanvas(items: RenderableItemWithState[], box: Box): void {
    if (!this._moving) {
      this.closeViewCanvas.drawItemsInBox(items, box, this.camera)
      this.mainCanvas.insertCloseViewCanvas(this.closeViewCanvas)
    }
  }

  /**
   * Repaints item by its id with all intersected items.
   */
  private repaintCachedItem(id: RenderableItem['id']): void {
    const bbox = this._itemsBBoxMap.get(id)
    const targetItem = this.getItem(id)

    // When actual render is scheduled we can skip the item repaint
    if (this._hasChanges) {
      return
    }

    if (!bbox) {
      return
    }
    if (!targetItem) {
      return
    }

    if (targetItem.type === 'skeleton' || targetItem.type === 'eye') {
      bbox.width += (DEFAULT_VERTEX_SIZE * 2) / this.camera.scale
      bbox.height += (DEFAULT_VERTEX_SIZE * 2) / this.camera.scale
    }

    this.repaintCachedCanvasBox(bbox)
  }

  /**
   * Repaints all items intersected with the bbox
   */
  private repaintCachedCanvasBox(box: BBox): void {
    const padding = 2.0 / this.camera.scale
    const itemBox = {
      x: box.x - box.width / 2 - padding,
      width: box.width + padding + DEFAULT_LINE_WIDTH / this.camera.scale,
      y: box.y - box.height / 2 - padding,
      height: box.height + padding + DEFAULT_LINE_WIDTH / this.camera.scale,
    }

    // Get all intersected items with the target item
    const rTreeItems = this.getSortedRTreeItemsByRange({
      minX: itemBox.x,
      minY: itemBox.y,
      maxX: itemBox.x + itemBox.width,
      maxY: itemBox.y + itemBox.height,
    })

    const items: RenderableItemWithState[] = []
    for (const rTreeItem of rTreeItems) {
      if (this.isHidden(rTreeItem.id)) {
        continue
      }

      if (this.isItemActive(rTreeItem.id)) {
        continue
      }

      const item = this.getItem(rTreeItem.id)
      if (!item) {
        continue
      }

      items.push(item)
    }

    items.sort((a, b) => this.zIndexList.indexOf(a.id) - this.zIndexList.indexOf(b.id))

    this.cachedCanvasController.drawItemsInBox(items, itemBox)
    this.drawItemsOnCachedCloseViewCanvas(items, itemBox)
  }

  /**
   * CloseView items rendering by setting scale and offset to the canvases context.
   * Renders items from the render pool
   * ignores items outside the viewport
   * ignores activated items.
   */
  private renderCloseView(): void {
    const items = []
    for (const { id } of this.zIndexList) {
      if (this.isHidden(id)) {
        continue
      }

      const itemWithState = this.getItem(id)
      if (!itemWithState) {
        continue
      }
      const bbox = this._itemsBBoxMap.get(id)
      if (this.isItemActive(id) || !(bbox && isVisibleInViewport(this.camera, bbox))) {
        continue
      }
      items.push(itemWithState)
    }

    this.closeViewCanvas.render(items, this.camera, this.mainCanvas.offset)
  }

  private _firstAnnotationRendered: boolean = false

  /**
   * Renders all items from the render pool
   * ignores activated items.
   */
  private async renderCached(): Promise<void> {
    const items = []
    for (const { id } of this.zIndexList) {
      if (this.isHidden(id)) {
        continue
      }

      const itemWithState = this.getItem(id)
      if (!itemWithState) {
        continue
      }
      if (this.isItemActive(id)) {
        continue
      }

      items.push(itemWithState)
    }

    await this.cachedCanvasController.drawAll(items)
    if (items.length > 0 && !this._firstAnnotationRendered) {
      this._firstAnnotationRendered = true
      mark(FIRST_ANNOTATION_RENDERED)
    }
    this.emit(LayerEvents.RENDERED, this.renderKey)
  }

  private renderCachedThrottle = throttle(() => this.renderCached(), 250, { trailing: true })

  /**
   * Renders cached canvas or close view canvas on the DOM attached main canvas.
   */
  private renderMain(): void {
    const { scale, offset } = this.camera

    this.mainCanvas.clearCanvas()

    // Saves current scale/offset to prevent useless redraw
    this.mainCanvas.saveCameraPosition(scale, offset)

    if (this.isIdle && !this.isBeforeScaleToFit) {
      this.mainCanvas.insertCloseViewCanvas(this.closeViewCanvas)
    } else {
      if (!this.cachedCanvasController.canvas) {
        return
      }

      this.mainCanvas.insertCached(this.cachedCanvasController.canvas, this.camera)
    }
  }

  private _activeAnnotationKey: string = ''

  /**
   * Renders activated items on the active canvas.
   * Uses active canvas/context for rendering.
   */
  private renderActive(): void {
    this.activeCanvas.render(this._activeItems, this.camera, (key) => {
      if (this.isHidden(key)) {
        return
      }
      return this.getItem(key)
    })

    const key = this._activeItems.values().next().value
    if (this._activeItems.size > 0 && this._activeAnnotationKey !== key) {
      this._activeAnnotationKey = key as string
      mark(ACTIVE_ANNOTATION_RENDERED)
    } else if (this._activeItems.size === 0) {
      // annotation is deactivated so we can reset the flag
      this._activeAnnotationKey = ''
    }

    this.emit(LayerEvents.RENDER, this.context, this.canvas)
  }

  /**
   * Render loop constantly renders active canvas and main canvas.
   * Updates the cached & close view canvases on changed() call.
   */
  public render(): void {
    if (this._hasChanges) {
      this.emit(LayerEvents.BEFORE_RENDER, this.mainCanvas.context, this.mainCanvas.canvas)
    }

    this.renderActive()

    if (this._hasChanges) {
      this.renderCached()
    }

    this.renderMain()

    if (this._hasChanges) {
      this.emit(LayerEvents.RENDER, this.context, this.canvas)
    }
    this._hasChanges = false
  }

  private drawActiveDrawCallback = (drawFn?: DrawFn): void => {
    if (!this.activeCanvas.drawCanvas) {
      return
    }
    if (!this.activeCanvas.drawContext) {
      return
    }
    drawFn?.(
      this.activeCanvas.drawContext,
      this.activeCanvas.drawCanvas,
      this.drawActiveDrawCallback,
    )
  }

  /**
   * Public interface that manages performant drawing on canvas.
   */
  public draw(fn?: DrawFn): void {
    if (!this.activeCanvas.drawCanvas) {
      return
    }
    if (!this.activeCanvas.drawContext) {
      return
    }

    this.activeCanvas.clearDrawCanvas()
    // ctx.save & ctx.restore encapsulates canvases context
    // changes inside the callback function (fn)
    this.activeCanvas.drawContext.save()

    // Callback function gets context, canvas and draw function
    // U can use drawFn to provide for the draw* functions
    fn?.(this.activeCanvas.drawContext, this.activeCanvas.drawCanvas, this.drawActiveDrawCallback)

    this.activeCanvas.drawContext.restore()
  }

  /**
   * Clear the active canvas
   */
  public clearDrawingCanvas(): void {
    this.activeCanvas.clearDrawCanvas()
  }

  public getSelectedVertex(): {
    itemId: RenderableItem['id']
    index: number
  } | null {
    return this.activeCanvas.getSelectedVertex()
  }

  /**
   * Set the vertexes state highlight/select and activates it.
   */
  public activateVertexWithState(
    itemId: RenderableItem['id'],
    index: number,
    state: VertexState,
  ): void {
    this.activeCanvas.activateVertexWithState(itemId, index, state)
  }

  public unhighlightAllVertices(): void {
    this.activeCanvas.unhighlightAllVertices()
  }

  public deactivateVertex(itemId?: RenderableItem['id'], index?: number): void {
    this.activeCanvas.deactivateVertex(itemId, index)
  }

  /**
   * Activated object rendered on active canvas & ignored on cached.
   */
  public activate(
    id: RenderableItem['id'],
    withState: RenderableItemState = {
      isSelected: false,
      isHighlighted: false,
      isLocked: false,
    },
  ): void {
    const itemReference = this.getItem(id)

    if (
      this._activeItems.has(id) &&
      itemReference?.state.isSelected === !!withState.isSelected &&
      itemReference?.state.isHighlighted === !!withState.isHighlighted &&
      itemReference?.state.isLocked === !!withState.isLocked
    ) {
      return
    }

    this._activeItems.add(id)

    if (itemReference) {
      itemReference.state.isSelected = !!withState.isSelected
      itemReference.state.isHighlighted = !!withState.isHighlighted
      itemReference.state.isLocked = !!withState.isLocked
    }

    this.repaintCachedItem(id)
  }

  /**
   * Returns true for active item id.
   *
   * Temporary solution cause we have legacy logic
   * that triggers view.setAnnotations on item update/create/delete
   * Instead we should use item.isActive
   */
  private isItemActive(id: RenderableItem['id']): boolean {
    return this._activeItems.has(id)
  }

  /**
   * Deactivates an item and reset the item state
   */
  public deactivate(id: RenderableItem['id']): void {
    this._activeItems.delete(id)
    this.activeCanvas.activeVertices.delete(id)

    if (this.has(id)) {
      const item = this.getItem(id)
      if (item) {
        item.state.isSelected = false
        item.state.isHighlighted = false
      }

      this.repaintCachedItem(id)
    }

    if (!this._activeItems.size) {
      this.activeCanvas.clearCanvas()
    }
  }

  // START: Visibility

  private _hiddenItems: Set<RenderableItem['id']> = new Set()

  public isHidden(id: RenderableItem['id']): boolean {
    return this._hiddenItems.has(id)
  }

  public showAll(ids?: RenderableItem['id'][]): void {
    if (ids) {
      ids.forEach((id) => this._hiddenItems.delete(id))
    } else {
      this._hiddenItems.clear()
    }

    this.changedDebounce()
    if (!this.isBeforeScaleToFit) {
      this.renderCloseView()
    }
  }

  public show(id: RenderableItem['id']): void {
    if (!this.isHidden(id)) {
      return
    }

    this._hiddenItems.delete(id)
    this.repaintCachedItem(id)
  }

  public hideAll(ids?: RenderableItem['id'][]): void {
    if (ids) {
      ids.forEach((id) => this._hiddenItems.add(id))
    } else {
      for (const id of this._itemsMap.keys()) {
        this._hiddenItems.add(id)
      }
    }

    this.changedDebounce()
    if (!this.isBeforeScaleToFit) {
      this.renderCloseView()
    }
  }

  public hide(id: RenderableItem['id']): void {
    if (this.isHidden(id)) {
      return
    }

    this._hiddenItems.add(id)
    this.repaintCachedItem(id)
  }

  public hideLayer(): void {}
  public showLayer(): void {}

  // END: Visibility

  /**
   * Marks layer as changed.
   *
   * So it will be redrawn during the next render iteration.
   */
  changed(itemId?: RenderableItem['id']): void {
    if (itemId) {
      this.repaintCachedItem(itemId)
      return
    }

    this._hasChanges = true
  }

  changedDebounce = debounce(() => {
    this.changed()
  }, 50)

  resetMovingDebounce = debounce(() => {
    this._moving = false
  }, 50)

  clear(): void {
    this.renderKey = null
    this._moving = false
    this.isIdle = false
    this._itemsMap.clear()
    this.zIndexList.clear()
    this._rTreeItems = []
    this._rBush.clear()
    this._itemsBBoxMap.clear()
    this.cachedCanvasController.cleanup()
    this.closeViewCanvas.cleanup()
    this.activeCanvas.clear()
    this.changed()
  }

  destroy(): void {
    CameraEvents.scaleChanged.off(this.onScaleChanged)
    CameraEvents.offsetChanged.off(this.onCanvasMove)
    CameraEvents.setImageSize.off(this.setCachedCanvasSize)
    CameraEvents.setDimensions.off(this.setSizeForCanvases)
    this.clear()
    this.cachedCanvasController.destroy()
    this.mainCanvas.destroy()
    this.activeCanvas.destroy()
    this.closeViewCanvas.destroy()
    this.removeAllListeners(LayerEvents.RENDER)
    this.removeAllListeners(LayerEvents.BEFORE_RENDER)
  }

  onRender(cb: (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => void): CallbackHandle {
    this.on(LayerEvents.RENDER, cb)

    return {
      id: this.listenerCount(LayerEvents.RENDER),
      release: this.off.bind(this, LayerEvents.RENDER, cb),
    }
  }

  onBeforeRender(
    cb: (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => void,
  ): CallbackHandle {
    this.on(LayerEvents.BEFORE_RENDER, cb)

    return {
      id: this.listenerCount(LayerEvents.BEFORE_RENDER),
      release: this.off.bind(this, LayerEvents.BEFORE_RENDER, cb),
    }
  }

  onRendered(cb: (renderKey: string | null) => void): CallbackHandle {
    this.on(LayerEvents.RENDERED, cb)

    return {
      id: this.listenerCount(LayerEvents.RENDERED),
      release: this.off.bind(this, LayerEvents.RENDERED, cb),
    }
  }

  /**
   * Returns item id instance that point hits to, respecting z-index.
   */
  public async hitItemRegion(point: IPoint): Promise<RenderableItem['id'] | undefined> {
    // Get the list of targets starting from the highest
    const result = this.getSortedRTreeItemsByRange(
      {
        minX: point.x,
        minY: point.y,
        maxX: point.x,
        maxY: point.y,
      },
      'desc',
    )

    if (!result.length) {
      return
    }

    for (const { id } of result) {
      const item = this.getItem(id)

      if (!item) {
        return Promise.resolve(undefined)
      }

      let isPointInPath

      if (item.state.isLocked) {
        continue
      }

      switch (item.type) {
        case MainAnnotationType.Eye:
        case MainAnnotationType.Skeleton:
        case MainAnnotationType.Polyline: {
          isPointInPath = await this.cachedCanvasController.isPointInStroke(id, point.x, point.y)
          isPointInPath = isPointInPath || this.activeCanvas.isPointInStroke(id, point.x, point.y)
          break
        }
        default: {
          isPointInPath = await this.cachedCanvasController.isPointInPath(
            id,
            point.x,
            point.y,
            'evenodd',
          )
          isPointInPath =
            isPointInPath || this.activeCanvas.isPointInPath(id, point.x, point.y, 'evenodd')
          break
        }
      }

      if (isPointInPath) {
        return Promise.resolve(id)
      }

      if (Number.isFinite(this.hitVertexRegion(id, point))) {
        return id
      }
    }
  }

  /**
   * Returns the item id which stroke hit by the point.
   * @param targetedItems can specify the list of the items to check.
   */
  public async hitItemStroke(
    point: IPoint,
    targetedItems?: {
      id: string
    }[],
  ): Promise<RenderableItem['id'] | undefined> {
    let items
    if (targetedItems) {
      items = targetedItems
    } else {
      items = this.getSortedRTreeItemsByRange(
        {
          minX: point.x,
          minY: point.y,
          maxX: point.x,
          maxY: point.y,
        },
        'desc',
      )

      if (!items.length) {
        return
      }
    }

    for (const { id } of items) {
      const item = this.getItem(id)

      if (!item) {
        return Promise.resolve(undefined)
      }

      let isPointInPath
      isPointInPath = await this.cachedCanvasController.isPointInStroke(id, point.x, point.y)
      isPointInPath = isPointInPath || this.activeCanvas.isPointInStroke(id, point.x, point.y)

      if (isPointInPath) {
        return id
      }
    }
  }

  /**
   * Returns items vertex index that point hits to.
   */
  public hitVertexRegion(itemId: RenderableItem['id'], point: IPoint): number | undefined {
    return this.activeCanvas.hitItemsVertex(itemId, point.x, point.y)
  }

  private getSortedRTreeItemsByRange(
    { minX, minY, maxX, maxY }: Range,
    order: SortOrder = 'asc',
  ): RTreeRenderableItem[] {
    return this._rBush
      .search({
        minX,
        minY,
        maxX,
        maxY,
      })
      .sort((a, b) => {
        const aItem = this.getItem(a.id)
        const bItem = this.getItem(b.id)

        if (!aItem || !bItem) {
          return 0
        }

        switch (order) {
          case 'desc': {
            return this.zIndexList.indexOf(b.id) - this.zIndexList.indexOf(a.id)
          }
          case 'asc':
          default: {
            return this.zIndexList.indexOf(a.id) - this.zIndexList.indexOf(b.id)
          }
        }
      })
  }
}
