import type { FrameData } from '@/core/annotations'
import type { BBox } from '@/modules/Editor/types'

import { calcPathBBox } from './calcPathBBox'

export const calcLineBBox = (data: FrameData): BBox | undefined => {
  const { line } = data

  if (!line) {
    return
  }

  const { path } = line

  return calcPathBBox(path)
}
