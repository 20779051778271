import type { AnnotationData } from '@/modules/Editor/AnnotationData'

import type { Annotation } from './models/annotation/Annotation'
import { isVideoAnnotation } from './models/annotation/annotationKindValidator'
import { inferVideoData } from './models/annotation/inferVideoData'
import type { VideoAnnotation } from './models/annotation/types'
import type { VideoAnnotationDataPayload } from './types'

/**
 * For the given annotation and the given frame number,
 * returns the appropriate single-frame annotation data.
 *
 * For image annotations, the frame number is ignored, but will likely be passed as 0,
 * and the plain unaltered data on the annotation is echoed back.
 *
 * For video annotations, if the frame falls within a keyframe segment, the data
 * returned is either the data at the start of the segment or, if the segment
 * supports interpolation, the data interpolated between the start of segment
 * and the start of next segment.
 *
 * If it's the last segment, the data returned is always from start of segment.
 *
 * If the frame falls out of segment, {} is returned.
 */
export const inferCurrentAnnotationData = <T extends Annotation | VideoAnnotation>(
  annotation: T,
  frameNumber: number,
): T extends VideoAnnotation ? VideoAnnotationDataPayload['data'] : AnnotationData =>
  isVideoAnnotation(annotation) ? inferVideoData(annotation, frameNumber).data : annotation.data
