import type { Camera } from '@/modules/Editor/camera'
import { DEFAULT_LINE_WIDTH, DEFAULT_VERTEX_SIZE } from '@/modules/Editor/config'
import type { IPoint } from '@/modules/Editor/point'
import { calcLineWidth } from '@/modules/Editor/graphicsV2/calcLineWidth'
import { drawByType } from '@/modules/Editor/graphicsV2/drawByType'
import { drawVerticesByType } from '@/modules/Editor/graphicsV2/drawVerticesByType'
import { fillStyle } from '@/modules/Editor/graphicsV2/fillStyle'
import { strokeStyle } from '@/modules/Editor/graphicsV2/strokeStyle'
import type { RenderableItemWithState } from '@/modules/Editor/models/layers/object2D'
import { CanvasAndContext } from '@/modules/Editor/models/layers/optimisedLayer/canvasAndContext'
import {
  DEFAULT_BORDER_OPACITY,
  DEFAULT_OPACITY,
} from '@/modules/Editor/models/layers/optimisedLayer/configs'
import type { Box, RenderFilters } from '@/modules/Editor/models/layers/types'
import { setContext } from '@/services/sentry'

export class CloseViewCanvas extends CanvasAndContext {
  // Last HQ offset and scale prevent glitching on panning
  public lastHQOffset: IPoint = { x: 0, y: 0 }
  public lastHQScale: number = 0

  protected filters: RenderFilters = {
    opacity: DEFAULT_OPACITY,
    borderOpacity: DEFAULT_BORDER_OPACITY,
  }

  setFilters(filters: RenderFilters): void {
    this.filters = filters
  }

  drawItemsInBox(items: RenderableItemWithState[], box: Box, camera: Camera): void {
    // NOTE: Since canvas width/height is Integer. We need to control what we send to it.
    // Otherwise, it will cut the float in an unpredictable way.

    let x1 = 0
    let x2 = 0
    let y1 = 0
    let y2 = 0

    const canvas = document.createElement('canvas')
    canvas.classList.add('close-view-canvas')
    x1 = box.x * camera.scale - camera.offset.x
    x2 = camera.offset.x + camera.width - (box.x + box.width) * camera.scale

    y1 = box.y * camera.scale - camera.offset.y
    y2 = camera.offset.y + camera.height - (box.y + box.height) * camera.scale

    let canvasWidth = box.width * camera.scale
    if (x1 < 0) {
      canvasWidth += x1
    }
    if (x2 < 0) {
      canvasWidth += x2
    }

    let canvasHeight = box.height * camera.scale
    if (y1 < 0) {
      canvasHeight += y1
    }
    if (y2 < 0) {
      canvasHeight += y2
    }

    canvas.width = Math.round(canvasWidth)
    canvas.height = Math.round(canvasHeight)

    const ctx = canvas.getContext('2d')

    if (!ctx) {
      return
    }

    for (const item of items) {
      if (!ctx) {
        return
      }

      try {
        ctx.save()

        ctx.scale(camera.scale, camera.scale)

        let x = -box.x
        let y = -box.y

        if (x1 < 0) {
          x -= box.width - canvas.width / camera.scale
        }
        if (x1 < 0 && x2 < 0) {
          x += Math.abs(x2) / camera.scale
        }

        if (y1 < 0) {
          y -= box.height - canvas.height / camera.scale
        }
        if (y1 < 0 && y2 < 0) {
          y += Math.abs(y2) / camera.scale
        }

        ctx.translate(x, y)

        drawByType(ctx, item.type, item.data, {
          fillColor: fillStyle(item.color, this.filters.opacity, false, false, false),
          strokeColor: strokeStyle(item.color, this.filters.borderOpacity, false, false),
          lineWidth: calcLineWidth(DEFAULT_LINE_WIDTH, camera.scale),
          pointSize: DEFAULT_VERTEX_SIZE / camera.scale,
          scale: camera.scale,
        })
        drawVerticesByType(ctx, item.type, item.data, {
          fillColor: fillStyle(item.color, this.filters.opacity, false, false, false),
          strokeColor: strokeStyle(item.color, this.filters.borderOpacity, false, false),
          lineWidth: calcLineWidth(DEFAULT_LINE_WIDTH, camera.scale),
          pointSize: DEFAULT_VERTEX_SIZE / camera.scale,
        })

        ctx.restore()
      } catch (e) {
        setContext('error', { error: e })
        console.error('V2 closeViewCanvas failed to render item in box')
      }
    }

    let x = -this.lastHQOffset.x + box.x * this.lastHQScale
    let y = -this.lastHQOffset.y + box.y * this.lastHQScale

    if (x1 < 0) {
      x += box.width * this.lastHQScale - canvas.width
    }
    if (x1 < 0 && x2 < 0) {
      x = 0
    }

    if (y1 < 0) {
      y += box.height * this.lastHQScale - canvas.height
    }
    if (y1 < 0 && y2 < 0) {
      y = 0
    }

    this.clearCanvas(Math.floor(x), Math.floor(y), canvas.width, canvas.height)

    this.insertCanvas(canvas, Math.floor(x), Math.floor(y), canvas.width, canvas.height)
    canvas.remove()
  }

  /**
   * HQ items rendering by setting scale and offset to the canvases context.
   * Renders items from the render pool
   * ignores items outside the viewport
   * ignores activated items.
   */
  public render(items: RenderableItemWithState[], camera: Camera, cachedOffset: IPoint): void {
    if (!this.context) {
      return
    }

    this.lastHQOffset.x = camera.offset.x
    this.lastHQOffset.y = camera.offset.y
    this.lastHQScale = camera.scale

    this.clearCanvas()

    // Renders all non-active items inside viewport
    for (const item of items) {
      try {
        this.context.save()
        // Set context scale to increase items quality
        this.context.scale(camera.scale, camera.scale)
        // Set offset to respect x,y position of the items
        this.context.translate(-cachedOffset.x / camera.scale, -cachedOffset.y / camera.scale)

        // Render item using HQ cached canvas/context
        drawByType(this.context, item.type, item.data, {
          fillColor: fillStyle(item.color, this.filters.opacity, false, false, false),
          strokeColor: strokeStyle(item.color, this.filters.borderOpacity, false, false),
          lineWidth: calcLineWidth(DEFAULT_LINE_WIDTH, camera.scale),
          pointSize: DEFAULT_VERTEX_SIZE / camera.scale,
          scale: camera.scale,
        })
        drawVerticesByType(this.context, item.type, item.data, {
          fillColor: fillStyle(item.color, this.filters.opacity, false, false, false),
          strokeColor: strokeStyle(item.color, this.filters.borderOpacity, false, false),
          lineWidth: calcLineWidth(DEFAULT_LINE_WIDTH, camera.scale),
          pointSize: DEFAULT_VERTEX_SIZE / camera.scale,
        })

        this.context.restore()
      } catch (e) {
        setContext('error', { error: e })
        console.error('V2 closeViewCanvas failed to render item')
      }
    }
  }

  public cleanup(): void {
    if (!this.canvas) {
      return
    }
    this.clearCanvas(0, 0, this.canvas.width, this.canvas.height)
  }
}
