import type { IPoint } from '@/modules/Editor/point'
import type { PolygonToRasterResult } from '@/modules/Editor/plugins/mask/utils/polygonToRaster'
import { toWorker } from '@/modules/Editor/utils/workers/toWorker'
import { Actions } from './types'
import Worker from './worker?worker'

export class RasterWorker {
  private worker: Worker
  private toWorker: ReturnType<typeof toWorker>

  constructor() {
    this.worker = new Worker()
    this.toWorker = toWorker(this.worker)
  }

  async polygonToRaster(path: IPoint[]): Promise<PolygonToRasterResult> {
    const result = await this.toWorker.postMessage<PolygonToRasterResult>(Actions.polygonToRaster, {
      path,
    })

    if (!result) {
      throw new Error('Failed to convert polygon to raster')
    }

    return result
  }

  cleanup(): void {
    this.toWorker.cleanup()
    this.worker.terminate()
  }
}
