import type { MainAnnotationType } from '@/core/annotationTypes'
import type { FrameData } from '@/core/annotations'
import type { IPoint } from '@/modules/Editor/point'
import type { RenderingContext2D } from '@/modules/Editor/types'
import type { VertexState } from '@/modules/Editor/models/layers/object2D'

const roundedRectWithText = (
  ctx: RenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
  text: string,
  scale: number,
): void => {
  ctx.save()
  ctx.strokeStyle = 'rgba(0, 0, 0, 1)'
  ctx.fillStyle = 'rgba(0, 0, 0, 1)'
  ctx.beginPath()
  ctx.moveTo(x, y + radius)
  ctx.lineTo(x, y + height - radius)
  ctx.arcTo(x, y + height, x + radius, y + height, radius)
  ctx.lineTo(x + width - radius, y + height)
  ctx.arcTo(x + width, y + height, x + width, y + height - radius, radius)
  ctx.lineTo(x + width, y + radius)
  ctx.arcTo(x + width, y, x + width - radius, y, radius)
  ctx.lineTo(x + radius, y)
  ctx.arcTo(x, y, x, y + radius, radius)
  ctx.closePath()
  ctx.stroke()
  ctx.fill()
  ctx.restore()
  ctx.save()
  ctx.font = `${12 / scale}px inter`
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.fillStyle = 'rgba(255, 255, 255, 1)'
  ctx.fillText(text, x + width / 2, y + height / 2)
  ctx.restore()
}

const DESCRIPTOR_WIDTH = 13
const DESCRIPTOR_HEIGHT = 20

const drawVertexDescriptor = (
  ctx: RenderingContext2D,
  node: IPoint & { name: string },
  scale: number,
  width: number = DESCRIPTOR_WIDTH,
  height: number = DESCRIPTOR_HEIGHT,
): void => {
  roundedRectWithText(
    ctx,
    node.x + 10 / scale,
    node.y - 10 / scale,
    (width + 7 * node.name.length) / scale,
    height / scale,
    3 / scale,
    node.name,
    scale,
  )
}

/**
 * Don't use this draw function.
 * we're not happy about this, it will be rebuilt in the nearest future.
 * If it's not push @vlad_yaremenko
 */
export const drawLabelByType = (
  ctx: RenderingContext2D,
  type: MainAnnotationType,
  data: FrameData,
  scale: number,
  activeVertices?: Map<number, VertexState>,
): Path2D | undefined => {
  if (type === 'skeleton') {
    if (!data.skeleton) {
      return
    }
    ctx.lineJoin = 'round'
    for (const [index, node] of data.skeleton?.nodes.entries() || []) {
      const activeVertex = activeVertices?.get(index)
      if (activeVertex?.isHighlighted || activeVertex?.isSelected) {
        drawVertexDescriptor(ctx, node, scale)
      }
    }
  } else if (type === 'eye') {
    if (!data.eye) {
      return
    }
    ctx.lineJoin = 'round'
    for (const [index, node] of data.eye?.nodes.entries() || []) {
      const activeVertex = activeVertices?.get(index)
      if (activeVertex?.isHighlighted || activeVertex?.isSelected) {
        drawVertexDescriptor(ctx, node, scale)
      }
    }
  }
}
