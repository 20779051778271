import round from 'lodash/round'

import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'
// eslint-disable-next-line boundaries/element-types
import { rgbaString } from '@/uiKit/colorPalette'

export const strokeStyle = (
  color: RGBA,
  filter: ImageManipulationFilter | null,
  inferred: boolean,
  isSelected: boolean,
): string => {
  if (!filter) {
    const alpha = inferred || isSelected ? 1 : 0.5
    return rgbaString(color, alpha)
  }

  const alpha = round(filter.borderOpacity / 100.0, 2)
  return rgbaString(color, alpha)
}
