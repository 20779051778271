import type { VideoRaster } from '@/modules/Editor/models/raster/VideoRaster'
import type { VoxelRaster } from '@/modules/Editor/models/raster/VoxelRaster'

/**
 * Gets the segment of a raster label.
 * This is a safe way to update a mask annotation getting the segment straight from the raster,
 * by checking where the label is present.
 *
 * @returns The segment of the label/annotation.
 */
export const getAnnotationSegmentFromRasterLabel = (
  videoRaster: VideoRaster | VoxelRaster,
  labelIndex: number,
): [number, number] => {
  const [start, end] = videoRaster.getLabelRange(labelIndex)
  return [start, end + 1]
}
