import type { FrameData } from '@/core/annotations'
import type { BoundingBox } from '@/modules/Editor/AnnotationData'
import { createEditablePoint } from '@/modules/Editor/point'

type SerializedBoundingBox = Pick<FrameData, 'bounding_box' | 'inference'>
export const boundingBoxSerializer = {
  serialize(data: BoundingBox): SerializedBoundingBox {
    const { x: left, y: top } = data.topLeft
    const { x: right, y: bottom } = data.bottomRight

    const res: SerializedBoundingBox = {
      bounding_box: {
        x: Math.min(left, right),
        y: Math.min(top, bottom),
        w: Math.abs(right - left),
        h: Math.abs(bottom - top),
      },
    }

    if (data.inference) {
      res.inference = data.inference
    }

    return res
  },

  deserialize(rawData: FrameData): BoundingBox | null {
    if (!rawData.bounding_box) {
      return null
    }
    const { x, y, w, h } = rawData.bounding_box
    const res: BoundingBox = {
      topLeft: createEditablePoint({ x, y }),
      bottomRight: createEditablePoint({ x: x + w, y: y + h }),
      topRight: createEditablePoint({ x: x + w, y: y }),
      bottomLeft: createEditablePoint({ x: x, y: y + h }),
    }

    const inference = rawData?.inference?.confidence ? rawData?.inference : undefined
    if (inference) {
      res.inference = inference
    }

    return res
  },
}
