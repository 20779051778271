import type { IPoint } from '@/modules/Editor/point'

export const drawGuideLines = (
  ctx: CanvasRenderingContext2D,
  size: { width: number; height: number },
  point: IPoint,
): void => {
  ctx.beginPath()
  ctx.lineWidth = 0.5
  ctx.strokeStyle = 'rgb(227, 234, 242)' // Alice Shade
  ctx.moveTo(point.x, 0)
  ctx.lineTo(point.x, size.height)
  ctx.moveTo(0, point.y)
  ctx.lineTo(size.width, point.y)
  ctx.stroke()
}
