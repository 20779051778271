import type { VideoAnnotationDataSegment } from '@/modules/Editor/AnnotationData'
import { hasSegmentContainingIndex } from '@/modules/Editor/helpers/segments'
import { isVideoAnnotationDataPayload } from '@/modules/Editor/models/annotation/annotationKindValidator'
import type { V2AnnotationPayload } from '@/store/types'
import { interpolateFrameLevelProperties } from './interpolateProperties'
import uniq from 'lodash/uniq'

export const removePropertiesOutsideSegment = (
  annotation: Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'>,
): Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'> => {
  if (!isVideoAnnotationDataPayload(annotation.data)) {
    return annotation
  }

  if (!annotation.properties) {
    return annotation
  }

  if (!('segments' in annotation.data) || !annotation.data.segments) {
    return annotation
  }
  const firstFrameOfAnnotation = annotation.data.segments?.[0]?.[0]

  const interpolatedProperties = interpolateFrameLevelProperties(
    firstFrameOfAnnotation,
    annotation.properties,
  )

  annotation.properties[firstFrameOfAnnotation] = interpolatedProperties

  Object.keys(annotation.properties).forEach((frameKeyStr) => {
    const frameKey = parseInt(frameKeyStr)

    if (!isVideoAnnotationDataPayload(annotation.data)) {
      return
    }

    if (hasSegmentContainingIndex(annotation.data.segments, frameKey)) {
      return
    }

    if (!annotation.properties?.[frameKey]) {
      return
    }
    // To delete the property we need to send null
    annotation.properties[frameKey] = null
  })

  return annotation
}

export const willSetFirstFrameEmptyProperties = (
  annotation: Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'>,
  oldAnnotationSegment?: VideoAnnotationDataSegment[],
): Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'> => {
  if (!isVideoAnnotationDataPayload(annotation.data)) {
    return annotation
  }

  // Collect all properties
  const allProperties = new Set<string>()

  if (!annotation.properties) {
    return annotation
  }

  Object.keys(annotation.properties).forEach((frameKeyStr) => {
    const frameKey = parseInt(frameKeyStr)
    const properties = annotation.properties?.[frameKey]
    if (!properties) {
      return
    }

    Object.keys(properties).forEach((propertyId) => allProperties.add(propertyId))
  })

  // Fill new first annotation frame with props
  const firstFrameOfAnnotation = annotation.data.segments?.[0]?.[0]
  if (firstFrameOfAnnotation === undefined) {
    return annotation
  }

  if (!Object.keys(annotation.properties).length) {
    return annotation
  }

  let firstFrameProperties = annotation.properties[firstFrameOfAnnotation]
  if (!firstFrameProperties) {
    firstFrameProperties = {}
    annotation.properties[firstFrameOfAnnotation] = firstFrameProperties
  }

  for (const propKey of allProperties.values()) {
    if (!firstFrameProperties[propKey]) {
      firstFrameProperties[propKey] = []
    }
  }

  // Will delete old first frame
  const previousFirstFrameOfAnnotation = oldAnnotationSegment?.[0]?.[0]
  if (
    previousFirstFrameOfAnnotation === undefined ||
    previousFirstFrameOfAnnotation === firstFrameOfAnnotation
  ) {
    return annotation
  }
  const prevFirstFrameProperties = annotation.properties[previousFirstFrameOfAnnotation]
  if (!prevFirstFrameProperties) {
    return annotation
  }
  if (
    Object.keys(prevFirstFrameProperties).every(
      (propertyId) => prevFirstFrameProperties[propertyId]?.length === 0,
    )
  ) {
    // To delete the property we need to send null
    annotation.properties[previousFirstFrameOfAnnotation] = null
  }

  return annotation
}

export const classChangeWillDeleteAllProperties = (
  annotation: Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'>,
  oldClassId?: number,
): Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'> => {
  if (annotation.annotation_class_id === oldClassId) {
    return annotation
  }

  if (!annotation.properties) {
    return annotation
  }

  Object.keys(annotation.properties).forEach((frameKeyStr) => {
    const frameKey = parseInt(frameKeyStr)

    if (!annotation.properties?.[frameKey]) {
      return
    }

    annotation.properties[frameKey] = null
  })

  return annotation
}

/**
 * Unify properties values to prevent duplicated values error
 */
export const unifyPropertiesValues = (
  annotation: Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'>,
): Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'> => {
  if (!annotation.properties) {
    return annotation
  }

  Object.keys(annotation.properties).forEach((frameKeyStr) => {
    const frameKey = parseInt(frameKeyStr)

    const properties = annotation.properties?.[frameKey]

    if (!properties) {
      return
    }

    Object.keys(properties).forEach((propertyId) => {
      properties[propertyId] = properties[propertyId] === null ? null : uniq(properties[propertyId])
    })
  })

  return annotation
}

export const willApplyPropertyCorrection = (
  annotation: Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'>,
  oldAnnotationSegment?: VideoAnnotationDataSegment[],
  oldClassId?: number,
): Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'> => {
  let res = annotation

  if (isVideoAnnotationDataPayload(annotation.data)) {
    res = removePropertiesOutsideSegment(res)
    res = willSetFirstFrameEmptyProperties(res, oldAnnotationSegment)
  }

  res = classChangeWillDeleteAllProperties(res, oldClassId)
  res = unifyPropertiesValues(res)

  return res
}
