export const binarySearch = <T extends { id: string }>(
  items: T[],
  value: string,
): { found: boolean; middle: number } => {
  let startIndex = 0
  let stopIndex = items.length - 1
  let middle = Math.floor((stopIndex + startIndex) / 2)
  let target = items[middle]

  // early exist if we have no itmes
  if (items.length === 0) {
    return {
      found: false,
      middle: 0,
    }
  }

  while (target.id !== value && startIndex < stopIndex) {
    // adjust search area
    if (value < target.id) {
      stopIndex = middle - 1
    } else if (value > target.id) {
      startIndex = middle + 1
    }

    // recalculate middle
    middle = Math.floor((stopIndex + startIndex) / 2)
    // break the loop if we have gonne out of boundaries
    if (!items[middle]) {
      break
    }
    target = items[middle]
  }

  const found = target.id === value
  if (found) {
    return {
      found,
      middle,
    }
  }

  // the item isn't present but we know where it should be placed
  // this way we don't neep to loop twice
  const guessedIndex = target.id < value ? middle + 1 : middle - 1
  return {
    found,
    middle: guessedIndex < 0 ? 0 : guessedIndex,
  }
}
