import type { Raster } from '@/modules/Editor/models/raster/Raster'
import { createRaster } from '@/modules/Editor/models/raster/createRaster'
import type { View } from '@/modules/Editor/views/view'

/**
 * Given a `View` object, returns the associated raster if present,
 * or creates a new one.
 *
 * @param view The view to get/create the raster from.
 * @returns The (new) raster.
 */
export const getOrCreateRasterForView = (view: View): Raster => {
  const currentFile = view.fileManager.file

  if (currentFile.metadata === undefined) {
    throw new Error('No file available to annotate')
  }

  // Define a new raster if one doesn't exist
  let raster = view.rasterManager.getRasterForFileInView()

  if (raster === undefined) {
    raster = createRaster(view)
    view.rasterManager.addRaster(raster)
    view.rasterAnnotationLayer.addRaster(raster.id)
  }

  return raster
}
