import { euclideanDistance } from '@/modules/Editor/algebra'
import { isPolyline } from '@/modules/Editor/annotationTypes/polyline'
import type { EditablePoint, IPoint } from '@/modules/Editor/point'
import { anchorCursor } from '@/modules/Editor/point'
import type { Editor } from '@/modules/Editor/editor'
import { inferCurrentAnnotationData } from '@/modules/Editor/inferCurrentAnnotationData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { POLYLINE_ANNOTATION_TYPE } from '@/modules/Editor/plugins/polyline/types'

/**
 * Loops through all visible polylines, finding the closest edge for the currentPoint
 * @returns [closest point on the edge, the polyline, the distance] or null
 */
export function findClosestPolylineEdge(
  editor: Editor,
  currentPoint: IPoint,
  maxDistance: number,
): [IPoint, Annotation, number, EditablePoint[]] | null {
  const annotationCursor = editor.activeView.camera.canvasViewToImageView(currentPoint)
  let distanceToEdge: Array<[number, IPoint, Annotation, number, EditablePoint[]]> = []

  for (const annotation of editor.activeView.annotationManager.visibleAnnotations) {
    // we should filter automatically somehow
    if (annotation.type !== POLYLINE_ANNOTATION_TYPE) {
      continue
    }
    const polyline = inferCurrentAnnotationData(annotation, editor.activeView.currentFrameIndex)

    if (!isPolyline(polyline)) {
      throw new Error('Annotation is not a polyline')
    }

    for (let i = 0; i < (polyline.path || []).length - 1; i++) {
      const point = polyline.path[i]
      const nextPoint = polyline.path[i + 1]
      const pointOnLine = anchorCursor(annotationCursor, point, nextPoint)
      const distanceFromCursor = euclideanDistance(
        editor.activeView.camera.imageViewToCanvasView(annotationCursor),
        editor.activeView.camera.imageViewToCanvasView(pointOnLine),
      )

      if (distanceFromCursor > maxDistance) {
        continue
      }

      // calculate if the point is between point and nextPoint.
      const AB = euclideanDistance(point, nextPoint)
      const AC = euclideanDistance(point, pointOnLine)
      const BC = euclideanDistance(nextPoint, pointOnLine)
      if (AB < AC + BC - 0.001) {
        continue
      }

      distanceToEdge.push([distanceFromCursor, pointOnLine, annotation, i + 1, polyline.path])
    }
  }
  distanceToEdge = distanceToEdge.sort(([d1], [d2]) => d1 - d2)
  if (distanceToEdge.length > 0) {
    return [distanceToEdge[0][1], distanceToEdge[0][2], distanceToEdge[0][3], distanceToEdge[0][4]]
  }
  return null
}
