import { defineAsyncComponent } from 'vue'
import type { RouteConfig } from 'vue-router'

const TeamClasses = defineAsyncComponent(() => import('@/modules/Classes/RouteTeamClasses.vue'))

const classesRoute: RouteConfig = {
  path: '/classes',
  name: 'Classes',
  component: TeamClasses,
  meta: { requiresAuth: true, requiresAbility: 'view_full_datasets' },
  children: [{ path: ':editClassId', name: 'ClassesEdit', component: TeamClasses }],
}

export default classesRoute
