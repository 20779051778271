/**
 * Loads the image into the memory and create Object URL
 */
export const getImageURL = (image: HTMLImageElement): string | null => {
  const { width, height } = image

  const tmpCanvas = document.createElement('canvas')
  tmpCanvas.width = width
  tmpCanvas.height = height
  const ctx = tmpCanvas.getContext('2d')
  if (!ctx) {
    return null
  }
  ctx.drawImage(image, 0, 0)

  return tmpCanvas.toDataURL()
}
