import { getMainAnnotationType, type AnnotationType } from '@/core/annotationTypes'
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import { ClassEvents, EditorEvents } from '@/modules/Editor/eventBus'
import type { SubToolName, ToolName } from '@/modules/Editor/tools/types'
import type { View } from '@/modules/Editor/views/view'

/**
 * Returns a promise resolves into an annotation class compatible with the specified type
 *
 * If the preselected class is compatible, it will be returned.
 * If not, the user will be shown a class selection dialog.
 *
 * If the user cancels the selection, the promise will resolve to `null`
 */
const resolveAnnotationClass = async (activeView: View): Promise<AnnotationClass | null> => {
  try {
    return await activeView.editor.classDialog.requestUserSelectClass()
  } catch {
    return null
  }
}

const preselectClasses = async (
  activeView: View,
  annotationTypes: AnnotationType[],
  errorMessage: string,
  mandatory = true,
): Promise<boolean> => {
  const { toolManager } = activeView

  const annotationClass = await resolveAnnotationClass(activeView)
  if (!annotationClass) {
    EditorEvents.message.emit({ content: errorMessage, level: 'info' })

    if (mandatory) {
      toolManager.activateToolWithStore('edit_tool')
    }

    return false
  }
  ClassEvents.preselectClassId.emit(annotationClass.id)

  return true
}

const getFirstClassForTool = (
  activeView: View,
  annotationTypes: AnnotationType[],
): AnnotationClass | undefined => {
  const isClassCompatibleWithClassFilter = (c?: AnnotationClass): boolean =>
    !!c && activeView.annotationManager.isClassCompatibleWithClassFilter(c.id)

  for (const annType of annotationTypes) {
    const classForTool = activeView.editor.getFirstClassForType(
      annType,
      isClassCompatibleWithClassFilter,
    )

    return classForTool
  }
}

/**
 * Evaluate if we need to prompt the user the class selection dialog, or fallback to
 * an existing one.
 * Usually called when activating a tool
 * @returns true if we want to keep up with the tool activation, false otherwise
 */
export const preselectOrPromptForAnnotationClass = async (
  activeView: View,
  toolName: ToolName | SubToolName,
  /**
   * The annotation types that the tool can work with
   */
  toolCompatibleAnnotationTypes: AnnotationType[],
  errorMessage: string,
  /**
   * Makes selecting an annotation class mandatory and will fallback to the edit_tool otherwise
   */
  mandatory = true,
): Promise<boolean> => {
  const { annotationManager } = activeView

  // step 1 - if the selected class, newly activated tool and selected annotations are all compatible, we are good

  const selectedAnnotationMainType = getMainAnnotationType(
    activeView.annotationManager.selectedAnnotation?.annotationClass.annotation_types || [],
  )

  const toolCompatibleMainType = getMainAnnotationType(toolCompatibleAnnotationTypes)

  const preselectedClassId = activeView.editor.preselectedAnnotationClassId
  const preselectedClass = preselectedClassId
    ? activeView.editor.getClassById(preselectedClassId)
    : undefined
  const currentlySelectedClassMainType = getMainAnnotationType(
    preselectedClass?.annotation_types || [],
  )

  // If we have an annotation selected and we switch to a tool that is compatible with this annotation
  // and the class that's currently selected is also compatible with both, then we don't have to change
  // anything. This can happen when switching between polygon and brush, as they both edit the polgon type,
  // but could also happen when switching between edit and any other tool, in theory.
  // The only time we've seen it happen is when switching from polygon to brush.

  const isEverythingCompatible =
    selectedAnnotationMainType &&
    selectedAnnotationMainType === toolCompatibleMainType &&
    currentlySelectedClassMainType === toolCompatibleMainType

  if (isEverythingCompatible) {
    return true
  }

  // If a preselected class for the active tool exists use it
  const preselectedClassIdPerTool = activeView.editor.preselectedClassIdPerTool
  const preselectedClassIdForToolType = preselectedClassIdPerTool[toolName]
  if (preselectedClassIdForToolType) {
    const currentPreslectedClassId = activeView.editor.preselectedAnnotationClassId
    if (preselectedClassIdForToolType !== currentPreslectedClassId) {
      ClassEvents.preselectClassId.emit(preselectedClassIdForToolType)
    }
    return true
  }

  // Default to first class if we have one
  const classForTool = getFirstClassForTool(activeView, toolCompatibleAnnotationTypes)
  if (classForTool !== undefined) {
    annotationManager.maybeChanceClassOfSelectedAnnotation(classForTool)
    ClassEvents.preselectClassId.emit(classForTool.id)
    return true
  }

  // Otherwise bring up class selection dialog
  return await preselectClasses(activeView, toolCompatibleAnnotationTypes, errorMessage, mandatory)
}
