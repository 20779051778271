import type { DatasetUploadMutation } from '@/store/modules/datasetUpload/types'

export const RESET_ALL: DatasetUploadMutation<void> = (state) => {
  state.currentUploadFile = null
  state.datasetId = null
  state.files = []
  state.path = ''
  state.tags = []
  state.tagClasses = []
  state.status = 'stopped'
}
