import { useRoute, useRouter } from 'vue-router/composables'
import session from '@/backend/session'
import { useAuthStore } from './useAuthStore'
import { useAttemptedRoute } from './useAttemptedRoute'

export const useInitialAuthLoader = (): void => {
  const router = useRouter()
  const route = useRoute()
  const authStore = useAuthStore()

  const { saveAttemptedRoute } = useAttemptedRoute()

  session.onLogout(async () => {
    console.info('User session expired, setting app state to unauthenticated')
    await authStore.logoutStore()

    saveAttemptedRoute(route)

    route.query.token
      ? router.replace({ name: 'Login', query: { token: route.query.token } })
      : router.replace({ name: 'Login' })
  })
}
