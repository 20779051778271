import type { Range } from '@/modules/Editor/types'

import { rangeOverlaps } from './rangeOverlaps'

/**
 * Finds the indicies of regions of the complex polygon which are in range to include
 * in polybooljs combine calculations.
 *
 * @param paddedCompoundPathRange The range to check for regions within.
 *
 * @returns An array of indicies.
 */
export const getIndiciesToMerge = (
  polygonRanges: Range[],
  paddedCompoundPathRange: Range,
): number[] => {
  const indicies: number[] = []

  polygonRanges.forEach((range, i) => {
    if (rangeOverlaps(paddedCompoundPathRange, range)) {
      indicies.push(i)
    }
  })

  return indicies
}
