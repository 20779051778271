import type { AnnotationType } from '@/core/annotationTypes'
import { isAnnotationSubType } from '@/core/annotationTypes'
import type { FrameData, SequenceData } from '@/core/annotations'
import type {
  AnnotationData,
  AutoAnnotateData,
  VideoAnnotationData,
} from '@/modules/Editor/AnnotationData'
import { autoAnnotateSerializer } from '@/modules/Editor/serialization/autoAnnotateSerializer'
import { deserializeFrame } from '@/modules/Editor/serialization/deserializeFrame'
import { measuresSerializer } from '@/modules/Editor/serialization/measuresSerializer'
import {
  isVideoAnnotationData,
  isVideoAnnotationDataPayload,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import { maskSerializer } from '@/modules/Editor/serialization/maskSerializer'
import type { View } from '@/modules/Editor/views/view'

type NormalizedSubAnnotation = { type: AnnotationType; data: AnnotationData | null }

export const normalizeAnnotationData = (
  type: AnnotationType,
  data: FrameData | SequenceData,
): AnnotationData | null => {
  if (isVideoAnnotationDataPayload(data)) {
    const frames: VideoAnnotationData['frames'] = {}
    Object.entries(data.frames).forEach(([frameIndex, frameData]) => {
      const autoAnnotateData = frameData.auto_annotate
        ? autoAnnotateSerializer.deserialize(frameData.auto_annotate)
        : undefined

      const measuresData = frameData.measures
        ? measuresSerializer.deserialize(frameData.measures)
        : undefined

      const deserializedData = deserializeFrame(type, frameData)

      // Always True if the `ANNOTATION_PAGINATION` FF is disabled
      const loaded = frameData.loaded === undefined ? true : frameData.loaded

      frames[frameIndex] = {
        ...frames[frameIndex],
        ...deserializedData,
        auto_annotate: autoAnnotateData,
        measures: measuresData || undefined,
        keyframe: frameData.keyframe,
        loaded,
      }
    })

    return { ...data, frames }
  }

  if (type === 'auto_annotate' && data.auto_annotate) {
    return autoAnnotateSerializer.deserialize(data.auto_annotate) as AnnotationData
  }

  return deserializeFrame(type, data)
}

const normalizeSubAnnotationsData = (
  data: FrameData,
): { type: AnnotationType; data: AnnotationData | null }[] =>
  (Object.keys(data) as AnnotationType[])
    .filter((type) => isAnnotationSubType(type))
    .map((type) => ({ type, data: normalizeAnnotationData(type, data) }))

// Possible bug
// We would expect this to accept a FrameData | SequenceData, since it's doing normalization
export const normalizeMaskData = (view: View, data: AnnotationData): AnnotationData | null => {
  const raster = view.rasterManager.getRasterForFileInView()

  if (raster === undefined) {
    return null
  }

  if (isVideoAnnotationData(data)) {
    const frames: { [k: string]: AnnotationData } = {}
    Object.entries(data.frames).forEach(([frameIndex, frameData]) => {
      // This whole thing looks bugged. The auto-annotate serializer `deserialize` function expect
      // an object like { bbox, clicks, ... }, not an object like { auto_annotate }
      // Potentially, this whole if branch does nothing.
      const hasAutoAnnotate = 'auto_annotate' in frameData && !!frameData.auto_annotate
      if (hasAutoAnnotate) {
        const deserializedAutoAnnData = autoAnnotateSerializer.deserialize(
          frameData as AutoAnnotateData,
        )

        if (deserializedAutoAnnData) {
          frames[frameIndex] = {
            ...frames[frameIndex],
            auto_annotate: deserializedAutoAnnData,
          }
        }

        const deserializedData = maskSerializer.deserialize(raster)
        if (deserializedData) {
          frames[frameIndex] = {
            ...frames[frameIndex],
            ...deserializedData,
          }
        }
        return
      }

      const deserializedData = maskSerializer.deserialize(raster)
      if (deserializedData) {
        frames[frameIndex] = deserializedData
      }
    })
    return { ...data, frames }
  }

  return maskSerializer.deserialize(raster)
}

export const normalizeSubAnnotations = (
  data: FrameData | SequenceData,
): NormalizedSubAnnotation[] | { [k: string]: NormalizedSubAnnotation[] } => {
  if (!('sub_frames' in data)) {
    return normalizeSubAnnotationsData(data as FrameData)
  }

  const subAnnotations: {
    [k: string]: {
      type: AnnotationType
      data: AnnotationData | null
    }[]
  } = {}

  for (const [index, frameData] of Object.entries(data.sub_frames)) {
    subAnnotations[index] = normalizeSubAnnotationsData(frameData)
  }

  return subAnnotations
}
