import { defineAsyncComponent } from 'vue'
import type { RouteConfig } from 'vue-router'

const meta = {
  requiresAuth: true,
  requiresAbility: 'view_full_datasets',
}

const reportsRoute: RouteConfig = {
  path: '/reports',
  name: 'Reports',
  component: defineAsyncComponent(() => import('@/modules/Reports/RouteReports.vue')),
  children: [
    {
      path: 'datasets',
      name: 'DatasetsReport',
      component: defineAsyncComponent(() => import('@/modules/Reports/DatasetsLayout.vue')),
      meta,
    },
    {
      path: 'users',
      name: 'AnnotatorsReport',
      component: defineAsyncComponent(() => import('@/modules/Reports/AnnotatorsLayout.vue')),
      meta,
    },
  ],
  meta,
}

export default reportsRoute
