import type { V2DatasetItemPayload } from '@/store/types'
import { get } from '@/backend/api'
import { errorMessages, isErrorResponse, parseError, type ParsedError } from '@/backend/error'
import type { AxiosResponse } from 'axios'

type Params = { teamSlug: string; itemId: string }

export const loadItem = async (params: Params): Promise<AxiosResponse | ParsedError> => {
  const { teamSlug, itemId } = params
  const path = `/v2/teams/${teamSlug}/items/${itemId}`

  try {
    const response = await get<V2DatasetItemPayload>(path)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.ITEM_LOAD)
  }
}
