import type { FrameData } from '@/core/annotations'
import type { AnnotationData, Attributes } from '@/modules/Editor/AnnotationData'

export const attributesSerializer = {
  serialize(data: AnnotationData): FrameData {
    // it looks like this one never actually gets called
    // attributes are somehow saved while bypassing the serializer
    const attributesData = data as { attributes: { attributes: string[] } }
    return { attributes: attributesData.attributes }
  },

  deserialize(rawData: FrameData): Attributes | null {
    if (!rawData.attributes) {
      return null
    }
    return {
      attributes: rawData.attributes.attributes,
    }
  },
}
