import { until } from '@vueuse/core'
import qs from 'qs'
import { defineAsyncComponent } from 'vue'
import Router from 'vue-router'
import type { Store } from 'vuex'

import { useAuthStore } from '@/modules/Auth/useAuthStore'
import { useFeatureFlagsStore } from '@/pinia/useFeatureFlagsStore'
import type { RootState } from '@/store/types'
import type { FeatureName } from '@/store/types/FeaturePayload'
import { getToken } from '@/backend/token'
import { useAttemptedRoute } from '@/modules/Auth/useAttemptedRoute'
import { useTeamStore } from '@/pinia/useTeamStore'
import { isMobileOrTablet } from '@/core/utils/browser'

import annotatorsRoute from './annotatorsRoute'
import classesRoute from './classesRoute'
import datasetRoute from './datasetRoute'
import modelRoute from './modelRoute'
import { ssoExchangeGuard } from './navigationGuards'
import reportsRoute from './reportsRoute'
import { workflowRoute, workflowsRoute } from './workflowsRoute'
import { workviewRoute } from './workviewRoute'

type Meta = {
  requiresAbility?: 'view_full_datasets'
  requiresAuth?: boolean
  requiresFeature?: FeatureName
  layout?: string
}

const tfaRoutes = ['/login-2fa', '/setup-2fa']

export const createRouter = (): Router => {
  const router: Router = new Router({
    mode: 'history',
    parseQuery: qs.parse,
    stringifyQuery: (query: object): string => qs.stringify(query, { addQueryPrefix: true }),
    routes: [
      {
        path: '/',
        component: () => import('@/layouts/AppLayout.vue'),
        children: [
          {
            path: '/',
            name: 'Root',
            component: () => import('@/layouts/Root.vue'),
            meta: { requiresAuth: true },
          },
          classesRoute,
          annotatorsRoute,
          workviewRoute,
          modelRoute,
          reportsRoute,
          {
            path: '/login',
            name: 'Login',
            component: defineAsyncComponent(() => import('@/modules/Auth/RouteLogin.vue')),
            meta: { layout: 'empty' },
          },
          {
            path: '/login-success',
            name: 'LoginSuccess',
            component: defineAsyncComponent(
              () => import('@/modules/Auth/RouteLoginSuccessMobile.vue'),
            ),
            meta: { requiresAuth: true, layout: 'empty' },
          },
          {
            path: '/login-sso',
            name: 'LoginSSO',
            component: defineAsyncComponent(() => import('@/modules/Auth/RouteLoginSSO.vue')),
            meta: { layout: 'empty' },
          },
          {
            path: '/sso/saml/exchange',
            name: 'SSOSamlExchange',
            beforeEnter: ssoExchangeGuard,
          },
          { path: '/sso/exchange', name: 'SSOExchange', beforeEnter: ssoExchangeGuard },
          {
            path: '/login-2fa',
            name: 'LoginTfa',
            component: defineAsyncComponent(() => import('@/modules/Auth/RouteLoginTfa.vue')),
            meta: { layout: 'empty' },
          },
          {
            path: '/setup-2fa',
            name: 'SetupTfa',
            component: defineAsyncComponent(() => import('@/modules/Auth/RouteSetupTfa.vue')),
            meta: { layout: 'empty' },
          },
          {
            path: '/forgot-password',
            name: 'ForgotPassword',
            component: defineAsyncComponent(() => import('@/modules/Auth/RouteForgotPassword.vue')),
            meta: { layout: 'empty' },
          },
          {
            path: '/password-reset',
            name: 'PasswordReset',
            component: defineAsyncComponent(() => import('@/modules/Auth/RoutePasswordReset.vue')),
            meta: { layout: 'empty' },
          },
          {
            path: '/register',
            name: 'Register',
            component: defineAsyncComponent(() => import('@/modules/Auth/RouteRegister.vue')),
            meta: { layout: 'empty' },
          },
          {
            path: '/register-team',
            name: 'RegisterTeam',
            component: defineAsyncComponent(() => import('@/modules/Teams/RouteRegisterTeam.vue')),
            meta: { requiresAuth: true, layout: 'empty' },
          },
          {
            path: '/register-members',
            name: 'RegisterMembers',
            component: defineAsyncComponent(
              () => import('@/modules/Teams/RouteRegisterMembers.vue'),
            ),
            meta: { requiresAuth: true, layout: 'empty' },
          },
          {
            path: '/select-team',
            name: 'SelectTeam',
            component: defineAsyncComponent(() => import('@/modules/Teams/RouteTeamSelect.vue')),
            meta: { requiresAuth: true, layout: 'empty' },
          },
          {
            path: '/join',
            name: 'Invitations',
            component: defineAsyncComponent(() => import('@/modules/Auth/RouteInvitations.vue')),
            meta: { layout: 'empty' },
          },
          {
            path: '/customize-experience',
            name: 'CustomizeExperience',
            component: defineAsyncComponent(
              () => import('@/modules/Auth/RouteCustomizeExperienceWizard.vue'),
            ),
            meta: { requiresAuth: true, layout: 'empty' },
          },
          {
            path: '/account-deleted',
            name: 'AccountDeleted',
            component: defineAsyncComponent(() => import('@/modules/Auth/RouteAccountDeleted.vue')),
            meta: { layout: 'empty' },
          },
          {
            path: '/error',
            name: 'error',
            component: defineAsyncComponent(() => import('@/layouts/ErrorPage.vue')),
            meta: { layout: 'empty' },
          },
          {
            path: '/datasets',
            name: 'DatasetsIndex',
            component: defineAsyncComponent(() => import('@/modules/Datasets/RouteDatasets.vue')),
            meta: { requiresAuth: true, requiresAbility: 'view_full_datasets' },
          },
          {
            path: '/datasets/:datasetId',
            redirect: '/datasets/:datasetId/dataset-management',
            meta: {
              requiresAuth: true,
              requiresAbility: 'view_full_datasets',
            },
          },
          datasetRoute,
          workflowsRoute,
          workflowRoute,
          { path: '*', redirect: '/error' },
        ],
      },
    ],
  })

  /**
   * Global handler to fire before each route navigation
   *
   * It's important to follow the pattern of an early return on redirect.
   *
   * If we return with `next()` early, the remaining checks will never be performed.
   *
   * In any other case, callback should not return until the end.
   */
  router.beforeEach(async (to, from, next) => {
    const store = router.app.$store as Store<RootState>
    const meta = to.meta as Meta
    const authStore = useAuthStore(store)

    const featureFlagsStore = useFeatureFlagsStore()
    const { saveAttemptedRoute } = useAttemptedRoute()

    // If there is an access token,
    // it means there is a session that will expire in 8 or less minutes.
    // We can use it to log in.
    // We can't keep the session alive only with the access token indefinitely,
    // but we might get a refresh token from another open tab, or if we don't,
    // once the access token does expire, we will be sent to login,
    // and redirected back to where we were.
    if (!authStore.authenticated && getToken()) {
      await authStore.loginWithToken()
    }

    if (!featureFlagsStore.loaded) {
      await until(() => featureFlagsStore.loaded)
    }

    const isUnauthRoute = ['Login', 'ForgotPassword', 'PasswordReset'].some((r) => r === to.name)

    if (meta.requiresAuth && !authStore.authenticated && !isUnauthRoute) {
      // pass path and query as `prev` param to login route before redirecting
      // login route can then redirect back to attempted route
      saveAttemptedRoute(to)
      return next({ name: 'Login' })
    }

    if (authStore.authenticated) {
      // we don't want already logged in users to visit the login page
      if (to.name === 'Login') {
        return next({ name: 'Root' })
      }

      // TODO DAR-1584: needs to be called inline to deal with team store relying on vuex store
      // can be moved out once we deal with that
      const currentTeam = useTeamStore().currentTeam

      // ensure user has a team
      if (!currentTeam?.id) {
        if (tfaRoutes.includes(to.path) || to.path === '/register-team' || to.path === '/join') {
          return next()
        }
        return next({ name: 'RegisterTeam' })
      }
      // ensure the current team is not disabled
      if (currentTeam && currentTeam.disabled && to.path !== '/select-team') {
        return next({ name: 'SelectTeam' })
      }

      // ensure mobile users can register members
      if (to.name === 'RegisterMembers') {
        return next()
      }

      // check if it isn't desktop for logged in user
      if (isMobileOrTablet()) {
        if (to.path === '/login-success') {
          return next()
        }
        return next({ name: 'LoginSuccess' })
      }
    }

    // some routes require a special ability to access
    const { requiresAbility: ability } = meta
    if (ability && !authStore.isAuthorized(ability)) {
      return next({ name: 'Root' })
    }

    const { requiresFeature: feature } = meta
    if (feature && !featureFlagsStore.featureFlags[feature]) {
      return next({ name: 'Root' })
    }

    return next()
  })

  return router
}

const router = createRouter()

export default router
