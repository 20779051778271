import type { V2DatasetItemTimeSummaryPayload } from '@/store/types'
import { get } from '@/backend/api'
import { errorMessages, isErrorResponse, parseError, type ParsedError } from '@/backend/error'
import type { AxiosResponse } from 'axios'

type Params = {
  datasetItemId: string
  teamSlug: string
}

export const loadV2DatasetItemTimeSummary = async (
  params: Params,
): Promise<AxiosResponse | ParsedError> => {
  const path = `v2/teams/${params.teamSlug}/items/${params.datasetItemId}/time_summary`

  try {
    const response = await get<V2DatasetItemTimeSummaryPayload>(path)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.DATASET_ITEM_LOAD)
  }
}
