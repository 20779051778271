import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import type { ActionGroup } from '@/modules/Editor/managers/actionManager'
import type { IPoint } from '@/modules/Editor/point'
import type { Segment } from '@/modules/Editor/polybooljs/types'
import type { PointerEvent } from '@/core/utils/touch'
import { isTouchEvent } from '@/core/utils/touch'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { buildRegularPolygonPath } from '@/modules/Editor/plugins/brush/utils/buildRegularPolygonPath'

export class BrushTool {
  /**
   * The polygon path descibing the tip of the polygon.
   * It is instantiated as a dodecagon, and rebuilt every time the tip size or shape changes
   */
  protected tipPath: number[][] = buildRegularPolygonPath(5, 12)

  /**
   * Whole path being created. Since it can be drawn with multiple brush strokes,
   * it is computed by merging all currentPath instances
   */
  protected compoundPath: Segment | null = null

  /** Size of the brush, corresponding to the radius of the tip */
  protected size: number = 10

  /** Last known position of the mouse (on image) Constantly updates as mouse moves. */
  protected mousePoint: IPoint | undefined = undefined

  /** The previous position of the mouse, used to interpolate between onmousemove calls */
  protected previousMousePoint: IPoint | undefined = undefined

  /**
   * This variable is used to see if we should rebuild the brush tip on class change.
   * This is because if we switch between a mask and a polygon class, we need to
   * rebuild the brush tip, even if we keep the tipShape and size the same.
   */
  protected usingMaskClass: boolean = false

  protected actionGroup: ActionGroup | undefined = undefined

  protected touching: boolean = false

  protected maybeSuppressMouseEvent(event: PointerEvent): CallbackStatus | void {
    event.preventDefault()
    const touching = isTouchEvent(event)
    if (!touching && this.touching) {
      // A touch event was already triggered, so we should prevent this mouse event to trigger
      return CallbackStatus.Stop
    }
    this.touching = touching
  }

  /**
   * Currently selected annotation.
   * If it is defined, the brush will draw on top of it.
   * Otherwise, the brush will draw a new path.
   */
  protected selectedAnnotation: Annotation | undefined = undefined

  /** id of the annotation the tool works with. */
  public get selectedAnnotationId(): string | null {
    return this.selectedAnnotation?.id || null
  }
}
