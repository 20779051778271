import { WindAuthAction } from '@/backend/darwin/WindAuthAction'
import type { ApiResult } from '@/backend/darwin/types'
import { errorMessages } from '@/backend/error'

import { withAuth } from './api'
import type { InferenceRequestCountPayload } from './types'

type Params = {
  from: string
  granularity: 'month' | 'day' | 'hour' | 'minute'
  runningSessionId: string
  teamId: number
}
export const loadInferenceRequests = (
  payload: Params,
): Promise<ApiResult<InferenceRequestCountPayload[]>> => {
  const { from, granularity, runningSessionId, teamId } = payload

  const authParams = { action: WindAuthAction.ViewModels, teamId }

  const path = `running_sessions/${runningSessionId}/inference_requests`
  const params = {
    granularity,
    from,
  }
  return withAuth<InferenceRequestCountPayload[]>(
    authParams,
    (client) => client.get<InferenceRequestCountPayload[]>(path, { params }),
    errorMessages.NEURAL_MODEL_DATA,
  )
}
