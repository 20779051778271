import { getCompoundPathFromPolygon } from '@/modules/Editor/annotationTypes/polygon'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import { drawPath } from '@/modules/Editor/graphics'
import { inferCurrentAnnotationData } from '@/modules/Editor/inferCurrentAnnotationData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { getPath2D, setPath2D } from '@/modules/Editor/models/annotation/annotationRenderingCache'
import type { ILayer } from '@/modules/Editor/models/layers/types'
import type { View } from '@/modules/Editor/views/view'

export const renderPolygon = (
  view: View,
  layer: ILayer<CanvasRenderingContext2D, HTMLCanvasElement>,
  annotation: Annotation,
  inferred: boolean,
  filter: ImageManipulationFilter | null,
): Path2D | undefined => {
  if (!layer.context) {
    return
  }

  const isSelected = view.annotationManager.isSelected(annotation.id)
  const isHighlighted = view.annotationManager.isHighlighted(annotation.id)
  const compoundPath = getCompoundPathFromPolygon(
    inferCurrentAnnotationData(annotation, view.currentFrameIndex),
  )
  const color = annotation.color
  const blackout = annotation.annotationClass?.blackout === true
  if (blackout && filter) {
    filter = { ...filter, opacity: 100 }
  }

  const oldPath2D = getPath2D(annotation.id)

  const path2D = drawPath(
    compoundPath,
    layer.context,
    view.camera,
    color,
    inferred,
    filter,
    isHighlighted && !blackout,
    isSelected && !blackout,
    oldPath2D,
  )

  if (path2D) {
    setPath2D(annotation.id, path2D)
  }

  return path2D
}
