import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import type { WindowLevel } from '@/modules/Editor/imageManipulation'
import { hasPointerLockSupport } from '@/modules/Editor/plugins/windowLevel/consts'
import type { WindowLevelState } from '@/modules/Editor/plugins/windowLevel/types'
import type { View } from '@/modules/Editor/views/view'

import { getWindowLevel, setWindowLevel } from './windowLevelController'

export const onMouseMove = (
  event: MouseEvent,
  activeView: View,
  windowLevelState: WindowLevelState,
): CallbackStatus => {
  const x = hasPointerLockSupport ? event.movementX : event.offsetX
  const y = hasPointerLockSupport ? event.movementY : event.offsetY
  if (!windowLevelState.previousPoint) {
    windowLevelState.previousPoint = { x, y }
    return CallbackStatus.Stop
  }

  windowLevelState.cursorPoint = { x, y }

  const { cursorPoint, previousPoint } = windowLevelState

  if (cursorPoint.x - previousPoint.x !== 0 || cursorPoint.y - previousPoint.y !== 0) {
    const { windowSensitivity, levelSensitivity } = windowLevelState.sensitivity
    const currentWindowLevel = getWindowLevel(activeView)

    const dX = hasPointerLockSupport ? cursorPoint.x : cursorPoint.x - previousPoint.x
    const dY = hasPointerLockSupport ? cursorPoint.y : cursorPoint.y - previousPoint.y

    const newWindow: WindowLevel = {
      window: currentWindowLevel.window + dX * windowSensitivity,
      level: currentWindowLevel.level + dY * levelSensitivity,
    }
    setWindowLevel(activeView, newWindow)
  }

  windowLevelState.previousPoint = { x, y }
  return CallbackStatus.Stop
}
