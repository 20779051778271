import type { FrameData, SequenceData } from '@/core/annotations'
import type { AnnotationData, VideoAnnotationData } from '@/modules/Editor/AnnotationData'
import { isVideoAnnotationData } from '@/modules/Editor/models/annotation/annotationKindValidator'
import type { Bounds, Raster } from '@/modules/Editor/models/raster/Raster'
import type { VideoRaster } from '@/modules/Editor/models/raster/VideoRaster'
import { assertImageRaster } from '@/modules/Editor/models/raster/assertImageRaster'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import { encodeSparseRLE } from '@/modules/Editor/plugins/mask/rle/sparseRle'
import type { EncodeSparseRLEBounds } from '@/modules/Editor/plugins/mask/rle/sparseRle'

import type { UpdateMeta } from './types'

/**
 * Gets the keyframes from the video raster which fall within the segment.
 */
const getKeyframes = (
  rasterKeyframes: number[],
  segments: VideoAnnotationData['segments'],
): number[] => {
  if (!segments) {
    throw new Error('segments should exist for video annotation at time of serialization')
  }

  const minFrame = segments[0][0]
  const maxFrame = segments[0][1]

  if (maxFrame === null) {
    throw new Error('Both segments should be defined for Video Mask Annotation')
  }

  const keyFrames: number[] = []

  for (let i = 0; i < rasterKeyframes.length; i++) {
    const keyFrame = rasterKeyframes[i]

    if (keyFrame < minFrame) {
      continue
    }

    if (keyFrame > maxFrame) {
      break
    }

    keyFrames.push(keyFrame)
  }

  return keyFrames
}

/**
 * Convert the raster to serialized image annotation data.
 */
const serializeImageAnnotationData = (
  raster: Raster,
  labelIndex: number,
  bounds?: Bounds,
): { mask: { sparse_rle: number[] } } => {
  let encodeSparseRLEBounds: EncodeSparseRLEBounds | undefined

  if (bounds !== undefined) {
    encodeSparseRLEBounds = {
      maskWidth: raster.width,
      topLeft: bounds.topLeft,
      bottomRight: bounds.bottomRight,
    }
  }

  // Note this could be an image, video or voxel mask at this point, as all
  // video annotations are initially created as image annotations.

  let buffer: Uint8Array

  if (raster.type === RasterTypes.IMAGE) {
    buffer = assertImageRaster(raster).getBufferForSerialization()
  } else if ([RasterTypes.VIDEO, RasterTypes.VOXEL].includes(raster.type)) {
    const frameBuffer = assertVideoRaster(raster).getBufferForImageSerialization()

    if (frameBuffer === undefined) {
      throw new Error('No buffer for video frame upon initial serialization')
    }

    buffer = frameBuffer
  } else {
    throw new Error('Unrecognised RasterType')
  }

  const sparse_rle = encodeSparseRLE(buffer, labelIndex, encodeSparseRLEBounds)

  return {
    mask: {
      sparse_rle,
    },
  }
}

/**
 * Convert the raster to serialized video annotation data.
 */
const serializeVideoAnnotationData = (
  videoAnnotationData: VideoAnnotationData,
  videoRaster: VideoRaster,
  labelIndex: number,
  updateMeta?: UpdateMeta,
): SequenceData => {
  const rasterKeyframes = videoRaster.rasterKeyframes
  const segments = videoAnnotationData.segments

  // We need to keep existing frames in the serializer output
  // as otherwise they will be lost when the annotation is updated.
  const frames: SequenceData['frames'] = videoAnnotationData.frames
  const frameBuffers = videoRaster.frameBuffers

  let keyframes: number[]

  if (updateMeta && updateMeta.updatedFramesIndices) {
    // serialize specified frames

    const { updatedFramesIndices } = updateMeta

    keyframes = [...updatedFramesIndices]
  } else {
    // Serialize all frames
    keyframes = getKeyframes(rasterKeyframes, segments)
  }

  const videoBounds = videoRaster.getVideoBoundsForLabelIndex(labelIndex)

  keyframes.forEach((keyframe) => {
    const buffer = frameBuffers[keyframe]

    if (!buffer) {
      throw new Error('No buffer for keyframe defined on raster')
    }

    const bounds = videoBounds[keyframe]

    let encodeSparseRLEBounds: EncodeSparseRLEBounds | undefined

    if (bounds) {
      encodeSparseRLEBounds = {
        maskWidth: videoRaster.width,
        topLeft: bounds.topLeft,
        bottomRight: bounds.bottomRight,
      }
    }

    frames[keyframe] = {
      keyframe: true,
      mask: {
        sparse_rle: encodeSparseRLE(buffer, labelIndex, encodeSparseRLEBounds),
      },
    }
  })

  return {
    frames,
    interpolate_algorithm: videoAnnotationData.interpolate_algorithm,
    interpolated: videoAnnotationData.interpolated,
    segments: videoAnnotationData.segments,
    // FIXME: another place where we seem to be completely circumventing aserialization of subs
    sub_frames: videoAnnotationData.sub_frames as SequenceData['sub_frames'],
  }
}

/**
 * Serializer for annotations of Mask type.
 *
 * The data is extracted from the semantic raster layer, but the resulting data payload is
 * scoped per annotation.
 */
export const maskSerializer = {
  serialize(
    data: AnnotationData | VideoAnnotationData,
    raster: Raster | undefined,
    annotationId: string,
    updateMeta?: UpdateMeta,
  ): FrameData | SequenceData {
    if (raster === undefined) {
      // Happens during raster deletion.
      return {
        mask: {
          sparse_rle: [],
        },
      }
    }

    const labelIndex = raster.getLabelIndexForAnnotationId(annotationId)

    if (labelIndex === undefined) {
      /**
       * labelIndex is not yet defined, so can't get sparse_rle data
       * for this annotation.
       */

      return {
        mask: {
          sparse_rle: [],
        },
      }
    }

    if (
      (raster.type === RasterTypes.VIDEO || raster.type === RasterTypes.VOXEL) &&
      'frames' in data
    ) {
      // Serialization annotation which is not new and has been made a video annotation already
      if (!isVideoAnnotationData(data)) {
        throw new Error('Annotation on Video Raster Layer should be a VideoAnnotation')
      }

      return serializeVideoAnnotationData(data, assertVideoRaster(raster), labelIndex, updateMeta)
    }

    let bounds: Bounds

    if (raster.type === RasterTypes.VIDEO || raster.type === RasterTypes.VOXEL) {
      const videoRaster = assertVideoRaster(raster)

      const videoBounds = videoRaster.getVideoBoundsForLabelIndex(labelIndex)

      bounds = videoBounds[raster.currentFrameIndex]
    } else {
      const imageRaster = assertImageRaster(raster)

      bounds = imageRaster.getBoundsForLabelIndex(labelIndex)
    }

    return serializeImageAnnotationData(raster, labelIndex, bounds)
  },

  /**
   * Note that our internal annotations _only_ hold the reference to the
   * raster they are associated with.
   */
  deserialize(raster: Raster): AnnotationData {
    const rasterId = raster.id

    return {
      rasterId: rasterId,
    }
  },
}
