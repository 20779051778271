import type { VideoAnnotationData } from '@/modules/Editor/AnnotationData'

const getEndFrame = (
  totalFrames: number,
  startFrame: number,
  videoAnnotationDuration: number,
): number => Math.min(totalFrames, startFrame + videoAnnotationDuration)

/**
 * Computes the segments value for a newly created video annotation.
 *
 * On single view videos, this usually just [currentFrame, length]. However,
 * there are different edge cases that need to be accounted for.
 *
 * - annotation length might put the annotation to past the length of the video,
 *   so we need to respect the upper bound
 * - with split views, each view past the 0th has a built in offset, so that
 *   offset needs to be accounted for.
 */
export const getInitialVideoAnnotationSegments = (
  startFrame: number,
  totalFrames: number,
  videoAnnotationDuration: number,
): NonNullable<VideoAnnotationData['segments']> => {
  const endFrame = getEndFrame(totalFrames, startFrame, videoAnnotationDuration)

  return [[startFrame, endFrame]]
}
