import { FramesLoaderEvents } from '@/modules/Editor/eventBus'
import { LimitedArray } from '@/modules/Editor/limitedArray'
import { getImageURL } from '@/modules/Editor/utils/getImageURL'
import { setContext } from '@/services/sentry'

import type { Frame, FramesLoaderConfig } from './types'

/**
 * Number of maximum simultaneous requests.
 */
const HARDWARE_CONCURRENCY = 2

/**
 * Maximum number of frames to cache in the worker.
 * These are URL strings created with URL.createObjectURL so we can have a big number as
 * the memory footprint isn't too big
 */
const MAX_CACHED_FRAMES = 1000

type CachedFrame = {
  index: number
  image: string // Image as URL string.
}

/**
 * Implementation of the frame loading behavior.
 * Provides an API to load individual high or low quality frames with top
 * priority, as well as a facility to queue up frames to load.
 *
 * Not to be used directly, but rather via the promise worker wrapper.
 */
export class FramesLoader {
  private config: Required<FramesLoaderConfig> = {
    hqOnly: false,
    dicomVolumePrefetching: false,
    hardwareConcurrency: HARDWARE_CONCURRENCY,
  }

  private lqController = new AbortController()
  private hqController = new AbortController()

  /**
   * Keeps active frame loading requests.
   * Supports multiple requests at a time.
   */
  private loading: Map<number, Promise<string | null | void>> = new Map()

  /**
   * Current frame index that Loader works with.
   */
  private currentFrameToLoad: number | null = 0
  /**
   * Next frame that Loader will take to work.
   * Supports jumps throw the frames using setNextFrameToLoad.
   */
  private nextFrameToLoad: number | null = null

  /**
   * Keeps all frame indexes that need to be loaded.
   */
  private framesToLoad: Set<number> = new Set()

  /**
   * Variables required for volume loading option
   */
  private frameOriginForLoad: number = 0
  private minFrameToLoad: number = 0
  private maxFrameToLoad: number = 0

  /**
   * Datastructures to hold cached frames.
   *
   * We cache frames in the worker up to the MAX_CACHED_FRAMES limit, after this the oldest
   * accessed object is removed.
   *
   * We have two datastructures as the LimitedArray is used for keeping track of timing, and the
   * cachedFramesMap is used for quick querying. They share the same underyling data as they point
   * to the same references.
   */
  private cachedFramesMap: Map<number, CachedFrame> = new Map()
  private cachedFramesLimitedArray = new LimitedArray<CachedFrame>(MAX_CACHED_FRAMES)

  /**
   * Keeps frame indexes that were loaded in High Quality.
   * Used to detect when we should not replace this.frames
   * cached value with a lower quality response.
   */
  private hqLoadedIndexes: Set<number> = new Set()
  /**
   * Keeps file sections Map to connect indexes with urls.
   */
  private slotSections: Map<number, Frame> = new Map()

  /**
   * Trick to implement promise for getSectionsByIndex
   * It's polling the variable to catch the time when it gets
   * response after frames request message
   */
  private waitForSection: boolean = false

  constructor(private id: string) {}

  /**
   * Push sections to the workers list
   * To get access to the section urls (hq/lq)
   * @param sections
   */
  public pushSections(sections: Frame[]): void {
    sections.forEach((section) => {
      this.slotSections.set(section.section_index, section)
    })

    this.waitForSection = false

    this.loadFrames()
  }

  /**
   * Clear and sets frame indexes that need to be loaded.
   * @param frames
   * @returns
   */
  public setFramesToLoad(frames: number[]): void {
    this.framesToLoad.clear()

    if (frames.length) {
      this.currentFrameToLoad = Infinity

      this.framesToLoad = new Set([...frames])

      if (this.currentFrameToLoad === null) {
        return
      }

      // Math.min and Math.max are too fragile we have a use case
      // where we try to get min / max from the array with 158,330 items and it crashes.
      // ANN-2679 - for more context
      for (const frameIndex of frames) {
        this.currentFrameToLoad =
          frameIndex < this.currentFrameToLoad ? frameIndex : this.currentFrameToLoad
        this.maxFrameToLoad = frameIndex > this.maxFrameToLoad ? frameIndex : this.maxFrameToLoad
      }
      this.minFrameToLoad = this.currentFrameToLoad

      this.loadFrames()
    }
  }

  public setConfig(config: FramesLoaderConfig): void {
    this.config.hqOnly = !!config.hqOnly
    this.config.dicomVolumePrefetching = !!config.dicomVolumePrefetching
    this.config.hardwareConcurrency = config.hardwareConcurrency || HARDWARE_CONCURRENCY
  }

  /**
   * Adds array of frame indexes to
   * the list of indexes that need to be loaded.
   * @param frames
   */
  public addFramesToLoad(frames: number[]): void {
    frames.forEach((index) => {
      this.framesToLoad.add(index)
    })

    this.loadFrames()
  }

  /**
   * Defines next frame to be loaded.
   * Returns promise of the sought frame.
   */
  public setNextFrameToLoad(index: number): Promise<string | null> {
    this.frameOriginForLoad = index
    this.nextFrameToLoad = index
    if (this.currentFrameToLoad === null) {
      this.currentFrameToLoad = index
    }

    if (!this.loading.has(index)) {
      const promise = this.useDefaultLoadFrameMethod(index).then((res) => {
        this.loading.delete(index)

        this.loadFrames()

        return res
      })

      this.loading.set(index, promise)

      return promise
    }

    return this.loading.get(index) as Promise<string | null>
  }

  /**
   * Returns a promise resolving in a loaded LQ frame.
   *
   * The frame is always loaded with highest priority, so this call cancells
   * all previous requests, making them resolve as null.
   *
   * The promise returned by this call can also be cancelled in the same way
   * by a subsequent call.
   */
  public async loadLQFrame(index: number): Promise<string | null> {
    if (this.loading.has(index)) {
      return (await this.loading.get(index)) || null
    }
    this.lqController.abort()
    this.lqController = new AbortController()
    return this.loadFrame(index, this.lqController, false, true)
  }

  /**
   * Returns a promise resolving in a loaded HQ frame.
   *
   * The frame is always loaded with highest priority, so this call cancells
   * all previous requests, making them resolve as null.
   *
   * The promise returned by this call can also be cancelled in the same way
   * by a subsequent call.
   */
  public loadHQFrame(index: number): Promise<string | null> {
    this.hqController.abort()
    this.hqController = new AbortController()
    return this.loadFrame(index, this.hqController, true, true)
  }

  private useDefaultLoadFrameMethod = (index: number): Promise<string | null> => {
    if (this.config.hqOnly) {
      return this.loadFrame(index, this.hqController, true)
    }

    return this.loadFrame(index, this.lqController)
  }

  /**
   * Initialise the frame loading process.
   * Loads all frames from framesToLoad arr
   * with HARDWARE_CONCURRENCY limitation of parallel requests.
   */
  private loadFrames(): void {
    if (this.waitForSection) {
      return
    }

    while (this.framesToLoad.size > 0 && this.loading.size <= this.config.hardwareConcurrency) {
      if (this.currentFrameToLoad === null) {
        return
      }

      const index = this.currentFrameToLoad

      if (!this.loading.has(index)) {
        this.loading.set(
          index,
          this.useDefaultLoadFrameMethod(index)
            .finally(() => this.loading.delete(index))
            .then((res) => {
              this.loadFrames()

              return res
            })
            .catch((e) => {
              setContext('error', { error: e })
              console.error('V2 frames loader, useDefaultLoadFrameMethod failed')
              return null
            }),
        )
      }

      if (this.framesToLoad.size === 0) {
        return
      }

      this.currentFrameToLoad = this.getNextIndex()
    }
  }

  private getNextIndex(): number | null {
    if (this.nextFrameToLoad !== null) {
      this.currentFrameToLoad = this.nextFrameToLoad

      this.nextFrameToLoad = null
    }

    if (this.config.dicomVolumePrefetching) {
      const nextIndex = this.getNextFrameToLoadVolumePrefetching()

      return nextIndex
    }

    if (this.currentFrameToLoad === null) {
      return null
    }

    return this.getNextClosestOrNull(this.currentFrameToLoad)
  }

  private getClosestBeforeOrigin(): number | null {
    const { minFrameToLoad, frameOriginForLoad } = this

    for (let i = frameOriginForLoad - 1; i >= minFrameToLoad; i--) {
      if (this.framesToLoad.has(i) && !this.loading.has(i)) {
        return i
      }
    }

    return null
  }

  private getClosestAfterOrigin(): number | null {
    const { maxFrameToLoad, frameOriginForLoad } = this

    for (let i = frameOriginForLoad + 1; i <= maxFrameToLoad; i++) {
      if (this.framesToLoad.has(i) && !this.loading.has(i)) {
        return i
      }
    }

    return null
  }

  private getNextFrameToLoadVolumePrefetching(): number | null {
    const { frameOriginForLoad } = this

    // NOTE: Get closest in from each direction and load closest.
    const closestBeforeOrigin = this.getClosestBeforeOrigin()
    const closestAfterOrigin = this.getClosestAfterOrigin()

    // If before is complete, return after
    if (closestBeforeOrigin === null) {
      return closestAfterOrigin
    }

    // If after is complete, return before
    if (closestAfterOrigin === null) {
      return closestBeforeOrigin
    }

    const distanceToBefore = frameOriginForLoad - closestBeforeOrigin
    const distanceToAfter = closestAfterOrigin - frameOriginForLoad

    return distanceToBefore < distanceToAfter ? closestBeforeOrigin : closestAfterOrigin
  }

  private getNextClosestOrNull(current: number): number | null {
    const length = this.framesToLoad.size
    for (let i = current + 1; i <= current + length; i++) {
      if (this.framesToLoad.has(i)) {
        return i
      }
    }

    return null
  }

  /**
   * Gets frame by it's index
   * By default returns LQ frame
   *
   * @param {number} index - frame index
   * @param {boolean} isHQ - download HQ frame
   * @param {boolean} force - tries to get the frame without checking download queue
   * @returns
   */
  private async loadFrame(
    index: number,
    controller: AbortController,
    isHQ: boolean = false,
    force: boolean = false,
  ): Promise<string | null> {
    const cachedFrame = this.cachedFramesMap.get(index)
    if (cachedFrame && (!isHQ || this.hqLoadedIndexes.has(index))) {
      this.setExistingCachedFrameAsVisited(cachedFrame)

      return cachedFrame?.image
    }

    if (!force && !this.framesToLoad.has(index) && (!isHQ || this.hqLoadedIndexes.has(index))) {
      return null
    }

    if (!this.slotSections.has(index)) {
      try {
        await this.getSectionByIndex(index)
      } catch (e) {
        setContext('error', { error: e })
        console.error('v2 framesLoader, loadFrame failed at getSectionByIndex')
        return null
      }
    }

    const section = this.slotSections.get(index)

    if (!section) {
      throw new Error("Can't get section")
    }

    let url = isHQ ? section.hq_url : section.lq_url

    if (!url) {
      console.warn(`No ${isHQ ? 'hq' : 'lq'} frame url. Frame index: ${index}`)

      if (!section.hq_url) {
        throw new Error(`Can't get urls for frame index: ${index}`)
      }

      isHQ = true
      url = section.hq_url
    }

    let frameObjectURL: string | null = null
    try {
      frameObjectURL = await this.loadFrameByUrl(url)
      this.framesToLoad.delete(index)

      if (!frameObjectURL) {
        throw new Error(`Can't get ${isHQ ? 'hq' : 'lq'} frame`)
      }

      if (!this.hqLoadedIndexes.has(index)) {
        this.setCachedFrame(index, frameObjectURL)
      }

      if (isHQ) {
        this.hqLoadedIndexes.add(index)
      }

      FramesLoaderEvents.frameLoaded.emit({
        index: index,
        url: frameObjectURL,
        isHQ: isHQ,
        id: this.id,
      })
    } catch (e) {
      this.loading.delete(index)

      // If the error was thrown after the object url was created, revoke it to release memory
      if (frameObjectURL) {
        URL.revokeObjectURL(frameObjectURL)
      }

      if (e instanceof Error && e.name === 'AbortError') {
        // AbortError is thrown when the request is aborted programmatically.
        // This only happens when we abort the request to load a new frame with priority.
        // For this reason, we can retry instead of returning null,
        // without falling in an infinite loop.
        return this.loadFrame(index, isHQ ? this.hqController : this.lqController, isHQ, force)
      }

      console.warn(e)
      return null
    }

    return frameObjectURL
  }

  /**
   * When a cached frame is invalidated, we remove it from the cachedFramesLimitedArray.
   * Invalidated frames are frames that are not loading correctly (possibly garbage collected).
   * @param index
   */
  public setFrameInvalid(index: number): void {
    this.cachedFramesLimitedArray.remove(index)
    this.cachedFramesMap.delete(index)
  }

  /**
   * Sets a frame on the cache. We use both a map and a limited array:
   * - The map is for easy rapid access.
   * - The LimitedArray is to track and manage first-in-first-out effectively.
   * The both point to the same underlying reference and no data is duplicated.
   */
  private setCachedFrame(index: number, image: string): void {
    let cachedFrame = this.cachedFramesMap.get(index)

    if (cachedFrame) {
      this.setExistingCachedFrameAsVisited(cachedFrame)

      cachedFrame = { index, image }
      this.cachedFramesMap.set(index, cachedFrame)

      return
    }

    cachedFrame = { index, image }

    // Add the number to the queue, see what is removed.
    const poppedItem = this.cachedFramesLimitedArray.push(cachedFrame)

    // If an item is popped, remove it from the cachedFramesMap and from memory
    if (poppedItem) {
      URL.revokeObjectURL(poppedItem.image)
      this.cachedFramesMap.delete(poppedItem.index)
    }

    // Set the value on the map.
    this.cachedFramesMap.set(index, cachedFrame)
  }

  /**
   * For a given cached frame, move it to the end of the cachedFramesLimitedArray.
   * This is so its cached frame if is visited, it isn't immediately discarded.
   */
  private setExistingCachedFrameAsVisited(cachedFrame: CachedFrame): void {
    const arrayIndexInLimitedArray = this.cachedFramesLimitedArray.findIndex(
      (cachedFrameItem) => cachedFrameItem.index === cachedFrame.index,
    )

    if (arrayIndexInLimitedArray === -1) {
      // Frame was invalid, so needs to be added again
      this.cachedFramesLimitedArray.push(cachedFrame)
      return
    }

    this.cachedFramesLimitedArray.remove(arrayIndexInLimitedArray)
    this.cachedFramesLimitedArray.push(cachedFrame)
  }

  private loadFrameByUrl(url: string): Promise<string> {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = url

    return new Promise((resolve, reject) => {
      img.onload = (): void => {
        const url = getImageURL(img)
        if (!url) {
          reject()
          return
        }

        resolve(url)
      }
    })
  }

  private getSectionByIndex(index: number): Promise<void> {
    this.waitForSection = true

    FramesLoaderEvents.getSection.emit({ index: index })

    let interval: number

    return new Promise((resolve) => {
      interval = self.setInterval(() => {
        if (this.waitForSection) {
          return
        }

        clearInterval(interval)
        resolve()
      }, 100)
    })
  }

  public cleanup(): void {
    this.loading.clear()
    this.framesToLoad.clear()
    this.slotSections.clear()
    this.hqLoadedIndexes.clear()
    this.currentFrameToLoad = 0
    for (const cachedFrame of this.cachedFramesMap.values()) {
      URL.revokeObjectURL(cachedFrame.image)
    }
    this.cachedFramesMap.clear()
    this.cachedFramesLimitedArray.clear()
    this.nextFrameToLoad = null
  }
}
