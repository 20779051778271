import { WindAuthAction } from '@/backend/darwin/WindAuthAction'
import type { ApiResult } from '@/backend/darwin/types'
import { errorMessages } from '@/backend/error'

import { withAuth, withoutAuth } from './api'
import type { TrainedModelPayload } from './types'

type Params = { teamId?: number; type?: string }

export const loadTrainedModels = (payload: Params): Promise<ApiResult<TrainedModelPayload[]>> => {
  const { teamId, type } = payload
  const params = type ? { type } : {}
  const path = 'trained_models'

  if (!teamId) {
    return withoutAuth<TrainedModelPayload[]>(
      (client) => client.get<TrainedModelPayload[]>(path, { params }),
      errorMessages.NEURAL_MODEL_DATA,
    )
  }

  const authParams = { action: WindAuthAction.ViewModels, teamId }

  return withAuth<TrainedModelPayload[]>(
    authParams,
    (client) => client.get<TrainedModelPayload[]>(path, { params }),
    errorMessages.NEURAL_MODEL_DATA,
  )
}
