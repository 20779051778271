import { HIGH_PRECISION_ZOOM_RANGE, SIMPLIFICATION_EPSILON_RANGE } from '@/modules/Editor/constants'

declare global {
  interface Window {
    darwin_epsilon?: number
  }
}

/**
 * Calculate the epsilon value based on the given zoom level.
 * There are 3 values to consider:
 * - When zoom is less than or equal the minimum, than we return loose precision (default is 0.5)
 * - When zoom is greater than or equal the maximum, than we return high precision
 * - Any value in between the minimum and maximum, will return an average value
 *
 * @param {number} zoomLevel - The zoom level for which to calculate the epsilon value.
 * @returns {number} The calculated epsilon value.
 */
export const getZoomEpsilon = (zoomLevel: number): number => {
  const [minZoomLevel, maxZoomLevel] = HIGH_PRECISION_ZOOM_RANGE
  const [minEpsilon, maxEpsilon] = SIMPLIFICATION_EPSILON_RANGE

  if (isNaN(zoomLevel) || zoomLevel <= minZoomLevel) {
    return maxEpsilon
  }
  if (zoomLevel >= maxZoomLevel) {
    return minEpsilon
  }
  return (maxEpsilon + minEpsilon) / 2
}

/**
 * A debugging function we are bringing along way from early days,
 * to allow us to override the epsilon value in prod
 * If global `darwin_epsilon` is not found, then it defaults to the highest supported epsilon value
 */
export const resolveEpsilon = (fallback: number = SIMPLIFICATION_EPSILON_RANGE[1]): number =>
  window.darwin_epsilon !== undefined ? window.darwin_epsilon : fallback

export const resolveRelativeEpsilon = (zoomLevel: number, fallback?: number): number =>
  zoomLevel && zoomLevel >= HIGH_PRECISION_ZOOM_RANGE[0]
    ? getZoomEpsilon(zoomLevel)
    : (fallback ?? resolveEpsilon())
