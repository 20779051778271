import type { BoundingBox } from '@/modules/Editor/AnnotationData'
import type { CompoundPath } from '@/modules/Editor/compoundPath'
import type { EditablePoint, IPoint } from '@/modules/Editor/point'
import { addPointMutate } from '@/modules/Editor/point'

export const isBoundingBox = (data: BoundingBox | object): data is BoundingBox => 'topLeft' in data

export const getPathFromBoundingBox = (boundingBox: BoundingBox | object): EditablePoint[] =>
  isBoundingBox(boundingBox)
    ? [boundingBox.topLeft, boundingBox.topRight, boundingBox.bottomRight, boundingBox.bottomLeft]
    : []

export const getCompoundPathFromBoundingBox = (
  boundingBox: BoundingBox | object,
): CompoundPath => ({
  path: getPathFromBoundingBox(boundingBox),
  additionalPaths: [],
})

export const getAllVerticesFromBoundingBox = (boundingBox: BoundingBox | object): EditablePoint[] =>
  getPathFromBoundingBox(boundingBox)

export const translateBoundingBox = (boundingBox: BoundingBox | object, offset: IPoint): void => {
  getPathFromBoundingBox(boundingBox).forEach((point) => addPointMutate(point, offset))
}

export const moveVertexOnBoundingBox = (
  boundingBox: BoundingBox | object,
  movingVertex: EditablePoint,
  offset: IPoint,
): void => {
  const path = getPathFromBoundingBox(boundingBox)
  let index: number | undefined
  for (let i = 0; i < path.length; i++) {
    if (path[i].x === movingVertex.x && path[i].y === movingVertex.y) {
      index = i
      break
    }
  }
  switch (index) {
    // Top Left -> Top Right and Bottom Left move
    case 0: {
      addPointMutate(path[0], offset)
      path[1].y += offset.y
      path[3].x += offset.x
      break
    }
    // Top Right -> Top Left and Bottom Right move
    case 1: {
      addPointMutate(path[1], offset)
      path[0].y += offset.y
      path[2].x += offset.x
      break
    }
    // Bottom Right -> Top Right and Bottom Left move
    case 2: {
      addPointMutate(path[2], offset)
      path[1].x += offset.x
      path[3].y += offset.y
      break
    }
    // Bottom Left -> Top Left and Bottom Right move
    case 3: {
      addPointMutate(path[3], offset)
      path[0].x += offset.x
      path[2].y += offset.y
      break
    }
  }
}
