import { ModelType, type FrameData, type SequenceData } from '@/core/annotations'
import { isVideoAnnotationDataPayload } from '@/modules/Editor/models/annotation/annotationKindValidator'
// eslint-disable-next-line boundaries/element-types
import type { V2AnnotationPayload } from '@/store/types'

/**
 * Returns true if the given frame data was generated by an auto-track model.
 */
export const isAutotrackFrame = (frameData?: Pick<FrameData, 'inference'>): boolean =>
  frameData?.inference?.model.type === ModelType.AUTO_TRACK

const frameInRange = (frameIndex: number, range?: [number, number]): boolean =>
  !range || (range[0] <= frameIndex && range[1] >= frameIndex)
/**
 * Deletes all keyframes that were generated by an auto-track model.
 * When a range is passed, then only the keyframes within that range will be deleted.
 */
export const deleteAutotrackKeyframes = (
  annotation: V2AnnotationPayload,
  range?: [number, number],
): {
  updatedAnnotation: V2AnnotationPayload
  updatedFramesIndices: number[]
} => {
  if (!isVideoAnnotationDataPayload(annotation.data)) {
    throw new Error('This action only supports video annotations')
  }

  // The indices of all auto-track frames
  // that will be deleted
  const indexesToBeDeleted: number[] = []

  // Frame data that will be kept after this action
  // is executed
  const frames: SequenceData['frames'] = {}

  Object.entries(annotation.data.frames).forEach(([frameIndex, frameData]) => {
    if (isAutotrackFrame(frameData) && frameInRange(Number(frameIndex), range)) {
      indexesToBeDeleted.push(Number(frameIndex))
      return
    }

    frames[frameIndex] = frameData
  })

  const updatedAnnotation: V2AnnotationPayload = {
    ...annotation,
    data: { ...annotation.data, frames },
  }

  return {
    updatedAnnotation,
    updatedFramesIndices: indexesToBeDeleted,
  }
}
