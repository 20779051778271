import { VideoViewEvents, type ViewEvent } from '@/modules/Editor/eventBus'
import { ToolName } from '@/modules/Editor/tools/types'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { ObjectHTML } from '@/modules/Editor/models/layers/HTMLLayer'
import { renderImageOnCanvas } from '@/modules/Editor/renderImageOnCanvas'
import { renderMeasureRegion } from '@/modules/Editor/renderMeasureRegion'
import { View } from '@/modules/Editor/views/view'

import { MainAnnotationType } from '@/core/annotationTypes'
import { loadImageData } from '@/modules/Editor/utils/loadImageData'
import { resolveTransformedImageData } from '@/modules/Editor/utils/resolveTransformedImageData'

import { ViewTypes, type ViewTypesType } from './viewTypes'

// To prevent annotations from jumping during the initialization
// we should compensate the default canvas height with the default timeline height
// HTML element is not available at the initialization moment to calculate it from there
// so we're using const
const DEFAULT_TIMELINE_HEIGHT = 203

export class VideoView extends View {
  private readonly videoViewEvent: ViewEvent = {
    viewId: this.id,
    slotName: this.fileManager.slotName,
  }
  readonly type: ViewTypesType = ViewTypes.VIDEO

  protected videoInterval: number | null = null

  private _isPlaying: boolean = false
  public get isPlaying(): boolean {
    return this._isPlaying
  }
  protected set isPlaying(val: boolean) {
    this._isPlaying = val
    VideoViewEvents.playStateChanged.emit(this.videoViewEvent, val)
  }

  private resolveCurrentFrameData(): void {
    if (!this.currentFrame) {
      return
    }

    this.currentFrame.rawData = this.currentFrame.rawData || loadImageData(this.currentFrame.data)
    if (!this.currentFrame.rawData) {
      throw new Error('Failed to load image data')
    }

    const resolved = resolveTransformedImageData(
      this.currentFrame.lastWindowLevels,
      this.imageFilter.windowLevels,
      this.currentFrame.rawData,
      this.currentFrame.data,
    )

    if (resolved) {
      this.currentFrame.transformedData = resolved
    }
  }

  private renderCurrentFrame(canvas: HTMLCanvasElement): void {
    if (this.currentFrame?.transformedData) {
      renderImageOnCanvas(
        canvas,
        this.currentFrame.data,
        this.currentFrame.transformedData,
        this.camera,
        this.imageFilter,
      )
    }

    if (this.fileManager.isFrameLoaded(this.currentFrameIndex)) {
      this.annotationsAndFrameSyncManager.frameRenderedForKey(
        `${this.name}_${this.currentFrameIndex}`,
      )
    }

    if (this.editor.renderMeasures) {
      renderMeasureRegion(this)
    }
  }

  async init(): Promise<void> {
    this.initState()

    this.mainLayer.clear()
    this.mainLayer.onBeforeRender(() => this.resolveCurrentFrameData())
    this.mainLayer.onRender((ctx, canvas) => this.renderCurrentFrame(canvas))

    await this.jumpToFrame(this.currentFrameIndex)

    this.scaleToFit()
  }

  protected initState(): void {
    if (this.fileManager.imageWidth && this.fileManager.imageHeight && !this.isMainLayerVtk) {
      this.camera.setImage({
        width: this.fileManager.imageWidth,
        height: this.fileManager.imageHeight,
      })
    }

    if (this.mainLayer.canvas) {
      this.updateCameraDimensions(
        this.mainLayer.canvas.clientWidth,
        this.mainLayer.canvas.clientHeight - (this.showFramesTool ? DEFAULT_TIMELINE_HEIGHT : 0),
      )
    }

    const shouldUseFrameManifestForFrameExtraction =
      !this.editor.featureFlags.FRAMES_MANIFEST_WITH_FRAMES_EXTRACTION &&
      !!this.fileManager.file?.metadata?.frames_manifests?.length

    const shouldLoadFrames =
      !this.isMainLayerVtk &&
      (!this.fileManager.isSectionless || !shouldUseFrameManifestForFrameExtraction)

    if (shouldLoadFrames) {
      this.fileManager.loadFrames()
    }

    this.isPlaying = false
    this.framesIndexes = this.fileManager.framesIndexes
    this.showFramesTool = this.framesIndexes.length > 1

    this.mainLayer.clear()

    // Reset the current image filter's window level
    this.setImageFilter({
      ...this.imageFilter,
      windowLevels: this.defaultWindowLevels,
    })

    // Reset tool
    const { currentTool } = this.toolManager
    if (currentTool) {
      currentTool.tool.reset(currentTool.context)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setPreviewFrameIndex(index: number): void {
    // We need this method for StreamView. But for some reason when you import StreamView to the Editor file.
    // it throws an error when you run unit tests.
    // So I'm just going to leave this empty for now.
  }

  public clearPreviewFrameIndex(): void {
    // We need this method for StreamView. But for some reason when you import StreamView to the Editor file.
    // it throws an error when you run unit tests.
    // So I'm just going to leave this empty for now.
  }

  play(): void {
    if (this.isPlaying) {
      return
    }
    const length = this.framesIndexes.length

    this.videoInterval = window.setInterval(() => {
      if (!this.fileManager.isFrameLoaded(this.currentFrameIndex)) {
        return
      }
      if (this.annotationsAndFrameSyncManager.shouldPausePlayback) {
        this.isPlaying = false
        return
      }
      if (!this.isPlaying) {
        this.isPlaying = true
      }

      const nextFrameIndex = (this.currentFrameIndex + 1) % length
      this.lqJumpToFrame(nextFrameIndex)
    }, 1000 / this.fileManager.fps)
  }

  async pause(): Promise<void> {
    if (!this.isPlaying) {
      return
    }
    this.videoInterval && window.clearInterval(this.videoInterval)
    this.isPlaying = false

    await this.jumpToFrame(this.currentFrameIndex)
  }

  async togglePlayPause(): Promise<void> {
    if (this.isPlaying) {
      await this.pause()
      return
    }

    this.play()
  }

  protected setAnnotations(): void {
    this.annotationsLayer.setKeyForNextRender(null)

    this.annotationsOverlayLayer.clear()

    this.annotationManager.annotations.forEach((annotation) => {
      if (
        !this.annotationManager.isHidden(annotation.id) &&
        annotation.type === MainAnnotationType.Mask
      ) {
        this.addRasterLayerAnnotation(annotation)
      }
    })

    if (this.editor.featureFlags.ANNOTATIONS_PACKAGE) {
      if (!this.annotationManager.annotationsParsedData) {
        return
      }

      this.annotationsLayer.setKeyForNextRender(`${this.name}_${this.currentFrameIndex}`)
      const dataPayload = this.annotationManager.annotationsParsedData

      if (!dataPayload.rTreeItems.length) {
        // We will not render the annotations for the current frame if there are no annotations
        // so we can mark it as rendered
        this.annotationsAndFrameSyncManager.annotationsRenderedForKey(
          `${this.name}_${this.currentFrameIndex}`,
        )
        this.annotationsLayer.clear()
        return
      }

      this.annotationsLayer.setKeyForNextRender(`${this.name}_${this.currentFrameIndex}`)
      this.annotationsLayer.replaceAllWithParsedData({
        itemsBBoxMap: new Map(dataPayload.itemsBBoxMap),
        rTreeItems: [...dataPayload.rTreeItems],
        itemsMap: new Map(dataPayload.itemsMap),
        zIndexesList: [...dataPayload.zIndexesList],
      })
    } else {
      const res = this.annotationManager.getRenderableAnnotations(this.currentFrameIndex)

      if (!res.length) {
        // We will not render the annotations for the current frame if there are no annotations
        // so we can mark it as rendered
        this.annotationsAndFrameSyncManager.annotationsRenderedForKey(
          `${this.name}_${this.currentFrameIndex}`,
        )
        this.annotationsLayer.clear()
        return
      }

      this.annotationsLayer.setKeyForNextRender(`${this.name}_${this.currentFrameIndex}`)
      this.annotationsLayer.replaceAll(res)
    }

    if (this.annotationManager.selectedAnnotation) {
      this.annotationsLayer.activate(this.annotationManager.selectedAnnotation.id, {
        isSelected: true,
      })

      if (this.annotationManager.selectedVertexIndex !== null) {
        this.annotationsLayer.activateVertexWithState(
          this.annotationManager.selectedAnnotation.id,
          this.annotationManager.selectedVertexIndex,
          { isSelected: true },
        )
      }
    }
  }

  protected createAnnotationOverlay(annotationId: Annotation['id']): ObjectHTML {
    return new ObjectHTML(`overlay_${annotationId}`, () => {
      if (
        this.toolManager?.currentTool?.name === ToolName.Brush &&
        this.annotationManager.selectedAnnotation?.id === annotationId
      ) {
        return
      }

      const annotation = this.annotationManager.getAnnotation(annotationId)
      if (!annotation) {
        this.annotationsOverlayLayer.delete(annotationId)
        return
      }

      if (this.annotationManager.isHidden(annotation.id)) {
        return
      }
      if (!this.isInViewport(annotation)) {
        return
      }

      return undefined
    })
  }

  cleanup(): void {
    super.cleanup()

    this.videoInterval && window.clearInterval(this.videoInterval)
    this.framesIndexes.length = 0
    this.isPlaying = false
  }
}
