import { isErrorResponse } from '@/backend/error/types'
import { Socket, type Channel } from '@/backend/socket'
import { defineComposable } from '@/core/utils/defineComposable'
import { setContext } from '@/services/sentry'
import type { NotificationMessagePayload } from '@/store/types/NotificationMessagePayload'
import { useToast } from '@/uiKit/Toast/useToast'

import { useNotificationsStore } from './useNotificationsStore'
import { useNotificationsChannelStore } from './useNotificationsChannelStore'

export const useNotificationsChannel = defineComposable(() => {
  const notificationStore = useNotificationsStore()
  const channelStore = useNotificationsChannelStore()
  const toast = useToast()

  const getTopicName = (userId: number): string => `notifications:${userId}`

  const getChannel = (topic: string): Promise<{ channel: Channel }> => Socket.connectAndJoin(topic)

  const join = async (userId: number): Promise<void> => {
    let response: { channel: Channel }

    try {
      const topic = getTopicName(userId)
      response = await getChannel(topic)
      channelStore.topic = topic
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }

      toast.warning({
        meta: { title: "We couldn't fetch your notifications. Try refreshing your browser." },
      })

      return
    }

    const { channel } = response

    if (channelStore.socketRef) {
      channel.off('notifications', channelStore.socketRef)
      channelStore.socketRef = undefined
    }

    const newSocketRef = channel.on('notifications', (payload: NotificationMessagePayload) => {
      const {
        notifications,
        deleted_notifications: deletedNotifications,
        by_team_unread_count: byTeamUnreadCount,
      } = payload

      if (notifications) {
        notificationStore.addNotifications(notifications)
      }

      if (deletedNotifications) {
        notificationStore.deleteNotifications(deletedNotifications)
      }

      if (byTeamUnreadCount) {
        notificationStore.setByTeamUreadCount(byTeamUnreadCount)
      }
    })

    channelStore.socketRef = newSocketRef
  }

  const leave = async (): Promise<void> => {
    if (!channelStore.topic) {
      return
    }
    await Socket.leave(channelStore.topic)
    channelStore.topic = undefined
    channelStore.socketRef = undefined
    notificationStore.clearNotifications()
  }

  const loadMoreNotifications = async (): Promise<void> => {
    if (!channelStore.topic) {
      return
    }

    const sortedRead = notificationStore.sortedNotifications.filter((n) => n.is_read)
    const oldestRead = sortedRead[sortedRead.length - 1]
    const sortedUnread = notificationStore.sortedNotifications.filter((n) => !n.is_read)
    const oldestUnread = sortedUnread[sortedUnread.length - 1]
    const payload = {
      before_read_id: oldestRead ? oldestRead.id : null,
      before_unread_id: oldestUnread ? oldestUnread.id : null,
    }

    if (!oldestRead && !oldestUnread) {
      return
    }

    try {
      const { channel } = await getChannel(channelStore.topic)
      channel.push('notifications', payload)
    } catch (e) {
      setContext('error', { error: e })
    }
  }

  const markNotificationRead = async (id: number): Promise<void> => {
    if (!channelStore.topic) {
      return
    }
    try {
      const { channel } = await getChannel(channelStore.topic)
      channel.push('notifications:read', { id: id })
      notificationStore.markRead(id)
    } catch (e) {
      setContext('channelErrorDetails', { error: e })
    }
  }

  return { join, leave, loadMoreNotifications, markNotificationRead }
})
