import cloneDeep from 'lodash/cloneDeep'

import type { AnnotationData } from '@/modules/Editor/AnnotationData'
import { euclideanDistance } from '@/modules/Editor/algebra'
import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import { ToolEvents } from '@/modules/Editor/eventBus'
import type { EditablePoint, IPoint } from '@/modules/Editor/point'
import { subPoints } from '@/modules/Editor/point'
import { ToolName, type ToolOption, type ToolOptionId } from '@/modules/Editor/tools/types'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import { restoreAllJointsAction, updateAnnotationData } from '@/modules/Editor/actions'
import type { SharedToolState, Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { clearPath2DCache } from '@/modules/Editor/models/annotation/annotationRenderingCache'
import translateEyeVertex from '@/modules/Editor/plugins/eye/utils/translateVertex'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import { setupPanning } from '@/modules/Editor/plugins/mixins/setupPanning'
import { setupToolMouseCallbacks } from '@/modules/Editor/plugins/mixins/setupToolMouseCallbacks'

import { handlePolygonMerge, handlePolygonSubtract, translatePath, translateVertex } from './utils'

type EditToolAfterMove = EditTool & {
  annotationMoving: Annotation
  annotationData: AnnotationData
  initialMouseDownPosition: IPoint
  previousMouseMovePosition: IPoint
}

class EditTool implements Tool {
  /**
   * Gives panning tool the way to define when it's active
   *
   * For active panning, we should disable annotation detection
   * under the cursor for performance purposes
   */
  sharedState: SharedToolState = {
    isPanning: false,
  }

  /**
   * Last known position of the mouse (on image)
   *
   * Constantly updates as mouse moves.
   */
  previousMouseMovePosition?: IPoint

  /**
   * Initial position of the mouse on mouse down (on image)
   *
   * Is set on mouse down and does not update as mouse moves.
   */
  initialMouseDownPosition?: IPoint

  /**
   * Holds reference to the annotation currently being moved (either as a whole, or by vertex)
   */
  annotationMoving?: Annotation

  /**
   * Copy of annotation data before movement started
   */
  initialAnnotationData?: AnnotationData

  /**
   * Holds reference to the vertex currently being moved
   */
  vertexMoving?: EditablePoint

  targetedItemId: string | undefined = undefined
  targetedItemsIndex: number | undefined = undefined

  /**
   * Return an active tool option of current tool
   */
  activeToolOption(context: ToolContext): ToolOption | null {
    const { currentTool: tool } = context.editor.toolManager
    if (!tool || tool.name !== 'edit_tool') {
      return null
    }

    const activeOption = (tool.toolConfig.toolOptions || []).find((option) => option.active)
    if (!activeOption) {
      return null
    }

    return activeOption
  }

  deactivateToolOption(context: ToolContext, toolOptionId: ToolOptionId): void {
    ToolEvents.deactivateToolOption.emit({
      toolName: ToolName.Edit,
      lastHotkeyPressed: null,
      editing: false,
    })
    context.editor.toolManager.deactivateToolOption(toolOptionId)
  }

  deactivateToolOptions(context: ToolContext): void {
    ToolEvents.deactivateToolOptions.emit({
      toolName: ToolName.Edit,
      lastHotkeyPressed: null,
      editing: false,
    })
    context.editor.toolManager.deactivateToolOptions()
  }

  activate(context: ToolContext): void {
    context.editor.activeView.annotationsLayer.draw()

    setupMouseButtonLoadout(context, { middle: true })

    context.editor.registerCommand('edit_tool.restore_joints', () => {
      const { selectedAnnotation } = context.editor.activeView.annotationManager
      if (!selectedAnnotation) {
        return
      }
      if (!['skeleton', 'eye'].includes(selectedAnnotation.type)) {
        return
      }

      const action = restoreAllJointsAction(context.editor.activeView, selectedAnnotation)
      context.editor.actionManager.do(action)

      return CallbackStatus.Stop
    })

    context.editor.registerCommand('edit_tool.activate_polygon_merge', () => {
      if (context.editor.activeView.isLoading) {
        return
      }
      context.editor.toolManager.activateToolOption('polygon_merge')
      const { selectedAnnotation } = context.editor.activeView.annotationManager
      if (selectedAnnotation?.type === 'polygon') {
        ToolEvents.polygonMerge.emit({
          toolName: ToolName.Edit,
          lastHotkeyPressed: null,
          editing: false,
        })
      }
    })

    context.editor.registerCommand('edit_tool.activate_polygon_subtract', () => {
      if (context.editor.activeView.isLoading) {
        return
      }
      context.editor.toolManager.activateToolOption('polygon_subtract')
      const { selectedAnnotation } = context.editor.activeView.annotationManager
      if (selectedAnnotation?.type === 'polygon') {
        ToolEvents.polygonSubtract.emit({
          toolName: ToolName.Edit,
          lastHotkeyPressed: null,
          editing: false,
        })
      }
    })

    setupToolMouseCallbacks(
      context,
      this.onStart.bind(this),
      this.onMove.bind(this),
      this.onEnd.bind(this),
    )

    // goes at the end, since the tool can also be used to drag an annotation or a vertex
    // putting it at the end mans the tool hadlers fire off first
    setupPanning.primary(context, this.sharedState)
  }

  onStart(context: ToolContext, event: PointerEvent): void | CallbackStatus {
    const canvasPoint = resolveEventPoint(event)
    if (!canvasPoint) {
      return
    }

    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(canvasPoint)

    context.editor.activeView.annotationsLayer.hitItemRegion(imagePoint).then((res) => {
      this.targetedItemId = res
    })
    if (!this.targetedItemId) {
      context.editor.activeView.annotationManager.cachedDeselectAllAnnotations()
      this.deactivateToolOptions(context)
      return
    }

    const annotation = context.editor.activeView.annotationManager.getAnnotation(
      this.targetedItemId,
    )

    this.initialMouseDownPosition = imagePoint
    this.previousMouseMovePosition = imagePoint

    if (!annotation) {
      context.editor.activeView.annotationManager.cachedDeselectAllAnnotations()
      this.deactivateToolOptions(context)
      return
    }

    const isSelected = context.editor.activeView.annotationManager.isSelected(annotation.id)
    if (isSelected) {
      this.deactivateToolOptions(context)

      this.annotationMoving = context.editor.activeView.annotationManager.getAnnotation(
        annotation.id,
      )

      if (this.annotationMoving) {
        this.initialAnnotationData = cloneDeep(this.annotationMoving.data)
      }

      context.editor.activeView.annotationManager.deselectVertex()

      const vertex = context.editor.activeView.annotationManager.findAnnotationVertexAt(imagePoint)
      if (vertex) {
        if (this.targetedItemId && this.targetedItemsIndex !== undefined) {
          context.editor.activeView.annotationManager.selectVertexIndex(this.targetedItemsIndex)
        }

        this.vertexMoving = vertex
      }
    } else {
      const { selectedAnnotation } = context.editor.activeView.annotationManager
      const activeToolOption = this.activeToolOption(context)
      if (
        activeToolOption &&
        annotation.type === 'polygon' &&
        selectedAnnotation?.type === 'polygon'
      ) {
        if (activeToolOption.id === 'polygon_merge') {
          handlePolygonMerge(selectedAnnotation, annotation, context)
          this.deactivateToolOption(context, 'polygon_merge')
          return CallbackStatus.Stop
        }

        if (activeToolOption.id === 'polygon_subtract') {
          handlePolygonSubtract(selectedAnnotation, annotation, context)
          this.deactivateToolOption(context, 'polygon_subtract')

          context.editor.activeView.annotationManager.selectAnnotation(selectedAnnotation.id)

          return CallbackStatus.Stop
        }
      }
      this.deactivateToolOptions(context)

      context.editor.activeView.annotationManager.selectAnnotation(annotation.id)
    }

    selectCursor(EditorCursor.Edit)
    return CallbackStatus.Stop
  }

  onMove(context: ToolContext, event: PointerEvent): void | CallbackStatus {
    // Continue status cause we have panning declaration tool an the end
    if (this.sharedState.isPanning) {
      return CallbackStatus.Continue
    }
    if (!context.editor.activeView.hitTarget(event)) {
      return CallbackStatus.Stop
    }

    const canvasPoint = resolveEventPoint(event)
    if (!canvasPoint) {
      return
    }

    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(canvasPoint)
    const { annotationMoving, previousMouseMovePosition, vertexMoving } = this

    if (previousMouseMovePosition && vertexMoving) {
      // already moving vertex
      if (annotationMoving?.type === 'eye' && this.targetedItemsIndex !== undefined) {
        translateEyeVertex(
          context.editor,
          this.targetedItemsIndex,
          subPoints(imagePoint, previousMouseMovePosition),
          event,
        )
      } else {
        translateVertex(
          context.editor,
          vertexMoving,
          subPoints(imagePoint, previousMouseMovePosition),
          this,
          event,
        )
      }
      if (annotationMoving) {
        clearPath2DCache(annotationMoving.id)
        annotationMoving.centroid = undefined
      }
    } else if (previousMouseMovePosition && annotationMoving) {
      // already moving annotation
      selectCursor(EditorCursor.Move)
      translatePath(
        context.editor,
        annotationMoving,
        subPoints(imagePoint, previousMouseMovePosition),
      )

      clearPath2DCache(annotationMoving.id)

      annotationMoving.centroid = undefined
    } else {
      context.editor.activeView.annotationsLayer.hitItemRegion(imagePoint).then((res) => {
        this.targetedItemId = res
      })
      if (!this.targetedItemId) {
        selectCursor(EditorCursor.Move)
        context.editor.activeView.annotationManager.cachedUnhighlightAllAnnotations()
        return
      }

      let annotation = context.editor.activeView.annotationManager.getAnnotation(
        this.targetedItemId,
      )

      if (this.targetedItemId) {
        const itemId = this.targetedItemId

        if (context.editor.activeView.annotationManager.isLocked(itemId)) {
          context.editor.activeView.annotationsLayer.updateItemState(itemId, { isLocked: true })
          return
        }

        this.targetedItemsIndex = context.editor.activeView.annotationsLayer.hitVertexRegion(
          itemId,
          imagePoint,
        )

        if (this.targetedItemId && this.targetedItemsIndex !== undefined) {
          context.editor.activeView.annotationManager.highlightVertexIndex(this.targetedItemsIndex)
        } else {
          context.editor.activeView.annotationManager.unhighlightVertex()
        }
      }

      const annotationWithVertex =
        context.editor.activeView.annotationManager.findAnnotationWithVertexAt(imagePoint)
      const vertex = annotationWithVertex?.vertex

      if (vertex) {
        // on vertex hover set annotation as well
        annotation = annotationWithVertex.annotation
      }

      if (annotation || vertex) {
        selectCursor(EditorCursor.Edit)
      }

      if (vertex) {
        if (!vertex.isHighlighted) {
          vertex.isHighlighted = true
          // Highlights annotation as well as its vertex
          if (
            annotation &&
            !context.editor.activeView.annotationManager.isHighlighted(annotation.id)
          ) {
            context.editor.activeView.annotationManager.highlightAnnotation(annotation.id)
          }
        }
      } else if (annotation) {
        if (!context.editor.activeView.annotationManager.isHighlighted(annotation.id)) {
          context.editor.activeView.annotationManager.highlightAnnotation(annotation.id)
        }
      } else {
        if (context.editor.activeView.annotationManager.highlightedAnnotations.length > 0) {
          context.editor.activeView.annotationManager.cachedUnhighlightAllAnnotations()
        }
        selectCursor(EditorCursor.Move)
      }
    }

    this.previousMouseMovePosition = imagePoint

    const { selectedAnnotation } = context.editor.activeView.annotationManager
    if (selectedAnnotation) {
      context.editor.activeView.updateRenderedAnnotation(selectedAnnotation.id)
    }
  }

  onEnd(context: ToolContext): void {
    if (this.didMove()) {
      this.handleMoveEnd(context)
    }
    this.reset(context)
  }

  /**
   * Type guard to ensure the edit tool instance has all the required
   * props to handle dragging of an annotation
   */
  didMove(): this is EditToolAfterMove {
    return !!this.annotationMoving && this.getCurrentDistanceMoved() > 0
  }

  /**
   * Handle the end of moving a vertex or annotation
   */
  handleMoveEnd(context: ToolContext): void {
    if (!this.didMove()) {
      return
    }
    const { annotationMoving, initialAnnotationData } = this
    if (!initialAnnotationData) {
      return
    }

    const action = updateAnnotationData(
      context.editor.activeView,
      annotationMoving,
      initialAnnotationData,
      annotationMoving.data,
    )
    context.editor.actionManager.do(action)
  }

  /**
   * Returns total distance moved within the current mouse down -> move -> up sequence
   */
  getCurrentDistanceMoved(): number {
    const { previousMouseMovePosition, initialMouseDownPosition } = this
    if (!initialMouseDownPosition || !previousMouseMovePosition) {
      return 0
    }
    return euclideanDistance(initialMouseDownPosition, previousMouseMovePosition)
  }

  deactivate(context: ToolContext): void {
    this.deactivateToolOptions(context)
  }

  /**
   * Reset state on mouse up, so the next mouse down -> move -> up cycle can be handled
   */
  reset(context: ToolContext): void {
    this.previousMouseMovePosition = undefined
    this.initialMouseDownPosition = undefined
    this.annotationMoving = undefined
    this.initialAnnotationData = undefined
    this.vertexMoving = undefined
    this.deactivateToolOptions(context)
  }
}

export const editTool = new EditTool()
