import { EditorEvents } from '@/modules/Editor/eventBus'
import type { IPoint } from '@/modules/Editor/point'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'

/**
 * Returns the given path if it's not undefined, and contains points.
 * @param context the tool context
 * @param path a list of { x, y } points
 */
export const validatePath = (context: ToolContext, path?: IPoint[]): IPoint[] | undefined => {
  if (!path || path.length === 0) {
    EditorEvents.message.emit({ content: 'Nothing found in this region', level: 'info' })
    return
  }
  return path
}
