export const isFrameInHiddenRange = (frameIdx: number, hiddenAreas: [number, number][]): boolean =>
  hiddenAreas.some(([start, end]) => start <= frameIdx && frameIdx < end)

export const isAnnotationOutOfView = (
  hiddenAreas: [number, number][] | undefined,
  frameIndex: number,
): boolean => {
  if (!hiddenAreas) {
    return false
  }

  return isFrameInHiddenRange(frameIndex, hiddenAreas)
}

/**
 * Retrieve the next hidden area, respective to the passed frame idx.
 * Returns by default [0, totalFrames] if no hidden area is available.
 */
export const getNextHiddenArea = (
  value: [number, number][] | undefined,
  frameIdx: number | undefined,
  totalFrames: number | undefined,
): [number, number] => {
  let result: [number, number] = [0, totalFrames || 0]

  if (value === undefined || frameIdx === undefined) {
    return result
  }

  value.forEach(([start, end]) => {
    if (frameIdx < start && result[0] === 0) {
      result = [start, end]
    }
  })

  return result
}

/**
 * Retrieve the next area turning point, respective to the passed frame idx.
 * Returns by default totalFrames if no hidden area is available.
 */
export const getNextAreaTurningPoint = (
  value: [number, number][] | undefined,
  frameIdx: number | undefined,
  lastFrame: number,
): number => {
  let result: number | undefined

  if (value === undefined || frameIdx === undefined) {
    return lastFrame
  }

  value.forEach(([start, end], idx) => {
    if (result !== undefined) {
      return
    }

    const isIndexBeforeCurrentArea = frameIdx < start
    if (isIndexBeforeCurrentArea) {
      result = start
      return
    }

    const isIndexWithinCurrentArea = frameIdx >= start && frameIdx < end
    if (isIndexWithinCurrentArea) {
      result = end
      return
    }

    const nextAreaStart = value[idx + 1]?.[0]
    const isIndexWithinCurrentAndNextArea = frameIdx >= end && frameIdx < nextAreaStart
    if (isIndexWithinCurrentAndNextArea) {
      result = nextAreaStart
    }
  })

  return result ?? lastFrame
}

/**
 * Toggles a single frame OOV state
 */
const updateSingleFrame = (
  value: [number, number][] | undefined,
  frameIdx: number,
  totalFrames: number,
): [number, number][] => {
  const result: [number, number][] = []

  // Annotation has no hidden_areas yet
  if (value === undefined || value.length === 0) {
    return [[frameIdx, frameIdx + 1]]
  }

  let alreadyCreatedNewArea = false
  value.forEach(([start, end], idx) => {
    // If the frameIdx is contained within the current area
    const isIndexWithinCurrentArea = frameIdx >= start && frameIdx < end
    if (isIndexWithinCurrentArea) {
      // If current area is 1 frame long we just remove it
      // othewrise we split it in two areas
      if (end - start === 1) {
        return
      }

      // If we're toggling the area start frame left reduce the area
      if (frameIdx === start) {
        result.push([frameIdx + 1, end])
        return
      }

      // If we're toggling the area end frame right reduce the area
      if (frameIdx === end - 1) {
        result.push([start, frameIdx])
        return
      }

      result.push([start, frameIdx])
      result.push([frameIdx + 1, end])
      return
    }

    const shouldMergeWithPreviousArea =
      result[result.length - 1] && result[result.length - 1][1] === start
    if (shouldMergeWithPreviousArea) {
      result[result.length - 1] = [result[result.length - 1][0], end]
      return
    }

    const wouldBeLeftContiguous = frameIdx === end
    if (wouldBeLeftContiguous) {
      // Override current area effectively right extending it
      result.push([start, frameIdx + 1])
      return
    }

    const wouldBeRightContiguous = frameIdx === start - 1
    if (wouldBeRightContiguous) {
      // Override current area effectively left extending it
      result.push([start - 1, end])
      return
    }

    // no prev area exists before frameIdx
    const prevAreaEnd = value[idx - 1] ? value[idx - 1][1] : 0
    const shouldAddBeforeCurrentArea =
      !alreadyCreatedNewArea && frameIdx > prevAreaEnd && frameIdx < start
    if (shouldAddBeforeCurrentArea) {
      result.push([frameIdx, frameIdx + 1])
      alreadyCreatedNewArea = true
    }

    result.push([start, end])

    const nextAreaStart = value[idx + 1] ? value[idx + 1][0] : totalFrames
    const shouldAddAfterCurrentArea =
      !alreadyCreatedNewArea && frameIdx > end && frameIdx < nextAreaStart
    if (shouldAddAfterCurrentArea) {
      result.push([frameIdx, frameIdx + 1])
      alreadyCreatedNewArea = true
    }
  })

  return result
}

/**
 * Toggles frame OOV state from frameIdx, up to the next
 * view section, mimicking how keyframes get interpolated
 */
const updateInterpolatingFrames = (
  value: [number, number][] | undefined,
  segments: [number, number | null][] | undefined,
  frameIdx: number,
  totalFrames: number,
): [number, number][] => {
  const result: [number, number][] = []

  if (segments === undefined) {
    return result
  }

  const startSegment: number = segments[0][0] || 0
  const endSegment: number = segments[0][1] || totalFrames

  // Annotation has no hidden_areas yet
  if (value === undefined || value.length === 0) {
    return [[frameIdx, endSegment]]
  }

  // We only allow for one left extension to avoid
  // trying to merge 3+ areas
  let haveAlreadyLeftExtended = false
  value.forEach(([start, end], idx) => {
    const prevArea = value[idx - 1] ? { start: value[idx - 1][0], end: value[idx - 1][1] } : null
    const nextArea = value[idx + 1] ? { start: value[idx + 1][0], end: value[idx + 1][1] } : null
    const canLeftExtendCurrentArea =
      frameIdx < start && (!prevArea?.end || frameIdx >= prevArea?.end) && !haveAlreadyLeftExtended

    // Left extend the current area
    if (canLeftExtendCurrentArea) {
      const wouldBeContiguousToPrevArea = frameIdx === prevArea?.end
      if (wouldBeContiguousToPrevArea) {
        // Override the previous area effectively right extending it
        result[result.length - 1] = [prevArea?.start || startSegment, end]
      } else {
        result.push([frameIdx, end])
      }

      haveAlreadyLeftExtended = true
      return
    }

    // If the frameIdx equals the current area start
    // we discard that area entirely
    const isIndexEqualToAreaStart = frameIdx === start
    if (isIndexEqualToAreaStart) {
      return
    }

    // If the frameIdx is contained within the current
    // area then we update the area end and eventually
    // restore the previous merged area
    const isIndexWithinCurrentArea = frameIdx > start && frameIdx < end
    if (isIndexWithinCurrentArea) {
      result.push([start, frameIdx])
      return
    }

    // Add the current area to the result, it needs to happend before
    // the next checks to guarantee the new hidden areas will be sorted
    result.push([start, end])

    // If we're in between 2 hidden areas return as
    // we will rely on the next area being left extended
    const isFrameBetweenTwoAreas = nextArea && frameIdx < nextArea?.start
    if (isFrameBetweenTwoAreas) {
      return
    }

    // If no hidden areas exists after the current frameIdx
    const shouldAddAreaAfterCurrent = !nextArea && frameIdx > start && frameIdx < endSegment
    if (shouldAddAreaAfterCurrent) {
      const wouldBeContiguousToCurrentArea = frameIdx === end
      if (wouldBeContiguousToCurrentArea) {
        // Override the current area effectively right extending it
        result[result.length - 1] = [start, endSegment]
        return
      }
      result.push([frameIdx, endSegment])
    }
  })

  return result
}

/**
 * Returns an updated ordered list of non-overlapping and non-negative
 * itergers, represeting start (inclusive) and ed (esclusive)
 * frame indices of hidden areas.
 *
 * If the singleFrame arg is true we will oly toggle the OOV state on
 * frameIdx, otherwise the toggle will be propagated up to the next
 * OOV turning point (start of another in view/out of view area).
 */
export const updatedHiddenAreas = (
  value: [number, number][] | undefined,
  segments: [number, number | null][] | undefined,
  frameIdx: number,
  totalFrames: number,
  singleFrame: boolean,
): [number, number][] =>
  singleFrame
    ? updateSingleFrame(value, frameIdx, totalFrames)
    : updateInterpolatingFrames(value, segments, frameIdx, totalFrames)

/**
 * Updates the hidden areas based on the given range and total number of frames.
 * If there are no existing hidden areas, the range is returned as the updated hidden areas.
 * If there are existing hidden areas, they are updated based on the range,
 * including adding new hidden areas or merging adjacent ones.
 */
export const updatedHiddenAreasFromRange = (
  hiddenAreas: [number, number][] | undefined,
  frameIndexRange: [number, number],
  totalFrames: number,
): [number, number][] => {
  let [rangeStart, rangeEnd] = frameIndexRange
  // No existing hidden areas, return range
  if (!hiddenAreas) {
    return [[rangeStart, rangeEnd || totalFrames]]
  }

  let result: [number, number][] = []
  hiddenAreas.forEach(([hiddenAreaStart, hiddenAreaEnd]) => {
    // the hidden area is outside the update range, add it to the list
    // __[hidden area]_____________
    // __________________[range]___
    if (hiddenAreaEnd < rangeStart - 1 || hiddenAreaStart > rangeEnd + 1) {
      result.push([hiddenAreaStart, hiddenAreaEnd])
      return
    }
    // hideen area is sequential to the start of the range
    // ___[hidden area]_________
    // _______________[range]___
    if (hiddenAreaEnd === rangeStart - 1) {
      rangeStart = hiddenAreaStart
      return
    }
    // hideen area is sequential to the end of the range
    // _________[hidden area]___
    // ___[range]_______________
    if (hiddenAreaStart === rangeEnd + 1) {
      rangeEnd = hiddenAreaEnd
      return
    }
    // hidden area is inside the update range, skip
    // ______[hidden area]______
    // ___[      range      ]___
    if (hiddenAreaStart > rangeStart && hiddenAreaEnd < rangeEnd) {
      return
    }
    // range is matching or included in hidden area. This should invert the range selection
    // ___[   hidden area   ]___
    // _____[    range    ]_____
    if (hiddenAreaStart <= rangeStart && hiddenAreaEnd >= rangeEnd) {
      // push only diff between hidden area and range
      if (rangeStart - hiddenAreaStart > 0) {
        result.push([hiddenAreaStart, rangeStart])
      }
      if (hiddenAreaEnd - rangeEnd > 0) {
        result.push([rangeEnd, hiddenAreaEnd])
      }
      rangeStart = -1
      rangeEnd = -1
      return
    }
    // intersection: hidden area intersect at start, update the range and skip
    // ___[hidden area]________
    // ________[   range   ]___
    if (hiddenAreaStart < rangeStart) {
      rangeStart = hiddenAreaStart
      return
    }
    // intersection: hidden area intersect at end, update the range and skip
    // ___________[hidden area]__
    // ______[   range   ]_______
    if (hiddenAreaEnd > rangeEnd) {
      rangeEnd = hiddenAreaEnd
      return
    }
  })
  if (rangeStart >= 0 && rangeEnd > 0) {
    result.push([rangeStart, rangeEnd])
  }
  result = result.sort((a, b) => a[0] - b[0])
  return result
}
