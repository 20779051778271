import type { ExtendedFileMetadata } from '@/modules/Editor/metadata'
import type { VoxelRasterDimensions } from '@/modules/Editor/models/raster/VoxelRaster'

import { getXYVoxelScaling } from './getXYVoxelScaling'

export const scaleReformattedPixelPath = (
  tipPath: number[][],
  slotName: string,
  metadata: ExtendedFileMetadata,
  voxelRasterDimensions?: VoxelRasterDimensions,
): number[][] => {
  if (!voxelRasterDimensions) {
    return tipPath
  }

  const scaling = getXYVoxelScaling(slotName, metadata, voxelRasterDimensions)

  if (!scaling) {
    return tipPath
  }

  const newTipPath: number[][] = []

  for (let i = 0; i < tipPath.length; i++) {
    const point = tipPath[i]
    newTipPath.push([point[0] * scaling.x, point[1] * scaling.y])
  }

  return newTipPath
}
