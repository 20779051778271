import type { Action } from '@/modules/Editor/managers/actionManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import {
  isRasterAnnotation,
  isVideoAnnotation,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import { cloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import type { VideoAnnotationDataPayload } from '@/modules/Editor/types'
import type { View } from '@/modules/Editor/views/view'

const deleteAnnotationAction = (view: View, ann: Annotation): Action => ({
  do(): boolean {
    view.annotationManager.deleteAnnotation(ann.id)
    return true
  },
  undo(): boolean {
    view.annotationManager.createAnnotation(ann)
    return true
  },
})

/**
 * Prepare the annotation to be saved into the clipboard memory.
 * @param context the plugin context
 * @param shouldCut, if it's true behave as a CUT instead than a COPY command
 * @returns all data to be stored and later perform a PASTE command
 */
export const clipboardCommandCopy = (
  activeView: View,
  shouldCut = false,
):
  | {
      clipboardAnnotation: Annotation
      sourceAnnotationId: string
      // This code seems extremely wrong but it would take hours to debug
      // as a result of this here, we end up returning what seems to be wrong payload,
      // which the caller accepts due to being typed as any.
      // See below on where this is wrong
      // https://linear.app/v7labs/issue/ANN-1761
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      videoAnnotationData: any | undefined
      inferredVideoAnnotationData: VideoAnnotationDataPayload | undefined
    }
  | undefined => {
  const annotationManager = activeView.annotationManager
  const actionManager = activeView.editor.actionManager
  const selectedAnnotation = annotationManager.selectedAnnotation

  let videoAnnotationData
  let inferredVideoAnnotationData

  if (!selectedAnnotation || isRasterAnnotation(selectedAnnotation)) {
    return
  }

  if (isVideoAnnotation(selectedAnnotation)) {
    videoAnnotationData = {
      data: Object.values(selectedAnnotation.data.frames)[0],
      // the caller expects an array of `Annotation[]` to be assigned here, but
      // because we return `any`, nothing complains
      subs: Object.values(selectedAnnotation.subAnnotations.frames) || [],
      keyframe: false,
      subkeyframe: false,
      hidden_areas: selectedAnnotation.data.hidden_areas,
      interpolateAlgorithm: selectedAnnotation.data.interpolate_algorithm,
    }
    inferredVideoAnnotationData = inferVideoData(selectedAnnotation, activeView.currentFrameIndex)
  }

  const _clipboard = selectedAnnotation

  if (shouldCut) {
    actionManager.do(deleteAnnotationAction(activeView, selectedAnnotation))
  }

  return {
    clipboardAnnotation: cloneAnnotation(_clipboard),
    sourceAnnotationId: selectedAnnotation.id,
    videoAnnotationData,
    inferredVideoAnnotationData,
  }
}

export const clipboardCommandCut = (activeView: View): ReturnType<typeof clipboardCommandCopy> =>
  clipboardCommandCopy(activeView, true)
