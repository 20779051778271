import type { ExtendedFileMetadata } from '@/modules/Editor/metadata'

/**
 * Check if the medical file needs isotropic transformation.
 *
 * For legacy uploads (when the isotropic transformation was applied during the backend processing)
 * the 'metadata.medical.handler' property is empty, for new files uploaded without
 * the isotropic transformation it's set to 'MONAI'.
 *
 * Legacy MPR uploads sets 'metadata.medical.is_volume' to true. For those files
 * we also want vtk.js to handle the correction for isotropic transformation.
 *
 * Can be used as an indicator when vtk.js should apply the isotropic transformation on the client.
 */
export const doesNeedIsotropicTransformation = (
  metadata: ExtendedFileMetadata | undefined | null,
): boolean => metadata?.medical?.handler === 'MONAI' || metadata?.medical?.is_volume === true
