import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { AnnotationsFramePackage, PrecalculatedStoreType } from './AnnotationsFramePackage'
import type { PartialRecord } from '@/core/helperTypes'
import type { V2AnnotationPayload } from '@/store/types'
import { removeAnnotationFromPackage } from './removeAnnotationFromPackage'
import { getAnnotationRange } from '@/core/utils/frames'
import { getMainAnnotationType, MainAnnotationType } from '@/core/annotationTypes'
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import { calcAnnotationFrame } from './calcAnnotationFrame'
import { isVideoAnnotationDataPayload } from '@/modules/Editor/models/annotation/annotationKindValidator'
import isEqual from 'lodash/isEqual'
import { hasSegmentContainingIndex } from '@/modules/Editor/helpers/segments'
import { isAnnotationOutOfView } from '@/modules/Editor/utils/outOfViewUtils'

export const updateAnnotationForPackage = (
  currentAnnotation: V2AnnotationPayload,
  precalculatedStore: PrecalculatedStoreType,
  annotationsPackage: AnnotationsFramePackage,
  annotation: V2AnnotationPayload,
  editorAnnotation: Annotation | undefined,
  slotName: string,
  frameIndex: number,
  editorClassesById: PartialRecord<number, AnnotationClass>,
  config: {
    totalFrames: number
    videoAnnotationDuration: number
    isProcessedAsVideo: boolean
  },
): void => {
  const annotationClass = editorClassesById[annotation.annotation_class_id]
  if (!annotationClass) {
    throw new Error('Annotation class not found')
  }

  const type = getMainAnnotationType(annotationClass.annotation_types)
  if (!type) {
    throw new Error('Annotation class does not have a valid type')
  }

  // Annotations package should not contain masks or raster layers
  if (type === MainAnnotationType.Mask || type === MainAnnotationType.RasterLayer) {
    return
  }

  // Remove annotation from the current frame index only
  // rest frame incexes gonna be updated/calculated by the worker
  const hasSegmentChanged =
    isVideoAnnotationDataPayload(annotation.data) &&
    isVideoAnnotationDataPayload(currentAnnotation.data) &&
    !isEqual(annotation.data.segments, currentAnnotation.data.segments)

  const currentFrameNotInNewSegment =
    isVideoAnnotationDataPayload(annotation.data) &&
    !hasSegmentContainingIndex(annotation.data.segments, frameIndex)

  if (hasSegmentChanged && currentFrameNotInNewSegment) {
    // Current frame package has no annotations
    if (!annotationsPackage) {
      return
    }

    removeAnnotationFromPackage(
      precalculatedStore,
      annotationsPackage,
      annotation,
      config.totalFrames,
    )

    return
  }

  if (type === MainAnnotationType.Tag) {
    const tagIndexToUpdate = annotationsPackage.tagAnnotations.findIndex(
      (a) => a.id === annotation.id,
    )
    if (tagIndexToUpdate >= 0) {
      annotationsPackage.tagAnnotations[tagIndexToUpdate] = annotation
    } else {
      annotationsPackage.tagAnnotations.push(annotation)
    }

    return
  }

  const parsedAnnotationData = calcAnnotationFrame(
    annotation,
    slotName,
    frameIndex,
    annotationClass,
    config,
  )

  if (!parsedAnnotationData) {
    throw new Error('Annotation data is not valid')
  }

  const storeAnnIndexToUpdate = annotationsPackage.storeAnnotations.findIndex(
    ({ id }) => id === annotation.id,
  )
  if (storeAnnIndexToUpdate >= 0) {
    annotationsPackage.storeAnnotations[storeAnnIndexToUpdate] = annotation
  } else {
    annotationsPackage.storeAnnotations.push(annotation)
  }
  if (!annotationsPackage.orderedAnnotationIds.includes(annotation.id)) {
    annotationsPackage.orderedAnnotationIds.push(annotation.id)
  }

  if (!editorAnnotation) {
    return
  }

  const editorAnnIndexToUpdate = annotationsPackage.editorAnnotations.findIndex(
    (a) => a.id === annotation.id,
  )
  if (editorAnnIndexToUpdate >= 0) {
    annotationsPackage.editorAnnotations[editorAnnIndexToUpdate] = editorAnnotation
  } else {
    annotationsPackage.editorAnnotations.push(editorAnnotation)
  }
  annotationsPackage.annotationsMap[annotation.id] = editorAnnotation

  /* Render data: START */
  // TODO: DAR-3946 we should replace this solution with marking annotation as hidden for on layer
  if (
    isVideoAnnotationDataPayload(annotation.data) &&
    annotation.data.hidden_areas &&
    isAnnotationOutOfView(annotation.data.hidden_areas, frameIndex)
  ) {
    annotationsPackage.annotationsRenderData.zIndexesList =
      annotationsPackage.annotationsRenderData.zIndexesList.filter(
        (annId) => annId !== annotation.id,
      )
    return
  }

  annotationsPackage.annotationsRenderData.itemsBBoxMap.set(
    annotation.id,
    parsedAnnotationData.bbox,
  )
  const rTreeAnnIndexToUpdate = annotationsPackage.annotationsRenderData.rTreeItems.findIndex(
    (item) => item.id === annotation.id,
  )
  if (rTreeAnnIndexToUpdate >= 0) {
    annotationsPackage.annotationsRenderData.rTreeItems[rTreeAnnIndexToUpdate] =
      parsedAnnotationData.rTreeItem
  } else {
    annotationsPackage.annotationsRenderData.rTreeItems.push(parsedAnnotationData.rTreeItem)
  }
  annotationsPackage.annotationsRenderData.itemsMap.set(annotation.id, parsedAnnotationData.item)
  if (!annotationsPackage.annotationsRenderData.zIndexesList.includes(annotation.id)) {
    annotationsPackage.annotationsRenderData.zIndexesList.push(annotation.id)
  }
  /* Render data: END */

  // Clear the update annotation calculated segment since
  // data might be changd and we need to recalculate it
  const range = isVideoAnnotationDataPayload(currentAnnotation.data)
    ? getAnnotationRange(currentAnnotation.data.segments, config.totalFrames)
    : { startFrame: 0, endFrame: 1 }

  for (let i = range.startFrame; i <= range.endFrame; i++) {
    const renderableDataForFrame = precalculatedStore.renderableData.get(i)
    renderableDataForFrame?.itemsBBox.delete(annotation.id)
    renderableDataForFrame?.rTreeItems.delete(annotation.id)
    renderableDataForFrame?.renderableItems.delete(annotation.id)
  }

  const renderableData = precalculatedStore.renderableData.get(frameIndex)
  if (!renderableData) {
    return
  }

  renderableData.itemsBBox.set(annotation.id, parsedAnnotationData.bbox)
  renderableData.rTreeItems.set(annotation.id, parsedAnnotationData.rTreeItem)
  renderableData.renderableItems.set(annotation.id, parsedAnnotationData.item)
}
