import type { Editor } from '@/modules/Editor/editor'
import { TilesManager, Events as TilesManagerEvents } from '@/modules/Editor/managers/tilesManager'
import { Object2D } from '@/modules/Editor/models/layers/object2D'
import type { Tile } from '@/modules/Editor/models/tiler'
import { renderTiledImage } from '@/modules/Editor/renderTiledImage'
import { View } from '@/modules/Editor/views/view'
import type { FramesLoaderConfig } from '@/modules/Editor/workers/FramesLoaderWorker/types'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemPayload } from '@/store/types/V2DatasetItemPayload'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemSlot } from '@/store/types/V2DatasetItemSlot'

import { ViewTypes } from './viewTypes'

export class TiledView extends View {
  readonly type = ViewTypes.TILED

  public tilesManager: TilesManager
  private visibleTiles: Tile[] = []

  constructor(
    public editor: Editor,
    file: V2DatasetItemSlot,
    item: V2DatasetItemPayload,
    initialFrameIndex: number = -1,
    framesLoaderConfig: FramesLoaderConfig = {},
  ) {
    super(editor, file, item, initialFrameIndex, framesLoaderConfig)

    const neighbourTiles = editor.store.state.workview.neighbourTiles

    this.tilesManager = new TilesManager(this)

    this.visibleTiles = this.tilesManager.getVisibleTiles(neighbourTiles, false)

    this.mainLayer.onBeforeRender(() => {
      this.visibleTiles = this.tilesManager.getVisibleTiles(neighbourTiles)
    })

    this.init()

    const handleTilesLoaded = (): void => {
      this.loading = false
    }
    this.tilesManager.on(TilesManagerEvents.TILES_LOADED, handleTilesLoaded)
    this.onCleanup.push(() =>
      this.tilesManager.off(TilesManagerEvents.TILES_LOADED, handleTilesLoaded),
    )
  }

  /** Override the view method to reload the tiles **/
  reloadCurrentFrame(): Promise<void> {
    const neighbourTiles = this.editor.store.state.workview.neighbourTiles
    this.tilesManager.reloadVisibleTiles(neighbourTiles, false)
    return Promise.resolve()
  }

  async init(): Promise<void> {
    this.camera.setImage({
      width: this.tilesManager.imageWidth,
      height: this.tilesManager.imageHeight,
    })
    this.scaleToFit()

    this.showFramesTool = false

    this.mainLayer.clear()
    this.mainLayer.add(
      new Object2D('tiled_image', () => {
        if (!this.mainLayer.context) {
          return
        }
        renderTiledImage(this, this.visibleTiles, this.mainLayer.context)
      }),
    )

    // Reset the current image filter's window level
    this.setImageFilter({
      ...this.imageFilter,
      windowLevels: this.defaultWindowLevels,
    })

    // Reset tool
    const { currentTool } = this.toolManager
    if (currentTool) {
      currentTool.tool.reset(currentTool.context)
    }

    await this.jumpToFrame(this.currentFrameIndex)
    this.initCamera()
  }

  get currentViewSize(): { width: number; height: number } | null {
    return this.tilesManager
      ? { width: this.tilesManager.imageWidth, height: this.tilesManager.imageHeight }
      : null
  }
}
