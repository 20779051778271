import type { FrameData } from '@/core/annotations'
import type { BBox } from '@/modules/Editor/types'
import { calcCentroidByType } from '@/modules/Editor/utils/calcCentroidByType'

import { calcBBoxForVectorByType } from './calcBBoxForVectorByType'
import { calcPathBBox } from './calcPathBBox'

export const calcPolygonBBox = (data: FrameData, offCenter = true): BBox | undefined => {
  const { polygon } = data

  if (!polygon) {
    return
  }

  const { path, additional_paths } = polygon

  const flattenedPaths = [...path, ...(additional_paths || []).flat()]
  const bbox = calcPathBBox(flattenedPaths, offCenter)

  if (data.directional_vector) {
    const centroidType = calcCentroidByType('polygon', data)

    if (!centroidType) {
      return bbox
    }

    return calcBBoxForVectorByType(data.directional_vector, centroidType, bbox)
  }

  return bbox
}
