import type { Camera } from '@/modules/Editor/camera'
import type { ILayer } from '@/modules/Editor/models/layers/types'
import type { InvalidatedRasterRegion } from '@/modules/Editor/models/raster/Raster'
import type { RasterBufferAccessor } from '@/modules/Editor/models/raster/RasterBufferAccessor'
// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'

type ViewportSize = {
  x: number
  y: number
}

export type RasterRenderables = {
  rasterBufferAccessor: RasterBufferAccessor | undefined
  cachedCanvas?: HTMLCanvasElement
  isInvalidated?: boolean
  invalidatedRasterRegion: InvalidatedRasterRegion
  targetViewportSize?: ViewportSize
  labelColorMap: Record<string, number>
}

/**
 * Clears the canvas so that we can draw the updated labelmap.
 * @param canvas The canvas to clear.
 * @param ctx The 2d canvas context.
 */
function clearCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
  ctx.save()
  // Set transform to the identiy matrix as:
  // [a c e]
  // [b d f]
  // [0 0 1]
  // Where arguments are, in order, `a, b, c, d, e, f`
  ctx.setTransform(1, 0, 0, 1, 0, 0)
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  ctx.restore()
}

/**
 * Maps the `RGBA` color to an rgba color encoded as a single 32 bit number.
 *
 * @param color The class color to map.
 * @returns The color as a 4-element array.
 */
export function getSegmentColorWithAlpha(color: RGBA, fillOpacity: number): number {
  const uint8Array = new Uint8Array(4)

  uint8Array[0] = color.r
  uint8Array[1] = color.g
  uint8Array[2] = color.b
  // Note: scale fill opacity from 0-100 to 0-255 by multiplying by 2.55
  uint8Array[3] = Math.round(fillOpacity * 2.55)

  // get a 32 bit number representation of the color.
  const uint32ArrayView = new Uint32Array(uint8Array.buffer)

  return uint32ArrayView[0]
}

/**
 * Gets the labelmap canvas (canvas with same dimensions as the underlying source image).
 *
 * @param raster The raster to render.
 * @param annotations The mask annotations on the raster.
 * @returns The canvas which can then be drawn onto the screen-scale canvas.
 */
function getLabelmapCanvas(rasterRenderables: RasterRenderables): HTMLCanvasElement | undefined {
  const {
    rasterBufferAccessor,
    cachedCanvas,
    isInvalidated,
    labelColorMap,
    invalidatedRasterRegion,
  } = rasterRenderables

  if (cachedCanvas === undefined) {
    return
  }

  // If the raster was not invalidated it means there was no change in the
  // raster buffer, so we can just return the cached canvas as there is no
  // need to re-paint.
  if (isInvalidated === false) {
    return cachedCanvas
  }

  const ctx = cachedCanvas.getContext('2d')

  if (ctx === null) {
    throw new Error('cannot get 2d canvas rendering context')
  }

  const { xMin, xMax, yMin, yMax } = invalidatedRasterRegion

  if (!rasterBufferAccessor) {
    // No renderable buffer for this frame, clear canvas
    clearCanvas(cachedCanvas, ctx)

    return cachedCanvas
  }

  ctx.imageSmoothingEnabled = false // We want a pixelated outline.

  // The +1 here is because the number of pixels in the region is one more
  // Then the difference in pixel index. E.g. if we are invalidating 1 pixel,
  // xMin = 12 and xMax = 12. If xMin = 2 and xMin = 3, we are invalidating 2 pixels,
  // with x=2 and x=3.
  const regionWidth = xMax - xMin + 1
  const regionHeight = yMax - yMin + 1

  // Detect if we're trying to draw outside the bounds of the canvas.
  if (regionWidth <= 0 || regionHeight <= 0) {
    return cachedCanvas
  }

  // Create RGBA ImageData. Note: initialized with all transparent black.
  const regionImageData = new ImageData(regionWidth, regionHeight)

  const data = regionImageData.data

  const data32BitView = new Uint32Array(data.buffer)

  for (let j = yMin; j <= yMax; j++) {
    for (let i = xMin; i <= xMax; i++) {
      const labelValue = rasterBufferAccessor.get(i, j)

      let color

      if (labelValue !== 0) {
        color = labelColorMap[labelValue]

        if (!color) {
          /**
           * This means there is data on the labelmap which is not covered
           * by an annotation. Do not render it as we don't know what color
           * it should be, and it would be "dangling" data. The common reason
           * for hitting this is when the full densely packed raster layer is
           * ingested from the backend, but the annotation are still be ing
           * added to the state.
           */
          color = 0
        }
      } else {
        color = 0
      }

      const iRegion = i - xMin
      const jRegion = j - yMin

      const regionPixelIndex = jRegion * regionWidth + iRegion

      // Modify ImageData.
      data32BitView[regionPixelIndex] = color
    }
  }

  // Put this image data onto the labelmapCanvas
  ctx.putImageData(regionImageData, xMin, yMin)

  return cachedCanvas
}

/**
 * Draws the image-sized labelmap onto the screen sized canvas.
 * The `imageSmoothingEnabled` property of the canvas context is
 * set to false during the draw, so that we get a sharp pixel mask
 * rendering for the raster (independent on whether Pixel View is
 * on for the underlying data).
 *
 * @param camera The camera of the view for mapping from image to
 * canvas coordiantes.
 * @param ctx The raster layer canvas context.
 * @param labelmapCanvas The labelmap canvas to draw to the context.
 */
function drawNewLabelmap(
  camera: Camera,
  ctx: CanvasRenderingContext2D,
  labelmapCanvas: HTMLCanvasElement,
  targetViewportSize?: ViewportSize,
): void {
  ctx.save()

  const previousImageSmoothingEnabled = ctx.imageSmoothingEnabled

  ctx.imageSmoothingEnabled = false

  camera.imageViewCtxToCanvasViewCtx(ctx)

  if (targetViewportSize) {
    ctx.drawImage(labelmapCanvas, 0, 0, targetViewportSize.x, targetViewportSize.y)
  } else {
    ctx.drawImage(labelmapCanvas, 0, 0)
  }

  ctx.restore()

  ctx.imageSmoothingEnabled = previousImageSmoothingEnabled
}

export const renderMasks = (
  layer: ILayer<CanvasRenderingContext2D, HTMLCanvasElement>,
  camera: Camera,
  rasterRenderables: RasterRenderables,
): void => {
  const labelmapCanvas = getLabelmapCanvas(rasterRenderables)
  const layerCanvas = layer.canvas
  const layerCtx = layer.context

  if (!layerCanvas || !layerCtx || !labelmapCanvas) {
    return
  }

  clearCanvas(layerCanvas, layerCtx)
  drawNewLabelmap(camera, layerCtx, labelmapCanvas, rasterRenderables.targetViewportSize)
}
