import cloneDeep from 'lodash/cloneDeep'

import type { IPoint } from '@/modules/Editor/point'
import type { Editor } from '@/modules/Editor/editor'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { isVideoAnnotation } from '@/modules/Editor/models/annotation/annotationKindValidator'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import { translateAnnotation } from '@/modules/Editor/translateAnnotation'

export const translatePath = (editor: Editor, annotation: Annotation, offset: IPoint): void => {
  const { activeView } = editor

  if (!isVideoAnnotation(annotation)) {
    return translateAnnotation(annotation, activeView, offset)
  }

  const { data: annotationData, subs } = inferVideoData(annotation, activeView.currentFrameIndex)
  if (!annotationData) {
    return
  }

  const { isLoading } = activeView
  if (isLoading) {
    return
  }

  // Build an Annotation object with current frame data, then translate it
  const currentAnnotation = shallowCloneAnnotation(annotation, { data: cloneDeep(annotationData) })
  translateAnnotation(currentAnnotation, activeView, offset)
  activeView.annotationManager.updateAnnotationFrame(
    annotation,
    currentAnnotation.data,
    subs,
    activeView.currentFrameIndex,
  )
}
