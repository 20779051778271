import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import type { Editor } from '@/modules/Editor/editor'
import type { CornerPosition } from '@/modules/Editor/plugins/click/types'

export const selectCornerCursor = (editor: Editor, corner: CornerPosition): void => {
  if (corner === 'top-left') {
    selectCursor(EditorCursor.NWResize)
  } else if (corner === 'top-right') {
    selectCursor(EditorCursor.NEResize)
  } else if (corner === 'bottom-right') {
    selectCursor(EditorCursor.SEResize)
  } else if (corner === 'bottom-left') {
    selectCursor(EditorCursor.SWResize)
  }
}
