import cloneDeep from 'lodash/cloneDeep'

import type { EyeData } from '@/core/annotations'
import type { AnnotationData, Eye } from '@/modules/Editor/AnnotationData'
import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import {
  DEFAULT_LINE_WIDTH,
  DEFAULT_VERTEX_ACTIVE_SCALE,
  DEFAULT_VERTEX_SIZE,
} from '@/modules/Editor/config'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import { isLeftMouseButton } from '@/modules/Editor/mouse'
import { createEditablePoint, subPoints } from '@/modules/Editor/point'
import type { IPoint } from '@/modules/Editor/point'
import { ToolName } from '@/modules/Editor/tools/types'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import type { Action } from '@/modules/Editor/managers/actionManager'
import { updateAnnotationData } from '@/modules/Editor/actions'
import { drawGuideLines } from '@/modules/Editor/graphics/drawGuideLines'
import { drawEye } from '@/modules/Editor/graphicsV2/drawEye'
import { drawEyeVertices } from '@/modules/Editor/graphicsV2/drawVerticesByType'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { translatePath } from '@/modules/Editor/plugins/edit/utils'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import { preselectOrPromptForAnnotationClass } from '@/modules/Editor/utils/preselectOrPromptForAnnotationClass'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'

import { annotationType } from './consts'
import translateVertex from './utils/translateVertex'

export interface EyeTool extends Tool {
  initialPoint: IPoint | undefined
  previousCursorPoint: IPoint | undefined
  cursorPoint: IPoint | undefined
  targetAnnotation: Annotation | null
  originalTargetAnnotationData: Annotation['data'] | null
  targetVertexIndex: number | undefined

  onStart: (context: ToolContext, event: PointerEvent) => void
  onMove: (context: ToolContext, event: PointerEvent) => void
  onEnd: (context: ToolContext, event: PointerEvent) => void
  draw: (view: View) => void
  resetHoverState: () => void
}

const annotationCreationAction = async (
  context: ToolContext,
  eye: AnnotationData,
): Promise<Action> => {
  const params = { type: annotationType, data: eye }

  const newAnnotation =
    await context.editor.activeView.annotationManager.prepareAnnotationForCreation(params)

  if (!newAnnotation) {
    throw new Error('Failed to create annotation')
  }

  // support view id for undoing purposes
  const sourceViewId = context.editor.activeView.id

  return {
    do(): boolean {
      context.editor.activeView.annotationManager.createAnnotation(newAnnotation)
      context.editor.activeView.annotationManager.selectAnnotation(newAnnotation.id)

      context.editor.activeView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)

      return true
    },
    undo(): boolean {
      // we don't want to use the active view here, as by the time the user want to undo
      // the view could have changed due to multi-slot;
      // this would result in the annotation not being found and the undo action failing
      const sourceView = context.editor.viewsList.find(({ id }) => id === sourceViewId)
      if (!sourceView) {
        return false
      }

      sourceView.annotationManager.deleteAnnotation(newAnnotation.id)

      sourceView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)
      return true
    },
  }
}

export const eyeTool: EyeTool = {
  initialPoint: undefined,
  cursorPoint: undefined,
  previousCursorPoint: undefined,
  targetAnnotation: null,
  originalTargetAnnotationData: null,
  targetVertexIndex: undefined,

  onStart(context: ToolContext, event: PointerEvent) {
    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    this.initialPoint = point

    // beginning movement of existing annotation, save original position for undo action
    if (this.targetAnnotation) {
      this.originalTargetAnnotationData = cloneDeep(this.targetAnnotation.data)
      context.editor.activeView.annotationManager.selectAnnotation(this.targetAnnotation.id)

      if (this.targetVertexIndex !== undefined) {
        context.editor.activeView.annotationManager.selectVertexIndex(this.targetVertexIndex)
      }
    } else {
      context.editor.activeView.annotationManager.deselectAllAnnotations()
    }
  },

  onMove(context: ToolContext, event: PointerEvent) {
    if (!context.editor.activeView.hitTarget(event)) {
      return CallbackStatus.Stop
    }

    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    this.previousCursorPoint = this.cursorPoint || this.initialPoint || point
    this.cursorPoint = point
    const cursorImagePoint = context.editor.activeView.camera.canvasViewToImageView(
      this.cursorPoint,
    )
    const previousCursorImagePoint = context.editor.activeView.camera.canvasViewToImageView(
      this.previousCursorPoint,
    )

    // drawing a new annotation
    if (this.initialPoint && !this.targetAnnotation) {
      this.draw(context.editor.activeView)
      return CallbackStatus.Stop
    }

    // dragging an existing annotation/vertex
    if (this.initialPoint && this.targetAnnotation) {
      const offsetPoint = subPoints(cursorImagePoint, previousCursorImagePoint)

      if (this.targetVertexIndex !== undefined) {
        // dragging vertex
        translateVertex(context.editor, this.targetVertexIndex, offsetPoint, event)
      } else {
        // dragging annotation
        translatePath(context.editor, this.targetAnnotation, offsetPoint)
      }
      context.editor.activeView.updateRenderedAnnotation(this.targetAnnotation.id)
      context.editor.activeView.annotationsLayer.changed()

      return CallbackStatus.Stop
    }

    context.editor.activeView.annotationsLayer.hitItemRegion(cursorImagePoint).then((id) => {
      const targetAnnotation = context.editor.activeView.annotationManager.getAnnotation(id || '')

      // hovering over annotation of different type or no annotation at all
      if (targetAnnotation?.type !== 'eye') {
        context.editor.activeView.annotationManager.unhighlightAllAnnotations()
        this.resetHoverState()
      } else if (targetAnnotation !== this.targetAnnotation) {
        this.targetAnnotation = targetAnnotation
        context.editor.activeView.annotationManager.highlightAnnotation(targetAnnotation.id)
      }
    })

    if (this.targetAnnotation) {
      const targetVertexIndex = context.editor.activeView.annotationsLayer.hitVertexRegion(
        this.targetAnnotation.id,
        cursorImagePoint,
      )

      if (this.targetVertexIndex !== targetVertexIndex) {
        if (this.targetVertexIndex !== undefined) {
          context.editor.activeView.annotationsLayer.activateVertexWithState(
            this.targetAnnotation.id,
            this.targetVertexIndex,
            { isHighlighted: false },
          )
        }
        if (targetVertexIndex !== undefined) {
          context.editor.activeView.annotationsLayer.activateVertexWithState(
            this.targetAnnotation.id,
            targetVertexIndex,
            { isHighlighted: true },
          )
        }
        this.targetVertexIndex = targetVertexIndex
      }
    } else {
      this.targetVertexIndex = undefined
    }

    this.draw(context.editor.activeView)
    return CallbackStatus.Stop
  },

  async onEnd(context: ToolContext, event: PointerEvent) {
    // finished dragging existing annotation/vertex
    if (this.targetAnnotation && this.originalTargetAnnotationData) {
      if (this.targetVertexIndex !== undefined) {
        context.editor.activeView.annotationManager.highlightVertexIndex(this.targetVertexIndex)
      }

      const action = updateAnnotationData(
        context.editor.activeView,
        this.targetAnnotation,
        this.originalTargetAnnotationData,
        this.targetAnnotation.data,
      )
      context.editor.actionManager.do(action)
      this.reset(context)
      return CallbackStatus.Stop
    }

    if (!this.initialPoint) {
      return
    }

    const point = resolveEventPoint(event, true)
    if (point) {
      this.cursorPoint = point
    }

    if (!this.cursorPoint) {
      return
    }

    const p1 = context.editor.activeView.camera.canvasViewToImageView(this.cursorPoint)
    const p2 = context.editor.activeView.camera.canvasViewToImageView(this.initialPoint)

    const threshold = 5
    if (Math.abs(p1.x - p2.x) < threshold || Math.abs(p1.y - p2.y) < threshold) {
      this.reset(context)
      this.draw(context.editor.activeView)
      return
    }

    const { x: width, y: height } = subPoints(p1, p2)

    if (!context.editor.activeView.annotationManager.preselectedAnnotationClass) {
      return
    }

    const eyeMetaData =
      context.editor.activeView.annotationManager.preselectedAnnotationClass.eyeMetaData
    if (!eyeMetaData) {
      return
    }
    const nodes = eyeMetaData.nodes.map((node) => ({
      point: createEditablePoint({ x: p2.x + node.x * width, y: p2.y + node.y * height }),
      name: node.name,
      occluded: false,
    }))
    const eye: Eye = { nodes }
    try {
      await context.editor.actionManager.do(await annotationCreationAction(context, eye))
    } catch (e) {
      setContext('error', { error: e })
      console.error('V2 eye tool createAnnotation failed')
    }

    this.reset(context)
    this.draw(context.editor.activeView)
  },

  async activate(context: ToolContext) {
    setupMouseButtonLoadout(context, { middle: true })

    const classSelected = await preselectOrPromptForAnnotationClass(
      context.editor.activeView,
      ToolName.Eye,
      [annotationType],
      'You must select an exising Eye class before using the eye tool',
    )

    if (!classSelected) {
      return
    }

    selectCursor(EditorCursor.BBox)

    context.editor.registerCommand('eye_tool.cancel', () => {
      this.reset(context)
      this.resetHoverState()
      this.draw(context.editor.activeView)
      return CallbackStatus.Stop
    })

    context.handles.push(
      ...context.editor.onMouseDown((e) => {
        if (!isLeftMouseButton(e)) {
          return CallbackStatus.Continue
        }
        return this.onStart(context, e)
      }),
    )
    context.handles.push(...context.editor.onMouseMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onMouseUp((event) => this.onEnd(context, event)))

    context.handles.push(...context.editor.onTouchStart((event) => this.onStart(context, event)))
    context.handles.push(...context.editor.onTouchMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onTouchEnd((event) => this.onEnd(context, event)))
  },

  draw(view: View): void {
    view.annotationsLayer.draw((ctx) => {
      if (this.cursorPoint) {
        drawGuideLines(ctx, view, this.cursorPoint)
      }

      if (this.cursorPoint === undefined || this.initialPoint === undefined) {
        return
      }

      ctx.beginPath()
      ctx.strokeStyle = view.annotationManager.preselectedAnnotationClassColor()
      ctx.lineWidth = 1
      ctx.strokeRect(
        this.initialPoint.x,
        this.initialPoint.y,
        this.cursorPoint.x - this.initialPoint.x,
        this.cursorPoint.y - this.initialPoint.y,
      )

      const annotationClass = view.annotationManager.preselectedAnnotationClass

      if (!annotationClass?.eyeMetaData) {
        return
      }

      const { x: width, y: height } = subPoints(this.cursorPoint, this.initialPoint)
      const tempNodes = (annotationClass.eyeMetaData as EyeData).nodes.map((node) => {
        if (this.cursorPoint === undefined || this.initialPoint === undefined) {
          return node
        }

        return {
          ...node,
          x: node.x * width + this.initialPoint.x,
          y: node.y * height + this.initialPoint.y,
        }
      })

      drawEye(ctx, {
        lineWidth: DEFAULT_LINE_WIDTH,
        strokeColor: annotationClass.colorRGBAstring,
        nodes: tempNodes,
      })
      drawEyeVertices(
        ctx,
        { eye: { nodes: tempNodes } },
        {
          fillColor: annotationClass.colorRGBAstring,
          lineWidth: DEFAULT_LINE_WIDTH,
          strokeColor: annotationClass.colorRGBAstring,
          pointSize: DEFAULT_VERTEX_SIZE / DEFAULT_VERTEX_ACTIVE_SCALE,
        },
      )
    })
  },

  deactivate() {},

  reset() {
    this.initialPoint = undefined
    this.cursorPoint = undefined
  },

  resetHoverState() {
    this.targetAnnotation = null
    this.originalTargetAnnotationData = null
    this.targetVertexIndex = undefined
  },
}
