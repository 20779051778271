import { ClassEvents, EditorEvents } from '@/modules/Editor/eventBus'
import type { Editor } from '@/modules/Editor/editor'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'

/**
 * Paste only the annotation class on top of an existing annotation
 * @param context the plugin context
 * @param payload, data from the clipboard memory
 */
export const clipboardCommandAltPaste = (
  editor: Editor,
  payload: {
    clipboardAnnotation: Annotation | undefined
  },
): void => {
  const { clipboardAnnotation } = payload
  if (!clipboardAnnotation || !clipboardAnnotation.annotationClass) {
    return
  }

  if (editor.activeView.isLoading) {
    return
  }

  const annotation = editor.activeView.annotationManager.selectedAnnotation
  if (!annotation) {
    return
  }
  const clipboardAnnotationType = editor.activeView.annotationManager.getMainAnnotationTypeForClass(
    clipboardAnnotation.annotationClass,
  )
  const annotationType = editor.activeView.annotationManager.getMainAnnotationTypeForClass(
    annotation.annotationClass,
  )

  if (clipboardAnnotationType !== annotationType) {
    EditorEvents.message.emit({
      content: 'Cannot Copy to the annotation class with different type',
      level: 'info',
    })
    return
  }

  ClassEvents.changeAnnotationClass.emit({
    annotationId: annotation.id,
    newClassId: clipboardAnnotation.annotationClass.id,
  })
}
