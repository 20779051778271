type CanvasSettings = {
  willReadFrequently: boolean
}

export class CanvasAndContext {
  private _canvas: HTMLCanvasElement | null
  private _context: CanvasRenderingContext2D | null

  constructor(settings?: CanvasSettings) {
    this._canvas = document.createElement('canvas')
    this._context = this._canvas.getContext('2d', {
      willReadFrequently: !!settings?.willReadFrequently,
    })
  }

  get canvas(): HTMLCanvasElement | null {
    return this._canvas
  }

  get context(): CanvasRenderingContext2D | null {
    return this._context
  }

  setCanvasSize(w: number, h: number): void {
    if (!this.canvas) {
      return
    }

    // Canvas accepts width/height as Integer
    this.canvas.width = Math.floor(w)
    this.canvas.height = Math.floor(h)
  }

  clearCanvas(x = 0, y = 0, w = this.canvas?.width, h = this.canvas?.height): void {
    if (!this.context) {
      return
    }
    if (!w || !h) {
      return
    }
    this.context.clearRect(x, y, w, h)
  }

  insertCanvas(
    image: HTMLCanvasElement | ImageBitmap,
    x = 0,
    y = 0,
    w = this.canvas?.width,
    h = this.canvas?.height,
  ): void {
    if (!this.context) {
      return
    }
    if (!w || !h) {
      return
    }
    if (!image.width || !image.height) {
      return
    }
    this.context.drawImage(image, x, y, w, h)
  }

  destroy(): void {
    this._canvas?.remove()
    this._canvas = null
    this._context = null
  }
}
