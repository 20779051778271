import type { FrameData } from '@/core/annotations'
import type { BBox } from '@/modules/Editor/types'

export const calcEllipseBBox = (data: FrameData): BBox | undefined => {
  const { ellipse } = data

  if (!ellipse) {
    return
  }

  const { radius, center, angle } = ellipse

  const xAx = Math.cos(angle)
  const xAy = Math.sin(angle)
  const w = ((radius.x * xAx) ** 2 + (radius.y * xAy) ** 2) ** 0.5
  const h = ((radius.x * xAy) ** 2 + (radius.y * xAx) ** 2) ** 0.5

  return {
    x: center.x,
    y: center.y,
    width: w * 2,
    height: h * 2,
  }
}
