import type ColorMapDict from '@/modules/Editor/colorMaps'

// Note: This is an incorrectly name type, throughout the app this
// is the range, not the window + level.
export type WindowLevels = [number, number]

export type ColorMap = 'default' | keyof typeof ColorMapDict

export const NUM_PIXEL_CHANNELS_64k = 65536
export const NUM_PIXEL_CHANNELS_256 = 256

export const MIN_PIXEL_CHANNEL = 0
export const MAX_PIXEL_CHANNEL_64k = NUM_PIXEL_CHANNELS_64k - 1
export const MAX_PIXEL_CHANNEL_256 = NUM_PIXEL_CHANNELS_256 - 1

export enum WINDOW_LEVEL_RANGE_UNITS {
  HOUNSFIELD = 'HOUNSFIELD',
}

export type WindowLevel = {
  window: number
  level: number
}

export type ImageManipulationFilter = {
  opacity: number
  borderOpacity: number
  contrast: number
  saturate: number
  brightness: number
  isInverted: boolean
  // Undefined here allows us to set the default value based on the file type.
  isImageSmoothing: boolean | undefined
  windowLevels: WindowLevels
  colorMap: ColorMap
}

export const DEFAULT_IMAGE_MANIPULATION_FILTER: ImageManipulationFilter = {
  opacity: 30,
  borderOpacity: 80,
  contrast: 100,
  saturate: 100,
  brightness: 100,
  isInverted: false,
  isImageSmoothing: undefined,
  // Note: This is an incorrectly name property, throughout the app this
  // is the range, not the window + level.
  windowLevels: [MIN_PIXEL_CHANNEL, MAX_PIXEL_CHANNEL_64k],
  colorMap: 'default',
}

/**
 * Cached opacities for the different views.
 */
export const CACHED_OPACITIES = {
  opacity: DEFAULT_IMAGE_MANIPULATION_FILTER.opacity,
  borderOpacity: DEFAULT_IMAGE_MANIPULATION_FILTER.borderOpacity,
}

/**
 * Cached color filters for the different views.
 */
export const CACHED_COLOR_FILTER = {
  contrast: DEFAULT_IMAGE_MANIPULATION_FILTER.contrast,
  brightness: DEFAULT_IMAGE_MANIPULATION_FILTER.brightness,
  saturate: DEFAULT_IMAGE_MANIPULATION_FILTER.saturate,
  isImageSmoothing: DEFAULT_IMAGE_MANIPULATION_FILTER.isImageSmoothing,
}

export const getImageManipulationFilter = (): ImageManipulationFilter => ({
  ...DEFAULT_IMAGE_MANIPULATION_FILTER,
  ...CACHED_OPACITIES,
  ...CACHED_COLOR_FILTER,
})
