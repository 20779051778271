/**
 * This function returns the raster frames that are affected when painting a stroke.
 *
 * For image types it returns undefined, as the frame is not needed.
 *
 * For videos it returns the current frame index.
 *
 * For voxel types it returns all the frames. This is inefficient but the performance seems to
 * be ok so far, there is a ticket to improve,
 * https://linear.app/v7labs/issue/DAR-1885/performance-improvement-for-undoredo-action
 * but it's not trivial as we don't know the amount of frames until we end the stroke, and we
 * need to have a comparison against the start state.
 */
import type { Raster } from '@/modules/Editor/models/raster/Raster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import { assertVoxelRaster } from '@/modules/Editor/models/raster/assertVoxelRaster'
import range from 'lodash/range'

export const getToolFrameRange = (
  raster: Raster,
  isReformattedView: boolean,
  brushDepth: number = 1,
): number[] | undefined => {
  const { currentFrameIndex } = raster.view
  if (raster.type === RasterTypes.VIDEO) {
    return [currentFrameIndex]
  }

  if (raster.type === RasterTypes.VOXEL) {
    const voxelRaster = assertVoxelRaster(raster)
    if (isReformattedView) {
      return range(0, voxelRaster.depth)
    }

    if (brushDepth > 1) {
      const halfBrushDepth = Math.ceil(brushDepth / 2)
      return range(
        Math.max(currentFrameIndex - halfBrushDepth, 0),
        Math.min(currentFrameIndex + halfBrushDepth + 1, voxelRaster.depth),
      )
    }
    return [currentFrameIndex]
  }
}
