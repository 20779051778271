import { buildPath2DImageView } from '@/modules/Editor/graphics/buildPath2DImageView'
import type { IPoint } from '@/modules/Editor/point'
import type { RenderingContext2D } from '@/modules/Editor/types'

type DrawPathProps = {
  fillColor: string
  strokeColor: string
  lineWidth: number
  path: IPoint[]
  additional_paths?: IPoint[][]
  closePath?: boolean
}

export const drawPath = (
  ctx: RenderingContext2D,
  {
    fillColor,
    strokeColor,
    lineWidth,
    path,
    additional_paths = [],
    closePath = true,
  }: DrawPathProps,
): Path2D => {
  const path2D = buildPath2DImageView(path, closePath)
  for (const p of additional_paths) {
    const additionalPath = buildPath2DImageView(p, closePath)
    path2D.addPath(additionalPath)
  }

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = strokeColor
  ctx.fillStyle = fillColor

  ctx.stroke(path2D)
  ctx.fill(path2D, 'evenodd')

  return path2D
}
