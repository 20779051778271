import type { Range } from '@/modules/Editor/types'
import type { Bounds } from '@/modules/Editor/models/raster/Raster'

export type PolygonToRasterResult = {
  data: [number, number][]
  coords: Range
  bounds: Bounds
}

export const polygonToRaster = (polygon: { x: number; y: number }[]): PolygonToRasterResult => {
  let minX = Infinity
  let maxX = -Infinity
  let minY = Infinity
  let maxY = -Infinity

  // Find the bounds of the polygon
  for (const point of polygon) {
    minX = Math.min(minX, point.x)
    minY = Math.min(minY, point.y)
    maxX = Math.max(maxX, point.x)
    maxY = Math.max(maxY, point.y)
  }

  minX = Math.floor(minX)
  minY = Math.floor(minY)
  maxX = Math.floor(maxX)
  maxY = Math.floor(maxY)

  // Initialize the raster array
  const points: [number, number][] = []

  // Fill the raster using scanline fill algorithm
  for (let y = minY; y <= maxY; y++) {
    const intersections = []
    for (let i = 0; i < polygon.length; i++) {
      const p1 = polygon[i]
      const p2 = polygon[(i + 1) % polygon.length]
      if (
        (Math.floor(p1.y) <= y && Math.floor(p2.y) > y) ||
        (Math.floor(p2.y) <= y && Math.floor(p1.y) > y)
      ) {
        // Calculate intersection point
        const xIntersect = Math.floor(
          p1.x +
            ((y - Math.floor(p1.y)) / (Math.floor(p2.y) - Math.floor(p1.y))) *
              (Math.floor(p2.x) - Math.floor(p1.x)),
        )
        intersections.push(xIntersect)
      }
    }

    // Sort the intersections
    intersections.sort((a, b) => a - b)

    // Fill the pixels between pairs of intersections
    for (let i = 0; i < intersections.length; i += 2) {
      for (let x = Math.ceil(intersections[i]); x < Math.floor(intersections[i + 1]); x++) {
        points.push([Math.floor(x), Math.floor(y)])
      }
    }
  }

  return {
    data: points,
    coords: {
      minX,
      maxX,
      minY,
      maxY,
    },
    bounds: {
      topLeft: {
        x: minX,
        y: minY,
      },
      bottomRight: {
        x: maxX,
        y: maxY,
      },
    },
  }
}
