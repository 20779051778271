import type { FrameData } from '@/core/annotations'
import type { AnnotationData, Text } from '@/modules/Editor/AnnotationData'

export const textSerializer = {
  serialize(data: AnnotationData): FrameData {
    const textData = data as Text
    return { text: { text: textData.text } }
  },

  deserialize(rawData: FrameData): AnnotationData | null {
    if (!rawData.text) {
      return null
    }
    return {
      text: rawData.text.text,
    }
  },
}
