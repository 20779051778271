import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import isEqual from 'lodash/isEqual'
import omitBy from 'lodash/omitBy'
import uniq from 'lodash/uniq'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { useClassesById } from './useClassesById'
import { useToast } from '@/uiKit/Toast/useToast'
import type { AnnotationType, MainAnnotationType } from '@/core/annotationTypes'
import { isAnnotationTypeRenderable, mainAnnotationTypes } from '@/core/annotationTypes'
import type { PartialRecord } from '@/core/helperTypes'
import { useFatalError } from '@/modules/Workview/useFatalError'
import { useDatasetStore } from '@/modules/Datasets/useDatasetStore'
import { useTeamStore } from '@/pinia/useTeamStore'
import type { ApiResponse } from '@/store/types'
import { FeatureName, LoadingStatus } from '@/store/types'
import type {
  AnnotationClassMetadata,
  AnnotationClassPayload,
} from '@/store/types/AnnotationClassPayload'
import type { DatasetPayload } from '@/store/types/DatasetPayload'
import * as api from '@/backend/api'
import { createAnnotationClass as createRequest } from '@/backend/darwin/createAnnotationClass'
import { loadAnnotationClasses as loadRequest } from '@/backend/darwin/loadAnnotationClasses'
import { updateAnnotationClass } from '@/backend/darwin/updateAnnotationClass'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'
import { useAnnotationAttributeStore } from '@/modules/Classes/useAnnotationAttributeStore'
import { loadClassUsage } from '@/backend/darwin/loadClassUsage'
import { storeToEditorAnnotationClass } from '@/modules/Workview/engineAdapters'
import { AnnotationSubTypeGranularity } from '@/core/annotations'
import { useFeatureFlagsStore } from '@/pinia/useFeatureFlagsStore'
import { useAnnotationTypesStore } from './useAnnotationTypesStore'
import type { AxiosResponse } from 'axios'
import { useDatasetCRUD } from '@/modules/Datasets/useDatasetCRUD'
/**
 * Provides functionalities related to the (new) classes page
 * Returns helpers to handle hotkeys and class selection in the UI
 *
 * This composable is meant to support the classes page, not to become
 * the pinia store for classes or anything of the sort. Whichever parts that talk
 * to vuex now will later talk to a separate pinia store.
 */

/**
 * The following object maps annotation type names to the features
 * name that enables them.
 */
const FEATURE_ENABLED_TYPES: PartialRecord<AnnotationType, FeatureName> = {
  simple_table: FeatureName.SIMPLE_TABLE,
  eye: FeatureName.EYE_TOOL,
}

export type CreateClassPayload = {
  annotationTypeNames: AnnotationType[]
  description: string | null
  images: AnnotationClassPayload['images']
  metadata: AnnotationClassMetadata
  name: string
  datasets: AnnotationClassPayload['datasets']
}

export type UpdateClassPayload = {
  id: number
  annotationTypeNames?: AnnotationType[]
  datasets?: AnnotationClassPayload['datasets']
  description?: string | null
  images?: AnnotationClassPayload['images']
  annotationClassImageUrl?: AnnotationClassPayload['annotation_class_image_url']
  metadata?: AnnotationClassPayload['metadata']
  name?: string
  granularity?: AnnotationSubTypeGranularity
}

export const useClasses = defineStore('classes', () => {
  const toast = useToast()
  const teamStore = useTeamStore()
  const featuresStore = useFeatureFlagsStore()
  const datasetStore = useDatasetStore()
  const { classesById, editorClassesById } = useClassesById()
  const annotationAttributeStore = useAnnotationAttributeStore()

  const typesStore = useAnnotationTypesStore()

  const currentDatasetId = computed(() => datasetStore.currentDataset?.id)

  /**
   * Checks if an annotation type is enabled.
   *
   * param {AnnotationType} type - The annotation type to check.
   * returns {boolean} Returns true if the annotation type is enabled, false otherwise.
   */
  const isAnnotationTypeEnabled = (type: AnnotationType): boolean => {
    const feature = FEATURE_ENABLED_TYPES[type]
    return !feature || featuresStore.featureFlags[feature]
  }

  /**
   * Available main annotation types, filters out the ones that are not enabled or not renderable.
   *
   * @type {MainAnnotationType[]}
   */
  const mainTypes = computed<MainAnnotationType[]>(() =>
    mainAnnotationTypes.filter((t) => isAnnotationTypeRenderable(t) && isAnnotationTypeEnabled(t)),
  )

  const teamClasses = ref<AnnotationClassPayload[]>([])

  /** Indicates whether the user has at least one class, regardless of whether it's filtered or not. */
  const hasClasses = computed(() => teamClasses.value.length > 0)

  type ClassesMapType = {
    dataset: Map<string, AnnotationClassPayload[]>
    unassigned: AnnotationClassPayload[]
    type: Record<string, number>
  }
  /**
   * Cycle through the classes only once to group classes by dataset, unassigned and type
   * This helps improve drastically the performance of the following computed arrays
   *
   * @type {ClassesMapType}
   */
  const classesMap = computed<ClassesMapType>(() => {
    const classesRecords: ClassesMapType = {
      dataset: new Map(),
      unassigned: [],
      type: {},
    }
    // cycle through all classes only once
    for (const cls of teamClasses.value) {
      for (const type of cls.annotation_types) {
        if (!classesRecords.type[type]) {
          classesRecords.type[type] = 0
        }
        classesRecords.type[type]++
      }
      for (const dataset of cls.datasets) {
        if (!classesRecords.dataset.has(String(dataset.id))) {
          classesRecords.dataset.set(String(dataset.id), [])
        }
        classesRecords.dataset.get(String(dataset.id))?.push(cls)
      }
      if (currentDatasetId.value && cls.datasets.every((d) => d.id !== currentDatasetId.value)) {
        classesRecords.unassigned.push(cls)
      }
    }
    return classesRecords
  })

  /**
   * Classes assigned to the current dataset.
   *
   * @type {AnnotationClassPayload[]}
   */
  const datasetClasses = computed<AnnotationClassPayload[]>(() => {
    if (!currentDatasetId.value) {
      return []
    }
    return classesMap.value.dataset.get(String(currentDatasetId.value)) || []
  })

  /**
   * Classes not assigned to the current dataset.
   *
   * @type {AnnotationClassPayload[]}
   */
  const unassignedTeamClasses = computed<AnnotationClassPayload[]>(() => {
    if (!currentDatasetId.value) {
      return teamClasses.value
    }
    return classesMap.value.unassigned
  })

  /**
   * Synchronizes the `classesById` composable with the latest set of classes from the store.
   * The resulting object is sealed to improve performance.
   *
   * @private
   * @returns {void}
   */
  const syncClassesById = (): void => {
    classesById.value = Object.seal(Object.fromEntries(teamClasses.value.map((c) => [c.id, c])))
    editorClassesById.value = Object.seal(
      Object.fromEntries(teamClasses.value.map((c) => [c.id, storeToEditorAnnotationClass(c)])),
    )
  }

  const datasetCRUD = useDatasetCRUD()

  /**
   * Removes the hotkey for a class.
   *
   * @param {AnnotationClassPayload} annClass - The class to remove the hotkey for.
   * @param {DatasetPayload} dataset - The dataset the class belongs to.
   * @returns {Promise<unknown>} - A promise that resolves when the hotkey is removed.
   */
  const removeHotKey = async (
    annClass: AnnotationClassPayload,
    dataset?: DatasetPayload,
  ): Promise<void> => {
    if (!dataset) {
      return
    }
    const { annotation_hotkeys: hotkeys } = dataset

    if (Object.values(hotkeys).includes(`select_class:${annClass.id}`)) {
      const annotationHotkeys = omitBy(hotkeys, (value) => value === `select_class:${annClass.id}`)
      const response = await datasetCRUD.updateDataset({ datasetId: dataset.id, annotationHotkeys })

      if (!response.ok) {
        toast.error({ meta: { title: `${annClass.name} hotkey was not removed` } })
        return
      }

      // Show notification for newly assigned hotkey
      toast.info({
        duration: 5000,
        meta: { title: `${annClass.name} hotkey unassigned` },
      })

      syncClassesById()
    }
  }

  /**
   * Updates the hotkey for a class.
   *
   * @param {AnnotationClassPayload} annClass - The class to update the hotkey for.
   * @param {string} hotkey - The new hotkey value.
   * @param {DatasetPayload} dataset - The dataset the class belongs to.
   * @returns {Promise<unknown>} - A promise that resolves when the hotkey is updated.
   */
  const updateHotkey = async (
    annClass: AnnotationClassPayload,
    hotkey: string,
    dataset?: DatasetPayload,
  ): Promise<void> => {
    if (!annClass || !dataset || !hotkey) {
      return
    }

    const hotkeys = cloneDeep(dataset.annotation_hotkeys)

    // Check if the annotation class already had a hotkey assigned
    const oldHotkeyId = Object.entries(hotkeys).find(
      (h) => h[1] === `select_class:${annClass.id}`,
    )?.[0]
    if (oldHotkeyId) {
      // In case there was a old hotkey remove it
      delete hotkeys[oldHotkeyId]
    }

    // Check if there was another class to which the same hotkey was assigned
    const dupClassId: number | null = hotkeys[hotkey]
      ? parseInt(hotkeys[hotkey].replace('select_class:', ''), 10)
      : null

    const newHotkeys = {
      ...hotkeys,
      [hotkey]: `select_class:${annClass.id}`,
    }

    const response = await datasetCRUD.updateDataset({
      datasetId: dataset.id,
      annotationHotkeys: newHotkeys,
    })

    if (!response.ok) {
      toast.error({ meta: { title: `${annClass.name} hotkey was not updated` } })
      return
    }

    // Show notification for newly assigned hotkey
    toast.info({
      duration: 5000,
      meta: { title: `${annClass.name} hotkey set to ${hotkey}` },
    })

    // Show notification for annotation class whose hotkey was unbound
    if (dupClassId) {
      const datasetCls = classesMap.value.dataset.get(String(dataset.id)) ?? []
      const dupAnnotationClass = datasetCls.find((c) => c.id === dupClassId)
      if (!dupAnnotationClass) {
        return
      }
      toast.info({
        duration: 5000,
        meta: { title: `${dupAnnotationClass.name} hotkey has been unbound` },
      })
    }

    syncClassesById()
  }

  const pushClass = (klass: AnnotationClassPayload): void => {
    const idx = teamClasses.value.findIndex((cl) => cl.id === klass.id)

    if (idx < 0) {
      teamClasses.value.push(klass)
    } else {
      teamClasses.value.splice(idx, 1, klass)
    }

    syncClassesById()
  }

  /**
   * Updates a class in the list.
   *
   * @param {StoreActionPayload<typeof updateAnnotationClass>} payload - The payload for updating
   * the class.
   * @returns {Promise<{ data: AnnotationClassPayload } & ParsedError>} A promise that resolves
   * to the updated class and possible error.
   */
  const updateClass = async (
    payload: UpdateClassPayload,
  ): Promise<ApiResponse<AnnotationClassPayload> | ParsedError> => {
    const original = classesById.value[payload.id]
    if (!original) {
      throw new Error("Cannot update class which isn't loaded")
    }

    // Initial params is just the id. All the other changes are optional, so are sent to
    // the backend only if they are actual changes.
    const params: Parameters<typeof updateAnnotationClass>[0] = { id: original.id }

    if (payload.annotationTypeNames) {
      const originalTypeNames = [...original.annotation_types].sort()
      const newTypeNames = [...payload.annotationTypeNames].sort()
      if (!isEqual(originalTypeNames, newTypeNames)) {
        const annotationTypeIds = typesStore.types
          .filter((t) => newTypeNames.includes(t.name))
          .map((t) => t.id)

        if (annotationTypeIds.length !== newTypeNames.length) {
          throw new Error('[useClasses/updateClass] Invalid type names specified')
        }
        params.annotation_type_ids = annotationTypeIds
      }
    }

    if (payload.name !== undefined && original.name !== payload.name) {
      params.name = payload.name
    }

    if (payload.description !== undefined && original.description !== payload.description) {
      params.description = payload.description
    }

    // For datasets and images, we shallow clone the arrays, so we can sort them
    // and then properly compare with each other. Not shallow cloning means we would
    // mutate the original arrays, which should not happen.
    if (payload.datasets && !isEqual([...original.datasets].sort(), [...payload.datasets].sort())) {
      params.datasets = payload.datasets
    }

    if (payload.images && !isEqual([...original.images].sort(), [...payload.images].sort())) {
      params.images = payload.images
    }
    if (
      payload.annotationClassImageUrl !== undefined &&
      payload.annotationClassImageUrl !== original.annotation_class_image_url
    ) {
      params.annotation_class_image_url = payload.annotationClassImageUrl || null
    }

    if (payload.metadata && !isEqual(original.metadata, payload.metadata)) {
      params.metadata = payload.metadata
    }

    const response = await updateAnnotationClass(params)

    if (response.ok) {
      pushClass(response.data)
      syncClassesById()
    }

    return response
  }

  /**
   * Exclude the passed class from the dataset.
   *
   * @param {AnnotationClassPayload} annotationClass - The annotation class to exclude.
   * @param {DatasetPayload} dataset - The dataset to exclude the class from.
   * @returns {void}
   */
  const excludeFromDataset = async (
    annotationClass: AnnotationClassPayload,
    dataset: DatasetPayload,
  ): Promise<void> => {
    const datasets = [...annotationClass.datasets]
    const idx = datasets.findIndex((d) => d.id === dataset.id)

    // No need to update the backend again
    if (idx < 0) {
      return
    }

    datasets.splice(idx, 1)

    const response = await updateAnnotationClass({ id: annotationClass.id, datasets })

    if (response.ok) {
      pushClass(response.data)
    }
  }

  /**
   * Includes an annotation class in a dataset.
   *
   * @param {AnnotationClassPayload} annotationClass - The annotation class payload.
   * @param {DatasetPayload} dataset - The dataset payload.
   */
  const includeInDataset = async (
    annotationClass: AnnotationClassPayload,
    dataset: DatasetPayload,
  ): Promise<void> => {
    const datasets = [...annotationClass.datasets]
    // No need to update the backend again
    if (datasets.find((d) => d.id === dataset.id)) {
      return
    }

    datasets.push({ id: dataset.id })

    const response = await updateAnnotationClass({ id: annotationClass.id, datasets })

    if (response.ok) {
      pushClass(response.data)
    }
  }

  /**
   * Creates a class.
   *
   * @template T - The type of the response.
   * @param {StoreActionPayload<typeof createAnnotationClass>} payload - The payload for creating
   * the class.
   * @returns {Promise<T>} A promise that resolves to the created class.
   */
  const createClass = async (
    payload: CreateClassPayload,
  ): Promise<ApiResponse<AnnotationClassPayload> | ParsedError> => {
    if (payload.annotationTypeNames.includes('skeleton')) {
      payload.metadata.skeleton = {
        nodes: [{ name: 'node', x: 0.5, y: 0.5 }],
        edges: [],
      }
    }

    if (!teamStore.currentTeam) {
      throw new Error('Creating a class requires current team to be set')
    }

    const annotationTypeIds = typesStore.types
      .filter((t) => payload.annotationTypeNames.includes(t.name))
      .map((t) => t.id)

    if (annotationTypeIds.length !== payload.annotationTypeNames.length) {
      throw new Error('Invalid class type specified')
    }

    const params: Parameters<typeof createRequest>[0] = {
      annotation_type_ids: annotationTypeIds,
      datasets: payload.datasets,
      description: payload.description,
      images: payload.images,
      metadata: payload.metadata,
      name: payload.name,
      team_slug: teamStore.currentTeam.slug,
    }

    const response = await createRequest(params)

    if ('error' in response) {
      toast.error({ meta: { title: `Error creating class "${payload.name}"` } })
      return response
    }

    if ('data' in response) {
      pushClass(response.data)
      syncClassesById()
    }

    return response
  }

  /**
   * Updates the annotation types of a class.
   *
   * @param {AnnotationClassPayload} annClass - The annotation class to update.
   * @param {AnnotationType[]} annotationTypes - The annotation types to add to the class.
   * @returns {Promise<{ data: AnnotationClassPayload } & ParsedError>} A promise that
   * resolves to the updated class and possible error.
   */
  const updateClassTypes = (
    annClass: AnnotationClassPayload,
    annotationTypes: AnnotationType[],
    granularity: AnnotationSubTypeGranularity = AnnotationSubTypeGranularity.SECTION,
  ): Promise<ApiResponse<AnnotationClassPayload> | ParsedError> => {
    const payload: UpdateClassPayload = {
      annotationTypeNames: uniq(concat(annClass.annotation_types, annotationTypes)),
      description: annClass.description,
      id: annClass.id,
      metadata: annClass.metadata,
      name: annClass.name,
      datasets: annClass.datasets,
      granularity,
    }
    return updateClass(payload)
  }

  const fatalError = useFatalError()
  const loadingStatus = ref<LoadingStatus>(LoadingStatus.Unloaded)

  const setClasses = (newClasses: AnnotationClassPayload[]): void => {
    teamClasses.value = newClasses
    syncClassesById()
  }

  /** Loads all team classes */
  const loadClasses = async (teamSlug: string): Promise<void | AnnotationClassPayload[]> => {
    if (!teamSlug) {
      throw new Error('Cannot load annotation classes without current team info')
    }

    loadingStatus.value = LoadingStatus.Loading

    try {
      const response = await loadRequest({ teamSlug, include_tags: true })

      loadingStatus.value = LoadingStatus.Loaded

      if (!response.ok) {
        fatalError.setFatalError(response.error)
        return
      }

      // Team changed while loading classes, aborting
      if (teamStore.currentTeam?.slug !== teamSlug) {
        fatalError.setFatalError('No currentTeam!')
        return
      }

      setClasses(response.data.annotation_classes)
      return response.data.annotation_classes
    } catch (e: unknown) {
      // Can this actually happen? I guess if it's a non-axios error, such as network down?
      fatalError.setFatalError(e)
    }
  }

  /** Loads the annotation attributes for a specific class */
  const loadClassAttributes = (classId: number): void => {
    annotationAttributeStore.loadClassAnnotationAttributes({ classId })
  }

  /**
   * Deletes multiple classes by id
   *
   * @param {number} teamId
   * @param {number[]} classIds The IDs of the classes to delete.
   * @param {number} deleteCount Count - TBC
   */
  const deleteClasses = async (
    teamId: number,
    classIds: number[],
    deleteCount: number,
  ): Promise<AxiosResponse | ParsedError> => {
    let response

    if (!teamId) {
      throw new Error('Cannot delete a class without current team id')
    }
    try {
      response = await api.remove(`teams/${teamId}/delete_classes`, {
        annotation_class_ids: classIds,
        annotations_to_delete_count: deleteCount,
      })
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }
      return parseError(error, errorMessages.ANNOTATION_CLASS_DELETE)
    }

    teamClasses.value = teamClasses.value.filter((c) => !classIds.includes(c.id))
    syncClassesById()

    return response
  }

  /**
   * Loads the usage count for a specific class.
   *
   * @param {number} classId - The ID of the class.
   * @returns {Promise<number | undefined>} - A promise that resolves to the usage count.
   */
  const getClassUsage = async (classId: number): Promise<number | undefined> => {
    if (!teamStore.currentTeam) {
      return undefined
    }

    const result = await loadClassUsage({
      annotation_class_ids: [classId],
      teamSlug: teamStore.currentTeam.slug,
    })

    return result.ok ? result.data.usage : undefined
  }

  const resetState = (): void => {
    teamClasses.value = []
    loadingStatus.value = LoadingStatus.Unloaded
  }

  return {
    mainTypes,
    hasClasses,
    isAnnotationTypeEnabled,
    isAnnotationTypeRenderable,
    teamClasses,
    datasetClasses,
    setClasses,
    unassignedTeamClasses,
    loadingStatus,
    createClass,
    updateClass,
    deleteClasses,
    updateClassTypes,
    excludeFromDataset,
    includeInDataset,
    loadClasses,
    loadClassAttributes,
    getClassUsage,
    updateHotkey,
    removeHotKey,
    resetState,
  }
})
