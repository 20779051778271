import type { AnnotationData } from '@/modules/Editor/AnnotationData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import type { VideoAnnotation } from '@/modules/Editor/models/annotation/types'
import type { View } from '@/modules/Editor/views/view'

export const updateVideoAnnotation = (
  view: View,
  annotation: VideoAnnotation,
  newData: AnnotationData,
): Annotation => {
  if (!view.isLoaded) {
    throw new Error('Expected video to be loaded')
  }

  const frameIndex = view.currentFrameIndex
  const { data } = inferVideoData(annotation, view.currentFrameIndex)

  const updatedAnnotation = shallowCloneAnnotation(annotation, {
    data: {
      ...annotation.data,
      frames: {
        ...annotation.data.frames,
        [frameIndex]: { ...data, ...newData },
      },
    },
  })

  return updatedAnnotation
}
