import { euclideanDistance } from '@/modules/Editor/algebra'
import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import {
  COMMENT_THREAD_SHADOW_BLUR,
  COMMENT_THREAD_SHADOW_COLOR,
  COMMENT_THREAD_SHADOW_OFFSET_Y,
} from '@/modules/Editor/comment'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import type { EditablePoint } from '@/modules/Editor/point'
import { subPoints } from '@/modules/Editor/point'
import type { IPoint } from '@/modules/Editor/point'
import { Rectangle } from '@/modules/Editor/rectangle'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import { moveCommentThreadVertexAction, moveCommentThreadAction } from '@/modules/Editor/actions'
import type { CommentThread } from '@/modules/Editor/iproviders/types'
import {
  Events as CommentManagerEvents,
  getThreadVertices,
} from '@/modules/Editor/managers/CommentManager'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import type { View } from '@/modules/Editor/views/view'

class InvalidStateError extends Error {
  constructor() {
    super('Invalid state')
  }
}

enum State {
  Inactive = 'Inactive',
  Initial = 'Initial',
  DownOverVertex = 'DownOverVertex',
  DownOverThread = 'DownOverThread',
  DownCreating = 'DownCreating',
  MovingVertex = 'MovingVertex',
  MovingThread = 'MovingThread',
  Creating = 'Creating',
  HoveringThread = 'HoveringThread',
  HoveringVertex = 'HoveringVertex',
}

const setState = (tool: CommentatorTool, state: State): void => {
  tool.state = state
}

interface CommentatorTool extends Tool {
  /**
   * Point when mousedown happened
   */
  initialPoint?: IPoint
  /**
   * Current mouse point
   */
  currentPoint?: IPoint

  /**
   * Holds reference to the vertex currently being moved
   */
  vertexBeingMoved?: EditablePoint

  /**
   * Current state of the state machine
   */
  state: State

  onStart(context: ToolContext, event: PointerEvent): CallbackStatus
  onMove(context: ToolContext, event: PointerEvent): CallbackStatus
  onEnd(context: ToolContext, event: PointerEvent): CallbackStatus
  onRender(context: ToolContext, view: View): void

  reset(): void
}

const isValidBox = (
  pointA: { x: number; y: number },
  pointB: { x: number; y: number },
): boolean => {
  const isStraightLine = pointA.x === pointB.x || pointA.y === pointB.y
  return !isStraightLine
}

const resolveDistanceMoved = (tool: CommentatorTool): number => {
  const { currentPoint, initialPoint } = tool
  if (!currentPoint || !initialPoint) {
    throw new InvalidStateError()
  }
  return euclideanDistance(currentPoint, initialPoint)
}

const resetMovedVertex = (tool: CommentatorTool): void => {
  if (!tool.vertexBeingMoved) {
    return
  }
  tool.vertexBeingMoved.isHighlighted = false
  tool.vertexBeingMoved.isSelected = false
  tool.vertexBeingMoved = undefined
}

// State.HoveringThread -> State.Initial
// State.HoveringVertex -> State.Initial
// State.Creating -> State.Initial
// State.MovingThread -> State.Initial
// State.MovingVertex -> State.Initial
const enterInitial = (tool: CommentatorTool, context: ToolContext): void => {
  selectCursor(EditorCursor.Commentator)
  resetMovedVertex(tool)

  context.editor.activeView.commentLayer.changed()
  setState(tool, State.Initial)
}

const enterInactive = (tool: CommentatorTool, context: ToolContext): void => {
  context.editor.activeView.commentManager.emit(CommentManagerEvents.THREAD_END_CREATION)
  enterInitial(tool, context)
  setState(tool, State.Inactive)
}

const leaveHovering = (tool: CommentatorTool, context: ToolContext): void => {
  const commentManager = context.editor.activeView.commentManager

  commentManager.unhighlightItem()
  resetMovedVertex(tool)

  enterInitial(tool, context)
}

// State.Initial -> State.HoveringThread
const enterHoveringThread = (
  tool: CommentatorTool,
  context: ToolContext,
  thread: CommentThread,
): void => {
  resetMovedVertex(tool)

  const commentManager = context.editor.activeView.commentManager

  commentManager.highlightItem(thread.id)

  context.editor.activeView.commentLayer.changed()
  setState(tool, State.HoveringThread)
}

// State.HoveringThread -> State.DownOverThread
const enterDownOverThread = (tool: CommentatorTool, context: ToolContext): void => {
  selectCursor(EditorCursor.Pointer)
  const commentManager = context.editor.activeView.commentManager

  const thread = commentManager.highlightedItem

  if (!thread) {
    throw new InvalidStateError()
  }

  commentManager.selectItem(thread.id)
  setState(tool, State.DownOverThread)
}

// State.DownOverThread -> (move distance > x)-> State.MovingThread
const enterMovingThread = (tool: CommentatorTool): void => {
  setState(tool, State.MovingThread)
}

const completeMovingThread = (tool: CommentatorTool, context: ToolContext): void => {
  const { currentPoint, initialPoint } = tool
  if (!currentPoint || !initialPoint) {
    throw new InvalidStateError()
  }

  const commentManager = context.editor.activeView.commentManager

  if (!commentManager.selectedItem) {
    throw new InvalidStateError()
  }

  const action = moveCommentThreadAction(context.editor, commentManager.selectedItem)
  context.editor.actionManager.do(action)

  setState(tool, State.Initial)
}

// State.HoveringThread -> State.HoveringVertex
const enterHoveringVertex = (
  tool: CommentatorTool,
  context: ToolContext,
  vertex: EditablePoint,
): void => {
  tool.vertexBeingMoved = vertex
  tool.vertexBeingMoved.isHighlighted = true
  context.editor.activeView.commentLayer.changed()
  setState(tool, State.HoveringVertex)
}

// State.HoveringVertex -> State.DownOverVertex
const enterDownOverVertex = (tool: CommentatorTool): void => {
  const vertex = tool.vertexBeingMoved
  if (!vertex) {
    throw new InvalidStateError()
  }
  vertex.isSelected = true
  selectCursor(EditorCursor.Pointer)
  setState(tool, State.DownOverVertex)
}

// State.DownOverVertex -> (move distance > x)-> State.MovingVertex
const enterMovingVertex = (tool: CommentatorTool): void => {
  setState(tool, State.MovingVertex)
}

const completeMovingVertex = (tool: CommentatorTool, context: ToolContext): void => {
  const { currentPoint, initialPoint } = tool
  if (!currentPoint || !initialPoint) {
    throw new InvalidStateError()
  }

  if (!isValidBox(currentPoint, initialPoint)) {
    return
  }

  const commentManager = context.editor.activeView.commentManager

  const thread = commentManager.selectedItem
  if (!thread) {
    throw new Error('Invalid commentator tool state')
  }

  const action = moveCommentThreadVertexAction(context.editor, thread)
  context.editor.actionManager.do(action)

  leaveHovering(tool, context)
}

// State.Initial -> State.DownCreating
const enterDownCreating = (tool: CommentatorTool, context: ToolContext): void => {
  const commentManager = context.editor.activeView.commentManager

  commentManager.deselectItem()

  setState(tool, State.DownCreating)
}

// State.DownCreating -> (move distance > x)-> State.Creating
const enterCreating = (tool: CommentatorTool): void => {
  setState(tool, State.Creating)
}

// State.DownOverThread -> State.Initial
// State.DownOverVertex -> State.Initial
export const openThread = (tool: CommentatorTool, context: ToolContext): void => {
  selectCursor(EditorCursor.Commentator)
  const commentManager = context.editor.activeView.commentManager

  if (!commentManager.highlightedItem) {
    throw new InvalidStateError()
  }

  commentManager.selectItem(commentManager.highlightedItem.id)
  setState(tool, State.Initial)
}

const completeCreating = (tool: CommentatorTool, context: ToolContext): void => {
  const { currentPoint, initialPoint } = tool
  if (!initialPoint || !currentPoint) {
    throw new InvalidStateError()
  }
  if (!isValidBox(initialPoint, currentPoint)) {
    return
  }

  const rect = new Rectangle(
    context.editor.activeView.camera.canvasViewToImageView(initialPoint),
    context.editor.activeView.camera.canvasViewToImageView(currentPoint),
  )

  const { commentManager } = context.editor.activeView

  commentManager.emit(CommentManagerEvents.THREAD_START_CREATION, {
    x: rect.topLeft.x,
    y: rect.topLeft.y,
    w: rect.topRight.x - rect.topLeft.x,
    h: rect.bottomLeft.y - rect.topLeft.y,
  })
  setState(tool, State.Initial)
}

// Happens in State.MovingThread, doesn't transition, but updates data
const moveThread = (tool: CommentatorTool, context: ToolContext): void => {
  const commentManager = context.editor.activeView.commentManager
  const thread = commentManager.selectedItem
  if (!thread) {
    throw new InvalidStateError()
  }

  const { currentPoint, initialPoint } = tool
  if (!currentPoint || !initialPoint) {
    throw new InvalidStateError()
  }

  const vertices = getThreadVertices(thread)
  const oldCenter = {
    x: vertices.topLeft.x + (vertices.bottomRight.x - vertices.topLeft.x) / 2,
    y: vertices.topLeft.y + (vertices.bottomRight.y - vertices.topLeft.y) / 2,
  }

  const currentImagePoint = context.editor.activeView.camera.canvasViewToImageView(currentPoint)
  const offset = subPoints(currentImagePoint, oldCenter)

  commentManager.moveBox(thread, offset)
  context.editor.activeView.commentLayer.changed()
}

export const moveVertex = (tool: CommentatorTool, context: ToolContext): void => {
  const commentManager = context.editor.activeView.commentManager
  const selectedCommentThread = commentManager.selectedItem
  const { vertexBeingMoved, currentPoint } = tool
  if (!vertexBeingMoved || !selectedCommentThread || !currentPoint) {
    throw new InvalidStateError()
  }

  const currentImagePoint = context.editor.activeView.camera.canvasViewToImageView(currentPoint)

  commentManager.moveVertex(selectedCommentThread, vertexBeingMoved, currentImagePoint)
  vertexBeingMoved.x = currentImagePoint.x
  vertexBeingMoved.y = currentImagePoint.y

  context.editor.activeView.commentLayer.changed()
}

export const updateNewThreadBox = (tool: CommentatorTool, context: ToolContext): void => {
  context.editor.activeView.commentLayer.changed()
}

export const commentatorTool: CommentatorTool = {
  state: State.Inactive,

  initialPoint: undefined,
  currentPoint: undefined,
  vertexBeingMoved: undefined,

  activate(context: ToolContext) {
    setupMouseButtonLoadout(context, { middle: true })

    enterInitial(this, context)

    selectCursor(EditorCursor.Commentator)

    context.editor.registerCommand('commentator.cancel', () => {
      enterInitial(this, context)
    })

    context.handles.push(...context.editor.onMouseDown((event) => this.onStart(context, event)))
    context.handles.push(...context.editor.onMouseMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onMouseUp((event) => this.onEnd(context, event)))

    context.handles.push(...context.editor.onTouchStart((event) => this.onStart(context, event)))
    context.handles.push(...context.editor.onTouchMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onTouchEnd((event) => this.onEnd(context, event)))

    const viewsOnRender = context.editor.viewsList.map((view) =>
      view.renderManager.onRender((view) => this.onRender(context, view)),
    )

    context.handles.push(...viewsOnRender)
  },

  /**
   * Handles the start of a mouse drag
   */
  onStart(context, event) {
    const eventPoint = resolveEventPoint(event)
    if (!eventPoint) {
      return CallbackStatus.Continue
    }

    this.initialPoint = eventPoint
    this.currentPoint = eventPoint

    if (this.state === State.Initial) {
      enterDownCreating(this, context)
      return CallbackStatus.Stop
    }

    if (this.state === State.HoveringThread) {
      enterDownOverThread(this, context)
      return CallbackStatus.Stop
    }

    if (this.state === State.HoveringVertex) {
      enterDownOverVertex(this)
      return CallbackStatus.Stop
    }

    return CallbackStatus.Continue
  },

  /**
   * Handles mouse move during drag
   */
  onMove(context, event) {
    const eventPoint = resolveEventPoint(event)
    if (!eventPoint) {
      return CallbackStatus.Continue
    }

    this.currentPoint = eventPoint

    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(eventPoint)
    const commentManager = context.editor.activeView.commentManager

    if (this.state === State.DownCreating && resolveDistanceMoved(this) > 5) {
      enterCreating(this)
      return CallbackStatus.Stop
    }

    const hihglightedCommentThreadIsEditable =
      !commentManager.highlightedItem?.issue_types?.length &&
      commentManager.highlightedItem?.author_id === context.editor.getCurrentUserId()

    if (
      this.state === State.DownOverThread &&
      resolveDistanceMoved(this) > 5 &&
      hihglightedCommentThreadIsEditable
    ) {
      enterMovingThread(this)
      return CallbackStatus.Stop
    }

    if (
      this.state === State.DownOverVertex &&
      resolveDistanceMoved(this) > 5 &&
      hihglightedCommentThreadIsEditable
    ) {
      enterMovingVertex(this)
      return CallbackStatus.Stop
    }

    if (this.state === State.MovingThread) {
      moveThread(this, context)
      return CallbackStatus.Stop
    }

    if (this.state === State.MovingVertex) {
      moveVertex(this, context)
      return CallbackStatus.Stop
    }

    if (this.state === State.Creating) {
      updateNewThreadBox(this, context)
      return CallbackStatus.Stop
    }

    const vertex = commentManager.findCommentThreadVertexAt(imagePoint)
    const thread = commentManager.findTopCommentThreadAt(imagePoint)

    if (this.state === State.Initial && thread) {
      enterHoveringThread(this, context, thread)
      return CallbackStatus.Stop
    }

    if (this.state === State.HoveringThread && vertex) {
      enterHoveringVertex(this, context, vertex)
      return CallbackStatus.Stop
    }

    if (this.state === State.HoveringThread && !thread) {
      leaveHovering(this, context)
      return CallbackStatus.Stop
    }

    if (this.state === State.HoveringVertex && !vertex && !thread) {
      leaveHovering(this, context)
      return CallbackStatus.Stop
    }

    if (this.state === State.HoveringVertex && !vertex && thread) {
      enterHoveringThread(this, context, thread)
      return CallbackStatus.Stop
    }

    return CallbackStatus.Continue
  },

  /**
   * Handles the end of a mouse drag
   *
   * This will result in the comment thread being moved, or a new comment thread initialized
   */
  onEnd(context) {
    if (this.state === State.DownOverThread || this.state === State.DownOverVertex) {
      openThread(this, context)
      return CallbackStatus.Stop
    }

    if (this.state === State.DownCreating) {
      const commentManager = context.editor.activeView.commentManager

      commentManager.deselectItem()

      context.editor.activeView.commentLayer.changed()
      context.editor.activeView.commentManager.emit(CommentManagerEvents.THREAD_END_CREATION)
      enterInitial(this, context)
      return CallbackStatus.Stop
    }

    if (this.state === State.Creating) {
      completeCreating(this, context)
      return CallbackStatus.Stop
    }

    if (this.state === State.MovingVertex) {
      completeMovingVertex(this, context)
      return CallbackStatus.Stop
    }

    if (this.state === State.MovingThread) {
      completeMovingThread(this, context)
      return CallbackStatus.Stop
    }

    return CallbackStatus.Continue
  },

  /**
   * Renders default new comment thread/selection rectangle.
   *
   * Runs when mouse drag started on a blank area of the canvas.
   */
  onRender(context: ToolContext, view: View) {
    if (this.state !== State.Creating) {
      return
    }

    const { currentPoint, initialPoint } = this
    if (!currentPoint || !initialPoint) {
      return
    }

    const ctx = view.commentLayer.context
    if (!ctx) {
      return
    }

    ctx.shadowColor = COMMENT_THREAD_SHADOW_COLOR
    ctx.shadowBlur = COMMENT_THREAD_SHADOW_BLUR
    ctx.shadowOffsetY = COMMENT_THREAD_SHADOW_OFFSET_Y

    ctx.strokeStyle = '#fff'
    ctx.lineWidth = 2
    ctx.strokeRect(
      initialPoint.x,
      initialPoint.y,
      currentPoint.x - initialPoint.x,
      currentPoint.y - initialPoint.y,
    )
  },

  deactivate(context: ToolContext) {
    context.editor.activeView.commentManager.deselectItem()
    enterInactive(this, context)
  },

  reset() {
    this.initialPoint = undefined
    this.currentPoint = undefined
    this.vertexBeingMoved = undefined
  },
}
