/*
 * The difference between team property and property is now blurry because of recent changes and
 * ongoing discussions.
 * A team property was supposed to be a property that is shared across the team and can be used
 * in multiple classes (1 to many relation).
 * Properties are now 1 to 1 with classes, effectively removing the concept of team-level property.
 * https://www.notion.so/v7labs/Properties-implementation-revision-f1cee2147fef41f2a253c9070cf31091
 * Although this is likely to be the final implementation, the properties functionality will be
 * reviewed after the first beta release and a decision will be made.
 * Depending on the outcome, the TeamProperty type might become redundant and be removed.
 *
 * Ticket: https://linear.app/v7labs/issue/ANN-3021/refactor-property-types
 */
export const TeamPropertyType = {
  SINGLE_SELECT: 'single_select',
  MULTI_SELECT: 'multi_select',
  TEXT: 'text',
} as const
export type TeamPropertyType = (typeof TeamPropertyType)[keyof typeof TeamPropertyType]

/**
 * Property granularity description:
 * `section`: normal property, at annotation level, that can be set per frame
 * `annotation`: normal annotation property, but the value will be static for the entire annotation,
 * so once changed in any frame, it will change on every frame (with a global value)
 * `item`: this property is set at item level, not related to annotations. It will appear on the
 * right sidebar as a "question"
 */
export const TeamPropertyGranularity = {
  SECTION: 'section',
  ITEM: 'item',
  ANNOTATION: 'annotation',
} as const
export type TeamPropertyGranularity =
  (typeof TeamPropertyGranularity)[keyof typeof TeamPropertyGranularity]

export interface TeamPropertyValue {
  id: string
  color: string
  position: number
  value: string
}

export interface TeamProperty {
  id: string
  name: string
  description?: string
  annotation_class_id?: number
  dataset_ids?: number[]
  required: boolean
  property_values: TeamPropertyValue[]
  slug: string
  team_id: number
  type: TeamPropertyType
  granularity: TeamPropertyGranularity
  inserted_at: string
}

export type TeamItemProperty = Omit<TeamProperty, 'annotation_class_id'> & {
  dataset_ids: number[]
  granularity: 'item'
}

export type TeamAnnotationProperty = TeamProperty & { values: TeamPropertyValue[] }

export type Property = Pick<
  TeamProperty,
  | 'id'
  | 'name'
  | 'type'
  | 'required'
  | 'annotation_class_id'
  | 'description'
  | 'granularity'
  | 'dataset_ids'
  | 'inserted_at'
> & {
  values: PropertyValue[]
}

export type UnsavedProperty = Omit<Property, 'id' | 'annotation_class_id' | 'inserted_at'> & {
  annotation_class_id?: number
}

// This type is for the dummy property component that is used to create a new property
// For this reason it can include both unsaved properties or real properties
export type MaybeProperty = Property | UnsavedProperty

export type PropertyValue = Pick<TeamPropertyValue, 'id'> & {
  name: TeamPropertyValue['value']
  color: string
  position: number
  error?: string
}

export type UnsavedPropertyValue = Partial<PropertyValue> & {
  id: TeamPropertyValue['id']
  dummy?: boolean
}

// This type is for the dummy property value component that is used to create new property values
// For this reason it can include both unsaved property values or real property values
export type MaybePropertyValue = PropertyValue | UnsavedPropertyValue

export const isTeamPropertyType = (propertyType: string): propertyType is TeamPropertyType =>
  propertyType === TeamPropertyType.MULTI_SELECT ||
  propertyType === TeamPropertyType.SINGLE_SELECT ||
  propertyType === TeamPropertyType.TEXT

export const isTeamProperty = (property: unknown): property is TeamProperty =>
  !!property &&
  typeof property === 'object' &&
  'type' in property &&
  isTeamPropertyType(property.type as string)

export const isTeamItemProperty = (property: unknown): property is TeamItemProperty =>
  isTeamProperty(property) && property.granularity === TeamPropertyGranularity.ITEM
