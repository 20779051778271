import { createSharedComposable } from '@vueuse/core'
import { ref, onBeforeUnmount, onMounted } from 'vue'
import type { Ref } from 'vue'

import { useEditorV2 } from '@/composables/useEditorV2/useEditorV2'
import type { Layout } from '@/modules/Editor/layout'
import { useWorkviewSettingsStore } from '@/pinia/useWorkviewSettingsStore'
import { LayoutEvents } from '@/modules/Editor/eventBus'

export type EditorLayout = {
  layout: Layout
}

const useEditorLayout = (): Ref<EditorLayout | undefined> => {
  const { editor } = useEditorV2()
  const workviewSettingsStore = useWorkviewSettingsStore()

  const layout = ref(
    editor.value
      ? {
          layout: editor.value.layout,
        }
      : undefined,
  ) as Ref<EditorLayout | undefined>

  const handleLayoutMutation = (newLayout: Layout, slotName?: string): void => {
    layout.value = { layout: newLayout }

    // on layout change we need to re-activate the current active otherwise it won't be possible
    // to PAN/ZOOM until a new view's clicked on
    const activeSlotName = slotName || workviewSettingsStore.activeSlotName
    const view = activeSlotName ? editor.value?.layout.getViewByName(activeSlotName) : undefined

    // view might not be available yet at this point, one case is when landing on a reformatted view
    // that is not created yet
    if (!view) {
      return
    }
    editor.value?.layout.setActiveView(view?.id || editor.value?.layout.viewsList[0]?.id)
  }

  const handleLayoutChanges = ({ layout, slotName }: { layout: Layout; slotName?: string }): void =>
    handleLayoutMutation(layout, slotName)

  onMounted(() => {
    LayoutEvents.changed.on(handleLayoutChanges)
    LayoutEvents.initialised.on(handleLayoutChanges)
    LayoutEvents.setViewport.on(handleLayoutChanges)
    LayoutEvents.enterFullscreen.on(handleLayoutChanges)
    LayoutEvents.exitFullscreen.on(handleLayoutChanges)
    LayoutEvents.enterMultiPlanarMode.on(handleLayoutChanges)
    LayoutEvents.exitMultiPlanarMode.on(handleLayoutChanges)
  })

  onBeforeUnmount(() => {
    LayoutEvents.changed.off(handleLayoutChanges)
    LayoutEvents.initialised.off(handleLayoutChanges)
    LayoutEvents.setViewport.off(handleLayoutChanges)
    LayoutEvents.enterFullscreen.off(handleLayoutChanges)
    LayoutEvents.exitFullscreen.off(handleLayoutChanges)
    LayoutEvents.enterMultiPlanarMode.off(handleLayoutChanges)
    LayoutEvents.exitMultiPlanarMode.off(handleLayoutChanges)

    layout.value = undefined
  })

  return layout as Ref<EditorLayout | undefined>
}

export const useSharedEditorLayout = createSharedComposable(useEditorLayout)
