import { WindAuthAction } from '@/backend/darwin/WindAuthAction'
import type { ApiResult } from '@/backend/darwin/types'
import { errorMessages } from '@/backend/error'

import { withAuth, withoutAuth } from './api'
import type { RunningSessionExpand, RunningSessionPayload } from './types'

type Params = {
  expand?: RunningSessionExpand[]
  includePublic?: boolean
  teamId?: number
  type?: string
}

export const loadRunningSessions = (
  payload: Params,
): Promise<ApiResult<RunningSessionPayload[]>> => {
  const { expand, includePublic: include_public, teamId, type } = payload

  const params = {
    ...(expand && { expand }),
    ...(include_public && { include_public }),
    ...(type && { type }),
  }

  const path = 'running_sessions'

  if (!teamId) {
    return withoutAuth((client) => client.get(path, { params }), errorMessages.NEURAL_MODEL_DATA)
  }

  const authParams = { action: WindAuthAction.ViewModels, teamId }
  return withAuth<RunningSessionPayload[]>(
    authParams,
    (client) => client.get<RunningSessionPayload[]>(path, { params }),
    errorMessages.NEURAL_MODEL_DATA,
  )
}
