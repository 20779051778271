import type { Polygon } from '@/modules/Editor/polygonOperations'

export const getNewPolygon = (regions: Polygon['regions']): Polygon => {
  regions = regions ? regions : []

  return {
    inverted: false,
    regions,
  }
}
