/**
 * Resolve an environment variable at runtime
 *
 * Reason we need this is, locally or at build time we export env variables and
 * use them during build using import.meta.env.{VAR_NAME}
 *
 * However, at deploy time, AFTER the build is done, there is an extra step at the
 * end which takes the outputted .js and does a search and replace of ${VARNAME}
 * strings with the proper value.
 *
 * So this function will return the value at build time if any, then fall back to
 * value at deploy time.
 */
const resolveVariable = (buildTime: string | undefined, deployTime: string): string | null => {
  // build time variable starts with 'VUE_APP_' and is replaced at build time
  // by webpack. We return that variable if available
  if (buildTime && buildTime !== '') {
    return buildTime
  }
  // deploy time variable starts with '$' and is replaced by `docker-entrypoint.sh` at deploy
  if (deployTime[0] !== '$' && deployTime !== '') {
    return deployTime
  }

  return null
}

export const GOOGLE_CLIENT_ID = resolveVariable(
  import.meta.env.VUE_APP_GOOGLE_SSO_CLIENT_ID,
  '$GOOGLE_SSO_CLIENT_ID',
)
/** Base URL of the Darwin API */
export const BASE_API = resolveVariable(import.meta.env.VUE_APP_BASE_API, '$BASE_API') || ''

/** Base URL of the frontend */
export const BASE_URL = resolveVariable(import.meta.env.BASE_URL, '$BASE_URL') || ''

/**
 * The environment the app is running in - staging, production, development, etc
 */
export const ENVIRONMENT = resolveVariable(import.meta.env.VUE_APP_ENVIRONMENT, '$ENVIRONMENT')

/**
 * The environment the frontend code was built in.
 * Staging, Production and DeployBot all have the same build environment - production
 */
export const BUILD_ENVIRONMENT: 'production' | 'performance_test' | 'development' = import.meta.env
  .NODE_ENV
export const isProductionBuild = BUILD_ENVIRONMENT === 'production'

export const SENTRY_DSN = resolveVariable(import.meta.env.VUE_APP_SENTRY_DSN, '$SENTRY_DSN')

/** SHA of the latest build */
export const BUILD = resolveVariable(import.meta.env.VUE_APP_BUILD, '$BUILD')

export const WIND_API = resolveVariable(import.meta.env.VUE_APP_WIND_API, '$WIND_API') || ''

export const FROALA_KEY = resolveVariable(import.meta.env.VUE_APP_FROALA_KEY, '$FROALA_KEY')

export const INTERCOM_APP_ID = resolveVariable(
  import.meta.env.VUE_APP_INTERCOM_APP_ID,
  '$INTERCOM_APP_ID',
)
