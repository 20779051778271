import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { CustomerPlan } from './planTypes'

export const usePlanStore = defineStore('plan', () => {
  const customerPlan = ref<CustomerPlan | null>(null)
  const hasCustomerPlanLoaded = ref(false)
  const customerPlanFetchError = ref<string | null>(null)

  return {
    customerPlan,
    hasCustomerPlanLoaded,
    customerPlanFetchError,
  }
})
