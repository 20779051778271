import type { Range } from '@/modules/Editor/types'

/**
 * Given a complex polygon, finds all the ranges of each path.
 *
 * @param polygonPath
 * @returns The ranges of each path.
 */
export const getRangeOfPolygonRegion = (region: number[][]): Range => {
  // Here a region is an array of 2D points making up a single polygon.
  let minX = Infinity
  let maxX = -Infinity
  let minY = Infinity
  let maxY = -Infinity

  region.forEach(([x, y]) => {
    if (x < minX) {
      minX = x
    }
    if (x > maxX) {
      maxX = x
    }
    if (y < minY) {
      minY = y
    }
    if (y > maxY) {
      maxY = y
    }
  })

  return {
    minX,
    maxX,
    minY,
    maxY,
  }
}
