import type { Raster } from '@/modules/Editor/models/raster/Raster'
import type { RasterBufferAccessor } from '@/modules/Editor/models/raster/RasterBufferAccessor'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'
import { assertVoxelRaster } from '@/modules/Editor/models/raster/assertVoxelRaster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import { isReformattedDICOMView } from '@/modules/Editor/utils/radiology/isReformattedDicomView'
import { getRasterPlaneFromRadiologySlotName } from '@/modules/Editor/utils/raster/getRasterPlaneFromRadiologySlotName'
import { getRasterBufferAccessorForPlane } from '@/modules/Editor/utils/raster/getRasterBufferAccessorForPlane'
import type { View } from '@/modules/Editor/views/view'

/**
 * Given a particular view and its assocaited raster, gets the RasterBufferAccessor for this view.
 * For any primary view, this will just be the accessor to the in frame.
 *
 * For a secondary view, this returns an accessor which provides and interface to read/write
 * to/from an orthogonal cut of the VoxelRaster.
 */
export const getRasterBufferAccessorForView = (
  view: View,
  raster: Raster,
  frameIndex?: number,
): RasterBufferAccessor => {
  if (isReformattedDICOMView(view)) {
    const voxelRaster = assertVoxelRaster(raster)

    const { slot_name, metadata } = view.fileManager.file
    const plane = getRasterPlaneFromRadiologySlotName(slot_name, metadata)

    if (!plane) {
      throw new Error('No plane found for given slot name')
    }

    return getRasterBufferAccessorForPlane(
      voxelRaster,
      plane,
      frameIndex !== undefined ? frameIndex : view.currentFrameIndex,
    )
  }

  if (
    (raster.type === RasterTypes.VIDEO || raster.type === RasterTypes.VOXEL) &&
    frameIndex !== undefined
  ) {
    const videoRaster = assertVideoRaster(raster)
    return videoRaster.getBufferForEdit(frameIndex)
  }

  return raster.getActiveBufferForEdit()
}
