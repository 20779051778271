import type { VoxelRasterDimensions } from '@/modules/Editor/models/raster/VoxelRaster'
import { getPrimaryViewFromView } from '@/modules/Editor/plugins/mask/utils/shared/getPrimaryViewFromView'
import type { View } from '@/modules/Editor/views/view'

export const getVoxelRasterDimensions = (view: View): VoxelRasterDimensions | undefined => {
  const primaryView = getPrimaryViewFromView(view)
  if (!primaryView) {
    return
  }

  const { metadata } = primaryView.fileManager.file
  if (metadata === undefined) {
    return
  }

  const width = metadata.pixelWidth ?? metadata.width
  const height = metadata.pixelHeight ?? metadata.height
  const depth = primaryView.totalFrames

  return { width, height, depth }
}
