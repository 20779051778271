import type { FrameData } from '@/core/annotations'
import type { AnnotationData } from '@/modules/Editor/AnnotationData'
import type { InferenceMetadata } from '@/modules/Editor/backend'

export const inferenceSerializer = {
  serialize(data: AnnotationData & { inference?: InferenceMetadata }): FrameData {
    if (!data.inference) {
      return {}
    }

    return {
      inference: {
        confidence: data.inference.confidence,
        model: data.inference.model,
      },
    }
  },

  deserialize(rawData: FrameData): InferenceMetadata | null {
    if (!rawData.inference) {
      return null
    }
    return {
      confidence: rawData.inference.confidence,
      model: rawData.inference.model,
    }
  },
}
