import type { AnnotationType } from '@/core/annotationTypes'
import type { FrameData, SequenceData } from '@/core/annotations'
import type { AnnotationData } from '@/modules/Editor/AnnotationData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { createSubAnnotation } from '@/modules/Editor/models/annotation/annotationFactories'

import { normalizeSubAnnotations } from './normalize'
import type { ImageSubAnnotation, VideoSubAnnotations } from './types'

export const initializeVideoSubAnnotations = (
  annotation: Annotation,
  data: SequenceData,
): VideoSubAnnotations => {
  const normalizedSubAnnotations = normalizeSubAnnotations(data)
  const subAnnotations: VideoSubAnnotations = { frames: {} }

  for (const [index, normalizedSubAnnotation] of Object.entries(normalizedSubAnnotations)) {
    subAnnotations.frames[index] = []
    normalizedSubAnnotation.forEach(
      ({ type, data }: { type: AnnotationType; data: AnnotationData | null }) => {
        if (!data) {
          return
        }

        const subAnnotation = createSubAnnotation({
          parent: annotation,
          data,
          type,
        })

        subAnnotations.frames[index]?.push(subAnnotation)
      },
    )
  }

  return subAnnotations
}

export const initializeSubAnnotations = (
  annotation: Annotation,
  data: FrameData,
): ImageSubAnnotation[] => {
  const normalizedSubAnnotations = normalizeSubAnnotations(data)
  const subAnnotations: ImageSubAnnotation[] = []

  if (!Array.isArray(normalizedSubAnnotations)) {
    return subAnnotations
  }

  normalizedSubAnnotations.forEach(({ type, data }) => {
    if (!data) {
      return
    }
    const subAnnotation = createSubAnnotation({ parent: annotation, data, type })
    subAnnotations.push(subAnnotation)
  })

  return subAnnotations
}
