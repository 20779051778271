import { DEFAULT_LINE_WIDTH, DEFAULT_VERTEX_SIZE } from '@/modules/Editor/config'
import type { RenderingCanvas, RenderingContext2D } from '@/modules/Editor/types'
import { calcLineWidth } from '@/modules/Editor/graphicsV2/calcLineWidth'
import { drawByType } from '@/modules/Editor/graphicsV2/drawByType'
import { drawVerticesByType } from '@/modules/Editor/graphicsV2/drawVerticesByType'
import { fillStyle } from '@/modules/Editor/graphicsV2/fillStyle'
import { strokeStyle } from '@/modules/Editor/graphicsV2/strokeStyle'
import type { RenderableItemWithState } from '@/modules/Editor/models/layers/object2D'
import type { CloseViewCanvas } from '@/modules/Editor/models/layers/optimisedLayer/closeViewCanvas/closeViewCanvas'
import {
  CACHED_CANVAS_PADDING,
  MAX_CANVAS_AREA,
} from '@/modules/Editor/models/layers/optimisedLayer/configs'
import {
  DEFAULT_BORDER_OPACITY,
  DEFAULT_OPACITY,
} from '@/modules/Editor/models/layers/optimisedLayer/configs'
import type { Box, CanvasFillRule, RenderFilters } from '@/modules/Editor/models/layers/types'
import { setContext } from '@/services/sentry'

import type { ICachedCanvas } from './ICachedCanvas'

const STROKE_TRACKER_MAGNIFICATION = 10

export class CachedCanvas implements ICachedCanvas {
  protected cachedCanvasScale = 1
  protected cameraScale = 1
  protected filters: RenderFilters = {
    opacity: DEFAULT_OPACITY,
    borderOpacity: DEFAULT_BORDER_OPACITY,
  }

  protected _canvas: RenderingCanvas | null
  protected _context: RenderingContext2D | null

  /**
   * Used to simplify tracking of the item under the cursor
   */
  private _renderedItemsMap: Map<string, Path2D> = new Map()

  constructor(canvas: RenderingCanvas, ctx: RenderingContext2D) {
    this._canvas = canvas
    this._context = ctx
  }

  get canvas(): RenderingCanvas | null {
    return this._canvas
  }

  get context(): RenderingContext2D | null {
    return this._context
  }

  setScale(scale: number): void {
    this.cachedCanvasScale = scale
  }

  setCameraScale(scale: number): void {
    this.cameraScale = scale
  }

  setCanvasSize(w: number, h: number): void {
    if (!this.canvas) {
      return
    }

    const area = w * h
    if (area > MAX_CANVAS_AREA) {
      setContext('error', { area })
      throw new Error('Canvas size exceeds the maximum area')
    }

    this.canvas.width = w
    this.canvas.height = h
  }

  setFilters(filters: RenderFilters): void {
    this.filters = filters
  }

  clearCanvas(x = 0, y = 0, w = this.canvas?.width, h = this.canvas?.height): void {
    if (!this.context) {
      return
    }
    if (!w || !h) {
      return
    }
    this.context.clearRect(x, y, w, h)
  }

  insertCanvas(
    image: RenderingCanvas | ImageBitmap,
    x = 0,
    y = 0,
    w = this.canvas?.width,
    h = this.canvas?.width,
  ): void {
    if (!image.width || !image.height) {
      return
    }
    if (!this.context) {
      return
    }
    if (!w || !h) {
      return
    }

    this.context.drawImage(image, x, y, w, h)
  }

  public drawItemsInBox(items: RenderableItemWithState[], box: Box): void {
    // NOTE: Since canvas width/height is Integer. We need to control what we send to it.
    // Otherwise, it will cut the float in an unpredictable way.

    // Clear item bbox section from the cached canvas
    this.clearCanvasWithScale(
      box.x + CACHED_CANVAS_PADDING,
      box.y + CACHED_CANVAS_PADDING,
      box.width,
      box.height,
    )

    let canvas: OffscreenCanvas | HTMLCanvasElement | undefined

    const width = Math.ceil(box.width * this.cachedCanvasScale)
    const height = Math.ceil(box.height * this.cachedCanvasScale)

    if (typeof OffscreenCanvas !== 'undefined') {
      canvas = new OffscreenCanvas(width, height)
    } else {
      canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
    }

    if (!canvas) {
      return
    }

    const ctx: RenderingContext2D | null = canvas.getContext('2d') as RenderingContext2D

    if (!ctx) {
      return
    }

    for (const item of items) {
      try {
        ctx.save()
        ctx.scale(this.cachedCanvasScale, this.cachedCanvasScale)
        ctx.translate(-box.x, -box.y)

        const path2D = drawByType(ctx, item.type, item.data, {
          fillColor: fillStyle(item.color, this.filters.opacity, false, false, false),
          strokeColor: strokeStyle(item.color, this.filters.borderOpacity, false, false),
          isSelected: !!item.state.isSelected,
          isHighlighted: !!item.state.isHighlighted,
          lineWidth: calcLineWidth(DEFAULT_LINE_WIDTH, this.cameraScale),
          pointSize: DEFAULT_VERTEX_SIZE / this.cameraScale,
          scale: this.cameraScale,
        })
        if (path2D) {
          this._renderedItemsMap.set(item.id, path2D)
        }

        drawVerticesByType(ctx, item.type, item.data, {
          fillColor: fillStyle(item.color, this.filters.opacity, false, false, false),
          strokeColor: strokeStyle(item.color, this.filters.borderOpacity, false, false),
          isSelected: !!item.state.isSelected,
          isHighlighted: !!item.state.isHighlighted,
          lineWidth: calcLineWidth(DEFAULT_LINE_WIDTH, this.cameraScale),
          pointSize: DEFAULT_VERTEX_SIZE / this.cameraScale,
        })

        ctx.restore()
      } catch (e) {
        setContext('error', { error: e })
        console.error('V2 cachedCanvas failed to render item in box')
      }
    }

    this.insertCanvasWithScale(
      canvas,
      box.x + CACHED_CANVAS_PADDING,
      box.y + CACHED_CANVAS_PADDING,
      box.width,
      box.height,
    )
    if (canvas && 'remove' in canvas) {
      canvas.remove()
    }
  }

  insertCloseViewCanvas(closeViewCanvas: CloseViewCanvas): void {
    if (!closeViewCanvas.canvas) {
      return
    }
    // Clear viewport section from the cached canvas
    this.clearCanvasWithScale(
      closeViewCanvas.lastHQOffset.x / closeViewCanvas.lastHQScale + CACHED_CANVAS_PADDING,
      closeViewCanvas.lastHQOffset.y / closeViewCanvas.lastHQScale + CACHED_CANVAS_PADDING,
      closeViewCanvas.canvas.width / closeViewCanvas.lastHQScale,
      closeViewCanvas.canvas.height / closeViewCanvas.lastHQScale,
    )

    // Insert HQ canvas into the cached canvas to keep fresh render.
    this.insertCanvasWithScale(
      closeViewCanvas.canvas,
      closeViewCanvas.lastHQOffset.x / closeViewCanvas.lastHQScale + CACHED_CANVAS_PADDING,
      closeViewCanvas.lastHQOffset.y / closeViewCanvas.lastHQScale + CACHED_CANVAS_PADDING,
      closeViewCanvas.canvas.width / closeViewCanvas.lastHQScale,
      closeViewCanvas.canvas.height / closeViewCanvas.lastHQScale,
    )
  }

  clearCanvasWithScale(x: number, y: number, w: number, h: number): void {
    this.clearCanvas(
      Math.floor(x * this.cachedCanvasScale),
      Math.floor(y * this.cachedCanvasScale),
      Math.ceil(w * this.cachedCanvasScale),
      Math.ceil(h * this.cachedCanvasScale),
    )
  }

  insertCanvasWithScale(
    canvas: RenderingCanvas | ImageBitmap,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    this.insertCanvas(
      canvas,
      Math.floor(x * this.cachedCanvasScale),
      Math.floor(y * this.cachedCanvasScale),
      Math.ceil(w * this.cachedCanvasScale),
      Math.ceil(h * this.cachedCanvasScale),
    )
  }

  drawItem(item: RenderableItemWithState): {
    path2D: Path2D | undefined
    verticesMap?: Map<number, Path2D>
  } {
    if (!this.context) {
      return { path2D: undefined }
    }
    const path2D = drawByType(this.context, item.type, item.data, {
      fillColor: fillStyle(
        item.color,
        this.filters.opacity,
        false,
        !!item.state.isHighlighted,
        !!item.state.isSelected,
      ),
      strokeColor: strokeStyle(
        item.color,
        this.filters.borderOpacity,
        false,
        !!item.state.isSelected,
      ),
      lineWidth: calcLineWidth(DEFAULT_LINE_WIDTH, this.cameraScale),
      pointSize: DEFAULT_VERTEX_SIZE / this.cameraScale,
      scale: this.cameraScale,
    })
    // We need to render vertices here only for the skeleton annotation type
    // All other annotation types can render vertices only for the active state.
    const verticesDrawResult = drawVerticesByType(this.context, item.type, item.data, {
      fillColor: fillStyle(
        item.color,
        this.filters.opacity,
        false,
        !!item.state.isHighlighted,
        !!item.state.isSelected,
      ),
      strokeColor: strokeStyle(
        item.color,
        this.filters.borderOpacity,
        false,
        !!item.state.isSelected,
      ),
      lineWidth: calcLineWidth(DEFAULT_LINE_WIDTH, this.cameraScale),
      pointSize: DEFAULT_VERTEX_SIZE / this.cameraScale,
    })

    if (!verticesDrawResult) {
      return { path2D }
    }

    const { compoundPath, verticesMap } = verticesDrawResult

    compoundPath && path2D?.addPath(compoundPath)

    return {
      path2D,
      verticesMap,
    }
  }

  drawAll(items: RenderableItemWithState[]): Promise<Map<string, Path2D>> {
    if (!this.context) {
      return Promise.resolve(new Map())
    }

    this.clearCanvas()

    const itemPath2DMap = new Map<string, Path2D>()

    // Renders all items on the cached canvas.
    for (const item of items) {
      try {
        this.context.save()
        this.context.scale(this.cachedCanvasScale, this.cachedCanvasScale)
        this.context.translate(CACHED_CANVAS_PADDING, CACHED_CANVAS_PADDING)

        // Render item using cached canvas/context
        const res = this.drawItem(item)

        const { path2D } = res

        if (path2D) {
          itemPath2DMap.set(item.id, path2D)
        }

        this.context.restore()
      } catch (e) {
        setContext('error', { error: e })
        console.error('V2 cachedCanvas failed to render item')
        continue
      }
    }

    this._renderedItemsMap = itemPath2DMap

    return Promise.resolve(itemPath2DMap)
  }

  isPointInStroke(id: RenderableItemWithState['id'], x: number, y: number): Promise<boolean> {
    if (!this.context) {
      return Promise.resolve(false)
    }

    const itemPath2D = this._renderedItemsMap.get(id)
    let res: boolean = false

    this.context.save()
    this.context.lineWidth = (DEFAULT_LINE_WIDTH * STROKE_TRACKER_MAGNIFICATION) / this.cameraScale

    if (itemPath2D) {
      res = this.context.isPointInStroke(itemPath2D, x, y)
    }

    this.context.restore()

    return Promise.resolve(res)
  }

  isPointInPath(
    id: RenderableItemWithState['id'],
    x: number,
    y: number,
    fillRule?: CanvasFillRule,
  ): Promise<boolean> {
    if (!this.context) {
      return Promise.resolve(false)
    }

    const itemPath2D = this._renderedItemsMap.get(id)
    if (!itemPath2D) {
      return Promise.resolve(false)
    }

    return Promise.resolve(this.context.isPointInPath(itemPath2D, x, y, fillRule))
  }

  cleanup(): void {
    this._renderedItemsMap.clear()
  }

  destroy(): void {
    // We can't just use `instanceof` since this class
    // can be instantiated from the browser and WebWorker
    if (this._canvas && 'remove' in this._canvas) {
      this._canvas?.remove()
    }
    this._canvas = null
    this._context = null
  }
}
