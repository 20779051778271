export const FilterOperator = {
  AllOf: 'all_of',
  AnyOf: 'any_of',
  NoneOf: 'none_of',
  Contains: 'contains',
  Prefix: 'prefix',
  Equals: 'equals',
} as const

export type FilterOperator = (typeof FilterOperator)[keyof typeof FilterOperator]
