import { createSharedComposable } from '@vueuse/core'
import { ref } from 'vue'

import type { PartialRecord } from '@/core/helperTypes'
import type { AnnotationClassPayload } from '@/store/types'
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'

export const useClassesById = createSharedComposable(() => {
  const classesById = ref<PartialRecord<number, AnnotationClassPayload>>({})

  const editorClassesById = ref<PartialRecord<number, AnnotationClass>>({})

  return { classesById, editorClassesById }
})
