import md5 from 'blueimp-md5'

import type { V2DatasetItemPayload } from '@/store/types/V2DatasetItemPayload'
import { parseRGBA, getRGBAColorHash, rgbaString } from '@/uiKit/colorPalette'
import type { RGBA } from '@/uiKit/colorPalette'

type Colorable = {
  metadata: {
    _color: string
  }
  name: string
}

export const classColor = <T extends Colorable>(annotationClass: T): RGBA =>
  annotationClass.metadata._color
    ? parseRGBA(annotationClass.metadata._color)
    : getRGBAColorHash(annotationClass.name)

export const classColorRGBAString = <T extends Colorable>(annotationClass: T): string =>
  rgbaString(classColor(annotationClass))

export const chromaHash = (value: string, bars: number = 3): string[] => {
  const hash = md5(value)
  const colors = hash.match(/([\dABCDEF]{6})/gi)
  return colors?.slice(0, bars) || []
}

export const resolveSlotThumbnailV2 = (
  item: V2DatasetItemPayload,
  slotName: string,
): string | null => {
  if (!item.slots?.length) {
    return null
  }

  const slot = item.slots.find((slot) => slot.slot_name === slotName)

  if (!slot) {
    return null
  }

  return slot.thumbnail_url || null
}
