import { getCompoundPathFromBoundingBox } from '@/modules/Editor/annotationTypes/boundingBox'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import { drawPathV2 } from '@/modules/Editor/graphics'
import { inferCurrentAnnotationData } from '@/modules/Editor/inferCurrentAnnotationData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { DrawCallback } from '@/modules/Editor/models/layers/types'
import type { View } from '@/modules/Editor/views/view'

export const renderBoundingBox = (
  drawFn: DrawCallback,
  view: View,
  annotation: Annotation,
  inferred: boolean,
  filter: ImageManipulationFilter | null,
): Path2D | void => {
  const isSelected = view.annotationManager.isSelected(annotation.id)
  const isHighlighted = view.annotationManager.isHighlighted(annotation.id)
  const compoundPath = getCompoundPathFromBoundingBox(
    inferCurrentAnnotationData(annotation, view.currentFrameIndex),
  )
  const color = annotation.color
  const blackout = annotation.annotationClass?.blackout === true
  if (blackout && filter) {
    filter = { ...filter, opacity: 100 }
  }
  const path2D = drawPathV2(
    drawFn,
    compoundPath,
    view.camera,
    color,
    inferred,
    filter,
    isHighlighted && !blackout,
    isSelected && !blackout,
  )

  if (!isHighlighted) {
    return path2D
  }

  return path2D
}
