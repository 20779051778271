import type Router from 'vue-router'
import type { Route } from 'vue-router'

const STORAGE_KEY = 'attemptedRoute'
export const useAttemptedRoute = (): {
  saveAttemptedRoute: (to: Pick<Route, 'fullPath'>) => void
  redirectOnLogin: (router: Pick<Router, 'push'>) => Promise<Route>
} => {
  /**
   * Save the curren't route's full path, to redirect back to on login
   */
  const saveAttemptedRoute = (to: Pick<Route, 'fullPath'>): void =>
    // store into session storage, so it expires on browser close
    window.sessionStorage.setItem(STORAGE_KEY, to.fullPath)

  const loadAttemptedRoute = (): string | null => {
    const route = sessionStorage.getItem(STORAGE_KEY)
    // we cannot keep the attempted route in storage, as then we'd get redirected
    // even when intentionally logging out and logging back in
    window.sessionStorage.removeItem(STORAGE_KEY)
    return route
  }

  /**
   * Redirect to stored attempted route, or default route.
   * Called after user successfully logs in,
   * to redirect them to where they were trying to go.
   */
  const redirectOnLogin = (router: Pick<Router, 'push'>): Promise<Route> =>
    router.push(loadAttemptedRoute() || { name: 'Root' })

  return {
    saveAttemptedRoute,
    redirectOnLogin,
  }
}
