/**
 * Array-like class that has a limited amount of elements
 * Based on FIFO (First in first out)
 *
 * NOTE: Don't extend LimitedArray using Array class
 * It introduces problems for ActionManager.createGroup because
 * of its implementation and Vue reactivity usage
 * With Vue reactivity under the hood it replaces LimitedArray with Array
 */

export class LimitedArray<T> {
  private list: T[] = []
  public limit: number = 1

  constructor(limit: number, list: T[] = []) {
    this.limit = limit
    this.list = list
  }

  /**
   * Push an item to the limited array.
   * @returns The item that was shifted out of the array, if the
   * array is already at max length.
   */
  public push(item: T): T | undefined {
    let removedItem

    if (this.length >= this.limit) {
      removedItem = this.list.shift()
    }

    this.list.push(item)

    return removedItem
  }

  public get(index: number): T {
    return this.list[index]
  }

  public pop(): T | undefined {
    return this.list.pop()
  }

  public get length(): number {
    return this.list.length
  }

  public findIndex(fn: (element: T, index: number, array: T[]) => boolean): number {
    return this.list.findIndex(fn)
  }

  public filter(fn: (value: T, index: number, array: T[]) => boolean): LimitedArray<T> {
    return new LimitedArray(this.limit, this.list.filter(fn))
  }

  public remove(index: number): T | undefined {
    const elements = this.list.splice(index, 1)

    // Splice returns an array, so need to remove that individual element.
    return elements[0]
  }

  public clear(): void {
    this.list.length = 0
  }
}
