import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'

const minFrameIndex = 0

function getClampedFrameIndex(frameIndex: number, max: number): number {
  return Math.min(Math.max(minFrameIndex, frameIndex), max)
}

const setupWheelStackScroll = (context: ToolContext): void => {
  context.handles.push(
    ...context.editor.onWheel((event) => {
      event.preventDefault()

      const view = context.editor.activeView

      const { currentFrameIndex, totalFrames } = view
      const maxFrameIndex = totalFrames - 1

      const { deltaY } = event

      if (deltaY > 0) {
        // Increase frame index
        const newFrameIndex = getClampedFrameIndex(currentFrameIndex + 1, maxFrameIndex)

        view.jumpToFrame(newFrameIndex)
      } else if (deltaY < 0) {
        // Decrease frame index
        const newFrameIndex = getClampedFrameIndex(currentFrameIndex - 1, maxFrameIndex)

        view.jumpToFrame(newFrameIndex)
      }

      return CallbackStatus.Stop
    }),
  )
}

const setupStackScroll = {
  wheel: setupWheelStackScroll,
}

export { setupStackScroll }
