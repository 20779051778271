import type { RenderableItem } from '@/modules/Editor/models/layers/object2D'
import type {
  ItemBBoxMap,
  ItemsMap,
  RTreeItem,
  ZIndexesList,
} from '@/modules/Editor/models/layers/optimisedLayer/types'

import { parseAnnotationData } from './parseAnnotationData'

export type ReturnType = {
  itemsBBoxMap: ItemBBoxMap
  rTreeItems: RTreeItem[]
  itemsMap: ItemsMap
  zIndexesList: ZIndexesList
}

/**
 * Gets a List of RenderableItems as an input and
 * provides optimized data structures to the OptimisedLayer to
 * reduce the execution time of the CRUD operations.
 *
 * 4 data structures as an output:
 * ItemBBoxMap - map of bounding boxes of all items
 * RTreeItem[] - rTree to simplify search by point
 * ItemsMap - map of all items
 * ZIndexesList - sorted by z-index list of ids
 */
export const parseAnnotationsData = (sortedAnnotationsList: RenderableItem[]): ReturnType => {
  const rTreeItems = []
  const itemsMap: ItemsMap = new Map()
  const itemsBBoxMap: ItemBBoxMap = new Map()
  const zIndexesList: ZIndexesList = []

  for (const ann of sortedAnnotationsList) {
    const res = parseAnnotationData(ann)
    if (!res) {
      continue
    }

    // Avoid adding the same item twice
    if (itemsMap.has(ann.id)) {
      continue
    }

    itemsMap.set(res.item.id, res.item)

    zIndexesList.push(res.item.id)

    itemsBBoxMap.set(ann.id, res.bbox)

    rTreeItems.push(res.rTreeItem)
  }

  return {
    itemsBBoxMap,
    itemsMap,
    rTreeItems,
    zIndexesList,
  }
}
