import type { Action } from '@/modules/Editor/managers/actionManager'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'

export const createPolygonBatchedUpdateAction = (
  context: ToolContext,
  updatedAnnotation: Annotation,
  clonedAnnotationForUpdate: Annotation | undefined,
  frameIndex: number,
): void => {
  const { activeView } = context.editor

  if (!activeView || !clonedAnnotationForUpdate) {
    // Note this is typed incorrectly and _can_ be null.
    // This prevents an annotation update, so throw such an error.
    throw new Error('Cannot update annotation')
  }

  // support view id for undoing purposes
  const sourceViewId = context.editor.activeView.id

  // batches happen outside edit mode, so they should always ensure
  // everything is deselected in both do and undo
  const action = ((): Action => {
    const nextAnnotation = updatedAnnotation
    const previousAnnotation = clonedAnnotationForUpdate

    return {
      do(): boolean {
        activeView.annotationManager.updateAnnotation(nextAnnotation, {
          updatedFramesIndices: [frameIndex],
        })
        activeView.annotationManager.deselectAllAnnotations()
        return true
      },
      undo(): boolean {
        // we don't want to use the active view here, as by the time the user want to undo
        // the view could have changed due to multi-slot;
        // this would result in the annotation not being found and the undo action failing
        const sourceView = context.editor.viewsList.find(({ id }) => id === sourceViewId)
        if (!sourceView) {
          return false
        }

        sourceView.annotationManager.updateAnnotation(previousAnnotation, {
          updatedFramesIndices: [frameIndex],
        })
        sourceView.annotationManager.deselectAllAnnotations()
        return true
      },
    }
  })()

  activeView.actionManager.done(action)
}
