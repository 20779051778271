// Note: Minimal interface imported here. Until we have a native editor store
// of the editor initial params, we cannot easily decouple this from editor,
// as its called by View another places, which take V2DatasetItemPayload directly as input for now.

// eslint-disable-next-line boundaries/element-types
import type { DatasetItemType } from '@/store/types/DatasetItemType'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemLayout } from '@/store/types/V2DatasetItemLayout'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemSlot } from '@/store/types/V2DatasetItemSlot'

export type ViewCheckParams = {
  slots: V2DatasetItemSlot[]
  slotTypes: DatasetItemType[]
  layout: V2DatasetItemLayout
}

/**
 * Determines if we are in a radiological view. Right now this
 * just means that all viewports are DicomViews.
 *
 * @param viewCheckParams Parameters describing the current hanging protocol.
 *
 * @returns True if all viewports are DicomViews.
 */
export const isRadiologicalView = (slotTypes: (DatasetItemType | null)[]): boolean => {
  const hasANonDicomView = slotTypes.some((slotType) => slotType !== 'dicom')

  return !hasANonDicomView
}

/**
 * Determines if we are in a radiological volume view. Right now this
 * means that we have 3 viewports in a horrizontal which are all DICOM.
 * We can easily relax this assumption in the future.
 *
 * @param viewCheckParams Parameters describing the current hanging protocol.
 *
 * @returns True if all viewports are DicomViews.
 */
export const isRadiologicalVolumeView = (viewCheckParams: ViewCheckParams): boolean => {
  if (!isRadiologicalView(viewCheckParams.slotTypes)) {
    // If we are not in a Radiological View, we cannot be in a Radiological Volume View.
    return false
  }

  const { slots, layout } = viewCheckParams

  // Note this is fallback for old data.
  // The best we can do is check if it has an affine, and is a 1x3 view.

  // Check if we have an affine, that should only be for volumes currently.
  const missingAffine = slots.some((slot) => slot.metadata?.affine === undefined)

  if (missingAffine) {
    return false
  }

  const areAllSlotsVolume = slots.every((slot) => slot.metadata?.medical?.is_volume === true)

  // Volume view must be horizontal, has a length of 3
  // and all slots must be set to volumes.
  return areAllSlotsVolume && 'type' in layout && layout.type === 'horizontal' && slots.length === 3
}
