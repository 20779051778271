import type {
  VideoAnnotationDataSegment,
  VideoAnnotationData,
} from '@/modules/Editor/AnnotationData'

/**
 * Checks if the passed index is within the segment.
 * Since the index can be a number or set to the string `global` for static subtypes or properties,
 * when index is global, then it will always return `true`, else it computes the presence
 */
const isIndexInsideSegment = (
  segment: VideoAnnotationDataSegment,
  frameIndexOrGlobal: number | 'global',
): boolean =>
  // End segment index will be null when tags are created
  // during registration of item from external storage.
  // Null means last frame index.
  typeof frameIndexOrGlobal === 'string'
    ? frameIndexOrGlobal === 'global'
    : frameIndexOrGlobal >= segment[0] && (!segment[1] || frameIndexOrGlobal < segment[1])

export const hasSegmentContainingIndex = (
  segments: VideoAnnotationData['segments'],
  frameIndexOrGlobal: number | 'global',
): boolean => (segments || [])?.some((segment) => isIndexInsideSegment(segment, frameIndexOrGlobal))

const isSegmentBetweenIndices = (
  segment: VideoAnnotationDataSegment,
  firstIndex: number,
  lastIndex: number,
): boolean => segment[0] >= firstIndex && (!segment[1] || segment[1] <= lastIndex + 1)

export const hasSegmentBetweenIndices = (
  segments: VideoAnnotationData['segments'],
  firstIndex: number,
  lastIndex: number,
): boolean =>
  (segments || [])?.some((segment) => isSegmentBetweenIndices(segment, firstIndex, lastIndex))

export const hasSegmentEqualOrBetweenIndices = (
  index: number,
  segments: VideoAnnotationData['segments'],
): boolean => {
  const startIndex = segments?.[0]?.[0] ?? -1
  const endIndex = segments?.[0]?.[1] ?? -1
  return index >= startIndex && index < endIndex
}
