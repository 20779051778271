import { AxiosError, isAxiosError, isCancel } from 'axios'

/**
 * Determines if the given error payload is an axios error payload thrown
 * due to request cancellation.
 *
 * Axios can cancel requests in two different ways
 *
 * - via deprecated cancel token
 * - via an abort controller signal
 *
 * This covers both of these cases.
 */
export const isCancelled = (e: unknown): boolean => {
  if (isCancel(e)) {
    return true
  }

  if (isAxiosError(e) && e.code === AxiosError.ECONNABORTED) {
    return true
  }

  return false
}
