import type { CommentThread } from '@/modules/Editor/iproviders/types'
import type { ApiResponse } from '@/store/types'
import { put } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

type Params = {
  boundingBox?: { h: number; w: number; x: number; y: number }
  datasetItemId: string
  resolved?: boolean
  teamSlug: string
  threadId: string
}

/**
 * Updates a comment thread associated to a 2.0 dataset item
 *
 * Only the bounding box and resolved status can be updated.
 */
export const updateV2CommentThread = async (
  params: Params,
): Promise<ApiResponse<CommentThread> | ParsedError> => {
  const { datasetItemId, teamSlug, threadId } = params
  const path = `v2/teams/${teamSlug}/items/${datasetItemId}/comment_threads/${threadId}`
  const payload: {
    resolved?: boolean

    bounding_box?: { x: number; y: number; h: number; w: number }
  } = {}

  if ('resolved' in params) {
    payload.resolved = params.resolved
  }
  if ('boundingBox' in params) {
    payload.bounding_box = params.boundingBox
  }

  try {
    const response = await put<CommentThread>(path, payload)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.COMMENT_THREAD_UPDATE)
  }
}
