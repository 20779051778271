import { defineAsyncComponent } from 'vue'
import type { RouteConfig } from 'vue-router'

import { useAuth } from '@/modules/Auth/useAuth'

const annotatorsRoute: RouteConfig = {
  path: '/annotators',
  name: 'Annotators',
  component: defineAsyncComponent(() => import('@/modules/ReportsLegacy/RouteAnnotators.vue')),
  beforeEnter: (to, from, next) => {
    const { isAuthorized } = useAuth()

    // annotators are not allowed to see statistics
    if (!isAuthorized('view_full_datasets')) {
      return next(from.fullPath)
    }
    next()
  },
  meta: { requiresAuth: true },
}

export default annotatorsRoute
