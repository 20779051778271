import type { IPoint } from '@/modules/Editor/point'
import { addPointMutate } from '@/modules/Editor/point'

export const getMaskPixelCenter = (
  size: number,
  pixelIndex?: IPoint,
  voxelScaling?: IPoint,
): IPoint | undefined => {
  if (!pixelIndex) {
    return
  }

  const center = { x: pixelIndex.x, y: pixelIndex.y }

  const width = Math.floor(size)

  if (width % 2 === 1) {
    // If we have an odd width move the cursor to the center of the pixel,
    // so that the path will cover pixel perfect.

    const offset = { x: 0.5, y: 0.5 }

    if (voxelScaling) {
      offset.x *= voxelScaling.x
      offset.y *= voxelScaling.y
    }

    addPointMutate(center, offset)
  }

  return center
}
