// eslint-disable-next-line boundaries/element-types
import type { NotEmpty } from '@/modules/Editor/types'

import type { MainAnnotationType, SubAnnotationType } from './annotationTypes'
import { isAnnotationType } from './annotationTypes'
import { isAnnotationTypeRenderable } from './annotationTypes'
import type { PartialRecord } from './helperTypes'

export type IPoint = {
  x: number
  y: number
}

/**
 * A single attribute is the id of an attribute record stored separately in the db.
 *
 * This is problematic for an embedded version of our editor,
 * as the concept of record ids cannot be used.
 *
 * Note that the typing here is correct.
 * A data field of an annotation with attributes will look like
 *
 * `annotation.data.attributes.attributes` which is a `string[]`
 */
export type AttributesData = {
  /**
   * A list of attribute ids associated to the annotation
   */
  attributes: string[]
}

export type PropertiesData = PartialRecord<string, string[] | null>

/*
 * @deprecated
 */
export type PropertiesDataLegacy = {
  properties: string[]
  property_values: string[]
}

/**
 * This payload appears in annotation data when it was created using the clicker tool.
 *
 * The user first draws a bounding box, which gets them a polygon,
 * which is stored as `PolygonData`.
 *
 * They can then click inside and outside the polygon to exclude or include additional regions of
 * the image. These clicks are pushed into the `clicks` array.
 *
 * Since clicker always produces polygons, any annotation containing clicker data
 * will always also contain polygon data.
 *
 * Newer annotations made by auto-annotate will also contain an `inference` key
 * with `InferenceData`
 */
type AutoAnnotateData = {
  clicks?: { x: number; y: number; type: 'add' | 'remove' }[]
  bbox?: { x1: number; y1: number; x2: number; y2: number }
  model: string
}

/**
 * Used to annotate a rectangular region on a frame. Usually, this is used for
 * lower fidelity annotations than what a polygon would be needed for.
 *
 * For example, for object tracking.
 *
 * https://www.notion.so/v7labs/Bounding-box-a19308c1aef64fe5b3af2a4cad6ce037
 */
export type BoundingBoxData = {
  x: number
  y: number
  w: number
  h: number
}

/**
 * Used to add directional/orientation information to an annotation
 *
 * We could use this, for example, to do "this side" up kind of annotation. Since
 * it's a vector, another common usage would be to annotate lengths, for example,
 * with the keypoint main type.
 */
type DirectionalVectorData = {
  /** In radians */
  angle: number
  /** In pixels */
  length: number
}

/**
 * Used to annotate ellipsoid regions on top of a frame. Similar, but narrower
 * user cases than those of a bounding box.
 *
 * The angle is in radians.
 */
export type EllipseData = {
  /** In radians */
  angle: number
  center: { x: number; y: number }
  radius: { x: number; y: number }
}

export const ModelType = {
  AUTO_ANNOTATE: 'auto_annotate',
  AUTO_BOX: 'auto_box',
  AUTO_TRACK: 'auto_track',
  CLASSIFICATION: 'classification',
  EMBED: 'embed',
  EXTERNAL: 'external',
  INSTANCE_SEGMENTATION: 'instance_segmentation',
  OBJECT_DETECTION: 'object_detection',
  SEMANTIC_SEGMENTATION: 'semantic_segmentation',
  TEXT_SCANNER: 'text_scanner',
} as const

export type ModelType = (typeof ModelType)[keyof typeof ModelType]

/**
 * Additional information about the model used the annotation was created via
 * model inference. This could be, for example, by using the model tool, or via
 * model stage.
 *
 * This usually appears combined with data for another annotation type.
 */
export type InferenceData = {
  confidence: number
  model: {
    /** The id of the model used */
    id: string
    /** The name of the model used */
    name: string
    /** The model type */
    type: ModelType
  }
}

/**
 * Used to mark different annotations of being part of the same thing in some way.
 *
 * The darwin backend will auto-increment the value of this for every new annotation,
 * but can then be set to the same available value on multiple annotations, to
 * depict them relating to each other somehow.
 */
type InstanceIdData = {
  /**
   * Always a positive integer.
   */
  value: number
}

/** Used to annotate single points on an a frame */
export type KeypointData = { x: number; y: number }

/**
 * Used to annotate a line on an image. Depicted as a path which is a series of
 * points
 */
export type LineData = {
  path: { x: number; y: number }[]
}

/**
 * NOTE: Subject for removal
 *
 * Used to annotate links between two other annotations.
 * The `from` and `to` are ids.
 *
 * Currently not being used in production. Unclear if it's being removed or added.
 */
type LinkData = { from: string; to: string }

/**
 * A mask is a list of numbers, indicating which pixels of a raster layer are
 * occupied by a specific class.
 *
 * Multiple such annotations join together using a "meta annotation" defining the
 * overall raster layer.
 *
 * This effectively means there will be 1 or more "mask" annotations and then
 * one "raster_layer" annotation linking them all.
 */
type MaskData = {
  /**
   * Encoding of the mask using sparse run length encoding
   *
   * In this type of encoding, we use pairs of numbers. The first number is the
   * index of the start of an  annotated space, while the second is the length
   * of that annotated space. That means free space is not mentioned.
   *
   * For example, a full mask of
   *
   * [0,0,0,1,0,1,0,1,1,1]
   *
   * will be encoded as
   *
   * [3,1,5,1,7,3]
   *
   * meaning
   *
   * - an annotated space starts at index 3 and has a length of 1
   * - an annotated space starts at index 5 and has a length of 1
   * - an annotated space starts at index 7 and has a lenght of 1
   */
  sparse_rle: number[]
}

/**
 * Used to annotate 2d shapes on a canvas, defined as polygons
 * Probably the mosty commonly used annotation type.
 *
 * https://www.notion.so/v7labs/Polygon-b210905f32e44238850ad10444c363bf
 */
export type PolygonData = {
  /**
   * The main outline path of the polygon. If the polygon is simple and has no
   * holes, this is the only data the annotation will contain.
   */
  path: { x: number; y: number }[]
  /**
   * If the polygon has holes or multiple sections, this depicts additional paths
   * defining those things.
   *
   * Each additional hole or region is a new additional path.
   *
   * Once encoded and saved, the additional paths are rendered on top of the
   * original, sequentially. The part of an additional path that intersects with
   * what's rendered already becomes a hole, while the part that doesn't,
   * becomes a new region.
   *
   * So it follows the even-odd rule in rendering, but this rule is not actually
   * encoded in the data model.
   *
   * ### As an example
   *
   * To render the letter B, you would have
   * - the main path representing the outline of the letter
   * - 2 smaller additional paths representing the holes.
   *
   * If you were to render the letters B and D at the same time, you would have
   * - 1 main path for either the outline of B or the outline of D, depending on
   * which one was drawn first
   * - 1 additional path for the outline of the other letter
   * - 3 additional paths for the holes on the letters B and D
   */
  additional_paths?: { x: number; y: number }[][]
}

/**
 * This field appears on a meta annotation which joins multiple mask annotations together
 *
 * It contains information on how those other annotations map together and thus holds an aggregate
 * of all mask annotations and updates it as a new mask value is provided.
 */
export type RasterLayerData = {
  /**
   * Maps annotation ids to positive integers, so integers can be used
   * within `dense_rle` in place of uuids.
   */
  mask_annotation_ids_mapping: PartialRecord<string, number>
  /** Positive integer, total number of pixels. Width * height of the frame */
  total_pixels: number
  /**
   * Dense RLE encoded data of the whole raster.
   *
   * We have pairs of integers. The first of the pair is an integer which maps
   * to a mask class or is empty (0). The second is the length of this segment
   * of pixels.
   *
   * That means that, as with `sparse_rle` of the mask type the number of elements
   * in the array is always even.
   * `dense_rle` can be [undefined, 0] when representing an empty buffer.
   */
  dense_rle: number[] | [undefined, number]
}

/**
 * Simplified version of a table
 */
export type SimpleTableData = {
  row_offsets: number[]
  col_offsets: number[]
  bounding_box: { x: number; y: number; w: number; h: number }
}

type SkeletonEdgeMetadata = {
  from: string
  to: string
}

/**
 * A skeleton type is used to annotate points (nodes) on a frame that have sort
 * of spatial relationship with each other.
 *
 * For example, branches of a tree, or the gait of a human or animal.
 *
 * The spatial relationship is initially set, but can be changed by editing the
 * annotation after it gets created.
 */
export type SkeletonData = {
  /** A list of connected named points */
  nodes: {
    x: number
    y: number
    name: string
    occluded: boolean
  }[]
  /**
   * An optional edges field that is not provided by the API with annotation data
   * we are getting this value from the Annotation class since it is required for
   * the skeleton render we attach this value to the SkeletonData type
   */
  edges?: SkeletonEdgeMetadata[]
}

export type EyeNodeName = 'inner' | 'outer' | 'upper' | 'lower'

export type EyeData = {
  /** A list of connected named points */
  nodes: {
    x: number
    y: number
    name: EyeNodeName
    occluded: boolean
  }[]
  /**
   * An optional edges field that is not provided by the API with annotation data
   * we are getting this value from the Annotation class since it is required for
   * the eye render we attach this value to the EyeData type
   */
  edges?: SkeletonEdgeMetadata[]
}

/**
 * Used to encapsulate text from one or more other annotations
 *
 * This kind of annotation is used in conjunction with bounding boxes containing text attributes,
 * outputted by an OCR model, to power natural language processing (NLP)
 *
 * Used to annotate data in the textual dimension, does not contain visual information
 *
 * https://www.notion.so/v7labs/String-2c6c93756a56492bb5f3bba4ac4587a1
 */
type StringData = {
  sources: {
    /**
     * The id of the annotation to take the text attribute from.
     */
    id: string
    /**
     * Optional, the range of text to actually take from the text attribute.
     */
    ranges: [number, number][] | null
  }[]
}

/**
 * Used to organize other annotations, usually bounding boxes with text attributes
 * into a tabular structure.
 *
 * A table annotation has a bounding box and a collection of cells.
 *
 * Each cell in turn also has a bounding box ans well as a set of information
 * declaring where it is positioned within the table.
 *
 * https://www.notion.so/v7labs/Table-c0d72a3c48904523a2f6fde7b2ae045d
 */
type TableData = {
  cells: {
    /**
     * Id of the annotation this cell pulls text from
     */
    id: string
    row: number
    col: number
    row_span: number
    col_span: number
    is_header: boolean
    /**
     * Geometric position of the cell in the frame
     */
    bounding_box: { x: number; y: number; w: number; h: number }
  }[]
  /**
   * Geometric position of the table in the frame
   */
  bounding_box: { x: number; y: number; w: number; h: number }
}

/**
 * Used to declare that a frame as a whole classifies as something.
 * Has no internal data. The presence of the key is all that's needed.
 *
 * https://www.notion.so/v7labs/Tag-7a81c40782294a5797f80e3e54de0bc4
 */
type TagData = Record<string, never>

/**
 * Used to assign some free-form textual information to an annotation.
 */
type TextData = { text: string }

type MeasuresData = {
  unit: { x: string; y: string }
  delta: { x: number; y: number }
}

/**
 * Structure of the data field for an annotation made on a single frame.
 *
 * The associated class of the annotation determines the actual structure.
 *
 * Based on the main class of the type, we pick one or several main fields,
 * which are required.
 *
 * Based on subtypes of the class, we are allowed optional sub type fields, one
 * for every associated subtype.
 *
 * The meta types appear in certain use cases, but are generally not determined
 * by the class directly.
 */
export type FrameData = {
  // main types
  bounding_box?: BoundingBoxData
  ellipse?: EllipseData
  keypoint?: KeypointData
  line?: LineData
  link?: LinkData
  mask?: MaskData
  polygon?: PolygonData
  raster_layer?: RasterLayerData
  eye?: EyeData
  skeleton?: SkeletonData
  simple_table?: SimpleTableData
  string?: StringData
  table?: TableData
  tag?: TagData

  // sub types
  attributes?: AttributesData
  directional_vector?: DirectionalVectorData
  instance_id?: InstanceIdData
  text?: TextData

  // "meta" types
  auto_annotate?: AutoAnnotateData
  inference?: InferenceData
  measures?: MeasuresData
}

/**
 * Type guard to check whether the given object contains valid annotation data
 * for a single frame annotation.
 *
 * Use this function when you receive an object and want to be sure you
 * can treat it as annotation data.
 */
export const isAnnotationFrameData = (obj: object): obj is FrameData => {
  const keys = Object.keys(obj)
  return !!keys.length && keys.every((k) => isAnnotationType(k) && isAnnotationTypeRenderable(k))
}

export const hasSomeAnnotationFrameData = (obj: object): obj is FrameData => {
  const keys = Object.keys(obj)
  return !!keys.length && keys.some((k) => isAnnotationType(k) && isAnnotationTypeRenderable(k))
}

/**
 * Subtypes can be of granularity 'section', when frame based, or 'annotation', when they will have
 * the same value on all frames
 */
export const AnnotationSubTypeGranularity = {
  SECTION: 'section',
  ANNOTATION: 'annotation',
} as const
export type AnnotationSubTypeGranularity =
  (typeof AnnotationSubTypeGranularity)[keyof typeof AnnotationSubTypeGranularity]

/**
 * Structure for the annotation data created for a sequence.
 * A sequence is a video, a multi-page pdf, etc.
 * Anything that has more than one frame and could contain annotations spanning multiple frames.
 */
export type SequenceData = {
  /**
   * An object where the key is the starting frame of the annotation and the value
   * is the structure of a single frame annotation, but only allowing keys
   * associated to main annotation types.
   */
  frames: {
    [frame: string]: NotEmpty<
      Pick<
        FrameData,
        /**
         * Need to Exclude eye because "eye" annotation type uses skeleton data
         */
        Exclude<MainAnnotationType, 'eye'> | 'auto_annotate' | 'measures' | 'inference'
      > & {
        keyframe?: boolean
        /**
         * When the ANNOTATION_PAGINATION FF is enabled we use this loaded attribute
         * to understand if a frame is loaded or not, useful for example to show
         * unloaded frames clearly within the UI.
         * NOTE: this is a FE-only attribute, assigned from
         * `src/pinia/useAnnotationPaginationStore.ts` and defaulting to true
         * if the ANNOTATION_PAGINATION FF is disabled.
         */
        loaded?: boolean
      }
    >
  }
  /**
   * An object where the key is the starting frame of the annotation and the value is
   * the structure of a single frame annotation, but only allowing keys associated to
   * sub/meta annotation types.
   *
   * Since a subannotation cannot exist without an annotation, there can only be
   * frame numbers here, which are already used in the `frames` object.
   */
  sub_frames: {
    [frame: string]: NotEmpty<Pick<FrameData, SubAnnotationType> & { keyframe?: boolean }>
  }
  /**
   * "segments" will be undefined when it's a "global" tag annotation
   *
   * The end segment can be null in some cases. This means it's implied to be
   * the end of the sequence.
   */
  segments: [number, number | null][] | undefined
  /**
   * Support 2D array of tuples to represent out of view areas within
   * a video annotation
   */
  hidden_areas?: [number, number][] | undefined
  /**
   * Signifies whether we should be interpolating the value for
   * annotations between keyframes.
   */
  interpolated: boolean
  /**
   * Determines the interpolation algorithm used, if `interpolated: true`.
   */
  interpolate_algorithm?: 'linear-1.0' | 'linear-1.1'

  global_sub_types?: {
    [SubAnnotationType.InstanceId]?: { value: number }
    [SubAnnotationType.Attributes]?: { attributes: string[] }
    [SubAnnotationType.DirectionalVector]?: { angle: number; length: number }
    [SubAnnotationType.Text]?: { text: string }
  }
}

export type AnnotationData = FrameData | SequenceData

export const isSequenceData = (data: AnnotationData): data is SequenceData => 'frames' in data

export const assertSequenceData = (data: AnnotationData): SequenceData => {
  if (!isSequenceData(data)) {
    throw new Error('passed data is not of type sequence')
  }
  return data
}
