import type { TeamAction } from '@/store/modules/team/types'
import * as api from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

export type DeleteInvitationPayload = {
  id: number
}

/**
 * Delete a team invitation
 * @param id Invitation Id
 */
export const deleteInvitation: TeamAction<DeleteInvitationPayload, { success: boolean }> = async (
  { commit },
  params,
) => {
  let response
  try {
    response = await api.remove<{ success: boolean }>(`invitations/${params.id}`)
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.TEAM_MEMBERS_DELETE_INVITATION, params)
  }

  commit('DELETE_INVITATION', params.id)
  return response
}
