import type { TeamAction } from '@/store/modules/team/types'
import type { TeamPayload } from '@/store/types/TeamPayload'
import * as api from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

export type UpdateTeamPayload = {
  id: number
  two_factor_auth_enforced?: boolean
  sso_enforced?: boolean
  annotators_can_create_tags_and_attributes?: boolean
  restrict_exports?: boolean
  export_token_ttl?: number | null
  default_role?: string
  email_domain?: string

  content?: Blob
  hash?: string
  name?: string
  type?: string
}

/**
 * Update team information
 */
export const updateTeam: TeamAction<UpdateTeamPayload, TeamPayload> = async (
  { commit, dispatch },
  params,
) => {
  const updateParams = {
    ...params,
    id: undefined,
    content: undefined,
    type: undefined,
  }

  let response

  try {
    response = await api.put<TeamPayload>(`teams/${params.id}`, updateParams)
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.TEAM_UPDATE)
  }

  const { data } = response
  try {
    await dispatch('resolveImageUrl', { data, params })
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.TEAM_REGISTER)
  }
  commit('SET_CURRENT_TEAM', data)
  commit('PUSH_TEAM', data)

  return { data }
}
