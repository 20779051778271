import type { AnnotationManager } from '@/modules/Editor/managers/annotationManager'
import { isRasterAnnotation } from '@/modules/Editor/models/annotation/annotationKindValidator'
import type { MaskAnnotation } from '@/modules/Editor/models/annotation/types'
import type { ImageRaster } from '@/modules/Editor/models/raster/ImageRaster'
import type { Raster } from '@/modules/Editor/models/raster/Raster'
import type { VideoRaster } from '@/modules/Editor/models/raster/VideoRaster'
import { assertImageRaster } from '@/modules/Editor/models/raster/assertImageRaster'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import type { View } from '@/modules/Editor/views/view'
import { isMaskAnnotationEmpty } from '@/modules/Editor/plugins/mask/utils/shared/isMaskAnnotationEmpty'

type MaskAnnotationAndLabelIndex = {
  annotation: MaskAnnotation
  labelIndex: number
}

/**
 * Utility that cleans up bookkeeping of masks present on different frames
 * of a VideoRaster. For any that are empty, associated metadata is removed
 * from the raster layer.
 */
const checkAndRemoveEmptyVideoMasks = (
  view: View,
  videoRaster: VideoRaster,
  annotationsWithLabels: MaskAnnotationAndLabelIndex[],
  framesRange: number[],
): void => {
  const { annotationManager } = view

  annotationsWithLabels.forEach(({ annotation, labelIndex }) => {
    // Scan the keyframes for the label in the updated frame range.
    for (let frameIndex = framesRange[0]; frameIndex <= framesRange[1]; frameIndex++) {
      if (!videoRaster.labelsPerKeyframe[frameIndex]?.has(labelIndex)) {
        continue
      }

      const rasterBufferAccessor = videoRaster.getBufferForEdit(frameIndex)
      const bounds = videoRaster.getVideoBoundsForLabelIndexForFrame(labelIndex, frameIndex)

      if (isMaskAnnotationEmpty(labelIndex, rasterBufferAccessor, bounds)) {
        videoRaster.deleteLabelOnKeyframe(labelIndex, frameIndex)
        videoRaster.deleteVideoBoundsForLabelIndexForFrame(labelIndex, frameIndex)
      }
    }

    // Check if after going through all keyframes, the label index is now empty
    const hasLabelIndex = Object.keys(videoRaster.labelsPerKeyframe).some((keyframe) =>
      videoRaster.labelsPerKeyframe[Number(keyframe)].has(labelIndex),
    )

    if (!hasLabelIndex) {
      annotationManager.deleteAnnotation(annotation.id)
    }
  })
}

const checkAndRemoveEmptyImageMasks = (
  view: View,
  imageRaster: ImageRaster,
  annotationsWithLabels: MaskAnnotationAndLabelIndex[],
): void => {
  const { annotationManager } = view
  const annotationsRemoved: MaskAnnotation[] = []

  const rasterBufferAccessor = imageRaster.getActiveBufferForEdit()

  annotationsWithLabels.forEach(({ annotation, labelIndex }) => {
    const bounds = imageRaster.getBoundsForLabelIndex(labelIndex)

    if (isMaskAnnotationEmpty(labelIndex, rasterBufferAccessor, bounds)) {
      annotationsRemoved.push(annotation)
    }
  })

  annotationsRemoved.forEach((annotation: MaskAnnotation) => {
    annotationManager.deleteAnnotation(annotation.id)
  })
}

const getAnnotationsWithLabels = (
  annotationManager: AnnotationManager,
  raster: Raster,
  labelsBeingOverwritten: number[],
): MaskAnnotationAndLabelIndex[] => {
  const annotationsWithLabels: MaskAnnotationAndLabelIndex[] = []

  labelsBeingOverwritten.forEach((labelIndex: number) => {
    const annotationId = raster.getAnnotationMapping(labelIndex)

    if (annotationId === undefined) {
      // Annotation might have already been deleted by
      // another recent action, already removed so fail gracefully.
      return
    }

    const annotation = annotationManager.getAnnotation(annotationId)

    if (annotation && isRasterAnnotation(annotation)) {
      annotationsWithLabels.push({ annotation, labelIndex })
    }
  })

  return annotationsWithLabels
}

/**
 * Checks if the given labels on a raster map are empty within the
 * regions defined by mask annotations. If any annotations are empty,
 * delete these annotations.
 *
 * @param view The view on which the raster layer sits.
 * @param raster The raster mask.
 * @param labelsBeingOverwritten The labels to query.
 * @param framesRange The range of frames to check for empty masks.
 *
 * If you have a tool which interacts with the raster layer, the tool
 * should keep track of what classes are being overwritten with this
 * action, and then call this helper to check if classes need to be removed.
 */
export const checkAndRemoveEmptyMasks = (
  view: View,
  raster: Raster,
  labelsBeingOverwritten: number[],
  framesRange?: number[],
): void => {
  const annotationsWithLabels = getAnnotationsWithLabels(
    view.annotationManager,
    raster,
    labelsBeingOverwritten,
  )

  if (raster.type === RasterTypes.VIDEO || raster.type === RasterTypes.VOXEL) {
    const videoRaster = assertVideoRaster(raster)
    checkAndRemoveEmptyVideoMasks(
      view,
      videoRaster,
      annotationsWithLabels,
      framesRange || [view.currentFrameIndex, view.currentFrameIndex],
    )
  } else {
    const imageRaster = assertImageRaster(raster)
    checkAndRemoveEmptyImageMasks(view, imageRaster, annotationsWithLabels)
  }
}
