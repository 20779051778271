import { SENSITIVITY_HIGH } from '@/modules/Editor/plugins/windowLevel/consts'
import type { WindowLevelState } from '@/modules/Editor/plugins/windowLevel/types'

export function initWindowLevelState(): WindowLevelState {
  return {
    previousPoint: undefined,
    cursorPoint: undefined,
    active: false,
    activatedTime: undefined,
    sensitivity: {
      windowSensitivity: SENSITIVITY_HIGH,
      levelSensitivity: SENSITIVITY_HIGH,
    },
  }
}
