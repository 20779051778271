import { WindAuthAction } from '@/backend/darwin/WindAuthAction'
import type { ApiResult } from '@/backend/darwin/types'
import { errorMessages } from '@/backend/error'

import { withAuth } from './api'
import type { RunningSessionInstanceCountPayload } from './types'

type DeploymentDataGranularity = 'month' | 'day' | 'hour'

type Params = {
  from: string
  granularity: DeploymentDataGranularity
  runningSessionId: string
  teamId: number
}

export const loadRunningSessionInstanceCounts = (
  payload: Params,
): Promise<ApiResult<RunningSessionInstanceCountPayload[]>> => {
  const { from, granularity, teamId, runningSessionId } = payload

  const authParams = { action: WindAuthAction.ViewModels, teamId }
  const params = {
    granularity,
    from,
  }

  const path = `running_sessions/${runningSessionId}/instance_counts`

  return withAuth<RunningSessionInstanceCountPayload[]>(
    authParams,
    (client) => client.get<RunningSessionInstanceCountPayload[]>(path, { params }),
    errorMessages.NEURAL_MODEL_DATA,
  )
}
