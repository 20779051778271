export const TokenKeys = {
  ACCESS_TOKEN: 'token',
  ACCESS_TOKEN_EXPIRY: 'token_expire',
  REFRESH_TOKEN: 'refresh_token',
} as const

export type TokenKeys = (typeof TokenKeys)[keyof typeof TokenKeys]

/** Stores refresh token either into local (if permanent) or session storage */
export const setRefreshToken = (refreshToken: string, isPermanent?: boolean): void => {
  if (isPermanent) {
    localStorage.setItem(TokenKeys.REFRESH_TOKEN, refreshToken)
    sessionStorage.removeItem(TokenKeys.REFRESH_TOKEN)
  } else {
    sessionStorage.setItem(TokenKeys.REFRESH_TOKEN, refreshToken)
    localStorage.removeItem(TokenKeys.REFRESH_TOKEN)
  }
}

/** Clears refresh token from both local and session storage */
export const clearRefreshToken = (): void => {
  localStorage.removeItem(TokenKeys.REFRESH_TOKEN)
  sessionStorage.removeItem(TokenKeys.REFRESH_TOKEN)
}

/** Stores access token into local storage */
export const setToken = (token: string): void => localStorage.setItem(TokenKeys.ACCESS_TOKEN, token)

/** Clears access token info from local storage */
export const clearToken = (): void => {
  localStorage.removeItem(TokenKeys.ACCESS_TOKEN)
  localStorage.removeItem(TokenKeys.ACCESS_TOKEN_EXPIRY)
}

/** Resolves access token expiration from local storage */
export const setTokenExpiration = (expiration: number): void =>
  localStorage.setItem(TokenKeys.ACCESS_TOKEN_EXPIRY, expiration.toString())

/** Resolves access token from local storage */
export const getToken = (): string | null => localStorage.getItem(TokenKeys.ACCESS_TOKEN)

/** Resolves refresh token, either from session or local storage */
export const getRefreshToken = (): string | null =>
  sessionStorage.getItem(TokenKeys.REFRESH_TOKEN) || localStorage.getItem(TokenKeys.REFRESH_TOKEN)

/** Retreives token expiration as an integer */
export const getTokenExpiration = (): number => {
  const expiration = localStorage.getItem(TokenKeys.ACCESS_TOKEN_EXPIRY)
  if (!expiration) {
    return 0
  }
  return parseInt(expiration)
}

/** Returns token lifetime left, in seconds */
export const getTokenLifetimeLeft = (): number => {
  const expiration = getTokenExpiration()
  if (!expiration) {
    return 0
  }

  const now = new Date().getTime() / 1000
  return Math.floor(expiration - now)
}

type TokenPayload = {
  token: string
  tokenExpiration: number
}
/**
 * Updates user's access token within this tab
 */
export const updateToken = (payload: TokenPayload): void => {
  setToken(payload.token)
  setTokenExpiration(payload.tokenExpiration)
}
