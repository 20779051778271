import EventEmitter from 'events'

import type { CallbackHandle } from '@/modules/Editor/callbackHandler'
import { setContext } from '@/services/sentry'

import { Events } from './layer'
import type { RenderableItem } from './object2D'
import type { ILayer, IObject } from './types'

/**
 * HTML element object
 *
 * render - function will return HTML element,
 * that will be accumulated and optimized by HTMLLayer.render
 */
export class ObjectHTML implements IObject<DocumentFragment, HTMLDivElement> {
  id: string

  constructor(
    id: string,
    public render: (context: DocumentFragment, canvas: HTMLDivElement) => HTMLElement | undefined,
  ) {
    this.id = id
  }
}

export const isObjectHTML = (obj: object): obj is ObjectHTML => 'render' in obj

export class HTMLLayer extends EventEmitter implements ILayer<DocumentFragment, HTMLDivElement> {
  private _canvas: HTMLDivElement
  private _context: DocumentFragment
  private _hasChanges: boolean = false

  private _renderPool: { [key: string]: ObjectHTML } = {}

  constructor(name: string) {
    super()

    this._canvas = document.createElement('div')
    this._canvas.classList.add(name)
    this._canvas.setAttribute('data-test', name)
    this._context = document.createDocumentFragment()
  }

  public activate(): void {}
  public deactivate(): void {}
  public reorderAnnotation(): void {}
  public draw(): void {}
  public clearDrawingCanvas(): void {}
  public hitItemRegion(): Promise<IObject<DocumentFragment, HTMLDivElement>['id'] | undefined> {
    return Promise.resolve(undefined)
  }
  public hitVertexRegion(): number | undefined {
    return
  }
  public hitItemStroke(): Promise<string | undefined> {
    return Promise.resolve(undefined)
  }
  public setFilters(): void {}
  public updateItemState(): void {}
  public activateVertexWithState(): void {}
  public unhighlightAllVertices(): void {}
  public deactivateVertex(): void {}
  public isHidden(): boolean {
    return false
  }
  public showAll(): void {}
  public show(): void {}
  public hide(): void {}
  public hideAll(): void {}
  public hideLayer(): void {}
  public showLayer(): void {}

  public get context(): DocumentFragment {
    if (!this._context) {
      throw new Error('Something went wrong! Context not set!')
    }

    return this._context
  }

  public get canvas(): HTMLDivElement {
    return this._canvas
  }

  public get element(): DocumentFragment {
    const frag = document.createDocumentFragment()

    frag.appendChild(this.canvas)

    return frag
  }

  /**
   * Add new object to the render pool.
   */
  add(payload: ObjectHTML | RenderableItem): void
  add(payload: ObjectHTML[] | RenderableItem[]): void
  add(payload: ObjectHTML | ObjectHTML[] | RenderableItem | RenderableItem[]): void {
    const items = Array.isArray(payload) ? payload : [payload]

    this._hasChanges = true

    items.forEach((item) => {
      if (!isObjectHTML(item)) {
        return
      }
      this._renderPool[item.id] = item
    })
  }

  replaceAll(): void {
    throw new Error('Method not implemented.')
  }
  replaceAllWithParsedData(): void {}

  public update(): void {}

  /**
   * Remove object from the render pool by its id.
   */
  public delete(itemId: ObjectHTML['id']): void {
    this._hasChanges = true

    delete this._renderPool[itemId]
  }

  /**
   * Returns object by its id.
   */
  getItem(id: string): ObjectHTML {
    return this._renderPool[id]
  }

  /**
   * Checks if the object with id exists on the layer.
   */
  has(id: string): boolean {
    return !!this._renderPool[id]
  }

  /**
   * Renders each item in the pool.
   *
   * If the layer has no changes it will skip re-render iteration.
   */
  render(): void {
    if (!this.context) {
      return
    }

    if (!this._hasChanges) {
      return
    }

    this._hasChanges = false

    this.emit(Events.BEFORE_RENDER, this.context, this.canvas)

    Object.values(this._renderPool).forEach((item) => {
      try {
        item.render(this.context, this.canvas)
      } catch (e) {
        setContext('error', { error: e })
        console.error('V2 HTMLLayer failed to render object')
      }
    })

    this.emit(Events.RENDER, this.context, this.canvas)
  }

  /**
   * Marks layer as changed.
   *
   * So it will be redrawn during the next render iteration.
   */
  changed(): void {
    this._hasChanges = true
  }

  changedDebounce(): void {}

  clear(): void {
    this._renderPool = {}
    this._hasChanges = true
  }

  destroy(): void {
    this.clear()
    this.removeAllListeners(Events.RENDER)
    this.removeAllListeners(Events.BEFORE_RENDER)
  }

  onRender(cb: (ctx: DocumentFragment, canvas: HTMLDivElement) => void): CallbackHandle {
    this.on(Events.RENDER, cb)

    return {
      id: this.listenerCount(Events.RENDER),
      release: this.off.bind(this, Events.RENDER, cb),
    }
  }

  onBeforeRender(cb: (ctx: DocumentFragment, canvas: HTMLDivElement) => void): CallbackHandle {
    this.on(Events.BEFORE_RENDER, cb)

    return {
      id: this.listenerCount(Events.BEFORE_RENDER),
      release: this.off.bind(this, Events.BEFORE_RENDER, cb),
    }
  }

  onRendered(cb: (renderKey: string | null) => void): CallbackHandle {
    this.on(Events.RENDERED, cb)

    return {
      id: this.listenerCount(Events.RENDERED),
      release: this.off.bind(this, Events.RENDERED, cb),
    }
  }

  setKeyForNextRender(): void {
    throw new Error('Method not implemented.')
  }
}
