// eslint-disable-next-line no-restricted-syntax
import MediaInfoFactory from 'mediainfo.js'

import { isVideoFile } from '@/modules/Datasets/fileUtils'
import { resolveNewFiles, toUploadFile, resolveNewSet } from '@/store/modules/datasetUpload/helpers'
import type { DatasetUploadAction, UploadFile } from '@/store/modules/datasetUpload/types'
import { assert } from '@/core/utils/assert'

import { extractVideoFPS } from './extractVideoFPS'

type Payload = FileList | File[]
type Response = UploadFile[]

export const addFiles: DatasetUploadAction<Payload, Response> = async (
  { commit, state },
  fileList,
) => {
  const set = resolveNewSet(state)
  const files = resolveNewFiles(state, fileList)

  const hasVideoFiles = files.some(isVideoFile)
  // lazy load mediainfo if we are processing video files
  // to extract framerate values
  const mi = hasVideoFiles
    ? await MediaInfoFactory({
        format: 'object',
        locateFile: () => '/MediaInfoModule.wasm',
      })
    : null

  const addedUploadFiles = []

  for (const f of files) {
    const fps = isVideoFile(f) ? await extractVideoFPS(assert(mi), f) : undefined
    addedUploadFiles.push(toUploadFile(f, set, state.tags, state.path, fps))
  }

  mi?.close()

  commit('ADD_UPLOAD_FILES', addedUploadFiles)

  return { data: addedUploadFiles }
}
