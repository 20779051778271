import type { Camera } from '@/modules/Editor/camera'
import { DEFAULT_LINE_WIDTH } from '@/modules/Editor/config'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import type { EditablePoint } from '@/modules/Editor/point'
import type { DrawCallback } from '@/modules/Editor/models/layers/types'
// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'

import { strokeStyle } from './strokeStyle'

export function drawSegmentV2(
  drawFn: DrawCallback,
  lineWidth: number,
  scale: number,
  p1: EditablePoint,
  p2: EditablePoint,
  color: RGBA,
  filter: ImageManipulationFilter | null,
  isSelected: boolean = false,
): Path2D {
  const path = new Path2D()
  drawFn((ctx) => {
    ctx.lineWidth = (lineWidth * 1.0) / scale
    ctx.strokeStyle = strokeStyle(color, filter, false, isSelected)
    ctx.beginPath()
    path.moveTo(p1.x, p1.y)
    path.lineTo(p2.x, p2.y)
    ctx.stroke(path)
  })
  return path
}

/**
 * @deprecated
 */
export function drawSegment(
  camera: Camera,
  ctx: CanvasRenderingContext2D,
  p1: EditablePoint,
  p2: EditablePoint,
  color: RGBA,
  filter: ImageManipulationFilter | null,
  isSelected: boolean = false,
): void {
  ctx.save()
  camera.imageViewCtxToCanvasViewCtx(ctx)
  ctx.lineWidth = (DEFAULT_LINE_WIDTH * 1.0) / camera.scale
  ctx.strokeStyle = strokeStyle(color, filter, false, isSelected)
  ctx.beginPath()
  ctx.moveTo(p1.x, p1.y)
  ctx.lineTo(p2.x, p2.y)
  ctx.stroke()
  ctx.restore()
}
