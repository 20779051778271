import type { PolygonData } from '@/core/annotations'
import type { IPoint } from '@/modules/Editor/point'

export const compoundToFlatPath = (compoundPath: PolygonData): IPoint[] => {
  const { path, additional_paths } = compoundPath

  if (Array.isArray(additional_paths)) {
    return [...path, ...additional_paths.flat()]
  }

  return path
}
