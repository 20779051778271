import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'
import { BrushError } from '@/modules/Editor/plugins/brush/tool'

/**
 * Ensures a class is preselected on brush annotation save,
 * throwing an error if not.
 */
export const resolveCurrentClass = (context: ToolContext): AnnotationClass => {
  // The function used to be more complex and tried to source the class
  // in two different ways, but now that the brush tool preselects the class
  // on activation, we just ensure there is a preselected class.
  const preselectedClass = context.editor.activeView.annotationManager.preselectedAnnotationClass
  if (preselectedClass) {
    return preselectedClass
  }

  throw new BrushError('Cannot resolve current class')
}
