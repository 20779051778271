import type { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import type { IPoint } from '@/modules/Editor/point'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'

interface SelectTool extends Tool {
  initialPoint?: IPoint
  initialPosition?: IPoint
}

export const selectTool: SelectTool = {
  initialPoint: undefined,

  activate(context: ToolContext) {
    const { annotationManager } = context.editor.activeView

    const highlightAnnotation = (id: string): void => {
      if (annotationManager.isHighlighted(id)) {
        return
      }
      annotationManager.highlightAnnotation(id)
    }
    const unHighlightAnnotations = (): void => {
      annotationManager.cachedUnhighlightAllAnnotations()
    }

    setupMouseButtonLoadout(context, {
      primary: true,
      middle: true,
    })

    selectCursor(EditorCursor.Default)

    let targetItemId: undefined | string
    let annotation: Annotation | undefined

    const onMove = (event: PointerEvent): void | CallbackStatus => {
      const canvasPoint = resolveEventPoint(event)
      if (!canvasPoint) {
        return
      }

      const imagePoint = context.editor.activeView.camera.canvasViewToImageView(canvasPoint)
      // if cursor is over an annotation, set it and activate the vertex
      context.editor.activeView.annotationsLayer.hitItemRegion(imagePoint).then((res) => {
        targetItemId = res
        annotation = res ? annotationManager.getAnnotation(res) : undefined

        if (!targetItemId) {
          return
        }
        const hitVertexId = context.editor.activeView.annotationsLayer.hitVertexRegion(
          targetItemId,
          imagePoint,
        )
        if (targetItemId && hitVertexId !== undefined) {
          context.editor.activeView.annotationsLayer.activateVertexWithState(
            targetItemId,
            hitVertexId,
            { isHighlighted: true },
          )
        }
      })

      if (annotation) {
        return highlightAnnotation(annotation.id)
      }

      if (!annotationManager.highlightedAnnotations.length) {
        return
      }
      unHighlightAnnotations()
    }

    context.handles.push(
      ...context.editor.onMouseDown((event) => {
        const mouse = context.editor.activeView.camera.canvasViewToImageView({
          x: event.offsetX,
          y: event.offsetY,
        })
        const annotation = context.editor.activeView.annotationManager.findTopAnnotationAt(mouse)

        if (annotation) {
          // If an annotation was clicked at, we will start moving it.
          context.editor.activeView.annotationManager.selectAnnotation(annotation.id)
          this.initialPosition = context.editor.activeView.camera.canvasViewToImageView({
            x: event.offsetX,
            y: event.offsetY,
          })
          this.initialPoint = context.editor.activeView.camera.canvasViewToImageView({
            x: event.offsetX,
            y: event.offsetY,
          })
        } else {
          context.editor.activeView.annotationManager.deselectAllAnnotations()
          context.editor.activeView.annotationManager.deselectVertex()
        }
      }),
    )
    context.handles.push(...context.editor.onMouseMove(onMove))
    context.handles.push(...context.editor.onTouchMove(onMove))
  },
  deactivate() {},
  reset() {},
}
