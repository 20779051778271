import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { computed, ref } from 'vue'

import { useTeamProperties } from '@/modules/Classes/useTeamProperties'
import { useToast } from '@/uiKit/Toast/useToast'
import { useTeamStore } from '@/pinia/useTeamStore'
import { setContext } from '@/services/sentry'
import type { TeamItemProperty, TeamProperty, TeamPropertyValue } from '@/store/types/PropertyTypes'
import { TeamPropertyGranularity } from '@/store/types/PropertyTypes'
import type {
  TeamPropertyRequestBody,
  DatasetItemPropertiesResponse,
} from '@/backend/darwin/teamProperties'
import {
  createTeamProperty,
  deleteTeamProperty,
  deleteTeamPropertyValue,
  getItemTeamItemProperties,
  setItemTeamPropertyValue,
  type TeamPropertyUpdateRequestBody,
  updateTeamProperty,
  updateTeamPropertyValue,
} from '@/backend/darwin/teamProperties'
import { getErrorMessage } from '@/backend/error'
import type { ParsedError } from '@/backend/error/types'
import type { V2WorkflowCommandResponse } from '@/store/types/V2WorkflowCommandResponse'
import type { ApiResponse } from '@/store/types'
import { useWorkflowInWorkviewStore } from '@/modules/Workview/useWorkflowInWorkviewStore'
import { defineComposable } from '@/core/utils/defineComposable'

export const CREATE_PROPERTY_SUCCESS_MESSAGE = 'Property created successfully'
export const UPDATE_PROPERTY_SUCCESS_MESSAGE = 'Property updated successfully'
export const DELETE_PROPERTY_SUCCESS_MESSAGE = 'Property deleted successfully'

class UpdateError extends Error {}

/** Store used to create, update or delete individual properties */
export const useTeamProperty = defineComposable(() => {
  const teamStore = useTeamStore()
  const toast = useToast()
  const teamProperties = useTeamProperties()
  const workflowStore = useWorkflowInWorkviewStore()

  const teamSlug = computed(() => teamStore.currentTeam?.slug)

  const defaultPropertyGranularity = ref(TeamPropertyGranularity.ANNOTATION)

  const createProperty = async (
    property: TeamPropertyRequestBody,
  ): Promise<Awaited<ReturnType<typeof createTeamProperty>> | void> => {
    if (!teamSlug.value) {
      return
    }

    const response = await createTeamProperty(teamSlug.value, property)
    if ('error' in response) {
      const message = getErrorMessage(response.error).join(', ')
      toast.error({ meta: { title: message } })
    } else {
      // Add created property to the list of properties
      teamProperties.setProperties([...teamProperties.properties, response.data])
      toast.success({ meta: { title: CREATE_PROPERTY_SUCCESS_MESSAGE } })
    }

    return response
  }

  const getRequestBody = (
    property: Omit<TeamProperty, 'team_id' | 'slug'>,
  ): TeamPropertyRequestBody => ({
    name: property.name,
    type: property.type,
    description: property.description,
    granularity: property.granularity,
    dataset_ids: property.dataset_ids,
    property_values: property.property_values?.map((v) => omit(v, 'id', 'position')) || [],
    annotation_class_id: property.annotation_class_id,
    required: property.required,
  })
  /**
   * Return the main body and list of property values to update.
   *
   * Since main body is not able to update the property value we need to remove
   * already created values from it and send 'em separately with a value update request.
   */
  const getUpdateBodyAndValuesToUpdate = (
    property: Omit<TeamProperty, 'team_id' | 'slug'>,
  ): {
    body: TeamPropertyRequestBody
    values: TeamPropertyValue[]
  } => {
    const currentPropIndex = teamProperties.properties.findIndex((p) => p.id === property.id)

    if (currentPropIndex < 0) {
      return { body: getRequestBody(property), values: [] }
    }

    const currentProp = teamProperties.properties[currentPropIndex]

    const propertyValuesToUpdate: TeamPropertyValue[] = []
    currentProp.property_values.forEach((currentProp) => {
      // Get property that already created
      const propertyToUpdate = property.property_values.findIndex((p) => p.id === currentProp.id)
      if (propertyToUpdate < 0) {
        return
      }
      // Push to update list
      propertyValuesToUpdate.push(property.property_values[propertyToUpdate])
      // Remove from the main body request (it supports only property creation)
      property.property_values.splice(propertyToUpdate, 1)
    })

    return {
      body: getRequestBody(property),
      values: propertyValuesToUpdate,
    }
  }

  /**
   * NOTE: updateProperty will be updated as soon as API implements the endpoint
   * for batch property values update
   */
  const updateProperty = async (
    property: Omit<TeamProperty, 'team_id' | 'slug'>,
  ): Promise<Awaited<ReturnType<typeof updateTeamProperty>> | void> => {
    if (!teamSlug.value) {
      return
    }
    const teamSlugValue = teamSlug.value

    const { body, values } = getUpdateBodyAndValuesToUpdate(property)
    const updateBody: TeamPropertyUpdateRequestBody = omit(body, [
      'annotation_class_id',
      'granularity',
    ])

    try {
      // Main property body update
      const propertyUpdateResponse = await updateTeamProperty(
        teamSlugValue,
        property.id,
        updateBody,
      )

      if ('error' in propertyUpdateResponse) {
        const message =
          typeof propertyUpdateResponse.error.message === 'string'
            ? propertyUpdateResponse.error.message
            : 'Something went wrong'

        throw new UpdateError(message)
      }

      if (propertyUpdateResponse && 'data' in propertyUpdateResponse) {
        // Replace updated property in the list of properties
        teamProperties.setProperties(
          teamProperties.properties.map((p) =>
            p.id === propertyUpdateResponse.data.id ? propertyUpdateResponse.data : p,
          ),
        )
        toast.success({ meta: { title: UPDATE_PROPERTY_SUCCESS_MESSAGE } })
      }

      // Update of all property options one by one
      for (const prop of values) {
        const response = await updateTeamPropertyValue(
          teamSlugValue,
          propertyUpdateResponse.data.id,
          prop.id,
          pick(prop, 'color', 'value', 'position'),
        )
        if ('error' in response) {
          const message =
            typeof response.error.message === 'string'
              ? response.error.message
              : 'Something went wrong'

          throw new UpdateError(message)
        }

        // Replace updated property in the list of properties
        const property = teamProperties.properties.find(
          (p) => p.id === propertyUpdateResponse.data.id,
        )
        if (!property) {
          return
        }
        property.property_values = property.property_values.map((p) =>
          p.id === response.data.id ? response.data : p,
        )
      }

      return propertyUpdateResponse
    } catch (e: unknown) {
      if (e instanceof UpdateError) {
        toast.error({ meta: { title: e.message } })
        return
      }

      setContext('updateProperty error', { error: e })
      return
    }
  }

  const deleteProperty = async (
    id: string,
  ): Promise<Awaited<ReturnType<typeof deleteTeamProperty>> | void> => {
    if (!teamSlug.value) {
      return
    }

    const response = await deleteTeamProperty(teamSlug.value, id)

    if ('error' in response) {
      const message =
        typeof response.error.message === 'string' ? response.error.message : 'Something went wrong'

      toast.error({ meta: { title: message } })
    } else {
      // Remove deleted property from the list of properties
      teamProperties.setProperties(teamProperties.properties.filter((p) => p.id !== id))
      toast.success({ meta: { title: DELETE_PROPERTY_SUCCESS_MESSAGE } })
    }
  }

  const deletePropertyValue = async (
    propertyId: string,
    propertyValueId: string,
  ): Promise<void> => {
    if (!teamSlug.value) {
      return
    }

    const response = await deleteTeamPropertyValue(teamSlug.value, propertyId, propertyValueId)

    if ('error' in response) {
      const message =
        typeof response.error.message === 'string' ? response.error.message : 'Something went wrong'

      toast.error({ meta: { title: message } })

      throw new Error(message)
    }

    toast.success({ meta: { title: DELETE_PROPERTY_SUCCESS_MESSAGE } })
  }

  const getItemPropertiesForItem = async (
    itemId: string,
  ): Promise<DatasetItemPropertiesResponse['properties'] | undefined> => {
    if (!teamSlug.value) {
      return
    }
    const response = await getItemTeamItemProperties(teamSlug.value, itemId)
    if ('error' in response) {
      return
    }
    return response?.data.properties
  }

  const setItemPropertyValue = (
    itemId: string,
    propertyId: string,
    propertyValueIds: string[] | [{ text: string }],
  ): Promise<ParsedError | ApiResponse<V2WorkflowCommandResponse>> | undefined => {
    if (!teamSlug.value) {
      return
    }
    if (!workflowStore.stageInstance?.id || !workflowStore.stage?.id) {
      return
    }
    // Optimistic UI: updates the current item property values, so the components updates right away
    teamProperties.setCurrentItemPropertyValues(propertyId, propertyValueIds)
    return setItemTeamPropertyValue(
      teamSlug.value,
      itemId,
      propertyId,
      propertyValueIds,
      workflowStore.stageInstance.id,
      workflowStore.stage.id,
    )
  }

  const isValidPropertyResponse = (
    response: { data: TeamProperty | TeamItemProperty } | ParsedError | void,
  ): response is { data: TeamProperty | TeamItemProperty } => !!response && !('error' in response)

  return {
    teamSlug,
    isValidPropertyResponse,
    createProperty,
    updateProperty,
    deleteProperty,
    deletePropertyValue,
    setItemPropertyValue,
    getItemPropertiesForItem,

    defaultPropertyGranularity,
  }
})
