import { getFileCategory, isEqual } from '@/modules/Datasets/fileUtils'

import type { DatasetUploadState } from './state'
import type { UploadFileData, UploadFile } from './types'

export const toUploadFile = (
  file: File,
  setId: number,
  tags?: string[],
  path?: string,
  framerate?: number,
): UploadFile => {
  const category = getFileCategory(file)
  const data: UploadFileData = {
    category,
    setId,
    status: 'added',
    signingURL: null,
    sentBytes: 0,
    tags,
    path,
    framerate,
    totalBytes: file.size,
  }

  return { file, data }
}

export const resolveNewFiles = (state: DatasetUploadState, files: File[] | FileList): File[] =>
  Array.from(files).filter((f) => !state.files.find((e) => isEqual(e.file, f)))

export const resolveNewSet = (state: DatasetUploadState): number =>
  state.files.length === 0 ? 1 : Math.max(...state.files.map((f) => f.data.setId)) + 1
