import type { Text } from '@/modules/Editor/AnnotationData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import type { ImageSubAnnotation } from '@/modules/Editor/models/annotation/types'

export function mergeSubAnnotations(
  firstAnnotation: Annotation,
  secondAnnotation: Annotation,
): ImageSubAnnotation[] {
  const subAnnotations: ImageSubAnnotation[] = []
  for (const firstSub of firstAnnotation.subAnnotations as ImageSubAnnotation[]) {
    const secondSub = (secondAnnotation.subAnnotations as ImageSubAnnotation[]).find(
      (sub) => sub.type === firstSub.type,
    )

    if (!secondSub) {
      // if the second annotation does not have the same type then there is nothing to merge
      subAnnotations.push(firstSub)
      continue
    }
    if (firstSub.type === 'text') {
      const firstText = (firstSub.data as Text).text
      const secondText = (secondSub.data as Text).text
      let newText
      if (firstText && !secondText) {
        newText = firstText
      }
      if (firstText && secondText) {
        newText = `${firstText} ${secondText}`
      }
      if (!firstText && secondText) {
        newText = secondText
      }

      subAnnotations.push(shallowCloneAnnotation(firstSub, { data: { text: newText } }))
    } else {
      subAnnotations.push(firstSub)
    }
  }
  return subAnnotations
}
