import type { V2AnnotationPayload, V2DatasetItemPayload } from '@/store/types'
import { get } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { parseError, errorMessages, isErrorResponse } from '@/backend/error'

import { isCancelled } from './isCancelled'

/**
 * Defines params structure for the loadStageAnnotations backend action
 */
type Payload = {
  teamSlug: string
  datasetSlug: string
  datasetItemId: V2DatasetItemPayload['id']
  annotationGroupIds?: string[]
  limitBySize?: number
  offset?: number
  annotationIds?: string[]
}

let controller: AbortController | undefined = undefined

/**
 * Loads stage annotations for the specified stage id
 */
export const loadV2Annotations = async (
  params: Payload,
): Promise<ParsedError | { data: V2AnnotationPayload[] | null }> => {
  controller?.abort()

  const { teamSlug, datasetItemId, annotationGroupIds, limitBySize, offset, annotationIds } = params

  const reqParams: {
    ids?: string[]
    annotation_group_ids?: string[]
    limit_by_size?: number
    offset?: number
  } = {}

  if (annotationGroupIds) {
    reqParams.annotation_group_ids = annotationGroupIds
  }

  if (limitBySize) {
    reqParams.limit_by_size = limitBySize
  }

  if (offset) {
    reqParams.offset = offset
  }

  if (annotationIds) {
    reqParams.ids = annotationIds
  }

  controller = new AbortController()

  try {
    const response = await get<V2AnnotationPayload[]>(
      `v2/teams/${teamSlug}/items/${datasetItemId}/annotations`,
      reqParams,
      { arrayFormat: 'brackets', signal: controller.signal },
    )
    return response
  } catch (error) {
    if (isCancelled(error)) {
      return { data: null }
    }

    if (!isErrorResponse(error)) {
      throw error
    }

    return parseError(error, errorMessages.STAGE_LOAD)
  }
}
