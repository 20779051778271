import type { BoundingBox } from '@/modules/Editor/AnnotationData'
import { isSimpleTable } from '@/modules/Editor/annotationTypes/simpleTable'
import type { IPoint } from '@/modules/Editor/point'
import { isPointOnPath, subPoints } from '@/modules/Editor/point'
import { inferCurrentAnnotationData } from '@/modules/Editor/inferCurrentAnnotationData'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'

import { SIMPLE_TABLE_ANNOTATION_TYPE } from './types'

export type OnTableLineContext =
  | {
      annotation: Annotation
      boundingBox: BoundingBox
      colOffsets: number[]
      rowOffsets: number[]
      type: 'column' | 'row'
      offset: number
    }
  | undefined

type OnTableContext =
  | {
      annotation: Annotation
      boundingBox: BoundingBox
      colOffsets: number[]
      rowOffsets: number[]
    }
  | undefined

export const isOnTable = (point: IPoint, context: ToolContext): OnTableContext => {
  const { activeView } = context.editor

  const annotation = activeView.annotationManager.findTopAnnotationAt(point)
  if (!annotation || annotation.type !== SIMPLE_TABLE_ANNOTATION_TYPE) {
    return
  }

  const data = inferCurrentAnnotationData(annotation, activeView.currentFrameIndex)
  if (!isSimpleTable(data)) {
    return
  }

  const { boundingBox, rowOffsets, colOffsets } = data

  return { annotation, boundingBox, rowOffsets, colOffsets }
}

export const isOnRowOrColumn = (point: IPoint, context: ToolContext): OnTableLineContext => {
  const onTableContext = isOnTable(point, context)
  if (!onTableContext) {
    return
  }

  const { annotation, boundingBox, rowOffsets, colOffsets } = onTableContext

  const tableSize = subPoints(boundingBox.bottomRight, boundingBox.topLeft)

  for (let r = 0; r < rowOffsets.length; r++) {
    const rowOffset = rowOffsets.at(r)
    if (!rowOffset) {
      continue
    }

    const left = {
      x: boundingBox.topLeft.x,
      y: boundingBox.topLeft.y + tableSize.y * rowOffset,
    }
    const right = {
      x: boundingBox.topLeft.x + tableSize.x,
      y: boundingBox.topLeft.y + tableSize.y * rowOffset,
    }

    if (isPointOnPath(point, [left, right], context.editor.activeView.cameraScale)) {
      return {
        annotation,
        boundingBox,
        type: 'row',
        offset: r,
        rowOffsets,
        colOffsets,
      }
    }
  }

  for (let c = 0; c < colOffsets.length; c++) {
    const colOffset = colOffsets.at(c)
    if (!colOffset) {
      continue
    }

    const top = {
      x: boundingBox.topLeft.x + tableSize.x * colOffset,
      y: boundingBox.topLeft.y,
    }
    const bottom = {
      x: boundingBox.topLeft.x + tableSize.x * colOffset,
      y: boundingBox.topLeft.y + tableSize.y,
    }
    if (isPointOnPath(point, [top, bottom], context.editor.activeView.cameraScale)) {
      return {
        annotation,
        boundingBox,
        type: 'column',
        offset: c,
        rowOffsets,
        colOffsets,
      }
    }
  }
}
