import type { TeamAction } from '@/store/modules/team/types'
import type { InvitationPayload } from '@/store/types/InvitationPayload'
import type { MembershipRole } from '@/store/types/MembershipRole'
import * as api from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

export type UpdateInvitationPayload = {
  id: number
  role: MembershipRole
}

/**
 * Update team invitation
 */
export const updateInvitation: TeamAction<UpdateInvitationPayload, InvitationPayload> = async (
  { commit },
  params,
) => {
  let response
  try {
    response = await api.put<InvitationPayload>(`invitations/${params.id}`, { role: params.role })
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.TEAM_MEMBERS_UPDATE_INVITATION, params)
  }

  commit('UPDATE_INVITATION', response.data)
  return response
}
