import { isLegacyPolygonData } from '@/modules/Editor/AnnotationData'
import { isPolygon } from '@/modules/Editor/annotationTypes/polygon'
import type { CompoundPath } from '@/modules/Editor/compoundPath'
import type { IPoint } from '@/modules/Editor/point'
import { createEditablePoint } from '@/modules/Editor/point'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import {
  isImageAnnotation,
  isVideoAnnotation,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import type { View } from '@/modules/Editor/views/view'

export const resolveCompundPath = (view: View, selectedAnnotation: Annotation): CompoundPath => {
  if (isVideoAnnotation(selectedAnnotation)) {
    const { data } = inferVideoData(selectedAnnotation, view.currentFrameIndex)
    if (!isLegacyPolygonData(data)) {
      throw new Error('brush tool: Annotation data is not inferred as polygon data')
    }

    return {
      path: data.path.map(({ x, y }: IPoint) => createEditablePoint({ x, y })),
      additionalPaths:
        data.additionalPaths && data.additionalPaths.length > 0
          ? data.additionalPaths.map((path: IPoint[]) =>
              path.map(({ x, y }: IPoint) => createEditablePoint({ x, y })),
            )
          : [],
    }
  }
  if (isImageAnnotation(selectedAnnotation)) {
    const annotationData = selectedAnnotation.data
    if (!isPolygon(annotationData)) {
      throw new Error(
        'brush tool: Annotation data for image annotation does not look like a polygon',
      )
    }
    return {
      path: annotationData.path.map((p) => ({ ...p })),
      additionalPaths: (annotationData.additionalPaths || []).map((path) =>
        path.map((p) => ({ ...p })),
      ),
    }
  }

  throw new Error('brush tool: Annotation inferred as neither image nor video')
}
