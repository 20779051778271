import type { CompoundPath } from '@/modules/Editor/compoundPath'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { DeletableVertexContext } from '@/modules/Editor/plugins/edit/types'
import { EditPluginError } from '@/modules/Editor/plugins/edit/types'

import { resolvePolygonDeletableVertexContext } from './resolvePolygonDeletableVertexContext'

/**
 * Resolves context for a deletable vertex
 * Returning the matched vertex and its index when resolved.
 * Returns null when not resolved
 *
 * TODO: This is a specialized solution specific for polygon types
 * To support other types, we need a generalized solution
 */
export const resolveDeletableVertexContext = (
  compoundPath: CompoundPath,
  annotation: Annotation,
  vertexIndex: number | null,
): DeletableVertexContext => {
  const deletionAllowed = ['line', 'polygon', 'skeleton', 'eye'].includes(annotation.type)
  if (!deletionAllowed) {
    return null
  }

  if (annotation.type === 'polygon') {
    if (!compoundPath.path) {
      return null
    }
    // The following condition should never be met
    // There seems to be typing issues in the codebase that force us to do this
    // Once we eliminate 'rendererFor', this can be dropped
    if (compoundPath instanceof Array) {
      throw new EditPluginError(
        'Polygon path is encoded as array of paths. Should be an array of points instead',
      )
    }
    return resolvePolygonDeletableVertexContext(compoundPath, vertexIndex)
  }
  if (annotation.type === 'line') {
    if (!compoundPath.path) {
      return null
    }
    // The following condition should never be met
    // There seems to be typing issues in the codebase that force us to do this
    // Once we eliminate 'rendererFor', this can be dropped
    if (!(compoundPath.path instanceof Array)) {
      return null
    }

    const paths = [compoundPath.path]
    for (const path of paths) {
      if (path.length <= 2) {
        return null
      }
      if (vertexIndex !== null && vertexIndex >= 0) {
        return { action: 'deleteVertex', content: [path, vertexIndex] }
      }
    }
    return null
  }
  if (vertexIndex !== null && vertexIndex >= 0) {
    return { action: 'deleteVertex', content: [compoundPath.path, vertexIndex] }
  }

  return null
}
