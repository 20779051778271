import type { TeamAction } from '@/store/modules/team/types'
import type { InvitationPayload } from '@/store/types/InvitationPayload'
import type { MembershipRole } from '@/store/types/MembershipRole'
import * as api from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

export type UpdateMembershipPayload = {
  id: number
  role: MembershipRole
}

/**
 * Update a team membership
 */
export const updateMembership: TeamAction<UpdateMembershipPayload, InvitationPayload> = async (
  { commit },
  params,
) => {
  let response

  try {
    response = await api.put<InvitationPayload>(`memberships/${params.id}`, { role: params.role })
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.TEAM_MEMBERS_UPDATE_MEMBERSHIP, params)
  }

  commit('UPDATE_MEMBERSHIP', response.data)

  return response
}
