import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { isGlobalSubAnnotationType, mainAnnotationTypes } from '@/core/annotationTypes'
import type { FrameData, SequenceData } from '@/core/annotations'
import type { AnnotationData, VideoAnnotationData } from '@/modules/Editor/AnnotationData'
import { enableInterpolateByDefault } from '@/modules/Editor/annotationInterpolation'
import { autoAnnotateSerializer } from '@/modules/Editor/serialization/autoAnnotateSerializer'
import { serializeFrame } from '@/modules/Editor/serialization/serializeFrame'
import { frameDataIsLoaded } from '@/modules/Editor/utils/pagination'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import {
  isImageAnnotation,
  isVideoAnnotation,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import { getRasterIdFromMaskAnnotation } from '@/modules/Editor/plugins/mask/utils/shared/getRasterIdFromMaskAnnotation'
import { maskSerializer } from '@/modules/Editor/serialization/maskSerializer'
import { getInitialVideoAnnotationSegments } from '@/modules/Editor/utils/getInitialVideoAnnotationSegments'
import type { View } from '@/modules/Editor/views/view'
// eslint-disable-next-line boundaries/element-types
import { VIDEO_MASK_ANNOTATION_DURATION_DEFAULT } from '@/store/modules/workview/constants'
// eslint-disable-next-line boundaries/element-types
import type { V2AnnotationPayload } from '@/store/types/StageAnnotationPayload'

import type { UpdateMeta } from './types'

export type AnnotationWithItemId = {
  annotation: V2AnnotationPayload
  itemId: string
}

/**
 * Options to change how we serialize an annotation. Is left as an options object
 * to make its effects clearer.
 */
type SerializationOptions = {
  /**
   * If used, then only the frames in this array will be serialized. If not used,
   * then all frames will be serialized.
   */
  framesToSerialize?: number[]
  updateMeta?: UpdateMeta
}

/**
 * Serializes data for mask raster annotations.
 */
const serializeRasterData = (
  annotation: Annotation,
  view: View,
  updateMeta?: UpdateMeta,
): FrameData | SequenceData | null => {
  const raster = view.rasterManager.getRasterForFileInView()

  if (isImageAnnotation(annotation)) {
    const data = maskSerializer.serialize(annotation.data, raster, annotation.id) as FrameData
    for (const subAnnotation of annotation.subAnnotations) {
      data[subAnnotation.type] = subAnnotation.data
    }
    return data
  }

  if (isVideoAnnotation(annotation)) {
    return maskSerializer.serialize(annotation.data, raster, annotation.id, updateMeta)
  }

  return null
}

/**
 * Serializes data for object (instance) annotations.
 */
const serializeObjectData = (
  annotation: Annotation,
  { framesToSerialize }: SerializationOptions,
): FrameData | SequenceData | null => {
  // Skeleton serializer needs Annotation Class to get edges property
  const annotationClass =
    annotation.type === 'skeleton' || annotation.type === 'eye'
      ? annotation.annotationClass
      : undefined

  const skeleton = annotationClass?.skeletonMetaData
  if (isImageAnnotation(annotation)) {
    const extra = annotation.type === 'skeleton' ? { skeleton } : undefined
    const data = serializeFrame(annotation.type, annotation.data, extra)

    for (const subAnnotation of annotation.subAnnotations) {
      // Do not override the data if it already exists
      // NOTE: auto_annotate can be created by the SAM tool.
      if (subAnnotation.type === 'auto_annotate' && data[subAnnotation.type]) {
        continue
      }
      data[subAnnotation.type] = subAnnotation.data
    }
    return data
  }

  if (!isVideoAnnotation(annotation)) {
    return null
  }

  const serializedFrames: SequenceData['frames'] = {}

  const frames = annotation.data.frames

  for (const [frame, data] of Object.entries(frames)) {
    // Pagination is enabled and frame is empty so we skip this frame
    if (!frameDataIsLoaded(data)) {
      continue
    }

    if (framesToSerialize && !framesToSerialize.includes(Number(frame))) {
      continue
    }

    const extra = annotation.type === 'skeleton' ? { skeleton } : undefined
    const serializedFrame: FrameData & { keyframe?: boolean } = serializeFrame(
      annotation.type,
      data,
      extra,
    )

    if (data.keyframe === undefined) {
      serializedFrame.keyframe = true
    } else {
      serializedFrame.keyframe = data.keyframe
    }

    if (data.auto_annotate) {
      serializedFrame.auto_annotate = autoAnnotateSerializer.serialize(data.auto_annotate)
    }

    serializedFrames[frame] = serializedFrame
  }

  // Frames size might be smaller than the actual number of keyframes,
  // but we need it to map the sub annotation values
  // Note: that we use a support index as neither the subAnnotations.frames or its value
  // works well, as the latter is an array of array which have an un-matching length
  // const valueIdx = 0
  const keyframeList = Object.keys(annotation.data.sub_frames || {})
  const subAnnotations = Object.values(annotation.subAnnotations.frames || {})

  const serializedSubFrames: SequenceData['sub_frames'] = {}

  subAnnotations.forEach((frame, idx) => {
    const keyframe = keyframeList[idx]
    if (keyframeList.length === 0) {
      return
    }

    const serializedSubFrame: FrameData & { keyframe?: boolean } = { keyframe: true }

    if (!frame) {
      return
    }

    frame.forEach((f) => {
      // the fact this can be an array indicates a potential
      // issue further up the pipeline
      const subAnnotation: Annotation = Array.isArray(f) ? f[0] : f
      // we are effectively bypassing subannotation serialization here
      // instead, we assume the subannotation data is already deserialized
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      serializedSubFrame[subAnnotation.type] = subAnnotation.data as any
    })

    serializedSubFrames[keyframe] = serializedSubFrame
  })

  return {
    ...annotation.data,
    frames: serializedFrames,
    sub_frames: serializedSubFrames,
  }
}

/**
 * Resolves frames and sub_frames of VideoAnnotationData from ImageAnnotationData
 * When the annotation is created for the first time, the data will be ImageAnnotationData format.
 * If it is video annotation, we need to resolve VideoAnnotationData format.
 * This function just resolves `frames` and `sub_frames` of VideoAnnotationData.
 */
const resolveVideoFrameData = (
  data: AnnotationData,
  startFrame: number,
): Pick<VideoAnnotationData, 'frames' | 'sub_frames'> => {
  const frameFields = [...mainAnnotationTypes, 'auto_annotate', 'loaded']
  return {
    frames: {
      [startFrame]: {
        ...pick(data, frameFields),
        keyframe: true,
      },
    },
    sub_frames: {
      [startFrame]: {
        ...omit(data, frameFields),
        keyframe: true,
      },
    },
  }
}

const resolveVideoGlobalData = (
  subAnnotations: Annotation['subAnnotations'],
): VideoAnnotationData['global_sub_types'] => {
  if (!subAnnotations || !Array.isArray(subAnnotations)) {
    return {}
  }

  const videoGlobalData: VideoAnnotationData['global_sub_types'] = {}
  subAnnotations.forEach((subAnnotation) => {
    const subAnnotationClass = subAnnotation.annotationClass
    const subAnnotationType = subAnnotation.type
    if (!isGlobalSubAnnotationType(subAnnotationType)) {
      return
    }
    if (subAnnotationClass.granularity?.[subAnnotationType] === 'annotation') {
      videoGlobalData[subAnnotationType] = subAnnotation.data
    }
  })
  return videoGlobalData
}

const isDeletedRasterAnnotaton = (annotation: Annotation, view: View): boolean => {
  if (annotation.type !== 'mask') {
    return false
  }

  const rasterId = getRasterIdFromMaskAnnotation(annotation)

  if (!rasterId) {
    return false
  }

  const hasRaster = view.rasterManager.hasRaster(rasterId)

  return !hasRaster
}

export type SerializerProps = {
  slotName: string
  isProcessedAsVideo: boolean
  videoAnnotationDuration: number
  frameIndex: number
  totalFrames: number
}

/**
 * Serialized an annotation from the engine to be stored in the application's data layer.
 * @param annotation The engine representation of the Annotation.
 * @returns The serialized annotation.
 *
 * TODO: we need to refactor this function DAR-3023
 */
export const serializeAnnotation = (
  annotation: Annotation,
  {
    slotName,
    isProcessedAsVideo,
    videoAnnotationDuration,
    frameIndex,
    totalFrames,
  }: SerializerProps,
  serializationOptions: SerializationOptions = {},
): Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'> | null => {
  const data = serializeObjectData(annotation, {
    framesToSerialize: serializationOptions.framesToSerialize,
  })
  if (!data) {
    return null
  }

  let annotationData = data

  if (
    isProcessedAsVideo &&
    // the annotation is not being pasted. a pasted annotation already has
    // frames and segments properly set in. Search for 'clipboard.paste'
    !('frames' in data)
  ) {
    // this means we are serializing a newly created, not yet persisted video
    // we have to give the one and only segment in the annotation the proper duration
    // we also have to structure the data corectly

    const duration = videoAnnotationDuration
    const segments = getInitialVideoAnnotationSegments(frameIndex, totalFrames, duration)

    const startFrame = segments[0][0]

    // FIXME: The cast here shows that resolveVideoFrameData is confused about
    //  what kind of payloads it's dealing with
    const { frames, sub_frames: subFrames } = resolveVideoFrameData(
      data,
      startFrame,
    ) as SequenceData

    const globalVideoData = resolveVideoGlobalData(annotation.subAnnotations)
    const interpolated = enableInterpolateByDefault(annotation.type)

    annotationData = {
      ...annotation.data,
      frames,
      sub_frames: subFrames,
      global_sub_types: globalVideoData,
      segments,
      interpolated,
    }
  }

  // Note there are properties on here that are not needed for V2, but we keep them here for now
  // Whilst we have V1. Once V1 is gone a lot of this can be cleaned up.
  return {
    annotation_class_id: annotation.classId,
    data: annotationData,
    id: annotation.id,
    properties: annotation.properties,
    context_keys: {
      slot_names: [slotName],
    },
    // z_index will be overridden right before the API request
    z_index: null,
  }
}

export const serializeRasterAnnotation = (
  annotation: Annotation,
  view: View,
  serializationOptions: SerializationOptions = {},
): Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'> | null => {
  const data = serializeRasterData(annotation, view, serializationOptions.updateMeta)
  if (!data) {
    return null
  }

  let annotationData = data

  if (
    view.fileManager.isProcessedAsVideo &&
    // the annotation is not being pasted. a pasted annotation already has
    // frames and segments properly set in. Search for 'clipboard.paste'
    !('frames' in data) &&
    !isDeletedRasterAnnotaton(annotation, view)
  ) {
    // this means we are serializing a newly created, not yet persisted video
    // we have to give the one and only segment in the annotation the proper duration
    // we also have to structure the data corectly

    const { videoAnnotationDuration } = view.editor.store.state.workview

    const duration =
      annotation.type === 'mask' ? VIDEO_MASK_ANNOTATION_DURATION_DEFAULT : videoAnnotationDuration
    const segments = getInitialVideoAnnotationSegments(
      view.currentFrameIndex,
      view.totalFrames,
      duration,
    )

    const startFrame = segments[0][0]

    // FIXME: The cast here shows that resolveVideoFrameData is confused about
    //  what kind of payloads it's dealing with
    const { frames, sub_frames: subFrames } = resolveVideoFrameData(
      data,
      startFrame,
    ) as SequenceData

    const interpolated = enableInterpolateByDefault(annotation.type)

    annotationData = {
      ...annotation.data,
      frames,
      sub_frames: subFrames,
      segments,
      interpolated,
    }
  }

  return {
    annotation_class_id: annotation.classId,
    data: annotationData,
    id: annotation.id,
    properties: annotation.properties,
    context_keys: {
      slot_names: [view.fileManager.slotName],
    },
    // z_index will be overridden right before the API request
    z_index: null,
  }
}
