import type { Camera } from '@/modules/Editor/camera'
import type { IPoint } from '@/modules/Editor/point'
import type { ReferenceLinesLayer } from '@/modules/Editor/models/layers/referenceLinesLayer'
import {
  HALF_CROSSHAIRS_SIZE_CANVAS_PIXELS,
  CROSSHAIRS_EDGE_SPACING_LR,
  CROSSHAIRS_EDGE_SPACING_TB,
} from '@/modules/Editor/utils/referenceLines/consts'

/**
 * Calculate the lines needed to render the reference lines and returns the
 * coordinates in image space.
 *
 * We only render a line if it should be visible on the screen. Its possible to pan
 * such that a line should not be rendered.
 *
 * @param centerImage The center of the crosshairs
 * @param camera The camera of the view.
 * @param layer The reference lines layer they will be rendered on.
 *
 * @returns An array of lines each defined by two image points.
 */
export function getImageReferenceLines(
  centerImage: IPoint,
  camera: Camera,
  layer: ReferenceLinesLayer,
): IPoint[][] {
  const { canvas } = layer
  if (!canvas) {
    return []
  }

  const lines: IPoint[][] = []

  const centerCanvas = camera.imageViewToCanvasView(centerImage)
  const leftHorizontalLineCanvas: IPoint[] = [
    { x: CROSSHAIRS_EDGE_SPACING_LR, y: centerCanvas.y },
    { x: centerCanvas.x - HALF_CROSSHAIRS_SIZE_CANVAS_PIXELS, y: centerCanvas.y },
  ]

  if (leftHorizontalLineCanvas[1].x > leftHorizontalLineCanvas[0].x) {
    lines.push(leftHorizontalLineCanvas.map((cp) => camera.canvasViewToImageView(cp)))
  }

  const rightHorizontalLineCanvas: IPoint[] = [
    { x: centerCanvas.x + HALF_CROSSHAIRS_SIZE_CANVAS_PIXELS, y: centerCanvas.y },
    { x: canvas.width - CROSSHAIRS_EDGE_SPACING_LR, y: centerCanvas.y },
  ]

  if (rightHorizontalLineCanvas[1].x > rightHorizontalLineCanvas[0].x) {
    lines.push(rightHorizontalLineCanvas.map((cp) => camera.canvasViewToImageView(cp)))
  }

  const topVerticalLineCanvas: IPoint[] = [
    { x: centerCanvas.x, y: CROSSHAIRS_EDGE_SPACING_TB },
    { x: centerCanvas.x, y: centerCanvas.y - HALF_CROSSHAIRS_SIZE_CANVAS_PIXELS },
  ]

  if (topVerticalLineCanvas[1].y > topVerticalLineCanvas[0].y) {
    lines.push(topVerticalLineCanvas.map((cp) => camera.canvasViewToImageView(cp)))
  }

  const bottomVerticalLineCanvas: IPoint[] = [
    { x: centerCanvas.x, y: centerCanvas.y + HALF_CROSSHAIRS_SIZE_CANVAS_PIXELS },
    { x: centerCanvas.x, y: canvas.height - CROSSHAIRS_EDGE_SPACING_TB },
  ]

  if (bottomVerticalLineCanvas[1].y > bottomVerticalLineCanvas[0].y) {
    lines.push(bottomVerticalLineCanvas.map((cp) => camera.canvasViewToImageView(cp)))
  }

  return lines
}
