import type { Eye, Skeleton } from '@/modules/Editor/AnnotationData'

export const updateNodes = <T extends Skeleton | Eye>(data: T, index: number): T['nodes'] => {
  const updatedNodes = []
  for (let i = 0; i < data.nodes.length; i++) {
    const node = data.nodes[i]
    if (i === index) {
      updatedNodes.push({ ...node, occluded: true })
    } else {
      updatedNodes.push(node)
    }
  }
  return updatedNodes
}
