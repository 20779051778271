type Params = unknown[]

type QueueItemStatus = 'IDLE' | 'RUNNING'

interface QueueItem<T, A extends Params> {
  func: (...args: A) => Promise<T>
  args: A
  resolve: (value: T) => void
  reject: (reason: unknown) => void
  groupId: string
  status: QueueItemStatus
}

type QueueItemType = QueueItem<unknown, Params>

/**
 * holds an asyncronous queue that you can add promise operations to.
 * it returns the single operation promise, so you can get the value when it's run
 */
export class AsyncOperationQueue {
  queue: Array<QueueItemType> = []

  enqueue<T, A extends Params>(
    groupId: string,
    func: (...args: A) => Promise<T>,
    ...args: A
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const item: QueueItem<T, A> = { func, args, resolve, reject, groupId, status: 'IDLE' }

      // Removes any pending (non-running) operation with the same group id
      this.clearByBroupId(groupId)

      this.queue.push(item as unknown as QueueItemType)
      if (this.queue.length === 1) {
        this.dequeue()
      }
    })
  }

  clearByBroupId(groupId: string): void {
    this.queue = this.queue.filter(
      (queuedItem) => !(queuedItem.groupId === groupId && queuedItem.status === 'IDLE'),
    )
  }

  clear(): void {
    this.queue = this.queue.filter((queuedItem) => queuedItem.status !== 'IDLE')
  }

  size(): number {
    return this.queue.length
  }

  private async dequeue(): Promise<void> {
    if (this.queue.length === 0) {
      return
    }
    const { func, args, resolve, reject } = this.queue[0] as QueueItem<unknown, Params>
    try {
      this.queue[0].status = 'RUNNING'
      const result = await func(...args)
      resolve(result)
    } catch (e) {
      reject(e)
    }
    this.queue.shift()
    if (this.queue.length > 0) {
      this.dequeue()
    }
  }
}
