import * as api from '@/backend/api'
import type { AttributePayload } from '@/store/types/AttributePayload'
import { errorMessages, isErrorResponse } from '@/backend/error'
import { useToast } from '@/uiKit/Toast/useToast'
import { loadDatasetAnnotationAttributes as backendLoadLoadDatasetAnnotationAttributes } from '@/backend/darwin'
import { loadClassAnnotationAttributes as backendLoadClassAnnotationAttributes } from '@/backend/darwin'
import { createOrFetchAnnotationAttribute as backendCreateOrFetchAnnotationAttribute } from '@/backend/darwin'

import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { getAttributeColor } from './attributes'
import type { PartialRecord } from '@/core/helperTypes'

export const useAnnotationAttributeStore = defineStore('annotationAttribute', () => {
  const toast = useToast()

  const attributes = ref<AttributePayload[]>([])

  const _setAttribute = (data: { classId?: number; attributes: AttributePayload[] }): void => {
    const { classId, attributes: attributesPayload } = data

    if (classId) {
      // only replace entries for the provided class id
      attributes.value = attributes.value.filter((entry) => entry.class_id !== classId)
      attributes.value.push(...attributesPayload)
    } else {
      // if no classId is set replace all attributes
      attributes.value = attributesPayload
    }
  }

  const _deleteAttribute = (data: AttributePayload): void => {
    const idx = attributes.value.findIndex((elem) => elem.id === data.id)
    if (idx >= 0) {
      attributes.value.splice(idx, 1)
    }
  }

  /**
   * Update the attribute with the new details
   */
  const updateAnnotationAttribute = async (args: {
    classId: number
    id: string
    name: string
    color: string
  }): Promise<AttributePayload | null> => {
    const { classId, id, name, color } = args
    const path = `annotation_classes/${classId}/attributes/${id}`

    let response
    try {
      response = await api.put<AttributePayload>(path, {
        name,
        color,
      })
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }

      toast.warning({ meta: { title: errorMessages.ANNOTATION_CLASS_UPDATE.default } })

      return null
    }

    const { data } = response
    const idx = attributes.value.findIndex((elem) => elem.id === data.id)
    if (idx < 0) {
      attributes.value.push(data)
    } else {
      attributes.value.splice(idx, 1, data)
    }

    return response.data
  }

  /**
   * Load annotation attributes for a specific class
   */
  const loadDatasetAnnotationAttributes = async (payload: {
    teamSlug: string
  }): Promise<AttributePayload[]> => {
    const response = await backendLoadLoadDatasetAnnotationAttributes(payload)
    if ('data' in response) {
      _setAttribute({ attributes: response.data })

      return response.data
    }

    if ('error' in response && typeof response.error.message === 'string') {
      toast.warning({ meta: { title: response.error.message } })
    }

    return []
  }

  /**
   * Load annotation attributes for a specific class
   */
  const loadClassAnnotationAttributes = async (payload: {
    classId: number
  }): Promise<AttributePayload[]> => {
    const response = await backendLoadClassAnnotationAttributes(payload)
    if ('data' in response) {
      const { classId } = payload
      _setAttribute({ classId, attributes: response.data })

      return response.data
    }

    if ('error' in response && typeof response.error.message === 'string') {
      toast.warning({ meta: { title: response.error.message } })
    }

    return []
  }

  /**
   * Update the attribute with the new details
   */
  const deleteAnnotationAttribute = async (args: {
    classId: number | undefined
    id: string
  }): Promise<AttributePayload | null> => {
    const { classId, id } = args
    const path = `annotation_classes/${classId}/attributes/${id}`

    let response
    try {
      response = await api.remove<AttributePayload>(path)
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }
      toast.warning({ meta: { title: errorMessages.ATTRIBUTE_DELETE.default } })
    }

    if (response && 'data' in response) {
      _deleteAttribute(response.data)
      return response.data
    }

    return null
  }

  /**
   * Fetch attribute id if it already exists, otherwise creates it.
   */
  const createOrFetchAnnotationAttribute = async (
    classId: number,
    name: string,
  ): Promise<AttributePayload | null> => {
    const payload = {
      classId: classId,
      name,
      color: getAttributeColor(name),
    }

    const response = await backendCreateOrFetchAnnotationAttribute(payload)

    if ('data' in response) {
      const { data } = response
      const idx = attributes.value.findIndex((elem) => elem.id === data.id)
      if (idx < 0) {
        attributes.value.push(data)
      } else {
        attributes.value.splice(idx, 1, data)
      }

      return response.data
    }

    if ('error' in response && typeof response.error.message === 'string') {
      toast.warning({ meta: { title: response.error.message } })
    }

    return null
  }

  const attributesByAnnotationClassId = computed(() =>
    attributes.value.reduce<PartialRecord<number, AttributePayload[]>>(
      (acc, entry: AttributePayload) => {
        if (!entry.class_id) {
          return acc
        }
        if (!acc[entry.class_id]) {
          acc[entry.class_id] = []
        }
        acc[entry.class_id]?.push(entry)
        return acc
      },
      {},
    ),
  )

  return {
    attributes,
    updateAnnotationAttribute,
    attributesByAnnotationClassId,
    loadDatasetAnnotationAttributes,
    loadClassAnnotationAttributes,
    deleteAnnotationAttribute,
    createOrFetchAnnotationAttribute,
  }
})
