import type { CommentThread } from '@/modules/Editor/iproviders/types'
import type { ApiResponse } from '@/store/types'
import { get } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

type Params = {
  datasetItemId: string
  teamSlug: string
}

/**
 * Lists comment threads associated to a 2.0 dataset item
 */
export const loadV2CommentThreads = async (
  params: Params,
): Promise<ApiResponse<CommentThread[]> | ParsedError> => {
  const { datasetItemId, teamSlug } = params
  const path = `v2/teams/${teamSlug}/items/${datasetItemId}/comment_threads`

  try {
    const response = await get<CommentThread[]>(path)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.COMMENT_THREADS_LOAD)
  }
}
