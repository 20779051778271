import { MainAnnotationType } from '@/core/annotationTypes'
import type { Polygon } from '@/modules/Editor/AnnotationData'
import { isPolygon } from '@/modules/Editor/annotationTypes/polygon'
import type { Editor } from '@/modules/Editor/editor'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import {
  isImageAnnotation,
  isVideoAnnotation,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import type { VideoAnnotation } from '@/modules/Editor/models/annotation/types'
import { findClosestKeyFrame } from '@/modules/Editor/utils'
import { setContext } from '@/services/sentry'

/**
 * Resolves polygon data for a video annotation.
 *
 * Since an annotation could span multiple frames, the correct video data
 * depends on which frame the editor is currently on.
 *
 * This is the entry from the closest keyframe to the left of the current frame,
 * or the closest to the right, if there are no entries to the left at all.
 */
const retrieveVideoPolygonData = (
  editor: Editor,
  annotation: VideoAnnotation,
): Polygon | undefined => {
  const currentFrameIndex = editor.activeView.currentFrameIndex
  if (currentFrameIndex === undefined || currentFrameIndex < 0) {
    return
  }

  const frame = findClosestKeyFrame(annotation, currentFrameIndex, 'path')

  if (frame) {
    if (!isPolygon(frame)) {
      setContext('retrievePolygonData', { data: annotation.data, type: annotation.type })
      throw new Error('Received a video annotation of type polygon, with incorrect data')
    }
    const { path, additionalPaths } = frame
    return { path, additionalPaths }
  }
}

/**
 * Retrieves the contents of the auto_annotate key, if any,
 * within an image or video annotation.
 */
export const retrievePolygonData = (
  annotation: Annotation,
  editor: Editor,
): Polygon | undefined => {
  if (isVideoAnnotation(annotation) && annotation.type === MainAnnotationType.Polygon) {
    return retrieveVideoPolygonData(editor, annotation)
  }

  if (isImageAnnotation(annotation) && annotation.type === MainAnnotationType.Polygon) {
    if (!isPolygon(annotation.data)) {
      setContext('retrievePolygonData', { data: annotation.data, type: annotation.type })
      throw new Error('Received an annotation of type polygon, with incorrect data')
    }
    const { path, additionalPaths } = annotation.data
    return { path, additionalPaths }
  }

  setContext('retrievePolygonData', { annotation })
  throw new Error(
    [
      'Received or loaded annotation with invalid data.',
      '"data" field has the structure of a video,',
      'while the "subAnnotations" field has the structure of an image or vice-versa.',
    ].join(' '),
  )
}
