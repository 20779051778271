import clamp from 'lodash/clamp'

// eslint-disable-next-line boundaries/element-types
import { parseRGBA } from '@/uiKit/colorPalette'
// eslint-disable-next-line boundaries/element-types
import { rgbaString } from '@/uiKit/colorPalette'

/**
 * Change the alpha value of the RGBA color
 * @param rgbaClr original color
 * @param delta target value value to add to the alpha
 * @returns Alpha value updated color
 */
export const changeRGBABrightness = (rgbaClr: string, delta: number): string => {
  const rgba = parseRGBA(rgbaClr)
  return rgbaString(rgba, clamp(rgba.a + delta, 0, 1))
}

export const changeRGBAAlpha = (rgbaClr: string, alpha: number): string => {
  const rgba = parseRGBA(rgbaClr)
  return rgbaString(rgba, alpha)
}
