import type { MeasureRegionsPayload } from '@/modules/Editor/MeasureOverlayData'
import { MedicalVolumePlane } from '@/modules/Editor/MedicalMetadata'
import type { MedicalMetadata } from '@/modules/Editor/MedicalMetadata'
import { resolvePixdims } from '@/modules/Editor/utils/radiology/resolvePixdims'

/**
 * Gets the measure region for a particular slot given the item's metadata.
 *
 * For volumes, some views are resampled by the backend so that the correct
 * spacing is displayed, as most pixels in reconstructed views are rectangular.
 * We need to account for this by multiplying by the ratio of the number of
 * pixels in the source data, to the number in the resampled image.
 *
 * @param slotName The name of slot to calculate the measure region for.
 * @param metadata The metadata for the item.
 *
 * @returns The measure region payload, if calculable, otherwise null.
 */
export const getRadiologyMeasureRegions = (
  slotName: string,
  metadata?: {
    medical?: MedicalMetadata
    width: number
    height: number
    shape?: [number, number, number, number]
  },
): MeasureRegionsPayload | null => {
  if (!metadata) {
    return null
  }

  const medicalMetadata = metadata.medical

  if (!medicalMetadata) {
    return null
  }

  const measureRegion = medicalMetadata.measure_regions?.[slotName]

  if (!measureRegion) {
    return null
  }

  if (medicalMetadata.is_volume) {
    // Scale measure regions by rescaled image size
    const { shape } = metadata
    const targetImageDimensions = [metadata.width, metadata.height]
    const pixdims = resolvePixdims(medicalMetadata)

    if (!shape) {
      // Fallback if shape is not present
      return measureRegion
    }

    const dims = [shape[1], shape[2], shape[3]]

    let sourcePixelDimensions: [number, number]
    let sourceDimensions: [number, number]

    const plane = medicalMetadata.plane_map[slotName]

    // Note, this is based on the current approach whereby all volumes are LPI.
    // The logic may have to change if we ever send other orientations to the frontend.
    if (plane === MedicalVolumePlane.AXIAL) {
      sourcePixelDimensions = [pixdims[0], pixdims[1]]
      sourceDimensions = [dims[0], dims[1]]
    } else if (plane === MedicalVolumePlane.CORONAL) {
      sourcePixelDimensions = [pixdims[0], pixdims[2]]
      sourceDimensions = [dims[0], dims[2]]
    } else if (plane === MedicalVolumePlane.SAGITTAL) {
      sourcePixelDimensions = [pixdims[1], pixdims[2]]
      sourceDimensions = [dims[1], dims[2]]
    } else {
      throw new Error('unrecognised orientation')
    }

    const delta = {
      x: (sourcePixelDimensions[0] * sourceDimensions[0]) / targetImageDimensions[0],
      y: (sourcePixelDimensions[1] * sourceDimensions[1]) / targetImageDimensions[1],
    }

    return { ...measureRegion, ...{ delta } }
  }
  return measureRegion
}
