import type { BoundingBox } from '@/modules/Editor/AnnotationData'
import { createEditablePoint, addPoints, mulScalar, subPoints } from '@/modules/Editor/point'

import type { LinearInterpolationParams } from './types'

const isValidBoundingBox = (boundingBox: BoundingBox): boolean => {
  const { topLeft, topRight, bottomRight, bottomLeft } = boundingBox

  return !!topLeft && !!topRight && !!bottomRight && !!bottomLeft
}

export const boundingBoxInterpolation = (
  params: LinearInterpolationParams,
  prevData: BoundingBox,
  nextData: BoundingBox,
): BoundingBox => {
  const { algorithm, interpolationFactor } = params

  if (!isValidBoundingBox(prevData) && !isValidBoundingBox(nextData)) {
    throw new Error('Interpolate: previous and next bounding boxes are not valid')
  }

  if (!isValidBoundingBox(prevData)) {
    return nextData
  }

  if (!isValidBoundingBox(nextData)) {
    return prevData
  }

  if (algorithm && !algorithm.startsWith('linear')) {
    throw new Error(
      `Interpolate: bounding boxes don't support '${algorithm}' interpolation algorithm`,
    )
  }

  return {
    topLeft: createEditablePoint(
      addPoints(
        prevData.topLeft,
        mulScalar(subPoints(nextData.topLeft, prevData.topLeft), interpolationFactor),
      ),
    ),
    topRight: createEditablePoint(
      addPoints(
        prevData.topRight,
        mulScalar(subPoints(nextData.topRight, prevData.topRight), interpolationFactor),
      ),
    ),
    bottomRight: createEditablePoint(
      addPoints(
        prevData.bottomRight,
        mulScalar(subPoints(nextData.bottomRight, prevData.bottomRight), interpolationFactor),
      ),
    ),
    bottomLeft: createEditablePoint(
      addPoints(
        prevData.bottomLeft,
        mulScalar(subPoints(nextData.bottomLeft, prevData.bottomLeft), interpolationFactor),
      ),
    ),
  }
}
