import type { View } from '@/modules/Editor/views/view'
import { isDicomView } from '@/modules/Editor/utils/radiology/isDicomView'

/**
 * Determines whether the 3D brush is available for a given view.
 *
 * @param {View} view - The view object to check for 3D brush availability.
 * @returns {boolean} - True if the 3D brush is available, false otherwise.
 */
export const is3dBrushAvailable = (view: View): boolean => {
  if (!isDicomView(view)) {
    return false
  }

  return view.isRadiologicalVolumeView
}
