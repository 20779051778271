import type { IPoint } from '@/modules/Editor/point'

/** Build a path2d object, stay in the image coordinate space */
export const buildPath2DImageView = (path: IPoint[], closePath: boolean = true): Path2D => {
  const [start, ...rest] = path
  const canvasPath = new Path2D()

  if (!start) {
    return canvasPath
  }

  canvasPath.moveTo(start.x, start.y)
  rest.forEach((p) => canvasPath.lineTo(p.x, p.y))

  if (closePath) {
    canvasPath.closePath()
  }

  return canvasPath
}
