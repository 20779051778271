import type { AnnotationData } from '@/modules/Editor/AnnotationData'
import type { Action } from '@/modules/Editor/managers/actionManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import type { View } from '@/modules/Editor/views/view'

// Updates the annotation data inside an action.
export function updateAnnotationData(
  view: View,
  annotation: Annotation,
  oldData: AnnotationData,
  newData: AnnotationData,
): Action {
  const frameIndex = view.currentFrameIndex
  return {
    do(): boolean {
      annotation.centroid = undefined

      view.annotationManager.updateAnnotation(
        shallowCloneAnnotation(annotation, { data: newData }),
        {
          updatedFramesIndices: [frameIndex],
        },
      )
      return true
    },

    undo(): boolean {
      annotation.centroid = undefined
      view.annotationManager.updateAnnotation(
        shallowCloneAnnotation(annotation, { data: oldData }),
        {
          updatedFramesIndices: [frameIndex],
        },
      )
      return true
    },
  }
}
