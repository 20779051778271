import { isEye } from '@/modules/Editor/annotationTypes/eye'
import { isSkeleton } from '@/modules/Editor/annotationTypes/skeleton'
import type { Action } from '@/modules/Editor/managers/actionManager'
import { inferCurrentAnnotationData } from '@/modules/Editor/inferCurrentAnnotationData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { isVideoAnnotation } from '@/modules/Editor/models/annotation/annotationKindValidator'
import type { View } from '@/modules/Editor/views/view'

import { updateNodes } from './updateNodes'
import { updateImageAnnotation } from './utils/updateImageAnnotation'
import { updateVideoAnnotation } from './utils/updateVideoAnnotation'

/**
 * Occlude a vertex from the skeleton annotation at the specified index.
 *
 * The path must be a reference in order for update to work correctly.
 *
 * @param {View} view instance
 * @param {Annotation} annotation Annotation whose path we're occluding a vertex from
 * @param {number} index The index of the vertex to occlude
 */
export const occludeVertexAction = (view: View, annotation: Annotation, index: number): Action => {
  const data = inferCurrentAnnotationData(annotation, view.currentFrameIndex)

  if (!(isSkeleton(data) || isEye(data))) {
    throw new Error('action: expected annotation of skeleton type')
  }

  const frameIndex = view.currentFrameIndex

  const nodes = updateNodes(data, index)

  const updatedAnnotation = isVideoAnnotation(annotation)
    ? updateVideoAnnotation(view, annotation, { nodes })
    : updateImageAnnotation(annotation, { nodes })

  return {
    do(): boolean {
      view.annotationManager.updateAnnotation(updatedAnnotation, {
        updatedFramesIndices: [frameIndex],
      })
      return true
    },
    undo(): boolean {
      view.annotationManager.updateAnnotation(annotation, { updatedFramesIndices: [frameIndex] })
      return true
    },
  }
}
