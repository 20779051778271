<script lang="ts">
export default { name: 'Icon' }
</script>

<script lang="ts" setup>
// see src/assets/darwin-icon-font/adding-icons-to-icon-font.md

import type { IconNames } from './icons'

defineProps<{ name: IconNames }>()
</script>

<template>
  <span
    class="darwin-icon"
    :class="`icon-${name}`"
    v-bind="$attrs"
  >
    <span class="path1"></span>
    <span class="path2"></span>
  </span>
</template>

<style lang="scss" scoped>
.darwin-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20px;
  aspect-ratio: 1 / 1;
  font-size: 16px;
}
</style>
