/*
  The code in this module is a workaround that is necessary to unblock
  the loading of videos inside the Intercom product tour iframe.
  They are blocked due to cross-origin isolation being enabled
  for our website in order to utilize features like SharedArrayBuffer
  for WebAssembly multithreading. To allow the loading of cross-origin resources,
  the iframe must have the 'credentialless' attribute set to 'true',
  but Intercom's script doesn't set it when inserting the product tour iframe.
  Therefore, we set it ourselves by observing DOM changes and
  reloading the iframe's content to apply the attribute's value.
  This logic needs to be removed after the fix from Intercom's side (ANN-1846).
*/

const fixIFrame = (iframe: Element): void => {
  const src = iframe.getAttribute('src')
  const credentialless = iframe.getAttribute('credentialless')
  if (src && !credentialless) {
    iframe.setAttribute('credentialless', 'true')
    // reloads content of iframe to apply the 'credentialless' attribute
    iframe.setAttribute('src', src)
  }
}

const searchIFrames = (node: Element): void => {
  Array.from(node.querySelectorAll('iframe[src]')).forEach((iframe) => {
    fixIFrame(iframe)
  })
}

const observeIFramesInsertions = (element: Element): void => {
  const config = { childList: true, subtree: true }

  const callback = (records: MutationRecord[]): void => {
    for (const record of records) {
      Array.from(record.addedNodes).forEach((node) => {
        if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'IFRAME') {
          fixIFrame(node as Element)
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          searchIFrames(node as Element)
        }
      })
    }
  }

  const observer = new MutationObserver(callback)
  observer.observe(element, config)
}

export const observeInvalidIntercomIFrames = (): void => {
  // observe only top level insertions or deletions to minimize performance penalty
  const config = { childList: true, subtree: false }

  const callback = (records: MutationRecord[]): void => {
    for (const record of records) {
      const addedIntercomElement = Array.from(record.addedNodes).find((node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          return (node as Element).id == 'intercom-positioner-tree'
        }
      }) as Element
      if (addedIntercomElement) {
        const tourFrame = addedIntercomElement.querySelector(
          '.intercom-tour-frame',
        ) as HTMLIFrameElement
        if (tourFrame && tourFrame.contentWindow) {
          const tourBody = tourFrame.contentWindow.document.body
          observeIFramesInsertions(tourBody)
          searchIFrames(tourBody)
        }
      }
    }
  }

  const observer = new MutationObserver(callback)
  observer.observe(document.body, config)
}
