import type {
  Ability,
  AbilityOptions,
  MembershipPayload,
  TeamPayload,
  UserPayload,
} from '@/store/types'
import type { MembershipRole } from '@/store/types/MembershipRole'

type Resource = Record<string, string | number | boolean>
type ConditionValue = string | number | boolean
/**
 * For a single resource, returns boolean indicating if resource matches any of
 * the values for the given key.
 */
const satisfiesCondition = (
  resource: Resource,
  key: string,
  value: ConditionValue | ConditionValue[],
): boolean => (value instanceof Array ? value.includes(resource[key]) : value === resource[key])
const satisfiesConditions = (
  resource: Resource | Resource[],
  key: string,
  value: ConditionValue | ConditionValue[],
): boolean =>
  resource instanceof Array
    ? resource.some((r) => satisfiesCondition(r, key, value))
    : satisfiesCondition(resource, key, value)
/**
 * For a resource or an array of resources, returns boolean if any of them
 * satisfy the listed conditions.
 */
const checkConditions = (
  resource: Resource | Resource[],
  conditions: Ability['conditions'],
): boolean => {
  if (!conditions) {
    return true
  }
  return Object.entries(conditions).every(([key, value]) =>
    satisfiesConditions(resource, key, value),
  )
}

export const checkAuthorized = (
  abilities: Ability[],
  ability: string,
  options?: AbilityOptions,
): boolean => {
  // a rule for 'all' subjects, without any conditions is matched -> user is authorized
  if (!options || !options.resource) {
    return abilities.some(
      (a) => a.subject === 'all' && a.actions.includes(ability) && !a.conditions,
    )
  }

  // no conditionless all-rule found, but also no options provided -> nothing to check against
  if (!options) {
    return false
  }

  const { subject = 'all', resource } = options

  const allRule = abilities.find((a) => a.subject === 'all' && a.actions.includes(ability))

  if (allRule && checkConditions(resource, allRule.conditions)) {
    return true
  }

  const subjectRule = abilities.find((a) => a.subject === subject && a.actions.includes(ability))
  if (subjectRule && checkConditions(resource, subjectRule.conditions)) {
    return true
  }

  return false
}

export type IsAuthorized = (
  ability: string,
  options?: AbilityOptions,
  allowedRoles?: MembershipRole[],
) => boolean
export const roleInCurrentTeam = (
  user: UserPayload | null,
  team: TeamPayload | null,
  memberships: MembershipPayload[],
): MembershipRole | null => {
  if (!user || !team) {
    return null
  }
  const membership = memberships.find((m) => m.team_id === team.id && m.user_id === user.id)
  if (!membership) {
    return null
  }
  return membership.role
}
