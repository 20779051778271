import type { AnnotationType } from '@/core/annotationTypes'
import type { FrameData, SequenceData } from '@/core/annotations'

import type { AnnotationData, VideoAnnotationData } from './AnnotationData'
import { interpolateAnnotationFrames, supportsInterpolation } from './annotationInterpolation'
import { hasSegmentContainingIndex } from './helpers/segments'

type Result<T> = T extends VideoAnnotationData ? AnnotationData : FrameData

/**
 * Infers data of the given type for the given frame of the given video annotation
 *
 * We say "infers" because it will either return the exact data on the
 * annotation, or compute it via interpolation if possible
 *
 * Note that this function accepts both backend `SequenceData` as well as
 * editor `VideoAnnotationData`, but in the case of backend data, it will not
 * actually interpolate correctly and eventually just return {}
 */
export const inferVideoDataForType = <T extends VideoAnnotationData | SequenceData>(
  annotationData: T,
  annotationType: AnnotationType,
  frameIndex: number,
): Result<T> => {
  const {
    frames,
    sub_frames: subFrames,
    segments,
    interpolate_algorithm: algorithm,
    interpolated,
  } = annotationData
  // Figure out if the video annotation is visible
  if (!hasSegmentContainingIndex(segments, frameIndex)) {
    return {}
  }

  const fromFrames = frames[frameIndex] as Result<T>
  if (fromFrames) {
    return fromFrames
  }

  const fromSubFrames = subFrames && (subFrames[frameIndex] as Result<T>)
  if (fromSubFrames) {
    return fromSubFrames
  }

  // Find the closest keyframe to the left of the current index
  // and the closest keyframe to the right of the current index
  let prev: [number, Result<T>] | null = null
  let next: [number, Result<T>] | null = null
  for (const entry of Object.entries(frames)) {
    const entryFrameIndex = parseInt(entry[0])
    if (entryFrameIndex < frameIndex) {
      if (!prev || (prev && frameIndex > prev[0])) {
        prev = [frameIndex, entry[1]]
      }
    } else if (frameIndex > frameIndex) {
      if (!next || (next && frameIndex < next[0])) {
        next = [frameIndex, entry[1]]
      }
    }
  }

  if (prev === null && next !== null) {
    return next[1]
  }

  if (prev !== null && next === null) {
    return prev[1]
  }

  if (next !== null && prev !== null) {
    if (!supportsInterpolation(annotationType) || !interpolated) {
      return prev[1]
    }

    return (interpolateAnnotationFrames(
      annotationType,
      // these casts result in typescript allowing the call, but the return value of it will be
      // null, if the data we are dealing with is backend data.
      prev[1],
      next[1],
      {
        algorithm,
        interpolationFactor: (frameIndex - prev[0]) / (next[0] - prev[0]),
      },
    ) || {}) as Result<T>
  }

  return {}
}
