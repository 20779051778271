import { defineAsyncComponent } from 'vue'
import type { RouteConfig } from 'vue-router'

const DatasetManagementData = defineAsyncComponent(
  () => import('@/modules/Datasets/RouteDatasetManagementData.vue'),
)

const meta = { requiresAuth: true, requiresAbility: 'view_full_datasets' }

const datasetManagementRoute: RouteConfig = {
  path: 'dataset-management',
  component: defineAsyncComponent(() => import('@/modules/Datasets/RouteDatasetManagement.vue')),
  children: [
    {
      name: 'DatasetManagementData',
      path: '',
      component: DatasetManagementData,
      meta,
    },
    { path: 'data', redirect: { name: 'DatasetManagementData' }, meta },
    {
      name: 'DatasetManagementFolderData',
      path: 'tree/:path+',
      component: DatasetManagementData,
      meta,
    },
  ],
  meta,
}

const OverviewView = defineAsyncComponent(
  () => import('@/modules/Datasets/RouteDatasetOverview.vue'),
)

const WorkflowView = defineAsyncComponent(
  () => import('@/modules/Datasets/RouteDatasetWorkflow.vue'),
)

const DatasetClassesView = defineAsyncComponent(
  () => import('@/modules/Datasets/RouteDatasetClasses.vue'),
)

// separate chunk because it relies on froala, which is a big chunk of js on it's own
const SettingsView = defineAsyncComponent(
  () => import('@/modules/Datasets/RouteDatasetSettings.vue'),
)

const datasetDetailsRoute: RouteConfig = {
  path: '/datasets/:datasetId',
  component: defineAsyncComponent(() => import('@/modules/Datasets/RouteDataset.vue')),
  props: ({ params }) => ({ ...params, datasetId: parseInt(params.datasetId) }),
  children: [
    { path: 'workflow', name: 'DatasetWorkflow', component: WorkflowView, meta },
    { path: 'overview', name: 'DatasetOverview', component: OverviewView, meta },
    {
      path: 'classes',
      name: 'DatasetClasses',
      component: DatasetClassesView,
      meta,
      children: [
        {
          path: ':editClassId',
          name: 'DatasetClassesEdit',
          component: DatasetClassesView,
          props: (route) => ({
            datasetId: parseInt(route.params.datasetId),
          }),
        },
      ],
      props: (route) => ({
        datasetId: parseInt(route.params.datasetId),
      }),
    },
    datasetManagementRoute,
    { path: 'settings', name: 'DatasetSettings', component: SettingsView, meta },
  ],
  meta,
}

export default datasetDetailsRoute
