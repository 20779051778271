import { euclideanDistance } from '@/modules/Editor/algebra'
import type { IPoint } from '@/modules/Editor/point'
import { addPointMutate, divScalarMutate } from '@/modules/Editor/point'

export const touchMiddlePoint = (event: TouchEvent): IPoint => {
  const point = { x: 0, y: 0 }
  if (event.targetTouches.length === 0) {
    return point
  }

  for (const touch of event.targetTouches) {
    addPointMutate(point, { x: touch.clientX, y: touch.clientY })
  }

  divScalarMutate(point, event.targetTouches.length)

  return point
}

export const touchDistance = (event: TouchEvent): number | undefined => {
  const { targetTouches } = event
  if (targetTouches.length !== 2) {
    return
  }

  const [point1, point2] = targetTouches

  return euclideanDistance(
    { x: point1.clientX, y: point1.clientY },
    { x: point2.clientX, y: point2.clientY },
  )
}
