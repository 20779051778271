import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { Bounds, Raster } from '@/modules/Editor/models/raster/Raster'
import { assertImageRaster } from '@/modules/Editor/models/raster/assertImageRaster'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import type { View } from '@/modules/Editor/views/view'
import { isMaskAnnotationEmpty } from '@/modules/Editor/plugins/mask/utils/shared/isMaskAnnotationEmpty'
import { getAnnotationSegmentFromRasterLabel } from '@/modules/Editor/plugins/mask/utils/shared/getAnnotationSegmentFromRasterLabel'
import { isVideoAnnotationData } from '@/modules/Editor/models/annotation/annotationKindValidator'
import { getLabelBounds } from '@/modules/Editor/plugins/mask/utils/shared/getLabelBounds'

/**
 * Updates a mask annotation and the corresponding raster, and
 * publish the change to the annotationManager.
 */
export const updateMaskAnnotation = (
  view: View,
  raster: Raster,
  annotation: Annotation,
  bounds?: Bounds,
  frameIndexes?: number[],
): void => {
  const modifiedFrameIndexes = frameIndexes ?? [view.currentFrameIndex]
  const annotationData = {
    rasterId: raster.id,
  }

  modifiedFrameIndexes.forEach((frameIndex) => {
    const labelIndex = raster.getLabelIndexForAnnotationId(annotation.id)

    if (labelIndex === undefined) {
      throw new Error('label not found on raster')
    }

    if (raster.type === RasterTypes.VIDEO || raster.type === RasterTypes.VOXEL) {
      const videoRaster = assertVideoRaster(raster)

      const videoAnnotationData = annotation.data

      if (!isVideoAnnotationData(videoAnnotationData)) {
        throw new Error('Annotation data for VOXEL or VIDEO raster is invalid')
      }

      const rasterBufferAccessor = videoRaster.getBufferForEdit(frameIndex)
      const bounds = getLabelBounds(raster, labelIndex, frameIndex)
      const isEmptyOnFrame = isMaskAnnotationEmpty(labelIndex, rasterBufferAccessor, bounds)

      if (isEmptyOnFrame) {
        // Synchronise the raster layer if the mask is empty on the frame
        videoRaster.deleteLabelOnKeyframe(labelIndex, frameIndex)
        videoRaster.deleteVideoBoundsForLabelIndexForFrame(labelIndex, frameIndex)
      } else {
        if (!videoRaster.frameBuffers[frameIndex]) {
          videoRaster.createNewKeyframe(frameIndex)
        }

        // Synchronise the raster layer with the updated label bounds
        videoRaster.setVideoBoundsForLabelIndexForFrame(labelIndex, frameIndex, bounds)
        videoRaster.setLabelOnKeyframe(labelIndex, frameIndex)

        videoAnnotationData.frames[frameIndex] = annotationData
      }

      if (!videoAnnotationData.segments) {
        throw new Error('Annotation segments not found on VOXEL or VIDEO raster')
      }
      // We should not rely on mask annotation segments, but the right-hand sidebar does.
      // TODO: we should update the sidebar so that it checks rasterRange instead
      videoAnnotationData.segments[0] = getAnnotationSegmentFromRasterLabel(videoRaster, labelIndex)
      view.annotationManager.updateAnnotationData(annotation, videoAnnotationData)
    } else {
      view.annotationManager.updateAnnotationData(annotation, annotationData)

      const imageRaster = assertImageRaster(raster)

      const labelBounds = bounds || getLabelBounds(imageRaster, labelIndex, frameIndex)
      imageRaster.setBoundsForLabelIndex(labelIndex, labelBounds)
    }
  })

  view.annotationManager.updateAnnotation(annotation, {
    updatedFramesIndices: modifiedFrameIndexes,
  })
}
