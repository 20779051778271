import type { Company, CompanyIdentifier } from 'intercom-client/Company'
import { computed, watch } from 'vue'

import { useIntercom } from '@/composables/useIntercom'
import { useStore } from '@/store/useStore'
import { useAuthStore } from '@/modules/Auth/useAuthStore'
import { useTeamStore } from '@/pinia/useTeamStore'
import type { BillingInfoPayload } from '@/modules/Billing/types'
import type { MembershipRole, TeamPayload } from '@/store/types'
import { getSidebarCollapsedWidth, getSidebarExpandedWidth } from '@/uiKitLegacy/theme'
import { getHighestRole } from '@/modules/Teams/getHighestRole'
import session from '@/backend/session'
import { usePlanStore } from '@/modules/Billing/usePlanStore'
import type { CustomerPlan } from '@/modules/Billing/planTypes'
import { useUserStore } from '@/modules/Auth/useUserStore'
import { useBillingStore } from '@/modules/Billing/useBillingStore'

type IntercomRole = Omit<MembershipRole, 'member'> | 'user'
type IntercomData = {
  horizontal_padding: number
  email: string
  name: string
  inserted_at: string
  show_notifications: boolean
  superuser: boolean
  freemium: boolean
  avatar?: {
    type: string
    image_url: string
  }
  team_role?: IntercomRole
  product: 'Darwin'
  company?: CompanyIdentifier & Partial<Company>
}

const resolveIntercomCompany = (
  currentTeam: TeamPayload | null,
  billingInfo: BillingInfoPayload | null,
  plan: CustomerPlan | null,
): (CompanyIdentifier & Partial<Company>) | null => {
  if (currentTeam) {
    const { id, name, inserted_at: insertedAt, members, ...teamAttrs } = currentTeam
    return {
      company_id: `${id}`,
      name,
      created_at: new Date(insertedAt).getTime() / 1000,
      size: members.length,
      plan: plan?.name || 'None',
      custom_attributes: {
        // This is probably an unneeded duplicate on top of the `freemium` field in the root object
        // but we're not sure yet
        freemium: plan?.name === 'Free Tier',
        ...teamAttrs,
      },
    }
  }
  return null
}

const MEMBERSHIP_ROLE_TO_INTERCOM_ROLE: Record<MembershipRole, IntercomRole> = {
  annotator: 'annotator',
  member: 'user',
  admin: 'admin',
  owner: 'owner',
  workforce_manager: 'workforce_manager',
}

export const useIntercomLoader = (): void => {
  const store = useStore()
  const authStore = useAuthStore()
  const intercom = useIntercom()

  const authenticated = computed(() => authStore.authenticated)
  const memberships = computed(() => store.state.team.memberships)
  const sidebarMinimized = computed(() => store.state.ui.sidebarMinimized)

  const billingStore = useBillingStore()

  const userStore = useUserStore()

  const sidebarWidth = computed((): number => {
    if (sidebarMinimized.value) {
      return getSidebarCollapsedWidth()
    }
    return getSidebarExpandedWidth()
  })

  const bootIntercom = (): void => {
    if (!intercom) {
      return
    }
    if (intercom.isBooted) {
      return
    }
    intercom.boot({
      hide_default_launcher: true,
      custom_launcher_selector: '#feedback-trigger',
      alignment: 'left',
    })
  }

  const shutdownIntercom = (): void => intercom?.shutdown()

  const teamStore = useTeamStore()
  const planStore = usePlanStore()

  const updateIntercom = (): void => {
    if (!userStore.currentUser || !intercom) {
      return
    }
    const {
      email,
      first_name: firstName,
      last_name: lastName,
      image,
      ...profileAttrs
    } = userStore.currentUser

    const currentUserMemberships = memberships.value.filter(
      (member) => member.user_id === userStore.currentUserId,
    )

    const teamRole =
      currentUserMemberships.length > 0 ? getHighestRole([...currentUserMemberships]) : undefined

    const intercomData: IntercomData = {
      horizontal_padding: sidebarWidth.value + 10,
      email,
      name: `${firstName} ${lastName}`,
      freemium: planStore.customerPlan?.name === 'Free Tier' || false,
      team_role: teamRole ? MEMBERSHIP_ROLE_TO_INTERCOM_ROLE[teamRole] : undefined,
      ...(image ? { avatar: { type: 'avatar', image_url: image.thumbnail_url } } : {}),
      ...profileAttrs,
      product: 'Darwin',
    }

    const company = resolveIntercomCompany(
      teamStore.currentTeam,
      billingStore.billingInfo,
      planStore.customerPlan,
    )
    if (company) {
      intercomData.company = company
    }
    intercom.update({ ...intercomData, hide_default_launcher: true })
  }

  watch(
    authenticated,
    (): void => {
      if (!intercom) {
        return
      }
      if (authenticated.value) {
        if (intercom.ready) {
          bootIntercom()
        } else {
          intercom.once('ready', () => bootIntercom())
        }
      } else {
        shutdownIntercom()
      }
    },
    { immediate: true },
  )

  watch(
    [
      (): unknown => billingStore.billingInfo,
      (): unknown => userStore.currentUser,
      (): unknown => teamStore.currentTeam,
    ],
    updateIntercom,
    { immediate: true },
  )
  watch(() => sidebarMinimized, updateIntercom)

  session.onLogout(() => {
    console.info('User session expired, shutting down Intercom')
    shutdownIntercom()
  })
}
