import { maybeSimplifyPolygon } from '@/modules/Editor/algebra'
import type { CompoundPath } from '@/modules/Editor/compoundPath'
import { fromPolyBool } from '@/modules/Editor/compoundPath'
import { createEditablePoint } from '@/modules/Editor/point'
import { polygon } from '@/modules/Editor/polybooljs'
import type { Segment } from '@/modules/Editor/polybooljs/types'
import { resolveRelativeEpsilon } from '@/modules/Editor/resolveEpsilon'

export const getCompoundPathFromSegment = (
  segment: Segment | null,
  zoomScale: number,
): CompoundPath | undefined => {
  if (!segment) {
    return
  }

  const epsilon = resolveRelativeEpsilon(zoomScale)
  const originalData = fromPolyBool(polygon(segment))

  const data: CompoundPath = {
    path: maybeSimplifyPolygon(originalData.path, epsilon).map((point) =>
      createEditablePoint(point),
    ),
    additionalPaths: originalData.additionalPaths.map((path) =>
      maybeSimplifyPolygon(path, epsilon).map((point) => createEditablePoint(point)),
    ),
  }

  return data
}
