import { v4 as uuid } from 'uuid'

import type { PartialRecord } from '@/core/helperTypes'

export const ORequestType = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  COPY: 'copy',
  REORDER: 'reorder',
  SHIFT: 'shift',
  START_AUTO_TRACK: 'start_auto_track',
  STOP_AUTO_TRACK: 'stop_auto_track',
} as const

export type RequestType = (typeof ORequestType)[keyof typeof ORequestType]

/**
 * Represents information defining single request
 */
export type Request<P> = {
  /**
   * Unique identifier for a request.
   * Having this allows us to understand which requests get merged when performing reduction.
   * It also allows us to maintain a reusable promise map so the system knows which event
   * to await on.
   * */
  id: string
  payload: P
  type: RequestType
}

/**
 * Represents all request data for a single identifier. This includes a single
 * in flight (fired) request and a queue of pending requests (not yet fired).
 */
export type RequestData<P> = {
  inFlightRequest: Request<P> | null
  queue: Array<Request<P>>
}

export type RequestMap<P> = PartialRecord<string, RequestData<P>>

export const createRequestMap = <P>(): {
  /** All current in-flight or queued requests, mapped by identifier */
  requests: RequestMap<P>
  /**
   * Adds a request under request data for the specified id
   *
   * If the data is there, the request is added to the queue. Otherwise,
   * a new data is added with single request in the queue.
   *
   * @param id
   * The unique identifier of the request (usually the id of the modified record).
   * This is required for 'create' requests to, so the id must be locally generated.
   *
   * @param request
   *
   * The request to add
   */
  addRequest: (id: string, request: Request<P>) => void
  /**
   * Updates queued request under request data for the specified id
   *
   * @param id
   * The unique identifier of the request (usually the id of the modified record).
   * This is required for 'create' requests to, so the id must be locally generated.
   *
   * @param request
   *
   * The request to add
   */
  updateRequest: (id: string, request: Request<P>) => void
  /**
   * Creates a request object from a type and payload.
   *
   * This is a function as the request needs to be given a unique ID.
   */
  createRequest: (type: RequestType, payload: P) => Request<P>

  /**
   * Clears the requests queue for the given record id.
   * This leaves only the in-flight request, if any. The queued requests will never be fired.
   */
  clearQueue: (id: string) => void
} => {
  const requests: RequestMap<P> = {}

  const createRequest = (type: RequestType, payload: P): Request<P> => ({
    id: uuid(),
    type,
    payload,
  })

  const addRequest = (id: string, request: Request<P>): void => {
    const item = requests[id]
    if (!item) {
      requests[id] = { inFlightRequest: null, queue: [request] }
      return
    }

    item.queue.push(request)
  }

  const updateRequest = (id: string, request: Request<P>): void => {
    const item = requests[id]
    if (!item) {
      throw new Error(`Cannot update request for id ${id} as item doesn't exist`)
    }
    const index = item.queue.findIndex((r) => r.id === request.id)
    if (index === -1) {
      throw new Error(`Cannot update request for id ${id} as it doesn't exist`)
    }

    item.queue[index] = request
  }

  const clearQueue = (id: string): void => {
    const item = requests[id]
    if (item) {
      item.queue = []
    }
  }

  return { requests, addRequest, updateRequest, createRequest, clearQueue }
}
