/**
 * returns a square path snapped to pixels for a given width, to
 * use as a brush tip when using a mask brush.
 */
export const buildSquareMaskPath = (widthInPixels: number): number[][] => {
  const flooredWidth = Math.floor(widthInPixels)

  if (flooredWidth === 1) {
    const tipPath = [
      [-0.5, -0.5],
      [0.5, -0.5],
      [0.5, 0.5],
      [-0.5, 0.5],
    ]
    return tipPath
  }

  const topLeft = [-widthInPixels / 2, -widthInPixels / 2]
  const topRight = [topLeft[0] + flooredWidth, topLeft[1]]
  const bottomRight = [topLeft[0] + flooredWidth, topLeft[1] + flooredWidth]
  const bottomLeft = [topLeft[0], topLeft[1] + flooredWidth]

  const path: number[][] = [topLeft, topRight, bottomRight, bottomLeft]

  return path
}
