import { computed, watch } from 'vue'

import { INTERCOM_EVENT, useIntercom } from '@/composables/useIntercom'
import { useRoute } from 'vue-router/composables'

/**
 * Composable for handling Intercom support requests.
 * It listens for a specific query parameter ('chatWithUs') in the route to trigger opening
 * the chat UI component with a pre-define support message.
 *
 * @module useIntercomMessaging
 */
export const useIntercomMessaging = (): undefined => {
  const intercom = useIntercom()
  const route = useRoute()

  const chatWithUs = computed(() => route.query.chatWithUs)

  const teamName = computed(() => {
    const result = Array.isArray(chatWithUs.value) ? chatWithUs.value[0] : chatWithUs.value
    if (result === '' || result === null || result === undefined) {
      return
    }

    return result
  })

  /**
   * Open the intercom chat UI component with a pre-defined support message.
   *
   * @param {string} teamName - The name of the team requesting support.
   */
  const askSupportRequest = (teamName: string): void => {
    if (!intercom) {
      return
    }

    intercom.trackEvent(INTERCOM_EVENT.SUPPORT, { teamName })
    const message =
      `Support Request from ${teamName}:\n` +
      '1. Team name:\n' +
      '2. Dataset URL:\n' +
      '3. Summary of the issue:\n' +
      '4. Urgency:\n' +
      '5. Any additional information, or screenshots you can provide'
    return intercom.showNewMessage(message)
  }

  watch(
    () => teamName.value,
    (value) => {
      if (value === undefined) {
        return
      }
      askSupportRequest(value)
    },
    { immediate: true },
  )
}
