import Vue from 'vue'
import type { MutationTree } from 'vuex'

import type { ImagePayload, InvitationPayload, MembershipPayload, TeamPayload } from '@/store/types'

import { PUSH_MEMBERSHIPS } from './mutations/PUSH_MEMBERSHIPS'
import type { TeamState } from './state'

const mutations: MutationTree<TeamState> = {
  PUSH_MEMBERSHIPS,

  SET_CURRENT_TEAM(state, payload?: TeamPayload) {
    state.currentTeam = payload || null
  },

  PUSH_TEAM(state, team: TeamPayload) {
    const index = state.teams.findIndex((t) => t.id === team.id)
    if (index > -1) {
      state.teams.splice(index, 1, team)
    } else {
      state.teams.push(team)
    }
  },

  SET_TEAMS(state, teams: TeamPayload[]) {
    state.teams = teams
  },

  SET_TEAM_AVATAR_URL(state, url: string) {
    if (!state.currentTeam) {
      return
    }
    if (!state.currentTeam.image) {
      return
    }
    Vue.set(state.currentTeam.image, 'url', url)
  },

  REMOVE_TEAM(state, team: TeamPayload) {
    const { teams } = state
    const index = teams.findIndex((t) => t.id === team.id)
    if (index > -1) {
      const newTeams = [...teams]
      newTeams.splice(index, 1)
      state.teams = newTeams
    }
  },

  UNSET_CURRENT_TEAM(state) {
    state.currentTeam = null
  },

  SET_INVITATIONS(state, invitations: InvitationPayload[]) {
    state.invitations = invitations
  },

  ADD_INVITATION(state, invitation: InvitationPayload) {
    if (!state.currentTeam) {
      return
    }
    state.invitations.push(invitation)
  },

  PUSH_INVITATIONS(state, invitations: InvitationPayload[]) {
    invitations.forEach((item) => {
      const index = state.invitations.findIndex((m) => m.id === item.id)
      if (index > -1) {
        Vue.set(state.invitations, index, item)
      } else {
        state.invitations.push(item)
      }
    })
  },

  UPDATE_INVITATION(state, invitation: InvitationPayload) {
    if (!state.currentTeam) {
      return
    }
    const index = state.invitations.findIndex((i) => i.id === invitation.id)
    if (index >= 0) {
      state.invitations.splice(index, 1, invitation)
    }
  },

  DELETE_INVITATION(state, invitationId: number) {
    if (!state.currentTeam) {
      return
    }
    const index = state.invitations.findIndex((i) => i.id === invitationId)
    if (index >= 0) {
      state.invitations.splice(index, 1)
    }
  },

  SET_MEMBERSHIPS(state, data: MembershipPayload[]) {
    state.memberships = data
  },

  UPDATE_MEMBERSHIP(state, member: MembershipPayload) {
    const { memberships } = state
    const index = memberships.findIndex((m) => m.id === member.id)
    if (index >= 0) {
      memberships.splice(index, 1, member)
    }
  },

  DELETE_MEMBERSHIP(state, memberId: number) {
    const { memberships } = state
    const index = memberships.findIndex((m) => m.id === memberId)
    if (index >= 0) {
      memberships.splice(index, 1)
    }
  },

  // TODO: Define AuthPayload/ProfilePayload/UserPayload
  SET_MEMBER_AVATAR_URL(state, user: { id: number; image: ImagePayload }) {
    const index = state.memberships.findIndex((m) => m.user_id === user.id)
    if (index >= 0) {
      Vue.set(state.memberships[index], 'image', user.image)
    }
  },

  RESET_ALL(state: TeamState) {
    state.invitations = []
    state.memberships = []
    state.teams = []
    state.currentTeam = null
  },
}

export default mutations
