import type { Getter } from 'vuex'

import type { DatasetItemReportsState } from '@/store/modules/datasetItemReports/state'
import type { DatasetItemReportPayload } from '@/store/modules/datasetItemReports/types'
import type { RootState } from '@/store/types'

/**
 * Returns ordered by date list of reports
 */
export const reports: Getter<DatasetItemReportsState, RootState> = (
  state,
): DatasetItemReportPayload[] =>
  [...state.reports].sort(
    (a, b) => new Date(b.requested_at).getTime() - new Date(a.requested_at).getTime(),
  )
