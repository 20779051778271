import { EventEmitter } from 'events'

import type { CommentVertices } from '@/modules/Editor/comment'
import { boundingBoxToVertices } from '@/modules/Editor/comment'
import type { EditablePoint, IPoint } from '@/modules/Editor/point'
import type { IHighlightable } from '@/modules/Editor/interfaces/IHighlightable'
import type { ISelectable } from '@/modules/Editor/interfaces/ISelectable'
import type { IVisibility } from '@/modules/Editor/interfaces/IVisibility'
import type { Comment, CommentThread, ICommentsProvider } from '@/modules/Editor/iproviders/types'
import { getCentroidRectPath } from '@/modules/Editor/plugins/commentator/commentatorRenderer'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'
import { CommentManagerEvents } from '@/modules/Editor/eventBus'

export enum Events {
  THREAD_START_CREATION = 'thread:start:creation',
  THREAD_END_CREATION = 'thread:end:creation',

  THREAD_CREATED = 'thread:created',
  THREAD_UPDATING = 'thread:updating',
  THREAD_UPDATED = 'thread:updated',
  THREAD_SELECTED = 'thread:selected',
  THREAD_DESELECTED = 'thread:deselected',
  THREADS_CHANGED = 'threads:changed',
  THREAD_VISIBILITY_CHANGED = 'thread:visibility:changed',

  THREAD_COMMENT_CREATED = 'thread:comment:created',
  THREAD_COMMENT_CHANGED = 'thread:comment:changed',
  THREAD_COMMENT_REMOVED = 'thread:comment:removed',
}

export const threadHasConflicts = (thread: CommentThread): boolean => !!thread.issue_types?.length

export const getThreadVertices = (thread: CommentThread): CommentVertices => {
  const { x, y, w, h } = thread.bounding_box
  return boundingBoxToVertices({ x, y, w, h })
}

/**
 * Manages comments in the workview.
 *
 * Comments render into a layer separate from annotations adn the thing the
 * editor is actually in charge of here is just the placement, moving and
 * resizing of the bounding box.
 *
 * Everything else is handled by the parent application
 *
 * All changes of the comment threads for the current view + section should go
 * through here, including all changes done by the comment tool.
 */
export class CommentManager
  extends EventEmitter
  implements ISelectable<CommentThread>, IHighlightable<CommentThread>, IVisibility<CommentThread>
{
  private view: View

  static THREAD_START_CREATION = Events.THREAD_START_CREATION
  static THREAD_END_CREATION = Events.THREAD_END_CREATION
  /**
   * Thread has been created
   */
  static THREAD_CREATED = Events.THREAD_CREATED
  /**
   * Thread is being changed in a way that triggers rerender, but it's not final yet.
   * This means we may want to update local data, but don't want to save it.
   * We also want to be fast with the update as the render needs to be fast
   */
  static THREAD_UPDATING = Events.THREAD_UPDATING
  /**
   * Thread has fully updated and now needs to change.
   */
  static THREAD_UPDATED = Events.THREAD_UPDATED
  static THREAD_SELECTED = Events.THREAD_SELECTED
  static THREAD_DESELECTED = Events.THREAD_DESELECTED
  /**
   * All threads have changed and need to re-render
   */
  static THREADS_CHANGED = Events.THREADS_CHANGED
  static THREAD_VISIBILITY_CHANGED = Events.THREAD_VISIBILITY_CHANGED

  static THREAD_COMMENT_CREATED = Events.THREAD_COMMENT_CREATED
  static THREAD_COMMENT_CHANGED = Events.THREAD_COMMENT_CHANGED

  constructor(view: View) {
    super()
    this.view = view

    this.commentsProvider.getThreads().then((threads) => {
      this.setThreads(threads.filter((t) => t.slot_name === this.view.fileManager.slotName))
    })
  }

  private get commentsProvider(): ICommentsProvider {
    return this.view.editor.commentsProvider
  }

  private threadsById: Partial<{ [id: string]: CommentThread }> = {}

  get threads(): CommentThread[] {
    return Object.values(this.threadsById).filter(
      (thread): thread is CommentThread => !!thread && !thread.resolved,
    )
  }

  get frameThreads(): CommentThread[] {
    return this.threads.filter(
      (t) => !Number.isFinite(t.section_index) || t.section_index === this.view.currentFrameIndex,
    )
  }

  public getThread(id: CommentThread['id']): CommentThread | null {
    return this.threadsById[id] || null
  }

  private _pushThread(payload: CommentThread): void {
    if (this.threadsById[payload.id]) {
      return
    }
    this.threadsById[payload.id] = payload
  }

  private _setThread(thread: CommentThread): void {
    this.threadsById[thread.id] = thread
  }

  private _removeThread(payload: CommentThread): void {
    this.hiddenThreads.delete(payload.id)
    delete this.threadsById[payload.id]
  }

  private setThreads(threads: CommentThread[]): void {
    this.hiddenThreads.clear()
    this.threadsById = {}

    threads.forEach((thread) => this._pushThread(thread))
    this.emit(Events.THREADS_CHANGED, this.threads)
    CommentManagerEvents.threadsChanged.emit({ viewId: this.view.id }, this.threads)
  }

  // CRUD create

  public async createThread(
    x: number,
    y: number,
    w: number,
    h: number,
    initialComment: string,
  ): Promise<CommentThread> {
    const thread = {
      bounding_box: { x, y, w, h },
      section_index: this.view.currentFrameIndex,
      slot_name: this.view.fileManager.slotName,
    } as CommentThread

    const response = await this.commentsProvider.createThread(thread, initialComment)

    this._pushThread(response)

    this.selectItem(response.id)
    this.emit(Events.THREAD_CREATED, this.getThread(response.id))
    CommentManagerEvents.threadCreated.emit({ viewId: this.view.id }, this.getThread(response.id))
    this.emit(Events.THREADS_CHANGED, this.threads)
    CommentManagerEvents.threadsChanged.emit({ viewId: this.view.id }, this.threads)

    return response
  }

  public async updateThread(payload: CommentThread): Promise<CommentThread> {
    if (!this.threadsById[payload.id]) {
      throw new Error("Something went wrong! Can't get thread by id.")
    }

    const updatedThread = await this.commentsProvider.updateThread(payload)

    this._setThread(updatedThread)

    this.emit(Events.THREAD_UPDATED, updatedThread)
    CommentManagerEvents.threadUpdated.emit({ viewId: this.view.id }, updatedThread)
    this.emit(Events.THREADS_CHANGED, this.threads)
    CommentManagerEvents.threadsChanged.emit({ viewId: this.view.id }, this.threads)

    return updatedThread
  }

  public async removeThread(threadId: CommentThread['id']): Promise<void> {
    if (this.selectedThreadId === threadId) {
      this.deselectItem()
    }

    const thread = this.threadsById[threadId]
    if (!thread) {
      throw new Error("Something went wrong! Can't get thread by id.")
    }

    await this.commentsProvider.removeThread(thread)

    this._removeThread(thread)
    this.emit(Events.THREADS_CHANGED, this.threads)
    CommentManagerEvents.threadsChanged.emit({ viewId: this.view.id }, this.threads)
  }

  public async resolveThread(threadId: CommentThread['id']): Promise<void> {
    const thread = this.getThread(threadId)
    if (!thread) {
      throw new Error("Something went wrong! Can't get thread by id.")
    }

    await this.commentsProvider.resolveThread(thread)

    this.deselectItem()

    this._setThread({
      ...thread,
      resolved: true,
    })
    this.emit(Events.THREADS_CHANGED, this.threads)
    CommentManagerEvents.threadsChanged.emit({ viewId: this.view.id }, this.threads)
  }

  // selection

  private selectedThreadId: string | null = null

  public get selectedItem(): CommentThread | null {
    if (!this.selectedThreadId) {
      return null
    }
    return this.threadsById[this.selectedThreadId] || null
  }

  public async selectItem(threadId: CommentThread['id']): Promise<void> {
    this.deselectItem()

    const thread = this.threadsById[threadId]
    if (!thread) {
      return
    }

    this.selectedThreadId = thread.id
    this.emit(Events.THREAD_SELECTED, thread)
    CommentManagerEvents.threadSelected.emit({ viewId: this.view.id }, thread)

    if (!this.selectedItem) {
      throw new Error("Can't get selected thread!")
    }

    await this.getComments(this.selectedItem.id)

    this.once(Events.THREAD_DESELECTED, () => {
      this.emit(Events.THREAD_COMMENT_CHANGED, [])
      CommentManagerEvents.threadCommentChanged.emit({ viewId: this.view.id }, [])
    })
  }

  public deselectItem(): void {
    this.selectedThreadId = null
    this.emit(Events.THREAD_DESELECTED)
    CommentManagerEvents.threadDeselected.emit({ viewId: this.view.id })
  }

  // highligh

  private highlightedThreadId: string | null = null

  public get highlightedItem(): CommentThread | null {
    if (!this.highlightedThreadId) {
      return null
    }
    return this.threadsById[this.highlightedThreadId] || null
  }

  public highlightItem(threadId: CommentThread['id']): void {
    this.highlightedThreadId = threadId
  }

  public unhighlightItem(): void {
    this.highlightedThreadId = null
  }

  // Visibility

  private hiddenThreads: Set<CommentThread['id']> = new Set()

  public get hiddenIds(): CommentThread['id'][] {
    return [...this.hiddenThreads.values()]
  }

  public isHidden(threadId: CommentThread['id']): boolean {
    return this.hiddenThreads.has(threadId)
  }

  public hide(threadId: CommentThread['id']): void {
    this.hiddenThreads.add(threadId)
    if (this.selectedThreadId === threadId) {
      this.deselectItem()
    }
    this.emit(Events.THREAD_VISIBILITY_CHANGED, [threadId])
    CommentManagerEvents.threadVisibilityChanged.emit({ viewId: this.view.id }, [threadId])
  }

  public show(threadId: CommentThread['id']): void {
    this.hiddenThreads.delete(threadId)
    this.emit(Events.THREAD_VISIBILITY_CHANGED, [threadId])
    CommentManagerEvents.threadVisibilityChanged.emit({ viewId: this.view.id }, [threadId])
  }

  public get isAllHidden(): boolean {
    if (this.hiddenThreads.size === 0) {
      return false
    }
    // hiddenThreads is set so it can't be larger then threads list
    return this.hiddenThreads.size === Object.keys(this.threadsById).length
  }

  public hideAll(): void {
    const keys = Object.keys(this.threadsById)

    this.hiddenThreads = new Set(keys)
    this.deselectItem()

    this.emit(Events.THREAD_VISIBILITY_CHANGED, keys)
    CommentManagerEvents.threadVisibilityChanged.emit({ viewId: this.view.id }, keys)
  }

  public showAll(): void {
    this.hiddenThreads.clear()
    this.emit(Events.THREAD_VISIBILITY_CHANGED, Object.keys(this.threadsById))
    CommentManagerEvents.threadVisibilityChanged.emit(
      { viewId: this.view.id },
      Object.keys(this.threadsById),
    )
  }

  // Misc

  public findCommentThreadVertexAt(point: IPoint, threshold?: number): EditablePoint | null {
    const thread = this.selectedItem
    if (!thread) {
      return null
    }
    const vertices = getThreadVertices(thread)
    const path = [vertices.topLeft, vertices.topRight, vertices.bottomRight, vertices.bottomLeft]
    return this.view.findVertexAtPath([path], point, threshold) || null
  }

  public findTopCommentThreadAt(point: IPoint): CommentThread | null {
    const matched = this.threads.find((thread) => {
      if (thread.section_index !== null && this.view.currentFrameIndex !== thread.section_index) {
        return false
      }

      const vertices = getThreadVertices(thread)
      const path = [vertices.topLeft, vertices.topRight, vertices.bottomRight, vertices.bottomLeft]
      if (thread.id === this.selectedThreadId) {
        return this.view.isPointInPath(point, path)
      }

      const centroidRectPath = getCentroidRectPath(thread)

      const conflict = threadHasConflicts(thread)
      return this.view.isPointInPath(point, conflict ? path : centroidRectPath)
    })

    return matched || null
  }

  public moveBox(thread: CommentThread, offset: IPoint): void {
    if (!this.threadsById[thread.id]) {
      return
    }
    const vertices = getThreadVertices(thread)

    vertices.topLeft.x += offset.x
    vertices.topLeft.y += offset.y
    vertices.topRight.x += offset.x
    vertices.topRight.y += offset.y
    vertices.bottomLeft.x += offset.x
    vertices.bottomLeft.y += offset.y
    vertices.bottomRight.x += offset.x
    vertices.bottomRight.y += offset.y

    const { x: left, y: top } = vertices.topLeft
    const { x: right, y: bottom } = vertices.bottomRight
    thread.bounding_box = {
      x: Math.min(left, right),
      y: Math.min(top, bottom),
      w: Math.abs(right - left),
      h: Math.abs(bottom - top),
    }

    this.emit(Events.THREAD_UPDATING, thread)
    CommentManagerEvents.threadUpdating.emit({ viewId: this.view.id }, thread)
  }

  public moveVertex(thread: CommentThread, vertex: IPoint, position: IPoint): void {
    // we move the vertex,
    // since it needs to be an orthogonal bounding box, we also move
    // neighboring vertices that are affected
    const vertices = getThreadVertices(thread)

    if (vertices.topLeft.x === vertex.x && vertices.topLeft.y === vertex.y) {
      vertices.topLeft.x = position.x
      vertices.topLeft.y = position.y

      vertices.bottomLeft.x = position.x
      vertices.topRight.y = position.y

      this.emit(Events.THREAD_UPDATING, thread)
      CommentManagerEvents.threadUpdating.emit({ viewId: this.view.id }, thread)
    } else if (vertices.topRight.x === vertex.x && vertices.topRight.y === vertex.y) {
      vertices.topRight.x = position.x
      vertices.topRight.y = position.y

      vertices.topLeft.y = position.y
      vertices.bottomRight.x = position.x

      this.emit(Events.THREAD_UPDATING, thread)
      CommentManagerEvents.threadUpdating.emit({ viewId: this.view.id }, thread)
    } else if (vertices.bottomLeft.x === vertex.x && vertices.bottomLeft.y === vertex.y) {
      vertices.bottomLeft.x = position.x
      vertices.bottomLeft.y = position.y

      vertices.topLeft.x = position.x
      vertices.bottomRight.y = position.y

      this.emit(Events.THREAD_UPDATING, thread)
      CommentManagerEvents.threadUpdating.emit({ viewId: this.view.id }, thread)
    } else if (vertices.bottomRight.x === vertex.x && vertices.bottomRight.y === vertex.y) {
      vertices.bottomRight.x = position.x
      vertices.bottomRight.y = position.y

      vertices.topRight.x = position.x
      vertices.bottomLeft.y = position.y

      this.emit(Events.THREAD_UPDATING, thread)
      CommentManagerEvents.threadUpdating.emit({ viewId: this.view.id }, thread)
    }

    const { x: left, y: top } = vertices.topLeft
    const { x: right, y: bottom } = vertices.bottomRight
    thread.bounding_box = {
      x: Math.min(left, right),
      y: Math.min(top, bottom),
      w: Math.abs(right - left),
      h: Math.abs(bottom - top),
    }
  }

  // Comments
  // CRUD comments

  private _comments: Map<string, Comment[]> = new Map()

  public getLocalComments(threadId: CommentThread['id']): Comment[] {
    return this._comments.get(threadId) || []
  }

  public async getComments(threadId: CommentThread['id']): Promise<Comment[]> {
    // send an event with existing comments
    this.emit(Events.THREAD_COMMENT_CHANGED, this.getLocalComments(threadId))
    CommentManagerEvents.threadCommentChanged.emit(
      { viewId: this.view.id },
      this.getLocalComments(threadId),
    )

    const thread = this.threadsById[threadId]
    if (!thread) {
      throw new Error("Something went wrong! Can't get thread by id.")
    }

    try {
      // refresh comments data storage
      const comments = await this.commentsProvider.getComments(thread)
      this._comments.set(threadId, comments)

      // send an event with refreshed comments
      this.emit(Events.THREAD_COMMENT_CHANGED, comments)
      CommentManagerEvents.threadCommentChanged.emit({ viewId: this.view.id }, comments)

      return comments
    } catch {
      return []
    }
  }

  public async createComment(
    threadId: CommentThread['id'],
    body: string,
  ): Promise<Comment | undefined> {
    const thread = this.threadsById[threadId]
    if (!thread) {
      throw new Error("Can't get thread by id!")
    }

    try {
      const comment = await this.commentsProvider.createComment(body, thread)

      this.threadsById[threadId] = {
        ...thread,
        comment_count: thread.comment_count + 1,
      }

      const oldComments = this._comments.get(threadId) || []
      this._comments.set(threadId, [...oldComments, comment])
      const newComments = this._comments.get(threadId) || []

      this.emit(Events.THREAD_COMMENT_CREATED, comment)
      CommentManagerEvents.threadCommentCreated.emit({ viewId: this.view.id }, comment)
      this.emit(Events.THREAD_COMMENT_CHANGED, newComments)
      CommentManagerEvents.threadCommentChanged.emit({ viewId: this.view.id }, newComments)
      this.emit(Events.THREADS_CHANGED, this.threads)
      CommentManagerEvents.threadsChanged.emit({ viewId: this.view.id }, this.threads)

      return comment
    } catch {
      return undefined
    }
  }

  public async updateComment(
    commentId: Comment['id'],
    threadId: CommentThread['id'],
    body: Comment['body'],
  ): Promise<Comment> {
    const thread = this.threadsById[threadId]
    if (!thread) {
      throw new Error("Can't get thread by id!")
    }

    const comments = this._comments.get(threadId) || []
    const commentToUpdate = comments.find((comment) => comment.id === commentId)
    if (!commentToUpdate) {
      throw new Error("Something went wrong! Can't get comment by id.")
    }
    const updatedComment = { ...commentToUpdate, body }
    try {
      await this.commentsProvider.updateComment(updatedComment, thread)
    } catch (error: unknown) {
      setContext('CommentManager', { error })
      throw new Error("CommentManager, Can't update comment")
    }

    const oldComments = this._comments.get(threadId) || []
    this._comments.set(
      threadId,
      oldComments.map((comment) => (comment === commentToUpdate ? updatedComment : comment)),
    )
    const newComments = this._comments.get(threadId) || []

    if (commentId === thread.first_comment?.id) {
      this.threadsById[threadId] = {
        ...thread,
        first_comment: updatedComment,
      }
    }

    this.emit(Events.THREAD_COMMENT_CHANGED, newComments)
    CommentManagerEvents.threadCommentChanged.emit({ viewId: this.view.id }, newComments)
    this.emit(Events.THREADS_CHANGED, this.threads)
    CommentManagerEvents.threadsChanged.emit({ viewId: this.view.id }, this.threads)

    return updatedComment
  }

  public async removeComment(
    commentId: Comment['id'],
    threadId: CommentThread['id'],
  ): Promise<void> {
    const thread = this.threadsById[threadId]
    if (!thread) {
      throw new Error("Can't get thread by id!")
    }

    const comments = this._comments.get(threadId) || []
    const commentToRemove = comments.find((comment) => comment.id === commentId)
    if (!commentToRemove) {
      throw new Error("Something went wrong! Can't get comment by id.")
    }
    if (thread.comment_count === 1) {
      return this.removeThread(threadId)
    }
    try {
      await this.commentsProvider.removeComment(commentToRemove, thread)
    } catch {
      this._pushThread(thread)
      return
    }

    const newComments = comments.filter((comment) => comment !== commentToRemove)
    this._comments.set(threadId, newComments)
    const updatedFirstComment =
      thread.first_comment?.id === commentToRemove.id ? newComments[0] : thread.first_comment
    this.threadsById[threadId] = {
      ...thread,
      comment_count: thread.comment_count - 1,
      first_comment: updatedFirstComment,
    }

    this.emit(Events.THREAD_COMMENT_REMOVED, commentToRemove)
    CommentManagerEvents.threadCommentRemoved.emit({ viewId: this.view.id }, commentToRemove)
    this.emit(Events.THREADS_CHANGED, this.threads)
    CommentManagerEvents.threadsChanged.emit({ viewId: this.view.id }, this.threads)
  }

  clear(): void {
    this._comments.clear()
    this.threadsById = {}
    this.selectedThreadId = null
    this.highlightedThreadId = null
    this.hiddenThreads.clear()
  }

  // Legacy

  /**
   * @deprecated
   */
  setCommentThreads(): void {}
  /**
   * @deprecated
   */
  closeSelectedThread(): void {}
  /**
   * @deprecated
   */
  unhighlightCommentThread(): void {}
  /**
   * @deprecated
   */
  highlightCommentThread(): void {}
  /**
   * @deprecated
   */
  selectCommentThread(): void {}
  /**
   * @deprecated
   */
  openSelectedThread(): void {}
  /**
   * @deprecated
   */
  initializeNewThread(): CommentThread {
    return {} as CommentThread
  }
  /**
   * @deprecated
   */
  get hihglightedCommentThreadIsEditable(): boolean {
    return false
  }
  /**
   * @deprecated
   */
  get selectedCommentThread(): null {
    return null
  }
  /**
   * @deprecated
   */
  get highlightedCommentThread(): null {
    return null
  }
}
