import type { FrameData } from '@/core/annotations'
import type { SkeletonData, Skeleton } from '@/modules/Editor/AnnotationData'
import { isSkeleton } from '@/modules/Editor/annotationTypes/skeleton'
import { createEditablePoint } from '@/modules/Editor/point'

export const skeletonSerializer = {
  serialize(
    data: Skeleton,
    metadata?: {
      skeleton?: {
        edges: { from: string; to: string }[]
      }
    },
  ): FrameData {
    if (!isSkeleton(data)) {
      throw new Error('skeleton: expected annotation of skeleton type')
    }
    const nodes = data.nodes.map((node) => ({
      x: node.point.x,
      y: node.point.y,
      name: node.name,
      occluded: node.occluded,
    }))

    if (!metadata?.skeleton?.edges) {
      return { skeleton: { nodes } }
    }

    const {
      skeleton: { edges },
    } = metadata
    return { skeleton: { nodes, edges } }
  },

  deserialize(rawData: FrameData): Skeleton | null {
    if (!rawData.skeleton) {
      return null
    }

    const nodes = rawData.skeleton.nodes.map((node: SkeletonData['nodes'][0]) => ({
      point: createEditablePoint({ x: node.x, y: node.y }),
      name: node.name,
      occluded: node.occluded,
    }))

    return { nodes }
  },
}
