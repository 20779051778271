import type { FrameData } from '@/core/annotations'
import type { Keypoint } from '@/modules/Editor/AnnotationData'

export const keypointSerializer = {
  serialize({ x, y }: Keypoint): FrameData {
    return { keypoint: { x, y } }
  },

  deserialize({ keypoint }: FrameData): Keypoint | null {
    return keypoint || null
  },
}
