import type { FrameData } from '@/core/annotations'
import type { MeasureRegionsPayload, MeasureUnit } from '@/modules/Editor/MeasureOverlayData'
import { isDefaultMeasureRegion } from '@/modules/Editor/utils/isDefaultMeasureRegion'

type Serialized = { unit: MeasureRegionsPayload['unit']; delta: MeasureRegionsPayload['delta'] }

export const measuresSerializer = {
  serialize(measureRegion: MeasureRegionsPayload | null): Serialized | null {
    if (!measureRegion) {
      return null
    }

    if (isDefaultMeasureRegion(measureRegion)) {
      return null
    }

    return {
      unit: measureRegion.unit,
      delta: measureRegion.delta,
    }
  },

  deserialize(rawData: FrameData['measures']): MeasureRegionsPayload | null {
    if (!rawData) {
      return null
    }

    return {
      unit: rawData.unit as { x: MeasureUnit; y: MeasureUnit },
      delta: rawData.delta,
      high_priority: false,
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    }
  },

  defaultData(measureRegion: MeasureRegionsPayload | null): MeasureRegionsPayload | null {
    if (!measureRegion) {
      return null
    }

    if (isDefaultMeasureRegion(measureRegion)) {
      return null
    }

    return {
      unit: measureRegion.unit,
      delta: measureRegion.delta,
      high_priority: false,
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    }
  },
}
