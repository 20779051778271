export const CACHED_CANVAS_PADDING = 60
export const DOUBLE_CACHED_CANVAS_PADDING = CACHED_CANVAS_PADDING * 2
export const CACHED_CANVAS_QUALITY_FACTOR = 2

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#maximum_canvas_size
// https://jhildenbiddle.github.io/canvas-size/#/?id=test-results
export const MAX_CANVAS_SIZE = 65535
export const MAX_CANVAS_AREA = 16384 * 16384

export const DEFAULT_OPACITY = 15
export const DEFAULT_BORDER_OPACITY = 80
