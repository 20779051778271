import { isReformattedDICOMView } from '@/modules/Editor/utils/radiology/isReformattedDicomView'
import { isDicomView } from '@/modules/Editor/utils/radiology/isDicomView'
import type { View } from '@/modules/Editor/views/view'

/**
 * A view can be a primary or a secondary view. All non DicomViews are always primary views.
 * DicomViews can be primary or secondary if they are volumetric. At the time of writing this
 * distinction is only required for VoxelRaster rendering, whereby the primary holds the data
 * and the secondaries show reformats of this.
 */
export const getPrimaryViewFromView = (view: View): View | undefined => {
  if (!isReformattedDICOMView(view)) {
    return view
  }

  const viewList = view.editor.viewsList
  const primaryView = viewList.find((view) => isDicomView(view) && view.isPrimary)

  return primaryView
}
