import { v4 as uuidv4 } from 'uuid'

import { createAndSelectAnnotation } from '@/modules/Editor/actions/utils/createAndSelectAnnotation'
import type { Bounds, Raster } from '@/modules/Editor/models/raster/Raster'
import { assertImageRaster } from '@/modules/Editor/models/raster/assertImageRaster'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import type { VideoAnnotationData } from '@/modules/Editor/AnnotationData'
import type { View } from '@/modules/Editor/views/view'
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'

export type CreateMaskAnnotationData = {
  view: View
  raster: Raster
  bounds?: Bounds // optional as it is not required when restoring a voxel deleted mask
  labelIndex: number
  classId: number
  frameIndex?: number
  annotationClass?: AnnotationClass
  annotationId?: string
}

/**
 * Creates a new mask annotation and associates it with the raster.
 *
 * The method accepts an optional frameIndex, if not provided, the current frame index is used.
 * The method also accepts an optional annotationClass, if not provided, the preselected
 * annotation class is used. (through the annotationManager)
 *
 * If an annotation map already exists for the labelIndex, this id will be used instead of creating
 * a new one.
 */
export async function createMaskAnnotation(params: CreateMaskAnnotationData): Promise<void> {
  const { view, raster, bounds, labelIndex, classId, annotationId, frameIndex, annotationClass } =
    params
  const frameIndexModified = frameIndex ?? view.currentFrameIndex

  // Allows to create an annotation with a controlled id in case we need to re-create a deleted
  // annotation, useful for undo/redo.
  const newAnnotationId = annotationId || raster.getAnnotationMapping(labelIndex) || uuidv4()

  raster.setAnnotationMapping(labelIndex, newAnnotationId, classId)

  if (raster.type === RasterTypes.VIDEO || raster.type === RasterTypes.VOXEL) {
    const videoRaster = assertVideoRaster(raster)

    const frames: VideoAnnotationData['frames'] = {
      [frameIndexModified]: {
        rasterId: raster.id,
      },
    }

    /**
     * `createMaskAnnotation` is generally called when a mask is not yet in the raster, so this
     * check is not necessary in most cases,
     * However, when deleting a mask from outside the raster (sidebar or timeline) and then
     * undoing the action, the mask will be re-serialized in the raster, and it could have multiple
     * frames (not just the current one), so if the mask is found in the raster, we make sure
     * we add all existing frames to it before sending the create call.
     * If not found, we just set the bounds and label in the raster.
     */
    if (videoRaster.getVideoBoundsForLabelIndex(labelIndex)) {
      const frameIndexes = videoRaster.getLabelKeyframes(labelIndex)

      frameIndexes.forEach((frameIndex) => {
        frames[frameIndex] = {
          rasterId: raster.id,
        }
      })
    } else {
      // This is a completely new annotation, we should update the raster with it
      if (!bounds) {
        throw new Error('Bounds are required for new mask annotations')
      }
      videoRaster.setVideoBoundsForLabelIndexForFrame(labelIndex, frameIndexModified, bounds)
      videoRaster.setLabelOnKeyframe(labelIndex, frameIndexModified)
    }

    let annotation = await view.annotationManager.prepareAnnotationForCreation({
      type: 'mask',
      id: newAnnotationId,
      data: {
        frames,
        sub_frames: {},
        segments: [[frameIndexModified, frameIndexModified + 1]],
        interpolated: false,
        interpolate_algorithm: 'linear-1.1',
      },
      annotationClass,
    })

    if (!annotation) {
      throw new Error('Failed to create mask annotation')
    }

    // Need to set this after prepareAnnotationForCreation, in order not change its internals.
    if (!annotation.data.segments) {
      throw new Error('Annotation segments not found on VOXEL or VIDEO raster')
    }
    const modifiedFrameIndexes = Object.keys(frames).map(Number)
    annotation.data.segments[0] = [
      Math.min(...modifiedFrameIndexes),
      Math.max(...modifiedFrameIndexes) + 1,
    ]
    annotation = createAndSelectAnnotation(view, annotation)

    // Call update so all the frames are registered.
    view.annotationManager.updateAnnotation(annotation, {
      updatedFramesIndices: modifiedFrameIndexes,
    })
  } else {
    const imageRaster = assertImageRaster(raster)

    if (!bounds) {
      throw new Error('Bounds are required for new mask annotations')
    }
    imageRaster.setBoundsForLabelIndex(labelIndex, bounds)

    const annotation = await view.annotationManager.prepareAnnotationForCreation({
      type: 'mask',
      id: newAnnotationId,
      data: {
        rasterId: raster.id,
      },
      annotationClass,
    })

    if (!annotation) {
      throw new Error('Failed to create mask annotation')
    }

    createAndSelectAnnotation(view, annotation)
  }
}
