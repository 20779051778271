<template>
  <component
    v-bind="$attrs"
    :is="tag"
    :class="`common-button common-button--${size}`"
    v-on="$listeners"
  >
    <slot>BUTTON TEMPLATE</slot>
  </component>
</template>

<script lang="ts">
export default { name: 'ButtonTemplate' }
</script>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    size?: 'default' | 'medium' | 'small'
    tag?: 'button' | 'router-link' | 'a' | 'div'
  }>(),
  { size: 'default', tag: 'button' },
)
</script>

<style lang="scss" scoped>
.common-button {
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 1px;
  text-align: center;

  border-radius: 3px;

  transition: all 0.2s ease;

  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &--default {
    height: 40px;
    padding: 2px 30px;
    font-size: 14px;
    line-height: 18px;
  }

  &--medium {
    height: 35px;
    padding: 2px 25px;
    font-size: 14px;
    line-height: 18px;
  }

  &--small {
    height: 25px;
    padding: 2px 10px;
    font-size: 10px;
    line-height: 14px;
  }
}
</style>
