import type { ViewEvent } from '@/modules/Editor/eventBus'
import { RasterManagerEvents } from '@/modules/Editor/eventBus'
import { CameraEvents, ViewEvents } from '@/modules/Editor/eventBus'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { InvalidatedRasterRegion, Raster } from '@/modules/Editor/models/raster/Raster'
import { assertVoxelRaster } from '@/modules/Editor/models/raster/assertVoxelRaster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import { getSegmentColorWithAlpha, renderMasks } from '@/modules/Editor/plugins/mask/MaskRenderer'
import { getPrimaryViewFromView } from '@/modules/Editor/plugins/mask/utils/shared/getPrimaryViewFromView'
import { Plane } from '@/modules/Editor/utils/raster/Plane'
import { getRasterPlaneFromRadiologySlotName } from '@/modules/Editor/utils/raster/getRasterPlaneFromRadiologySlotName'
import { getRasterBufferAccessorForPlane } from '@/modules/Editor/utils/raster/getRasterBufferAccessorForPlane'
import { isDicomView } from '@/modules/Editor/utils/radiology/isDicomView'
import { shouldApplyRasterScaling } from '@/modules/Editor/plugins/mask/utils/shared/shouldApplyRasterScaling'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'

import { Layer, Events as LayerEvents } from './layer'
import type { RenderableItem } from './object2D'
import type { RGB } from '@/modules/Editor/types'

/**
 * Manages rendering process for rasters independently of other
 * other annotations.
 *
 * Still has support for `Object2D` annotations that may come up
 * as subannotations for Rasters in the future.
 */
export class RasterLayer extends Layer {
  private _rasters: Set<string> = new Set()
  private readonly view: View
  private readonly viewId: string

  private get isOnPrimaryView(): boolean {
    return !isDicomView(this.view) || this.view.isPrimary
  }

  constructor(view: View) {
    super()

    if (this._canvas) {
      this._canvas.classList.add('raster-layer')
    }
    this.view = view
    this.viewId = view.id

    ViewEvents.imageFilterChanged.on(this.invalidateRasterInView)
    ViewEvents.currentFrameIndexChanged.on(this.onCurrentFrameIndexChanged)

    CameraEvents.scaleChanged.on(this.triggerRerender)
    CameraEvents.offsetChanged.on(this.triggerRerender)

    RasterManagerEvents.videoRasterKeyframeDeleted.on(this.invalidateRasterInView)
    RasterManagerEvents.rasterUpdated.on(this.triggerRerender)
    RasterManagerEvents.rasterDeleted.on(this.onRasterDelete)
  }

  /**
   * Callback which invalidates the raster being rendered on this layer.
   * This is so that (part of) the cached canvas can be recalculated as it is
   * now invalid.
   *
   * In the case of a VoxelRaster, we invalidate only what is needed based on the plane
   * (as potentially only one row is invalidated if this is a reformat).
   */
  private invalidateRasterInView = (e?: ViewEvent): void => {
    if (e && e.viewId !== this.viewId) {
      return
    }

    const raster = this.getRasterForFileInPrimaryView()

    if (!raster) {
      return
    }

    if (raster.type === RasterTypes.VOXEL) {
      // An optimisation to only invalidate the plane, so
      // that other views need not update if they don't intersect.
      const voxelRaster = assertVoxelRaster(raster)
      const { slot_name, metadata } = this.view.fileManager.file
      const currentFrameIndex = this.view.currentFrameIndex
      const plane = getRasterPlaneFromRadiologySlotName(slot_name, metadata)

      if (plane === undefined) {
        // optimistically just re-render everything
        voxelRaster.invalidateAll()
      } else if (plane === Plane.X) {
        voxelRaster.invalidateXPlane(currentFrameIndex)
      } else if (plane === Plane.Y) {
        voxelRaster.invalidateYPlane(currentFrameIndex)
      } else if (plane === Plane.Z) {
        voxelRaster.invalidateZPlane(currentFrameIndex)
      }
    } else {
      raster.invalidateAll()
    }
  }

  /**
   * Call invalidate if there is a raster image on this layer when the frame index changes.
   */
  private onCurrentFrameIndexChanged = (e?: ViewEvent): void => {
    if (e && e.viewId !== this.viewId) {
      return
    }

    const raster = this.getRasterForFileInPrimaryView()

    if (!raster) {
      return
    }

    this.invalidateRasterInView()
  }

  private onRasterDelete = (e: ViewEvent, rasterId: string): void => {
    e.viewId === this.viewId && this.removeRaster(rasterId)
  }

  private triggerRerender = (e: ViewEvent): void => {
    if (e.viewId !== this.viewId) {
      return
    }
    this._hasChanges = true
    this.render()
  }

  public getRasterIds(): string[] {
    return Array.from(this.rasters.values())
  }

  public addRaster(rasterId: string): void {
    this._rasters.add(rasterId)
    this.changed()
  }

  public removeRaster(rasterId: string): void {
    this._rasters.delete(rasterId)
    this.changed()
  }

  get rasters(): Set<string> {
    if (this.isOnPrimaryView) {
      return this._rasters
    }

    // On initial render, the layout object might not yet be instantiated.
    if (!this.view.editor.layout) {
      return new Set()
    }

    const primaryView = getPrimaryViewFromView(this.view)
    const primaryViewRasterLayer = primaryView?.rasterAnnotationLayer

    if (!primaryViewRasterLayer) {
      // probably not yet initialised, return blank set.
      return new Set()
    }

    const rasterIds = primaryViewRasterLayer.getRasterIds()
    const rasterIdSet = new Set<string>()

    rasterIds.forEach((rasterId) => rasterIdSet.add(rasterId))

    return rasterIdSet
  }

  private _hiddenItems: Set<RenderableItem['id']> = new Set()

  public hideAll(ids?: RenderableItem['id'][]): void {
    if (ids) {
      ids.forEach((id) => this._hiddenItems.add(id))
    } else {
      for (const id of this._rasters.keys()) {
        this._hiddenItems.add(id)
      }
    }

    // Raster display control requires invalidation, not just canvas redrawing,
    // as all raster have shared IDs.
    this.invalidateRasterInView()
    this.changed()
  }

  public showAll(ids?: RenderableItem['id'][]): void {
    if (ids) {
      ids.forEach((id) => this._hiddenItems.delete(id))
    } else {
      this._hiddenItems.clear()
    }

    // Raster display control requires invalidation, not just canvas redrawing,
    // as all raster have shared IDs.
    this.invalidateRasterInView()
    this.changed()
  }

  /**
   * Renders groups of mask annotations to single rasters in one draw call,
   * using the `Annotation` data to style the rendering of different
   * portions of the raster.
   *
   * If the layer has no changes it will skip the re-render.
   */
  public render(): void {
    if (!this.context) {
      return
    }
    if (!this._hasChanges) {
      return
    }

    this._hasChanges = false

    this.emit(LayerEvents.BEFORE_RENDER, this.context, this.canvas)

    this.rasters.forEach((rasterId) => this.renderRaster(rasterId))

    // Render the subannotations (TODO: Not using these yet)
    Object.values(this._renderPool).forEach((item) => {
      try {
        item.render(this.context, this.canvas)
      } catch (e) {
        setContext('error', { error: e })
        console.error('V2 Raster layer failed to render object')
      }
    })

    this.emit(LayerEvents.RENDER, this.context, this.canvas)
  }

  renderRaster = (rasterId: string): void => {
    if (shouldApplyRasterScaling(this.view)) {
      this.renderRasterWithScaling(rasterId)
    } else {
      this.renderRasterWithoutScaling(rasterId)
    }
  }

  /**
   * Gets the raster that is associated with the primary of this view.
   */
  private getRasterForFileInPrimaryView(): Raster | undefined {
    if (this.isOnPrimaryView) {
      return this.view.rasterManager.getRasterForFileInView()
    }

    const primaryView = getPrimaryViewFromView(this.view)

    return primaryView?.rasterManager.getRasterForFileInView()
  }

  private renderRasterWithScaling(rasterId: string): void {
    const { view } = this
    const { slot_name, metadata } = this.view.fileManager.file
    const currentFrameIndex = this.view.currentFrameIndex
    const plane = getRasterPlaneFromRadiologySlotName(slot_name, metadata)

    if (!plane) {
      throw new Error('No plane found for given slot name')
    }

    const primaryView = getPrimaryViewFromView(this.view)

    if (!primaryView) {
      // Views not set up yet, return early
      return
    }

    const raster = primaryView.rasterManager.getRaster(rasterId)

    if (!raster || raster.type !== RasterTypes.VOXEL) {
      throw new Error('Volumetric raster not found')
    }

    const voxelRaster = assertVoxelRaster(raster)

    const rasterBufferAccessor = getRasterBufferAccessorForPlane(
      voxelRaster,
      plane,
      currentFrameIndex,
    )

    const { labelColorMap } = this.getCommonRenderables(primaryView, voxelRaster)

    let cachedCanvas: HTMLCanvasElement | undefined
    let invalidatedRasterRegion: InvalidatedRasterRegion

    if (plane === Plane.X) {
      invalidatedRasterRegion = voxelRaster.planeXInvalidationRegion
      cachedCanvas = voxelRaster.planeXCachedCanvas
    } else if (plane === Plane.Y) {
      invalidatedRasterRegion = voxelRaster.planeYInvalidationRegion
      cachedCanvas = voxelRaster.planeYCachedCanvas
    } else if (plane === Plane.Z) {
      invalidatedRasterRegion = voxelRaster.invalidatedRegion
      cachedCanvas = voxelRaster.cachedCanvas
    } else {
      throw new Error(`Unknown medical plane: ${plane}`)
    }

    const fileMetadata = view.fileManager.file.metadata

    if (!fileMetadata) {
      throw new Error('No file metadata present, we require this for VoxelRaster scaling.')
    }

    const viewWidth = fileMetadata.width
    const viewHeight = fileMetadata.height

    const targetViewportSize = {
      x: viewWidth,
      y: viewHeight,
    }

    renderMasks(this, view.camera, {
      rasterBufferAccessor,
      cachedCanvas,
      invalidatedRasterRegion,
      labelColorMap,
      targetViewportSize,
    })
  }

  /**
   * Renders the raster on an image, video, or primary DICOM.
   */
  private renderRasterWithoutScaling = (rasterId: string): void => {
    const raster = this.view.rasterManager.getRaster(rasterId)

    if (raster === undefined) {
      throw new Error('Raster referened by layer is not found in RasterManager')
    }

    const rasterBufferAccessor = raster.getActiveBufferForRender()
    const { labelColorMap } = this.getCommonRenderables(this.view, raster)

    const { cachedCanvas, invalidatedRegion } = raster
    const isInvalidated = raster.isInvalidated()

    renderMasks(this, this.view.camera, {
      rasterBufferAccessor,
      cachedCanvas,
      isInvalidated,
      invalidatedRasterRegion: invalidatedRegion,
      labelColorMap,
    })

    raster.clearInvalidation()
  }

  public getCommonRenderables(
    view: View,
    raster: Raster,
  ): {
    labelColorMap: Record<string, number>
    labelRGBColorMap: Record<string, RGB>
  } {
    const { imageFilter } = view
    const { labelsOnRaster, annotationIdsOnRaster } = raster
    const fillOpacity = imageFilter.opacity

    const visibleAnnotations: Annotation[] = []

    // Get annotations required for colors, etc
    annotationIdsOnRaster.forEach((annotationId) => {
      const annotation = this.getMaskAnnotationForRaster(view, raster, annotationId)

      if (annotation && !this._hiddenItems.has(annotation.id)) {
        visibleAnnotations.push(annotation)
      }
    })

    const labelsToAnnotationIds: Record<number, string> = {}

    labelsOnRaster.forEach((labelIndex: number) => {
      const annotationIdForLabel = raster.getAnnotationMapping(labelIndex)

      if (annotationIdForLabel) {
        labelsToAnnotationIds[Number(labelIndex)] = annotationIdForLabel
      }
    })

    const labelColorMap: Record<string, number> = {}
    const labelRGBColorMap: Record<string, RGB> = {}
    Object.keys(labelsToAnnotationIds).forEach((labelIndex) => {
      const annotationId = labelsToAnnotationIds[Number(labelIndex)]
      const annotation = visibleAnnotations.find((annotation) => annotation.id === annotationId)

      if (annotation !== undefined) {
        const color = getSegmentColorWithAlpha(annotation.color, fillOpacity)

        labelColorMap[labelIndex] = color
        labelRGBColorMap[labelIndex] = annotation.color
      }
    })

    return { labelColorMap, labelRGBColorMap }
  }

  private getMaskAnnotationForRaster = (
    view: View,
    raster: Raster,
    annotationId: string,
  ): Annotation | undefined => {
    // Check the annotation manager
    const annotation = view.annotationManager.getAnnotation(annotationId)

    if (annotation) {
      return annotation
    }

    // Fallback to checking if we have a temporary in progress annotation
    // (e.g. during a brush stroke for an as-of-yet uncreated mask annotation)
    return raster.getInProgressAnnotation(annotationId)
  }

  clear(): void {
    super.clear()
    this._rasters.clear()
  }

  destroy(): void {
    super.destroy()

    ViewEvents.imageFilterChanged.off(this.invalidateRasterInView)
    ViewEvents.currentFrameIndexChanged.off(this.onCurrentFrameIndexChanged)

    CameraEvents.scaleChanged.off(this.triggerRerender)
    CameraEvents.offsetChanged.off(this.triggerRerender)

    RasterManagerEvents.videoRasterKeyframeDeleted.off(this.invalidateRasterInView)
    RasterManagerEvents.rasterUpdated.off(this.triggerRerender)
    RasterManagerEvents.rasterDeleted.off(this.onRasterDelete)
  }
}
