import type { NavigationGuard } from 'vue-router'

import { getCookie } from '@/core/utils/cookies'
import session from '@/backend/session'

const getRefreshTokenFromCookie = (): string | undefined => {
  const refreshKeys = ['v7_sso_saml_sign_in_refresh_token', 'v7_sso_sign_in_refresh_token']
  const refreshToken = refreshKeys.map((key) => getCookie(key)).find((value) => !!value)
  return refreshToken
}

const getTokenFromCookie = (): string | undefined => {
  const tokenKeys = ['v7_sso_saml_sign_in_token', 'v7_sso_sign_in_token']
  const token = tokenKeys.map((key) => getCookie(key)).find((value) => !!value)
  return token
}

export const ssoExchangeGuard: NavigationGuard<Vue> = (to, from, next) => {
  const refreshToken = getRefreshTokenFromCookie()
  const token = getTokenFromCookie()

  if (!refreshToken || !token) {
    console.error('Cannot get token or refreshToken')
    next({ name: 'Login' })
    return
  }

  const parsedToken = window.atob(token.split('.')[1])

  let tokenExpiration
  if (parsedToken) {
    tokenExpiration = JSON.parse(parsedToken)?.exp?.toString()
  }

  if (!tokenExpiration) {
    console.error('Cannot get tokenExpiration')
    next({ name: 'Login' })
    return
  }

  session.authenticate({
    isPermanent: true,
    refreshToken,
    token,
    tokenExpiration,
  })

  next({ name: 'Root' })
}
