import type { CompoundPath } from '@/modules/Editor/compoundPath'
import type { DeletableVertexContext } from '@/modules/Editor/plugins/edit/types'

export const resolvePolygonDeletableVertexContext = (
  compoundPath: CompoundPath,
  selectedVertexIndex: number | null,
): DeletableVertexContext => {
  if (selectedVertexIndex === null) {
    return null
  }
  // For polygons, search all paths for a selected vertex
  const paths = [compoundPath.path, ...compoundPath.additionalPaths]
  let pathLengthAccumulator = 0

  if (paths.length === 1 && paths[0].length <= 3) {
    return null
  }

  for (let pathIndex = 0; pathIndex < paths.length; pathIndex++) {
    const path = paths[pathIndex]

    // Path with 3 points or less will be deleted
    if (path.length <= 3) {
      return { action: 'update', content: [paths, pathIndex] }
    }

    // If the selected vertex is in the current path, return the context
    if (selectedVertexIndex < path.length + pathLengthAccumulator) {
      return {
        action: 'deleteVertex',
        content: [path, selectedVertexIndex - pathLengthAccumulator],
      }
    }

    pathLengthAccumulator += path.length
  }
  return null
}
