import { getPathFromPolyline } from '@/modules/Editor/annotationTypes/polyline'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import { drawOpenPathV2 } from '@/modules/Editor/graphics'
import { inferCurrentAnnotationData } from '@/modules/Editor/inferCurrentAnnotationData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { getPath2D } from '@/modules/Editor/models/annotation/annotationRenderingCache'
import type { DrawCallback } from '@/modules/Editor/models/layers/types'
import type { View } from '@/modules/Editor/views/view'

export const renderPolyline = (
  drawFn: DrawCallback,
  view: View,
  annotation: Annotation,
  filter: ImageManipulationFilter | null,
): Path2D | void => {
  const isSelected = view.annotationManager.isSelected(annotation.id)
  const path = getPathFromPolyline(inferCurrentAnnotationData(annotation, view.currentFrameIndex))
  const color = annotation.color

  const oldPath2D = getPath2D(annotation.id)

  return drawOpenPathV2(drawFn, view.camera, path, color, filter, isSelected, oldPath2D)
}
