import { ModelType } from '@/core/annotations'
import type { Editor } from '@/modules/Editor/editor'

/**
 * Checks whether or not the preselected model is of type `auto_annotate`.
 */
export const isPreselectedModelAutoAnnotate = (editor: Editor): boolean => {
  const { preselectedAutoAnnotateModel: preselectedModel } = editor.autoAnnotateManager
  if (!preselectedModel) {
    return false
  }

  const model = editor.autoAnnotateModels.find((m) => m.id === preselectedModel.id)
  // If no trained model is found, we should assume that the preselected model
  // is in fact of AutoAnnotation type
  if (!model) {
    return true
  }
  return model.type === ModelType.AUTO_ANNOTATE
}
