import cloneDeep from 'lodash/cloneDeep'

import { isAnnotationFrameData } from '@/core/annotations'
import { captureMessage as captureSentryMessage } from '@/services/sentry'
import type { sendV2Commands } from '@/backend/darwin/sendV2Commands'

import {
  convertToUpdate,
  isCreateCommand,
  isShiftCommand,
  mergeRequests,
} from './annotationRequestAdapter'
import type { Request } from './requestMap'
import { ORequestType } from './requestMap'

type SendCommandsParams = Parameters<typeof sendV2Commands>[0]

const last = (queue: Array<Request<SendCommandsParams>>): Request<SendCommandsParams> =>
  queue[queue.length - 1]

export const ReducerAction = {
  /**
   * Add a command to the end of the queue
   */
  Add: 'add',
  /**
   * Update a set of commands in the queue
   */
  Update: 'update',
  /**
   * Replace the entire queue with this command
   */
  ReplaceAllQueued: 'replace_all_queued',
  /**
   * Discard the final item of the queue
   */
  DiscardLastQueued: 'discard_last_queued',
  /**
   * Discard the new item
   */
  DiscardNew: 'discard_new',
} as const

export type ReducerAction = (typeof ReducerAction)[keyof typeof ReducerAction]

type AddCommand = [(typeof ReducerAction)['Add'], Request<SendCommandsParams>]
type Update = [(typeof ReducerAction)['Update'], Array<Request<SendCommandsParams>>]
type ReplaceAllQueued = [(typeof ReducerAction)['ReplaceAllQueued'], Request<SendCommandsParams>]
type DiscardLastQueued = [(typeof ReducerAction)['DiscardLastQueued'], undefined]
type DiscardNew = [(typeof ReducerAction)['DiscardNew'], undefined]

type ReducerReturn = AddCommand | Update | ReplaceAllQueued | DiscardLastQueued | DiscardNew

/**
 * Performs merge of 3 requests for a single record
 *
 * @param inFlight The request already fired via network
 * @param queue Queue of pending requests to send
 * @param toBeAdded The request being added now
 *
 * The requests are merged in such a way where we either
 * - send back a request to replace the queued request
 * - send back 'discard' indicating the 'toBeAdded' request is to be ignored
 *
 * When sending back a request, a merge is performed where, at minimum, the id of the queued
 * request needs to be reused, as it's used to map request listeners
 */
export const reduceWithMerge = (
  inFlight: Request<SendCommandsParams> | null,
  queue: Array<Request<SendCommandsParams>>,
  toBeAdded: Request<SendCommandsParams>,
): ReducerReturn => {
  // Shouldn't really happen. Means the request data is blank.
  // In this case, the function wouldn't even get called.
  if (!inFlight && queue.length === 0) {
    captureSentryMessage('Reducer unexpectedly received event while in blank state ', {
      extra: { inFlight, queue, toBeAdded },
    })
    return [ReducerAction.Add, toBeAdded]
  }

  // A duplicate create request is a mistake in the system, but can be "recovered" from
  // by converting it to an update
  if (
    queue.length == 0 &&
    inFlight?.type === ORequestType.CREATE &&
    toBeAdded.type === ORequestType.CREATE
  ) {
    captureSentryMessage('Reducer unexpectedly received a duplicate create request', {
      extra: { inFlight, queue, toBeAdded },
    })

    return [
      ReducerAction.Add,
      {
        id: toBeAdded.id,
        type: ORequestType.UPDATE,
        payload: convertToUpdate(toBeAdded.payload),
      },
    ]
  }

  // If there is a request to start an auto-track session, then the annotation should be read-only
  // and no further requests should be made. We should throw an error, since processing the
  // request could put the backend in a weird state, and we don't want the frontend to think that
  // the request has been successfully processed. Ideally we will never reach this point, since the
  // UI should prevent the user from making any other changes to an annotation with an active
  // auto-track session.
  const autotrackIsInFlight = inFlight?.type === ORequestType.START_AUTO_TRACK
  const autotrackIsQueued = queue.some((r) => r.type === ORequestType.START_AUTO_TRACK)
  const isUpdatingAnnotation =
    toBeAdded.type === ORequestType.UPDATE || toBeAdded.type === ORequestType.SHIFT
  if ((autotrackIsInFlight || autotrackIsQueued) && isUpdatingAnnotation) {
    captureSentryMessage(
      'Reducer unexpectedly received a request while there is an active auto-track session',
      {
        extra: { inFlight, queue, toBeAdded },
      },
    )
    throw new Error('Cannot modify annotation with active auto-track session')
  }

  // these result in both the last queued and new request being discarded,
  // as they are together a no-op

  const isCreateUndoRedo =
    inFlight?.type === ORequestType.CREATE &&
    last(queue)?.type === ORequestType.DELETE &&
    toBeAdded.type === ORequestType.CREATE

  const isCopyUndoRedo =
    inFlight?.type === ORequestType.COPY &&
    last(queue)?.type === ORequestType.DELETE &&
    toBeAdded.type === ORequestType.COPY

  const isDeleteUndoRedo =
    inFlight?.type === ORequestType.DELETE &&
    last(queue)?.type === ORequestType.CREATE &&
    toBeAdded.type === ORequestType.DELETE

  const isUpdateDeleteUndo =
    inFlight?.type === ORequestType.UPDATE &&
    last(queue)?.type === ORequestType.DELETE &&
    toBeAdded.type === ORequestType.CREATE

  const isReorderDeleteUndo =
    inFlight?.type === ORequestType.REORDER &&
    last(queue)?.type === ORequestType.DELETE &&
    toBeAdded.type === ORequestType.CREATE

  if (
    isCreateUndoRedo ||
    isCopyUndoRedo ||
    isDeleteUndoRedo ||
    isUpdateDeleteUndo ||
    isReorderDeleteUndo
  ) {
    return [ReducerAction.DiscardLastQueued, undefined]
  }

  if (toBeAdded.type === ORequestType.START_AUTO_TRACK) {
    /**
     * If there is a queued request to stop an auto-track session then
     * we can discard both the start and stop requests, as they are a no-op.
     */
    if (last(queue)?.type === ORequestType.STOP_AUTO_TRACK) {
      return [ReducerAction.DiscardLastQueued, undefined]
    }

    // As long as there is no queued request to stop an auto-track session, we can
    // always the start-auto-track command to the end of the queue.
    return [ReducerAction.Add, toBeAdded]
  }

  if (toBeAdded.type === ORequestType.STOP_AUTO_TRACK) {
    /**
     * If there already is a queued request to start an auto-track session then
     * we can discard both the start and stop requests, as they are a no-op.
     */
    if (last(queue)?.type === ORequestType.START_AUTO_TRACK) {
      return [ReducerAction.DiscardLastQueued, undefined]
    }

    // Otherwise we can always add the stop-auto-track command to the end of the queue.
    return [ReducerAction.Add, toBeAdded]
  }

  // With a delete in progress and nothign queued, any update or reorder requests being
  // introduced are out of order and can safely be discarded
  if (
    inFlight?.type === ORequestType.DELETE &&
    queue.length == 0 &&
    (toBeAdded.type === ORequestType.UPDATE ||
      toBeAdded.type === ORequestType.REORDER ||
      toBeAdded.type === ORequestType.SHIFT)
  ) {
    return [ReducerAction.DiscardNew, undefined]
  }

  // The inflight request (if we had it) is compatible with the toBeAdded request
  // and there is nothing queued, so the toBeAdded becomes the queued
  if (queue.length == 0) {
    return [ReducerAction.Add, toBeAdded]
  }

  // An update following a create, neither of which are fired yet, can be merged into a create
  // this includes partial sequence annotation update requests.
  const queuedCreate = queue.find((r) => r.type === ORequestType.CREATE)
  if (queuedCreate && toBeAdded.type === ORequestType.UPDATE) {
    return [
      ReducerAction.Update,
      [{ ...queuedCreate, payload: mergeRequests(queuedCreate.payload, toBeAdded.payload) }],
    ]
  }

  // Any unfired request followed by a delete request can be discarded
  // Note that we already handled some edge cases where this does not hold true in previous checks
  if (queue.length != 0 && toBeAdded.type === ORequestType.DELETE) {
    // We need to recycle the id
    return [ReducerAction.ReplaceAllQueued, toBeAdded]
  }

  // An error in the system that we can resolve.
  // Create following an update means we can first convert the create to an update,
  // then merge the two
  const queuedUpdateIndex = queue.findIndex((r) => r.type === ORequestType.UPDATE)
  const queuedUpdate = queuedUpdateIndex === -1 ? null : queue[queuedUpdateIndex]
  if (queuedUpdate && toBeAdded.type === ORequestType.CREATE) {
    return [
      ReducerAction.Update,
      [
        {
          ...queuedUpdate,
          payload: mergeRequests(queuedUpdate.payload, convertToUpdate(toBeAdded.payload)),
        },
      ],
    ]
  }

  /*
   * Shift commands can be merged into:
   * - An existing create command by incrementing all of the frame indices
   *   of the create command
   * - An existing shift command by summing the shift amounts
   *
   * When merging into a shift, we will also need to update any queued update
   * commands that come AFTER the updated shift command, as the frame indices
   * will have changed.
   *
   * So the possible cases are:
   * 1. shift,?update + shift -> newShift,?newUpdate
   * 2. create + shift -> newCreate (there will never be create,update as an update
   *    would have been merged into the create already)
   * 3. no shift or create in the queue -> add shift to the end
   */
  if (toBeAdded.type === ORequestType.SHIFT) {
    const queuedShiftIndex = queue.findIndex((r) => r.type === ORequestType.SHIFT)
    const queuedShift = queuedShiftIndex !== -1 ? queue[queuedShiftIndex] : null

    if (queuedShift) {
      // Case 1 - merge the two shift commands
      const updates = [
        {
          ...queuedShift,
          payload: mergeRequests(queuedShift.payload, toBeAdded.payload),
        },
      ]

      const proceedingUpdate = queue.find(
        (r, i) => i > queuedShiftIndex && r.type === ORequestType.UPDATE,
      )
      // If there is an update that comes AFTER the original shift, then we
      // need to apply the shift to that update as well
      if (proceedingUpdate) {
        updates.push({
          ...proceedingUpdate,
          payload: mergeRequests(proceedingUpdate.payload, toBeAdded.payload),
        })
      }

      return [ReducerAction.Update, updates]
    }

    // Case 2 - merge the shift into the create command
    if (queuedCreate) {
      const command = queuedCreate.payload.commands[0]
      if (!isCreateCommand(command)) {
        throw new Error('Create request contains an invalid command')
      }

      const annotationData = command.data.data
      if (isAnnotationFrameData(annotationData)) {
        throw new Error("Can't merge shift command into frame data")
      }

      const segmentEnd = annotationData.segments?.[0][1]

      // Condition is theoretically possible according to TypeScript types;
      // retained to future-proof the code.
      if (segmentEnd === null) {
        console.warn('Unexpected annotation created with null end segment', toBeAdded)

        captureSentryMessage('Unexpected annotation created with null end segment', {
          extra: { inFlight, queue, toBeAdded },
        })

        return [ReducerAction.Add, toBeAdded]
      }

      return [
        ReducerAction.Update,
        [
          {
            ...queuedCreate,
            payload: mergeRequests(queuedCreate.payload, toBeAdded.payload),
          },
        ],
      ]
    }

    // Case 3 - add the shift to the end of the queue since we cannot merge
    return [ReducerAction.Add, toBeAdded]
  }

  // Two update requests are either containing
  // - full payloads for a single frame annotation, meaning the second one overrides the first one
  // - partial payloads for a sequence annotation, meaning a merge is possible
  //
  // Both cases are handled by merging the payloads
  if (queuedUpdate && toBeAdded.type === ORequestType.UPDATE) {
    const proceedingShift = queue.find(
      (r, i) => i > queuedUpdateIndex && r.type === ORequestType.SHIFT,
    )
    if (proceedingShift) {
      // If there has been a shift queued up after the first update, then we will need to
      // 'unshift' the new update before merging to account for the fact that the update was
      // queued after the shift had been applied, and we're sending it to the backend before
      // the shift.
      const reverseShift: typeof proceedingShift = cloneDeep(proceedingShift)
      if (!isShiftCommand(reverseShift.payload.commands[0])) {
        throw new Error('Shift request contains an invalid command')
      }

      // We reverse the shift by multipying the shift amount by -1
      reverseShift.payload.commands[0].data.shift_amount *= -1

      const unshiftedUpdate = mergeRequests(toBeAdded.payload, reverseShift.payload)
      return [
        ReducerAction.Update,
        [
          {
            ...queuedUpdate,
            payload: mergeRequests(queuedUpdate.payload, unshiftedUpdate),
          },
        ],
      ]
    }
    return [
      ReducerAction.Update,
      [{ ...queuedUpdate, payload: mergeRequests(queuedUpdate.payload, toBeAdded.payload) }],
    ]
  }

  // Replace content of earlier reorder request by new one
  const queuedReorder = queue.find((r) => r.type === ORequestType.REORDER)
  if (queuedReorder && toBeAdded.type === ORequestType.REORDER) {
    return [ReducerAction.Update, [{ ...queuedReorder, payload: toBeAdded.payload }]]
  }

  console.warn(
    'Impossible path reached in advanced annotation reducer',
    inFlight?.type,
    queue,
    toBeAdded.type,
  )

  // No merge resolution happened. This should not be possible, so we track via sentry
  captureSentryMessage('Impossible path reached in advanced annotation reducer', {
    extra: { inFlight, queue, toBeAdded },
  })

  // Again, should not be possible, but as fallback, just add new request
  return ['add', toBeAdded]
}
