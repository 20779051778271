import { type V2AnnotationPayload } from '@/store/types'
import { getMainAnnotationType } from '@/core/annotationTypes'
import { annotationToRenderableItem } from '@/modules/Editor/models/annotation/annotationToRenderableItem'
import { createAnnotationFromDeserializable } from '@/modules/Editor/models/annotation/annotationFactories'
import { parseAnnotationData } from '@/modules/Editor/models/layers/optimisedLayer/dataParser/parseAnnotationData'
import type { RenderableItemWithState } from '@/modules/Editor/models/layers/object2D'
import type { BBox } from '@/modules/Editor/types'
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import type { RTreeItem } from '@/modules/Editor/models/layers/optimisedLayer/types'

type ReturnType = {
  bbox: BBox
  rTreeItem: RTreeItem
  item: RenderableItemWithState
}

export const calcAnnotationFrame = (
  annotation: V2AnnotationPayload,
  slotName: string,
  frameIndex: number,
  annotationClass: AnnotationClass,
  config: {
    totalFrames: number
    videoAnnotationDuration: number
    isProcessedAsVideo: boolean
  },
): ReturnType | null => {
  const type = getMainAnnotationType(annotationClass.annotation_types)
  if (!type) {
    return null
  }

  const editorAnnotation = createAnnotationFromDeserializable(annotationClass, annotation)
  if (!editorAnnotation) {
    return null
  }

  const renderableItem = annotationToRenderableItem(editorAnnotation, {
    frameIndex,
    totalFrames: config.totalFrames,
    slotName: slotName,
    isProcessedAsVideo: config.isProcessedAsVideo,
    videoAnnotationDuration: config.videoAnnotationDuration,
  })

  if (!renderableItem) {
    return null
  }

  const parsedAnn = parseAnnotationData(renderableItem)

  if (!parsedAnn) {
    return null
  }

  return {
    bbox: parsedAnn.bbox,
    rTreeItem: parsedAnn.rTreeItem,
    item: parsedAnn.item,
  }
}
