import type { ApiResponse, FeaturePayload } from '@/store/types'
import { get } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

export const loadTeamFeatures = async (
  teamId: number,
): Promise<ApiResponse<FeaturePayload[]> | ParsedError> => {
  try {
    const response = await get<FeaturePayload[]>(`teams/${teamId}/features`)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.GET_FEATURES)
  }
}
