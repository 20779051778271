import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { ToolEvents } from '@/modules/Editor/eventBus'
import { isLeftMouseButton, isMiddleMouseButton } from '@/modules/Editor/mouse'
import type { IPoint } from '@/modules/Editor/point'
import { addPoints, mulScalar, subPoints } from '@/modules/Editor/point'
import { isIOSTouch, TouchType } from '@/core/utils/touch'
import type { Editor } from '@/modules/Editor/editor'
import type { SharedToolState, ToolContext } from '@/modules/Editor/managers/toolManager'
import { isModifierPressed } from '@/modules/Editor/utils'

import { touchMiddlePoint } from './utils'

const KEY_OFFSET = 28

type PanningContext = {
  panCursorStart: IPoint | null
  panOriginalOffset: IPoint | null
}

const getCursorPoint = (event: MouseEvent, editor: Editor): IPoint => {
  if (editor.viewsList.length === 1) {
    return { x: event.offsetX, y: event.offsetY }
  }
  return editor.activeView.camera.getOffset()
}

/**
 * Tells us if the mouse pan was past the minimal threshold.
 *
 * This allows us to use mouse primary button panning in conjunction with the
 * keypoint tool, for example, where the keypoint doesn't need dragging and is
 * drawn by a single click.
 */
const didMovePastThreshold = (startOffset: IPoint, endOffset: IPoint): boolean => {
  const delta = subPoints(startOffset, endOffset) || { x: 0, y: 0 }

  const deltaX = Math.abs(delta?.x || 0)
  const deltaY = Math.abs(delta?.y || 0)

  return deltaX > 10 || deltaY > 10
}

const exitMousePanning = (
  event: MouseEvent,
  panningContext: PanningContext,
  toolContext: ToolContext,
): CallbackStatus => {
  if (!toolContext.editor.activeView.mainLayer.canvas) {
    return CallbackStatus.Continue
  }
  event.preventDefault()
  toolContext.editor.activeView.mainLayer.canvas.ownerDocument.exitPointerLock()

  const currentOffset = toolContext.editor.activeView.camera.getOffset()

  // before reseting, we figure out if the pan was big enough to be considered
  // an actual pan, and not just a random click
  const movedPastThreshold =
    !!panningContext.panOriginalOffset &&
    didMovePastThreshold(panningContext.panOriginalOffset, currentOffset)

  panningContext.panCursorStart = null
  panningContext.panOriginalOffset = null

  if (movedPastThreshold) {
    return CallbackStatus.Stop
  }
  return CallbackStatus.Continue
}

const enterMousePanning = (
  event: MouseEvent,
  panningContext: PanningContext,
  toolContext: ToolContext,
): void => {
  event.preventDefault()

  panningContext.panCursorStart = getCursorPoint(event, toolContext.editor)
  panningContext.panOriginalOffset = toolContext.editor.activeView.camera.getOffset()
}

const doMousePanning = (
  event: MouseEvent,
  panningContext: PanningContext,
  toolContext: ToolContext,
): CallbackStatus => {
  if (panningContext.panCursorStart === null) {
    return CallbackStatus.Continue
  }
  if (panningContext.panOriginalOffset === null) {
    return CallbackStatus.Continue
  }

  if (toolContext.editor.viewsList.length === 1) {
    const cursorPoint = { x: event.offsetX, y: event.offsetY }
    const newOffset = addPoints(
      mulScalar(subPoints(panningContext.panCursorStart, cursorPoint), 2),
      panningContext.panOriginalOffset,
    )

    toolContext.editor.activeView.camera.setOffset(newOffset)
  } else {
    if (!toolContext.editor.activeView.mainLayer.canvas) {
      return CallbackStatus.Continue
    }
    toolContext.editor.activeView.mainLayer.canvas.requestPointerLock()

    const cursorPoint = { x: event.movementX, y: event.movementY }
    panningContext.panCursorStart = subPoints(panningContext.panCursorStart, cursorPoint)

    toolContext.editor.activeView.camera.setOffset(panningContext.panCursorStart)
  }

  return CallbackStatus.Stop
}

/**
 * Sets up panning using primary mouse button
 */
const setupPrimaryButtonPanning = (context: ToolContext, sharedState?: SharedToolState): void => {
  const panningContext: PanningContext = {
    panCursorStart: null,
    panOriginalOffset: null,
  }
  context.handles.push(
    ...context.editor.onMouseDown((event) => {
      if (!isLeftMouseButton(event)) {
        return CallbackStatus.Continue
      }
      if (sharedState) {
        ToolEvents.framePanningStateChange.emit(true)
        sharedState.isPanning = true
      }
      window.addEventListener(
        'mouseup',
        (event) => {
          if (sharedState) {
            ToolEvents.framePanningStateChange.emit(false)
            sharedState.isPanning = false
          }
          exitMousePanning(event, panningContext, context)
        },
        {
          once: true,
        },
      )
      return enterMousePanning(event, panningContext, context)
    }),
  )

  context.handles.push(
    ...context.editor.onMouseMove((event) => doMousePanning(event, panningContext, context)),
  )

  context.handles.push(
    ...context.editor.onMouseLeave(() => {
      panningContext.panCursorStart = null
    }),
  )
}

/**
 * Sets up panning using touch events
 */
const setupTouchPanning = (context: ToolContext): void => {
  const panningContext: PanningContext = {
    panCursorStart: null,
    panOriginalOffset: null,
  }

  context.handles.push(
    ...context.editor.onTouchStart((event) => {
      event.preventDefault()

      const length = event.targetTouches.length
      if (length !== 1) {
        return
      }

      panningContext.panCursorStart = touchMiddlePoint(event)
      panningContext.panOriginalOffset = context.editor.activeView.camera.getOffset()
    }),
  )

  context.handles.push(
    ...context.editor.onTouchMove((event) => {
      event.preventDefault()

      const touch = event.targetTouches[0]
      if (isIOSTouch(touch) && touch.touchType === TouchType.STYLUS) {
        return
      }

      const cursorPoint = touchMiddlePoint(event)
      if (panningContext.panCursorStart !== null && panningContext.panOriginalOffset !== null) {
        context.editor.activeView.camera.setOffset(
          addPoints(
            subPoints(panningContext.panCursorStart, cursorPoint),
            panningContext.panOriginalOffset,
          ),
        )
        return CallbackStatus.Stop
      }
    }),
  )

  context.handles.push(
    ...context.editor.onTouchEnd((event) => {
      event.preventDefault()

      panningContext.panCursorStart = null
    }),
  )
}

/**
 * Sets up panning using WASD keys
 */
const setupKeyPanning = (context: ToolContext): void => {
  const keyStatus: {
    KeyW: boolean
    KeyA: boolean
    KeyS: boolean
    KeyD: boolean
  } = { KeyW: false, KeyA: false, KeyS: false, KeyD: false }
  type VALID_KEYCODES = keyof typeof keyStatus

  const computePanDeltaFromKey = (withShift: boolean): { x: number; y: number } => {
    const times = withShift ? 2 : 1
    const delta = { x: 0, y: 0 }
    if (keyStatus.KeyW) {
      delta.y = -KEY_OFFSET * times
    }
    if (keyStatus.KeyA) {
      delta.x = -KEY_OFFSET * times
    }
    if (keyStatus.KeyS) {
      delta.y = KEY_OFFSET * times
    }
    if (keyStatus.KeyD) {
      delta.x = KEY_OFFSET * times
    }

    return delta
  }

  const performScroll = (event: KeyboardEvent): void => {
    if (context.editor.activeView.isLoading) {
      return
    }

    const delta = computePanDeltaFromKey(event.shiftKey)
    context.editor.activeView.camera.scroll(delta)

    event.preventDefault()
  }

  type PanEvent = KeyboardEvent & { code: VALID_KEYCODES }

  const isValidPanEvent = (event: KeyboardEvent): event is PanEvent => {
    if (isModifierPressed(event)) {
      return false
    }
    if (event.metaKey || event.ctrlKey || event.altKey) {
      return false
    }
    if (!['KeyW', 'KeyA', 'KeyS', 'KeyD'].includes(event.code)) {
      return false
    }
    return true
  }

  context.handles.push(
    ...context.editor.onKeyDown((event) => {
      if (!isValidPanEvent(event)) {
        return
      }

      const { code } = event
      keyStatus[code] = true

      // Should override opposite behavior to avoid racing conditions
      if (code === 'KeyW') {
        keyStatus.KeyS = false
      }
      if (code === 'KeyS') {
        keyStatus.KeyW = false
      }
      if (code === 'KeyA') {
        keyStatus.KeyD = false
      }
      if (code === 'KeyD') {
        keyStatus.KeyA = false
      }

      performScroll(event)
    }),
  )

  context.handles.push(
    ...context.editor.onKeyPress((event) => {
      if (!isValidPanEvent(event)) {
        return
      }

      performScroll(event)
    }),
  )

  context.handles.push(
    ...context.editor.onKeyUp((event) => {
      if (!isValidPanEvent(event)) {
        return
      }

      const { code } = event
      keyStatus[code] = false
    }),
  )
}

/**
 * Sets up panning by holding the mouse wheel pressed
 *
 * This type of panning is used when an annotation type tool is selected, to allow for
 * panning while the mouse click, touch and cursor keys are taken by other actions.
 */
const setupMiddleButtonPanning = (context: ToolContext): void => {
  const panningContext: PanningContext = {
    panCursorStart: null,
    panOriginalOffset: null,
  }

  context.handles.push(
    ...context.editor.onMouseDown((event) => {
      // we do not initiate unless the middle mouse button is pressed
      if (!isMiddleMouseButton(event)) {
        return CallbackStatus.Continue
      }
      return enterMousePanning(event, panningContext, context)
    }),
  )

  context.handles.push(
    ...context.editor.onMouseMove((event) => doMousePanning(event, panningContext, context)),
  )

  context.handles.push(
    ...context.editor.onMouseUp((event) => exitMousePanning(event, panningContext, context)),
  )
}

const setupPanning = {
  primary: setupPrimaryButtonPanning,
  middle: setupMiddleButtonPanning,
  key: setupKeyPanning,
  touch: setupTouchPanning,
}

export { setupPanning }
