import type { PartialRecord } from '@/core/helperTypes'
import {
  isImageAnnotationDataPayload,
  isVideoAnnotationDataPayload,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import type { VideoRaster } from '@/modules/Editor/models/raster/VideoRaster'
import type { VoxelRaster } from '@/modules/Editor/models/raster/VoxelRaster'
import { assertImageRaster } from '@/modules/Editor/models/raster/assertImageRaster'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'
import { assertVoxelRaster } from '@/modules/Editor/models/raster/assertVoxelRaster'
import { createRaster } from '@/modules/Editor/models/raster/createRaster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import type { View } from '@/modules/Editor/views/view'
import { ViewTypes } from '@/modules/Editor/views/viewTypes'
import type { V2AnnotationPayload } from '@/store/types/StageAnnotationPayload'
import mapValues from 'lodash/mapValues'
import { deserializeImageRaster } from '@/modules/Editor/models/raster/deserializeImageRaster'
import { deserializeVideoOrVoxelRaster } from '@/modules/Editor/models/raster/deserializeVideoOrVoxelRaster'

export function createRasterFromDeserializable(
  view: View,
  rasterAnnotationPayload: V2AnnotationPayload,
  maskAnnotations: PartialRecord<string, V2AnnotationPayload>,
): void {
  if (!Object.keys(maskAnnotations).length) {
    // Masks are deleted but raster annotation still currently exists.
    // This is an edge case that can happen whilst the BE is still going through and
    // Seeing whether the raster layer should be deleted, which can happen when you have
    // Many keyframes.
    return
  }

  // Check if raster already exists
  const existingRaster = view.rasterManager.getRasterForFileInView()

  if (existingRaster) {
    // Already been processed during this client session, ignore.
    return
  }

  const raster = createRaster(view)
  const annotationIdToClassIdMapping = mapValues(maskAnnotations, (annotation) => {
    if (!annotation) {
      throw new Error('Annotation not found')
    }
    return annotation.annotation_class_id
  })

  view.rasterManager.addRaster(raster)

  if (view.type === ViewTypes.IMAGE) {
    const imageDataPayload = rasterAnnotationPayload.data

    if (!isImageAnnotationDataPayload(imageDataPayload)) {
      throw new Error('Is not image data payload')
    }

    deserializeImageRaster(
      assertImageRaster(raster),
      imageDataPayload.raster_layer,
      annotationIdToClassIdMapping,
    )
  } else if (
    view.type === ViewTypes.VIDEO ||
    view.type === ViewTypes.STREAM ||
    view.type === ViewTypes.DICOM ||
    view.type === ViewTypes.LEGACY_DICOM
  ) {
    const videoDataPayload = rasterAnnotationPayload.data

    if (!isVideoAnnotationDataPayload(videoDataPayload)) {
      throw new Error('Is not video data payload')
    }

    let videoOrVoxelRaster: VideoRaster | VoxelRaster

    if (raster.type === RasterTypes.VIDEO) {
      videoOrVoxelRaster = assertVideoRaster(raster)
    } else if (raster.type === RasterTypes.VOXEL) {
      videoOrVoxelRaster = assertVoxelRaster(raster)
    } else {
      throw new Error('unsupported raster type for view type')
    }

    // When recreating video raster from the API payload, we need to make sure we have ID in sync.
    videoOrVoxelRaster.idSyncedWithApi = rasterAnnotationPayload.id

    deserializeVideoOrVoxelRaster(
      videoOrVoxelRaster,
      videoDataPayload.frames,
      annotationIdToClassIdMapping,
    )
  }
}
