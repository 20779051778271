import type { components } from '@/backend/darwin/api'

import type { DatasetItemStatus } from './DatasetItemStatus'
import type { DatasetUploadItemPayload } from './DatasetUploadItemPayload'
import type { V2DatasetItemLayout } from './V2DatasetItemLayout'
import type { V2DatasetItemSlot } from './V2DatasetItemSlot'

export type V2DatasetItemWorkflowData =
  components['schemas']['DatasetsV2.ItemManagement.Item']['workflow_data']

export type V2DatasetItemPayload = Omit<
  components['schemas']['DatasetsV2.ItemManagement.Item'],
  'slots'
> & {
  // the backend definition of a slot defines its `'metadata'` field as `Record<string, unknown>`
  // the frontend definition provides more details, so we replace it here
  slots: V2DatasetItemSlot[]
  name: string
  path: string
  layout: V2DatasetItemLayout
  priority: number
  processing_status: DatasetItemStatus
  status: DatasetItemStatus
  workflow_status: Exclude<
    DatasetItemStatus,
    | typeof DatasetItemStatus.new
    | typeof DatasetItemStatus.processing
    | typeof DatasetItemStatus.uploading
  >
  uploads: DatasetUploadItemPayload[]
  workflow_data?: V2DatasetItemWorkflowData

  tags?: number[]

  inserted_at: string
  updated_at: string
}

export type V2DatasetItemWithChannelsPayload = V2DatasetItemPayload & {
  layout: components['schemas']['DatasetsV2.Common.ItemLayoutV3']
}

export const isLayoutV3ItemPayload = (
  payload: V2DatasetItemPayload,
): payload is V2DatasetItemWithChannelsPayload => payload.layout && payload.layout.version === 3
