import { calcCentroid } from '@/modules/Editor/annotationCentroid'
import type { CommentVertices } from '@/modules/Editor/comment'
import { COMMENT_THREAD_BOUNDING_BOX_COLOR } from '@/modules/Editor/comment'
import type { EditablePoint } from '@/modules/Editor/point'
import { createEditablePoint } from '@/modules/Editor/point'
import { drawComment } from '@/modules/Editor/graphics'
import type { CommentThread } from '@/modules/Editor/iproviders/types'
import { getThreadVertices, threadHasConflicts } from '@/modules/Editor/managers/CommentManager'
import type { ILayer } from '@/modules/Editor/models/layers/types'
import type { View } from '@/modules/Editor/views/view'

const ICON_WIDTH = 25
const ICON_HEIGHT = 25

const getPath = (thread: CommentVertices): EditablePoint[] => [
  thread.topLeft,
  thread.topRight,
  thread.bottomRight,
  thread.bottomLeft,
]

export const renderCommentThread = (
  view: View,
  layer: ILayer<CanvasRenderingContext2D, HTMLCanvasElement>,
  thread: CommentThread,
): void => {
  const isSelected = view.commentManager.selectedItem?.id === thread.id
  const isHighlighted = view.commentManager.selectedItem?.id === thread.id

  const isEditable =
    thread.author_id === view.editor.getCurrentUserId() && !threadHasConflicts(thread)

  const shouldRender = isHighlighted || isSelected
  if (!shouldRender) {
    return
  }

  const path = getPath(getThreadVertices(thread))
  const color = COMMENT_THREAD_BOUNDING_BOX_COLOR

  if (!layer.context) {
    return
  }

  drawComment(path, layer.context, view.camera, color, isSelected && isEditable)
}

export const getCentroidRectPath = (thread: CommentThread): EditablePoint[] => {
  let vertices = []
  const res = getThreadVertices(thread)
  vertices = [res.topLeft, res.topRight, res.bottomRight, res.bottomLeft]

  const centroid = calcCentroid(vertices)
  const cTopLeft = createEditablePoint({
    x: centroid.x - ICON_WIDTH / 2,
    y: centroid.y - ICON_HEIGHT / 2,
  })
  const cTopRight = createEditablePoint({
    x: centroid.x + ICON_WIDTH / 2,
    y: centroid.y - ICON_HEIGHT / 2,
  })
  const cBottomRight = createEditablePoint({
    x: centroid.x + ICON_WIDTH / 2,
    y: centroid.y + ICON_HEIGHT / 2,
  })
  const cBottomLeft = createEditablePoint({
    x: centroid.x - ICON_WIDTH / 2,
    y: centroid.y + ICON_HEIGHT / 2,
  })

  return [cTopLeft, cTopRight, cBottomRight, cBottomLeft]
}
