import type { Eye } from '@/modules/Editor/AnnotationData'
import type { CompoundPath } from '@/modules/Editor/compoundPath'
import { addPointMutate, type EditablePoint, type IPoint } from '@/modules/Editor/point'

export const isEye = (annotationData: Eye | object): annotationData is Eye =>
  'nodes' in annotationData &&
  annotationData.nodes.every((node: { name: string }) =>
    ['inner', 'outer', 'upper', 'lower'].includes(node.name),
  )

const getPathFromEye = (data: Eye | object): EditablePoint[] =>
  isEye(data) ? data.nodes.map((node) => node.point) : []

export const getAllVerticesFromEye = (data: Eye | object): EditablePoint[] => getPathFromEye(data)

export const getCompoundPathFromEye = (data: Eye | object): CompoundPath => ({
  path: getPathFromEye(data),
  additionalPaths: [],
})

export const translateEye = (data: Eye | object, offset: IPoint): void =>
  getPathFromEye(data).forEach((point) => addPointMutate(point, offset))

export const moveVertexOnEye = (movingVertex: EditablePoint, offset: IPoint): void =>
  addPointMutate(movingVertex, offset)
