import type { IPoint } from '@/modules/Editor/point'
import { addPoints, divScalar } from '@/modules/Editor/point'

export const calcMiddleSegmentMiddlePoint = (path: IPoint[]): IPoint | undefined => {
  if (path.length < 2) {
    return path[0]
  }

  const i = Math.floor(path.length / 2) - 1
  return divScalar(addPoints(path[i], path[i + 1]), 2)
}
