import type {
  DatasetDetailPayload,
  DatasetExportPayload,
  DatasetItemCountsPayload,
  DatasetPayload,
  V2DatasetItemPayload,
} from '@/store/types'
import type { Dataset } from '@/store/types/Dataset'
import type { DatasetItemFilenamePayload } from '@/store/types/DatasetItemFilenamePayload'

export type DatasetState = {
  currentDataset: Dataset
  datasetDetails: (DatasetDetailPayload | (DatasetItemCountsPayload & { id: number }))[]
  datasetExports: DatasetExportPayload[]
  datasetItemFilenames: DatasetItemFilenamePayload[]
  datasetItemsV2: { [id: string]: V2DatasetItemPayload }
  datasetItemIdsV2: V2DatasetItemPayload['id'][]
  datasets: DatasetPayload[]
  dataTabColumnCount: number
  /**
   * If `selectedAll: false`, this field holds ids of all items which are
   * currently individually selected. Any action which acts on selected items
   * will affect only items with these ids.
   *
   * If `selectedAll: false`, this field is ignored
   */
  selectedV2ItemIds: string[]
  /**
   * Indicates if current item selection mode is "all"
   *
   * When this mode is enabled, we assume any action related to seletion, deals
   * with all currently filtered items in the dataset and the `selectedItemIds`
   * field is effectively ignored.
   */
  selectedAll: boolean
  exportCompleteCount: number
}

export const getInitialState = (): DatasetState => ({
  currentDataset: { id: null },
  datasetDetails: [],
  datasetExports: [],
  datasetItemFilenames: [],
  datasetItemsV2: {},
  datasetItemIdsV2: [],
  datasets: [],
  dataTabColumnCount: 2,
  selectedV2ItemIds: [],
  selectedAll: false,
  exportCompleteCount: 0,
})

// initial state
export const state: DatasetState = getInitialState()
