import type { TeamPayload, InvitationPayload, MembershipPayload } from '@/store/types'

export type TeamState = {
  /**
   * @deprecated use useTeamStore instead
   */
  currentTeam: TeamPayload | null
  invitations: InvitationPayload[]
  memberships: MembershipPayload[]
  teams: TeamPayload[]
}

export const getInitialState = (): TeamState => ({
  currentTeam: null,
  invitations: [],
  memberships: [],
  teams: [],
})

const state: TeamState = getInitialState()

export default state
