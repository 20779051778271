import type { ToolManager } from '@/modules/Editor/managers/toolManager'

export const BrushMode = {
  Brush: 'brush',
  Eraser: 'eraser',
} as const
export type BrushMode = (typeof BrushMode)[keyof typeof BrushMode]

const isBrushMode = (mode: string): mode is BrushMode =>
  Object.values(BrushMode).includes(mode as BrushMode)

/**
 * Brush mode:
 * - brush: merge drawn polygons together in a single compound path
 * - eraser: subtract drawn polygon from the existing compound path
 */
export const getBrushMode = (toolManager: ToolManager): BrushMode => {
  const { currentTool: tool } = toolManager
  if (!tool || tool.name !== 'brush_tool') {
    return BrushMode.Brush
  }

  const activeToolOption = (tool.toolConfig.toolOptions || [])
    .filter((option) => option.category === 'brush-mode')
    .find((option) => option.active)

  if (!activeToolOption) {
    return BrushMode.Brush
  }

  if (!isBrushMode(activeToolOption.id)) {
    return BrushMode.Brush
  }

  return activeToolOption.id
}
