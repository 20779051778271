import { EventEmitter } from 'events'

import type { AnnotationType } from '@/core/annotationTypes'
import { MainAnnotationType, SubAnnotationType } from '@/core/annotationTypes'
import { getMainAnnotationType } from '@/core/annotationTypes'
import type { CallbackHandle } from '@/modules/Editor/callbackHandler'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import type { ViewEvent } from '@/modules/Editor/eventBus'
import { ToolManagerEvents } from '@/modules/Editor/eventBus'
import { ViewEvents } from '@/modules/Editor/eventBus'
import { TOOL_FOR_MAIN_TYPE } from '@/modules/Editor/tools/consts'
import { SubToolName, ToolName, isToolName } from '@/modules/Editor/tools/types'
import type { ToolInfo, ToolOptionId } from '@/modules/Editor/tools/types'
import type { Editor } from '@/modules/Editor/editor'
import { keybindingMatch } from '@/modules/Editor/keybinding'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { boundingBoxTool } from '@/modules/Editor/plugins/boundingBox/tool'
import { brushTool } from '@/modules/Editor/plugins/brush/tool'
import { clickerTool } from '@/modules/Editor/plugins/click/ClickerTool'
import { emptyTool } from '@/modules/Editor/plugins/click/emptyTool'
import { clickerSubTool } from '@/modules/Editor/plugins/click/subTool'
import { commentatorTool } from '@/modules/Editor/plugins/commentator/tool'
import { crosshairsTool } from '@/modules/Editor/plugins/crosshairsTool/tool'
import { directionalVectorTool } from '@/modules/Editor/plugins/directionalVector/tool'
import { editTool } from '@/modules/Editor/plugins/edit/tool'
import { ellipseTool } from '@/modules/Editor/plugins/ellipse/tool'
import { eyeTool } from '@/modules/Editor/plugins/eye/tool'
import { keypointTool } from '@/modules/Editor/plugins/keypoint/tool'
import { polygonTool } from '@/modules/Editor/plugins/polygon/tool'
import { polylineTool } from '@/modules/Editor/plugins/polyline/tool'
import { selectTool } from '@/modules/Editor/plugins/select/selectTool'
import { simpleTableTool } from '@/modules/Editor/plugins/simple_table/tool'
import { skeletonTool } from '@/modules/Editor/plugins/skeleton/tool'
import { windowLevelTool } from '@/modules/Editor/plugins/windowLevel/windowLevelTool'
import { zoomTool } from '@/modules/Editor/plugins/zoom/tool'
import type { View } from '@/modules/Editor/views/view'

import {
  autoAnnotateToolConfig,
  boundingBoxToolConfig,
  brushToolConfig,
  clickerToolConfig,
  commentatorToolConfig,
  crosshairsToolConfig,
  directionalVectorToolConfig,
  editToolConfig,
  ellipseToolConfig,
  emptyToolConfig,
  eyeToolConfig,
  keypointToolConfig,
  polygonToolConfig,
  polylineToolConfig,
  selectToolConfig,
  simpleTableToolConfig,
  skeletonToolConfig,
  windowLevelToolConfig,
  zoomToolConfig,
} from './toolConfigs'
import type { ToolConfig, SubToolConfig } from './toolConfigs'

// Available tool state to share
export type SharedToolState = {
  isPanning?: boolean
}

export interface ToolContext {
  editor: Editor
  handles: CallbackHandle[]
}

export interface Tool {
  activate(context: ToolContext): void

  deactivate(context: ToolContext): void

  reset(context: ToolContext): void

  draw?(view: View, context?: ToolContext): void
}

export interface SubAnnotationTool extends Tool {
  masterAnnotation: Annotation | null

  selectMasterAnnotation(context: ToolContext, annotation: Annotation): void
}

export interface SubAnnotationToolPayload {
  master: Annotation
}

export interface ToolEntry {
  tool: Tool
  toolConfig: ToolConfig | SubToolConfig
  name: ToolName | SubToolName
  context: ToolContext
  active: boolean
}

export enum Events {
  TOOL_ACTIVATED = 'tool:activated',
}

const TOOL_ANNOTATION_TYPES: Record<ToolName, AnnotationType[]> = {
  [ToolName.BoundingBox]: [MainAnnotationType.BoundingBox],
  [ToolName.Brush]: [MainAnnotationType.Polygon, MainAnnotationType.Mask],
  [ToolName.Clicker]: [MainAnnotationType.Polygon],
  [ToolName.Commentator]: [],
  [ToolName.Crosshairs]: [],
  [ToolName.Edit]: [],
  [ToolName.Ellipse]: [MainAnnotationType.Ellipse],
  [ToolName.Eye]: [MainAnnotationType.Eye],
  [ToolName.Keypoint]: [MainAnnotationType.Keypoint],
  [ToolName.Polygon]: [MainAnnotationType.Polygon, MainAnnotationType.Mask],
  [ToolName.Polyline]: [MainAnnotationType.Polyline],
  [ToolName.SAM]: [],
  [ToolName.Select]: [],
  [ToolName.SimpleTable]: [MainAnnotationType.SimpleTable],
  [ToolName.Skeleton]: [MainAnnotationType.Skeleton],
  [ToolName.WindowLevel]: [],
  [ToolName.Zoom]: [],
}

const SUB_TOOL_ANNOTATION_TYPES: Record<SubToolName, AnnotationType[]> = {
  [SubToolName.Attributes]: [],
  [SubToolName.AutoAnnotate]: [
    MainAnnotationType.Polygon,
    MainAnnotationType.Mask,
    SubAnnotationType.AutoAnnotate,
  ],
  [SubToolName.DirectionalVector]: [SubAnnotationType.DirectionalVector],
  [SubToolName.Empty]: [
    MainAnnotationType.Polygon,
    MainAnnotationType.Mask,
    SubAnnotationType.AutoAnnotate,
  ],
  [SubToolName.InstanceID]: [SubAnnotationType.InstanceId],
  [SubToolName.Text]: [SubAnnotationType.Text],
}

export class ToolManager extends EventEmitter {
  private toolEntries: ToolEntry[] = []
  private previousToolEntry?: ToolEntry
  private editor: Editor

  private availableTools: ToolInfo[] = []

  constructor(editor: Editor) {
    super()

    this.editor = editor

    this.registerTool(ToolName.BoundingBox, boundingBoxTool, boundingBoxToolConfig)
    this.registerTool(ToolName.Brush, brushTool, brushToolConfig)
    this.registerTool(ToolName.Clicker, clickerTool, clickerToolConfig)
    this.registerTool(ToolName.Commentator, commentatorTool, commentatorToolConfig)
    this.registerTool(ToolName.Crosshairs, crosshairsTool, crosshairsToolConfig)
    this.registerTool(ToolName.Edit, editTool, editToolConfig)
    this.registerTool(ToolName.Ellipse, ellipseTool, ellipseToolConfig)
    this.registerTool(ToolName.Eye, eyeTool, eyeToolConfig)
    this.registerTool(ToolName.Keypoint, keypointTool, keypointToolConfig)
    this.registerTool(ToolName.Polygon, polygonTool, polygonToolConfig)
    this.registerTool(ToolName.Polyline, polylineTool, polylineToolConfig)
    this.registerTool(ToolName.Select, selectTool, selectToolConfig)
    this.registerTool(ToolName.SimpleTable, simpleTableTool, simpleTableToolConfig)
    this.registerTool(ToolName.Skeleton, skeletonTool, skeletonToolConfig)
    this.registerTool(ToolName.WindowLevel, windowLevelTool, windowLevelToolConfig)
    this.registerTool(ToolName.Zoom, zoomTool, zoomToolConfig)

    this.registerTool(SubToolName.AutoAnnotate, clickerSubTool, autoAnnotateToolConfig)
    this.registerTool(
      SubToolName.DirectionalVector,
      directionalVectorTool,
      directionalVectorToolConfig,
    )
    this.registerTool(SubToolName.Empty, emptyTool, emptyToolConfig)

    // Listeners
    ViewEvents.currentFrameIndexChanged.on(this.handleFrameChanged)
  }

  /**
   * Track the frame change to disable the annotation editing
   * on jump outside the annotations segment.
   */
  private handleFrameChanged = (
    e: ViewEvent,
    { newIndex, oldIndex }: { newIndex: number; oldIndex?: number },
  ): void => {
    if (e.viewId !== this.editor.activeView.id) {
      return
    }

    ToolManagerEvents.frameChanged.emit(e, { newIndex, oldIndex })
  }

  /**
   * The current tool selected, null if no tool is currently select
   */
  public get currentTool(): ToolEntry | null {
    for (const entry of this.toolEntries) {
      if (entry.active) {
        return entry
      }
    }
    return null
  }

  /**
   * Array of annotation type names supported by current tool,
   * empty array when no tool is currently selected
   */
  public currentAnnotationTypes(): AnnotationType[] {
    const { currentTool, editor } = this
    const { selectedAnnotation } = editor.activeView.annotationManager
    if (currentTool) {
      // If the current tool is edit tool, return the current annotation's annotation types
      if (currentTool.name === ToolName.Edit && selectedAnnotation?.annotationClass) {
        const type = getMainAnnotationType(selectedAnnotation.annotationClass.annotation_types)
        if (!type) {
          throw new Error('Tool manager encountered a class with no main annotation type')
        }
        return [type]
      }

      return isToolName(currentTool.name)
        ? TOOL_ANNOTATION_TYPES[currentTool.name]
        : SUB_TOOL_ANNOTATION_TYPES[currentTool.name]
    }
    return []
  }

  setAvailableTools(availableToolNames: (ToolName | SubToolName)[]): void {
    const mainTools = this.toolEntries.filter((tool) =>
      availableToolNames.some((n) => n === tool.name),
    )

    this.availableTools = mainTools.map((entry) => {
      const { active, name } = entry
      return { active, name }
    })

    // because editor cleans up all commands, including .activate commands when things outside
    // change, here, we have to re-register all .activate commands, for hotkeys to work

    this.availableTools.forEach((entry) => {
      this.editor.registerCommand(`${entry.name}.activate`, () =>
        this.activateToolWithStore(entry.name),
      )
    })
  }

  public isToolAvailable(name: ToolName | SubToolName): boolean {
    return !!this.availableTools.find((t) => t.name === name)
  }

  /**
   * Get tool by name
   * @param name name of the tool
   */
  public findByName(name: ToolName | SubToolName): ToolEntry | null {
    return this.toolEntries.find((entry) => entry.name === name) || null
  }

  /**
   * Get tool by annotation type name
   * @param typeName annotation type name
   */
  public findByMainAnnotationTypeName(typeName: MainAnnotationType): ToolEntry | null {
    if (typeName === MainAnnotationType.Mask) {
      // The mask annotation type can be related to the polygon_tool or
      // the brush tool. If one is selected, choose that otherwise fall back
      // to the polygon_tool.
      const toolsAvailableForMask: (ToolName | SubToolName)[] = [ToolName.Polygon, ToolName.Brush]
      const currentToolName = this.currentTool?.name

      if (currentToolName && toolsAvailableForMask.includes(currentToolName)) {
        return this.findByName(currentToolName)
      }

      return this.findByName(ToolName.Polygon)
    }

    const toolName = TOOL_FOR_MAIN_TYPE[typeName]
    return toolName && this.findByName(toolName)
  }

  /**
   * Registers a new tool for a given name.
   * Registering a tool makes it visible in availableTools and activateTool
   *
   * @param name the name of the tool, needs to be unique
   * @param tool implementation of the tool
   */

  private registerTool(
    name: ToolName | SubToolName,
    tool: Tool,
    toolConfig: ToolConfig | SubToolConfig,
  ): void {
    if (toolConfig.name !== name) {
      console.warn(`Registering tool with name '${name}' but config name '${toolConfig.name}'`)
      return
    }

    const { editor } = this

    const context = { editor, handles: [] }
    this.toolEntries.push({ active: false, context, name, tool, toolConfig })
  }

  public deactivateToolEntry(entry: ToolEntry): void {
    if (!entry.active) {
      return
    }
    entry.tool.deactivate(entry.context)
    entry.active = false
    for (const handle of entry.context.handles) {
      handle.release()
    }
  }

  public deactivateTool(name: ToolName | SubToolName): void {
    const entry = this.getToolEntry(name)
    if (!entry) {
      return
    }
    this.deactivateToolEntry(entry)
  }

  public activateToolWithStore(
    name: ToolName | SubToolName,
    payload?: { sub: SubAnnotationToolPayload },
  ): void {
    if (this.currentTool?.name === name) {
      // tool is already active, so this is a no-op
      return
    }

    this.activateTool(name, payload)

    // what is the guarantee that this is actually correct?
    // presumably, we would only want to keep annotation selected for brush tool
    // if the selected annotation is of polygon type
    if (name !== ToolName.Brush) {
      this.editor.activeView.annotationManager.deselectAllAnnotations()
    }
  }

  /**
   * Activates a tool by calling it's activate function.
   * If another tool is already active, it will be deactivated.
   * If the tool is already active, nothing happens.
   *
   * @param name tool to active
   */
  public activateTool(
    name: ToolName | SubToolName,
    payload: { sub: SubAnnotationToolPayload } | undefined = undefined,
  ): void {
    const entry = this.getToolEntry(name)
    if (!entry) {
      return
    }

    // if the tool is not available, it cannot be activated
    if (!this.availableTools.some((t) => t.name === name)) {
      return
    }

    this.toolEntries.forEach((entry) => {
      if (entry.name !== name && entry.active) {
        this.previousToolEntry = entry
        this.deactivateToolEntry(entry)
        // Reset the cursor from the previous tool.
        selectCursor(EditorCursor.Default)
      }
    })

    if (entry) {
      if (!entry.active) {
        // Important to have this line before tool.activate
        // tool.activate can trigger autoActivateTool method that might reset the current tool
        // NOTE: It is a wrong approach that we know about and want to rewrite
        // here is the ticket for this ANN-909
        entry.active = true
        entry.tool.activate(entry.context)
      }

      if (payload && payload.sub) {
        const subAnnotationTool = entry.tool as SubAnnotationTool
        if (subAnnotationTool) {
          subAnnotationTool.selectMasterAnnotation(entry.context, payload.sub.master)
        }
      }

      this.emit(Events.TOOL_ACTIVATED, name)
    } else {
      console.warn(`Tool with name '${name}' is not registered`)
    }
  }

  private getToolEntry(name: ToolName | SubToolName): ToolEntry | null {
    for (const entry of this.toolEntries) {
      if (entry.name === name) {
        return entry
      }
    }
    return null
  }

  public handleKeybindings(event: KeyboardEvent): void {
    for (const entry of this.toolEntries) {
      for (const keybinding of entry.toolConfig.keybindings) {
        if (keybinding.when) {
          // only send the call if the tool is active.
          if (keybinding.when === 'active' && !entry.active) {
            continue
          }
        }
        if (keybindingMatch(event, keybinding.keys)) {
          if (typeof keybinding.action === 'string') {
            this.editor.callCommand(keybinding.action, event)
          } else {
            for (const action of keybinding.action) {
              this.editor.callCommand(action, event)
            }
          }
          event.preventDefault()
        }
      }
    }
  }

  public activatePreviousToolEntry(): void {
    if (this.previousToolEntry) {
      this.activateToolWithStore(this.previousToolEntry.name)
      this.previousToolEntry = undefined
      return
    }

    // When it is no previouse tool we need to deactivate the current tool at least.
    this.activateToolWithStore(ToolName.Edit)
  }

  public getToolNameForType(type: MainAnnotationType): ToolName | SubToolName | null {
    const tools: ToolInfo[] = this.availableTools.filter((t) =>
      isToolName(t.name)
        ? TOOL_ANNOTATION_TYPES[t.name].includes(type)
        : SUB_TOOL_ANNOTATION_TYPES[t.name].includes(type),
    )

    if (tools.length === 0) {
      return null
    }
    // This way we are prioritizing picking the current tool if possible
    const currentTool = this.currentTool
    const tool = tools.find((t) => currentTool && t.name === currentTool.name) || tools[0]

    return tool.name
  }

  /**
   * Activate a ToolOption by ID.
   * If that ToolOption belongs to any category,
   * deactivate all the other ToolOptions in that category.
   *
   * @param toolOptionId the ID of the ToolOption to be activated
   */
  public activateToolOption(toolOptionId: ToolOptionId): void {
    const { currentTool: tool } = this
    if (!tool) {
      return
    }

    const optionByName = (tool.toolConfig.toolOptions || []).find(
      (option) => option.id === toolOptionId,
    )
    if (!optionByName) {
      return
    }

    const { category } = optionByName
    if (category) {
      ;(tool.toolConfig.toolOptions || [])
        .filter((toolOption) => toolOption.category === category)
        .forEach((toolOption) => {
          toolOption.active = false
        })
    }

    optionByName.active = true
  }

  public deactivateToolOption(toolOptionId: ToolOptionId): void {
    const { currentTool: tool } = this
    if (!tool) {
      return
    }

    const optionByName = (tool.toolConfig.toolOptions || []).find(
      (option) => option.id === toolOptionId,
    )
    if (!optionByName) {
      return
    }

    optionByName.active = false
  }

  public deactivateToolOptions(): void {
    const { currentTool: tool } = this
    if (!tool) {
      return
    }
    if (!tool.toolConfig.toolOptions) {
      return
    }

    tool.toolConfig.toolOptions.forEach((toolOption) => {
      toolOption.active = false
    })
  }

  public setToolOptionProps(toolOptionId: string, props: object): void {
    const { currentTool: tool } = this
    if (!tool) {
      return
    }

    const toolOption = (tool.toolConfig.toolOptions || []).find(
      (option) => option.id === toolOptionId,
    )

    if (!toolOption) {
      return
    }

    toolOption.props = props
  }

  cleanup(): void {
    this.availableTools = []
    ViewEvents.currentFrameIndexChanged.off(this.handleFrameChanged)
  }
}
