import type { FrameData } from '@/core/annotations'
import type { BBox } from '@/modules/Editor/types'

import { calcPathBBox } from './calcPathBBox'

export const calcEyeBBox = (data: FrameData): BBox | undefined => {
  if (!data.eye) {
    return
  }

  return calcPathBBox(data.eye.nodes)
}
