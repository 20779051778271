import type { MainAnnotationType } from '@/core/annotationTypes'
import type { FrameData } from '@/core/annotations'
import type { PartialRecord } from '@/core/helperTypes'
import type { BBox } from '@/modules/Editor/types'

import { calcBoundingBoxBBox } from './calcBoundingBoxBBox'
import { calcEllipseBBox } from './calcEllipseBBox'
import { calcEyeBBox } from './calcEyeBBox'
import { calcKeypointBBox } from './calcKeypointBBox'
import { calcLineBBox } from './calcLineBBox'
import { calcPolygonBBox } from './calcPolygonBBox'
import { calcSimpleTableBBox } from './calcSimpleTableBBox'
import { calcSkeletonBBox } from './calcSkeletonBBox'

const typeBBoxFunctionMap: PartialRecord<
  MainAnnotationType,
  (data: FrameData) => BBox | undefined
> = {
  bounding_box: calcBoundingBoxBBox,
  ellipse: calcEllipseBBox,
  keypoint: calcKeypointBBox,
  line: calcLineBBox,
  mask: () => undefined,
  polygon: calcPolygonBBox,
  raster_layer: () => undefined,
  simple_table: calcSimpleTableBBox,
  eye: calcEyeBBox,
  skeleton: calcSkeletonBBox,
  tag: () => undefined,
}

/**
 * Calculates the bounding box for the FrameData by its type.
 */
export const calcBBoxByType = (type: MainAnnotationType, data: FrameData): BBox | undefined =>
  typeBBoxFunctionMap[type]?.(data)
