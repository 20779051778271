import type { Skeleton } from '@/modules/Editor/AnnotationData'
import { addPoints, createEditablePoint, mulScalar, subPoints } from '@/modules/Editor/point'

import type { LinearInterpolationParams } from './types'

/**
 * NOTE:
 * The skeleton interpolation process drops the edges right now because
 * they are missing in several places in the calling code.
 * The caller is expected to add them in manually from another source.
 */
export const skeletonInterpolation = (
  params: LinearInterpolationParams,
  prevData: Skeleton,
  nextData: Skeleton,
): Skeleton => {
  const { algorithm, interpolationFactor } = params

  if (algorithm && !algorithm.startsWith('linear')) {
    throw new Error(`Interpolate: skeletons don't support '${algorithm}' interpolation algorithm`)
  }

  return {
    nodes: prevData.nodes.map((prevNode) => {
      const nextNode = nextData.nodes.find((node) => node.name === prevNode.name)
      if (!nextNode) {
        throw new Error("skeletonInterpolation was given invalid skeleton. Couldn't find next node")
      }

      return {
        name: prevNode.name,
        occluded: prevNode.occluded,
        point: createEditablePoint(
          addPoints(
            prevNode.point,
            mulScalar(subPoints(nextNode.point, prevNode.point), interpolationFactor),
          ),
        ),
      }
    }),
  }
}
