import { WindAuthAction } from '@/backend/darwin/WindAuthAction'
import type { ApiResult } from '@/backend/darwin/types'
import { errorMessages } from '@/backend/error'

import { withAuth } from './api'
import type { ExternalModelResponsePayload } from './types'

export const loadExternalModels = (
  teamId: number,
): Promise<ApiResult<ExternalModelResponsePayload[]>> =>
  withAuth<ExternalModelResponsePayload[]>(
    { action: WindAuthAction.ViewModels, teamId },
    (client) => client.get<ExternalModelResponsePayload[]>('external_models'),
    errorMessages.LOAD_EXTERNAL_MODELS,
  )
