import { watch } from 'vue'

import { useClasses } from './useClasses'
import { useTeamStore } from '@/pinia/useTeamStore'

export const useClassLoader = (): void => {
  const teamStore = useTeamStore()
  const classStore = useClasses()

  watch(
    () => teamStore.currentTeam,
    (): void => {
      if (!teamStore.currentTeam) {
        return
      }
      classStore.loadClasses(teamStore.currentTeam.slug)
    },
    { immediate: true },
  )
}
