import { get } from '@/backend/api'

export const loadFramesManifests = async (url: string): Promise<string | null> => {
  const response = await fetch(url).catch(() => {
    console.error('Failed to load frames manifests')
    return null
  })

  if (!response) {
    return null
  }

  return response.text()
}

export const getSignedUrl = async (url: string): Promise<string> => (await get<string>(url)).data
