import type { Camera } from '@/modules/Editor/camera'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import type { EditablePoint } from '@/modules/Editor/point'
import { strokeStyle } from '@/modules/Editor/graphics'
import type { DrawCallback } from '@/modules/Editor/models/layers/types'
// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'

import { fillStyle } from './fillStyle'

export function drawPointV2(
  drawFn: DrawCallback,
  camera: Camera,
  point: EditablePoint,
  color: RGBA,
  filter: ImageManipulationFilter | null,
  isHighlighted = false,
  isSelected = false,
): Path2D {
  const path2D = new Path2D()
  drawFn((ctx) => {
    const canvasPoint = point
    const pointSize = isHighlighted || isSelected ? 5.5 : 3.5
    ctx.lineWidth = 1 / camera.scale
    ctx.strokeStyle = strokeStyle(color, filter, false, isSelected)
    ctx.fillStyle = fillStyle(color, filter, false, isHighlighted, isSelected)
    ctx.beginPath()
    path2D.arc(canvasPoint.x, canvasPoint.y, pointSize / camera.scale, 0, 2 * Math.PI)
    ctx.fill(path2D)
    ctx.stroke(path2D)
  })
  return path2D
}

/**
 * @deprecated
 */
export function drawPoint(
  camera: Camera,
  ctx: CanvasRenderingContext2D,
  point: EditablePoint,
  color: RGBA,
  filter: ImageManipulationFilter | null,
  isHighlighted = false,
  isSelected = false,
): void {
  const canvasPoint = camera.imageViewToCanvasView(point)
  const pointSize = isHighlighted || isSelected ? 5.5 : 3.5
  ctx.strokeStyle = strokeStyle(color, filter, false, isSelected)
  ctx.fillStyle = fillStyle(color, filter, false, isHighlighted, isSelected)
  ctx.beginPath()
  ctx.arc(canvasPoint.x, canvasPoint.y, pointSize, 0, 2 * Math.PI)
  ctx.fill()
  ctx.stroke()
  ctx.closePath()
}
