import type { EyeData } from '@/core/annotations'
import type { IPoint } from '@/modules/Editor/point'
import type { RenderingContext2D } from '@/modules/Editor/types'
// eslint-disable-next-line boundaries/element-types
import { rgbaString } from '@/uiKit/colorPalette'

import { OCCLUDED_COLOR } from './drawVerticesByType'

type DrawEyeProps = {
  strokeColor: string
  lineWidth: number
  nodes: EyeData['nodes']
}

const getControlPoints = (
  inner: IPoint,
  outer: IPoint,
  upper: IPoint,
  lower: IPoint,
): {
  upperControlOuter: IPoint
  upperControlInner: IPoint
  lowerControlOuter: IPoint
  lowerControlInner: IPoint
} => {
  const distanceX = inner.x - outer.x
  const distanceY = inner.y - outer.y
  const upperControlOuter = { x: upper.x - distanceX * 0.25, y: upper.y - distanceY * 0.25 }
  const upperControlInner = { x: upper.x + distanceX * 0.25, y: upper.y + distanceY * 0.25 }
  const lowerControlOuter = { x: lower.x - distanceX * 0.25, y: lower.y - distanceY * 0.25 }
  const lowerControlInner = { x: lower.x + distanceX * 0.25, y: lower.y + distanceY * 0.25 }

  return { upperControlOuter, upperControlInner, lowerControlOuter, lowerControlInner }
}

export const drawEye = (
  ctx: RenderingContext2D,
  { strokeColor, lineWidth, nodes }: DrawEyeProps,
): Path2D => {
  const inner = nodes.find((node) => node.name === 'inner')
  const outer = nodes.find((node) => node.name === 'outer')
  const upper = nodes.find((node) => node.name === 'upper')
  const lower = nodes.find((node) => node.name === 'lower')

  if (!inner || !outer || !upper || !lower) {
    throw new Error('attempting to draw eye annotation without all necessary nodes')
  }

  const { upperControlOuter, upperControlInner, lowerControlOuter, lowerControlInner } =
    getControlPoints(inner, outer, upper, lower)

  ctx.strokeStyle = strokeColor
  ctx.lineWidth = lineWidth

  const path = new Path2D()

  const pathOuterUpper = new Path2D()
  pathOuterUpper.moveTo(outer.x, outer.y)
  pathOuterUpper.quadraticCurveTo(upperControlOuter.x, upperControlOuter.y, upper.x, upper.y)
  ctx.strokeStyle = outer.occluded || upper.occluded ? rgbaString(OCCLUDED_COLOR) : strokeColor
  ctx.stroke(pathOuterUpper)
  path.addPath(pathOuterUpper)

  const pathUpperInner = new Path2D()
  pathUpperInner.moveTo(upper.x, upper.y)
  pathUpperInner.quadraticCurveTo(upperControlInner.x, upperControlInner.y, inner.x, inner.y)
  ctx.strokeStyle = upper.occluded || inner.occluded ? rgbaString(OCCLUDED_COLOR) : strokeColor
  ctx.stroke(pathUpperInner)
  path.addPath(pathUpperInner)

  const pathInnerLower = new Path2D()
  pathInnerLower.moveTo(inner.x, inner.y)
  pathInnerLower.quadraticCurveTo(lowerControlInner.x, lowerControlInner.y, lower.x, lower.y)
  ctx.strokeStyle = inner.occluded || lower.occluded ? rgbaString(OCCLUDED_COLOR) : strokeColor
  ctx.stroke(pathInnerLower)
  path.addPath(pathInnerLower)

  const pathLowerOuter = new Path2D()
  pathLowerOuter.moveTo(lower.x, lower.y)
  pathLowerOuter.quadraticCurveTo(lowerControlOuter.x, lowerControlOuter.y, outer.x, outer.y)
  ctx.strokeStyle = lower.occluded || outer.occluded ? rgbaString(OCCLUDED_COLOR) : strokeColor
  ctx.stroke(pathLowerOuter)
  path.addPath(pathLowerOuter)

  return path
}
