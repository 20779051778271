import type { Camera } from '@/modules/Editor/camera'
import { DEFAULT_LINE_WIDTH, DEFAULT_VERTEX_SIZE } from '@/modules/Editor/config'
import { calcLineWidth } from '@/modules/Editor/graphicsV2/calcLineWidth'
import { drawByType } from '@/modules/Editor/graphicsV2/drawByType'
import { drawLabelByType } from '@/modules/Editor/graphicsV2/drawLabelByType'
import { drawVerticesByType } from '@/modules/Editor/graphicsV2/drawVerticesByType'
import { fillStyle } from '@/modules/Editor/graphicsV2/fillStyle'
import { strokeStyle } from '@/modules/Editor/graphicsV2/strokeStyle'
import type {
  RenderableItem,
  RenderableItemWithState,
  VertexState,
} from '@/modules/Editor/models/layers/object2D'
import type { CanvasFillRule } from '@/modules/Editor/models/layers/types'

import { CanvasAndContext } from './canvasAndContext'
import { DEFAULT_BORDER_OPACITY, DEFAULT_OPACITY } from './configs'

export class ActiveCanvas extends CanvasAndContext {
  private cameraScale: number = 1

  private _drawCanvas: HTMLCanvasElement | null
  private _drawContext: CanvasRenderingContext2D | null

  /**
   * Used to simplify tracking the item under the cursor
   */
  private _renderedItemsMap: Map<string, Path2D> = new Map()

  /**
   * List of activated vertices connected to the active items.
   */
  public activeVertices: Map<RenderableItem['id'], Map<number, VertexState>> = new Map()

  /**
   * Used to simplify tracking the items vertices under the cursor
   */
  private _renderedItemsVerticesMap: Map<string, Map<number, Path2D>> = new Map()

  constructor() {
    super()

    if (this.canvas) {
      this.canvas.style.pointerEvents = 'none'
      this.canvas.classList.add('active-canvas')
    }

    this._drawCanvas = document.createElement('canvas')
    this._drawContext = this._drawCanvas.getContext('2d')
  }

  public setCameraScale(scale: number): void {
    this.cameraScale = scale
  }

  public get drawCanvas(): HTMLCanvasElement | null {
    return this._drawCanvas
  }

  public get drawContext(): CanvasRenderingContext2D | null {
    return this._drawContext
  }

  public setCanvasSize(w: number, h: number): void {
    if (!this.canvas) {
      return
    }
    if (!this.drawCanvas) {
      return
    }
    this.canvas.width = w
    this.canvas.height = h
    this.drawCanvas.width = w
    this.drawCanvas.height = h
  }

  public render(
    activeItems: Set<string>,
    camera: Camera,
    getItem: (key: string) => RenderableItemWithState | undefined,
  ): Map<string, Path2D> {
    if (!this.drawCanvas) {
      return new Map()
    }
    if (!this.context) {
      return new Map()
    }

    const itemPath2DMap = new Map()

    this.clearCanvas()
    if (!activeItems.size) {
      this.insertCanvas(this.drawCanvas)
      return itemPath2DMap
    }

    this.context.save()
    this.context.scale(this.cameraScale, this.cameraScale)
    this.context.translate(-camera.offset.x / this.cameraScale, -camera.offset.y / this.cameraScale)

    // Renders only active items.
    for (const itemId of activeItems.values()) {
      const item = getItem(itemId)

      if (!item) {
        continue
      }

      // Draw the annotations shape
      const path = drawByType(this.context, item.type, item.data, {
        fillColor: fillStyle(
          item.color,
          DEFAULT_OPACITY,
          false,
          !!item.state.isSelected,
          !!item.state.isHighlighted,
        ),
        strokeColor: strokeStyle(
          item.color,
          DEFAULT_BORDER_OPACITY,
          false,
          !!item.state.isSelected,
        ),
        isSelected: !!item.state.isSelected,
        isHighlighted: !!item.state.isHighlighted,
        lineWidth: calcLineWidth(DEFAULT_LINE_WIDTH, this.cameraScale),
        pointSize: DEFAULT_VERTEX_SIZE / this.cameraScale,
        scale: this.cameraScale,
      })
      // Draw the annotations vertices
      const drawVerticesRes = drawVerticesByType(
        this.context,
        item.type,
        item.data,
        {
          fillColor: fillStyle(item.color, DEFAULT_OPACITY, false, false, false),
          strokeColor: strokeStyle(item.color, DEFAULT_BORDER_OPACITY, false, false),
          isSelected: !!item.state.isSelected,
          isHighlighted: !!item.state.isHighlighted,
          lineWidth: calcLineWidth(DEFAULT_LINE_WIDTH, this.cameraScale),
          pointSize: DEFAULT_VERTEX_SIZE / this.cameraScale,
        },
        this.activeVertices.get(itemId),
      )
      // Draw the annotations labels
      // NOTE: right now it is skeleton labels only
      drawLabelByType(
        this.context,
        item.type,
        item.data,
        this.cameraScale,
        this.activeVertices.get(itemId),
      )

      if (drawVerticesRes?.verticesMap) {
        this._renderedItemsVerticesMap.set(item.id, drawVerticesRes.verticesMap)
      }

      if (path) {
        if (drawVerticesRes?.compoundPath) {
          path.addPath(drawVerticesRes.compoundPath)
        }

        itemPath2DMap.set(item.id, path)
      }
    }

    this.context.restore()

    this.insertCanvas(this.drawCanvas)

    this._renderedItemsMap = itemPath2DMap

    return itemPath2DMap
  }

  public clearDrawCanvas(x = 0, y = 0, w = this.canvas?.width, h = this.canvas?.height): void {
    if (!this.drawContext) {
      return
    }
    if (!w || !h) {
      return
    }
    this.drawContext.clearRect(x, y, w, h)
  }

  /**
   * Set the active state (highlighted or selected) for the vertex of the item.
   * For performance purposes, we manage the active state of the vertex separate from the vertices.
   * This approach keeps vertices immutable.
   * @param itemId
   * @param index
   * @param state
   * @returns
   */
  public activateVertexWithState(
    itemId: RenderableItem['id'],
    index: number,
    state: VertexState,
  ): void {
    let activeVertices = this.activeVertices.get(itemId)
    if (!activeVertices) {
      activeVertices = new Map()
      this.activeVertices.set(itemId, activeVertices)
    }

    for (const item of activeVertices.values()) {
      item.isHighlighted = false
    }
    if (state.isSelected) {
      for (const item of activeVertices.values()) {
        item.isSelected = false
      }
    }

    const vertex = activeVertices.get(index)
    if (vertex) {
      if (state.isHighlighted !== undefined) {
        vertex.isHighlighted = state.isHighlighted
      }
      if (state.isSelected !== undefined) {
        vertex.isSelected = state.isSelected
      }
      return
    }

    activeVertices.set(index, state)
  }

  /**
   * Resets the isHighlighted state for all vertices of all items.
   */
  public unhighlightAllVertices(): void {
    for (const activeVertices of this.activeVertices.values()) {
      for (const item of activeVertices.values()) {
        item.isHighlighted = false
      }
    }
  }

  /**
   * Retrieves the selected vertex from the active canvas's active vertices.
   * @returns {Object | null} An object containing the selected vertex's item ID and index,
   *                          or `null` if no selected vertex is found.
   */
  public getSelectedVertex(): {
    itemId: RenderableItem['id']
    index: number
  } | null {
    for (const [key, value] of this.activeVertices) {
      for (const [index, vertex] of value) {
        if (vertex.isSelected) {
          return { itemId: key, index }
        }
      }
    }
    return null
  }

  /**
   * Deactivates vertex's active state of all or specified vertex.
   * @param itemId?
   * @param index?
   * @returns
   */
  public deactivateVertex(itemId?: RenderableItem['id'], index?: number): void {
    if (!itemId) {
      this.activeVertices.clear()
      return
    }

    const activeVertices = this.activeVertices.get(itemId)
    if (!activeVertices) {
      return
    }

    if (index !== undefined) {
      activeVertices.delete(index)
      return
    }

    activeVertices.clear()
  }

  /**
   * Returns true if the point hits the vertex's area.
   * @param id
   * @param x
   * @param y
   * @returns
   */
  public hitItemsVertex(
    id: RenderableItemWithState['id'],
    x: number,
    y: number,
  ): number | undefined {
    if (!this.context) {
      return
    }

    const vertices = this._renderedItemsVerticesMap.get(id)

    if (!vertices) {
      return
    }

    for (const [index, vertexPath2D] of vertices.entries()) {
      if (this.context.isPointInPath(vertexPath2D, x, y)) {
        return index
      }
    }
  }

  /**
   * Returns true if the point hits the stroke of the rendered items shape.
   * @param id
   * @param x
   * @param y
   * @returns
   */
  public isPointInStroke(id: RenderableItemWithState['id'], x: number, y: number): boolean {
    if (!this.context) {
      return false
    }

    const itemPath2D = this._renderedItemsMap.get(id)
    let res: boolean = false

    this.context.save()
    this.context.lineWidth = 10 / this.cameraScale

    if (itemPath2D) {
      res = this.context.isPointInStroke(itemPath2D, x, y)
    }

    this.context.restore()

    return res
  }

  /**
   * Returns true if the point hits the area of the rendered items shape.
   * @param id
   * @param x
   * @param y
   * @param fillRule
   * @returns
   */
  public isPointInPath(
    id: RenderableItemWithState['id'],
    x: number,
    y: number,
    fillRule?: CanvasFillRule,
  ): boolean {
    if (!this.context) {
      return false
    }

    const itemPath2D = this._renderedItemsMap.get(id)
    if (!itemPath2D) {
      return false
    }
    return this.context.isPointInPath(itemPath2D, x, y, fillRule)
  }

  public clear(): void {
    this._renderedItemsMap.clear()
    this.activeVertices.clear()
    this._renderedItemsVerticesMap.clear()
  }

  public destroy(): void {
    super.destroy()
    this.clear()
    this._drawCanvas?.remove()
    this._drawCanvas = null
    this._drawContext = null
  }
}
