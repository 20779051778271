import type { PropType } from 'vue'
import type { RawLocation } from 'vue-router'

import type { DarwinKindType, DarwinFlairType, DarwinSizeType } from '@/uiKit/types'
import { kindValidator, flairValidator, sizeValidator } from '@/uiKit/validators'

export interface ButtonProps {
  flair: DarwinFlairType
  kind: DarwinKindType
  size: DarwinSizeType
  disabled?: boolean
  to?: RawLocation
  type?: HTMLButtonElement['type']
}

export const buttonProps = {
  flair: {
    required: true,
    type: String as PropType<DarwinFlairType>,
    validator: flairValidator,
  },
  kind: {
    required: true,
    type: String as PropType<DarwinKindType>,
    validator: kindValidator,
  },
  size: {
    required: true,
    type: String as PropType<DarwinSizeType>,
    validator: sizeValidator,
  },
  name: { default: undefined, type: String },
  disabled: { default: false, type: Boolean },
  to: { default: undefined, type: [String, Object] as PropType<RawLocation> },
  type: { default: 'button', type: String as PropType<HTMLButtonElement['type']> },
}
