import type { AnnotationType } from '@/core/annotationTypes'
import type { IPoint, EditablePoint } from '@/modules/Editor/point'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'

export const DISTANCE_THRESHOLD = 0.5
export const POLYGON_ANNOTATION_TYPE: AnnotationType = 'polygon'

export type AddingPointContext = {
  pointOnLine: IPoint
  pointOnLineAnnotation: Annotation
  pointOnLinePath: EditablePoint[]
  pointOnLinePosition: number
}
