import { imageFilterToString } from './imageFilterToString'
import type { Tile } from './models/tiler'
import { isRenderableImage } from './models/tiler'
import type { View } from './views/view'

export const renderTiledImage = (
  view: View,
  tiles: Tile[],
  ctx: CanvasRenderingContext2D,
): void => {
  ctx.imageSmoothingEnabled = !!view.imageFilter.isImageSmoothing
  ctx.filter = imageFilterToString(view.imageFilter)
  for (const tile of tiles) {
    const tileImage = tile.image()
    if (!isRenderableImage(tileImage)) {
      continue
    }
    ctx.drawImage(tileImage.data, tile.cx, tile.cy, tile.w, tile.h)
  }
  ctx.filter = 'none'
  ctx.imageSmoothingEnabled = true
}
