import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import { createEditablePoint } from '@/modules/Editor/point'
import type { IPoint } from '@/modules/Editor/point'
import { drawPointV2 } from '@/modules/Editor/graphics'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { DrawCallback } from '@/modules/Editor/models/layers/types'
import type { View } from '@/modules/Editor/views/view'

export const renderKeypoint = (
  drawFn: DrawCallback,
  view: View,
  annotation: Annotation,
  filter: ImageManipulationFilter | null,
): Path2D | void => {
  const isSelected = view.annotationManager.isSelected(annotation.id)
  const isHighlighted = view.annotationManager.isHighlighted(annotation.id)
  const color = annotation.color
  const point = createEditablePoint(annotation.data as IPoint)
  return drawPointV2(drawFn, view.camera, point, color, filter, isHighlighted, isSelected)
}
