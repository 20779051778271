import type { FrameData } from '@/core/annotations'
import type { AnnotationData } from '@/modules/Editor/AnnotationData'

export const tagSerializer = {
  serialize(data: AnnotationData): FrameData {
    return { tag: data.tag }
  },

  deserialize(rawData: FrameData): AnnotationData | null {
    if (!rawData.tag) {
      return null
    }
    return { tag: rawData.tag }
  },
}
