/* globals CanvasImageSource */
import isEqual from 'lodash/isEqual'

import type { ColorMap, WindowLevels } from '@/modules/Editor/imageManipulation'
import type { ExtendedFileMetadata, FileMetadata } from '@/modules/Editor/metadata'
import { getNumPixelChannels, getWindowLevelsRange } from '@/modules/Editor/utils/windowLevels'
import { C_MAPS } from '@/modules/Editor/consts'
import { loadImageData } from '@/modules/Editor/utils'
import type { RuntimeCacher } from '@/modules/Editor/utils/runtimeCacher'
// eslint-disable-next-line boundaries/element-types
import type { RenderableImage } from '@/store/modules/workview/types'

export const windowFunction = (v: number, low: number, high: number): number => {
  if (v <= low) {
    return 0
  }
  if (v >= high) {
    return 255
  }
  return Math.floor(((v - low) / (high - low)) * 255.0)
}

const handleRGB = (
  element: HTMLImageElement,
  data: Uint8ClampedArray,
  width: number,
  height: number,
  windowLevels: WindowLevels | null,
): CanvasImageSource | null => {
  const windowLevelsRange = getWindowLevelsRange('RGB')
  const windowLow = windowLevels ? windowLevels[0] : windowLevelsRange[0]
  const windowHigh = windowLevels ? windowLevels[1] : windowLevelsRange[1]

  // If the window is the full 0-255 range, just return the raw data
  if (windowLow === windowLevelsRange[0] && windowHigh === windowLevelsRange[1]) {
    return element
  }

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return null
  }

  const buf = new ArrayBuffer(width * height * 4)
  const buf8 = new Uint8ClampedArray(buf)
  for (let i = 0; i < width * height; i++) {
    buf8[i * 4 + 0] = windowFunction(data[i * 4 + 0], windowLow, windowHigh)
    buf8[i * 4 + 1] = windowFunction(data[i * 4 + 1], windowLow, windowHigh)
    buf8[i * 4 + 2] = windowFunction(data[i * 4 + 2], windowLow, windowHigh)
    buf8[i * 4 + 3] = 255
  }
  // we need to first put this into a canvas of the same size as the
  // image before we can draw it on the main canvas (resized)
  const imageData = ctx.createImageData(width, height)
  imageData.data.set(buf8)
  ctx.putImageData(imageData, 0, 0)

  return canvas
}

const getTransformedImageData = (
  element: HTMLImageElement,
  data: Uint8ClampedArray,
  width: number,
  height: number,
  windowLevels: WindowLevels | null,
  colorMap: ColorMap = 'default',
  videoMetadata: FileMetadata | null = null,
): CanvasImageSource | null => {
  // Normal images or videos without color mapping
  if ((!videoMetadata || videoMetadata.type !== 'dicom') && colorMap === 'default') {
    return handleRGB(element, data, width, height, windowLevels)
  }

  // DICOMs in RGB space without color mapping
  if (videoMetadata && videoMetadata.colorspace === 'RGB' && colorMap === 'default') {
    return handleRGB(element, data, width, height, windowLevels)
  }

  const windowLevelsRange = getWindowLevelsRange(videoMetadata?.colorspace)

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return null
  }

  const area = width * height
  const nimg = new Uint16Array(area)
  if (
    videoMetadata === null ||
    videoMetadata.colorspace === 'RGB' ||
    videoMetadata.colorspace === undefined
  ) {
    if (colorMap !== 'default') {
      // convert to grey scale
      for (let i = 0; i < area; i++) {
        nimg[i] = data[i * 4] * 0.3 + data[i * 4 + 1] * 0.59 + data[i * 4 + 2] * 0.11
      }
    }
  } else if (videoMetadata.colorspace === 'RG16') {
    for (let i = 0; i < area; i++) {
      nimg[i] = (data[i * 4] << 8) | data[i * 4 + 1]
    }
  } else {
    throw new Error(`Unknown colorspace ${videoMetadata.colorspace}`)
  }

  const windowLow = windowLevels ? windowLevels[0] : windowLevelsRange[0]
  const windowHigh = windowLevels ? windowLevels[1] : windowLevelsRange[1]
  const cmap = C_MAPS[colorMap]

  const buf = new ArrayBuffer(width * height * 4)
  const buf8 = new Uint8ClampedArray(buf)
  const bufData = new Uint32Array(buf)
  let max = 0
  let min = Infinity
  for (let y = 0; y < height; ++y) {
    for (let x = 0; x < width; ++x) {
      const v = nimg[y * width + x]
      const sv = windowFunction(v, windowLow, windowHigh)
      if (sv > max) {
        max = sv
      }
      if (sv < min) {
        min = sv
      }
      // use the window-rescaled input pixel value
      // shifting the same value into r, g and b
      bufData[y * width + x] = cmap[sv]
    }
  }
  // we need to first put this into a canvas of the same size as the
  // image before we can draw it on the main canvas (resized)
  const imageData = ctx.createImageData(width, height)
  imageData.data.set(buf8)
  ctx.putImageData(imageData, 0, 0)

  return canvas
}

export const resolveRawImageData = (
  rawData: ImageData | null,
  data: HTMLImageElement,
): ImageData | null => rawData || loadImageData(data)

/**
 * Resolves new renderable image data from the current data and new render params
 *
 * Returns null if data is unchanged, or
 */
export const resolveTransformedImageData = (
  lastWindowLevels: WindowLevels | null,
  windowLevels: WindowLevels | null,
  rawData: ImageData,
  imageElement: HTMLImageElement,
  hasChanged: boolean = false,
): CanvasImageSource | null => {
  const hasWindowLevelsChanged = !isEqual(lastWindowLevels, windowLevels)

  if (hasWindowLevelsChanged || hasChanged) {
    return getTransformedImageData(
      imageElement,
      rawData.data,
      rawData.width,
      rawData.height,
      windowLevels,
    )
  }

  return null
}

export const resolveDicomTransformedImageData = (
  image: RenderableImage,
  windowLevels: WindowLevels | null,
  colorMap: ColorMap = 'default',
  videoMetadata: FileMetadata | null = null,
): CanvasImageSource | null => {
  const hasWindowLevelsChanged = !isEqual(image.lastWindowLevels, windowLevels)

  // For normal images/videos,
  // we don't need to transform image data with default window levels and color map.
  // For dicom video, we need to always transform image data because
  // it is not what we can draw directly without transformation.
  // Also, if none of window level and color map has changed,
  // we don't need to re-calculate the transformed image.
  if (hasWindowLevelsChanged || !image.transformedData) {
    image.lastWindowLevels = windowLevels
    image.lastColorMap = colorMap
    image.rawData = resolveRawImageData(image.rawData, image.data)

    if (!image.rawData) {
      image.transformedData = null
      return null
    }
    image.transformedData = getTransformedImageData(
      image.data,
      image.rawData.data,
      image.rawData.width,
      image.rawData.height,
      windowLevels,
      colorMap,
      videoMetadata,
    )
  }

  return image.transformedData || image.data
}

/**
 * Returns the scale and offset to apply to the pixels of the PNG to get the pixel values in
 * Hounsfield unit. The formula is:
 * pixelIntensityInHounsfieldUnit = pixelIntensityFromPng * scale + offset
 *
 * This function uses the followings from medicalMetadata:
 * smallestImagePixelValue, largestImagePixelValue, rescaleSlope, rescaleIntercept
 * @param fileMetadata metadata of a FileManager
 * @returns scale and offset in an object
 */
export const getMedicalPixelTransformsToHounsfield = (
  fileMetadata?: ExtendedFileMetadata | null,
): { scale: number; offset: number } => {
  // Compute scale and offset to convert from pixel to scaled
  // Inspired by pngPixelValueToScaledPixelValue
  // Don't use it directly to avoid recomputing scale and offset for each voxel

  // Pixel to raw
  // raw = pixel * pixelToRawScale + pixelToRawOffset
  const numPixelChannels = getNumPixelChannels(fileMetadata?.colorspace)
  const medicalMetadata = fileMetadata?.medical
  const smallestImagePixelValue = Number(medicalMetadata?.smallestImagePixelValue ?? 0)
  const largestImagePixelValue = Number(medicalMetadata?.largestImagePixelValue ?? 1)
  const pixelToRawScale = (largestImagePixelValue - smallestImagePixelValue) / numPixelChannels
  const pixelToRawOffset = smallestImagePixelValue

  // Raw to scaled
  // scaled = raw * rawToScaledScale + rawToScaledOffset
  const rawToScaledScale = Number(medicalMetadata?.rescaleSlope ?? 1)
  const rawToScaledOffset = Number(medicalMetadata?.rescaleIntercept ?? 0)

  // Combination of pixelToRaw + rawToScaled
  // Pixel to scaled:
  // scaled = pixel * pixelToScaledScale + pixelToScaledOffset
  const pixelToScaledScale = pixelToRawScale * rawToScaledScale
  const pixelToScaledOffset = pixelToRawOffset * rawToScaledScale + rawToScaledOffset

  return { scale: pixelToScaledScale, offset: pixelToScaledOffset }
}

/**
 * Resolve the DICOM frames buffer for 3D rendering
 * @param frameCacheHQ runtime cacher for the frame
 * @param windowLevels window level from the manipulator
 * @param frameArea size (width x height) of the frame in pixels
 * @param numberOfFrames total amount of frames (buffer depth)
 */
export const resolveDicomFramesBufferFor3D = (
  frameCacheHQ: RuntimeCacher<RenderableImage>,
  frameArea: number,
  numberOfFrames: number,
  fileMetadata?: ExtendedFileMetadata | null,
): Int16Array => {
  const numberOfVoxels = frameArea * numberOfFrames
  const outputBuffer = new Int16Array(numberOfVoxels)

  const { scale, offset } = getMedicalPixelTransformsToHounsfield(fileMetadata)

  // Process each frame
  for (let frameIndex = 0; frameIndex < numberOfFrames; frameIndex++) {
    const frame = frameCacheHQ.getItem(frameIndex.toString())
    if (!frame) {
      continue
    }

    const rawData = resolveRawImageData(frame.rawData, frame.data)
    if (!rawData) {
      continue
    }

    // Array of size 4 * frameArea (RGBA)
    // Suppose fileMetadata?.colorspace is RG16:
    // For each pixel, R and G hold the data, B and A are unused
    const rawDataArray = rawData.data
    const voxelFrameOffset = frameIndex * frameArea
    for (let voxelInFrameIndex = 0; voxelInFrameIndex < frameArea; ++voxelInFrameIndex) {
      const pixelFromPng =
        (rawDataArray[4 * voxelInFrameIndex] << 8) | rawDataArray[4 * voxelInFrameIndex + 1]

      const pixelInHounsfield = pixelFromPng * scale + offset
      outputBuffer[voxelFrameOffset + voxelInFrameIndex] = pixelInHounsfield
    }
  }

  return outputBuffer
}

/**
 * Given any number of images data, it blends them together and returns the final image data.
 * PLEASE NOTE: this is not used yet, it will be when multi-channel support is implemented.
 */
export const resolveBlendedImageData = (
  imagesData: { image: RenderableImage | undefined; windowLevels: WindowLevels | undefined }[],
): RenderableImage | undefined => {
  if (!imagesData?.length) {
    return undefined
  }

  /**
   * This is always returning the first item, as the support for multiple channels is not implemented yet.
   */
  return imagesData[0].image
}
