import type { IPoint } from '@/modules/Editor/point'

/**
 * Cursors in Workview
 * Class names for every key should be defined in `src/components/WorkviewV2/workview-cursor.scss`
 */
export enum EditorCursor {
  Default = 'default-cur',
  Pointer = 'pointer-cur',
  Draw = 'draw-cur',
  AddPoint = 'add-point-cur',
  RemovePoint = 'remove-point-cur',
  Edit = 'edit-cur',
  Ellipse = 'ellipse-cur',
  DefaultMove = 'default-move-cur',
  Move = 'move-cur',
  Moving = 'moving-cur',
  Magic = 'magic-cur',
  MagicAddPoint = 'magic-add-point-cur',
  MagicRemovePoint = 'magic-remove-point-cur',
  MagicDeleteClick = 'magic-delete-click-cur',
  BBox = 'bbox-cur',
  Commentator = 'commentator-cur',
  ZoomIn = 'zoom-in-cur',
  ZoomOut = 'zoom-out-cur',
  Hidden = 'hidden-cur',
  NWResize = 'nw-resize-cur',
  NEResize = 'ne-resize-cur',
  SEResize = 'se-resize-cur',
  SWResize = 'sw-resize-cur',
}

const clearCursor = (el: Element): void => {
  Object.values(EditorCursor).forEach((value: string) => {
    el.classList.remove(value)
  })
}

export const selectCursor = (
  cursorClass: EditorCursor,
  text?: string,
  canvasPoint?: IPoint,
): void => {
  // TODO: Ideally should be something better then get element by ...
  const el = document.getElementById('workview-canvas')
  if (!el) {
    return
  }

  // It's important to check the classList here.
  // Without it, the cursor will be removed and re-added on every
  // mousemove, which is a hefty performance cost.
  if (!el.classList.contains(cursorClass)) {
    clearCursor(el)
    el.classList.add(cursorClass)
  }

  const child = el.getElementsByClassName('workview__cursor-helper')[0]
  if (!canvasPoint && child) {
    el.removeChild(child)
    return
  }

  if (text && canvasPoint) {
    const child = el.getElementsByClassName('workview__cursor-helper').item(0) as HTMLDivElement
    if (child) {
      child.textContent = text
      child.style.left = `${canvasPoint.x + 15}px`
      child.style.top = `${canvasPoint.y - 15}px`
      return
    }

    const otherDiv = document.createElement('div')
    otherDiv.classList.add('workview__cursor-helper')
    otherDiv.textContent = text
    otherDiv.style.position = 'absolute'
    otherDiv.style.background = 'rgba(0, 61, 184, 0.7)'
    otherDiv.style.color = 'white'
    otherDiv.style.padding = '3px 5px'
    otherDiv.style.zIndex = '1000'
    otherDiv.style.fontSize = '10px'
    otherDiv.style.borderRadius = '3px'
    otherDiv.style.pointerEvents = 'none'
    otherDiv.style.left = `${canvasPoint.x + 10}px`
    otherDiv.style.top = `${canvasPoint.y - 20}px`
    el.prepend(otherDiv)
  }
}
