import type { Range3D } from '@/modules/Editor/types'
import { Plane } from '@/modules/Editor/utils/raster/Plane'
import type { View } from '@/modules/Editor/views/view'
import { getRasterPlaneMapForAcquisitionPlane } from '@/modules/Editor/utils/raster/getRasterPlaneMapForAcquisitionPlane'

import type { InvalidatedRasterRegion } from './Raster'
import type { RasterBufferAccessor } from './RasterBufferAccessor'
import { VideoRaster, Events } from './VideoRaster'
import { RasterTypes } from './rasterTypes'

export type VoxelRasterDimensions = { width: number; height: number; depth: number }

/**
 * This is an extension of the VideoRaster where instead of having seperate buffers per frame,
 * we instead keep a buffer reference per frame of a 3D volume. This buffer references one
 * global blob of memory which can easily be reformatted/later displayed using volume rendering.
 */
export class VoxelRaster extends VideoRaster {
  public readonly type: RasterTypes = RasterTypes.VOXEL
  /**
   * The flattened 3D voxel buffer.
   */
  public voxelRasterBuffer: Uint8Array

  public planeXInvalidationRegion: InvalidatedRasterRegion
  public planeYInvalidationRegion: InvalidatedRasterRegion

  public planeXCachedCanvas?: HTMLCanvasElement
  public planeYCachedCanvas?: HTMLCanvasElement

  public readonly depth

  constructor(view: View) {
    super(view)

    this.depth = view.totalFrames
    this.voxelRasterBuffer = new Uint8Array(this.size * this.depth)

    this.planeXInvalidationRegion = {
      xMin: 0,
      xMax: this.height,
      yMin: 0,
      yMax: this.depth,
    }
    this.planeYInvalidationRegion = {
      xMin: 0,
      xMax: this.width,
      yMin: 0,
      yMax: this.depth,
    }

    this.planeXCachedCanvas = this.createCanvas(this.height, this.depth)
    this.planeYCachedCanvas = this.createCanvas(this.width, this.depth)
  }

  public getActiveBufferForRender(): RasterBufferAccessor | undefined {
    const { currentFrameIndex } = this.view
    const { width, height } = this

    const currentFrameBuffer = this.frameBuffers[currentFrameIndex]

    if (!currentFrameBuffer) {
      // For voxel rasters we don't show previews of previous frames,
      // as this is not video and does not make sense (and would look strange compared
      // to other views)
      return
    }

    return {
      get(x: number, y: number): number {
        return currentFrameBuffer[y * width + x]
      },
      set(x: number, y: number, val: number): void {
        currentFrameBuffer[y * width + x] = val
      },
      width,
      height,
      plane: Plane.Z,
    }
  }

  public getBufferForEdit(frameIndex: number): RasterBufferAccessor {
    const rasterBufferAccessor = super.getBufferForEdit(frameIndex)

    // Just add the plane view
    rasterBufferAccessor.plane = Plane.Z

    return rasterBufferAccessor
  }

  public getActiveBufferForEdit(): RasterBufferAccessor {
    const rasterBufferAccessor = super.getActiveBufferForEdit()

    // Just add the plane view
    rasterBufferAccessor.plane = Plane.Z

    return rasterBufferAccessor
  }

  /**
   * Always create a fresh buffer for voxel rasters since these aren't videos and
   * we aren't doing nearest neighbour previews.
   */
  public createNewKeyframe(currentFrameIndex: number): Uint8Array {
    const { voxelRasterBuffer } = this

    this.frameBuffers[currentFrameIndex] = new Uint8Array(
      voxelRasterBuffer.buffer,
      currentFrameIndex * this.size,
      this.size,
    )

    // update the raster range so we can draw on the new frame
    this.updateRasterRangeFromBuffer()

    this.emit(Events.VIDEO_RASTER_KEYFRAME_CREATED, {
      rasterId: this.id,
      keyframe: currentFrameIndex,
    })

    // We need to fetch the buffer, as we do a set inside setActiveBufferForFrame,
    // So its actually a memcpy. blankFrameBuffer would be the wrong reference
    const newFrameBuffer = this.frameBuffers[currentFrameIndex]

    if (!newFrameBuffer) {
      throw new Error('failed to create VoxelRaster keyframe.')
    }

    return newFrameBuffer
  }

  public setActiveBufferForFrame(buffer: Uint8Array, frameIndex: number): void {
    const { voxelRasterBuffer } = this

    // Set the buffer into a portion of the 3D buffer.
    voxelRasterBuffer.set(buffer, frameIndex * this.size)

    // Set the frameBuffer to be a view of the 3D buffer.
    this.frameBuffers[frameIndex] = new Uint8Array(
      voxelRasterBuffer.buffer,
      frameIndex * this.size,
      this.size,
    )
  }

  protected removeKeyframe(keyframe: number): void {
    // Clear the region of the 3D buffer covered by the keyframe.
    this.voxelRasterBuffer.fill(0, keyframe * this.size, (keyframe + 1) * this.size)
    delete this.frameBuffers[keyframe]
    delete this.labelsPerKeyframe[keyframe]
  }

  cleanup(): void {
    super.cleanup()
    // This will drop the reference and be garbage collected soon when the manager drops
    // the reference but making a new empty array means we don't need to make this
    // property optional.
    this.voxelRasterBuffer = new Uint8Array(0)
    this.planeXCachedCanvas?.remove()
    this.planeYCachedCanvas?.remove()
    delete this.planeXCachedCanvas
    delete this.planeYCachedCanvas
  }

  public invalidateAll(): void {
    this.invalidate3D({
      minX: 0,
      maxX: this.width - 1,
      minY: 0,
      maxY: this.height - 1,
      minZ: 0,
      maxZ: this.depth - 1,
    })
  }

  public invalidateXPlane(xIndex: number): void {
    this.invalidate3D({
      minX: xIndex,
      maxX: xIndex,
      minY: 0,
      maxY: this.height - 1,
      minZ: 0,
      maxZ: this.depth - 1,
    })
  }

  public invalidateYPlane(yIndex: number): void {
    this.invalidate3D({
      minX: 0,
      maxX: this.width - 1,
      minY: yIndex,
      maxY: yIndex,
      minZ: 0,
      maxZ: this.depth - 1,
    })
  }

  public invalidateZPlane(zIndex: number): void {
    this.invalidate3D({
      minX: 0,
      maxX: this.width - 1,
      minY: 0,
      maxY: this.height - 1,
      minZ: zIndex,
      maxZ: zIndex,
    })
  }

  /**
   * Invalidates a specified 3D range within an image.
   * When a depth is passed, the minZ is adapted to account for the depth in the x and Y planes.
   *
   * @param {Range3D} range3D - The range to invalidate in 3D space.
   * @return {void}
   */
  public invalidate3D(range3D: Range3D): void {
    // Invalidate

    // Clip bounds to image if we somehow get an invalidation region that goes
    // partially offscreen.
    const clampedRange3D = {
      minX: Math.max(0, range3D.minX),
      maxX: Math.min(this.width - 1, range3D.maxX),
      minY: Math.max(0, range3D.minY),
      maxY: Math.min(this.height - 1, range3D.maxY),
      minZ: Math.max(0, range3D.minZ),
      maxZ: Math.max(this.depth - 1, range3D.maxZ),
    }

    this.maybeInvalidateXPlane(clampedRange3D)
    this.maybeInvalidateYPlane(clampedRange3D)
    this.maybeInvalidateZPlane(clampedRange3D)
  }

  private maybeInvalidateZPlane(range3D: Range3D): void {
    const rasterPlaneMap = getRasterPlaneMapForAcquisitionPlane(this.view.fileManager.file.metadata)
    const slotNameForPlaneZ = Object.entries(rasterPlaneMap).find(
      ([, plane]) => plane === Plane.Z,
    )?.[0]

    const viewsList = this.view.editor.viewsList
    const planeZView = viewsList.find(
      (view) => view.fileManager.file.slot_name === slotNameForPlaneZ,
    )

    if (!planeZView) {
      return
    }

    // Check that the currently viewed plane is within the invalidated range.
    const currentFrameIndex = planeZView.currentFrameIndex
    if (currentFrameIndex > range3D.maxZ || currentFrameIndex < range3D.minZ) {
      return
    }

    this._invalidatedRegion = {
      xMin: range3D.minX,
      xMax: range3D.maxX,
      yMin: range3D.minY,
      yMax: range3D.maxY,
    }
    this.invalidated = true

    const rasterLayer = planeZView.rasterAnnotationLayer

    rasterLayer.changed()
    rasterLayer.render()
  }

  private maybeInvalidateXPlane(range3D: Range3D): void {
    const rasterPlaneMap = getRasterPlaneMapForAcquisitionPlane(this.view.fileManager.file.metadata)
    const slotNameForPlaneX = Object.entries(rasterPlaneMap).find(
      ([, plane]) => plane === Plane.X,
    )?.[0]

    const viewsList = this.view.editor.viewsList
    const planeXView = viewsList.find(
      (view) => view.fileManager.file.slot_name === slotNameForPlaneX,
    )

    if (!planeXView) {
      return
    }

    // Check that the currently viewed plane is within the invalidated range.
    const currentFrameIndex = planeXView.currentFrameIndex

    if (currentFrameIndex > range3D.maxX || currentFrameIndex < range3D.minX) {
      return
    }

    this.planeXInvalidationRegion = {
      xMin: range3D.minY,
      xMax: range3D.maxY,
      yMin: range3D.minZ,
      yMax: range3D.maxZ,
    }

    const rasterLayer = planeXView.rasterAnnotationLayer

    rasterLayer.changed()
    rasterLayer.render()
  }

  private maybeInvalidateYPlane(range3D: Range3D): void {
    const rasterPlaneMap = getRasterPlaneMapForAcquisitionPlane(this.view.fileManager.file.metadata)
    const slotNameForPlaneY = Object.entries(rasterPlaneMap).find(
      ([, plane]) => plane === Plane.Y,
    )?.[0]

    const viewsList = this.view.editor.viewsList
    const planeYView = viewsList.find(
      (view) => view.fileManager.file.slot_name === slotNameForPlaneY,
    )

    if (!planeYView) {
      return
    }

    // Check that the currently viewed plane is within the invalidated range.
    const currentFrameIndex = planeYView.currentFrameIndex

    if (currentFrameIndex > range3D.maxY || currentFrameIndex < range3D.minY) {
      return
    }

    this.planeYInvalidationRegion = {
      xMin: range3D.minX,
      xMax: range3D.maxX,
      yMin: range3D.minZ,
      yMax: range3D.maxZ,
    }

    const rasterLayer = planeYView.rasterAnnotationLayer

    rasterLayer.changed()
    rasterLayer.render()
  }

  public getDimensions(): VoxelRasterDimensions {
    return {
      width: this.width,
      height: this.height,
      depth: this.depth,
    }
  }
}
