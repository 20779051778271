import type { MedicalMetadata } from '@/modules/Editor/MedicalMetadata'

/**
 * Resolves the pixel dimensions of the medical image.
 * If the pixel dimensions are not present, it defaults to [1, 1, 1].
 *
 * @param medicalMetadata The metadata of the medical image.
 * @returns The pixel dimensions of the medical image.
 */
export const resolvePixdims = (
  medicalMetadata: MedicalMetadata | undefined,
): [number, number, number] => {
  if (medicalMetadata?.pixdims) {
    return [
      parseFloat(medicalMetadata.pixdims[0]),
      parseFloat(medicalMetadata.pixdims[1]),
      parseFloat(medicalMetadata.pixdims[2]),
    ]
  }

  return [1, 1, 1]
}
