export const ToastEvent = {
  DEFAULT: 'default',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
} as const

export type ToastEvent = (typeof ToastEvent)[keyof typeof ToastEvent]

export type ToastProps = {
  variant: ToastEvent
  meta: { title: string; desc?: string }
}
