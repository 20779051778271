import type { Action } from '@/modules/Editor/managers/actionManager'
import type { EditablePoint, IPoint } from '@/modules/Editor/point'
import { subPoints } from '@/modules/Editor/point'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import type { View } from '@/modules/Editor/views/view'
import { isVideoAnnotation } from '@/modules/Editor/models/annotation/annotationKindValidator'

export const moveVertexAction = (
  view: View,
  originAnnotation: Annotation | undefined,
  annotation: Annotation,
  vertex: EditablePoint,
  startPosition: IPoint,
  endPosition: IPoint,
  move: (annotation: Annotation, vertex: EditablePoint, offset: IPoint, view: View) => void,
): Action => {
  const frameIndex = view.currentFrameIndex
  return {
    do(): boolean {
      move(annotation, vertex, subPoints(endPosition, startPosition), view)
      annotation.centroid = undefined

      let updatedAnnotation: Annotation = shallowCloneAnnotation(annotation)

      if (isVideoAnnotation(annotation)) {
        const { data, keyframe } = inferVideoData(annotation, view.currentFrameIndex)

        if (!keyframe && originAnnotation) {
          updatedAnnotation = shallowCloneAnnotation(annotation, {
            data: {
              ...originAnnotation.data,
              frames: {
                ...originAnnotation.data.frames,
                [frameIndex]: data,
              },
            },
          })
        }

        // If moving a vertex should generate a new keyframe, fill in the new keyframe
        // with inferred data (closest frame, likely the previous)
        if (
          updatedAnnotation.data.frames &&
          !Object.keys(updatedAnnotation.data.frames || {}).includes(`${frameIndex}`)
        ) {
          updatedAnnotation.data.frames[frameIndex] = data
        }
      }

      view.annotationManager.updateAnnotation(updatedAnnotation, {
        updatedFramesIndices: [frameIndex],
      })
      return true
    },
    undo(): boolean {
      move(annotation, vertex, subPoints(startPosition, endPosition), view)
      annotation.centroid = undefined

      view.annotationManager.updateAnnotation(annotation, { updatedFramesIndices: [frameIndex] })
      return true
    },
  }
}
