import { MainAnnotationType, SubAnnotationType } from '@/core/annotationTypes'
import { ToolName } from '@/modules/Editor/tools/types'

import type { Annotation } from './models/annotation/Annotation'
import { isVideoAnnotation } from './models/annotation/annotationKindValidator'
import { getPath2D, setPath2D } from './models/annotation/annotationRenderingCache'
import { shallowCloneAnnotation } from './models/annotation/cloneAnnotation'
import { inferVideoData } from './models/annotation/inferVideoData'
import type { DrawCallback, ILayer } from './models/layers/types'
import { renderBoundingBox } from './plugins/boundingBox/BoundingBoxRenderer'
import { renderDirectionalVector } from './plugins/directionalVector/DirectionalVectorRenderer'
import { renderEllipse } from './plugins/ellipse/EllipseRenderer'
import { renderKeypoint } from './plugins/keypoint/KeypointRenderer'
import { renderPolygon } from './plugins/polygon/polygonRenderer'
import { renderPolyline } from './plugins/polyline/renderer'
import { renderSimpleTable } from './plugins/simple_table/renderer'
import { renderSkeleton } from './plugins/skeleton/SkeletonRenderer'
import type { View } from './views/view'
import { isBoundingBox } from '@/modules/Editor/annotationTypes/boundingBox'
import { isPolygon } from '@/modules/Editor/annotationTypes/polygon'
import { isEllipse } from '@/modules/Editor/annotationTypes/ellipse'
import { isPolyline } from '@/modules/Editor/annotationTypes/polyline'
import { isKeyPoint } from '@/modules/Editor/annotationTypes/keypoint'
import { isSkeleton } from '@/modules/Editor/annotationTypes/skeleton'
import { isSimpleTable } from '@/modules/Editor/annotationTypes/simpleTable'

export const renderAnnotation = (
  drawFn: DrawCallback,
  view: View,
  layer: ILayer<CanvasRenderingContext2D, HTMLCanvasElement>,
  annotation: Annotation,
  parentAnnotation?: Annotation,
  inferred: boolean = false,
): Path2D | void => {
  // When the brush tool is active, this stops us from rendering the overlays
  // of the selected polygon annotation being edited by the tool.
  // It's a hack and will be replaced by a more standard approach.
  if (
    view.toolManager.currentTool?.name === ToolName.Brush &&
    annotation.id === view.annotationManager.selectedAnnotation?.id
  ) {
    view.measureManager.removeOverlayForAnnotation(annotation.id)
    return
  }

  let actualAnnotation = annotation
  if (isVideoAnnotation(annotation)) {
    const { data: annotationData } = inferVideoData(annotation, view.currentFrameIndex)
    if (Object.keys(annotationData).length === 0) {
      view.measureManager.removeOverlayForAnnotation(annotation.id)
      return
    }
    actualAnnotation = shallowCloneAnnotation(annotation, { data: annotationData })
  }

  if (view.editor.renderMeasures && view.measureManager.measureRegion) {
    view.measureManager.updateOverlayForAnnotation(annotation)
  }

  let path2D
  if (annotation.type === MainAnnotationType.BoundingBox && isBoundingBox(actualAnnotation.data)) {
    path2D = renderBoundingBox(drawFn, view, actualAnnotation, inferred, view.imageFilter)
  } else if (annotation.type === MainAnnotationType.Polygon && isPolygon(actualAnnotation.data)) {
    path2D = renderPolygon(view, layer, actualAnnotation, inferred, view.imageFilter)
  } else if (annotation.type === MainAnnotationType.Ellipse && isEllipse(actualAnnotation.data)) {
    path2D = renderEllipse(drawFn, view, actualAnnotation, view.imageFilter)
  } else if (annotation.type === MainAnnotationType.Polyline && isPolyline(actualAnnotation.data)) {
    path2D = renderPolyline(drawFn, view, actualAnnotation, view.imageFilter)
  } else if (annotation.type === MainAnnotationType.Keypoint && isKeyPoint(actualAnnotation.data)) {
    path2D = renderKeypoint(drawFn, view, actualAnnotation, view.imageFilter)
  } else if (annotation.type === MainAnnotationType.Skeleton && isSkeleton(actualAnnotation.data)) {
    path2D = renderSkeleton(drawFn, view, actualAnnotation, view.imageFilter)
  } else if (
    annotation.type === MainAnnotationType.SimpleTable &&
    isSimpleTable(actualAnnotation.data)
  ) {
    path2D = renderSimpleTable(drawFn, view, actualAnnotation, inferred, view.imageFilter)
  } else if (annotation.type === SubAnnotationType.DirectionalVector) {
    if (!parentAnnotation) {
      throw new Error('Tried to render directional vector without providing a parent annotation')
    }
    renderDirectionalVector(drawFn, view, actualAnnotation, view.imageFilter, parentAnnotation)
  } else {
    return
  }

  const actualAnnotationPath2D = getPath2D(actualAnnotation.id)

  if (actualAnnotationPath2D) {
    setPath2D(annotation.id, actualAnnotationPath2D)
  }

  return path2D
}
