import { ref } from 'vue'

import { useToast } from '@/uiKit/Toast/useToast'
import { useTeamStore } from '@/pinia/useTeamStore'
import type { TeamProperty } from '@/store/types/PropertyTypes'
import type {
  DatasetItemPropertiesResponse,
  DatasetItemPropertyActor,
  DatasetItemPropertyId,
  DatasetItemPropertyValues,
} from '@/backend/darwin/teamProperties'
import { getTeamProperties } from '@/backend/darwin/teamProperties'
import { defineStore } from 'pinia'
import { useUserStore } from '@/modules/Auth/useUserStore'

/**
 * Store used to fetch all team properties and keep the loading state in sync.
 * This is also responsible for holding current item selected property values
 */
export const useTeamProperties = defineStore('properties', () => {
  const teamStore = useTeamStore()
  const userStore = useUserStore()
  const toast = useToast()

  const properties = ref<TeamProperty[]>([])
  const loaded = ref(false)

  const setProperties = (payload: TeamProperty[]): void => {
    properties.value = payload
  }

  const fetchProperties = async (teamSlug: string): Promise<void> => {
    loaded.value = false
    const response = await getTeamProperties(teamSlug)

    if ('error' in response) {
      const message =
        typeof response.error.message === 'string' ? response.error.message : 'Something went wrong'

      toast.error({ meta: { title: message } })
      loaded.value = true
    } else {
      setProperties(response.data.properties)
      loaded.value = true
    }
  }

  const getCurrentActor = (): DatasetItemPropertyActor | null => {
    const currentTeamId = teamStore.currentTeam?.id
    if (!currentTeamId || !userStore.currentUserId) {
      return null
    }
    const member = teamStore.membershipByTeamIdUserId(currentTeamId, userStore.currentUserId)
    if (member) {
      return {
        user_id: member.user_id,
        role: member.role,
        model_id: undefined,
      }
    }
    return null
  }

  const currentItemProperties = ref<DatasetItemPropertiesResponse['properties']>([])
  const setCurrentItemPropertyValues = (
    propertyId: DatasetItemPropertyId,
    values: DatasetItemPropertyValues,
  ): void => {
    currentItemProperties.value = currentItemProperties.value.filter(
      (itemProperty) => itemProperty.id !== propertyId,
    )
    const member = getCurrentActor()
    currentItemProperties.value.push({
      id: propertyId,
      values,
      actors: member ? [member] : [],
    })
  }

  return {
    properties,
    setProperties,
    fetchProperties,
    loaded,
    currentItemProperties,
    setCurrentItemPropertyValues,
  }
})
