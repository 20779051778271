export const isEqual = (a?: string, b?: string, caseSensitive: boolean = true): boolean => {
  if (typeof a !== 'string' || typeof b !== 'string') {
    return false
  }

  if (!caseSensitive) {
    a = a.toLowerCase()
    b = b.toLowerCase()
  }

  return a === b
}

export const containsCssUnit = (val: string | number): boolean => {
  if (typeof val === 'number') {
    return false
  }

  return (
    val.includes('px') ||
    val.includes('em') ||
    val.includes('rem') ||
    val.includes('%') ||
    val.includes('vh') ||
    val.includes('vw') ||
    val.includes('unset')
  )
}
