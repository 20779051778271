import { isAnnotationMainType } from '@/core/annotationTypes'
import { calcBBoxByType } from '@/modules/Editor/bbox/calcBBoxByType'
import { DEFAULT_LINE_WIDTH } from '@/modules/Editor/config'
import { DEFAULT_VERTEX_SIZE } from '@/modules/Editor/config'
import type { BBox } from '@/modules/Editor/types'
import type {
  RenderableItem,
  RenderableItemState,
  RenderableItemWithState,
} from '@/modules/Editor/models/layers/object2D'
import type { RTreeItem } from '@/modules/Editor/models/layers/optimisedLayer/types'
import { setContext } from '@/services/sentry'

export type ReturnType = {
  bbox: BBox
  rTreeItem: RTreeItem
  item: RenderableItemWithState
}

const typeWithVerticesByDefault = ['keypoint', 'skeleton', 'eye']

/**
 * Gets a RenderableItem as an input and
 * provides optimized an item for OptimisedLayer's data structures
 * to reduce the execution time of the CRUD operations.
 *
 * 4 data structures as an output:
 * ItemBBox - bounding boxes of the item
 * RTreeItem - rTree to simplify search by point
 * Item - items with state
 */
export const parseAnnotationData = (
  ann: RenderableItem,
  withState?: RenderableItemState,
): ReturnType | null => {
  // We don't want to include non-main types in the render
  if (!isAnnotationMainType(ann.type)) {
    return null
  }

  const data = ann.data

  const bbox = calcBBoxByType(ann.type, data)

  if (!bbox || !bbox.width || !bbox.height) {
    setContext('Calculate annotation bbox', { ann, bbox, type: ann.type })
    console.error("Can't get bbox for this annotation type!")
    return null
  }

  const itemHasVertices = withState?.isSelected || typeWithVerticesByDefault.includes(ann.type)
  const padding = itemHasVertices ? DEFAULT_VERTEX_SIZE : 0

  bbox.width += (padding + DEFAULT_LINE_WIDTH) * 2
  bbox.height += (padding + DEFAULT_LINE_WIDTH) * 2

  return {
    bbox,
    item: {
      ...ann,
      state: {
        isSelected: false,
        isHighlighted: false,
        isLocked: false,
      },
    },
    rTreeItem: {
      minX: bbox.x - bbox.width / 2,
      minY: bbox.y - bbox.height / 2,
      maxX: bbox.x + bbox.width / 2,
      maxY: bbox.y + bbox.height / 2,
      id: ann.id,
    },
  }
}
