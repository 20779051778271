import type { PartialRecord } from '@/core/helperTypes'
import { sendV2Commands } from '@/backend/darwin/sendV2Commands'

import { ReducerAction, reduceWithMerge } from './advancedReducer'
import { createDeferredPromise } from './createDeferredPromise'
import type { RequestMap as GenericRequestMap, RequestData, RequestType } from './requestMap'
import { createRequestMap, ORequestType } from './requestMap'

type Listener = {
  promise: Promise<SendCommandsResponse>
  resolve: (result: SendCommandsResponse) => void
  reject: (e: unknown) => void
}

type ListenerMap = PartialRecord<string, Listener>

type RequestMap = GenericRequestMap<SendCommandsParams>

type SendCommandsParams = Parameters<typeof sendV2Commands>[0]
type SendCommandsResponse = Awaited<ReturnType<typeof sendV2Commands>>

const clean = (requests: RequestMap): void => {
  // destructure so we can mutate the original
  Object.entries(requests).forEach(([annotationId, item]) => {
    if (item && !item.inFlightRequest && item.queue.length == 0) {
      delete requests[annotationId]
    }
  })
}

/**
 * Recursively processes requests for the given annotationId until they are all cleared
 */
const processRequests = async (
  listenerMap: ListenerMap,
  requests: RequestMap,
  id: string,
): Promise<void> => {
  const requestItem = requests[id]
  const requestToSend = requestItem?.queue[0]
  if (!requestItem || !requestToSend) {
    clean(requests)
    return
  }

  requestItem.inFlightRequest = requestToSend
  requestItem.queue.shift()

  try {
    const result = await sendV2Commands(requestToSend.payload)
    listenerMap[requestToSend.id]?.resolve(result)
  } catch (e) {
    listenerMap[requestToSend.id]?.reject(e)
  }

  requestItem.inFlightRequest = null

  processRequests(listenerMap, requests, id)
}

const getAnnotationId = (params: SendCommandsParams): string => {
  const command = params.commands[0]

  if (!('annotation_id' in command.data)) {
    throw new Error('Unsupported command passed into reducer')
  }

  // When copying an annotation, the `annotation_id` prop refers to
  // the source annotation - the new annotation's ID is stored in the
  // `new_annotation_id` property.
  if (command.type === 'copy_annotation') {
    if ('new_annotation_id' in command.data) {
      return command.data.new_annotation_id
    }

    throw new Error("Copy command doesn't have new_annotation_id in its data payload")
  }

  return command.data.annotation_id
}

/**
 * Returns the command type of a request.
 */
const getCommandType = (request: SendCommandsParams): RequestType => {
  const type = request.commands[0].type
  if (type === 'create_annotation') {
    return ORequestType.CREATE
  }

  if (type === 'update_annotation' || type === 'update_sequence_annotation') {
    return ORequestType.UPDATE
  }

  if (type === 'delete_annotation') {
    return ORequestType.DELETE
  }

  if (type === 'copy_annotation') {
    return ORequestType.COPY
  }

  if (type === 'reorder_annotation') {
    return ORequestType.REORDER
  }

  if (type === 'shift_sequence_annotation_segments') {
    return ORequestType.SHIFT
  }

  if (type === 'request_tracking') {
    return ORequestType.START_AUTO_TRACK
  }

  if (type === 'stop_tracking') {
    return ORequestType.STOP_AUTO_TRACK
  }

  throw new Error('Unsupported command passed into reducer')
}

export const createRequestEngine = (): {
  /** All current in-flight or queued requests, mapped by identifier */
  requests: RequestMap
  /** Adds a request to the requests map */
  dispatchRequest: (payload: SendCommandsParams) => Promise<SendCommandsResponse>
} => {
  const { requests, createRequest, updateRequest, addRequest, clearQueue } =
    createRequestMap<SendCommandsParams>()

  const listenerMap: ListenerMap = {}

  const getInFlightListener = (item: RequestData<SendCommandsParams>): Listener => {
    if (!item.inFlightRequest) {
      throw new Error('Failed to resolve in flight request')
    }
    const inFlightRequestListener = listenerMap[item.inFlightRequest.id]
    if (!inFlightRequestListener) {
      throw new Error('Failed to resolve in flight request listener')
    }
    return inFlightRequestListener
  }

  const createNewListener = (requestId: string): Listener => {
    const listener: Listener = createDeferredPromise()
    listenerMap[requestId] = listener
    return listener
  }

  const dispatchRequest = (payload: SendCommandsParams): Promise<SendCommandsResponse> => {
    const annotationId = getAnnotationId(payload)
    const type = getCommandType(payload)

    const newRequest = createRequest(type, payload)

    const item = requests[annotationId]

    let listener: Listener

    if (!item) {
      listener = createNewListener(newRequest.id)
      addRequest(annotationId, newRequest)
    } else {
      const [action, request] = reduceWithMerge(item.inFlightRequest, item.queue, newRequest)

      if (action === ReducerAction.Add) {
        listener = createNewListener(request.id)
        addRequest(annotationId, request)
      } else if (action == ReducerAction.Update) {
        const firstListener = listenerMap[request[0].id]
        if (!firstListener) {
          throw new Error("Failed to resolve listener for first request in 'update' action")
        }
        listener = firstListener
        for (const r of request) {
          const updatedRequestListener = listenerMap[r.id]
          if (!updatedRequestListener) {
            throw new Error('Failed to resolve listener for updated request')
          }
          listener = updatedRequestListener
          updateRequest(annotationId, r)
        }
      } else if (action === ReducerAction.ReplaceAllQueued) {
        listener = createNewListener(request.id)
        // Because all queued requests will be dropped, we need to chain their promises to the
        // replacing request's promise
        const queueListeners = item.queue.map((r) => {
          const listener = listenerMap[r.id]
          if (!listener) {
            throw new Error('Failed to resolve listener for queued request')
          }
          return listener
        })
        listener.promise
          .then((val) => {
            queueListeners.forEach((l) => l.resolve(val))
          })
          .catch((val) => {
            queueListeners.forEach((l) => l.reject(val))
          })
        item.queue.forEach((r) => delete listenerMap[r.id])
        clearQueue(annotationId)
        addRequest(annotationId, request)
      } else if (action === ReducerAction.DiscardLastQueued) {
        const lastQueuedRequest = item.queue.pop()
        if (!lastQueuedRequest) {
          throw new Error('Failed to resolve last queued request')
        }
        const lastQueuedListener = listenerMap[lastQueuedRequest.id]
        if (!lastQueuedListener) {
          throw new Error('Failed to resolve last queued request listener')
        }
        delete listenerMap[lastQueuedRequest.id]
        listener = getInFlightListener(item)
        // Because last queued request has been dropped, we need to resolve the promise
        // as soon as the in flight request is done
        listener.promise.then(lastQueuedListener.resolve).catch(lastQueuedListener.reject)
      } else if (action === 'discard_new') {
        // The new request is discarded, so we need to resolve the promise as soon as the
        // in flight request is done
        listener = getInFlightListener(item)
      } else {
        throw new Error('Unsupported action')
      }
    }

    const existingItem = requests[annotationId]
    // at this point, an item MUST exist. This purely satisfies typescript
    if (!existingItem) {
      throw new Error('Failed to resolve queue request item')
    }

    // No in flight request means the request loop is not currently running, so it needs to be
    // started. Once started, it will continue until there are no more requests to process.
    // This is PER annotation id.
    if (!existingItem.inFlightRequest) {
      processRequests(listenerMap, requests, annotationId)
    }

    return listener.promise
  }

  return {
    requests,
    dispatchRequest,
  }
}
