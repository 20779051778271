import { Object2D } from '@/modules/Editor/models/layers/object2D'
import { renderDicomImageOnCanvas } from '@/modules/Editor/renderDicomImageOnCanvas'
import { renderMeasureRegion } from '@/modules/Editor/renderMeasureRegion'
import { VideoView } from '@/modules/Editor/views/videoView'

import { ViewTypes } from './viewTypes'

/**
 * As time goes on we are going to extend the DicomView for actual medical purposes.
 * Due to bad 16-bit support in V7, some customers have used DICOM in their pipelines to
 * properly upload, view, and annotate 16-bit images. They have built entire pipelines around
 * this so building an alternative 16 bit upload for them is not an option.
 *
 * Here we keep a simple legacy DICOM view for old customers using this work around.
 */
export class LegacyDicomView extends VideoView {
  public readonly type = ViewTypes.LEGACY_DICOM

  public readonly childSlotNames: string[] = []
  public readonly parentSlotName?: string = undefined

  async init(): Promise<void> {
    if (!this.mainLayer.canvas) {
      return
    }

    this.initState()
    this.camera.setConfig({ centerY: true })
    // For DICOM we use dark background

    this.mainLayer.canvas.style.background = '#000'
    this.mainLayer.add(
      new Object2D(ViewTypes.DICOM, () => {
        if (!this.currentFrame) {
          return
        }
        if (!this.mainLayer.canvas) {
          return
        }

        renderDicomImageOnCanvas(
          this,
          this.mainLayer.canvas,
          this.currentFrame,
          this.fileManager.metadata || null,
        )

        if (this.editor.renderMeasures) {
          renderMeasureRegion(this)
        }
      }),
    )

    await this.jumpToFrame(this.currentFrameIndex)

    this.scaleToFit()
  }
}
