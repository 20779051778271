import type { FrameData } from '@/core/annotations'
import type { IPoint } from '@/modules/Editor/point'
import type { BBox } from '@/modules/Editor/types'

export const calcBBoxForVectorByType = (
  vector: FrameData['directional_vector'],
  centroid: IPoint,
  annBBox: BBox,
): BBox | undefined => {
  if (!vector) {
    return
  }

  const start = {
    x: centroid.x,
    y: centroid.y,
  }
  const end = {
    x: start.x + vector.length * Math.cos(vector.angle),
    y: start.y + vector.length * Math.sin(vector.angle),
  }

  const left = Math.min(start.x, end.x)
  const right = Math.max(start.x, end.x)
  const top = Math.min(start.y, end.y)
  const bottom = Math.max(start.y, end.y)

  const bLeft = Math.min(annBBox.x - annBBox.width / 2, left)
  const bRight = Math.max(annBBox.x + annBBox.width / 2, right)
  const bTop = Math.min(annBBox.y - annBBox.height / 2, top)
  const bBottom = Math.max(annBBox.y + annBBox.height / 2, bottom)

  return {
    width: bRight - bLeft,
    height: bBottom - bTop,
    x: (bRight + bLeft) / 2,
    y: (bBottom + bTop) / 2,
  }
}
