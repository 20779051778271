import { remove } from '@/backend/api'
import { parseError, errorMessages, isErrorResponse, type ParsedError } from '@/backend/error'
import type { AxiosResponse } from 'axios'

type Params = {
  datasetSlug: string
  name: string
  teamSlug: string
}

export const deleteV2Export = async (params: Params): Promise<AxiosResponse | ParsedError> => {
  const { datasetSlug, name, teamSlug } = params
  const path = `v2/teams/${teamSlug}/datasets/${datasetSlug}/exports/${name}`
  try {
    const response = await remove(path)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.DATASET_EXPORT)
  }
}
