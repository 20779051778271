import type { IPoint } from '@/modules/Editor/point'
import type { BBox } from '@/modules/Editor/types'
import { getAnnotationCompoundPathAtFrame } from '@/modules/Editor/getAnnotationCompoundPath'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { View } from '@/modules/Editor/views/view'

/**
 * Returns BBox for annotation.
 *
 * @deprecated
 * getBBox calculates bbox wrong sometimes (eclipse for e.g.).
 * @param view
 * @param annotation
 * @returns
 */
export const getBBox = (view: View, annotation: Annotation): BBox | undefined => {
  if (annotation.type === 'tag' || annotation.type === 'mask') {
    return
  }

  const { path: mainPath, additionalPaths } = getAnnotationCompoundPathAtFrame(
    annotation,
    view.currentFrameIndex,
  )

  let xMin = Infinity
  let xMax = -Infinity
  let yMin = Infinity
  let yMax = -Infinity

  const allPaths = [mainPath, ...additionalPaths]
  allPaths.forEach((path: IPoint[]) => {
    path.forEach((point: IPoint) => {
      const p = view.camera.imageViewToCanvasView(point)

      xMin = Math.min(p.x, xMin)
      yMin = Math.min(p.y, yMin)
      xMax = Math.max(p.x, xMax)
      yMax = Math.max(p.y, yMax)
    })
  })

  const width = Math.abs(xMax - xMin)
  const height = Math.abs(yMax - yMin)

  return {
    width,
    height,
    x: (xMax + xMin) / 2 || Infinity,
    y: (yMax + yMin) / 2 || Infinity,
  }
}
