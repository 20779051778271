import { createSharedComposable } from '@vueuse/core'
import { ref, onBeforeUnmount, watch } from 'vue'
import type { Ref } from 'vue'

import { useSharedEditorLayout } from '@/composables/useEditorV2/useLayout'
import { LayoutEvents } from '@/modules/Editor/eventBus'
import type { Layout } from '@/modules/Editor/layout'
import type { View } from '@/modules/Editor/views/view'

const useActiveView = (): Ref<View | undefined> => {
  const sharedLayout = useSharedEditorLayout()

  const activeView = ref(sharedLayout.value?.layout.activeView)

  const handleActiveViewChanged = ({ newViewId }: { newViewId: string }): void => {
    activeView.value = sharedLayout.value?.layout.views.get(newViewId)
  }

  LayoutEvents.activeViewChanged.on(handleActiveViewChanged)

  watch(
    () => sharedLayout.value,
    (newSharedLayout: { layout: Layout } | undefined) => {
      newSharedLayout &&
        handleActiveViewChanged({
          newViewId: newSharedLayout.layout.activeView.id,
        })
    },
    { immediate: true },
  )

  onBeforeUnmount(() => {
    LayoutEvents.activeViewChanged.off(handleActiveViewChanged)
    activeView.value = undefined
  })

  return activeView as Ref<View | undefined>
}

export const useSharedActiveView = createSharedComposable(useActiveView)
