import type { PropertiesData } from '@/core/annotations'
import type { AnnotationProperties } from '@/modules/Editor/models/annotation/types'
import cloneDeep from 'lodash/cloneDeep'
import type { TeamPropertyFrameIndex } from '@/backend/darwin/teamProperties'
import { STATIC_PROPERTY_FRAME_NAME } from '@/backend/darwin/teamProperties'

/**
 * Returns the annotation.properties data filtering out frameIndexes that are null
 */
export const getAnnotationPropertiesWithValidData = (
  properties: AnnotationProperties,
): AnnotationProperties => {
  const validFrames: AnnotationProperties = {}
  for (const frameIndex in properties) {
    const propertiesData = properties[Number(frameIndex)]
    // When properties are removed from a frame, they are set to `null` and we need to remove them
    if (propertiesData !== null) {
      validFrames[Number(frameIndex)] = propertiesData
    }
  }
  return validFrames
}

/**
 * Interpolates frame level properties without considering global properties
 */
export const interpolateFrameLevelProperties = (
  frameIndex: number,
  allProperties: AnnotationProperties,
): PropertiesData => {
  const previousFrames = Object.keys(allProperties).filter(
    (index) => index != 'global' && Number(index) <= frameIndex,
  )
  const activeFrameIndex = previousFrames.reduce((a, b) => Math.max(a, Number(b)), -Infinity)

  let interpolatedProperties: PropertiesData = {}

  // Object.keys returns indices in ascending order
  for (const frameIndex of Object.keys(allProperties)) {
    if (activeFrameIndex < Number(frameIndex)) {
      break
    }

    const currentFrameProperties = cloneDeep(allProperties[Number(frameIndex)])

    if (!currentFrameProperties) {
      break
    }

    Object.keys(currentFrameProperties).forEach((propertyId) => {
      if (currentFrameProperties[propertyId] === null) {
        delete currentFrameProperties[propertyId]
      }
    })

    interpolatedProperties = {
      ...interpolatedProperties,
      ...currentFrameProperties,
    }
  }

  return interpolatedProperties
}

/**
 * Interpolates properties on every level separately.
 * If extendSectionsByGlobalProperties is true, then section level properties are extended
 * by global properties.
 */
export const interpolateProperties = (
  frameIndexOrGlobal: TeamPropertyFrameIndex,
  propertiesPerFrame: AnnotationProperties,
  extendSectionsByGlobalProperties: boolean = true,
): PropertiesData => {
  let interpolatedProperties: PropertiesData = {}

  if (frameIndexOrGlobal !== STATIC_PROPERTY_FRAME_NAME) {
    interpolatedProperties = interpolateFrameLevelProperties(frameIndexOrGlobal, propertiesPerFrame)
  }

  if (frameIndexOrGlobal === STATIC_PROPERTY_FRAME_NAME || extendSectionsByGlobalProperties) {
    // If global values are set for properties, then override the frame value
    const globalPropertiesOverride = propertiesPerFrame[STATIC_PROPERTY_FRAME_NAME]
    if (globalPropertiesOverride) {
      Object.keys(globalPropertiesOverride).forEach((propertyId: string) => {
        interpolatedProperties[propertyId] = globalPropertiesOverride[propertyId]
      })
    }
  }

  return interpolatedProperties
}
