import type { View } from '@/modules/Editor/views/view'
import type { VideoView } from '@/modules/Editor/views/videoView'

export const isVideoView = (view: View | undefined): view is VideoView =>
  !!view &&
  (view.type === 'video' ||
    view.type === 'stream' ||
    view.type === 'dicom' ||
    view.type === 'legacy_dicom' ||
    view.type === 'pdf')
