import type { TeamAction } from '@/store/modules/team/types'
import type { TeamPayload } from '@/store/types'
import * as api from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

/**
 * Get Detailed team information
 * @param teamId Team Id
 */
export const getTeam: TeamAction<number, TeamPayload> = async ({ commit, state }, teamId) => {
  let response

  try {
    response = await api.get<TeamPayload>(`teams/${teamId}`)
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.TEAM_LOAD)
  }
  const team = response.data as TeamPayload
  const { currentTeam } = state

  if (currentTeam && currentTeam.id === team.id) {
    commit('SET_CURRENT_TEAM', team)
  }
  commit('PUSH_TEAM', response.data)
  return response
}
