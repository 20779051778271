import { isSkeleton } from '@/modules/Editor/annotationTypes/skeleton'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import { drawSkeletonV2 } from '@/modules/Editor/graphics'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { DrawCallback } from '@/modules/Editor/models/layers/types'
import type { View } from '@/modules/Editor/views/view'

export const renderSkeleton = (
  drawFn: DrawCallback,
  view: View,
  annotation: Annotation,
  filter: ImageManipulationFilter | null,
): void => {
  const isSelected = view.annotationManager.isSelected(annotation.id)
  const { annotationClass, data } = annotation
  if (!annotationClass) {
    return
  }

  const { color, skeletonMetaData } = annotationClass
  if (!isSkeleton(data)) {
    throw new Error('skeleton: expected annotation of skeleton type')
  }
  const { nodes } = data
  if (!skeletonMetaData) {
    return
  }
  const { edges } = skeletonMetaData
  drawSkeletonV2(drawFn, view.camera, nodes, edges, color, filter, isSelected)
}
