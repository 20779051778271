import type { VideoAnnotationData } from '@/modules/Editor/AnnotationData'

/**
 * Builds the initial value for the `segments` field of a video annotation.
 * This value is equal to [[currentFrameIndex, currentFrameIndex]].
 *
 * It should not be stored to backend. Rathern, when serializing annotation, we
 * should call `isInitialSegments` to check if we are using the initial value,
 * and replace it with the final value.
 *
 * We place both functions here, to centralize the logic behind it, but this is
 * a partial solution to the issue. Video annotations should be instead
 * instantiated differently, where this sort of "live patching" is not needed.
 */
export const buildInitialVideoAnnotationSegments = (
  frameIndex: number,
): VideoAnnotationData['segments'] => [[frameIndex, frameIndex]]
