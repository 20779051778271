import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { isLeftMouseButton } from '@/modules/Editor/mouse'
import type { PointerEvent } from '@/core/utils/touch'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'

export const setupToolMouseCallbacks = (
  context: ToolContext,
  onStart: (context: ToolContext, event: PointerEvent) => void,
  onMove: (context: ToolContext, event: PointerEvent) => void,
  onEnd: (context: ToolContext, event: PointerEvent) => void,
): void => {
  context.handles.push(
    ...context.editor.onMouseDown((e) => {
      if (!isLeftMouseButton(e)) {
        return CallbackStatus.Continue
      }
      window.addEventListener('mouseup', (windowEvent) => onEnd(context, windowEvent), {
        once: true,
      })
      return onStart(context, e)
    }),
  )

  context.handles.push(
    ...context.editor.onTouchStart((e) => {
      window.addEventListener('touchend', (windowEvent) => onEnd(context, windowEvent), {
        once: true,
      })
      return onStart(context, e)
    }),
  )

  context.handles.push(...context.editor.onMouseMove((e) => onMove(context, e)))
  context.handles.push(...context.editor.onTouchMove((e) => onMove(context, e)))
}
