import { isDicomView } from '@/modules/Editor/utils/radiology/isDicomView'
import { doesNeedIsotropicTransformation } from '@/modules/Editor/utils/radiology/doesNeedIsotropicTransformation'
import type { View } from '@/modules/Editor/views/view'

export type DicomFrameParams = {
  width: number
  height: number
  xScale: number
  yScale: number
}

/**
 * Gets DICOM frame params.
 *
 * It's main use case is to extract DICOM frame data for AI models like SAM or Generic Auto-Annotate.
 *
 * These models operate on non-scaled raw DICOMs (PNG frames) and for them to work correctly we need to scale
 * both input (like clicks we send) and output (like incoming mask/polygon) to the original DICOM frame size.
 */
export const getDicomFrameParams = (view: View): DicomFrameParams | undefined => {
  if (!view.editor.featureFlags.MED_2D_VIEWER) {
    return
  }

  if (!isDicomView(view)) {
    return
  }

  const fileManager = view.fileManager
  if (!fileManager.metadata || !doesNeedIsotropicTransformation(fileManager.metadata)) {
    return
  }

  const { width, height, pixelWidth, pixelHeight } = fileManager.metadata
  if (!pixelWidth || !pixelHeight) {
    return
  }

  const xScale = pixelWidth / width
  const yScale = pixelHeight / height

  return { width, height, xScale, yScale }
}
