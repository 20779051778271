import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import type { Camera } from '@/modules/Editor/camera'
import { DEFAULT_LINE_WIDTH } from '@/modules/Editor/config'
import type { IPoint } from '@/modules/Editor/point'
import { subPoints, createEditablePoint } from '@/modules/Editor/point'
import type { DrawCallback } from '@/modules/Editor/models/layers/types'
import type { View } from '@/modules/Editor/views/view'

import { drawVertex, drawVertexV2 } from './drawVertex'

export const drawTemporarySkeletonV2 = (
  drawFn: DrawCallback,
  view: View,
  initialPoint: IPoint,
  cursorPoint: IPoint,
  annotationClass: AnnotationClass,
): void => {
  drawFn((ctx, canvas, draw) => {
    if (!annotationClass) {
      return
    }
    if (!annotationClass.annotation_types.includes('skeleton')) {
      return
    }

    ctx.strokeStyle = annotationClass.colorRGBAstring
    ctx.lineWidth = 1

    const { x: width, y: height } = subPoints(cursorPoint, initialPoint)

    if (!annotationClass.skeletonMetaData) {
      return
    }
    const { nodes, edges } = annotationClass.skeletonMetaData
    for (const edge of edges) {
      const fromNode = nodes.find((node) => node.name === edge.from)
      const toNode = nodes.find((node) => node.name === edge.to)
      if (!fromNode || !toNode) {
        continue
      }

      ctx.moveTo(initialPoint.x + fromNode.x * width, initialPoint.y + fromNode.y * height)
      ctx.lineTo(initialPoint.x + toNode.x * width, initialPoint.y + toNode.y * height)
    }
    ctx.stroke()

    if (!draw) {
      return
    }

    ctx.lineJoin = 'round'
    for (const node of nodes) {
      drawVertexV2(
        draw,
        view.camera,
        createEditablePoint({
          x: initialPoint.x + node.x * width,
          y: initialPoint.y + node.y * height,
        }),
        null,
        annotationClass.color,
        true,
        false,
        false,
        false,
      )
    }
  })
}

/**
 * @deprecated
 */
export const drawTemporarySkeleton = (
  camera: Camera,
  ctx: CanvasRenderingContext2D,
  initialPoint: IPoint,
  cursorPoint: IPoint,
  annotationClass: AnnotationClass,
): void => {
  if (!annotationClass) {
    return
  }
  if (!annotationClass.annotation_types.includes('skeleton')) {
    return
  }

  ctx.strokeStyle = annotationClass.colorRGBAstring
  ctx.lineWidth = DEFAULT_LINE_WIDTH

  const { x: width, y: height } = subPoints(cursorPoint, initialPoint)

  if (!annotationClass.skeletonMetaData) {
    return
  }
  const { nodes, edges } = annotationClass.skeletonMetaData
  for (const edge of edges) {
    const fromNode = nodes.find((node) => node.name === edge.from)
    const toNode = nodes.find((node) => node.name === edge.to)
    if (!fromNode || !toNode) {
      continue
    }

    ctx.moveTo(initialPoint.x + fromNode.x * width, initialPoint.y + fromNode.y * height)
    ctx.lineTo(initialPoint.x + toNode.x * width, initialPoint.y + toNode.y * height)
  }
  ctx.stroke()

  ctx.lineJoin = 'round'
  for (const node of nodes) {
    drawVertex(
      ctx,
      camera,
      createEditablePoint({
        x: initialPoint.x + node.x * width,
        y: initialPoint.y + node.y * height,
      }),
      null,
      annotationClass.color,
      true,
      false,
    )
  }
}
