import range from 'lodash/range'

import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import { isLeftMouseButton } from '@/modules/Editor/mouse'
import type { IPoint } from '@/modules/Editor/point'
import { Rectangle } from '@/modules/Editor/rectangle'
import { ToolName } from '@/modules/Editor/tools/types'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import { updateAnnotationData } from '@/modules/Editor/actions'
import { drawGuideLines } from '@/modules/Editor/graphics/drawGuideLines'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import { createBoundingBoxData } from '@/modules/Editor/plugins/boundingBox/tool'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import { preselectOrPromptForAnnotationClass } from '@/modules/Editor/utils/preselectOrPromptForAnnotationClass'
import type { View } from '@/modules/Editor/views/view'

import { SIMPLE_TABLE_ANNOTATION_TYPE } from './types'
import { isOnRowOrColumn, isOnTable } from './utils'
import type { OnTableLineContext } from './utils'
import type { Action } from '@/modules/Editor/managers/actionManager'
import type { SimpleTable } from '@/modules/Editor/AnnotationData'

export interface SimpleTableTool extends Tool {
  initialPoint?: IPoint
  cursorPoint?: IPoint

  rowOffsets: number[]
  colOffsets: number[]

  initialImagePoint?: IPoint
  cursorImagePoint?: IPoint

  onTableContext: OnTableLineContext

  rows: number
  cols: number

  onStart: (context: ToolContext, event: PointerEvent) => void
  onMove: (context: ToolContext, event: PointerEvent) => void
  onEnd: (context: ToolContext, event: PointerEvent) => void
  draw: (view: View) => void

  confirmTable: (context: ToolContext) => void
}

const annotationCreationAction = async (
  context: ToolContext,
  data: SimpleTable,
): Promise<Action> => {
  const params = {
    type: SIMPLE_TABLE_ANNOTATION_TYPE,
    data,
    classId: -1,
  }

  const newAnnotation =
    await context.editor.activeView.annotationManager.prepareAnnotationForCreation(params)

  if (!newAnnotation) {
    throw new Error('Failed to prepare annotation for creation')
  }

  // support view id for undoing purposes
  const sourceViewId = context.editor.activeView.id

  return {
    do(): boolean {
      context.editor.activeView.annotationManager.createAnnotation(newAnnotation)
      context.editor.activeView.annotationManager.selectAnnotation(newAnnotation.id)
      context.editor.activeView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)

      return true
    },
    undo(): boolean {
      // we don't want to use the active view here, as by the time the user want to undo
      // the view could have changed due to multi-slot;
      // this would result in the annotation not being found and the undo action failing
      const sourceView = context.editor.viewsList.find(({ id }) => id === sourceViewId)
      if (!sourceView) {
        return false
      }

      sourceView.annotationManager.deleteAnnotation(newAnnotation.id)

      sourceView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)
      return true
    },
  }
}

export const simpleTableTool: SimpleTableTool = {
  initialPoint: undefined,
  cursorPoint: undefined,

  onTableContext: undefined,

  rows: 2,
  cols: 3,

  rowOffsets: range(1, 2).map((o) => o / 2),
  colOffsets: range(1, 3).map((o) => o / 3),

  onStart(context: ToolContext, event: PointerEvent) {
    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(point)
    this.onTableContext = isOnRowOrColumn(imagePoint, context)

    // If the cursor lays on top of an existing table,
    // automatically change the number of rows and columns in the tool options
    // top bar. Otherwise, set initial values of rowOffsets and colOffsets
    // so that the delimiters of the rows and columns of the new table
    // are equally distanced.
    const { onTableContext } = this
    if (onTableContext) {
      this.rows = onTableContext.rowOffsets.length + 1
      this.cols = onTableContext.colOffsets.length + 1
      this.rowOffsets = onTableContext.rowOffsets
      this.colOffsets = onTableContext.colOffsets
      context.editor.toolManager.setToolOptionProps('rows', { value: this.rows })
      context.editor.toolManager.setToolOptionProps('cols', { value: this.cols })
      context.editor.activeView.annotationManager.selectAnnotation(onTableContext.annotation.id)
    } else {
      this.rowOffsets = range(1, this.rows).map((o) => o / this.rows)
      this.colOffsets = range(1, this.cols).map((o) => o / this.cols)
    }

    this.initialPoint = point
    this.draw(context.editor.activeView)
  },

  onMove(context: ToolContext, event: PointerEvent) {
    selectCursor(EditorCursor.BBox)

    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(point)
    // If the cursor is on top of a row or column delimiter, the user is
    // adjusting said delimiters, which are distanced according to rowOffsets and
    // colOffsets for rows and columns respectively.
    if (this.onTableContext) {
      const { boundingBox, offset, type } = this.onTableContext
      if (type === 'row') {
        const prevRowOffset = offset === 0 ? 0 : this.rowOffsets[offset - 1]

        const nextRowOffset =
          offset === this.rowOffsets.length - 1 ? 1 : this.rowOffsets[offset + 1]

        this.rowOffsets[offset] = Math.min(
          Math.max(
            (imagePoint.y - boundingBox.topLeft.y) /
              (boundingBox.bottomRight.y - boundingBox.topLeft.y),
            prevRowOffset,
          ),
          nextRowOffset,
        )
      } else {
        const prevColOffset = offset === 0 ? 0 : this.colOffsets[offset - 1]

        const nextColOffset =
          offset === this.colOffsets.length - 1 ? 1 : this.colOffsets[offset + 1]

        this.colOffsets[offset] = Math.min(
          Math.max(
            (imagePoint.x - boundingBox.topLeft.x) /
              (boundingBox.bottomRight.x - boundingBox.topLeft.x),
            prevColOffset,
          ),
          nextColOffset,
        )
      }
      this.draw(context.editor.activeView)
      return CallbackStatus.Stop
    }

    if (isOnRowOrColumn(imagePoint, context)) {
      selectCursor(EditorCursor.Pointer)
    }

    this.cursorPoint = point
    this.draw(context.editor.activeView)
    return CallbackStatus.Stop
  },

  async onEnd(context: ToolContext, event: PointerEvent) {
    const point = resolveEventPoint(event, true)
    if (!point) {
      return
    }

    if (!this.initialPoint || !this.cursorPoint) {
      return
    }

    // If the mouse action ends on a row or column delimiter,
    // then just trigger the update of the existing table and return.
    if (this.onTableContext) {
      return await this.confirmTable(context)
    }

    // Otherwise, first make sure that the table bounding box is valid.
    // (If not reset the status of the tool)
    this.initialImagePoint = context.editor.activeView.camera.canvasViewToImageView(
      this.initialPoint,
    )
    this.cursorImagePoint = context.editor.activeView.camera.canvasViewToImageView(this.cursorPoint)

    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(point)

    const box = new Rectangle(this.initialImagePoint, this.cursorImagePoint)
    if (!box.isValid()) {
      this.reset(context)
      const onTableContext = isOnTable(imagePoint, context)
      if (onTableContext) {
        const { rowOffsets, colOffsets } = onTableContext
        this.rows = rowOffsets.length + 1
        this.cols = colOffsets.length + 1
        this.rowOffsets = rowOffsets
        this.colOffsets = colOffsets
        context.editor.toolManager.setToolOptionProps('rows', { value: this.rows })
        context.editor.toolManager.setToolOptionProps('cols', { value: this.cols })
      }
      return
    }

    await this.confirmTable(context)
  },

  async confirmTable(context: ToolContext) {
    const { initialPoint, cursorPoint } = this
    if (!initialPoint || !cursorPoint) {
      return
    }

    const { activeView } = context.editor

    try {
      // If the table has been previously edited, then trigger an update action,
      // otherwise create a new table annotation
      if (this.onTableContext) {
        const { annotation } = this.onTableContext
        const action = updateAnnotationData(
          activeView,
          annotation,
          { ...annotation.data },
          { ...annotation.data, rowOffsets: this.rowOffsets, colOffsets: this.colOffsets },
        )
        await activeView.actionManager.do(action)
      } else {
        const boundingBox = createBoundingBoxData(context, initialPoint, cursorPoint)
        if (!boundingBox) {
          throw new Error('Failed to create bounding box for simple table')
        }
        await activeView.actionManager.do(
          await annotationCreationAction(context, {
            rowOffsets: this.rowOffsets,
            colOffsets: this.colOffsets,
            boundingBox,
          }),
        )
      }
    } finally {
      this.reset(context)

      if (context.editor.renderMeasures) {
        context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
      }
    }

    this.draw(context.editor.activeView)
  },

  async activate(context: ToolContext) {
    setupMouseButtonLoadout(context, { middle: true })

    const classSelected = await preselectOrPromptForAnnotationClass(
      context.editor.activeView,
      ToolName.SimpleTable,
      [SIMPLE_TABLE_ANNOTATION_TYPE],
      'You must create or select an existing Table class before using the table tool',
    )

    if (!classSelected) {
      return
    }

    selectCursor(EditorCursor.BBox)

    context.editor.toolManager.setToolOptionProps('rows', { value: this.rows })
    context.editor.toolManager.setToolOptionProps('cols', { value: this.cols })

    context.editor.registerCommand('table_tool.set_rows', (size: number) => {
      this.rows = size
      this.rowOffsets = range(1, this.rows).map((o) => o / this.rows)

      context.editor.toolManager.setToolOptionProps('rows', { value: this.rows })
      this.draw(context.editor.activeView)
    })

    context.editor.registerCommand('table_tool.set_cols', (size: number) => {
      this.cols = size
      this.colOffsets = range(1, this.cols).map((o) => o / this.cols)

      context.editor.toolManager.setToolOptionProps('cols', { value: this.cols })
      this.draw(context.editor.activeView)
    })

    context.editor.registerCommand('simple_table_tool.cancel', () => {
      this.reset(context)
      this.draw(context.editor.activeView)
    })

    context.handles.push(
      ...context.editor.onMouseDown((e) => {
        if (!isLeftMouseButton(e)) {
          return CallbackStatus.Continue
        }
        return this.onStart(context, e)
      }),
    )
    context.handles.push(...context.editor.onTouchStart((event) => this.onStart(context, event)))
    context.handles.push(...context.editor.onMouseMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onTouchMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onMouseUp((event) => this.onEnd(context, event)))
    context.handles.push(...context.editor.onTouchEnd((event) => this.onEnd(context, event)))

    const viewsOnRender = context.editor.viewsList.map((view) =>
      view.renderManager.onRender((view) => {
        const ctx = view.annotationsLayer.context
        if (!ctx) {
          return
        }

        if (this.cursorPoint) {
          drawGuideLines(ctx, view, this.cursorPoint)
        }

        if (this.cursorPoint == null || this.initialPoint == null) {
          return
        }

        ctx.beginPath()
        ctx.strokeStyle =
          context.editor.activeView.annotationManager.preselectedAnnotationClassColor()
        ctx.fillStyle =
          context.editor.activeView.annotationManager.preselectedAnnotationClassColor(0.15)
        ctx.lineWidth = 1

        const finalInitialPoint = this.initialImagePoint
          ? context.editor.activeView.camera.imageViewToCanvasView(this.initialImagePoint)
          : this.initialPoint

        const finalCursorPoint = this.cursorImagePoint
          ? context.editor.activeView.camera.imageViewToCanvasView(this.cursorImagePoint)
          : this.cursorPoint

        const x = finalInitialPoint.x
        const y = finalInitialPoint.y
        const w = finalCursorPoint.x - finalInitialPoint.x
        const h = finalCursorPoint.y - finalInitialPoint.y
        ctx.strokeRect(x, y, w, h)
        ctx.fillRect(x, y, w, h)

        for (const col of range(1, this.cols)) {
          const ratio = this.colOffsets[col - 1]
          ctx.beginPath()
          ctx.moveTo(x + ratio * w, y)
          ctx.lineTo(x + ratio * w, y + h)
          ctx.stroke()
        }
        for (const row of range(1, this.rows)) {
          const ratio = this.rowOffsets[row - 1]
          ctx.beginPath()
          ctx.moveTo(x, y + ratio * h)
          ctx.lineTo(x + w, y + ratio * h)
          ctx.stroke()
        }
      }),
    )
    context.handles.push(...viewsOnRender)

    this.reset(context)
  },
  draw(view: View): void {
    view.annotationsLayer.draw((ctx) => {
      if (this.cursorPoint) {
        drawGuideLines(ctx, view, this.cursorPoint)
      }

      if (this.cursorPoint == null || this.initialPoint == null) {
        return
      }

      ctx.beginPath()
      ctx.strokeStyle = view.annotationManager.preselectedAnnotationClassColor()
      ctx.fillStyle = view.annotationManager.preselectedAnnotationClassColor(0.15)
      ctx.lineWidth = 1

      const finalInitialPoint = this.initialImagePoint
        ? view.camera.imageViewToCanvasView(this.initialImagePoint)
        : this.initialPoint

      const finalCursorPoint = this.cursorImagePoint
        ? view.camera.imageViewToCanvasView(this.cursorImagePoint)
        : this.cursorPoint

      const x = finalInitialPoint.x
      const y = finalInitialPoint.y
      const w = finalCursorPoint.x - finalInitialPoint.x
      const h = finalCursorPoint.y - finalInitialPoint.y
      ctx.strokeRect(x, y, w, h)
      ctx.fillRect(x, y, w, h)

      for (const col of range(1, this.cols)) {
        const ratio = this.colOffsets[col - 1]
        ctx.beginPath()
        ctx.moveTo(x + ratio * w, y)
        ctx.lineTo(x + ratio * w, y + h)
        ctx.stroke()
      }
      for (const row of range(1, this.rows)) {
        const ratio = this.rowOffsets[row - 1]
        ctx.beginPath()
        ctx.moveTo(x, y + ratio * h)
        ctx.lineTo(x + w, y + ratio * h)
        ctx.stroke()
      }
    })
  },
  deactivate() {},
  reset(context: ToolContext) {
    this.initialPoint = undefined
    this.cursorPoint = undefined

    this.rowOffsets = range(1, this.rows).map((o) => o / this.rows)
    this.colOffsets = range(1, this.cols).map((o) => o / this.cols)

    this.initialImagePoint = undefined
    this.cursorImagePoint = undefined

    this.onTableContext = undefined

    if (context.editor.renderMeasures) {
      context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
    }
  },
}
