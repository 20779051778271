import sortBy from 'lodash/sortBy'
import { shallowRef, triggerRef } from 'vue'
import type { ShallowRef } from 'vue'

import { binarySearch } from '@/utils/binarySearch'

/**
 * The performance of array methods like .includes, .indexOf, .findIndex, etc, is terrible
 * specially when dealing with very large arrays that are constantly accessed.
 * If we add the extremely sensible Vue's reactivity system  to the equation were every change
 * fires countless parent/dependency computations we can easily kill the browser
 * when dealing with very large arrays.
 *
 * To overcome this performance bottleneck we need to move to a binarySeach implementation
 * which only works with sorted arrays and we also need to have fine control
 * over when and why the reactivity is fired.
 *
 * This composable allow us work with sorted arrays effortless
 * and fire the reactivity only when it's needed
 * @returns
 */
export const useBinarySearchArray = <T extends { id: string }>(): {
  items: ShallowRef<readonly T[]>
  create: (array: T[], options?: { triggerReactivity: boolean }) => void
  findIndex: (id: string) => number
  add: (item: T, options?: { triggerReactivity?: boolean; overwrite?: boolean }) => boolean
  addBatch: (items: T[], options?: { triggerReactivity?: boolean; overwrite?: boolean }) => void
  remove: (id: string, options?: { triggerReactivity: boolean }) => boolean
  removeBatch: (ids: string[], options?: { triggerReactivity: boolean }) => void
  reset: (options?: { triggerReactivity: boolean }) => void
  update: (item: T, options?: { triggerReactivity: boolean }) => boolean
} => {
  const items = shallowRef<T[]>([])

  /**
   * ShallowRefs reactivity only fires when the value is replaced
   * @see https://vuejs.org/guide/best-practices/performance.html
   * #reduce-reactivity-overhead-for-large-immutable-structures
   *
   * this won't trigger updates...
   * shallowArray.value.push(newObject)
   *
   * this will trigger updates...
   * shallowArray.value = [...shallowArray.value, newObject]
   *
   * triggerRef allow us to manually fire Vue reactivity system
   * without needing to replace the entire object plus it gives us
   * full control on when the reactivity is fired
   * @returns void
   */
  const triggerReactiveUpdate = (): void => triggerRef(items)

  const _create = (array: T[]): void => {
    items.value = sortBy(array, ['id'])
  }

  const create = (array: T[], { triggerReactivity = false } = {}): void => {
    _create(array)
    if (triggerReactivity) {
      triggerReactiveUpdate()
    }
  }

  const findIndex = (id: string): number => binarySearch(items.value, id).middle

  const _add = (item: T, overwrite: boolean): boolean => {
    const { found, middle } = binarySearch(items.value, item.id)
    if (!found) {
      items.value.splice(middle, 0, item)
    }
    if (overwrite) {
      items.value.splice(middle, 1, item)
    }
    return !found
  }

  const add = (item: T, { triggerReactivity = false, overwrite = false } = {}): boolean => {
    const response = _add(item, overwrite)
    if (triggerReactivity) {
      triggerReactiveUpdate()
    }
    return response
  }

  const addBatch = (items: T[], { triggerReactivity = false, overwrite = false } = {}): void => {
    items.forEach((item) => _add(item, overwrite))
    if (triggerReactivity) {
      triggerReactiveUpdate()
    }
  }

  // this method is meant to only update existing elements
  // and prevent the insertion of new ones
  const _update = (item: T): boolean => {
    const { found, middle } = binarySearch(items.value, item.id)
    if (!found) {
      return false
    }
    items.value.splice(middle, 1, item)
    return true
  }

  const update = (item: T, { triggerReactivity = false } = {}): boolean => {
    const response = _update(item)
    if (triggerReactivity) {
      triggerReactiveUpdate()
    }
    return response
  }

  const _remove = (id: string): boolean => {
    const { found, middle } = binarySearch(items.value, id)
    if (!found) {
      return false
    }
    items.value.splice(middle, 1)
    return true
  }

  const remove = (id: string, { triggerReactivity = false } = {}): boolean => {
    const response = _remove(id)
    if (triggerReactivity) {
      triggerReactiveUpdate()
    }
    return response
  }

  const removeBatch = (ids: string[], { triggerReactivity = false } = {}): void => {
    ids.forEach(_remove)
    if (triggerReactivity) {
      triggerReactiveUpdate()
    }
  }

  const reset = ({ triggerReactivity = false } = {}): void => {
    items.value = []
    if (triggerReactivity) {
      triggerReactiveUpdate()
    }
  }

  return {
    items,
    create,
    add,
    addBatch,
    remove,
    removeBatch,
    findIndex,
    reset,
    update,
  }
}
