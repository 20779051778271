import type { Directive } from 'vue'

type SpinnerOptions = {
  active: boolean // Controls visibility
  backgroundColor: string
  animation: string
  delay: string
  size: 'large' | 'medium' | 'small'
  theme: 'grey' | 'dark'
  label?: string
}

/**
 * Retrieves the spinner element from the given element.
 * @param {HTMLElement} element - The parent element to search within.
 * @returns {HTMLElement | null} - The found spinner element or null if not found.
 */
const getSpinner = (element: HTMLElement): HTMLElement | null =>
  element.querySelector(':scope > .v-loading') as HTMLElement | null

/**
 * Combines user-provided spinner options with default settings.
 * @param {Partial<SpinnerOptions>} userOptions - Custom options provided by the user.
 * @returns {SpinnerOptions} - The combined spinner configuration.
 */
const options = (userOptions: Partial<SpinnerOptions> = {}): SpinnerOptions => ({
  active: false,
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  animation: '0.5s',
  delay: '0s',
  size: 'medium',
  theme: 'grey',
  ...userOptions,
})

/**
 * Constructs and returns a new spinner element configured based on provided options.
 * @param {SpinnerOptions} opts - Configuration options for the spinner's appearance and behavior.
 * @returns {HTMLElement} - A new spinner element ready to be inserted into the DOM.
 */
const createSpinner = (opts: SpinnerOptions): HTMLElement => {
  const box = document.createElement('div')
  box.className = `v-loading v-loading--${opts.size} v-loading--${opts.theme}`
  box.style.backgroundColor = opts.backgroundColor
  box.style.opacity = '0'
  box.style.visibility = 'hidden'
  box.style.transition = `opacity ${opts.animation} ${opts.delay}`

  const spinnerHtml = `
    <div class="mul5">
      <div class="mul5circle1"></div>
      <div class="mul5circle2"></div>
      <div class="mul5circle3"></div>
    </div>
  `

  box.innerHTML = opts.label ? `${spinnerHtml}<label>${opts.label}</label>` : spinnerHtml
  if (opts.label) {
    box.className += ' v-loading--with-label'
  }

  return box
}

/**
 * Controls the display of the spinner, either adding it to the DOM or removing it,
 * based on the specified 'show' flag. Manages positioning and visibility transitions.
 * @param {HTMLElement} el - The element to which the spinner is to be attached.
 * @param {boolean} show - Determines whether the spinner should be shown or hidden.
 * @param {SpinnerOptions} opts - Spinner configuration options.
 */
const showSpinner = (el: HTMLElement, show: boolean, opts: SpinnerOptions): void => {
  if (!show) {
    const existingSpinner = getSpinner(el)
    if (existingSpinner) {
      existingSpinner.style.opacity = '0'
      setTimeout(
        () => {
          if (el.contains(existingSpinner)) {
            el.removeChild(existingSpinner)
          }
        },
        parseFloat(opts.animation) * 1000,
      ) // Convert the animation duration to milliseconds
    }
    if (el.dataset.static) {
      el.style.removeProperty('position')
    }
    return // Early return if not showing the spinner
  }

  // Early return if spinner already exists
  // to avoid showing multiple spinners at once
  const existingSpinner = getSpinner(el)
  if (existingSpinner) {
    return
  }

  const style = window.getComputedStyle(el)
  const position = style.getPropertyValue('position')
  if (position === 'static' || position === '') {
    el.dataset.static = 'true'
    el.style.position = 'relative'
  }

  const spinner = createSpinner(opts)
  el.appendChild(spinner)
  requestAnimationFrame(() => {
    spinner.style.opacity = '1'
    spinner.style.visibility = 'visible'
  })
}

// Vue directive definition
const vLoadingDirective: Directive<HTMLElement, Partial<SpinnerOptions>> = (el, binding) => {
  const opts = options(binding.value)
  if (binding.value && binding.value.active) {
    showSpinner(el, true, opts)
  } else {
    showSpinner(el, false, opts)
  }
}

export default vLoadingDirective
