import type { InferenceResult, ParsedInferenceData } from '@/modules/Editor/backend'
import { WindAuthAction } from '@/backend/darwin/WindAuthAction'
import type { ApiResult } from '@/backend/darwin/types'
import { errorMessages } from '@/backend/error'

import { withAuth } from './api'
import type { RunInferenceResponse } from './types'

type Params = { teamId: number; modelId: string } & ParsedInferenceData

/**
 * Run inference on an image using a model.
 *
 * Note that although SAM embeddings use the same Wind endpoint, these
 * should instead use the `getEmbeddings` function to be properly typed.
 */
export const runModelInference = <ModelInferenceType = InferenceResult>({
  teamId,
  modelId,
  ...inferenceData
}: Params): Promise<ApiResult<RunInferenceResponse<ModelInferenceType>>> => {
  const authParams = { action: WindAuthAction.RunInference, teamId }

  return withAuth(
    authParams,
    (client) =>
      client.post<RunInferenceResponse<ModelInferenceType>>(
        `models/${modelId}/infer`,
        inferenceData,
      ),
    errorMessages.NEURAL_MODEL_INFER,
  )
}
