import {
  getColumnLines,
  getCompoundPathFromSimpleTable,
  getRowLines,
} from '@/modules/Editor/annotationTypes/simpleTable'
import { DEFAULT_LINE_WIDTH } from '@/modules/Editor/config'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import { createEditablePoint } from '@/modules/Editor/point'
import { drawPathV2, drawSegmentV2 } from '@/modules/Editor/graphics'
import { inferCurrentAnnotationData } from '@/modules/Editor/inferCurrentAnnotationData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { DrawCallback } from '@/modules/Editor/models/layers/types'
import type { View } from '@/modules/Editor/views/view'

export const renderSimpleTable = (
  drawFn: DrawCallback,
  view: View,
  annotation: Annotation,
  inferred: boolean,
  filter: ImageManipulationFilter,
): void => {
  const isSelected = view.annotationManager.isSelected(annotation.id)
  const isHighlighted = view.annotationManager.isHighlighted(annotation.id)
  const data = inferCurrentAnnotationData(annotation, view.currentFrameIndex)
  const compoundPath = getCompoundPathFromSimpleTable(data)
  const rowLines = getRowLines(data)
  const colLines = getColumnLines(data)
  const color = annotation.color

  drawPathV2(
    drawFn,
    compoundPath,
    view.camera,
    { ...color, a: 0.2 },
    inferred,
    filter,
    isHighlighted,
    isSelected,
  )

  for (const rowLine of rowLines) {
    drawSegmentV2(
      drawFn,
      DEFAULT_LINE_WIDTH,
      view.camera.scale,
      createEditablePoint(rowLine.left),
      createEditablePoint(rowLine.right),
      color,
      filter,
      isSelected,
    )
  }

  for (const colLine of colLines) {
    drawSegmentV2(
      drawFn,
      DEFAULT_LINE_WIDTH,
      view.camera.scale,
      createEditablePoint(colLine.top),
      createEditablePoint(colLine.bottom),
      color,
      filter,
      isSelected,
    )
  }
}
