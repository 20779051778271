import type { ApiResponse, Login2FAResponsePayload, LoginResponsePayload } from '@/store/types'
import { post } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

type Params = {
  email: string
  password: string
}

export const login = async (
  params: Params,
): Promise<ApiResponse<Login2FAResponsePayload | LoginResponsePayload> | ParsedError> => {
  const { email, password } = params
  const path = 'users/authenticate'

  try {
    const response = await post<Login2FAResponsePayload | LoginResponsePayload>(path, {
      email,
      password,
    })
    return { data: response.data }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    if (error.response?.status === 403) {
      const reason = error.response?.data.errors.message
      return parseError(
        error,
        typeof reason === 'string' ? { default: reason } : errorMessages.AUTH_LOGIN,
      )
    }

    return parseError(error, errorMessages.AUTH_LOGIN)
  }
}
