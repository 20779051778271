import type { Sort } from './sort'

export const prepareSort = (sort: Sort): Record<string, string> => {
  // V2 does not support sorting by inserted_at. Instead, it supports
  // sorting by ID, which then amounts to the same thing as inserted_at
  // However, the default work prioritization for all datasets is still by
  // inserted at, which is used as a default sort option in the UI
  // This is why we modify the sort before sending it out.
  // Track the status of this in https://linear.app/v7labs/issue/IO-772
  // We only ever apply the sort when loading item ids, so this should be safe to
  // stay here for now, but we may get to a point where we need to move this to
  const normalizedProperty = sort.property.replace('inserted_at', 'id')

  return { [normalizedProperty]: sort.direction }
}
