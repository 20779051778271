import type { Editor } from '@/modules/Editor/editor'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'
import { isRadiologicalView } from '@/modules/Editor/utils/isRadiologicalView'

import { setupPanning } from './setupPanning'
import { setupStackScroll } from './setupStackScroll'
import { setupZoom } from './setupZoom'

/**
 * This file defines "loadouts", which define what tools get attached to:
 * -- Primary Click
 * -- Middle Click
 * -- Right Click
 * -- Mouse Wheel Scroll
 * when a tool is attached to primary click from tool activation.
 *
 * Primary + Middle Click:
 * -- Some tools add pan to primary and some to middle click, when in use.
 *
 * Right Click + Mouse Wheel Scroll:
 * -- These depends on the view context.
 * ---- The default is as follows:
 * ------ Mouse Wheel Scroll: Zooms the image in and out.
 * ------ Right Click: Does nothing.
 * ---- For instance, for Radiological layouts we use controls typical for Radiology, these are:
 * ------ Mouse Wheel Scroll: Scrolls the "stack" of medical images
 *        (this is akin to scrubbing through slices in video)
 * ------ Right Click: Zooms the image in and out when dragging (zooms towards the
 *        selected image point)
 *
 * Other loadouts could exist in the future, for specific use cases, we can filter based
 * on the layout and file types present in the views.
 */

export type PanningBindings = {
  primary?: boolean
  middle?: boolean
}

/**
 * Sets up the radiological mouse loadout used for Radiology.
 *
 * @param context The ToolContext object which gives access to the editor as well an interface
 * to setup handlers.
 * @param panPrimary Whether to add pan to the primary button as well as the middle one.
 */
const setupRadiologicalLoadout = (context: ToolContext, panPrimary?: boolean): void => {
  if (panPrimary) {
    setupPanning.primary(context)
  }
  setupPanning.middle(context)
  setupStackScroll.wheel(context)
  setupZoom.secondary(context)
  setupPanning.touch(context)
  setupPanning.key(context)
}

/**
 * Sets up the default loadout used for image, video and tiled images.
 * @param context The ToolContext object which gives access to the editor as well an interface
 * to setup handlers.
 * @param panning Which buttons to activate pan on.
 */
const defaultLoadout = (context: ToolContext, panning: PanningBindings): void => {
  if (panning.primary) {
    setupPanning.primary(context)
  }

  if (panning.middle) {
    setupPanning.middle(context)
  }

  setupZoom.wheel(context)
  setupPanning.touch(context)
  setupPanning.key(context)
}

const useRadiologicalLoadout = (editor: Editor): boolean => {
  if (editor.featureFlags.LEGACY_DICOM) {
    return false
  }

  const slotTypes = editor.slotTypes
  return isRadiologicalView(slotTypes)
}

/**
 * Sets up the mouse button tools required for a specific active tool in a specific view context.
 *
 * @param context The ToolContext object which gives access to the editor as well an interface
 * to setup handlers.
 * @param panning Which buttons to activate pan on. Note Radiological loadout always has pan on
 * middle click, can optionally have it on primary.
 */
export const setupMouseButtonLoadout = (context: ToolContext, panning: PanningBindings): void => {
  if (useRadiologicalLoadout(context.editor)) {
    setupRadiologicalLoadout(context, panning.primary)
  } else {
    defaultLoadout(context, panning)
  }
}
