import type { ExtendedFileMetadata } from '@/modules/Editor/metadata'
import type { IPoint } from '@/modules/Editor/point'
import type { VoxelRasterDimensions } from '@/modules/Editor/models/raster/VoxelRaster'
import { getRasterPlaneFromRadiologySlotName } from '@/modules/Editor/utils/raster/getRasterPlaneFromRadiologySlotName'
import { Plane } from '@/modules/Editor/utils/raster/Plane'

export const getXYVoxelScaling = (
  slotName: string,
  metadata: ExtendedFileMetadata,
  voxelRasterDimensions: VoxelRasterDimensions,
): IPoint | undefined => {
  const { medical: medicalMetadata } = metadata

  if (!medicalMetadata || !voxelRasterDimensions) {
    return
  }

  const plane = getRasterPlaneFromRadiologySlotName(slotName, metadata)

  let inPlanePixelDimensions: IPoint | undefined = undefined

  if (plane === Plane.Z) {
    // XY
    inPlanePixelDimensions = { x: voxelRasterDimensions.width, y: voxelRasterDimensions.height }
  }

  if (plane === Plane.X) {
    // YZ
    inPlanePixelDimensions = { x: voxelRasterDimensions.height, y: voxelRasterDimensions.depth }
  }

  if (plane === Plane.Y) {
    // XZ
    inPlanePixelDimensions = { x: voxelRasterDimensions.width, y: voxelRasterDimensions.depth }
  }

  if (!inPlanePixelDimensions) {
    return
  }

  const { width, height } = metadata

  return { x: width / inPlanePixelDimensions.x, y: height / inPlanePixelDimensions.y }
}
