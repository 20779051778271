import type { components } from '@/backend/darwin/api'
import type { V2DatasetItemLayout } from '@/store/types/V2DatasetItemLayout'

export const isLayoutV3 = (
  layout: V2DatasetItemLayout,
): layout is components['schemas']['DatasetsV2.Common.ItemLayoutV3'] => layout?.version === 3

export const isLayoutV2 = (
  layout: V2DatasetItemLayout,
): layout is components['schemas']['DatasetsV2.Common.ItemLayoutV3'] => layout?.version === 2

export const isLayoutV1 = (
  layout: V2DatasetItemLayout,
): layout is components['schemas']['DatasetsV2.Common.ItemLayoutV3'] => layout?.version === 1
