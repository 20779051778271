import { defineStore } from 'pinia'
import { shallowRef, triggerRef, computed, ref } from 'vue'

import { PersistedSettings } from '@/core/utils/PersistedSettings'
import { useFeatureFlagsStore } from '@/pinia/useFeatureFlagsStore'
import {
  ITEMS_GALLERY_COLLAPSED,
  RAD_VIEW_GALLERY_COLLAPSED,
  SHOULD_RENDER_MEASURES,
  SHOULD_RENDER_SUB_ANNOTATIONS,
  SYNC_VIDEO_PLAYBACK,
  VIDEO_FRAME_JUMP_STEP,
  VIDEO_PLAYBACK_LOOP,
  VIDEO_PLAYBACK_SPEED,
  VIDEO_TIMELINE_GALLERY_COLLAPSED,
  ANNOTATIONS_PAGE_SIZE,
  FRAMES_PRELOAD_SIZE,
} from '@/utils/localStorageKeys'
import type { PartialRecord } from '@/core/helperTypes'

const DEFAULT_PLAYBACK_SPEED = 1
const DEFAULT_PLAYBACK_LOOP = false
const DEFAULT_SYNC_VIDEO_PLAYBACK = false
const DEFAULT_RAD_VIEW_GALLERY_COLLAPSED = false
const DEFAULT_VIDEO_TIMELINE_GALLERY_COLLAPSED = false
const DEFAULT_ITEMS_GALLERY_COLLAPSED = false
const DEFAULT_SHOULD_RENDER_MEASURES = false
const DEFAULT_SHOULD_RENDER_SUB_ANNOTATIONS = true
const DEFAULT_VIDEO_FRAME_JUMP_STEP = 5

/**
 * Store to reuse workview settings across components.
 * TODO: all current workview related settings should be moved here.
 */
export const useWorkviewSettingsStore = defineStore('workviewSettings', () => {
  const featureStore = useFeatureFlagsStore()
  const persistedSettings = new PersistedSettings('localStorage')

  /**
   * This flag relates to the url slot name and frame index at first load. After it is applied,
   * then the flag should be set to true
   **/
  const hasSetInitialSlotAndFrame = ref(false)
  const activeSlotName = ref('')
  const multiSlotFrameIndexes = ref<PartialRecord<string, number>>({})

  const currentPreviewFrameIndex = ref<number | null>(null)

  // Dev configurations for ENABLE_DEV_CONFIGS
  const defaultAnnotationsPageSize = ref<number>(0)
  const setDefaultAnnotationsPageSize = (value: number): void => {
    defaultAnnotationsPageSize.value = value
    persistedSettings.saveValue(ANNOTATIONS_PAGE_SIZE, value)
  }

  // Dev configurations for ENABLE_DEV_CONFIGS
  const defaultFramesPreloadSize = ref<number>(0)
  const setDefaultFramesPreloadSize = (value: number): void => {
    defaultFramesPreloadSize.value = value
    persistedSettings.saveValue(FRAMES_PRELOAD_SIZE, value)
  }

  const setPreviewFrameIndex = (frameIndex: number): void => {
    currentPreviewFrameIndex.value = frameIndex
  }

  const clearPreviewFrameIndex = (): void => {
    currentPreviewFrameIndex.value = null
  }

  /**
   * Expected playback state, not attached to the Editor state
   * NOTE: if the video is not playing and the state here is true it should trigger the video playback
   */
  const videoPlaybackState = shallowRef<PartialRecord<string, boolean>>({})
  const setPlaybackState = (slotName: string, state: boolean): void => {
    videoPlaybackState.value = { ...videoPlaybackState.value, [slotName]: state }
    triggerRef(videoPlaybackState)
  }

  const anySlotIsPlaying = computed(() => Object.values(videoPlaybackState.value).some((v) => v))

  const slotAnnotationsPageLoadingState = shallowRef<PartialRecord<string, boolean>>({})
  const setSlotAnnotationsPageLoadingState = (slotName: string, state: boolean): void => {
    slotAnnotationsPageLoadingState.value = {
      ...slotAnnotationsPageLoadingState.value,
      [slotName]: state,
    }
    triggerRef(slotAnnotationsPageLoadingState)
  }

  const slotNames = computed(() => Object.keys(multiSlotFrameIndexes.value || {}))

  const slotCount = computed(() => slotNames.value.length)

  const currentFrameIndex = computed(() => multiSlotFrameIndexes.value[activeSlotName.value] || 0)

  const setActiveSlotName = (slotName: string): void => {
    activeSlotName.value = slotName
  }

  const clearActiveSlotName = (): void => {
    activeSlotName.value = ''
  }

  const setCurrentFrameIndexForSlot = (frameIndex: number, slotName: string): void => {
    // trick to force triggering reactivity
    multiSlotFrameIndexes.value = { ...multiSlotFrameIndexes.value, [slotName]: frameIndex }
  }

  const clearMultiSlotFrameIndexes = (): void => {
    multiSlotFrameIndexes.value = {}
  }

  /**
   * The range of timeline's frames in the viewport.
   */
  const timelineFramesRange = ref({ from: 0, to: 0 })
  const setTimelineFramesRange = (from: number, to: number): void => {
    timelineFramesRange.value = { from, to }
  }

  const init = (slotsListPayload: { slotName: string; currentFrameIndex: number }[]): void => {
    if (
      !activeSlotName.value ||
      !slotsListPayload.find(({ slotName }) => slotName === activeSlotName.value)
    ) {
      setActiveSlotName(slotsListPayload[0].slotName)
    }
    slotsListPayload.forEach(({ slotName, currentFrameIndex }) => {
      if (multiSlotFrameIndexes.value[slotName] === undefined) {
        setCurrentFrameIndexForSlot(currentFrameIndex, slotName)
      }
    })

    videoPlaybackState.value = {}
    timelineFramesRange.value = { from: 0, to: 0 }

    if (featureStore.featureFlags.ENABLE_DEV_CONFIGS) {
      defaultAnnotationsPageSize.value = persistedSettings.loadValue(ANNOTATIONS_PAGE_SIZE, 0)
      defaultFramesPreloadSize.value = persistedSettings.loadValue(FRAMES_PRELOAD_SIZE, 0)
    }
  }

  /**
   * Bottom drawer collapsed panels
   */
  const isRadViewGalleryDrawerCollapsed = ref(
    persistedSettings.loadValue(RAD_VIEW_GALLERY_COLLAPSED, DEFAULT_RAD_VIEW_GALLERY_COLLAPSED),
  )
  const toggleRadViewGalleryCollapsed = (persist = true): void => {
    const value = !isRadViewGalleryDrawerCollapsed.value
    if (persist) {
      persistedSettings.saveValue(RAD_VIEW_GALLERY_COLLAPSED, value)
    }
    isRadViewGalleryDrawerCollapsed.value = value
  }
  const isVideoTimelineDrawerCollapsed = ref(
    persistedSettings.loadValue(
      VIDEO_TIMELINE_GALLERY_COLLAPSED,
      DEFAULT_VIDEO_TIMELINE_GALLERY_COLLAPSED,
    ),
  )
  const toggleVideoTimelineGalleryCollapsed = (persist = true): void => {
    const value = !isVideoTimelineDrawerCollapsed.value
    if (persist) {
      persistedSettings.saveValue(VIDEO_TIMELINE_GALLERY_COLLAPSED, value)
    }
    isVideoTimelineDrawerCollapsed.value = value
  }
  const isItemsGalleryDrawerCollapsed = ref(
    persistedSettings.loadValue(ITEMS_GALLERY_COLLAPSED, DEFAULT_ITEMS_GALLERY_COLLAPSED),
  )
  const toggleItemsGalleryCollapsed = (persist = true): void => {
    const value = !isItemsGalleryDrawerCollapsed.value
    if (persist) {
      persistedSettings.saveValue(ITEMS_GALLERY_COLLAPSED, value)
    }
    isItemsGalleryDrawerCollapsed.value = value
  }

  /**
   * Right sidebar collapsed
   */

  /** Used to reposition PopupMenus that are attached to the sub sidebar **/
  const subSidebarRepositionKey = ref(0)

  const isRightSidebarCollapsed = ref(false)
  const toggleRightSidebarCollapsed = (): void => {
    isRightSidebarCollapsed.value = !isRightSidebarCollapsed.value
    subSidebarRepositionKey.value++
  }

  /**
   * Focus / Fullscreen mode
   * When toggled, it will toggle all drawers and right sidebar
   */
  const isFocusMode = ref(false)
  const toggleFocusMode = (): void => {
    isFocusMode.value = !isFocusMode.value

    if (isRightSidebarCollapsed.value !== isFocusMode.value) {
      toggleRightSidebarCollapsed()
    }
    if (isRadViewGalleryDrawerCollapsed.value !== isFocusMode.value) {
      toggleRadViewGalleryCollapsed(false)
    }
    if (isVideoTimelineDrawerCollapsed.value !== isFocusMode.value) {
      toggleVideoTimelineGalleryCollapsed(false)
    }
    if (isItemsGalleryDrawerCollapsed.value !== isFocusMode.value) {
      toggleItemsGalleryCollapsed(false)
    }
  }

  /**
   * Sync video playback
   *
   * Allow to play all multi-slot video items in sync and keep them in
   * sync when the user performs frame jumps.
   * Note: this doesn't mean they will have all the same frame index, but rather
   * they will be synched by timestamp, therefore this feature requries LONG_VIDEOS
   * to actually work.
   */
  const syncVideoPlayback = ref(
    persistedSettings.loadValue(SYNC_VIDEO_PLAYBACK, DEFAULT_SYNC_VIDEO_PLAYBACK),
  )

  const toggleSyncVideoPlayback = (): void => {
    const value = !syncVideoPlayback.value
    persistedSettings.saveValue(SYNC_VIDEO_PLAYBACK, value)
    syncVideoPlayback.value = value
  }

  const frameJumpStep = ref(
    persistedSettings.loadValue<number>(VIDEO_FRAME_JUMP_STEP, DEFAULT_VIDEO_FRAME_JUMP_STEP),
  )
  const setFrameJumpStep = (step: number): void => {
    frameJumpStep.value = step
    persistedSettings.saveValue(VIDEO_FRAME_JUMP_STEP, step)
  }

  /** useEditorV2 listens to videoPlaybackSpeed and updates the editor when changed **/
  const videoPlaybackSpeed = ref(
    persistedSettings.loadValue<number>(VIDEO_PLAYBACK_SPEED, DEFAULT_PLAYBACK_SPEED),
  )
  const setVideoPlaybackSpeed = (speed: number): void => {
    videoPlaybackSpeed.value = speed
    persistedSettings.saveValue(VIDEO_PLAYBACK_SPEED, speed)
  }

  /** Setting to control wether a video restarts from the beginning when reaching the end */
  const videoPlaybackLoop = ref(
    persistedSettings.loadValue<boolean>(VIDEO_PLAYBACK_LOOP, DEFAULT_PLAYBACK_LOOP),
  )
  const toggleVideoPlaybackLoop = (): void => {
    videoPlaybackLoop.value = !videoPlaybackLoop.value
    persistedSettings.saveValue(VIDEO_PLAYBACK_LOOP, videoPlaybackLoop.value)
  }

  const renderMeasures = ref(
    persistedSettings.loadValue(SHOULD_RENDER_MEASURES, DEFAULT_SHOULD_RENDER_MEASURES),
  )
  const toggleRenderedMeasures = (): void => {
    const value = !renderMeasures.value
    renderMeasures.value = value
    persistedSettings.saveValue(SHOULD_RENDER_MEASURES, value)
  }

  const renderSubAnnotations = ref(
    persistedSettings.loadValue(
      SHOULD_RENDER_SUB_ANNOTATIONS,
      DEFAULT_SHOULD_RENDER_SUB_ANNOTATIONS,
    ),
  )
  const toggleRenderedSubAnnotations = (): void => {
    const value = !renderSubAnnotations.value
    renderSubAnnotations.value = value
    persistedSettings.saveValue(SHOULD_RENDER_SUB_ANNOTATIONS, value)
  }

  const showAttributesInputPopoverForAnnId = ref<string | null>(null)
  const showAttributesInputPopoverFor = (annId: string): void => {
    showAttributesInputPopoverForAnnId.value = annId
  }
  const hideAttributesInputPopover = (): void => {
    showAttributesInputPopoverForAnnId.value = null
  }

  const showInstanceIdInputPopoverForAnnId = ref<string | null>(null)
  const showInstanceIdInputPopoverFor = (annId: string): void => {
    showInstanceIdInputPopoverForAnnId.value = annId
  }
  const hideInstanceIdInputPopover = (): void => {
    showInstanceIdInputPopoverForAnnId.value = null
  }

  const showTextInputPopoverForAnnId = ref<string | null>(null)
  const showTextInputPopoverFor = (annId: string): void => {
    showTextInputPopoverForAnnId.value = annId
  }
  const hideTextInputPopover = (): void => {
    showTextInputPopoverForAnnId.value = null
  }

  return {
    init,

    activeSlotName,
    slotNames,
    slotCount,
    multiSlotFrameIndexes,
    currentFrameIndex,
    hasSetInitialSlotAndFrame,
    setActiveSlotName,
    clearActiveSlotName,
    setCurrentFrameIndexForSlot,
    clearMultiSlotFrameIndexes,
    currentPreviewFrameIndex,
    setPreviewFrameIndex,
    clearPreviewFrameIndex,

    isRadViewGalleryDrawerCollapsed,
    toggleRadViewGalleryCollapsed,
    isVideoTimelineDrawerCollapsed,
    toggleVideoTimelineGalleryCollapsed,
    isItemsGalleryDrawerCollapsed,
    toggleItemsGalleryCollapsed,
    isRightSidebarCollapsed,
    toggleRightSidebarCollapsed,
    isFocusMode,
    toggleFocusMode,
    subSidebarRepositionKey,

    frameJumpStep,
    setFrameJumpStep,

    syncVideoPlayback,
    toggleSyncVideoPlayback,

    videoPlaybackSpeed,
    setVideoPlaybackSpeed,

    videoPlaybackLoop,
    toggleVideoPlaybackLoop,

    // Expected playback state
    videoPlaybackState,
    setPlaybackState,
    anySlotIsPlaying,

    // Loading state
    slotAnnotationsPageLoadingState,
    setSlotAnnotationsPageLoadingState,

    // Render measures
    renderMeasures,
    toggleRenderedMeasures,

    // Render sub-anntoations
    renderSubAnnotations,
    toggleRenderedSubAnnotations,

    // Sub annotation attributes input
    showAttributesInputPopoverForAnnId,
    showAttributesInputPopoverFor,
    hideAttributesInputPopover,

    // Sub annotation instance id input
    showInstanceIdInputPopoverForAnnId,
    showInstanceIdInputPopoverFor,
    hideInstanceIdInputPopover,

    // Sub annotation text input
    showTextInputPopoverForAnnId,
    showTextInputPopoverFor,
    hideTextInputPopover,

    // Dev configurations
    defaultAnnotationsPageSize,
    setDefaultAnnotationsPageSize,
    defaultFramesPreloadSize,
    setDefaultFramesPreloadSize,

    timelineFramesRange,
    setTimelineFramesRange,
  }
})
