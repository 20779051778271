import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { isRightMouseButton } from '@/modules/Editor/mouse'
import type { IPoint } from '@/modules/Editor/point'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'

import { touchDistance, touchMiddlePoint } from './utils'

type ZoomingContext = {
  zoomCursorStart: IPoint | null
}

function setupWheelZoom(context: ToolContext): void {
  let cursorPoint: IPoint | null = null
  let prevScale: number = 1

  let pinchStart: IPoint | null = null
  let prevPinchWidth: number = 1

  context.handles.push(
    ...context.editor.onMouseMove((event) => {
      cursorPoint = { x: event.offsetX, y: event.offsetY }
    }),
  )

  context.handles.push(
    ...context.editor.onWheel((event) => {
      if (cursorPoint === null) {
        return
      }

      event.preventDefault()
      if (event.shiftKey) {
        context.editor.activeView.camera.scroll({ x: event.deltaX, y: event.deltaY })
      } else {
        const zoomLevel = Math.max(Math.min(Math.exp(-event.deltaY / 100), 1.4), 0.6)
        context.editor.activeView.camera.zoom(zoomLevel, cursorPoint)
      }
      return CallbackStatus.Stop
    }),
  )

  context.handles.push(
    ...context.editor.onGestureStart((event) => {
      event.preventDefault()
      prevScale = (event as unknown as { scale: number }).scale
    }),
  )

  context.handles.push(
    ...context.editor.onGestureChange((event) => {
      if (cursorPoint === null) {
        return
      }
      const scale = (event as unknown as { scale: number }).scale
      event.preventDefault()
      context.editor.activeView.camera.zoom(scale / prevScale, cursorPoint)
      prevScale = scale
      return CallbackStatus.Stop
    }),
  )

  context.handles.push(
    ...context.editor.onGestureEnd((event) => {
      event.preventDefault()
    }),
  )

  context.handles.push(
    ...context.editor.onTouchStart((event) => {
      event.preventDefault()

      const length = event.targetTouches.length
      if (length !== 2) {
        return
      }

      pinchStart = touchMiddlePoint(event)
      prevPinchWidth = touchDistance(event) || 1

      return CallbackStatus.Stop
    }),
  )

  context.handles.push(
    ...context.editor.onTouchMove((event) => {
      event.preventDefault()

      if (!pinchStart) {
        return
      }

      const pinchWidth = touchDistance(event)
      if (!pinchWidth) {
        return
      }

      context.editor.activeView.camera.zoom(pinchWidth / prevPinchWidth, pinchStart)
      prevPinchWidth = pinchWidth

      return CallbackStatus.Stop
    }),
  )

  context.handles.push(
    ...context.editor.onTouchEnd((event) => {
      event.preventDefault()

      pinchStart = null
    }),
  )
}

const enterMouseZooming = (event: MouseEvent, zoomingContext: ZoomingContext): void => {
  event.preventDefault()

  zoomingContext.zoomCursorStart = { x: event.offsetX, y: event.offsetY }
}

const exitMouseZooming = (
  event: MouseEvent,
  zoomingContext: ZoomingContext,
  toolContext: ToolContext,
): CallbackStatus => {
  if (!toolContext.editor.activeView.mainLayer.canvas) {
    return CallbackStatus.Continue
  }

  event.preventDefault()
  toolContext.editor.activeView.mainLayer.canvas.ownerDocument.exitPointerLock()

  zoomingContext.zoomCursorStart = null

  return CallbackStatus.Stop
}

const doMouseZooming = (
  event: MouseEvent,
  zoomingContext: ZoomingContext,
  context: ToolContext,
): CallbackStatus => {
  const cursorPoint = zoomingContext.zoomCursorStart

  if (cursorPoint === null) {
    return CallbackStatus.Continue
  }

  const deltaY = event.movementY
  const zoomLevel = Math.max(Math.min(Math.exp(deltaY / 100), 1.4), 0.6)

  context.editor.activeView.camera.zoom(zoomLevel, cursorPoint)

  return CallbackStatus.Stop
}

const setupSecondaryButtonZoom = (context: ToolContext): void => {
  const zoomingContext: ZoomingContext = {
    zoomCursorStart: null,
  }

  context.handles.push(
    ...context.editor.onMouseDown((event) => {
      // we do not initiate unless the middle mouse button is pressed
      if (!isRightMouseButton(event)) {
        return CallbackStatus.Continue
      }

      return enterMouseZooming(event, zoomingContext)
    }),
  )

  context.handles.push(
    ...context.editor.onMouseMove((event) => doMouseZooming(event, zoomingContext, context)),
  )

  context.handles.push(
    ...context.editor.onMouseUp((event) => {
      // we do not initiate unless the middle mouse button is pressed
      if (!isRightMouseButton(event)) {
        return CallbackStatus.Continue
      }

      return exitMouseZooming(event, zoomingContext, context)
    }),
  )
}

const setupZoom = {
  secondary: setupSecondaryButtonZoom,
  wheel: setupWheelZoom,
}

export { setupZoom }
