import { defineStore } from 'pinia'
import { computed, shallowRef, toRef, watch } from 'vue'

import { useStore } from '@/store/useStore'
import { useToast } from '@/uiKit/Toast/useToast'
import type { addInvitations } from '@/store/modules/team/actions/addInvitations'
import type { deleteInvitation } from '@/store/modules/team/actions/deleteInvitation'
import type { deleteMembership } from '@/store/modules/team/actions/deleteMembership'
import type { getInvitations } from '@/store/modules/team/actions/getInvitations'
import type { updateInvitation } from '@/store/modules/team/actions/updateInvitation'
import type { updateMembership } from '@/store/modules/team/actions/updateMembership'
import type {
  InvitationPayload,
  MembershipPayload,
  MembershipRole,
  StoreActionPayload,
  StoreActionResponse,
  TeamPayload,
  UserPayload,
} from '@/store/types'
import { getFullName } from '@/core/utils/formatter'
import type { PartialRecord } from '@/core/helperTypes'
import { useWorkviewV3TrackerStore } from '@/modules/Workview/useWorkviewV3TrackerStore'

const getRelevantTeamMemberships = (
  currentTeam: TeamPayload,
  memberships: MembershipPayload[],
): MembershipPayload[] => {
  // partner and regular teams just filter their own members

  if (currentTeam.managed_status !== 'client') {
    return memberships.filter((m) => m.team_id === currentTeam.id)
  }

  // client teams also filter their partner's members
  // if a user is a member in both partner and client, the client membership is used
  const ownMemberships = memberships.filter((m) => m.team_id === currentTeam.id)

  const ownUserIds = ownMemberships.map((m) => m.user_id)
  const partnerMembershipsNotInOwn = memberships.filter(
    (m) => m.team_id === currentTeam.partner_id && !ownUserIds.includes(m.user_id),
  )

  return ownMemberships.concat(partnerMembershipsNotInOwn)
}

/**
 * The main store for all team related data, to be used with any new code.
 *
 * Serves as a proxy to vuex, but aims to fully phase out vuex in the future.
 */

export const useTeamStore = defineStore('team', () => {
  const store = useStore()
  const { state, dispatch } = store
  const toast = useToast()
  const workviewV3Tracker = useWorkviewV3TrackerStore()

  const invitations = toRef(state.team, 'invitations')

  const currentTeam = toRef(state.team, 'currentTeam')
  // Keep reactivity when currentTeam changes
  // Safe to remove when we fully migrate to pinia and there are no dependencies to Vuex
  store.subscribe((mutation) => {
    if (mutation.type === 'team/SET_CURRENT_TEAM') {
      currentTeam.value = mutation.payload
      workviewV3Tracker.setWorklogVersion(currentTeam.value?.work_log_version)
    }
  })

  const currentTeamIsClient = computed<boolean>(
    () => currentTeam.value?.managed_status === 'client',
  )

  const allRelevantMemberships = computed(() =>
    currentTeam.value ? getRelevantTeamMemberships(currentTeam.value, state.team.memberships) : [],
  )

  const currentTeamMemberships = computed(() =>
    allRelevantMemberships.value.filter((m) => m.team_id === currentTeam.value?.id),
  )

  const membershipInRelevantTeamsByUserId = (userId: number): MembershipPayload | null => {
    const { currentTeam, memberships } = state.team
    const relevantTeamIds: number[] = []

    if (currentTeam) {
      relevantTeamIds.push(currentTeam.id)
    }

    if (currentTeam?.managed_status === 'client' && currentTeam.partner_id) {
      relevantTeamIds.push(currentTeam.partner_id)
    }

    if (currentTeam?.managed_status === 'partner' && currentTeam.clients) {
      relevantTeamIds.concat(currentTeam.clients.map((t) => t.id))
    }

    return (
      memberships.find((m) => relevantTeamIds.includes(m.team_id) && m.user_id === userId) || null
    )
  }

  /**
   * Used to match a team member currently in the store by their team and user id
   */
  const membershipByTeamIdUserId = (teamId: number, userId: number): MembershipPayload | null =>
    state.team.memberships.find((m) => m.team_id === teamId && m.user_id === userId) || null

  /**
   * Dispatches a request to delete a memebership by the given ID
   *
   * It will show a toast notification on success or error and always return void
   */
  const deleteMembershipById = async (membershipId: number): Promise<void> => {
    const payload: StoreActionPayload<typeof deleteMembership> = { id: membershipId }
    const response: StoreActionResponse<typeof deleteMembership> = await dispatch(
      'team/deleteMembership',
      payload,
    )

    if ('error' in response) {
      const message =
        typeof response.error.message === 'string'
          ? response.error.message
          : 'Something went wrong while removing the user from the team'
      toast.warning({ meta: { title: message } })
      return
    }

    toast.success({ meta: { title: 'Member successfully removed' } })
  }

  /**
   * Dispatches a request to the role of a membership with the given id
   */
  const updateMembershipRole = async (
    membershipId: number,
    newRole: MembershipRole,
  ): Promise<{ success: boolean }> => {
    const payload: StoreActionPayload<typeof updateMembership> = {
      id: membershipId,
      role: newRole,
    }

    const result: StoreActionResponse<typeof updateMembership> = await dispatch(
      'team/updateMembership',
      payload,
    )

    if ('error' in result) {
      // convoluted type guarding due to convoluted type definitions in vuex store
      // once we make it better, we can simplify this
      const content =
        'isValidationError' in result.error && result.error.isValidationError && result.error?.role
          ? result.error.role
          : result.error.message

      if (typeof content === 'string') {
        toast.warning({ meta: { title: content } })
      }

      return { success: false }
    }

    return { success: true }
  }

  /**
   * Dispatches a request to get invitations for a team.
   */
  const getUnconfirmedInvitations = async (): Promise<void> => {
    if (!currentTeam.value) {
      return
    }

    const params: StoreActionPayload<typeof getInvitations> = {
      teamId: currentTeam.value.id,
    }

    await dispatch('team/getInvitations', params)
  }

  /**
   * Dispatches a request to create an invitation for a user to join a team.
   *
   * The invitation requires an email and a role.
   *
   * The "owner" role cannot be assigned via invitation. Instead, the team owner
   * is expected to transfer ownership to another team member.
   *
   * It returns undefined if the request is succesful and an object with
   * validation errors if not.
   */
  const createInvitation = async (
    email: string,
    role: MembershipRole,
  ): Promise<void | { email?: string; role?: string }> => {
    if (!currentTeam.value) {
      return
    }
    const params: StoreActionPayload<typeof addInvitations> = {
      teamId: currentTeam.value.id,
      invitations: [{ email, role }],
    }

    const response: StoreActionResponse<typeof addInvitations> = await dispatch(
      'team/addInvitations',
      params,
    )

    if ('error' in response) {
      if ('isValidationError' in response.error && response.error.isValidationError) {
        const errors: { email?: string; role?: string } = {}

        if (typeof response.error.email === 'string') {
          errors.email = response.error.email
        }

        if (typeof response.error.role === 'string') {
          errors.role = response.error.role
        }

        return errors
      }

      const message =
        typeof response.error.message === 'string' ? response.error.message : 'Something went wrong'

      toast.warning({ meta: { title: message } })

      // we have to return something in case of non-validation errors
      // this is not optimal, but it's legacy. once we cleanup, we can improve the API here
      return {}
    }

    toast.success({ meta: { title: 'Invitation successfully sent' } })
  }

  /**
   * Dispatches a request to delete the invitation with the specified ID.
   *
   * It will dispatch an error if it fails, but will always return void
   */
  const deleteInvitationById = async (invitationId: InvitationPayload['id']): Promise<void> => {
    const payload: StoreActionPayload<typeof deleteInvitation> = { id: invitationId }
    const response: StoreActionResponse<typeof deleteInvitation> = await dispatch(
      'team/deleteInvitation',
      payload,
    )

    if ('error' in response) {
      const message =
        typeof response.error.message === 'string'
          ? response.error.message
          : 'Something went wrong while deleting invitation'
      toast.warning({ meta: { title: message } })
      return
    }

    toast.success({ meta: { title: 'Invitation successfully revoked' } })
  }

  /**
   * Dispatches a request to update the role of a given invitation
   *
   * It will dispatch an error if it fails, but will always return void
   */
  const updateInvitationRole = async (
    invitationId: number,
    newRole: MembershipRole,
  ): Promise<void> => {
    const payload: StoreActionPayload<typeof updateInvitation> = {
      id: invitationId,
      role: newRole,
    }

    const response: StoreActionResponse<typeof updateInvitation> = await dispatch(
      'team/updateInvitation',
      payload,
    )

    if ('error' in response) {
      const message =
        typeof response.error.message === 'string'
          ? response.error.message
          : 'Something went wrong while updating invitation role'

      toast.warning({ meta: { title: message } })
    }
  }

  const membershipsByTeamId = (teamId: number): MembershipPayload[] =>
    state.team.memberships.filter((m) => m.team_id === teamId)

  const setTeamMemberAvatar = (user: UserPayload): void => {
    store.commit('team/SET_MEMBER_AVATAR_URL', user)
  }

  const memberIdByUserId = shallowRef<PartialRecord<number, number>>({})
  const memberNameById = shallowRef<PartialRecord<number, string>>({})
  const memberAvatarUrlById = shallowRef<PartialRecord<number, string>>({})
  watch(
    () => [currentTeam.value?.id, currentTeam.value?.partner_id, state.team.memberships.length],
    (newValues, oldValues) => {
      if (oldValues) {
        const [newTeamId, newPartnerId, newMembershipsLength] = newValues
        const [oldTeamId, oldPartnerId, oldMembershipsLength] = oldValues

        // avoid unnecessary calcs
        if (
          newTeamId === oldTeamId &&
          newPartnerId === oldPartnerId &&
          newMembershipsLength === oldMembershipsLength
        ) {
          return
        }
      }

      // clear maps
      memberIdByUserId.value = {}
      memberNameById.value = {}
      memberAvatarUrlById.value = {}

      state.team.memberships.forEach((item: MembershipPayload) => {
        memberIdByUserId.value[item.user_id] = item.id
        memberNameById.value[item.id] = getFullName(item)
        if (!item.image) {
          return
        }
        memberAvatarUrlById.value[item.id] = item.image.thumbnail_url || item.image.url
      })
    },
    { immediate: true },
  )

  return {
    allRelevantMemberships,
    currentTeam,
    currentTeamIsClient,
    membershipByTeamIdUserId,
    membershipInRelevantTeamsByUserId,
    // memberships
    currentTeamMemberships,
    memberIdByUserId,
    memberNameById,
    memberAvatarUrlById,
    deleteMembershipById,
    updateMembershipRole,
    membershipsByTeamId,
    setTeamMemberAvatar,
    // invites
    invitations,
    getUnconfirmedInvitations,
    createInvitation,
    deleteInvitationById,
    updateInvitationRole,
  }
})
