import type { FrameData } from '@/core/annotations'
import type { BBox } from '@/modules/Editor/types'

export const calcSimpleTableBBox = (data: FrameData): BBox | undefined => {
  const { simple_table: simpleTable } = data
  if (!simpleTable) {
    return
  }

  const { bounding_box } = simpleTable

  if (!bounding_box) {
    return
  }
  const { x, y, w, h } = bounding_box

  return {
    x: x + w / 2,
    y: y + h / 2,
    width: w,
    height: h,
  }
}
