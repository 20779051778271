import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { NotificationPayload } from '@/store/types/NotificationPayload'

const compareInsertedAt = (a: NotificationPayload, b: NotificationPayload): number => {
  if (a.inserted_at === b.inserted_at) {
    return 0
  }
  if (a.inserted_at < b.inserted_at) {
    return 1
  }
  return -1
}

const resort = (notifications: NotificationPayload[]): NotificationPayload[] =>
  Array.from(notifications.values()).sort(compareInsertedAt)

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<{ [id: number]: NotificationPayload }>({})
  const sortedNotifications = ref<NotificationPayload[]>([])

  const byTeamUnreadCount = ref<{ [teamId: number]: number }>({})

  const addNotifications = (added: NotificationPayload[]): void => {
    notifications.value = {
      ...notifications.value,
      ...Object.fromEntries(added.map((n) => [n.id, n])),
    }
    const sorted = resort(Object.values(notifications.value))
    sortedNotifications.value = sorted
  }

  const deleteNotifications = (ids: number[]): void => {
    const idsToDelete = new Set(ids)
    notifications.value = Object.fromEntries(
      Object.entries(notifications.value).filter(([id]) => !idsToDelete.has(Number(id))),
    )
    const sorted = resort(Object.values(notifications.value))
    sortedNotifications.value = sorted
  }

  const clearNotifications = (): void => {
    notifications.value = {}
    sortedNotifications.value = []
    byTeamUnreadCount.value = {}
  }

  const setByTeamUreadCount = (data: [number, number][]): void => {
    byTeamUnreadCount.value = Object.fromEntries(data)
  }

  const markRead = (id: number): void => {
    const notification = notifications.value[id]
    if (!notification) {
      return
    }

    notification.is_read = true
    notifications.value = {
      ...notifications.value,
      [id]: notification,
    }
    const sorted = resort(Object.values(notifications.value))
    sortedNotifications.value = sorted
  }

  return {
    addNotifications,
    deleteNotifications,
    clearNotifications,
    setByTeamUreadCount,
    markRead,
    notifications,
    sortedNotifications,
    byTeamUnreadCount,
  }
})
