import { PixelMatrixValues } from './PixelMatrixValues'

const { EXTERIOR } = PixelMatrixValues

export const getPixelOutlines = (
  pixel: [number, number],
  pixelMask: Uint8ClampedArray,
  width: number,
): number[][] => {
  // Note if we always draw clockwise from the top, we will always get the correct order,
  // as we traverse the path clockwise

  const lines: number[][] = []

  const [x, y] = pixel

  const emptyBottom = y - 1 < 0 || pixelMask[(y - 1) * width + x] === EXTERIOR
  const emptyRight = x + 1 >= width || pixelMask[y * width + x + 1] === EXTERIOR
  const emptyTop = y + 1 >= width || pixelMask[(y + 1) * width + x] === EXTERIOR
  const emptyLeft = x - 1 < 0 || pixelMask[y * width + x - 1] === EXTERIOR

  // There is a unique case on the top left of the path where we need to draw left before bottom
  if (emptyBottom && emptyLeft) {
    lines.push([x, y + 1])
    lines.push([x, y])
    lines.push([x, y])
    lines.push([x + 1, y])

    return lines
  }

  // Bottom
  if (emptyBottom) {
    lines.push([x, y])
    lines.push([x + 1, y])
  }
  // Right
  if (emptyRight) {
    lines.push([x + 1, y])
    lines.push([x + 1, y + 1])
  }

  // Top
  if (emptyTop) {
    lines.push([x + 1, y + 1])
    lines.push([x, y + 1])
  }

  // Left
  if (emptyLeft) {
    lines.push([x, y + 1])
    lines.push([x, y])
  }

  return lines
}
