<template>
  <ToastTemplate :variant="variant">
    <div class="minimal-toast__container">
      <div
        class="minimal-toast-icon__wrapper"
        :class="`minimal-toast-icon__wrapper--${variant}`"
      >
        <Icon :name="icon" />
      </div>
      <h3
        class="minimal-toast-status__label"
        :class="`minimal-toast-status__label--${variant}`"
      >
        {{ meta.title }}
      </h3>
    </div>
  </ToastTemplate>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Icon from '@/uiKit/Icon/Icon.vue'
import type { IconNames } from '@/uiKit/Icon/icons'

import ToastTemplate from './ToastTemplate.vue'
import { ToastEvent } from './types'
import type { ToastProps } from './types'

const props = defineProps<{
  variant: ToastProps['variant']
  meta: ToastProps['meta']
}>()

const icon = computed<IconNames>(() => {
  if (props.variant === ToastEvent.SUCCESS) {
    return 'duotone-status-complete'
  }

  if (props.variant === ToastEvent.WARNING || props.variant === ToastEvent.ERROR) {
    return 'duotone-warn'
  }

  return 'duotone-info'
})
</script>

<style lang="scss" scoped>
.minimal-toast__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > * {
    &:first-child {
      margin-right: 4px;
    }
  }
}

.minimal-toast-icon__wrapper {
  &--default {
    color: var(--color-icon-primary);
  }

  &--success {
    color: var(--color-icon-success);
  }

  &--error {
    color: var(--color-icon-critical);
  }

  &--warning {
    color: var(--color-icon-warning);
  }
}

.minimal-toast-status__label {
  @include typography(md-1, inter, 500);
  white-space: pre-line;

  &--default {
    color: var(--color-text-selected);
  }

  &--warning {
    color: var(--color-text-warning);
  }

  &--error {
    color: var(--color-text-critical);
  }

  &--success {
    color: var(--color-text-success);
  }
}

.minimal-toast-icon__wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 20px;
  height: 20px;
}
</style>
