import type { Range } from '@/modules/Editor/types'
import type { Bounds, Raster } from '@/modules/Editor/models/raster/Raster'
import { assertImageRaster } from '@/modules/Editor/models/raster/assertImageRaster'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'

import { combineRange } from './combineRange'

/**
 * Given a raster, an existing mask, and a newly edited region, combine the current
 * raster range with the edit to get the new extent of the mask.
 */
export const getUpdatedMaskAnnotationBounds = (
  raster: Raster,
  labelIndex: number,
  editRange: Range,
  imageWidth: number,
  imageHeight: number,
  frameIndex: number,
): Bounds => {
  let bounds: Bounds | undefined

  if ([RasterTypes.VIDEO, RasterTypes.VOXEL].includes(raster.type)) {
    const videoRaster = assertVideoRaster(raster)
    const videoBounds = videoRaster.getVideoBoundsForLabelIndex(labelIndex)

    if (videoBounds) {
      bounds = videoBounds[frameIndex]
    }
  } else {
    const imageRaster = assertImageRaster(raster)

    bounds = imageRaster.getBoundsForLabelIndex(labelIndex)
  }

  if (bounds === undefined) {
    // New annotation, so just the edit range.
    return {
      topLeft: {
        x: editRange.minX,
        y: editRange.minY,
      },
      bottomRight: {
        x: editRange.maxX,
        y: editRange.maxY,
      },
    }
  }

  const { topLeft, bottomRight } = bounds

  const newRange = combineRange(
    editRange,
    {
      minX: topLeft.x,
      maxX: bottomRight.x,
      minY: topLeft.y,
      maxY: bottomRight.y,
    },
    imageWidth,
    imageHeight,
  )

  return {
    topLeft: {
      x: newRange.minX,
      y: newRange.minY,
    },
    bottomRight: {
      x: newRange.maxX,
      y: newRange.maxY,
    },
  }
}
