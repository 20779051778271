import type { Annotation } from './models/annotation/Annotation'
import { isImageAnnotation, isVideoAnnotation } from './models/annotation/annotationKindValidator'
import type { DrawCallback, ILayer } from './models/layers/types'
import { renderAnnotation } from './renderAnnotation'
import type { View } from './views/view'

export const renderSubAnnotations = (
  drawFn: DrawCallback,
  view: View,
  layer: ILayer<CanvasRenderingContext2D, HTMLCanvasElement>,
  annotation: Annotation,
): void => {
  if (!view.editor.renderSubAnnotations) {
    return
  }

  // break early if class has no subtypes
  if (
    view.annotationManager.getSubAnnotationTypesForClass(annotation.annotationClass).length === 0
  ) {
    return
  }

  if (isVideoAnnotation(annotation)) {
    const subs = view.editor.activeView.annotationManager.inferVideoSubAnnotations(annotation)
    subs.forEach((subAnnotation) => {
      renderAnnotation(drawFn, view, layer, subAnnotation, annotation)
    })

    return
  }
  if (isImageAnnotation(annotation)) {
    annotation.subAnnotations.forEach((subAnnotation) => {
      renderAnnotation(drawFn, view, layer, subAnnotation, annotation)
    })
  }
}
