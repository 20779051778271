import type { AnnotationType, GlobalSubAnnotationType } from '@/core/annotationTypes'
import type { AnnotationSubTypeGranularity, EyeNodeName } from '@/core/annotations'
// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'
// eslint-disable-next-line boundaries/element-types
import { getRGBAColorHash } from '@/uiKit/colorPalette'
import type { PartialRecord } from '@/core/helperTypes'

/**
 * An annotation class as used by the editor.
 *
 * The goal is for this to get merged into Annoation.ts once that is made simpler enough
 */
export type AnnotationClass = {
  /**
   * Annotation class id, unique globally, but should be threated as unique at team level
   */
  id: number
  /**
   * Name of the annotation class, unique at team level
   */
  name: string

  /**
   * If the class is of a skeleton main type, this contains node and edge
   * information for the skeleton
   */
  skeletonMetaData?: {
    nodes: { name: string; x: number; y: number }[]
    edges: { from: string; to: string }[]
  }

  /**
   * If the class is of a eye main type, this contains node and edge
   * information for the eye
   */
  eyeMetaData?: {
    nodes: { name: EyeNodeName; x: number; y: number }[]
    edges: { from: string; to: string }[]
  }
  /**
   * When true, the class needs to be 100% filled during render
   * to prevent the annotators from seeing data image below.
   *
   * This is currently set manually in the database,
   * only valid for polygons and bounding boxes.
   *
   * Note that this is only a workaround until we can generate a
   * new blacked out image as a transformation stage in wf2
   */
  blackout?: boolean

  /**
   * Describes which annotation types are implemented by this annotation class
   * (e.g. bounding_box, label, direction_vector)
   */
  annotation_types: AnnotationType[]

  color: { r: number; g: number; b: number; a: number }
  colorRGBAstring: string

  granularity?: PartialRecord<GlobalSubAnnotationType, AnnotationSubTypeGranularity>
}

export const getAnnotationClassColor = (annotationClass: AnnotationClass): RGBA =>
  annotationClass.color || getRGBAColorHash(annotationClass.name)
