import type { View } from '@/modules/Editor/views/view'
import type { AnnotationsFramePackage } from '@/pinia/utils/AnnotationPackage/AnnotationsFramePackage'
import { resolveRasterMapping } from './resolveRasterMapping'
import type { V2AnnotationPayload } from '@/store/types'
import type { PartialRecord } from '@/core/helperTypes'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { createMaskAnnotationFromDeserializable } from '@/modules/Editor/models/annotation/annotationFactories'
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'

import { setContext } from '@/services/sentry'
import { createMaskAnnotationOrAnnotationFromDeserializable } from '@/modules/Editor/models/annotation/annotationFactories'
import type { useV2AnnotationsStore } from '@/modules/Workview/useV2AnnotationsStore'

/**
 * Set annotations for current frame index for the given layout.
 *
 * When ANNOTATIONS_PACKAGE feature is off
 */
export const setAnnotationsForCurrentFrameIndex = (
  view: View,
  orderedAnnotationIds: { id: string }[],
  rasterLayers: V2AnnotationPayload[],
  getAnnotation: ReturnType<typeof useV2AnnotationsStore>['getAnnotation'],
  allowedClassIds: number[],
  editorClassesById: PartialRecord<number, AnnotationClass>,
): void => {
  const annotationsMap: PartialRecord<string, Annotation> = {}

  const annotations: V2AnnotationPayload[] = []

  orderedAnnotationIds.forEach(({ id }) => {
    const annotation = getAnnotation(id)
    if (annotation) {
      annotations.push(annotation)
    }
  })

  const annotationsExcludingRaster = resolveRasterMapping(view, annotations, rasterLayers)

  const ids: string[] = []
  annotationsExcludingRaster.forEach((storeAnnotation) => {
    if (!storeAnnotation) {
      return
    }

    const editorClass = editorClassesById[storeAnnotation.annotation_class_id]
    if (!editorClass) {
      setContext('useLinkAnnotationState', {
        function: 'setAnnotations',
        annotationId: storeAnnotation.id,
        classId: storeAnnotation.annotation_class_id,
      })

      throw new Error('Class not found in store for given class id while setting annotations')
    }

    const editorAnnotation = createMaskAnnotationOrAnnotationFromDeserializable(
      view,
      editorClass,
      storeAnnotation,
    )

    if (editorAnnotation) {
      annotationsMap[editorAnnotation.id] = editorAnnotation
      ids.push(editorAnnotation.id)
    }
  })

  view.annotationManager?.setAnnotations(ids, annotationsMap, view.currentFrameIndex)
  view.annotationManager?.setClassFilter(new Set(allowedClassIds))
}

/**
 * Set annotations for current frame index for the given layout.
 * Using frame package
 *
 * When ANNOTATIONS_PACKAGE feature is on
 */
export const setAnnotationsForCurrentFramePackageIndex = (
  view: View,
  annotationsFrameData: AnnotationsFramePackage | null,
  maskAnnotations: V2AnnotationPayload[],
  rasterLayers: V2AnnotationPayload[],
  allowedClassIds: number[],
  editorClassesById: PartialRecord<number, AnnotationClass>,
): void => {
  // When no data is provided set empty annotations list.
  if (!annotationsFrameData && !maskAnnotations.length && !rasterLayers.length) {
    view.annotationManager?.setAnnotations([], {}, view.currentFrameIndex)
    view.annotationManager.setParsedData({
      itemsBBoxMap: new Map(),
      rTreeItems: [],
      itemsMap: new Map(),
      zIndexesList: [],
    })
    return
  }

  // TODO: Is a feature check actually needed here?
  // At a glance, it looks like
  // - if there are no rasters in the payload, the resolve call is almost a no-op
  // - if there are rasters int he payload and flag is off, the feature is fully broken
  // - if there are rasters in the payload and flag is on, the feature works
  // Shouldn't the feature flag only allow/prevent annotations from being created?
  const maskMap: PartialRecord<string, Annotation> = {}
  const maskIds: string[] = []
  if (rasterLayers && maskAnnotations.length) {
    const res = resolveRasterMapping(view, maskAnnotations, rasterLayers)
    res.forEach((storeAnnotation) => {
      if (!storeAnnotation) {
        return
      }

      const editorClass = editorClassesById[storeAnnotation.annotation_class_id]
      if (!editorClass) {
        throw new Error('Class not found in store for given class id while setting annotations')
      }

      const editorAnnotation = createMaskAnnotationFromDeserializable(
        view,
        editorClass,
        storeAnnotation,
      )
      if (editorAnnotation) {
        maskMap[editorAnnotation.id] = editorAnnotation
        maskIds.push(editorAnnotation.id)
      }
    })
  }

  const sortedIds = [
    ...(annotationsFrameData ? annotationsFrameData.orderedAnnotationIds : []),
    ...maskIds,
  ]

  const annMap = {
    ...(annotationsFrameData ? annotationsFrameData.annotationsMap : {}),
    ...maskMap,
  }

  view.annotationManager?.setAnnotations(sortedIds, annMap, view.currentFrameIndex)
  view.annotationManager.setParsedData({
    // Copy to avoid mutation
    itemsBBoxMap: new Map(annotationsFrameData?.annotationsRenderData.itemsBBoxMap),
    rTreeItems: [...(annotationsFrameData?.annotationsRenderData.rTreeItems || [])],
    itemsMap: new Map(annotationsFrameData?.annotationsRenderData.itemsMap),
    zIndexesList: [...(annotationsFrameData?.orderedAnnotationIds || [])],
  })
  view.annotationManager?.setClassFilter(new Set(allowedClassIds))
}
