import type { DicomFrameParams } from './getDicomFrameParams'
import type { Polygon } from '@/modules/Editor/AnnotationData'

export const reformatPolygonToDicomScaling = (
  dicomFrameParams: DicomFrameParams,
  polygon: Polygon,
): void => {
  const polygonPath = polygon.path
  const polygonaAdditionalPaths = polygon.additionalPaths

  for (let i = 0; i < polygonPath.length; i++) {
    polygonPath[i].x /= dicomFrameParams.xScale
    polygonPath[i].y /= dicomFrameParams.yScale
  }

  if (polygonaAdditionalPaths) {
    for (let i = 0; i < polygonaAdditionalPaths.length; i++) {
      for (let j = 0; j < polygonaAdditionalPaths[i].length; j++) {
        polygonaAdditionalPaths[i][j].x /= dicomFrameParams.xScale
        polygonaAdditionalPaths[i][j].y /= dicomFrameParams.yScale
      }
    }
  }
}
