import type { WindowLevels, WindowLevel } from '@/modules/Editor/imageManipulation'
import type { View } from '@/modules/Editor/views/view'

const getWindowLevel = (view: View): WindowLevel => {
  const { imageFilter } = view
  // Note what we call "WindowLevels" throughout the app is incorrect,
  // but this is a larger task to tackle.
  const { windowLevels: windowLevelRange } = imageFilter

  const window = windowLevelRange[1] - windowLevelRange[0]
  const level = (windowLevelRange[0] + windowLevelRange[1]) / 2

  return {
    window,
    level,
  }
}

const setWindowLevel = (view: View, windowLevel: WindowLevel): void => {
  const { imageFilter } = view

  const { level, window } = windowLevel
  const halfWindow = window / 2

  const newWindowLevelRange: WindowLevels = [level - halfWindow, level + halfWindow]

  imageFilter.windowLevels = newWindowLevelRange
  view.setImageFilter(imageFilter)
}

export { getWindowLevel, setWindowLevel }
