import type { DatasetMutation } from '@/store/modules/dataset/types'
import type { DatasetPayload } from '@/store/types'

export const PUSH_DATASET: DatasetMutation<DatasetPayload> = (state, payload) => {
  const index = state.datasets.findIndex((p) => p.id === payload.id)
  if (index > -1) {
    const oldDataset = state.datasets[index]
    state.datasets.splice(index, 1, {
      ...oldDataset,
      ...payload,
      // the single dataset endpoint sets null or [] on these fields, so they get overwritten
      // this is a temporary fix until we migrate to a Pinia store
      num_annotations: payload.num_annotations || oldDataset.num_annotations,
      num_classes: payload.num_classes || oldDataset.num_classes,
      num_images: payload.num_images || oldDataset.num_images,
      num_videos: payload.num_videos || oldDataset.num_videos,
      num_annotators: payload.num_annotators || oldDataset.num_annotators,
      thumbnails: [...(payload.thumbnails || []), ...(oldDataset.thumbnails || [])],
      progress: payload.progress || oldDataset.progress,
    })
  } else {
    state.datasets.push(payload)
  }
}
