import { ImageRaster } from './ImageRaster'
import type { Raster } from './Raster'

/**
 * Returns a raster as an image raster for type interpretation, without casting.
 * Throws if this is not possible.
 */
export const assertImageRaster = (raster: Raster): ImageRaster => {
  if (!(raster instanceof ImageRaster)) {
    throw new Error('Raster is not an ImageRaster')
  }

  return raster
}
