import { defineComposable } from '@/core/utils/defineComposable'
import { updateDataset } from '@/backend/darwin/updateDataset'
import { useDatasetStore } from './useDatasetStore'

export const useDatasetCRUD = defineComposable(() => {
  const datasetStore = useDatasetStore()

  return {
    updateDataset: async (
      payload: Parameters<typeof updateDataset>[0],
    ): Promise<ReturnType<typeof updateDataset>> => {
      const result = await updateDataset(payload)
      result.ok && datasetStore.pushDataset(result.data)
      return result
    },
  }
})
