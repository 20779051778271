import type { ActionTree } from 'vuex'

import type { RootState } from '@/store/types'

import { deleteV2Export } from './actions/deleteV2Export'
import { getV2Exports } from './actions/getV2Exports'
import type { DatasetState } from './state'

type Actions = ActionTree<DatasetState, RootState>

// actions
export const actions: Actions = { deleteV2Export, getV2Exports }

