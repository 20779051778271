import { isPolygon } from '@/modules/Editor/annotationTypes/polygon'
import { fromPolyBool, toPolyBool, polygonToCompoundPath } from '@/modules/Editor/compoundPath'
import { EditorEvents } from '@/modules/Editor/eventBus'
import type { Action } from '@/modules/Editor/managers/actionManager'
import { polygon, segments } from '@/modules/Editor/polygonOperations'
import { combine, selectDifference } from '@/modules/Editor/polygonOperations'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import type { View } from '@/modules/Editor/views/view'

export const subtractPolygonsAction = (
  view: View,
  firstAnnotation: Annotation,
  secondAnnotation: Annotation,
): Action => {
  const previousSecondAnnotationData = secondAnnotation.data

  return {
    do(): boolean {
      if (!isPolygon(firstAnnotation.data) || !isPolygon(secondAnnotation.data)) {
        return false
      }

      const polygonCompoundPath1 = polygonToCompoundPath(firstAnnotation.data)
      const polygonCompoundPath2 = polygonToCompoundPath(secondAnnotation.data)
      const segment1 = segments(toPolyBool(polygonCompoundPath1))
      const segment2 = segments(toPolyBool(polygonCompoundPath2))

      const combined = combine(segment2, segment1)

      const subtractedSegment = selectDifference(combined)
      const subtractedPolygon = fromPolyBool(polygon(subtractedSegment))
      if (subtractedPolygon.path.length === 0) {
        EditorEvents.message.emit({
          content: 'You cannot delete entire annotations with subtractions',
          level: 'warning',
        })

        return false
      }

      const updatedAnnotation = shallowCloneAnnotation(secondAnnotation, {
        data: {
          ...secondAnnotation.data,
          path: subtractedPolygon.path,
          additionalPaths: subtractedPolygon.additionalPaths,
        },
      })

      view.annotationManager.updateAnnotation(updatedAnnotation)

      return true
    },
    undo(): boolean {
      secondAnnotation.data = previousSecondAnnotationData
      view.annotationManager.updateAnnotation(secondAnnotation)
      return true
    },
  }
}
