import { MainAnnotationType, type AnnotationType } from '@/core/annotationTypes'

import type { AnnotationData } from './AnnotationData'
import { boundingBoxInterpolation } from './annotationInterpolation/boundingBoxInterpolation'
import { ellipseInterpolation } from './annotationInterpolation/ellipseInterpolation'
import eyeInterpolation from './annotationInterpolation/eyeInterpolation'
import { keypointInterpolation } from './annotationInterpolation/keypointInterpolation'
import { polygonInterpolation } from './annotationInterpolation/polygonInterpolation'
import { polylineInterpolation } from './annotationInterpolation/polylineInterpolation'
import { skeletonInterpolation } from './annotationInterpolation/skeletonInterpolation'
import type { LinearInterpolationParams } from './annotationInterpolation/types'
import { annotationDataIsEmpty } from './utils/pagination'
import { isPolygon } from './annotationTypes/polygon'
import { isBoundingBox } from './annotationTypes/boundingBox'
import { isPolyline } from './annotationTypes/polyline'
import { isKeyPoint } from './annotationTypes/keypoint'
import { isSkeleton } from './annotationTypes/skeleton'
import { isEllipse } from './annotationTypes/ellipse'
import { isEye } from './annotationTypes/eye'

export const supportsInterpolation = (type: AnnotationType): boolean =>
  ['polygon', 'bounding_box', 'ellipse', 'line', 'skeleton', 'eye', 'keypoint'].includes(type)

export const enableInterpolateByDefault = (type: AnnotationType): boolean =>
  ['polygon', 'bounding_box', 'ellipse', 'line', 'skeleton', 'eye', 'keypoint'].includes(type)

export const interpolateAnnotationFrames = (
  type: AnnotationType,
  prev: AnnotationData,
  next: AnnotationData,
  params: LinearInterpolationParams,
): AnnotationData | null => {
  if (annotationDataIsEmpty(prev)) {
    throw new Error('Cannot interpolate as there is no frame data for previous keyframe')
  }

  if (annotationDataIsEmpty(next)) {
    throw new Error('Cannot interpolate as there is no frame data for next keyframe')
  }

  if (type === MainAnnotationType.Polygon && isPolygon(prev) && isPolygon(next)) {
    return polygonInterpolation(params, prev, next)
  }

  if (type === MainAnnotationType.BoundingBox && isBoundingBox(prev) && isBoundingBox(next)) {
    return boundingBoxInterpolation(params, prev, next)
  }

  if (type === MainAnnotationType.Polyline && isPolyline(prev) && isPolyline(next)) {
    return polylineInterpolation(params, prev, next)
  }

  if (type === MainAnnotationType.Keypoint && isKeyPoint(prev) && isKeyPoint(next)) {
    return keypointInterpolation(params, prev, next)
  }

  if (type === MainAnnotationType.Skeleton && isSkeleton(prev) && isSkeleton(next)) {
    return skeletonInterpolation(params, prev, next)
  }

  if (type === MainAnnotationType.Ellipse && isEllipse(prev) && isEllipse(next)) {
    return ellipseInterpolation(params, prev, next)
  }

  if (type === MainAnnotationType.Eye && isEye(prev) && isEye(next)) {
    return eyeInterpolation(params, prev, next)
  }

  return null
}
