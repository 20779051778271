/**
 * Provides a friendly interface for work with CachedCanvas worker.
 */
import type { BBox } from '@/modules/Editor/types'
import { toWorker } from '@/modules/Editor/utils/workers/toWorker'
import type { RenderableItemWithState } from '@/modules/Editor/models/layers/object2D'
import type { CanvasFillRule, RenderFilters } from '@/modules/Editor/models/layers/types'

import CachedCanvasWoker from './cachedCanvasWorker?worker'
import { ACTIONS } from './types'

export class CachedCanvasWorkerInterface {
  private worker = new CachedCanvasWoker()

  private toWorker: ReturnType<typeof toWorker>

  constructor() {
    this.toWorker = toWorker(this.worker)
  }

  public connect(offscreen: OffscreenCanvas): Promise<void> {
    return this.toWorker.postMessage<void, OffscreenCanvas>(ACTIONS.CONNECT, offscreen, [offscreen])
  }

  public setScale(scale: number): Promise<void> {
    return this.toWorker.postMessage(ACTIONS.SET_SCALE, scale)
  }

  public setCameraScale(scale: number): Promise<void> {
    return this.toWorker.postMessage(ACTIONS.SET_CAMERA_SCALE, scale)
  }

  public setFilters(filters: RenderFilters): Promise<void> {
    return this.toWorker.postMessage(ACTIONS.SET_FILTERS, filters)
  }

  public async drawAll(items: RenderableItemWithState[]): Promise<Map<string, Path2D>> {
    await this.toWorker.postMessage<void, RenderableItemWithState[]>(ACTIONS.DRAW_ALL, items)

    // Path2D is not transferable
    // We return an empty map since we gonna use WebWorker's detection of the point in path
    return new Map()
  }

  public drawItemsInBox(items: RenderableItemWithState[], box: BBox): Promise<void> {
    return this.toWorker.postMessage<void, { items: RenderableItemWithState[]; box: BBox }>(
      ACTIONS.DRAW_ITEMS_IN_BOX,
      { items, box },
    )
  }

  async isPointInStroke(id: RenderableItemWithState['id'], x: number, y: number): Promise<boolean> {
    const res = await this.toWorker.postMessage<
      boolean,
      {
        id: RenderableItemWithState['id']
        x: number
        y: number
      }
    >(ACTIONS.IS_POINT_IN_STROKE, { id, x, y })

    return !!res
  }

  async isPointInPath(
    id: RenderableItemWithState['id'],
    x: number,
    y: number,
    fillRule?: CanvasFillRule,
  ): Promise<boolean> {
    const res = await this.toWorker.postMessage<
      boolean,
      {
        id: RenderableItemWithState['id']
        x: number
        y: number
        fillRule?: CanvasFillRule
      }
    >(ACTIONS.IS_POINT_IN_PATH, { id, x, y, fillRule })

    return !!res
  }

  terminate(): void {
    this.toWorker.cleanup()
    this.worker.terminate()
  }

  cleanup(): Promise<void> {
    return this.toWorker.postMessage(ACTIONS.CLEANUP)
  }
}
