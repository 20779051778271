import { MainAnnotationType } from '@/core/annotationTypes'
import type { FrameData } from '@/core/annotations'
import calcPathFromBBox from '@/modules/Editor/bbox/calcPathFromBBox'
import type { RenderingContext2D } from '@/modules/Editor/types'
import { drawDirectionalVectorByType } from '@/modules/Editor/graphicsV2/drawDirectionalVectorByType'
import { drawEllipse } from '@/modules/Editor/graphicsV2/drawEllipse'
import { drawEye } from '@/modules/Editor/graphicsV2/drawEye'
import { drawPath } from '@/modules/Editor/graphicsV2/drawPath'
import { drawSimpleTable } from '@/modules/Editor/graphicsV2/drawSimpleTable'
import { drawSkeleton } from '@/modules/Editor/graphicsV2/drawSkeleton'
import { drawVertex } from '@/modules/Editor/graphicsV2/drawVertex'

type PropsType = {
  fillColor: string
  strokeColor: string
  lineWidth: number
  pointSize: number
  scale: number
  isSelected?: boolean
  isHighlighted?: boolean
}

export const drawByType = (
  ctx: RenderingContext2D,
  type: MainAnnotationType,
  data: FrameData,
  props: PropsType,
): Path2D | undefined => {
  if (type === MainAnnotationType.Ellipse) {
    if (!data.ellipse) {
      return
    }

    return drawEllipse(ctx, {
      fillColor: props.fillColor,
      strokeColor: props.strokeColor,
      lineWidth: props.lineWidth,
      radius: data.ellipse.radius,
      center: data.ellipse.center,
      angle: data.ellipse.angle,
    })
  }

  if (type === MainAnnotationType.BoundingBox) {
    if (!data.bounding_box) {
      return
    }

    const path = calcPathFromBBox(data.bounding_box)

    const path2D = drawPath(ctx, {
      fillColor: props.fillColor,
      strokeColor: props.strokeColor,
      lineWidth: props.lineWidth,
      path,
    })

    if (data.directional_vector) {
      drawDirectionalVectorByType(ctx, MainAnnotationType.BoundingBox, data, {
        lineWidth: props.lineWidth,
        scale: props.scale,
      })
    }

    return path2D
  }

  if (type === MainAnnotationType.Polygon) {
    if (!data.polygon) {
      return
    }

    const { path, additional_paths } = data.polygon

    const path2D = drawPath(ctx, {
      fillColor: props.fillColor,
      strokeColor: props.strokeColor,
      lineWidth: props.lineWidth,
      path,
      additional_paths,
    })

    if (data.directional_vector) {
      drawDirectionalVectorByType(ctx, MainAnnotationType.Polygon, data, {
        lineWidth: props.lineWidth,
        scale: props.scale,
      })
    }

    return path2D
  }

  if (type === MainAnnotationType.Polyline) {
    if (!data.line) {
      return
    }

    return drawPath(ctx, {
      fillColor: 'transparent',
      strokeColor: props.strokeColor,
      lineWidth: props.lineWidth,
      path: data.line.path,
      closePath: false,
    })
  }

  if (type === MainAnnotationType.Keypoint) {
    if (!data.keypoint) {
      return
    }

    return drawVertex(ctx, data.keypoint, {
      fillColor: props.fillColor,
      strokeColor: props.strokeColor,
      pointSize: props.pointSize,
      isHighlighted: !!props.isHighlighted,
      isSelected: !!props.isSelected,
      lineWidth: props.lineWidth,
    })
  }

  if (type === MainAnnotationType.Skeleton) {
    if (!data.skeleton) {
      return
    }

    return drawSkeleton(ctx, {
      nodes: data.skeleton?.nodes,
      edges: data.skeleton?.edges || [],
      pointSize: props.pointSize,
      strokeColor: props.strokeColor,
      lineWidth: props.lineWidth,
    })
  }

  if (type === MainAnnotationType.Eye) {
    if (!data.eye) {
      return
    }

    return drawEye(ctx, {
      nodes: data.eye?.nodes,
      strokeColor: props.strokeColor,
      lineWidth: props.lineWidth,
    })
  }

  if (type === MainAnnotationType.SimpleTable) {
    if (!data.simple_table) {
      return
    }

    const path = calcPathFromBBox(data.simple_table.bounding_box)

    return drawSimpleTable(ctx, {
      path,
      boundingBox: data.simple_table.bounding_box,
      colOffsets: data.simple_table.col_offsets,
      rowOffsets: data.simple_table.row_offsets,
      fillColor: props.fillColor,
      strokeColor: props.strokeColor,
      lineWidth: props.lineWidth,
    })
  }
}
