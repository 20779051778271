import type { AxiosResponse } from 'axios'

import type { V2AnnotationPayload } from '@/store/types/StageAnnotationPayload'
import type { V2DatasetItemPayload } from '@/store/types/V2DatasetItemPayload'
import { get } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { parseError, errorMessages, isErrorResponse } from '@/backend/error'

/**
 * Defines params structure for backend action
 */
type Params = {
  teamSlug: string
  datasetItemId: V2DatasetItemPayload['id']
  snapshotId: string
  limitBySize?: number
  offset?: number
}

/**
 * Loads annotations for the specified snapshot id
 */
export const loadV2AnnotationsSnapshot = async (
  params: Params,
): Promise<ParsedError | AxiosResponse<V2AnnotationPayload[]>> => {
  const { teamSlug, datasetItemId, snapshotId, limitBySize, offset } = params

  const reqParams: { limit_by_size?: number; offset?: number } = {}

  if (limitBySize) {
    reqParams.limit_by_size = limitBySize
  }

  if (offset) {
    reqParams.offset = offset
  }

  const path = `/v2/teams/${teamSlug}/items/${datasetItemId}/snapshots/${snapshotId}/annotations`

  try {
    const response = await get<V2AnnotationPayload[]>(path, reqParams)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.STAGE_LOAD)
  }
}
