/**
 * This module represents core data needed to deal with annotation types.
 *
 * A type in the context of an annotation defines how that annotation so
 * represented. Differently represented annotations have different data and
 * we use different tools to draw them.
 *
 * For example, a polygon type is drawn using the polygon or brush tool. The
 * bounding box type is drawn using the bounding box tool, etc.
 *
 * A type can be considered a main type or a subtype. The can only be one main
 * type per annotation, but it can have multiple subtypes.
 *
 * Subtypes represent additional data for an annotation. For example, a polygon
 * annotation might also have a directional vector tied to it.
 */

import { assert } from './utils/assert'

export const MainAnnotationType = {
  BoundingBox: 'bounding_box',
  Ellipse: 'ellipse',
  Keypoint: 'keypoint',
  Polyline: 'line',
  Mask: 'mask',
  Polygon: 'polygon',
  RasterLayer: 'raster_layer',
  SimpleTable: 'simple_table',
  Skeleton: 'skeleton',
  Eye: 'eye',
  Tag: 'tag',
} as const

/**
 * A main annotation type represents an overall kind of annotation.
 *
 * Different annotation types are stored differently and use different
 * annotation tools to be stored.
 */
export type MainAnnotationType = (typeof MainAnnotationType)[keyof typeof MainAnnotationType]

export const isMainAnnotationType = (type: unknown): type is MainAnnotationType =>
  typeof type === 'string' && !!(Object.values(MainAnnotationType) as string[]).includes(type)

export const SubAnnotationType = {
  // actual subtypes
  Attributes: 'attributes',
  DirectionalVector: 'directional_vector',
  InstanceId: 'instance_id',
  Text: 'text',
  // meta types are data that gets added by our tools internally,
  // not by the user directly
  AutoAnnotate: 'auto_annotate', // added by clicker
  Inference: 'inference', // added by model tool
  Measures: 'measures', // added when using measures
} as const

/**
 * A sub type represents additional data for a main type and doesn't exist on
 * it's own.
 *
 * For example, a polygon main type could have a directional vector.
 */
export type SubAnnotationType = (typeof SubAnnotationType)[keyof typeof SubAnnotationType]

export type AnnotationType = MainAnnotationType | SubAnnotationType

export const mainAnnotationTypes: MainAnnotationType[] = Object.values(MainAnnotationType)

// manually defined because we want to exclude properties
export const subAnnotationTypes: SubAnnotationType[] = [
  // actual sub types
  'attributes',
  'directional_vector',
  'instance_id',
  'text',
  // meta subtypes
  'auto_annotate',
  'inference',
  'measures',
]

/**
 * Defines which main types can have which subtypes
 */
export const mainTypeSubTypeDependencies: Record<MainAnnotationType, SubAnnotationType[]> = {
  keypoint: ['attributes', 'instance_id', 'text'],
  line: ['attributes', 'instance_id', 'text'],
  skeleton: ['attributes', 'instance_id', 'text'],
  eye: ['attributes', 'instance_id', 'text'],
  tag: ['attributes', 'inference', 'text'],
  ellipse: ['attributes', 'instance_id', 'measures', 'text'],
  bounding_box: [
    'attributes',
    'directional_vector',
    'inference',
    'instance_id',
    'measures',
    'text',
  ],
  mask: ['attributes', 'auto_annotate', 'inference', 'measures', 'text'],
  polygon: [
    'attributes',
    'auto_annotate',
    'directional_vector',
    'inference',
    'instance_id',
    'measures',
    'text',
  ],
  raster_layer: [],
  simple_table: ['attributes', 'inference'],
}

export const isAnnotationMainType = (type: string): type is MainAnnotationType =>
  mainAnnotationTypes.some((t) => t === type)

export const isAnnotationSubType = (type: string): type is SubAnnotationType =>
  subAnnotationTypes.some((t) => t === type)

/**
 * Type guard to check whether the given type is valid annotation type.
 *
 * Use this function when you receive the string and want to be sure you
 * can treat it as an annotation type.
 */
export const isAnnotationType = (type: string): type is AnnotationType =>
  isAnnotationMainType(type) || isAnnotationSubType(type)

/**
 * From a given list of types, returns the first main type in the list.
 */
export const getMainAnnotationType = (types: AnnotationType[]): MainAnnotationType | undefined => {
  // requires things to be written this way for type inference to work
  for (const t of types) {
    if (isAnnotationMainType(t)) {
      return t
    }
  }
}

/**
 * A variation of `getMainAnnotationType` that throws an error if there is no main type in the list.
 */
export const fetchMainAnnotationType = (types: AnnotationType[]): MainAnnotationType =>
  assert(getMainAnnotationType(types))

export const getSubAnnotationTypes = (types: AnnotationType[]): SubAnnotationType[] =>
  types.filter((t): t is SubAnnotationType => isAnnotationSubType(t))

export const metaTypes: AnnotationType[] = [
  'auto_annotate',
  'inference',
  'measures',
  'raster_layer',
]

export const isAnnotationTypeRenderable = (t: AnnotationType): boolean => !metaTypes.includes(t)

export const annotationTypes: AnnotationType[] = [...mainAnnotationTypes, ...subAnnotationTypes]

export const GlobalSubAnnotationType = {
  [SubAnnotationType.InstanceId]: SubAnnotationType.InstanceId,
}
export type GlobalSubAnnotationType =
  (typeof GlobalSubAnnotationType)[keyof typeof GlobalSubAnnotationType]

export const isGlobalSubAnnotationType = (subtype: string): subtype is GlobalSubAnnotationType =>
  subtype === SubAnnotationType.InstanceId

export const assertGlobalSubAnnotationType = (subtype: string): GlobalSubAnnotationType => {
  if (subtype !== SubAnnotationType.InstanceId) {
    throw new Error('the given string is not a global annotation subtype')
  }
  return subtype
}
