import { defineAsyncComponent } from 'vue'
import type { RouteConfig } from 'vue-router'

const ModelsMain = defineAsyncComponent(() => import('@/modules/Models/RouteModelsMain.vue'))

const ModelsIndex = defineAsyncComponent(() => import('@/modules/Models/RouteModelsIndex.vue'))

const ModelsNew = defineAsyncComponent(() => import('@/modules/Models/RouteModelsNew.vue'))

const ModelsShow = defineAsyncComponent(() => import('@/modules/Models/RouteModelsShow.vue'))

const ExternalModels = defineAsyncComponent(
  () => import('@/modules/Models/RouteExternalModels.vue'),
)

const ExternalModelsOverview = defineAsyncComponent(
  () => import('@/modules/Models/RouteExternalModelsOverview.vue'),
)

const modelRoute: RouteConfig = {
  // The root models route simply performs some data loading and provides a router-view.
  // No layout or styles
  path: '/models',
  name: 'Models',
  meta: { requiresAuth: true, requiresAbility: 'view_full_datasets' },
  component: ModelsMain,
  children: [
    // The index page renders all external models, internal training sessions,
    // internal trained models and internal running sssions
    // User can
    // - click a button to train a new internal model (ModelsNew route)
    // - click button to register a new external model (ExternalModels > ExternalModelsRoute route)
    // - click a model card to view details (ModelsShow route)
    {
      path: '',
      name: 'ModelsIndex',
      component: ModelsIndex,
      meta: { requiresAuth: true, requiresAbility: 'view_full_datasets' },
    },
    // Creation of a new internal model
    {
      path: 'new',
      name: 'ModelsNew',
      component: ModelsNew,
      meta: { requiresAuth: true, requiresAbility: 'view_full_datasets' },
    },

    // Registration of a new external model.
    // Uses same route component as viewing details of a registered external model
    // Provides a tab layout, but only has the overview tab at the moment
    // Other tabs are likely to take us to a child of the ModelsShow route,
    // so they will probaly not be added here, even if we ever do have them
    {
      path: 'register-external-model',
      name: 'ExternalModels',
      component: ExternalModels,
      meta: { requiresAuth: true, requiresAbility: 'view_full_datasets' },
      children: [
        // Renders overview tab component for a newly registered external model
        {
          path: '',
          name: 'ExternalModelsNew',
          component: ExternalModelsOverview,
          meta: { requiresAuth: true, requiresAbility: 'view_full_datasets' },
        },
      ],
    },
    {
      path: ':modelId',
      props: true,
      name: 'ModelsShow',
      component: ModelsShow,
      meta: { requiresAuth: true, requiresAbility: 'view_full_datasets' },
      children: [
        // The ModelsShow route component will not actually render a router-view for this child.
        // Instead, it will directly render the ExternalModelsOverview component, which will then
        // provide the router-view. This is confusing and should be refactored.
        // DAR-1348
        {
          path: '',
          name: 'ExternalModelsShow',
          component: ExternalModelsOverview,
          meta: { requiresAuth: true, requiresAbility: 'view_full_datasets' },
        },
      ],
    },
  ],
}

export default modelRoute
