import type { MainAnnotationType } from '@/core/annotationTypes'
import type { FrameData } from '@/core/annotations'
import calcPathFromBBox from '@/modules/Editor/bbox/calcPathFromBBox'
import type { IPoint } from '@/modules/Editor/point'

const HORIZONTAL_SHIFT = 25

/**
 * Calculates the centroid point for path.
 * @param path
 * @param scale
 * @returns
 */
export const calcCentroid = (path: IPoint[], scale: number = 1): IPoint => {
  // In the case of keypoints, the centroid is shifted horizontally,
  // so the keypoint itself remains visible
  if (path.length === 1 && scale) {
    return { x: path[0].x + HORIZONTAL_SHIFT / scale, y: path[0].y + 0 }
  }
  // Calculating the centroid of a polygon https://en.wikipedia.org/wiki/Centroid
  let x = 0
  let y = 0
  let area = 0
  for (let i = 0; i < path.length; i++) {
    const c = path[i]
    const n = path[i < path.length - 1 ? i + 1 : 0]
    x += (c.x + n.x) * (c.x * n.y - n.x * c.y)
    y += (c.y + n.y) * (c.x * n.y - n.x * c.y)
    area += c.x * n.y - n.x * c.y
  }

  return {
    x: x / (3 * area),
    y: y / (3 * area),
  }
}

/**
 * Calculates the centroid point for the FrameData by the annotation type.
 * @param type
 * @param data
 * @returns
 */
export const calcCentroidByType = (type: MainAnnotationType, data: FrameData): IPoint | null => {
  if (type === 'bounding_box') {
    if (!data.bounding_box) {
      return null
    }
    return calcCentroid(calcPathFromBBox(data.bounding_box))
  }

  if (type === 'polygon') {
    if (!data.polygon) {
      return null
    }
    return calcCentroid(data.polygon.path)
  }

  return null
}
