import type { AdvancedFilters } from '@/modules/AdvancedFilters/filters'
import { normalizeFilter } from '@/modules/AdvancedFilters/normalizeFilter'
import type { V2DatasetItemPayload, PagedApiV2Response, PaginationParams } from '@/store/types'
import { post } from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'
import type { ApiResult } from './types'

export type LoadV2DatasetItemsParams = {
  teamSlug: string
  filter: AdvancedFilters
  page?: PaginationParams
  include_thumbnails?: boolean
  include_workflow_data?: boolean
  include_tags?: boolean
  dataset_ids: number[]
}
/**
 * Retrieves list of dataset items from the backend
 *
 * Supports pagination, filtering and ordering.
 */
export const loadV2DatasetItems = async ({
  teamSlug,
  ...params
}: LoadV2DatasetItemsParams): Promise<ApiResult<PagedApiV2Response<V2DatasetItemPayload>>> => {
  try {
    const response = await post<PagedApiV2Response<V2DatasetItemPayload>>(
      `unstable/teams/${teamSlug}/items/list`,
      {
        ...params,
        filter: normalizeFilter(params.filter),
      },
    )
    return { ok: true, data: response.data }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return {
      ...parseError(error, errorMessages.WORKVIEW_IMAGES_LOAD),
      ok: false,
    }
  }
}
