import { highlightedAlpha, defaultAlpha } from '@/modules/Editor/graphics/renderingConstants'
// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'
// eslint-disable-next-line boundaries/element-types
import { rgbaString } from '@/uiKit/colorPalette'

/**
 * Produce the fillStyle string based on arguments.
 * @param color
 * @param opacity
 * @param inferred
 * @param isHighlighted
 * @param isSelected
 * @returns {string}
 */
export const fillStyle = (
  color: RGBA,
  opacity: number | null,
  inferred: boolean,
  isHighlighted: boolean,
  isSelected: boolean,
): string => {
  let alpha = 1
  if (opacity === null) {
    alpha = inferred || isHighlighted || isSelected ? highlightedAlpha : defaultAlpha
  } else {
    if (isSelected) {
      alpha = opacity / 100.0
    } else {
      alpha = inferred || isHighlighted ? highlightedAlpha : opacity / 100.0
    }
  }
  return rgbaString(color, alpha)
}
