import type { IPoint } from '@/modules/Editor/point'
import type { Editor } from '@/modules/Editor/editor'

import { focusOnSelectedAnnotation as doFocusOnSelectedAnnotation } from './focusOnSelectedAnnotation'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useZoomInteractions = (editor: Editor) => {
  let cursorPoint: IPoint | undefined = undefined

  editor.onMouseMove((event) => {
    cursorPoint = { x: event.offsetX, y: event.offsetY }
  })

  const reset = (): void => {
    editor.viewsList.forEach((view) => {
      view.resetZoom()
    })
  }

  const focusOnSelectedAnnotation = (): void =>
    editor.viewsList.forEach((view) => {
      doFocusOnSelectedAnnotation(view)
    })

  const zoomIn = (): void => {
    if (cursorPoint) {
      editor.activeView.camera.zoomIn(cursorPoint)
      editor.activeView.allLayersChanged()
    }
  }

  const zoomOut = (): void => {
    if (cursorPoint) {
      editor.activeView.camera.zoomOut(cursorPoint)
      editor.activeView.allLayersChanged()
    }
  }

  return {
    reset,
    zoomIn,
    zoomOut,
    focusOnSelectedAnnotation,
  }
}
