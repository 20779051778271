import type { Action } from '@/modules/Editor/managers/actionManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'

export function addAnnotationsAction(view: View, annotations: Annotation[]): Action {
  // need to store ids to match by on undo/redo
  const ids = annotations.map((a) => a.id)

  return {
    do(): boolean {
      // We need to deselect all the annotations
      // because the new annotation will always be selected by default.
      view.annotationManager.deselectAllAnnotations()
      for (let i = 0; i < annotations.length; i++) {
        const annotation = annotations[i]
        let newAnnotation
        try {
          newAnnotation = view.annotationManager.createAnnotation(annotation)
        } catch (e) {
          setContext('error', { error: e })
          console.error('in v2 addAnnotationsAction, annotation manager failed to create')
          return false
        }
        if (newAnnotation) {
          ids[i] = newAnnotation.id
        }
      }
      return true
    },

    undo(): boolean {
      for (const id of ids) {
        view.annotationManager.deleteAnnotation(id)
      }
      return true
    },
  }
}
