import { subtractPolygonsAction } from '@/modules/Editor/actions'
import type { ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'

export const handlePolygonSubtract = (
  firstAnnotation: Annotation,
  lastAnnotation: Annotation,
  context: ToolContext,
): void => {
  const action = subtractPolygonsAction(context.editor.activeView, firstAnnotation, lastAnnotation)
  context.editor.actionManager.do(action)
}
