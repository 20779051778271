/**
 * This enumerates all common DICOM modalities and gives information about formal support.
 * It also enumerates "Modalities" generated by out backend, such as "nifti" for NIFTI volumes.
 */
export enum MedicalModality {
  /**
   * Supported Imaging:
   *
   * Well tested imaging modalities support on the platform.
   */
  CR = 'CR', // Computed Radiography
  DX = 'DX', // Digital Radiography
  CT = 'CT', // Computed Tomography
  MR = 'MR', // Magnetic Resonance
  US = 'US', // Ultrasound
  MG = 'MG', // Mammography
  PT = 'PT', // Positron Emission Tomography
  NM = 'NM', // Nuclear Medicine
  ES = 'ES', // Endoscopy
  GM = 'GM', // General Microscopy
  DM = 'DM', // Digital Microscopy
  ECG = 'ECG', // Electrocardiography
  OSS = 'OSS', // Optical Surface Scan
  OCT = 'OCT', // Optical Coherence Tomography (non-Ophthalmic)
  OP = 'OP', // Ophthalmic Photography

  /**
   * "Officially Unsupported" Imaging:
   *
   * _May_ display correctly, but not had dedicated effort.
   */
  SM = 'SM', // Slide Microscopy (This should use the tiled renderer eventually)
  AR = 'AR', // Autorefraction
  BSUD = 'BSUD', // Bone Densitometry (Ultrasound)
  BI = 'BI', // Biomagnetic Imaging
  BMD = 'BMD', // Bone Densitometry (X-Ray)
  DG = 'DG', // Diaphanography
  EPS = 'EPS', // Cardiac Electrophysiology
  HD = 'HD', // Hemodynamic Waveform
  IO = 'IO', // Intra-Oral Radiography
  IOL = 'IOL', // Intraocular Lens Data
  IVOCT = 'IVOCT', // Intravascular Optical Coherence Tomography
  IVUS = 'IVUS', // Intravascular Ultrasound
  LEN = 'LEN', // Lensometry
  LS = 'LS', // Laser surface scan
  OPT = 'OPT', // Ophthalmic Tomography
  PX = 'PX', // Panoramic X-Ray
  RESP = 'RESP', // Respiratory Waveform
  RF = 'RF', // Radio Fluoroscopy
  RG = 'RG', // Radiographic Imaging (convential film images scanned in)
  TG = 'TG', // Thermography
  XA = 'XA', // X-Ray Angiography
  XC = 'XC', // External-camera Photography

  /**
   * "Officially Unsupported" Non-Imaging:
   *
   * In the future if each of these are used they may instead end up as
   * different resources. We should document why those modalities are
   * processed differently and removed from this list in that case.
   */
  CTPROTOCOL = 'CTPROTOCOL', // Contains information on the exact CT Protocol used.
  DOC = 'DOC', // Document
  AU = 'AU', // Audio
  HC = 'HC', // Hard Copy
  KO = 'KO', // Key Object Selection (usually used by PACS systems to indicated archived items etc)
  M3D = 'M3D', // Model for 3D Manufacturing
  OPM = 'OPM', // Ophthalmic Mapping
  OPTBSV = 'OPTBSV', // Ophthalmic Tomography B-scan Volume Analysis
  OPTENF = 'OPTENF', // Ophthalmic Tomography En Face
  OPV = 'OPV', // Ophthalmic Visual Field
  OT = 'OT', // Other
  PLAN = 'PLAN', // Treatment Plan
  PR = 'PR', // Presentation State
  REG = 'REG', // Registration
  RWV = 'RWV', // Real World Value Map
  SMR = 'SMR', // Stereometric Relationship
  SRF = 'SRF', // Subjective Refraction
  STAIN = 'STAIN', // Auotmated Slide Stainer
  TEXTUREMAP = 'TEXTUREMAP', // Texture Map
  VA = 'VA', // Visual Activity

  /**
   * Annotation Storage:
   *
   * These should likely not hit the FE longterm,
   * and should be removed if and when they are supported as imports instead.
   */
  SEG = 'SEG', // Segmentation
  SR = 'SR', // Structured Report
  FIDS = 'FIDS', // Fiducials
  KER = 'KER', // Keratometry
  OAM = 'OAM', // Ophthalmic Axial Measurements

  /**
   * Radiotherapy - Currently no official support for these.
   */
  RTDOSE = 'RTDOSE', // Radiotherapy Dose
  RTIMAGE = 'RTIMAGE', // Radiotherapy Image
  RTINTENT = 'RTINTENT', // Radiotherapy Intent
  RTPLAN = ' RTPLAN', // Radiotherapy Plan
  RTRAD = 'RTRAD', // Radiotherapy Radiation Report
  RTRECORD = 'RTRECORD', // Radiotherapy Treatment Record
  RTSEGANN = 'RTSEGANN', // Radiotherapy Segment Annotation
  RTSTRUCT = 'RTSTRUCT', // Radiotherapy Structure Set

  /**
   * Non-standard entries on prod
   */
  XR = 'XR',
  DR = 'DR', // Digital Radiography

  /**
   * Modalities defined by our own extractor.
   */
  unknown = 'unknown',
  Unknown = 'Unknown',
  mixed = 'mixed',

  /**
   * "Modality" we assign to NIFTI uploads
   */
  nifti = 'nifti',
}
