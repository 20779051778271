import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { AnnotationsFramePackage, PrecalculatedStoreType } from './AnnotationsFramePackage'
import type { PartialRecord } from '@/core/helperTypes'
import type { V2AnnotationPayload } from '@/store/types'
import { getMainAnnotationType, MainAnnotationType } from '@/core/annotationTypes'
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import { calcAnnotationFrame } from './calcAnnotationFrame'
import { isVideoAnnotationDataPayload } from '@/modules/Editor/models/annotation/annotationKindValidator'
import { isAnnotationOutOfView } from '@/modules/Editor/utils/outOfViewUtils'

export const addAnnotationForPackage = (
  precalculatedStore: PrecalculatedStoreType,
  annotationsPackage: AnnotationsFramePackage,
  annotation: V2AnnotationPayload,
  editorAnnotation: Annotation | undefined,
  slotName: string,
  frameIndex: number,
  editorClassesById: PartialRecord<number, AnnotationClass>,
  config: {
    totalFrames: number
    videoAnnotationDuration: number
    isProcessedAsVideo: boolean
  },
): void => {
  const annotationClass = editorClassesById[annotation.annotation_class_id]
  if (!annotationClass) {
    throw new Error('Annotation class not found')
  }

  const type = getMainAnnotationType(annotationClass.annotation_types)
  if (!type) {
    throw new Error('Annotation class does not have a valid type')
  }

  // Annotations package should not contain masks or raster layers
  if (type === MainAnnotationType.Mask || type === MainAnnotationType.RasterLayer) {
    return
  }

  if (type === MainAnnotationType.Tag) {
    annotationsPackage.tagAnnotations.unshift(annotation)
    return
  }

  const parsedAnnotationData = calcAnnotationFrame(
    annotation,
    slotName,
    frameIndex,
    annotationClass,
    config,
  )

  if (!parsedAnnotationData) {
    throw new Error('Annotation data is not valid')
  }

  annotationsPackage.storeAnnotations.unshift(annotation)
  annotationsPackage.orderedAnnotationIds.unshift(annotation.id)

  if (editorAnnotation) {
    annotationsPackage.editorAnnotations.unshift(editorAnnotation)
  }
  annotationsPackage.annotationsMap[annotation.id] = editorAnnotation

  // TODO: DAR-3946 we should replace this solution with marking annotation as hidden for on layer
  if (
    isVideoAnnotationDataPayload(annotation.data) &&
    annotation.data.hidden_areas &&
    isAnnotationOutOfView(annotation.data.hidden_areas, frameIndex)
  ) {
    return
  }

  annotationsPackage.annotationsRenderData.itemsBBoxMap.set(
    annotation.id,
    parsedAnnotationData.bbox,
  )
  annotationsPackage.annotationsRenderData.rTreeItems.unshift(parsedAnnotationData.rTreeItem)
  annotationsPackage.annotationsRenderData.itemsMap.set(annotation.id, parsedAnnotationData.item)
  annotationsPackage.annotationsRenderData.zIndexesList.push(annotation.id)

  let renderableData = precalculatedStore.renderableData.get(frameIndex)
  if (!renderableData) {
    renderableData = {
      itemsBBox: new Map(),
      rTreeItems: new Map(),
      renderableItems: new Map(),
    }
    precalculatedStore.renderableData.set(frameIndex, renderableData)
  }
  renderableData.itemsBBox.set(annotation.id, parsedAnnotationData.bbox)
  renderableData.rTreeItems.set(annotation.id, parsedAnnotationData.rTreeItem)
  renderableData.renderableItems.set(annotation.id, parsedAnnotationData.item)
}
