import OurVue from 'vue'
import Intercom from './lib'

let intercomInstalled = false

export function install(Vue, options = {}) {
  if (OurVue !== Vue) {
    console.error('Multiple instances of Vue detected.')
    return
  }

  const { appId } = options

  if (!appId) {
    console.warn('You did not provide Intercom appId. Please check your configuration.')
    return
  }

  const intercom = new Intercom({ appId })

  Vue.mixin({
    async beforeCreate() {
      if (intercomInstalled) return

      // https://developers.intercom.com/installing-intercom/docs/basic-javascript
      if (typeof window.Intercom === 'function') {
        this.$intercom.init()
        this.$intercom.callIntercom('reattach_activator')
        this.$intercom.update()
      } else {
        const placeholder = (...args) => placeholder.c(args)
        placeholder.q = []
        placeholder.c = (args) => placeholder.q.push(args)
        window.Intercom = placeholder

        this.$intercom = Vue.observable(intercom)
        Vue.prototype.$intercom = this.$intercom
      }

      intercomInstalled = true
    },
  })
}
