import type { TeamAction } from '@/store/modules/team/types'
import type { InvitationPayload } from '@/store/types/InvitationPayload'
import * as api from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

export type GetInvitationsPayload = {
  teamId: number
}

/**
 * Load current team invitations
 */
export const getInvitations: TeamAction<GetInvitationsPayload, InvitationPayload[]> = async (
  { commit },
  { teamId },
) => {
  let response
  try {
    response = await api.get<InvitationPayload[]>('invitations', {
      team_id: teamId,
      confirmed: false,
    })
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.TEAM_MEMBERS_LOAD_INVITATIONS)
  }

  commit('SET_INVITATIONS', response.data)
  return response
}
