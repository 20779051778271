import { EventEmitter } from 'events'

import type {
  LoadedImageWithTiles,
  TileCacheImage,
} from '@/modules/Editor/models/annotation/LoadedImageWithTiles'
import type { Tile } from '@/modules/Editor/models/tiler'
import { createTiler } from '@/modules/Editor/models/tiler'
import type { TiledView } from '@/modules/Editor/views/tiledView'

export enum Events {
  TILES_LOADED = 'tiles:loaded',
}

export class TilesManager extends EventEmitter {
  private tilesUrls: { [k: string]: string } = {}
  private tiles: { [k: string]: TileCacheImage } = {}
  private tiler = createTiler()

  constructor(private view: TiledView) {
    super()
  }

  public get imageWidth(): number {
    return this.view.fileManager.imageWidth || 0
  }

  public get imageHeight(): number {
    return this.view.fileManager.imageHeight || 0
  }

  private get tiledImage(): Partial<LoadedImageWithTiles> {
    return {
      width: this.imageWidth,
      height: this.imageHeight,

      /** Only available if the full image has been loaded (not just metadata) */
      data: this.view.currentFrame,

      levels: this.view.fileManager.metadata?.levels,

      cache: this.tiles,
    }
  }

  public async getTiles(
    tiles: { x: number; y: number; z: number }[],
  ): Promise<{ [k in string]: string }> {
    // Current implementation of channels only allow 1 channel at the time
    const fileManagers =
      this.view.activeChannels.length > 0
        ? this.view.activeChannels.map((channel) => channel.fileManager)
        : [this.view.fileManager]
    const responses = await Promise.all(
      fileManagers.map((fileManager) =>
        this.view.editor.loadTiles(fileManager.item.id, fileManager.file.slot_name, tiles),
      ),
    )

    if (!responses?.[0]) {
      return {}
    }

    // Merge together in an object string urls coming from all tiles
    this.tilesUrls = Object.assign({}, ...responses)

    this.emit(Events.TILES_LOADED)

    return this.tilesUrls
  }

  /**
   * Invalidates the cache and get the visible tiles again
   */
  public reloadVisibleTiles(neighbourTiles = false, throttled = true): void {
    this.tiles = {}
    this.getVisibleTiles(neighbourTiles, throttled)
  }

  public getVisibleTiles(neighbourTiles = false, throttled = true): Tile[] {
    return this.tiler.getVisibleTiles(this.tiledImage, this.view, neighbourTiles, throttled, () => {
      this.view.mainLayer.changed()
    })
  }

  public cleanup(): void {
    this.tilesUrls = {}
    this.tiles = {}
  }
}
