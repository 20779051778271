import type { EditablePoint } from './point'
import { createEditablePoint } from './point'
import type { Polygon } from './polygonOperations'

/**
 * A compound path is how we encode a polygon with multiple regions or holes.
 * The path field is the primary region and if it's a basic polygon, it will
 * be the only field with data.
 *
 * Every additional path represents either a hole or a region using the even-odd
 * rule.
 *
 * When an additional path region is rendered, the part of that new region
 * that overlaps of with a previously rendered region will render as a hole
 * in the previous region. Non-overlapping parts render as new regions.
 *
 * ### As an example
 *
 * To render the letter B, you would have
 * - the main path representing the outline of the letter
 * - 2 smaller additional paths representing the holes.
 *
 * If you were to render the letters B and D at the same time, you would have
 * - 1 main path for either the outline of B or the outline of D, depending on
 * which one was drawn first
 * - 1 additional path for the outline of the other letter
 * - 3 additional paths for the holes on the letters B and D
 */
export interface CompoundPath {
  path: EditablePoint[]
  additionalPaths: EditablePoint[][]
}

/**
 * Builds a { xmin, xmax, ymin, ymax } object, representing a rectangle
 * circumscribing the given compound path. The fields of the object represent
 * the minimum and maximum x and y coordinates of the compound path.
 */
export const compoundPathOuterBox = (
  compoundPath: CompoundPath,
): { xmin: number; xmax: number; ymin: number; ymax: number } => {
  const outerBox = { xmin: Infinity, xmax: 0, ymin: Infinity, ymax: 0 }
  for (const p of [compoundPath.path, ...compoundPath.additionalPaths]) {
    for (const point of p) {
      if (point.x < outerBox.xmin) {
        outerBox.xmin = point.x
      }
      if (point.x > outerBox.xmax) {
        outerBox.xmax = point.x
      }
      if (point.y < outerBox.ymin) {
        outerBox.ymin = point.y
      }
      if (point.y > outerBox.ymax) {
        outerBox.ymax = point.y
      }
    }
  }
  return outerBox
}

/**
 * Converts a PolyBool Polygon to a CompoundPath
 * @param polyBoolPolygon the Polygon object to be converted
 */
export const fromPolyBool = (polyBoolPolygon: Polygon): CompoundPath => {
  const [path, ...additionalPaths] = polyBoolPolygon.regions
    .filter((path) => path.length >= 3)
    .map((path) => path.map((coord) => createEditablePoint({ x: coord[0], y: coord[1] })))

  if (!path) {
    return { path: [], additionalPaths: [] }
  }
  return { path, additionalPaths }
}

/**
 * Converts a PolyBool CompoundPath to a Polygon
 * @param compoundPath the CompoundPath object to be converted
 */
export const toPolyBool = (compoundPath: CompoundPath): Polygon => {
  const { path, additionalPaths } = compoundPath
  const paths = additionalPaths ? [path, ...additionalPaths] : [path]
  return {
    regions: paths.map((path) => path.map((point) => [point.x, point.y])),
    inverted: false,
  }
}

export const polygonToCompoundPath = (polygon: {
  path: EditablePoint[]
  additionalPaths?: EditablePoint[][]
}): CompoundPath => ({
  path: polygon.path,
  additionalPaths: polygon.additionalPaths || [],
})
