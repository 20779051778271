import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'

/**
 * Empty tool to migrate tools to the new architecture.
 * The main purpose of this tool is to setup Pan & Zoom since not each tool has this functionality.
 * NOTE: Later we will implement it on the Editor/view level.
 */
class EmptyTool implements Tool {
  activate(context: ToolContext): void {
    setupMouseButtonLoadout(context, { middle: true })
  }

  deactivate(): void {}

  reset(): void {}
}

export const emptyTool = new EmptyTool()
