import type { MedicalMetadata } from '@/modules/Editor/MedicalMetadata'
import type { MedicalVolumePlane } from '@/modules/Editor/MedicalMetadata'

const defaultSlotNames = {
  AXIAL: '0.1',
  CORONAL: '0.2',
  SAGITTAL: '0.3',
}

export const getSlotNamesPerPlane = (
  medicalMetadata: MedicalMetadata | undefined,
): Record<MedicalVolumePlane, string> => {
  if (medicalMetadata === undefined) {
    return defaultSlotNames
  }

  const planeMap = medicalMetadata.plane_map
  const axialSlotName = Object.keys(planeMap).find((key) => planeMap[key] === 'AXIAL')
  const coronalSlotName = Object.keys(planeMap).find((key) => planeMap[key] === 'CORONAL')
  const sagittalSlotName = Object.keys(planeMap).find((key) => planeMap[key] === 'SAGITTAL')

  return {
    AXIAL: axialSlotName ?? defaultSlotNames.AXIAL,
    CORONAL: coronalSlotName ?? defaultSlotNames.CORONAL,
    SAGITTAL: sagittalSlotName ?? defaultSlotNames.SAGITTAL,
  }
}
