import type { FrameData } from '@/core/annotations'
import type { Polygon } from '@/modules/Editor/AnnotationData'
import { createEditablePoint } from '@/modules/Editor/point'

export const polygonSerializer = {
  serialize(polygon: Polygon): FrameData {
    const inference = polygon.inference?.confidence ? polygon.inference : undefined

    const path = polygon.path.map(({ x, y }) => ({ x, y }))

    const additionalPaths =
      polygon.additionalPaths && polygon.additionalPaths.length > 0
        ? polygon.additionalPaths.map((path) => path.map(({ x, y }) => ({ x, y })))
        : undefined

    const res: FrameData = {
      polygon: { path, additional_paths: additionalPaths },
    }

    if (polygon.auto_annotate?.model) {
      res.auto_annotate = polygon.auto_annotate
    }

    if (inference) {
      res.inference = inference
    }

    return res
  },

  deserialize(rawData: FrameData): Polygon | null {
    if (!rawData.polygon) {
      return null
    }
    const path = rawData.polygon.path.map((point) => createEditablePoint(point))

    const autoAnnotate = rawData?.auto_annotate

    const inference = rawData?.inference?.confidence ? rawData?.inference : undefined

    const additionalPaths = rawData.polygon.additional_paths
      ? rawData.polygon.additional_paths.map((path) =>
          path.map((point) => createEditablePoint(point)),
        )
      : undefined

    const res: Polygon = { path, additionalPaths }

    if (inference) {
      res.inference = inference
    }

    if (autoAnnotate) {
      res.auto_annotate = autoAnnotate
    }

    return res
  },
}
