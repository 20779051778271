import { watch } from 'vue'

import { useTeamStore } from '@/pinia/useTeamStore'

import { errorsByCode } from '@/backend/error/errors'
import { loadCustomerPlan } from '@/backend/darwin/loadCustomerPlan'

import { usePlanStore } from './usePlanStore'

export const usePlanLoader = (): {
  loadPlan: () => Promise<void>
  autoLoadPlanOnTeamChange: () => void
} => {
  const teamStore = useTeamStore()
  const planStore = usePlanStore()

  const loadPlan = async (): Promise<void> => {
    if (!teamStore.currentTeam) {
      return
    }

    const result = await loadCustomerPlan({ teamSlug: teamStore.currentTeam.slug })

    if ('error' in result) {
      planStore.customerPlanFetchError = errorsByCode.LOAD_CUSTOMER_PLAN

      if (result.error.status === 404) {
        planStore.hasCustomerPlanLoaded = true
      }

      return
    }

    planStore.hasCustomerPlanLoaded = true
    planStore.customerPlan = result.data
  }

  const autoLoadPlanOnTeamChange = (): void => {
    watch(
      () => teamStore.currentTeam,
      () => {
        planStore.hasCustomerPlanLoaded = false
        planStore.customerPlan = null
        loadPlan()
      },
      { immediate: true },
    )
  }

  return {
    loadPlan,
    autoLoadPlanOnTeamChange,
  }
}
