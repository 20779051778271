import { v4 as uuidv4 } from 'uuid'

import {
  getMainAnnotationType,
  MainAnnotationType,
  type AnnotationType,
} from '@/core/annotationTypes'
import type { FrameData } from '@/core/annotations'
import { getAnnotationClassColor, type AnnotationClass } from '@/modules/Editor/AnnotationClass'
import type { AnnotationData, VideoAnnotationData } from '@/modules/Editor/AnnotationData'
import type { InferenceResult } from '@/modules/Editor/backend'
import type { VideoAnnotation, VideoSubAnnotations } from '@/modules/Editor/models/annotation/types'
import type { AnnotationProperties } from '@/modules/Editor/models/annotation/types'
import type { View } from '@/modules/Editor/views/view'
// eslint-disable-next-line boundaries/element-types
import type { V2AnnotationPayload } from '@/store/types/StageAnnotationPayload'
// eslint-disable-next-line boundaries/element-types
import { getRGBAColorHash, rgbaString, type RGBA } from '@/uiKit/colorPalette'

import type { Annotation } from './Annotation'
import {
  isImageAnnotationDataPayload,
  isMaskAnnotationData,
  isVideoAnnotationData,
  isVideoAnnotationDataPayload,
} from './annotationKindValidator'
import { initializeVideoSubAnnotations, initializeSubAnnotations } from './initializeAnnotations'
import { normalizeAnnotationData, normalizeMaskData } from './normalize'
import type { TrainedModelPayload } from '@/backend/wind/types'

/**
 * Annotation parameters used to instantiate a new Annotation.
 */
export type CreateAnnotationParams<T = AnnotationData | VideoAnnotationData> = {
  id?: string
  parentId?: string
  annotationClass: AnnotationClass
  data: T
  subAnnotations?: T extends VideoAnnotationData ? VideoSubAnnotations : Annotation[]
  properties?: AnnotationProperties
  classId: number
  label: string
  color: RGBA
  type: AnnotationType
}

/**
 * Annotation parameters used to instantiate a new SubAnnotation.
 */
export type SubAnnotationPayload = {
  parent: Annotation
  data: AnnotationData | VideoAnnotationData
  type: AnnotationType
}

const createAnnotation = <T extends AnnotationData | VideoAnnotationData>(
  params: CreateAnnotationParams<T>,
): T extends VideoAnnotationData ? VideoAnnotation : Annotation =>
  ({
    id: params.id || uuidv4(),
    parentId: params.parentId,
    classId: params.classId,
    annotationClass: params.annotationClass,
    data: params.data,
    label: params.label,
    color: params.color,
    properties: params.properties,
    subAnnotations:
      params.subAnnotations || (isVideoAnnotationData(params.data) ? { frames: {} } : []),
    type: params.type,
  }) as T extends VideoAnnotationData ? VideoAnnotation : Annotation

/**
 * Factory method to create an Annotation instance from a StageAnnotation payload.
 *
 * @param editor Editor who will own this annotation
 * @param payload Annotation Payload with Renderable Data
 *
 * @returns the Annotations instance instance.
 */
export const createAnnotationFromDeserializable = (
  annotationClass: AnnotationClass,
  payload: Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'>,
): Annotation | null => {
  try {
    const type = getMainAnnotationType(annotationClass.annotation_types)

    if (!type) {
      throw new Error('Cannot resolve annotation type')
    }

    const data = normalizeAnnotationData(type, payload.data)

    if (!data) {
      throw new Error('Cannot normalize annotation data')
    }

    const instanceParams: CreateAnnotationParams = {
      id: payload.id,
      annotationClass,
      data,
      type,
      classId: annotationClass.id,
      label: annotationClass.name,
      color: getAnnotationClassColor(annotationClass),
      properties: payload.properties,
    }

    const annotation = createAnnotation(instanceParams)

    if (isVideoAnnotationDataPayload(payload.data)) {
      annotation.subAnnotations = initializeVideoSubAnnotations(annotation, payload.data)
    }

    if (isImageAnnotationDataPayload(payload.data)) {
      annotation.subAnnotations = initializeSubAnnotations(annotation, payload.data)
    }

    return annotation
  } catch (err) {
    console.warn(err)
    return null
  }
}

export const createMaskAnnotationFromDeserializable = (
  view: View,
  annotationClass: AnnotationClass,
  payload: Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'>,
): Annotation | null => {
  try {
    const type = getMainAnnotationType(annotationClass.annotation_types)

    if (!type) {
      throw new Error('Cannot resolve annotation type')
    }

    if (type !== MainAnnotationType.Mask && !isMaskAnnotationData(payload.data)) {
      throw new Error('Cannot resolve annotation type')
    }

    const data = normalizeMaskData(view, payload.data)

    if (!data) {
      throw new Error('Cannot normalize annotation data')
    }

    const instanceParams: CreateAnnotationParams = {
      id: payload.id,
      annotationClass,
      data,
      type,
      classId: annotationClass.id,
      label: annotationClass.name,
      color: getAnnotationClassColor(annotationClass),
      properties: payload.properties,
    }

    const annotation = createAnnotation(instanceParams)

    if (isVideoAnnotationDataPayload(payload.data)) {
      annotation.subAnnotations = initializeVideoSubAnnotations(annotation, payload.data)
    }

    if (isImageAnnotationDataPayload(payload.data)) {
      annotation.subAnnotations = initializeSubAnnotations(annotation, payload.data)
    }

    return annotation
  } catch (err) {
    console.warn(err)
    return null
  }
}

export const createMaskAnnotationOrAnnotationFromDeserializable = (
  view: View,
  editorClass: AnnotationClass,
  storeAnnotation: Omit<V2AnnotationPayload, 'actors' | 'annotation_group_id'>,
): Annotation | null => {
  const type = getMainAnnotationType(editorClass.annotation_types)
  const isMask = type === MainAnnotationType.Mask || isMaskAnnotationData(storeAnnotation.data)

  return isMask
    ? createMaskAnnotationFromDeserializable(view, editorClass, storeAnnotation)
    : createAnnotationFromDeserializable(editorClass, storeAnnotation)
}

/**
 * Factory method to create a Sub Annotation from a parent Annotation.
 *
 * @param view The associated view for this annotation.
 * @param subAnnotationPayload Payload used to create a new annotation.
 * @param subAnnotationPayload.parent The SubAnnotation's parent Annotation.
 * @param subAnnotationPayload.data The data used to create the SubAnnotation.
 * @param subAnnotationPayload.type The type of SubAnnotation.
 *
 * @returns The new SubAnnotation instance.
 */
export const createSubAnnotation = (subAnnotationPayload: SubAnnotationPayload): Annotation =>
  createAnnotation({
    annotationClass: subAnnotationPayload.parent.annotationClass,
    data: subAnnotationPayload.data,
    id: uuidv4(),
    parentId: subAnnotationPayload.parent.id,
    subAnnotations: [],
    type: subAnnotationPayload.type,
    classId: subAnnotationPayload.parent.annotationClass.id,
    label: subAnnotationPayload.parent.annotationClass.name,
    color: getAnnotationClassColor(subAnnotationPayload.parent.annotationClass),
  })

/**
 * Factory method to create an Annotation instance from from a set of parameters.
 *
 * @param view The associated view for this annotation.
 * @param instanceParams The parameters used to construct the Annotation.
 *
 * @returns The new Annotation instance.
 */
export const createAnnotationFromInstanceParams = createAnnotation

/**
 * Factory method to create an Annotation from inference data.
 *
 * @param view The associated view for this annotation.
 * @param inferenceData The inference data used to construct the Annotation.
 *
 * @returns The new Annotation instance.
 */
export const createAnnotationFromInferenceData = (
  inferenceData: InferenceResult,
  inferenceClasses: TrainedModelPayload['classes'],
): Annotation | null => {
  try {
    const inferenceLabel = inferenceData.label || inferenceData.name
    if (!inferenceLabel) {
      throw new Error('Cannot construct inference result. Missing label')
    }

    const inferenceClass = inferenceClasses.find((c) => c.name === inferenceLabel)
    if (!inferenceClass) {
      throw new Error(`Cannot construct inference result. Unknown label: ${inferenceLabel}`)
    }

    const { type } = inferenceClass
    const label = inferenceClass.display_name || inferenceClass.name

    const id = inferenceData.id || uuidv4()

    // InferenceData is a special edge case that is actually compatible with FrameData, somewhat
    // We cast here to satisfy typescript, but if this logic remains for a longer time, we really
    // should rewrite normalizeAnnotationData properly
    const normalizedData = normalizeAnnotationData(type, inferenceData as FrameData)
    if (!normalizedData) {
      throw new Error('Cannot normalize annotation data')
    }

    const color = getRGBAColorHash(label)

    const instanceParams: CreateAnnotationParams = {
      id,
      type,
      annotationClass: {
        id: -1,
        name: label,
        annotation_types: [type],
        color,
        colorRGBAstring: rgbaString(color),
      },
      label,
      classId: -1,
      color,
      data: normalizedData,
    }

    const annotation = createAnnotation(instanceParams)

    const subAnnotationData = { ...instanceParams.data } as FrameData
    if (inferenceData.inference) {
      // FIXME
      // This is likely a bug. The right side is InferenceMetadata and left side is InferenceData
      // The two are not exactly the same, but do have some overlap
      subAnnotationData.inference = inferenceData.inference as FrameData['inference'] | undefined
    }
    if (inferenceData.text) {
      subAnnotationData.text = inferenceData.text
    }

    annotation.subAnnotations = initializeSubAnnotations(annotation, subAnnotationData)

    return annotation
  } catch (err) {
    console.warn(err)
    return null
  }
}

export const createMaskAnnotationFromInferenceData = (
  view: View,
  inferenceData: InferenceResult,
  inferenceClasses: TrainedModelPayload['classes'],
): Annotation | null => {
  try {
    const inferenceLabel = inferenceData.label || inferenceData.name
    if (!inferenceLabel) {
      throw new Error('Cannot construct inference result. Missing label')
    }

    const inferenceClass = inferenceClasses.find((c) => c.name === inferenceLabel)
    if (!inferenceClass) {
      throw new Error(`Cannot construct inference result. Unknown label: ${inferenceLabel}`)
    }

    const { type } = inferenceClass
    const label = inferenceClass.display_name || inferenceClass.name

    const id = inferenceData.id || uuidv4()

    if (type !== MainAnnotationType.Mask && !isMaskAnnotationData(inferenceData as FrameData)) {
      return null
    }

    // InferenceData is a special edge case that is actually compatible with FrameData, somewhat
    // We cast here to satisfy typescript, but if this logic remains for a longer time, we really
    // should rewrite normalizeAnnotationData properly

    const normalizedData = normalizeMaskData(view, inferenceData as FrameData)
    if (!normalizedData) {
      throw new Error('Cannot normalize annotation data')
    }

    const color = getRGBAColorHash(label)

    const instanceParams: CreateAnnotationParams = {
      id,
      type,
      annotationClass: {
        id: -1,
        name: label,
        annotation_types: [type],
        color,
        colorRGBAstring: rgbaString(color),
      },
      label,
      classId: -1,
      color,
      data: normalizedData,
    }

    const annotation = createAnnotation(instanceParams)

    const subAnnotationData = { ...instanceParams.data } as FrameData
    if (inferenceData.inference) {
      // FIXME
      // This is likely a bug. The right side is InferenceMetadata and left side is InferenceData
      // The two are not exactly the same, but do have some overlap
      subAnnotationData.inference = inferenceData.inference as FrameData['inference'] | undefined
    }
    if (inferenceData.text) {
      subAnnotationData.text = inferenceData.text
    }

    annotation.subAnnotations = initializeSubAnnotations(annotation, subAnnotationData)

    return annotation
  } catch (err) {
    console.warn(err)
    return null
  }
}
