import type { AutoAnnotateInferencePayload, Click, InferenceData } from '@/modules/Editor/backend'
import type { Rectangle } from '@/modules/Editor/rectangle'
import type { Editor } from '@/modules/Editor/editor'
import type { PointMapping } from '@/modules/Editor/plugins/click/types'

import { isPreselectedModelAutoAnnotate } from '.'
import type { ClickImagePayload } from './getImagePayload'

const mappingOnClick = (click: Click, mapping: PointMapping): Click => {
  const convert = mapping.forward({ x: click.x, y: click.y })
  return {
    type: click.type,
    x: convert.x,
    y: convert.y,
  }
}

export const buildAutoAnnotateRequestPayload = (
  editor: Editor,
  clickImagePayload: ClickImagePayload,
  bbox: Rectangle,
  clicks: Click[],
  threshold?: number,
  original_bbox?: Rectangle,
): InferenceData | AutoAnnotateInferencePayload => {
  const { imagePayload, mapping, scaled } = clickImagePayload

  if (isPreselectedModelAutoAnnotate(editor)) {
    const data = {
      bbox,
      clicks: clicks.map((click) => mappingOnClick(click, mapping)),
      threshold,
    }
    const requestPayload: InferenceData = { image: imagePayload, data }

    // A dicom image needs window levels included
    if (editor.activeView.fileManager.isDicom) {
      requestPayload.data.dicom = {
        window_low: editor.activeView.imageFilter.windowLevels[0],
        window_high: editor.activeView.imageFilter.windowLevels[1],
      }
    }
    return requestPayload
  }

  const requestPayload: AutoAnnotateInferencePayload = {
    image: imagePayload,
    bbox,
    threshold,
    original_bbox: scaled ? original_bbox : undefined,
    item_metadata: {
      item_id: editor.activeView.fileManager.item.id,
      slot_name: editor.activeView.fileManager.slotName,
      section_index: editor.activeView.currentFrameIndex,
    },
  }

  // A dicom image needs window levels included
  if (editor.activeView.fileManager.isDicom) {
    requestPayload.dicom = {
      window_low: editor.activeView.imageFilter.windowLevels[0],
      window_high: editor.activeView.imageFilter.windowLevels[1],
    }
  }

  return requestPayload
}
