import type { MainAnnotationType } from '@/core/annotationTypes'
import type { FrameData } from '@/core/annotations'
import type { RenderingContext2D } from '@/modules/Editor/types'
import { calcCentroidByType } from '@/modules/Editor/utils/calcCentroidByType'

import { drawVector } from './drawVector'

type PropsType = {
  lineWidth: number
  scale: number
}

export const drawDirectionalVectorByType = (
  ctx: RenderingContext2D,
  type: MainAnnotationType,
  data: FrameData,
  props: PropsType,
): void => {
  if (!data.directional_vector) {
    return
  }

  const centroid = calcCentroidByType(type, data)
  if (!centroid) {
    return
  }

  drawVector(ctx, centroid, data.directional_vector, {
    strokeColor: 'rgb(255, 255, 255)',
    fillColor: 'rgb(255, 255, 255)',
    lineWidth: props.lineWidth,
    scale: props.scale,
  })
}
