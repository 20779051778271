import { setContext } from '@/services/sentry'

export class PlayVideoError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'PlayVideoError'
  }
}

export const playVideo = async (video?: HTMLVideoElement): Promise<void> => {
  if (!video) {
    throw new PlayVideoError('Video element is not defined')
  }
  try {
    await video.play()
  } catch (error) {
    if (error instanceof DOMException) {
      // We can get this error when pause is called while the play promise is still pending
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play
      // We should have removed this scenario by using the variable in the video playback
      // loop so that pause is not called while the play promise is still pending, but we
      // can still add it to Sentry in case it happens again.
      setContext('video_play_error', {
        error: error.name,
        message: error.message,
        stack: error.stack,
      })
    }
  }
}
