import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import type { WindowLevel } from '@/modules/Editor/imageManipulation'
import { isLeftMouseButton } from '@/modules/Editor/mouse'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'

import { getSensitivy } from './helpers/getSensitivity'
import { initWindowLevelState } from './helpers/initWindowLevelState'
import { getWindowLevel, setWindowLevel } from './helpers/windowLevelController'

interface WindowLevelTool extends Tool {
  onStart(context: ToolContext, event: PointerEvent): void
  onMove(context: ToolContext, event: PointerEvent): void
  onEnd(context: ToolContext, event: PointerEvent): void
}

let windowLevelState = initWindowLevelState()

/**
 * Window level mouse Tool for left click.
 */
export const windowLevelTool: WindowLevelTool = {
  onStart(context: ToolContext) {
    windowLevelState.active = true

    const activeView = context.editor.activeView

    const { metadata } = activeView.fileManager
    const colorspace = metadata?.colorspace
    const { width, height } = activeView.camera

    windowLevelState.sensitivity = getSensitivy(width, height, colorspace)
  },

  onMove(context: ToolContext, event: PointerEvent) {
    if (!windowLevelState.active) {
      return
    }

    const cursorPoint = resolveEventPoint(event)

    if (!cursorPoint) {
      return CallbackStatus.Stop
    }
    if (!windowLevelState.previousPoint) {
      windowLevelState.previousPoint = cursorPoint
      return CallbackStatus.Stop
    }

    windowLevelState.cursorPoint = cursorPoint

    const view = context.editor.activeView

    const { previousPoint } = windowLevelState

    if (cursorPoint.x - previousPoint.x !== 0 || cursorPoint.y - previousPoint.y !== 0) {
      const { windowSensitivity, levelSensitivity } = windowLevelState.sensitivity
      const currentWindowLevel = getWindowLevel(view)

      const dX = cursorPoint.x - previousPoint.x
      const dY = cursorPoint.y - previousPoint.y

      const newWindow: WindowLevel = {
        window: currentWindowLevel.window + dX * windowSensitivity,
        level: currentWindowLevel.level + dY * levelSensitivity,
      }
      setWindowLevel(context.editor.activeView, newWindow)
    }

    windowLevelState.previousPoint = cursorPoint
    return CallbackStatus.Stop
  },

  onEnd() {
    windowLevelState = initWindowLevelState()
  },

  activate(context: ToolContext) {
    setupMouseButtonLoadout(context, { middle: true })

    selectCursor(EditorCursor.BBox)

    context.handles.push(
      ...context.editor.onMouseDown((e) => {
        if (!isLeftMouseButton(e)) {
          return CallbackStatus.Continue
        }
        return this.onStart(context, e)
      }),
    )

    context.handles.push(...context.editor.onMouseMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onMouseUp((event) => this.onEnd(context, event)))

    context.handles.push(...context.editor.onTouchStart((event) => this.onStart(context, event)))
    context.handles.push(...context.editor.onTouchMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onTouchEnd((event) => this.onEnd(context, event)))
  },

  deactivate(context: ToolContext): void {
    this.reset(context)
  },

  reset(): void {
    windowLevelState = initWindowLevelState()
  },
}
