import { setContext } from '@/services/sentry'

const measureRunningTime = async (
  measurementName: string,
  fn: () => Promise<unknown>,
): Promise<void> => {
  const startMarkName = `${measurementName}-start`
  performance.mark(startMarkName)
  await fn().catch((e) => {
    setContext(`[measureRunningTime] ${measurementName}`, e)
    throw e
  })
  const endMeasure = performance.measure(measurementName, startMarkName)
  console.info(`${measurementName} took ${Math.round(endMeasure.duration)}ms`)
}

export default measureRunningTime
