import type { PartialRecord } from '@/core/helperTypes'
import { deleteMaskAnnotationsOnFrame } from '@/modules/Editor/plugins/mask/utils/shared/deleteMaskAnnotationsOnFrame'
import type { View } from '@/modules/Editor/views/view'

import type { Bounds, RasterDataSnapshot } from './Raster'
import { Raster } from './Raster'
import type { RasterBufferAccessor } from './RasterBufferAccessor'
import { RasterTypes } from './rasterTypes'
import type { VideoAnnotationDataSegment } from '@/modules/Editor/AnnotationData'
import { isVideoAnnotation } from '@/modules/Editor/models/annotation/annotationKindValidator'
import { encodeDenseRLE } from '@/modules/Editor/plugins/mask/rle/denseRle'

export type VideoBounds = Record<number, Bounds>
type VideoBoundsPerLabelIndex = Record<number, VideoBounds>
type LabelsPerKeyframe = Record<number, Set<number>>

export enum Events {
  VIDEO_RASTER_RANGE_UPDATED = 'videoraster.videorasterrangeupdated',
  VIDEO_RASTER_KEYFRAME_CREATED = 'videoraster.keyframecreated',
  VIDEO_RASTER_KEYFRAME_DELETED = 'videoraster.keyframedeleted',
}

/**
 * A raster that stores a bunch of buffers per frame. Only frames with data are included,
 * to reduce memory footprint, and they are created when needed on demand.
 */
export class VideoRaster extends Raster {
  public idSyncedWithApi: string | null = null
  public readonly type: RasterTypes = RasterTypes.VIDEO
  public frameBuffers: PartialRecord<number, Uint8Array> = {}
  public rasterRange: [number, number] = [0, 0]
  private videoBoundsPerLabelIndex: VideoBoundsPerLabelIndex = {}
  public readonly labelsPerKeyframe: LabelsPerKeyframe = {}

  constructor(view: View) {
    super(view)
  }

  /**
   * Takes a snapshot of the current state of the Raster.
   * This is only returning the snapshot, not setting it in any way.
   * Include the buffer for every frame, and data mappings to link annotations, labels and classes
   */
  public takeSnapshot(frameIndexes: number[]): RasterDataSnapshot {
    // Calculates the labels that are involved in the snapshot. Not all raster labels are used in
    // a partial frame range
    const labelsOnIndexes = frameIndexes.reduce((acc, frameIndex) => {
      this.getLabelsOnKeyframe(frameIndex).forEach((l) => acc.add(l))
      return acc
    }, new Set<number>())

    const snapshotData: RasterDataSnapshot = {
      denseRLE: {},
      maskAnnotationIdsMapping: structuredClone(this.getAnnotationToLabelMapping()),
      maskAnnotationClassIdsMapping: structuredClone(this.getAnnotationIdToClassIdMapping()),
      labelIndexToClassIdMapping: structuredClone(this.getLabelIndexToClassIdMapping()),
      labelIndexToAnnotationIdMapping: structuredClone(this.getLabelIndexToAnnotationMapping()),
      labelsInSnapshot: Array.from(labelsOnIndexes),
    }

    frameIndexes.forEach((frameIndex) => {
      const frameBuffer = this.frameBuffers[frameIndex]
      if (!frameBuffer) {
        // nothing to snapshot, skip
        return
      }
      snapshotData.denseRLE[frameIndex] = encodeDenseRLE(frameBuffer)
    })
    return snapshotData
  }

  public clearLabelsOnKeyframe(keyframe: number): void {
    this.labelsPerKeyframe[keyframe] = new Set()
  }

  public setLabelOnKeyframe(labelIndex: number, keyframe: number): void {
    if (!this.labelsPerKeyframe[keyframe]) {
      this.labelsPerKeyframe[keyframe] = new Set()
    }

    this.labelsPerKeyframe[keyframe].add(labelIndex)
    this.validateRasterRange()
  }

  public deleteLabelOnKeyframe(labelIndex: number, keyframe: number): void {
    if (this.labelsPerKeyframe[keyframe]) {
      this.labelsPerKeyframe[keyframe].delete(labelIndex)
      if (this.labelsPerKeyframe[keyframe].size === 0) {
        delete this.labelsPerKeyframe[keyframe]
      }
    }
    this.validateRasterRange()
  }

  public getLabelsOnKeyframe(keyframe: number): number[] {
    if (!this.labelsPerKeyframe[keyframe]) {
      return []
    }

    return Array.from(this.labelsPerKeyframe[keyframe])
  }

  /** Get all keyframes where a label is present **/
  public getLabelKeyframes(labelIndex: number): number[] {
    return Object.keys(this.labelsPerKeyframe)
      .filter((keyframe) => this.labelsPerKeyframe[Number(keyframe)]?.has(labelIndex))
      .map(Number)
  }

  /** Returns the range of frames where the given label is defined **/
  public getLabelRange(labelIndex: number): [number, number] {
    const boundsPerFrame = this.videoBoundsPerLabelIndex[labelIndex]
    if (!boundsPerFrame) {
      console.error('bounds per frame is undefined')
      return [0, 0]
    }

    const keyframes = Object.keys(boundsPerFrame).map(Number)

    if (keyframes.length === 0) {
      return [0, 0]
    }

    const first = Math.min(...keyframes)
    const last = Math.max(...keyframes)

    return [first, last]
  }

  /**
   * Returns an array of segments where the given label is not defined.
   * It works in the same way as annotation frame segments, where the upper bound is not
   * inclusive, so if frames 2 and 3 are empty, the result will be [[2, 4]].
   **/
  public getFramesWithoutLabel(labelIndex: number): [number, number][] {
    const framesWithoutLabel: [number, number][] = []

    const boundsPerFrame = this.videoBoundsPerLabelIndex[labelIndex]
    if (!boundsPerFrame) {
      console.error('bounds per frame is undefined')
      return framesWithoutLabel
    }

    const keyframes = Object.keys(boundsPerFrame).map(Number)

    let emptyRange: [number, number] | undefined
    for (let i = Math.min(...keyframes); i < Math.max(...keyframes); i++) {
      if (!keyframes.includes(i)) {
        // If we have an empty range, we should go on and check next frame
        if (emptyRange) {
          continue
        }

        // Let's create the empty frame object for the current frame range
        emptyRange = [i, -1]
        continue
      }

      // When keyframe has the label, and an empty range was previously found, then when finalise
      // it and add to the list
      if (emptyRange) {
        emptyRange[1] = i
        framesWithoutLabel.push(emptyRange)
        emptyRange = undefined
      }
    }

    // When the last frame is empty, then we need to close the range and add it to the list
    if (emptyRange) {
      emptyRange[1] = Math.max(...keyframes)
      framesWithoutLabel.push(emptyRange)
    }

    return framesWithoutLabel
  }

  public setVideoBoundsForLabelIndex(labelIndex: number, bounds: VideoBounds): void {
    this.videoBoundsPerLabelIndex[labelIndex] = bounds
  }

  public getVideoBoundsForLabelIndex(labelIndex: number): VideoBounds {
    return this.videoBoundsPerLabelIndex[labelIndex]
  }

  public setVideoBoundsForLabelIndexForFrame(
    labelIndex: number,
    keyframe: number,
    bounds: Bounds,
  ): void {
    if (!this.videoBoundsPerLabelIndex[labelIndex]) {
      this.videoBoundsPerLabelIndex[labelIndex] = {}
    }

    this.videoBoundsPerLabelIndex[labelIndex][keyframe] = bounds
  }

  public deleteVideoBoundsForLabelIndexForFrame(labelIndex: number, keyframe: number): void {
    if (this.videoBoundsPerLabelIndex[labelIndex]) {
      delete this.videoBoundsPerLabelIndex[labelIndex][keyframe]
    }
  }

  public getVideoBoundsForLabelIndexForFrame(
    labelIndex: number,
    keyframe: number,
  ): Bounds | undefined {
    const videoBoundsForLabelIndex = this.getVideoBoundsForLabelIndex(labelIndex)

    if (videoBoundsForLabelIndex) {
      return videoBoundsForLabelIndex[keyframe]
    }
  }

  protected deleteBoundsForLabelIndex(labelIndex: number): void {
    delete this.videoBoundsPerLabelIndex[labelIndex]
  }

  public deleteBoundsForFrame(frameIndex: number): void {
    Object.keys(this.videoBoundsPerLabelIndex).forEach((labelIndex) => {
      delete this.videoBoundsPerLabelIndex[Number(labelIndex)][frameIndex]
    })
  }

  /**
   * When we first create masks, they are created as image annotations.
   * As such we need a direct path to the active buffer for this first time a mask is drawn.
   */
  public getBufferForImageSerialization(): Uint8Array | undefined {
    const currentFrameIndex = this.view.currentFrameIndex

    return this.frameBuffers[currentFrameIndex]
  }

  /**
   * Gets a buffer in the passed frameIndex for editing:
   *
   * The intention here is that we always get an editable buffer for this frame, even if
   * this means we create a new keyframe as a side effect (ready for drawing on).
   *
   * If the buffer exists for the current frame, just return it.
   * If the buffer doesn't exist for the current frame, create a blank one.
   */
  public getBufferForEdit(frameIndex: number): RasterBufferAccessor {
    const { width, height } = this

    const currentFrameBuffer = this.frameBuffers[frameIndex] || this.createNewKeyframe(frameIndex)

    return {
      get(x: number, y: number): number {
        return currentFrameBuffer[y * width + x]
      },
      set(x: number, y: number, val: number): void {
        currentFrameBuffer[y * width + x] = val
      },
      width,
      height,
    }
  }

  /**
   * Gets the current buffer for editing
   */
  public getActiveBufferForEdit(): RasterBufferAccessor {
    const { currentFrameIndex } = this.view

    return this.getBufferForEdit(currentFrameIndex)
  }

  /**
   * Gets the current buffer for display:
   *
   * The intention here is that if we do not have a buffer for display on this frame
   * we display whatever the last frame was in the timeline as a preview.
   *
   * If we are outside the range, return undefined
   *
   * If the frame exists for the current image, just return it.
   */
  public getActiveBufferForRender(): RasterBufferAccessor | undefined {
    const { currentFrameIndex } = this.view
    const { width, height } = this

    // If we are past the upper range, return undefined
    if (this.rasterRange[1] < currentFrameIndex) {
      return
    }

    const currentFramBuffer = this.frameBuffers[currentFrameIndex]

    if (currentFramBuffer == undefined) {
      return
    }

    return {
      get(x: number, y: number): number {
        return currentFramBuffer[y * width + x]
      },
      set(x: number, y: number, val: number): void {
        currentFramBuffer[y * width + x] = val
      },
      width,
      height,
    }
  }

  public setActiveBufferForFrame(buffer: Uint8Array, frameIndex: number): void {
    this.frameBuffers[frameIndex] = buffer
  }

  /**
   * Updates the `rasterRange` based on the labels per keyframe.
   * It emits an event to notify the change.
   * A possible improvement is to get rid of `rasterRange` completely and instead have a method
   * to return the current raster range dynamically, so we have one less state to keep track of.
   */
  public validateRasterRange(): void {
    // Exclude frames with label 0
    const keyframesWithLabels = this.rasterKeyframesWithLabels
    this.rasterRange = [Math.min(...keyframesWithLabels), Math.max(...keyframesWithLabels)]
    this.emit(Events.VIDEO_RASTER_RANGE_UPDATED, this.id)
  }

  /**
   * The range is dynamically calculated based on the labels per keyframe, this method
   * will be cleaned up.
   * @deprecated
   */
  public setRange(min: number, max: number): void {
    this.rasterRange = [min, max]
    this.emit(Events.VIDEO_RASTER_RANGE_UPDATED, this.id)
  }

  /**
   * The range is dynamically calculated based on the labels per keyframe, using segments is
   * wrong. This method will be cleaned up.
   * @deprecated
   */
  public updateRasterRange(newSegments: VideoAnnotationDataSegment[]): void {
    const { rasterRange } = this

    // Note we have formal support for multiple segments, but currently only use one throughout
    // the system.
    const [newSegmentMin, newSegmentMax] = newSegments[0]

    if (rasterRange[0] > newSegmentMin) {
      rasterRange[0] = newSegmentMin
    }

    if (newSegmentMax === null) {
      return
    }

    // Note that annotation segments have a max which is one higher than the number of frames.
    // The raster's range is inclusive of frames it actually occupies, so we need to subtract one.
    const maxIndex = newSegmentMax - 1

    if (rasterRange[1] < maxIndex) {
      rasterRange[1] = maxIndex
    }
  }

  // TODO: return string[] to avoid cycling through large number of keys
  public get rasterKeyframes(): number[] {
    if (!this.frameBuffers) {
      throw new Error('no frame buffers were provided')
    }

    return Object.keys(this.frameBuffers).map(Number)
  }

  /**
   * Returns an array of keyframes that have labels associated with them.
   * Any frame with label 0 (no annotation) is excluded.
   *
   * @returns {number[]} An array of keyframes.
   */
  public get rasterKeyframesWithLabels(): number[] {
    if (!this.labelsPerKeyframe) {
      console.error('labelsPerKeyframe is undefined')
      return []
    }

    return Object.keys(this.labelsPerKeyframe).reduce((acc: number[], keyframe) => {
      const labelsOnKeyframe = new Set(this.labelsPerKeyframe[Number(keyframe)])
      labelsOnKeyframe.delete(0)
      if (labelsOnKeyframe.size > 0) {
        acc.push(Number(keyframe))
      }
      return acc
    }, [])
  }

  /*
   * For cases where we want to extend the raster range, without having new labels (new keyframe)
   * we can update the range using the frame buffers.
   * `frameBuffers` and `labelsWithKeyframes` should always be in sync, except for
   * new empty keyframes
   */
  protected updateRasterRangeFromBuffer(): void {
    const keyframes = this.rasterKeyframes
    this.rasterRange = [Math.min(...keyframes), Math.max(...keyframes)]
  }

  public createNewKeyframe(newFrameIndex: number): Uint8Array {
    const newFrameBuffer: Uint8Array = new Uint8Array(this.size)

    this.setActiveBufferForFrame(newFrameBuffer, newFrameIndex)
    // update the raster range so we can draw on the new frame
    this.updateRasterRangeFromBuffer()

    this.emit(Events.VIDEO_RASTER_KEYFRAME_CREATED, {
      rasterId: this.id,
      keyframe: newFrameIndex,
    })

    return newFrameBuffer
  }

  public deleteKeyframe(keyframe: number): void {
    deleteMaskAnnotationsOnFrame(this.view, this, keyframe)

    this.getLabelsOnKeyframe(keyframe).forEach((labelIndex) => {
      this.deleteVideoBoundsForLabelIndexForFrame(labelIndex, keyframe)
    })
    delete this.frameBuffers[keyframe]
    delete this.labelsPerKeyframe[keyframe]

    this.validateRasterRange()
    this.emit(Events.VIDEO_RASTER_KEYFRAME_DELETED, {
      rasterId: this.id,
      keyframe,
    })
  }

  protected copyPreviousFrame(
    currentFrameIndex: number,
    previousKeyframeIndex: number,
  ): Uint8Array {
    const newFrameBuffer = new Uint8Array(this.size)
    const previousFrameBuffer = this.frameBuffers[previousKeyframeIndex]

    if (previousFrameBuffer) {
      // Deep copy it's contents into the new buffer
      newFrameBuffer.set(previousFrameBuffer)
    }

    const previousFrameLabels = this.getLabelsOnKeyframe(previousKeyframeIndex)

    this.setLabelsOnKeyframe(previousFrameLabels, currentFrameIndex)

    // Copy ranges from previous for all labels.
    previousFrameLabels.forEach((labelIndex) => {
      const ranges = this.getVideoBoundsForLabelIndex(labelIndex)

      const previousRange = ranges[previousKeyframeIndex]

      this.setVideoBoundsForLabelIndexForFrame(
        labelIndex,
        currentFrameIndex,
        structuredClone(previousRange),
      )
    })

    return newFrameBuffer
  }

  private setLabelsOnKeyframe(labelIndicies: number[], keyframe: number): void {
    labelIndicies.forEach((labelIndex) => {
      this.setLabelOnKeyframe(labelIndex, keyframe)
    })
  }

  public getPreviousKeyframeIndex(currentFrameIndex: number): number | undefined {
    for (let f = currentFrameIndex; f >= 0; f--) {
      const frameBuffer = this.frameBuffers[f]

      if (frameBuffer) {
        return f
      }
    }
  }

  public deleteLabelFromRaster(labelIndex: number): void {
    const { frameBuffers, width } = this

    const boundsPerFrame = this.getVideoBoundsForLabelIndex(labelIndex)
    if (!boundsPerFrame) {
      console.error('bounds per frame is undefined')
      return
    }

    // Itterate through every keyframe that has that label and remove it
    Object.keys(boundsPerFrame)
      .map(Number)
      .forEach((keyframe) => {
        const bounds = boundsPerFrame[keyframe]
        const buffer = frameBuffers[keyframe]

        if (!buffer) {
          return
        }

        if (bounds) {
          const xMin = bounds.topLeft.x
          const xMax = bounds.bottomRight.x
          const yMin = bounds.topLeft.y
          const yMax = bounds.bottomRight.y

          for (let y = yMin; y < yMax; y++) {
            for (let x = xMin; x < xMax; x++) {
              const pixelIndex = y * width + x

              if (buffer[pixelIndex] === labelIndex) {
                buffer[pixelIndex] = 0
              }
            }
          }
        } else {
          const length = buffer.length

          for (let pixelIndex = 0; pixelIndex < length; pixelIndex++) {
            if (buffer[pixelIndex] === labelIndex) {
              buffer[pixelIndex] = 0
            }
          }
        }
      })

    this.deleteVideoBoundsForLabelIndex(labelIndex)
    this.deleteAnnotationMapping(labelIndex)
    this.deleteLabelFromAllKeyframes(labelIndex)
    this.invalidateAll()
  }

  private deleteVideoBoundsForLabelIndex(labelIndex: number): void {
    delete this.videoBoundsPerLabelIndex[labelIndex]
  }

  private deleteLabelFromAllKeyframes(labelIndex: number): void {
    const { labelsPerKeyframe } = this

    this.rasterKeyframes.forEach((keyframe) => {
      const labelsOnKeyframe = labelsPerKeyframe[keyframe]

      if (!labelsOnKeyframe) {
        return
      }

      labelsOnKeyframe.delete(labelIndex)

      if (Array.from(labelsOnKeyframe).length === 0) {
        delete this.frameBuffers[keyframe]
        delete this.labelsPerKeyframe[keyframe]
      }
    })

    this.validateRasterRange()
  }

  public isLabelUsedOnOtherKeyframe(labelIndex: number, frameIndex: number): boolean {
    const { labelsPerKeyframe } = this

    for (let i = 0; i < this.rasterKeyframes.length; i++) {
      const keyframe = this.rasterKeyframes[i]

      if (keyframe !== frameIndex) {
        const labelsOnKeyframe = labelsPerKeyframe[keyframe]

        if (labelsOnKeyframe?.has(labelIndex)) {
          return true
        }
      }
    }

    return false
  }

  /**
   * We shouldn't update the range based on the segments, this method will be cleaned up.
   * @deprecated
   */
  public updateRangeToRangeOfMaskAnnotations(): void {
    const annotations = this.getMaskAnnotationsOnRaster()

    if (!annotations.length) {
      return
    }

    let min = Infinity
    let max = -Infinity

    annotations.forEach((annotation) => {
      if (!isVideoAnnotation(annotation)) {
        return
      }

      const { segments } = annotation.data
      const firstSegment = segments && segments[0]

      if (firstSegment) {
        const [lower, upper] = firstSegment

        if (lower < min) {
          min = lower
        }

        if (upper && upper > max) {
          max = upper
        }
      }
    })

    this.setRange(min, Math.max(max - 1, min))
  }

  public getFileSlotName(): string {
    return this.view.fileManager.slotName
  }

  cleanup(): void {
    this.frameBuffers = {}
    this.videoBoundsPerLabelIndex = {}

    if (this.labelsPerKeyframe) {
      Object.keys(this.labelsPerKeyframe).forEach((keyframe) => {
        delete this.labelsPerKeyframe[Number(keyframe)]
      })
    }

    this.cachedCanvas?.remove()
    delete this.cachedCanvas
  }
}
