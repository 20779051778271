import type { Range } from '@/modules/Editor/types'

/**
 * Clips a given Range type object to fit within an image of the given
 * width and height.
 */
export const clipRangeToRasterBounds = (
  range: Range,
  imageWidth: number,
  imageHeight: number,
): Range => {
  const { minX, maxX, minY, maxY } = range

  return {
    minX: Math.max(minX, 0),
    maxX: Math.min(maxX, imageWidth),
    minY: Math.max(minY, 0),
    maxY: Math.min(maxY, imageHeight),
  }
}
