import type { SequenceData } from '@/core/annotations'
import type { PartialRecord } from '@/core/helperTypes'

type RasterDataMappingObject = {
  annotationIdToLabelIndex: PartialRecord<string, number>
  labelIndexToAnnotationId: PartialRecord<number, string>
  frameLabelIndexCorrection: Record<number, Record<number, number>>
  getLabelIndex(annotationId: string): number | undefined
  getMaskAnnotationId(labelIndex: number): string | undefined
}

/**
 * Get the mapping of annotationId to labelIndex for the whole video or voxel data.
 *
 * IMPORTANT: The BE can return different annotation id <=> label index mapping per frame.
 * This is because for each frame the label index count starts from 1, and when a mask is
 * missing on a frame, the label index is not incremented.
 * On the FE we want to have unique mapping, as labelIndexes must not be reused, this function
 * is helping achiving that, by making sure we get a unique label per annotationID.
 * This is also returning a correction map, so we can remap the label indexes per frame to the
 * corrected ones.
 */
export const getVideoOrVoxelDataMapping = (
  frames: SequenceData['frames'],
): RasterDataMappingObject => {
  const annotationIdToLabelIndex: Record<string, number> = {}
  const labelIndexToAnnotationId: Record<number, string> = {}
  const frameLabelIndexCorrection: Record<number, Record<number, number>> = {}

  // Need to make sure we get a unique label per annotationID.
  Object.keys(frames).forEach((frameIndexString) => {
    const frameIndex = Number(frameIndexString)
    const frame = frames[frameIndex]
    const rasterLayer = frame.raster_layer
    frameLabelIndexCorrection[frameIndex] = {}

    if (rasterLayer === undefined) {
      throw new Error('No raster_layer property on raster layer annotation')
    }

    const frameMaskAnnotationIdsMapping = rasterLayer.mask_annotation_ids_mapping

    Object.keys(frameMaskAnnotationIdsMapping).forEach((annotationId) => {
      const labelIndex =
        annotationIdToLabelIndex[annotationId] || Object.keys(annotationIdToLabelIndex).length + 1

      annotationIdToLabelIndex[annotationId] = labelIndex
      labelIndexToAnnotationId[labelIndex] = annotationId

      const frameLabelIndex = frameMaskAnnotationIdsMapping[annotationId]
      if (frameLabelIndex === undefined) {
        return
      }
      // Create the correction map so we can remap the buffer
      frameLabelIndexCorrection[Number(frameIndex)][frameLabelIndex] = labelIndex
    })
  })

  return {
    annotationIdToLabelIndex,
    labelIndexToAnnotationId,
    frameLabelIndexCorrection,
    getLabelIndex: (annotationId: string) => annotationIdToLabelIndex[annotationId],
    getMaskAnnotationId: (labelIndex: number) => labelIndexToAnnotationId[labelIndex],
  }
}
