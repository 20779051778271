import type { ActionTree, MutationTree, Module } from 'vuex'

import { PersistedSettings } from '@/core/utils/PersistedSettings'
import type { RootState } from '@/store/types'
import { WORKVIEW_VIDEO_FRAME_LINE_WIDTH_KEY } from '@/utils/localStorageKeys'

export type UIState = {
  sidebarMinimized: boolean
  showSettings: boolean
  currentSettingsTab: string
  workviewVideoFrameLineWidth: number
}

const persistedSettings = new PersistedSettings('localStorage')

const getSavedSidebarMinimizedStatus = (): boolean => {
  const minimized = window.localStorage.getItem('sidebar_minimized') || 'false'
  return minimized === 'true'
}

export const DEFAULT_VIDEO_FRAME_LINE_WIDTH = 4

export const getInitialState = (): UIState => ({
  sidebarMinimized: getSavedSidebarMinimizedStatus(),

  showSettings: false,
  currentSettingsTab: 'profile',

  workviewVideoFrameLineWidth: persistedSettings.loadValue(
    WORKVIEW_VIDEO_FRAME_LINE_WIDTH_KEY,
    DEFAULT_VIDEO_FRAME_LINE_WIDTH,
  ),
})

const state: UIState = getInitialState()

// actions
const actions: ActionTree<UIState, RootState> = {
  showSettingsDialog({ commit }, { tab }) {
    commit('SET_SHOW_SETTINGS', true)
    if (tab) {
      commit('SET_CURRENT_SETTINGS_TAB', tab)
    }
  },

  hideSettingsDialog({ commit }) {
    commit('SET_SHOW_SETTINGS', false)
  },

  /**
   * Save sidebar status
   */
  setSidebarStatus({ commit }, minimized) {
    window.localStorage.setItem('sidebar_minimized', minimized ? 'true' : 'false')
    commit('SET_SIDEBAR_MINIMIZED', minimized)
  },

  reloadSidebarStatus({ commit }) {
    commit('SET_SIDEBAR_MINIMIZED', getSavedSidebarMinimizedStatus())
  },
}

// mutations
const mutations: MutationTree<UIState> = {
  SET_SIDEBAR_MINIMIZED(state, minimized) {
    state.sidebarMinimized = minimized
  },

  SET_SHOW_SETTINGS(state, visibility) {
    state.showSettings = visibility
  },

  SET_CURRENT_SETTINGS_TAB(state, tab) {
    state.currentSettingsTab = tab
  },

  SET_WORKVIEW_VIDEO_FRAME_LINE_WIDTH(state, lineWidth: number) {
    state.workviewVideoFrameLineWidth = lineWidth
    persistedSettings.saveValue(WORKVIEW_VIDEO_FRAME_LINE_WIDTH_KEY, lineWidth)
  },
}

const ui: Module<UIState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
}

export default ui
