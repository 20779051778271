import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'

export const imageFilterToString = (filter: ImageManipulationFilter): string => {
  let filterString = ''

  if (filter.colorMap !== 'default') {
    /**
     * Sets color map filter using ColorMaps.vue
     * by its id
     */
    filterString += ` url(#color-map_${filter.colorMap})`
  }

  return filterString.trim() || 'none'
}
