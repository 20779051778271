import { DarwinKind, DarwinFlair, DarwinSize, DarwinTag } from './types'
import type { DarwinKindType, DarwinFlairType, DarwinSizeType, DarwinTagType } from './types'

export const flairValidator = (value: DarwinFlairType): boolean =>
  Object.values(DarwinFlair).includes(value)

export const sizeValidator = (value: DarwinSizeType): boolean =>
  Object.values(DarwinSize).includes(value)

export const tagValidator = (value: DarwinTagType): boolean =>
  Object.values(DarwinTag).includes(value)

export const kindValidator = (value: DarwinKindType): boolean =>
  Object.values(DarwinKind).includes(value)
