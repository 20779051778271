import type { Eye } from '@/modules/Editor/AnnotationData'
import { euclideanDistance } from '@/modules/Editor/algebra'
import type { IPoint } from '@/modules/Editor/point'

const calcEyeLowerVertex = (nodes: Eye['nodes']): IPoint => {
  const inner = nodes.find((node) => node.name === 'inner')?.point
  const outer = nodes.find((node) => node.name === 'outer')?.point
  const upper = nodes.find((node) => node.name === 'upper')?.point
  const lower = nodes.find((node) => node.name === 'lower')?.point

  if (!inner || !outer || !upper || !lower) {
    throw new Error(
      'attempting to calculate Eye annotation lower vertex without all necessary nodes',
    )
  }

  const euclideanDistanceInnerOuter = euclideanDistance(inner, outer)
  const euclideanDistanceUpperLower = euclideanDistance(upper, lower)

  const distanceX = inner.x - outer.x
  const distanceY = inner.y - outer.y
  const upperX = upper.x
  const upperY = upper.y
  // if lower vertex moves above upper, invert the direction of the movement adjustment
  const lowerBelowUpper = lower.y > upper.y
  let x
  let y

  if ((distanceX < 0 && lowerBelowUpper) || (distanceX >= 0 && !lowerBelowUpper)) {
    x = upperX + (distanceY / euclideanDistanceInnerOuter) * euclideanDistanceUpperLower
    y = upperY - (distanceX / euclideanDistanceInnerOuter) * euclideanDistanceUpperLower
  } else {
    x = upperX - (distanceY / euclideanDistanceInnerOuter) * euclideanDistanceUpperLower
    y = upperY + (distanceX / euclideanDistanceInnerOuter) * euclideanDistanceUpperLower
  }

  return { x, y }
}

export default calcEyeLowerVertex
