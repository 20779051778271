import type { IPoint } from '@/modules/Editor/point'
import type { CrosshairsState, voidPromiseFn } from '@/modules/Editor/plugins/crosshairsTool/types'
import { getSlotNamesPerPlane } from '@/modules/Editor/utils/radiology/getSlotNamesPerPlane'

/**
 * Produces an async load function for the jump operation.
 *
 * @param crosshairsState The state of the crosshairs tool.
 * @param imageIndexPoint The (x, y) indicies of the
 * @param width
 * @param height
 *
 * @returns The async method to be queued.
 */
const getAsyncLoadFunction = (
  crosshairsState: CrosshairsState,
  imageIndexPoint: IPoint,
  width: number,
  height: number,
): voidPromiseFn => {
  const { activeView, axialView, coronalView, sagittalView } = crosshairsState
  const { slot_name: viewSlotName } = activeView.fileManager.file
  const slotNames = getSlotNamesPerPlane(activeView.fileManager.metadata?.medical)

  // Get the middle of the pixel in image space
  const x = imageIndexPoint.x + 0.5
  const y = imageIndexPoint.y + 0.5

  const normalizedX = x / width
  const normalizedY = y / height

  if (viewSlotName === slotNames.AXIAL) {
    const lastSagittalIndex = sagittalView.totalFrames - 1
    const lastCoronalIndex = coronalView.totalFrames - 1

    const sagittalIndex = Math.floor(normalizedX * lastSagittalIndex)
    const coronalIndex = Math.floor(normalizedY * lastCoronalIndex)

    return async (): Promise<void> => {
      await Promise.all([
        sagittalView.jumpToFrameNoLoad(sagittalIndex),
        coronalView.jumpToFrameNoLoad(coronalIndex),
      ])
    }
  }

  if (viewSlotName === slotNames.SAGITTAL) {
    const lastAxialIndex = axialView.totalFrames - 1
    const lastCoronalIndex = coronalView.totalFrames - 1

    const axialIndex = Math.floor(normalizedY * lastAxialIndex)
    const coronalIndex = Math.floor(normalizedX * lastCoronalIndex)

    return async (): Promise<void> => {
      await Promise.all([
        axialView.jumpToFrameNoLoad(axialIndex),
        coronalView.jumpToFrameNoLoad(coronalIndex),
      ])
    }
  }

  if (viewSlotName === slotNames.CORONAL) {
    const lastAxialIndex = axialView.totalFrames - 1
    const lastSagittalIndex = sagittalView.totalFrames - 1

    const axialIndex = Math.floor(normalizedY * lastAxialIndex)
    const sagittalIndex = Math.floor(normalizedX * lastSagittalIndex)

    return async (): Promise<void> => {
      await Promise.all([
        axialView.jumpToFrameNoLoad(axialIndex),
        sagittalView.jumpToFrameNoLoad(sagittalIndex),
      ])
    }
  }

  throw new Error('unrecognised slot')
}

export function queueJumpToPosition(
  crosshairsState: CrosshairsState,
  imageIndexPoint: IPoint,
): void {
  const { activeView } = crosshairsState
  const { metadata: viewMetadata } = activeView.fileManager.file

  if (viewMetadata === undefined) {
    // Return silently
    return
  }

  const asyncLoadfunction = getAsyncLoadFunction(
    crosshairsState,
    imageIndexPoint,
    viewMetadata.width,
    viewMetadata.height,
  )

  if (crosshairsState.activeLoadPromise) {
    crosshairsState.queuedPromiseFunction = asyncLoadfunction

    crosshairsState.activeLoadPromise.then(() => {
      if (crosshairsState.queuedPromiseFunction) {
        // If a queued function exists, run it and set is the active promise
        crosshairsState.activeLoadPromise = crosshairsState.queuedPromiseFunction()
        crosshairsState.queuedPromiseFunction = undefined
      }
    })
  } else {
    crosshairsState.activeLoadPromise = asyncLoadfunction()
  }
}
