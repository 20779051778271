import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import {
  isRasterAnnotation,
  isVideoAnnotationData,
} from '@/modules/Editor/models/annotation/annotationKindValidator'

/**
 * Every mask annotation contains a reference to a raster via an ID.
 * This helper simply fetches and returns this. If the annotation is not
 * a mask annotation it returns undefined.
 */
export const getRasterIdFromMaskAnnotation = (annotation: Annotation): string | undefined => {
  // Not a raster annotation.
  if (!isRasterAnnotation(annotation)) {
    return
  }

  const annotationData = annotation.data

  if (isVideoAnnotationData(annotationData)) {
    const firstFrameIndex = Object.keys(annotationData.frames)[0]
    const firstFrame = annotationData.frames[firstFrameIndex]
    return firstFrame.rasterId
  }

  return annotationData.rasterId
}
