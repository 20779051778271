import type { FrameData } from '@/core/annotations'
import type { Polyline } from '@/modules/Editor/AnnotationData'
import { createEditablePoint } from '@/modules/Editor/point'

export const polylineSerializer = {
  serialize(data: Polyline): FrameData {
    return {
      line: {
        path: data.path,
      },
    }
  },

  deserialize(rawData: FrameData): Polyline | null {
    if (!rawData.line) {
      return null
    }
    const path = rawData.line.path.map((point) => createEditablePoint(point))
    return {
      path,
    }
  },
}
